export function getUrlParameter(): string | undefined {
  const parameter = window.location.href.split('?');

  if (parameter && parameter.length <= 1) {
    return undefined;
  }

  return parameter[1]
    .replaceAll('#', '')
    .replaceAll('/', '');
}
