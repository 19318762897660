import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import { InvoiceConfirmImportModal } from '../../components/invoiceConfirmImportModal/invoiceConfirmImportModal';
import { InvoiceImportListComponent } from '../../components/invoiceImportListComponent/invoiceImportListComponent';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { InvoiceImportModel } from '../../models/invoiceImportModel';
import { InvoiceModel } from '../../models/invoiceModel';
import { PaginationModel } from '../../models/paginationModel';
import { useAppDispatch } from '../../redux/hooks';
import { setImportInvoicesReset } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoUri.json';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { ImportInvoiceService } from '../../services/importInvoiceService';
import { PromiseStatuses } from '../../types/strings';
import './invoiceImportList.css';
import lang from './invoiceImportList.json';

export function InvoiceImportList() {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [statusImport, setStatusImport] = useState<PromiseStatuses>('idle');
  const [paginationInvoices, setPaginationInvoices] = useState<
    PaginationModel<InvoiceModel>
  >({
    content: [],
  });
  const [page, setPage] = useState<number>(0);

  const { collapsed } = useProSidebar();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getPaginationInvoices = () => {
    const service = new ImportInvoiceService();

    setStatus('loading');
    service
      .findAllCustomer()
      .then((data) => {
        setPaginationInvoices(
          CommonInvoiceService.paginate(page, config.pageSize, data)
        );
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failed');
        console.error(err);
      });
  };

  const importInvoices = () => {
    const service = new ImportInvoiceService();

    setStatusImport('loading');
    service
      .importCustomer()
      .then(() => {
        setStatusImport('successfully');
        dispatch(setImportInvoicesReset())  
        navigate(uri.CustomerInvoiceList);
      })
      .catch((err) => {
        setStatusImport('failed');
        console.error(err);
      });
  };

  const updatePaginationInvoices = (
    index: number,
    updates: InvoiceImportModel
  ) => {
    const invoicesArray = paginationInvoices.content.map((item, i) =>
      i === index ? { ...item, ...updates } : item
    );

    setPaginationInvoices({ ...paginationInvoices, content: invoicesArray });
  };

  useEffect(() => {
    getPaginationInvoices();
  }, [page]);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="customer-list-menu"
            menuItem={MenuItems.CUSTOMER_INVOICE}
          />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-auto'}>
              <div className="invoice-importList-warn-box p-4">
                <b>{lang.warn_box_1}</b>
                <br />
                {lang.warn_box_2}
                <b>{lang.warn_box_3}</b> {lang.warn_box_4}
                <br />
                {lang.warn_box_5}
                <b>{lang.warn_box_6}</b> {lang.warn_box_7}
              </div>
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <InvoiceImportListComponent
                invoiceList={paginationInvoices.content}
                status={status}
                updatePaginationInvoices={updatePaginationInvoices}
                getPaginationInvoices={getPaginationInvoices}
                type='customer'
              />
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <div className={'d-flex justify-content-center'}>
                <QuickfiscoPagination
                  totalPages={paginationInvoices.totalPages}
                  number={paginationInvoices.number}
                  empty={paginationInvoices.empty}
                  doPagination={(page: number) => setPage(page)}
                  first={paginationInvoices.first}
                  last={paginationInvoices.last}
                />
              </div>
            </div>
          </div>
          <div className={'row mt-4 mb-5'}>
            <div className={'col-6 offset-3'}>
              <QuickfiscoButton
                id={'invoiceImportListRow-import-btn'}
                type={'secondary'}
                label={lang.importBtn}
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <InvoiceConfirmImportModal
        open={open}
        setOpen={(open: boolean) => setOpen(open)}
        status={statusImport}
        importInvoices={importInvoices}
        type='customer'
      />
    </div>
  );
}
