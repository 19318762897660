import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import useComponentVisible from '../../hooks/componentVisible';
import {
  InvoiceModel,
  InvoiceStatusType,
  InvoiceType,
} from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setAutoInvoiceSaveOrDuplicateOrEditInvoiceId,
  setAutoInvoiceSaveOrDuplicateOrEditOperation,
} from '../../redux/slices/autoInvoiceSaveOrDuplicateOrEditSlice';
import {
  setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId,
  setCustomerInvoiceSaveOrDuplicateOrEditOperation,
} from '../../redux/slices/customerInvoiceSaveOrDuplicateOrEditSlice';
import {
  setProformaInvoiceSaveOrDuplicateOrEditInvoiceId,
  setProformaInvoiceSaveOrDuplicateOrEditOperation,
} from '../../redux/slices/proformaInvoiceSaveOrDuplicateOrEditSlice';
import {
  setStsInvoiceSaveOrDuplicateOrEditInvoiceId,
  setStsInvoiceSaveOrDuplicateOrEditOperation,
} from '../../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice';
import {
  setSupplierInvoiceSaveOrEditInvoiceId,
  setSupplierInvoiceSaveOrEditOperation,
} from '../../redux/slices/supplierInvoiceSaveOrEditSlice';
import uri from '../../routers/quickfiscoUri.json';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { InvoiceSign } from '../../types/strings';
import { formatDateIT } from '../../utils/date';
import { formatNumberIT } from '../../utils/number';
import { CustomerInvoiceRejected } from '../customerInvoiceRejected/customerInvoiceRejected';
import { InvoiceMenu } from '../invoiceMenu/invoiceMenu';
import { InvoicePaymentSave } from '../invoicePaymentSave/invoicePaymentSave';
import { InvoicePaymentStatus } from '../invoicePaymentStatus/invoicePaymentStatus';
import { InvoiceStatus } from '../invoiceStatus/invoiceStatus';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { StsInvoiceMenu } from '../stsInvoiceMenu/stsInvoiceMenu';
import { StsInvoiceRejected } from '../stsInvoiceRejected/stsInvoiceRejected';
import './invoiceRow.css';
import lang from './invoiceRow.json';

interface Props {
  invoiceSign: InvoiceSign;
  invoice: InvoiceModel;
  invoiceCustomerRowType: InvoiceCustomerRowType;
}

export enum InvoiceCustomerRowType {
  DASHBOARD = 'DASHBOARD',
  INVOICE_LIST = 'INVOICE_LIST',
}

export function InvoiceRow(props: Props) {
  const { invoiceSign } = props;
  const { invoice } = props;
  const { invoiceCustomerRowType } = props;

  const showMenu = useComponentVisible(false);
  const showPopup = useComponentVisible(false);

  const [mouseOverMenu, setMouseOverMenu] = useState(false);
  const [openModalPaymentStatus, setOpenModalPaymentStatus] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.user);

  const user = userState.user;
  const invoiceId = invoice.id;
  const totalAmount = CustomerInvoiceService.calculateTotalAmount(
    invoice,
    user,
    invoiceSign
  );

  let partialAmount: number = 0;
  if (invoice.payments !== undefined && invoice.payments !== null) {
    invoice.payments.forEach((payment) => {
      partialAmount = partialAmount + payment.amount;
    });
  }

  if (invoiceId === undefined) {
    return <div>Errore durante il caricamento dei dati</div>;
  }

  if (
    invoiceSign !== 'customer' &&
    invoiceCustomerRowType === InvoiceCustomerRowType.DASHBOARD
  ) {
    return (
      <div>
        Errore: il tipo invoiceCustomerRowType=DASHBOARD è disponibile solo per
        le customer invoice
      </div>
    );
  }

  if (invoiceCustomerRowType === InvoiceCustomerRowType.DASHBOARD) {
    return (
      <div className="row-invoice-container">
        <div className={'row pt-1 no-gutters'}>
          <div className={'col-4 d-flex align-items-center'}>
            <span className={'invoice-list-row-text-bold list-crop'}>
              {invoice.customer?.companyName}
            </span>
          </div>
          <div className={'col-2 d-flex align-items-center'}>
            <span className={'invoice-list-row-text list-crop'}>
              {formatNumberIT(totalAmount) + config.EUR}
            </span>
          </div>
          <div className={'col-3 d-flex align-items-center'}>
            {invoice.isPaymentDue && (
              <InvoicePaymentStatus
                status={invoice.status}
                partialPaid={
                  partialAmount !== totalAmount && partialAmount !== 0
                }
              />
            )}
          </div>
          {(invoice.status === InvoiceStatusType.DUE_TERMS ||
            invoice.status === InvoiceStatusType.ACCEPTED ||
            invoice.status === InvoiceStatusType.DELIVERED ||
            invoice.status === InvoiceStatusType.RECEIVED ||
            invoice.status === InvoiceStatusType.NOT_RECEIVED) &&
            invoice.type !== InvoiceType.TD04 &&
            invoice.type !== InvoiceType.STS &&
            invoice.type !== InvoiceType.TD16 &&
            invoice.type !== InvoiceType.TD17 &&
            invoice.type !== InvoiceType.TD18 &&
            invoice.type !== InvoiceType.TD19 && (
              <div className={'col-3 d-flex align-items-center'}>
                <QuickfiscoButton
                  id={'to-be-paid-invoices-payment'}
                  label={lang.payment}
                  onClick={() => setOpenModalPaymentStatus(true)}
                  type={'quinary'}
                />
              </div>
            )}
        </div>
        <div className={'row mt-1 no-gutters'}>
          <div className={'col-12'}>
            <div className={'invoice-list-row-line'} />
          </div>
        </div>
        <InvoicePaymentSave
          open={openModalPaymentStatus}
          setOpen={(open) => setOpenModalPaymentStatus(open)}
          invoiceSign={invoiceSign}
          invoice={invoice}
        />
      </div>
    );
  }

  switch (invoiceSign) {
    case 'customer':
    case 'sts':
    case 'creditSlip':
      const editCustomerSts = () => {
        if (invoiceSign === 'creditSlip') {
          dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(invoiceId));
          dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('edit'));
          navigate(uri.CreditSlipInvoiceSave);
        } else if (invoice.type === InvoiceType.STS) {
          dispatch(setStsInvoiceSaveOrDuplicateOrEditInvoiceId(invoiceId));
          dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('edit'));
          navigate(uri.StsInvoiceSave);
        } else {
          dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(invoiceId));
          dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('edit'));
          navigate(uri.CustomerInvoiceSave);
        }
      };

      return (
        <div className={'row no-gutters row-invoice-container'}>
          <div className={'row no-gutters'}>
            <div className={'col-12'}>
              <div className={'invoice-list-row-line'} />
            </div>
          </div>
          <div
            className={'row no-gutters invoice-list-row-row align-items-center'}
          >
            <div
              className={'col no-gutters pb-3 pt-3'}
              onClick={() => editCustomerSts()}
            >
              <div className={'row no-gutters align-items-center'}>
                <div className={'col invoice-list-row-bold'}>
                  {invoice.customer?.companyName}
                </div>
                <div
                  className={
                    'col invoice-row-total-amount invoice-list-row-bold list-crop'
                  }
                >
                  {formatNumberIT(totalAmount)}
                  {config.EUR}
                </div>
                <div
                  className={
                    'col invoice-row-number invoice-list-row-gray list-crop'
                  }
                >
                  {invoice.number}
                </div>
                <div className={'col invoice-row-status position-relative'}>
                  <div
                    className={
                      'd-flex flex-row justify-content-start align-items-center'
                    }
                  >
                    <div className={'list-crop'}>
                      <InvoiceStatus
                        status={invoice.status}
                        previousStatus={invoice.previousStatus}
                        invoiceSign={
                          invoice.type === InvoiceType.STS ? 'sts' : 'customer'
                        }
                      />
                    </div>
                    {(invoice.status === InvoiceStatusType.REJECTED ||
                      invoice.status === InvoiceStatusType.QUEUE_NACK) &&
                      invoice.type !== undefined &&
                      invoice.type !== InvoiceType.STS && (
                        <div className={'d-flex'}>
                          <div className={'ms-2'}>
                            {/* <InvoiceRejectAcknowledgment
                              invoiceId={invoiceId}
                              invoiceType={invoice.type}
                              invoiceSign={invoiceSign}
                            /> */}
                            <div
                              onMouseOver={() => showPopup.setIsVisible(true)}
                              onMouseOut={() => showPopup.setIsVisible(false)}
                            >
                              <QuickfiscoIcon
                                name={'back-to-draft.svg'}
                                isButton={true}
                                onClick={() =>
                                  navigate(uri.CustomerInvoiceSave)
                                }
                              />
                            </div>
                          </div>
                          {(invoice.status === InvoiceStatusType.QUEUE_NACK ||
                            invoice.status === InvoiceStatusType.REJECTED) &&
                            invoice.lastError && (
                              <div className={'ms-2'}>
                                <CustomerInvoiceRejected
                                  error={invoice.lastError}
                                />
                              </div>
                            )}
                        </div>
                      )}
                    {(invoice.status === InvoiceStatusType.REJECTED ||
                      invoice.status === InvoiceStatusType.QUEUED_NACK ||
                      invoice.status ===
                        InvoiceStatusType.COMMUNICATE_CHANGE_NACK ||
                      (invoice.status === InvoiceStatusType.ACCEPTED &&
                        invoice.previousStatus ===
                          InvoiceStatusType.CANCEL_SENDING)) &&
                      invoice.type === InvoiceType.STS && (
                        <div className={'ms-2'}>
                          <StsInvoiceRejected error={invoice.error} />
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className={
                    'col d-none d-xxl-block invoice-row-date invoice-list-row-gray list-crop'
                  }
                >
                  {invoice.date && formatDateIT(invoice.date)}
                </div>
                {invoiceSign !== 'creditSlip' && (
                  <div
                    className={`col d-none d-xxl-block invoice-row-date invoice-list-row-gray list-crop ${
                      invoice.isPaymentDue && 'invoice-row-date-expired'
                    }`}
                  >
                    {invoice.paymentExpiration &&
                      formatDateIT(invoice.paymentExpiration)}
                  </div>
                )}
                {invoiceSign !== 'creditSlip' && (
                  <div
                    className={
                      'col invoice-row-payment invoice-list-row-gray list-crop'
                    }
                  >
                    {invoice.type !== InvoiceType.TD04 ? (
                      <InvoicePaymentStatus
                        status={
                          invoice.type === InvoiceType.STS
                            ? InvoiceStatusType.PAID
                            : invoice.status
                        }
                        partialPaid={
                          partialAmount !== totalAmount && partialAmount !== 0
                        }
                      />
                    ) : (
                      lang.line
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                'col-auto position-relative d-flex align-items-start justify-content-end'
              }
            >
              {showMenu.isVisible ? (
                <div>
                  <QuickfiscoIcon name={'menu-active.svg'} />
                </div>
              ) : (
                <div
                  onMouseOver={() => setMouseOverMenu(true)}
                  onMouseOut={() => setMouseOverMenu(false)}
                  onClick={() => showMenu.setIsVisible(true)}
                >
                  <QuickfiscoIcon
                    name={mouseOverMenu ? 'menu-hover.svg' : 'menu.svg'}
                  />
                </div>
              )}
              <div className={'contactListRow-menu'} ref={showMenu.ref}>
                {invoice.type === InvoiceType.STS ? (
                  <StsInvoiceMenu
                    invoice={invoice}
                    invoiceSign="customer"
                    componentVisible={showMenu}
                  />
                ) : (
                  <InvoiceMenu
                    componentVisible={showMenu}
                    invoiceSign={invoiceSign}
                    invoice={invoice}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case 'supplier':
      const editSupplier = () => {
        dispatch(setSupplierInvoiceSaveOrEditInvoiceId(invoice?.id));
        dispatch(setSupplierInvoiceSaveOrEditOperation('edit'));
        navigate(uri.SupplierInvoiceSave);
      };

      return (
        <div className={'row no-gutters row-invoice-container'}>
          <div className={'row no-gutters'}>
            <div className={'col-12'}>
              <div className={'invoice-list-row-line'} />
            </div>
          </div>
          <div
            className={'row no-gutters invoice-list-row-row align-items-center'}
          >
            <div
              className={'col no-gutters pb-3 pt-3'}
              onClick={() => editSupplier()}
            >
              <div className={'row no-gutters align-items-center'}>
                <div className="col invoice-list-row-bold">
                  {invoice.supplier?.companyName}
                </div>
                <div className={'col invoice-list-row-bold list-crop'}>
                  {invoice.amount !== undefined &&
                    invoice.amount !== null &&
                    formatNumberIT(invoice.amount)}
                  {config.EUR}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.number}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.type && invoice.type === 'TD04'
                    ? 'Nota di credito'
                    : 'Fattura'}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.date && formatDateIT(invoice.date)}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.paymentExpiration &&
                    formatDateIT(invoice.paymentExpiration)}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  <InvoiceStatus
                    status={invoice.status}
                    invoiceSign={invoiceSign}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                'col-auto position-relative d-flex align-items-start justify-content-end'
              }
            >
              {showMenu.isVisible ? (
                <div>
                  <QuickfiscoIcon name={'menu-active.svg'} />
                </div>
              ) : (
                <div
                  onMouseOver={() => setMouseOverMenu(true)}
                  onMouseOut={() => setMouseOverMenu(false)}
                  onClick={() => showMenu.setIsVisible(true)}
                >
                  <QuickfiscoIcon
                    name={mouseOverMenu ? 'menu-hover.svg' : 'menu.svg'}
                  />
                </div>
              )}
              <div className={'contactListRow-menu'} ref={showMenu.ref}>
                <InvoiceMenu
                  componentVisible={showMenu}
                  invoiceSign={invoiceSign}
                  invoice={invoice}
                />
              </div>
            </div>
          </div>
        </div>
      );
    case 'proforma':
      const editProforma = () => {
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id));
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('edit'));
        navigate(uri.ProformaInvoiceSave);
      };

      return (
        <div className={'row no-gutters row-invoice-container'}>
          <div className={'row no-gutters'}>
            <div className={'col-12'}>
              <div className={'invoice-list-row-line'} />
            </div>
          </div>
          <div
            className={'row no-gutters invoice-list-row-row align-items-center'}
          >
            <div
              className={'col no-gutters pb-3 pt-3'}
              onClick={() => editProforma()}
            >
              <div className={'row no-gutters align-items-center'}>
                <div className="col invoice-list-row-bold">
                  {invoice.customer?.companyName}
                </div>
                <div className={'col invoice-list-row-bold list-crop'}>
                  {formatNumberIT(totalAmount)}
                  {config.EUR}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.number}
                </div>
                <div className={'col position-relative'}>
                  <div
                    className={
                      'd-flex flex-row justify-content-start align-items-center'
                    }
                  >
                    <div className={'list-crop'}>
                      <InvoiceStatus
                        status={invoice.status}
                        previousStatus={invoice.previousStatus}
                        invoiceSign={'proforma'}
                      />
                    </div>
                  </div>
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.date && formatDateIT(invoice.date)}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.paymentExpiration &&
                    formatDateIT(invoice.paymentExpiration)}
                </div>
              </div>
            </div>
            <div
              className={
                'col-auto position-relative d-flex align-items-start justify-content-end'
              }
            >
              {showMenu.isVisible ? (
                <div>
                  <QuickfiscoIcon name={'menu-active.svg'} />
                </div>
              ) : (
                <div
                  onMouseOver={() => setMouseOverMenu(true)}
                  onMouseOut={() => setMouseOverMenu(false)}
                  onClick={() => showMenu.setIsVisible(true)}
                >
                  <QuickfiscoIcon
                    name={mouseOverMenu ? 'menu-hover.svg' : 'menu.svg'}
                  />
                </div>
              )}
              <div className={'contactListRow-menu'} ref={showMenu.ref}>
                <InvoiceMenu
                  componentVisible={showMenu}
                  invoiceSign={invoiceSign}
                  invoice={invoice}
                />
              </div>
            </div>
          </div>
        </div>
      );
    case 'auto':
      const editAuto = () => {
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id));
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditOperation('edit'));
        navigate(uri.AutoInvoiceSave);
      };

      return (
        <div className={'row no-gutters row-invoice-container'}>
          <div className={'row no-gutters'}>
            <div className={'col-12'}>
              <div className={'invoice-list-row-line'} />
            </div>
          </div>
          <div
            className={'row no-gutters invoice-list-row-row align-items-center'}
          >
            <div
              className={'col no-gutters pb-3 pt-3'}
              onClick={() => editAuto()}
            >
              <div className={'row no-gutters align-items-center'}>
                <div className="col invoice-list-row-bold">
                  {invoice.supplier?.companyName}
                </div>
                <div
                  className={
                    'col invoice-row-total-amount invoice-list-row-bold list-crop'
                  }
                >
                  {formatNumberIT(totalAmount)}
                  {config.EUR}
                </div>
                <div
                  className={
                    'col invoice-row-number invoice-list-row-gray list-crop'
                  }
                >
                  {invoice.number}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.type && autoInvoiceType.get(invoice.type)}
                </div>
                <div className={'col invoice-row-status position-relative'}>
                  <div
                    className={
                      'd-flex flex-row justify-content-start align-items-center'
                    }
                  >
                    <div className={'list-crop'}>
                      <InvoiceStatus
                        invoiceSign={'auto'}
                        status={invoice.status}
                        previousStatus={invoice.previousStatus}
                      />
                    </div>
                    {(invoice.status === InvoiceStatusType.REJECTED ||
                      invoice.status === InvoiceStatusType.QUEUE_NACK) &&
                      invoice.type !== undefined && (
                        <div className={'d-flex'}>
                          <div className={'ms-2'}>
                            {/* <InvoiceRejectAcknowledgment
                              invoiceId={invoiceId}
                              invoiceType={invoice.type}
                              invoiceSign={invoiceSign}
                            /> */}
                            <div
                              onMouseOver={() => showPopup.setIsVisible(true)}
                              onMouseOut={() => showPopup.setIsVisible(false)}
                            >
                              <QuickfiscoIcon
                                name={'back-to-draft.svg'}
                                isButton={true}
                                onClick={() => navigate(uri.AutoInvoiceSave)}
                              />
                            </div>
                          </div>
                          {(invoice.status === InvoiceStatusType.QUEUE_NACK ||
                            invoice.status === InvoiceStatusType.REJECTED) &&
                            invoice.lastError && (
                              <div className={'ms-2'}>
                                <CustomerInvoiceRejected
                                  error={invoice.lastError}
                                />
                              </div>
                            )}
                        </div>
                      )}
                  </div>
                </div>
                <div
                  className={
                    'col invoice-row-date invoice-list-row-gray list-crop'
                  }
                >
                  {invoice.date && formatDateIT(invoice.date)}
                </div>
              </div>
            </div>
            <div
              className={
                'col-auto position-relative d-flex align-items-start justify-content-end'
              }
            >
              {showMenu.isVisible ? (
                <div>
                  <QuickfiscoIcon name={'menu-active.svg'} />
                </div>
              ) : (
                <div
                  onMouseOver={() => setMouseOverMenu(true)}
                  onMouseOut={() => setMouseOverMenu(false)}
                  onClick={() => showMenu.setIsVisible(true)}
                >
                  <QuickfiscoIcon
                    name={mouseOverMenu ? 'menu-hover.svg' : 'menu.svg'}
                  />
                </div>
              )}
              <div className={'contactListRow-menu'} ref={showMenu.ref}>
                <InvoiceMenu
                  componentVisible={showMenu}
                  invoiceSign={invoiceSign}
                  invoice={invoice}
                />
              </div>
            </div>
          </div>
        </div>
      );
    case 'occasionalJob':
      const editOccasionalJob = () => {
        navigate(uri.OccasionalJobInvoice + '/' + invoice.id);
      };

      return (
        <div className={'row no-gutters row-invoice-container'}>
          <div className={'row no-gutters'}>
            <div className={'col-12'}>
              <div className={'invoice-list-row-line'} />
            </div>
          </div>
          <div
            className={'row no-gutters invoice-list-row-row align-items-center'}
          >
            <div
              className={'col no-gutters pb-3 pt-3'}
              onClick={() => editOccasionalJob()}
            >
              <div className={'row no-gutters align-items-center'}>
                <div className="col invoice-list-row-bold">
                  {invoice.customer?.companyName}
                </div>
                <div className={'col invoice-list-row-bold list-crop'}>
                  {formatNumberIT(totalAmount)}
                  {config.EUR}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.number}
                </div>
                <div className={'col invoice-list-row-gray list-crop'}>
                  {invoice.date && formatDateIT(invoice.date)}
                </div>
                <div className={'col position-relative'}>
                  <div
                    className={
                      'd-flex flex-row justify-content-start align-items-center'
                    }
                  >
                    <div className={'list-crop'}>
                      <InvoiceStatus
                        status={invoice.status}
                        previousStatus={invoice.previousStatus}
                        invoiceSign={'occasionalJob'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                'col-auto position-relative d-flex align-items-start justify-content-end'
              }
            >
              {showMenu.isVisible ? (
                <div>
                  <QuickfiscoIcon name={'menu-active.svg'} />
                </div>
              ) : (
                <div
                  onMouseOver={() => setMouseOverMenu(true)}
                  onMouseOut={() => setMouseOverMenu(false)}
                  onClick={() => showMenu.setIsVisible(true)}
                >
                  <QuickfiscoIcon
                    name={mouseOverMenu ? 'menu-hover.svg' : 'menu.svg'}
                  />
                </div>
              )}
              <div className={'contactListRow-menu'} ref={showMenu.ref}>
                <InvoiceMenu
                  componentVisible={showMenu}
                  invoiceSign={invoiceSign}
                  invoice={invoice}
                />
              </div>
            </div>
          </div>
        </div>
      );
  }
}

const autoInvoiceType: Map<InvoiceType, string> = new Map([
  [InvoiceType.TD16, lang.td16],
  [InvoiceType.TD17, lang.td17],
  [InvoiceType.TD18, lang.td18],
  [InvoiceType.TD19, lang.td19],
]);
