import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './dataSendSelect.json';

interface Props {
  id: string;
  value?: string;
  disabled?: boolean;
  onChange?: (dataSend?: string) => void;
  error?: boolean;
  errorLabel?: string;
}

export function DataSendSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { disabled } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.title}
      options={getOptions(value)}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          onChange && onChange(e.value);
        }
      }}
      disabled={disabled}
      error={error}
      errorLabel={errorLabel}
      requiredField={true}
      styleType={'default'}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Seleziona opzione',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Il cliente acconsente',
      value: 'false',
      selected: value === 'false',
    },
    {
      label: 'Il cliente si oppone',
      value: 'true',
      selected: value === 'true',
    },
  ];
}
