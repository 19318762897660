import { OptionModel } from '../../models/optionModel';
import { InpsFundDescriptionType } from '../../models/userModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  onChange?: (fund: InpsFundDescriptionType | undefined) => void;
}

export function InpsFundDescriptionSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { value } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'Descrizione cassa (INPS) *'}
      // @ts-ignore
      onChange={(e) => onChange && onChange(e.value)}
      options={getOptions(value)}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      styleType={'default'}
      disabled={true}
    />
  );
}

function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona descrizione cassa INPS',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Gestione separata',
      value: InpsFundDescriptionType.NOT_FUND,
      selected: value === InpsFundDescriptionType.NOT_FUND,
    },
    {
      label: 'Cassa Artigiani',
      value: InpsFundDescriptionType.ARTISAN_FUND,
      selected: value === InpsFundDescriptionType.ARTISAN_FUND,
    },
    {
      label: 'Cassa Commercianti',
      value: InpsFundDescriptionType.TRADER_FUND,
      selected: value === InpsFundDescriptionType.TRADER_FUND,
    },
    {
      label: 'EX-ENPALS',
      value: InpsFundDescriptionType.EX_ENPALS_FUND,
      selected: value === InpsFundDescriptionType.EX_ENPALS_FUND,
    },
  ];
}
