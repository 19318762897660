import './quickfiscoModal.css';
import React from 'react';
import { Modal } from 'react-bootstrap';

interface Props {
  hide: Function;
  isOpen: boolean;
  children?: React.ReactNode;
  modalClassName?: string;
  backdrop?: boolean | 'static';
  closeButton?: boolean;
  keyboard?: boolean;
  centered?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}

export function QuickfiscoModal(props: Props) {
  const { hide } = props;
  const { isOpen } = props;
  const { children } = props;
  const { modalClassName } = props;
  const { backdrop } = props;
  const { closeButton } = props;
  const { keyboard } = props;
  const { centered } = props;
  const { size } = props;

  return (
    <Modal
      show={isOpen}
      onHide={() => hide()}
      backdrop={backdrop}
      className={modalClassName}
      keyboard={keyboard}
      centered={centered}
      size={size}
    >
      <Modal.Header
        closeButton={closeButton ? false : true}
        className={'border-0'}
      />
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}
