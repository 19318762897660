import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FinanceOutcomeEnum, FinanceSimulationModel, FinanceStatusEnum, FinanceUserModel, FinancingModel } from '../../models/financeModel';
import { FinanceService } from '../../services/financeService';
import { PromiseStatuses } from '../../types/strings';

export interface FinanceState {
  findUserFinanceStatus: PromiseStatuses;
  userFinance?: FinanceUserModel;
  financeStep: number
  userFinanceRequest: FinanceUserModel
  financing?: FinancingModel
  financeSimulationStatus: PromiseStatuses
  financeSimulationResponse?: FinanceSimulationModel
  sendFinanceUserStatus: PromiseStatuses
  sendEmailStatus: PromiseStatuses
  openSendEmailModal: boolean
}

const initialState: FinanceState = {
  findUserFinanceStatus: 'idle',
  financeStep: 0,
  userFinanceRequest: {
    status: FinanceStatusEnum.sent,
    outcome: FinanceOutcomeEnum.waiting, 
    data: {
      income: 0,
      mortgage_or_rental: false,
      financing_ongoing: false,
      birth_date: new Date(),
      civil_status: '',
      family_unit: undefined,
      citizenship: '',
      politically_exposed: false,
      address: '',
      postal_code: '',
      province: '',
      city: '',
      region: '',
      housing_type: 'rent',
      residence_start_date: new Date(),
      loan_reason: '',
      bank_name: '',
      iban: '',
      same_as_domicile: true,
      domicile_address: null,
      domicile_postal_code: null,
      domicile_province: null,
      domicile_city: null,
      domicile_region: null
    },
    total_amount: 0,
    installment_amount: 0,
    installment_count: 0
  },
  financeSimulationStatus: 'idle',
  sendFinanceUserStatus: 'idle',
  sendEmailStatus: 'idle',
  openSendEmailModal: false
};

export const findUserFinance = createAsyncThunk(
  'finance/findUserFinance',
  async (): Promise<FinanceUserModel> => {
    return new FinanceService().findUserFinance();
  }
);

export const financeSimulation = createAsyncThunk(
  'finance/financeSimulation',
  async (value: number): Promise<FinanceSimulationModel> => {
    return new FinanceService().financeSimulation(value);
  }
);

export const sendFinanceUser = createAsyncThunk(
  'finance/sendFinanceUser',
  async (value: FinanceUserModel): Promise<void> => {
    return new FinanceService().sendFinanceUser(value)
  }
);

export const sendEmail = createAsyncThunk(
  'finance/sendEmail',
  async (): Promise<void> => {
    return new FinanceService().sendEmail()
  }
);

const FinanceSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    setFinanceStep: (state, action) => {
      state.financeStep = action.payload
    },
    setFinanceUserRequestStatus: (state, action) => {
      state.userFinanceRequest.status = action.payload
    },
    setFinanceUserRequestOutcome: (state, action) => {
      state.userFinanceRequest.outcome = action.payload
    },
    setFinanceUserRequestIncome: (state, action) => {
      state.userFinanceRequest.data.income = action.payload
    },
    setFinanceUserRequestMortageOrRental: (state, action) => {
      state.userFinanceRequest.data.mortgage_or_rental = action.payload
    },
    setFinanceUserRequestMortageOrRentalAmount: (state, action) => {
      state.userFinanceRequest.data.mortgage_or_rental_amount = action.payload
    },
    setFinanceUserRequestFinancingOngoing: (state, action) => {
      state.userFinanceRequest.data.financing_ongoing = action.payload
    },
    setFinanceUserRequestFinancingOngoingTotal: (state, action) => {
      state.userFinanceRequest.data.financing_ongoing_total = action.payload
    },
    setFinanceUserRequestBirthDate: (state, action) => {
      state.userFinanceRequest.data.birth_date = action.payload
    },
    setFinanceUserRequestCivilStatus: (state, action) => {
      state.userFinanceRequest.data.civil_status = action.payload
    },
    setFinanceUserRequestFamilyUnit: (state, action) => {
      state.userFinanceRequest.data.family_unit = action.payload
    },
    setFinanceUserRequestCitizienship: (state, action) => {
      state.userFinanceRequest.data.citizenship = action.payload
    },
    setFinanceUserRequestPoliticallyExposed: (state, action) => {
      state.userFinanceRequest.data.politically_exposed = action.payload
    },
    setFinanceUserRequestAddress: (state, action) => {
      state.userFinanceRequest.data.address = action.payload
    },
    setFinanceUserRequestPostalCode: (state, action) => {
      state.userFinanceRequest.data.postal_code = action.payload
    },
    setFinanceUserRequestProvince: (state, action) => {
      state.userFinanceRequest.data.province = action.payload
    },
    setFinanceUserRequestCity: (state, action) => {
      state.userFinanceRequest.data.city = action.payload
    },
    setFinanceUserRequestRegion: (state, action) => {
      state.userFinanceRequest.data.region = action.payload
    },
    setFinanceUserRequestHousingType: (state, action) => {
      state.userFinanceRequest.data.housing_type = action.payload
    },
    setFinanceUserRequestResidenceStartDate: (state, action) => {
      state.userFinanceRequest.data.residence_start_date = action.payload
    },
    setFinanceUserRequestLoanReason: (state, action) => {
      state.userFinanceRequest.data.loan_reason = action.payload
    },
    setFinanceUserRequestBankName: (state, action) => {
      state.userFinanceRequest.data.bank_name = action.payload
    },
    setFinanceUserRequestIban: (state, action) => {
      state.userFinanceRequest.data.iban = action.payload
    },
    setFinanceUserRequestPaymentPreference: (state, action) => {
      state.userFinanceRequest.data.payment_preference = action.payload
    },
    setFinanceUserRequestsameAsDomicile: (state, action) => {
      state.userFinanceRequest.data.same_as_domicile = action.payload
    },
    setFinanceUserRequestDomicileAddress: (state, action) => {
      state.userFinanceRequest.data.domicile_address = action.payload
    },
    setFinanceUserRequestDomicileCity: (state, action) => {
      state.userFinanceRequest.data.domicile_city = action.payload
    },
    setFinanceUserRequestDomicilePostalCode: (state, action) => {
      state.userFinanceRequest.data.domicile_postal_code = action.payload
    },
    setFinanceUserRequestDomicileProvince: (state, action) => {
      state.userFinanceRequest.data.domicile_province = action.payload
    },
    setFinanceUserRequestDomicileRegion: (state, action) => {
      state.userFinanceRequest.data.domicile_region = action.payload
    },
    setFinanceUserRequestTotalAmount: (state, action) => {
      state.userFinanceRequest.total_amount = action.payload
    },
    setFinanceUserRequestInstallmentAmount: (state, action) => {
      state.userFinanceRequest.installment_amount = action.payload
    },
    setFinanceUserRequestInstallmentCount: (state, action) => {
      state.userFinanceRequest.installment_count = action.payload
    },
    setFinancing: (state, action) => {
      state.financing = action.payload
    },
    setOpenSendEmailModal: (state, action) => {
      state.openSendEmailModal = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findUserFinance.pending, (state) => {
        state.findUserFinanceStatus = 'loading';
      })
      .addCase(findUserFinance.fulfilled, (state, action) => {
        state.findUserFinanceStatus = 'successfully';
        state.userFinance = action.payload;
      })
      .addCase(findUserFinance.rejected, (state) => {
        state.findUserFinanceStatus = 'failed';
      })
      .addCase(financeSimulation.pending, (state) => {
        state.financeSimulationStatus = 'loading';
      })
      .addCase(financeSimulation.fulfilled, (state, action) => {
        state.financeSimulationStatus = 'successfully';
        state.financeSimulationResponse = action.payload
      })
      .addCase(financeSimulation.rejected, (state) => {
        state.financeSimulationStatus = 'failed';
      })
      .addCase(sendFinanceUser.pending, (state) => {
        state.sendFinanceUserStatus = 'loading';
      })
      .addCase(sendFinanceUser.fulfilled, (state) => {
        state.sendFinanceUserStatus = 'successfully';
      })
      .addCase(sendFinanceUser.rejected, (state) => {
        state.sendFinanceUserStatus = 'failed';
      })
      .addCase(sendEmail.pending, (state) => {
        state.sendEmailStatus = 'loading';
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.sendEmailStatus = 'successfully';
      })
      .addCase(sendEmail.rejected, (state) => {
        state.sendEmailStatus = 'failed';
      });
  },
});

export const {
  setFinanceStep,
  setFinanceUserRequestAddress,
  setFinanceUserRequestBankName,
  setFinanceUserRequestBirthDate,
  setFinanceUserRequestCitizienship,
  setFinanceUserRequestCity,
  setFinanceUserRequestCivilStatus,
  setFinanceUserRequestFamilyUnit,
  setFinanceUserRequestDomicileAddress,
  setFinanceUserRequestDomicilePostalCode,
  setFinanceUserRequestDomicileProvince,
  setFinanceUserRequestDomicileRegion,
  setFinanceUserRequestFinancingOngoing,
  setFinanceUserRequestFinancingOngoingTotal,
  setFinanceUserRequestHousingType,
  setFinanceUserRequestIban,
  setFinanceUserRequestIncome,
  setFinanceUserRequestInstallmentAmount,
  setFinanceUserRequestMortageOrRental,
  setFinanceUserRequestMortageOrRentalAmount,
  setFinanceUserRequestOutcome,
  setFinanceUserRequestPaymentPreference,
  setFinanceUserRequestPoliticallyExposed,
  setFinanceUserRequestPostalCode,
  setFinanceUserRequestProvince,
  setFinanceUserRequestRegion,
  setFinanceUserRequestResidenceStartDate,
  setFinanceUserRequestLoanReason,
  setFinanceUserRequestStatus,
  setFinanceUserRequestTotalAmount,
  setFinanceUserRequestsameAsDomicile,
  setFinancing,
  setFinanceUserRequestDomicileCity,
  setFinanceUserRequestInstallmentCount,
  setOpenSendEmailModal
} = FinanceSlice.actions

export default FinanceSlice.reducer; 
