import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { ConfirmationError } from '../confirmationError/confirmationError';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { setPassword, doLogin, doLogout } from '../../redux/slices/loginSlice';
import { UserService } from '../../services/userService';
import { getMe } from '../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { routing } from '../../utils/onboardingSteps';
import { OnboardingInitErrorModal } from '../onboardingInitErrorModal/onboardingInitErrorModal';
import { getItem, setItem } from '../../utils/localStorage';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import lang from './loginOnboardingComponent.json';
import './loginOnboardingComponent.css';

interface Props {
  crmUserId?: string | null;
}

export function LoginOnboardingComponent(props: Props) {
  const { crmUserId } = props;

  const [open, setOpen] = useState(!!getItem('onboardingInitError'));
  const [onboardingStatus, setOnboardingStatus] =
    useState<PromiseStatuses>('idle');

  const user = useAppSelector((state) => state.onboarding.user);
  const status = useAppSelector((state) => state.login.status);
  const loggedUser = useAppSelector((state) => state.user.user);
  const loggedUserStatus = useAppSelector((state) => state.user.status);

  const loggedIn = useAppSelector((state) => state.login.loggedIn);
  const authCode = useAppSelector((state) => state.login.codeModel);
  const errorMaxAttempts = useAppSelector(
    (state) => state.login.errorMaxAttempts
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn && loggedUser.registrationSteps) {
      navigate(routing(loggedUser.registrationSteps));
    }
  }, [loggedUser, loggedIn]);

  useEffect(() => {
    if (!getItem('onboardingInitError')) {
      setOpen(false);
    }
  }, [getItem('onboardingInitError')]);

  if (
    loggedUserStatus === 'loading' ||
    onboardingStatus === 'loading' ||
    loggedIn
  ) {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <>
      <ConfirmationError />
      {(loggedUserStatus === 'successfully' || loggedUserStatus === 'idle') && (
        <div className="row">
          <div className="col-12">
            <div className={'row no-gutters'}>
              <div className="col-10 offset-1 text-center">
                <h2 className="login-onboarding-component-sub-title">
                  {lang.hi} <span>{user?.name},</span>
                </h2>
                <p className="login-onboarding-component-text mb-0">
                  {lang.text}
                </p>
              </div>
            </div>
            <div className={'row'}>
              <div className="col-12">
                <hr className="my-4" />
              </div>
            </div>
            <div className="col-12">
              <div className={'row d-flex justify-content-center no-gutters'}>
                <QuickfiscoInput
                  id="login-onboarding-loginEmail"
                  styleType={'default'}
                  label={lang.EmailLabel}
                  value={user.email}
                  type="email"
                  error={false}
                  placeholder={lang.EmailPlaceholder}
                  required={true}
                  disabled={true}
                />
                <div className="mt-4" />
                <QuickfiscoInput
                  id="login-onboarding-loginPassword"
                  styleType={'default'}
                  label={lang.PasswordLabel}
                  type="password"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    dispatch(setPassword(event.target.value))
                  }
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                    login(
                      setOnboardingStatus,
                      authCode.email,
                      authCode.password,
                      dispatch,
                      event.key,
                      crmUserId
                    )
                  }
                  error={status === 'failed'}
                  errorLabel={
                    status === 'failed' && !errorMaxAttempts
                      ? lang.ErrorLabel
                      : 'Hai raggiunto il numero massimo di tentativi. Riprova fra 10 minuti'
                  }
                  placeholder={lang.PasswordPlaceholder}
                  required={true}
                />
                <div className="row mt-4">
                  <div className="col-12">
                    <QuickfiscoButton
                      id="login-onboarding-login-btn"
                      label={lang.LoginLabel}
                      type={'senary'}
                      onClick={() =>
                        login(
                          setOnboardingStatus,
                          authCode.email,
                          authCode.password,
                          dispatch,
                          undefined,
                          crmUserId
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <OnboardingInitErrorModal open={open} setOpen={setOpen} />
    </>
  );
}

function login(
  setStatus: SetState<PromiseStatuses>,
  email: string,
  password: string,
  dispatch: Function,
  eventKey?: string,
  crmUserId?: string | null
): void {
  if (!crmUserId) {
    return;
  }

  if ((eventKey === 'Enter' || !eventKey) && email && password) {
    setStatus('loading');
    const registerService = new UserService();
    try {
      dispatch(
        doLogin({
          email,
          password,
        })
      ).then((res: { error?: Object }) => {
        if (res.error) {
          throw res.error;
        }
        registerService
          .onboardingInit(crmUserId)
          .then(() => {
            dispatch(getMe());
            setStatus('successfully');
          })
          .catch((err) => {
            setStatus('failed');
            console.error(err);
            setItem('onboardingInitError', 'true');
            dispatch(doLogout());
          });
      });
    } catch (err) {
      console.error(err);
    }
  }
}
