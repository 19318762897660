import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import atecoSlice from './slices/atecoSlice';
import autoInvoiceListSlice from './slices/autoInvoiceListSlice';
import autoInvoiceSaveOrDuplicateOrEditSlice from './slices/autoInvoiceSaveOrDuplicateOrEditSlice';
import autoInvoiceSlice from './slices/autoInvoiceSlice';
import customerInvoiceListSlice from './slices/customerInvoiceListSlice';
import customerInvoiceSaveOrDuplicateOrEditSlice from './slices/customerInvoiceSaveOrDuplicateOrEditSlice';
import customerInvoiceSlice from './slices/customerInvoiceSlice';
import customerListSlice from './slices/customerListSlice';
import documentsListSlice from './slices/documentsListSlice';
import feesRegisterSlice from './slices/feesRegisterSlice';
import financeSlice from './slices/financeSlice';
import loginSlice from './slices/loginSlice';
import messagesListSlice from './slices/messagesListSlice';
import newContactToInvoiceSlice from './slices/newContactToInvoiceSlice';
import occasionalJobInvoiceListSlice from './slices/occasionalJobInvoiceListSlice';
import operationStatusSlice from './slices/operationStatusSlice';
import proformaInvoiceListSlice from './slices/proformaInvoiceListSlice';
import proformaInvoiceSaveOrDuplicateOrEditSlice from './slices/proformaInvoiceSaveOrDuplicateOrEditSlice';
import proformaInvoiceSlice from './slices/proformaInvoiceSlice';
import stsInvoiceListSlice from './slices/stsInvoiceListSlice';
import stsInvoiceSaveOrDuplicateOrEditSlice from './slices/stsInvoiceSaveOrDuplicateOrEditSlice';
import stsInvoiceSlice from './slices/stsInvoiceSlice';
import stsProfileSlice from './slices/stsProfileSlice';
import supplierInvoiceListSlice from './slices/supplierInvoiceListSlice';
import supplierInvoiceSaveOrEditSlice from './slices/supplierInvoiceSaveOrEditSlice';
import supplierInvoiceSlice from './slices/supplierInvoiceSlice';
import supplierListSlice from './slices/supplierListSlice';
import taxCalculationSlice from './slices/taxCalculationSlice';
import taxProfileSlice from './slices/taxProfileSlice';
import totalRevenuesSlice from './slices/totalRevenuesSlice';
import userSlice from './slices/userSlice';
import onboardingSlice from './slices/onboardingSlice';
import paymentSlice from './slices/paymentSlice';


export const store = configureStore({
  reducer: {
    user: userSlice,
    login: loginSlice,
    onboarding: onboardingSlice,
    payment: paymentSlice,
    customerInvoice: customerInvoiceSlice,
    supplierInvoice: supplierInvoiceSlice,
    stsInvoice: stsInvoiceSlice,
    autoInvoice: autoInvoiceSlice,
    newContactToInvoice: newContactToInvoiceSlice,
    customerInvoiceSaveOrDuplicateOrEdit: customerInvoiceSaveOrDuplicateOrEditSlice,
    supplierInvoiceSaveOrEdit: supplierInvoiceSaveOrEditSlice,
    stsInvoiceSaveOrDuplicateOrEdit: stsInvoiceSaveOrDuplicateOrEditSlice,
    autoInvoiceSaveOrDuplicateOrEdit: autoInvoiceSaveOrDuplicateOrEditSlice,
    operationStatus: operationStatusSlice,
    customerInvoiceList: customerInvoiceListSlice,
    supplierInvoiceList: supplierInvoiceListSlice,
    documentsList: documentsListSlice,
    messagesList: messagesListSlice,
    customerList: customerListSlice,
    supplierList: supplierListSlice,
    stsProfile: stsProfileSlice,
    stsInvoiceList: stsInvoiceListSlice,
    autoInvoiceList: autoInvoiceListSlice,
    taxProfile: taxProfileSlice,
    taxCalculation: taxCalculationSlice,
    ateco: atecoSlice,
    feesRegister: feesRegisterSlice,
    proformaInvoice: proformaInvoiceSlice,
    proformaInvoiceList: proformaInvoiceListSlice,
    proformaInvoiceSaveOrDuplicateOrEdit: proformaInvoiceSaveOrDuplicateOrEditSlice,
    occasionalJobInvoiceList: occasionalJobInvoiceListSlice,
    totalRevenues: totalRevenuesSlice,
    finance: financeSlice
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
