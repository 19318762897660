import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaxDeadlineModel } from '../../models/taxDeadlineModel';
import { TaxForecastModel } from '../../models/taxForecastModel';
import { TaxCalculationService } from '../../services/taxCalculationService';
import { PromiseStatuses } from '../../types/strings';

export interface TaxCalculationStatus {
  forecast: TaxForecastModel,
  status: PromiseStatuses
  editStatus: PromiseStatuses
  editErrorMessage?: string,
  year: string
}

const initialState: TaxCalculationStatus = {
  forecast: {
    date: new Date(),
    deadlinesResponse: [],
    cashed: 0,
    taxes: 0,
    contribution: 0,
    revenues: 0,
    balance: 0,
    lastUpdate: new Date(),
    year: new Date().getFullYear()
  },
  status: 'idle',
  editStatus: 'idle',
  year: new Date().getFullYear().toString()
};

export const getTaxCalculation = createAsyncThunk(
  'taxCalculation/get',
  async (year: string): Promise<TaxForecastModel> => {
    return new TaxCalculationService().get(year);
  }
);

export const editTaxCalculation = createAsyncThunk(
  'taxCalculation/edit',
  async (request: TaxDeadlineModel): Promise<void> => {
    return new TaxCalculationService().editDeadline(request)
      .catch(err => err
        .json()
        .then((data: any) => Promise.reject(data.message))
      )
  }
);

const taxCalculationSlice = createSlice({
  name: 'taxCalculation',
  initialState,
  reducers: {
    setEditErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      state.editErrorMessage = action.payload;
    },
    setYear: (state, action: PayloadAction<string>) => {
      state.year = action.payload
    },
    setEditStatus: (state, action: PayloadAction<PromiseStatuses>) => {
      state.editStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxCalculation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTaxCalculation.fulfilled, (state, action) => {
        state.status = 'successfully';
        state.forecast = action.payload;
      })
      .addCase(getTaxCalculation.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(editTaxCalculation.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editTaxCalculation.fulfilled, (state) => {
        state.editStatus = 'successfully';
      })
      .addCase(editTaxCalculation.rejected, (state, action) => {
        state.editStatus = 'failed';
        if (action.error.message === 'deadline date is not valid') {
          state.editErrorMessage = "Attenzione! Puoi confermare questo importo solo a partire dal giorno indicato in scadenza"
        } else {
          state.editErrorMessage = "Errore durante la validazione dei dati."
        }
      });
  },
});

export const {
  setEditErrorMessage,
  setYear,
  setEditStatus
} = taxCalculationSlice.actions

export default taxCalculationSlice.reducer;
