import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import { InvoiceModel, ReimbursementOfExpenseModel } from '../../models/invoiceModel';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { Operation, PromiseStatuses } from '../../types/strings';

interface CustomerInvoiceSaveOrDuplicateOrEditState {
  invoiceId?: string;
  operation: Operation;
  successfullySaved: boolean;
  editStatus: PromiseStatuses
}

const initialState: CustomerInvoiceSaveOrDuplicateOrEditState = {
  operation: 'save',
  successfullySaved: false,
  editStatus: 'idle'
};

export const editCustomerInvoice = createAsyncThunk(
  'customerInvoiceSaveOrDuplicateOrEdit/editCustomerInvoice',
  async (request: { invoice: InvoiceModel, fileList: File[], reimbursementsOfExpenses: ReimbursementOfExpenseModel[], errorSetters: InvoiceErrorSetters }): Promise<void> => {
    const customerInvoiceService = new CustomerInvoiceService(request.errorSetters);

    return customerInvoiceService.edit(request.invoice, request.fileList, request.reimbursementsOfExpenses)
      .catch(err => {
        err
          .json()
          .then((data: any) => {
            if (data.message === 'error.invalid-date') {
              request.errorSetters.setErrorDateMessage && request.errorSetters.setErrorDateMessage('Esiste già una fattura con data successiva ma numero inferiore.')
              request.errorSetters.setErrorDate && request.errorSetters.setErrorDate(true)
            }
          })
      })
  }
)

const customerInvoiceSaveOrDuplicateOrEditSlice = createSlice({
  name: 'customerInvoiceSaveOrDuplicateOrEdit',
  initialState,
  reducers: {
    setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId: (state, action: PayloadAction<string | undefined>) => {
      state.invoiceId = action.payload
    },
    setCustomerInvoiceSaveOrDuplicateOrEditOperation: (state, action: PayloadAction<Operation>) => {
      state.operation = action.payload
    },
    setCustomerInvoiceSaveOrDuplicateOrEditSuccessfullySaved: (state, action: PayloadAction<boolean>) => {
      state.successfullySaved = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editCustomerInvoice.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editCustomerInvoice.fulfilled, (state) => {
        state.editStatus = 'successfully';
      })
      .addCase(editCustomerInvoice.rejected, (state) => {
        state.editStatus = 'failed';
      })
  }
});

export const {
  setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId,
  setCustomerInvoiceSaveOrDuplicateOrEditOperation,
  setCustomerInvoiceSaveOrDuplicateOrEditSuccessfullySaved
} = customerInvoiceSaveOrDuplicateOrEditSlice.actions;

export default customerInvoiceSaveOrDuplicateOrEditSlice.reducer;
