import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InvoiceModel, InvoiceStatusType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import uri from '../../routers/quickfiscoUri.json';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { SetState } from '../../types/functions';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { downloadPdf } from '../../utils/file';
import { CancelStsSending } from '../cancelStsSending/cancelStsSending';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './stsInvoiceMenu.css';
import lang from './stsInvoiceMenu.json';

import { ComponentVisibleOutput } from '../../hooks/componentVisible';
import { InvoiceFilters } from '../../models/invoiceFilters';
import { getCustomerInvoiceList } from '../../redux/slices/customerInvoiceListSlice';
import { getStsInvoiceList } from '../../redux/slices/stsInvoiceListSlice';
import { setStsInvoiceSaveOrDuplicateOrEditInvoiceId, setStsInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice';

interface Props {
  componentVisible: ComponentVisibleOutput;
  invoice: InvoiceModel;
  invoiceSign: InvoiceSign
}

export function StsInvoiceMenu(props: Props) {
  const [deletionStatus, setDeletionStatus] = useState<PromiseStatuses>('idle');
  const [downloadPdfStatus, setDownloadPdfStatus] = useState<PromiseStatuses>('idle');
  const [openCancelSendingModal, setOpenCancelSendingModal] = useState(false);

  const { invoice } = props;
  const { componentVisible } = props;
  const { invoiceSign } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)
  const stsInvoiceFilters: InvoiceFilters = {
    page: stsInvoiceListState.page,
    year: new Date().getFullYear().toString(),
    companyName: stsInvoiceListState.companyNameFilter,
    stsStatus: stsInvoiceListState.statusFilter
  }

  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const customerInvoiceFilters: InvoiceFilters = {
    page: customerInvoiceListState.page,
    month: customerInvoiceListState.monthFilter,
    year: customerInvoiceListState.yearFilter,
    type: customerInvoiceListState.typeFilter,
    companyName: customerInvoiceListState.companyNameFilter,
    status: customerInvoiceListState.statusFilter,
    isSts: true,
  }

  let invoiceFilters: InvoiceFilters;

  switch (invoiceSign) {
    case 'sts':
      invoiceFilters = stsInvoiceFilters;
      break;
    case 'customer':
      invoiceFilters = customerInvoiceFilters;
      break;
    default:
      return (
        <div>Tipo fattura non valido</div>
      )
  }

  let edit = () => {
    componentVisible.setIsVisible(false);
    dispatch(setStsInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
    dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('edit'))
    navigate(uri.StsInvoiceSave);
  };

  let duplicate = () => {
    componentVisible.setIsVisible(false);
    dispatch(setStsInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
    dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('duplicate'))
    navigate(uri.StsInvoiceSave);
  };

  let getPdf = () => {
    if (invoice.id) {
      const service = new StsInvoiceService();

      setDownloadPdfStatus('loading');
      service
        .getPdf(invoice.id)
        .then(data => {
          componentVisible.setIsVisible(false);
          setDownloadPdfStatus('idle');
          downloadPdf(StsInvoiceService.getPdfName('sts', undefined, invoice.number, invoice.customer?.companyName), data, 'application/pdf');
        })
        .catch(err => {
          setDownloadPdfStatus('failed');
          console.error(err);
        });
    }
  };

  const invoiceId = invoice.id

  if (invoiceId === undefined) {
    return (
      <div>Errore durante il caricamento dei dati</div>
    )
  }

  let editLabel: string;
  let editIcon: string;

  switch (invoice.status) {
    case InvoiceStatusType.DRAFT:
    case InvoiceStatusType.ALREADY_TRANSMITTED:
    case InvoiceStatusType.QUEUED:
    case InvoiceStatusType.QUEUED_NACK:
    case InvoiceStatusType.REJECTED:
    case InvoiceStatusType.COMMUNICATE_CHANGE:
    case InvoiceStatusType.CANCEL_SENDING:
    case InvoiceStatusType.CANCEL_SENDING_NACK:
    case InvoiceStatusType.CANCEL_SENDING_ACK:
      editLabel = 'Scheda fattura';
      editIcon = 'find.svg';
      break;
    case InvoiceStatusType.ACCEPTED:
    case InvoiceStatusType.COMMUNICATE_CHANGE_NACK:
    case InvoiceStatusType.REJECTED_BUT_CREATED:
      editLabel = 'Comunica variazione';
      editIcon = 'variation.svg';
      break;
    default:
      return (
        <div>Tipo fattura non valido</div>
      )
  }

  return (
    <div>
      {
        componentVisible.isVisible && (
          <div className={'invoiceMenu-container'}>
            <QuickfiscoError
              message={lang.DeletionError}
              active={deletionStatus === 'failed'}
              close={() => setDeletionStatus('idle')}
            />
            <QuickfiscoError
              message={lang.DownloadPdfError}
              active={downloadPdfStatus === 'failed'}
              close={() => setDownloadPdfStatus('idle')}
            />
            <div>
              <div
                className={'invoiceMenu-item'}
                onClick={() => edit()}
              >
                <div className={'invoiceMenu-icon'}>
                  <QuickfiscoIcon name={editIcon} />
                </div>
                {editLabel}
              </div>
              <div className={'invoiceMenu-line mt-2 mb-2'} />
            </div>
            {
              (
                invoice.status === InvoiceStatusType.ACCEPTED ||
                invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK ||
                invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED
              ) && (
                <div>
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => setOpenCancelSendingModal(true)}
                  >
                    <div className={'invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'cancel.svg'} />
                    </div>
                    {lang.cancel}
                  </div>
                  <div className={'invoiceMenu-line mt-2 mb-2'} />
                </div>
              )
            }
            {
              downloadPdfStatus === 'loading' ? (
                <div className={'w-100 d-flex justify-content-center align-items-center'}>
                  <QuickfiscoSpinner alignCenter={true} />
                </div>
              ) : (
                <div
                  className={'invoiceMenu-item'}
                  onClick={() => getPdf()}
                >
                  <div className={'invoiceMenu-icon'}>
                    <QuickfiscoIcon name={'download.svg'} />
                  </div>
                  {lang.getPdf}
                </div>
              )
            }
            <div className={'invoiceMenu-line mt-2 mb-2'} />
            <div>
              <div
                className={'invoiceMenu-item'}
                onClick={() => duplicate()}
              >
                <div className={'invoiceMenu-icon'}>
                  <QuickfiscoIcon name={'duplicate.svg'} />
                </div>
                {lang.duplicate}
              </div>
            </div>
            {
              (
                invoice.status === InvoiceStatusType.DRAFT ||
                invoice.status === InvoiceStatusType.ALREADY_TRANSMITTED ||
                invoice.status === InvoiceStatusType.QUEUED_NACK ||
                invoice.status === InvoiceStatusType.REJECTED ||
                invoice.status === InvoiceStatusType.CANCEL_SENDING_ACK
              ) && (
                deletionStatus === 'loading' ? (
                  <div className={'w-100 d-flex justify-content-center align-items-center'}>
                    <QuickfiscoSpinner alignCenter={true} />
                  </div>
                ) : (
                  <div>
                    <div className={'invoiceMenu-line mt-2 mb-2'} />
                    <div
                      className={'invoiceMenu-item'}
                      onClick={() => del(
                        invoiceId,
                        componentVisible,
                        dispatch,
                        invoiceFilters,
                        invoiceSign,
                        setDeletionStatus,
                        invoice.documents
                      )}
                    >
                      <div className={'invoiceMenu-icon'}>
                        <QuickfiscoIcon name={'delete-icon.svg'} />
                      </div>
                      {lang.delete}
                    </div>
                  </div>
                )
              )
            }
          </div>
        )
      }
      <CancelStsSending
        open={openCancelSendingModal}
        setOpen={(_open) => setOpenCancelSendingModal(_open)}
        invoiceId={invoiceId}
      />
    </div>
  );
}

function del(
  invoiceId: string,
  componentVisible: ComponentVisibleOutput,
  dispatch: Function,
  invoiceFilters: InvoiceFilters,
  invoiceSign: InvoiceSign,
  setStatus: SetState<PromiseStatuses>,
  documents?: string[] | null,
) {
  const service = new StsInvoiceService();

  setStatus('loading');
  service
    .del(invoiceId, documents, [])
    .then(() => {
      componentVisible.setIsVisible(false);
      setStatus('successfully');

      if (invoiceSign === 'sts') {
        dispatch(getStsInvoiceList(invoiceFilters))
      } else {
        dispatch(getCustomerInvoiceList(invoiceFilters))
      }
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}
