import { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './checkoutStripeForm.json';
import './checkoutStripeForm.css';

interface Props {
  onPayClick: Function;
}

export function CheckoutStripeForm(props: Props) {
  const { onPayClick } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: 'if_required',
    });

    if (result.error) {
      if (
        result.error.type === 'card_error' ||
        result.error.type === 'validation_error'
      ) {
        result.error.type === 'card_error' &&
          result.error.message &&
          setMessage(result.error.message);
      } else {
        setMessage(lang.genericError);
      }
      setIsLoading(false);
      return;
    }

    const paymentIntent = result.paymentIntent;

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      onPayClick();
    } else {
      setMessage(lang.genericError);
      setIsLoading(false);
    }
  };

  return (
    <form id="checkout-stripe-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="checkout-stripe-form-payment-element"
        onChange={() => setMessage('')}
      />
      <div className={'row mt-5'}>
        <div className="col-6 offset-3">
          {isLoading ? (
            <div
              className={
                'w-100 d-flex justify-content-center align-items-center'
              }
            >
              <QuickfiscoSpinner />
            </div>
          ) : (
            <QuickfiscoButton
              id={'checkout-stripe-form-submit-button'}
              label={lang.ctaButton}
              type={isLoading || !stripe || !elements ? 'disabled' : 'senary'}
            />
          )}
        </div>
      </div>
      {message && (
        <div className="checkout-stripe-form-payment-message">{message}</div>
      )}
    </form>
  );
}
