import { useAppSelector } from '../../redux/hooks';
import config from '../../../config.json';
import { TotalRevenuesModel } from '../../models/totalRevenuesModel';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { PromiseStatuses } from '../../types/strings';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TotalRevenuesAteco } from '../totalRevenuesAteco/totalRevenuesAteco';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { differenceInCalendarDays } from 'date-fns';
import { ExceedingThresholdsModal } from '../exceedingThresholdsModal/exceedingThresholdsModal';
import { RegimeLimitStatusType } from '../../models/userModel';
import lang from './totalRevenues.json';
import './totalRevenues.css';

interface Props {
  status: PromiseStatuses;
  stsStatus?: PromiseStatuses;
  feesStatus?: PromiseStatuses;
  revenues?: TotalRevenuesModel;
  stsRevenues?: TotalRevenuesModel;
  feesRevenues?: TotalRevenuesModel;
  year: number;
}

export function TotalRevenues(props: Props) {
  const { status } = props;
  const { stsStatus } = props;
  const { feesStatus } = props;
  const { revenues } = props;
  const { stsRevenues } = props;
  const { feesRevenues } = props;
  const { year } = props;

  const userState = useAppSelector((state) => state.user);
  const user = userState.user;

  const vatOpenCurrentYearFlag =
    new Date().getFullYear() ===
    (user.vatOpeningDate ? new Date(user.vatOpeningDate).getFullYear() : false);

  if (
    status === 'loading' ||
    stsStatus === 'loading' ||
    feesStatus === 'loading'
  ) {
    return (
      <div
        className={
          'total-revenues-container-white box-shadow p-4 d-flex justify-content-center align-items-center'
        }
      >
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (!revenues || !stsRevenues || !feesRevenues) {
    return (
      <div className={'d-flex justify-content-center align-items-center'}>
        Errore durante il caricamento dei dati.
      </div>
    );
  }

  const mergedRevenues = CommonInvoiceService.mergeTotalRevenues(
    revenues,
    stsRevenues,
    feesRevenues
  );
  const totalInvoicesPaid: number =
    CommonInvoiceService.calculateTotalPaid(mergedRevenues).totalPaid;
  const totalInvoicesPaidLastYear: number =
    CommonInvoiceService.calculateTotalPaid(mergedRevenues).totalPaidLastYear;
  const totalAmountCurrentYear =
    (85000 / 365) *
      (user.vatOpeningDate
        ? differenceInCalendarDays(
            new Date(new Date().getFullYear(), 11, 31),
            new Date(user.vatOpeningDate)
          )
        : 365) -
    (totalInvoicesPaid + totalInvoicesPaidLastYear);
  const progress =
    ((totalInvoicesPaid + totalInvoicesPaidLastYear) * 100) /
    (vatOpenCurrentYearFlag
      ? (85000 / 365) *
        (user.vatOpeningDate
          ? differenceInCalendarDays(
              new Date(new Date().getFullYear(), 11, 31),
              new Date(user.vatOpeningDate)
            )
          : 365)
      : 85000);

  return (
    <div className="total-revenues-container box-shadow p-4">
      {user.regimeLimit?.status === RegimeLimitStatusType.OVER85k && (
        <div className="row align-items-center total-revenues-exceeding-thresholds-100k mb-3">
          <div className="col-12">
            {lang.exceeding_thresholds_100k_text}
            <b>
              {lang.exceeding_thresholds_100k_text_bold}{' '}
              {new Date().getFullYear()}
            </b>
          </div>
        </div>
      )}
      <div className="row align-items-center total-revenues-title">
        <div className="col-8">
          <span className="pe-2">{lang.Title + year}</span>
        </div>
        <div className="col-4 text-end">
          {mergedRevenues.totalInvoices !== undefined &&
            formatNumberIT(mergedRevenues.totalInvoices)}
          {config.EUR}
        </div>
      </div>
      <div className="row mt-2 align-items-center">
        <div className="col-8 d-flex total-revenues-subtitle">
          <span className="pe-2">{lang.Subtitle + year}</span>
          <TotalRevenuesAteco
            totalInvoicesPaid={mergedRevenues.totalInvoicesPaid}
          />
        </div>
        <div className="col-4 total-revenues-total-invoices">
          {mergedRevenues.totalInvoices !== undefined &&
            formatNumberIT(totalInvoicesPaid + totalInvoicesPaidLastYear)}
          {config.EUR}
        </div>
      </div>
      <div className={'row mt-4'}>
        <div className={'col-12'}>
          <div className={'total-revenues-total-bar'}>
            <div
              className={'total-revenues-progress-bar'}
              style={
                progress === 0
                  ? {
                      background: '#EFEBFD',
                      width: '15px',
                    }
                  : {
                      background: '#FFB810',
                      width: `${progress}%`,
                    }
              }
            />
          </div>
        </div>
      </div>
      <div className={'row no-gutters mt-3'}>
        <div className="col-12 no-gutters total-revenues-total-remaining">
          {lang.Remaining}{' '}
          {totalAmountCurrentYear < 0 ||
          totalInvoicesPaid + totalInvoicesPaidLastYear > 85000
            ? 0
            : vatOpenCurrentYearFlag
            ? formatNumberIT(totalAmountCurrentYear) + config.EUR
            : mergedRevenues.totalRemaining !== undefined
            ? formatNumberIT(mergedRevenues.totalRemaining) + config.EUR
            : undefined}
        </div>
      </div>
      <div className={'row mt-4'}>
        <div className="col-6">
          <div className="d-flex flex-column justify-content-between total-revenues-box-container-first ">
            <div>{lang.TotalCashed + year}</div>
            <div className="total-revenues-box-number mt-4">
              {formatNumberIT(totalInvoicesPaid)}
              {config.EUR}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex flex-column justify-content-between total-revenues-box-container-last">
            <div>{lang.TotalCashedLastYear}</div>
            <div className="total-revenues-box-number mt-4">
              {formatNumberIT(totalInvoicesPaidLastYear)}
              {config.EUR}
            </div>
          </div>
        </div>
      </div>
      <div className={'row mt-2'}>
        <div className="col-12 total-revenues-info d-flex align-items-center">
          <span>
            <QuickfiscoIcon name={'warning-small.svg'} /> {lang.Info}
          </span>
        </div>
      </div>
      {((vatOpenCurrentYearFlag
        ? totalAmountCurrentYear < 0 && !user.regimeLimit
        : totalInvoicesPaid + totalInvoicesPaidLastYear > 85000 &&
          !user.regimeLimit) ||
        user.regimeLimit?.status === RegimeLimitStatusType.PENDING) && (
        <ExceedingThresholdsModal
          type={'85k'}
          isOpen={
            (vatOpenCurrentYearFlag
              ? totalAmountCurrentYear < 0 && !user.regimeLimit
              : totalInvoicesPaid + totalInvoicesPaidLastYear > 85000 &&
                !user.regimeLimit) ||
            user.regimeLimit?.status === RegimeLimitStatusType.PENDING
          }
        />
      )}
    </div>
  );
}
