import { SingleValue } from 'react-select';
import { ClientResidence } from '../../models/contactModel';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
    id: string;
    value?: ClientResidence;
    error?: boolean;
    errorLabel?: string;
    onChange?: ((esidence: ClientResidence | undefined) => void);
    required?: boolean;
}

export function ClientResidenceSelect(props: Props) {
    const { onChange } = props;
    const { value } = props;
    const { id } = props;
    const { error } = props;
    const { errorLabel } = props;
    const { required } = props;

    return (
        <QuickfiscoSelect
            id={id}
            label={'Residenza committente'}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {
                    // @ts-ignore
                    onChange && onChange(ClientResidence[e.value])
                }
            }}
            options={getOptions(value)}
            styleType={'default'}
            error={error}
            requiredField={required}
            errorLabel={errorLabel}
            value={getOptions(value).filter(option => option.selected === true)[0]}
        />
    );
}

function getOptions(value?: ClientResidence): OptionModel[] {
    return [
        {
            label: 'Seleziona residenza',
            value: undefined,
            selected: value === undefined,
        },
        {
            label: 'EXTRA-EU',
            value: ClientResidence.EXTRA_EU,
            selected: value === ClientResidence.EXTRA_EU,
        },
        {
            label: 'EU',
            value: ClientResidence.EU,
            selected: value === ClientResidence.EU
        },
    ];
}
