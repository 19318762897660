import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import useComponentVisible from '../../hooks/componentVisible';
import React from 'react';
import { formatNumberIT } from '../../utils/number';
import config from '../../../config.json';
import { useAppSelector } from '../../redux/hooks';
import { TotalInvoicePaidMap } from '../../models/totalRevenuesModel';
import lang from './totalRevenuesAteco.json';
import './totalRevenuesAteco.css';

interface Props {
  totalInvoicesPaid?: TotalInvoicePaidMap;
}

export function TotalRevenuesAteco(props: Props) {
  const { totalInvoicesPaid } = props;

  const userAtecos = useAppSelector((state) => state.user.user)?.atecos;

  const componentVisible = useComponentVisible(false);

  const totalInvoicesPaidGroupedByAteco: React.ReactNode[] = [];

  if (
    userAtecos === undefined ||
    totalInvoicesPaid === undefined ||
    totalInvoicesPaid.size < 1
  ) {
    return <></>;
  }

  userAtecos.forEach((value, index) => {
    let label = lang.first;

    if (index === 1) {
      label = lang.second;
    } else if (index === 2) {
      label = lang.third;
    }

    const amount = totalInvoicesPaid[value];

    totalInvoicesPaidGroupedByAteco.push(
      <div key={label} className={'row mt-2'}>
        <div className={'col-12'}>
          <div className={'total-revenues-ateco-text'}>{label}</div>
          <div className={'total-revenues-ateco-text'}>
            {!amount ? (
              <span>-</span>
            ) : (
              <b>
                {formatNumberIT(amount)}
                {config.EUR}
              </b>
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} className={'d-flex'} />
        </div>
        <div
          ref={componentVisible.ref}
          className={'total-revenues-ateco-popup'}
        >
          {componentVisible.isVisible && (
            <div className={'total-revenues-ateco-popup-container'}>
              {totalInvoicesPaidGroupedByAteco}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
