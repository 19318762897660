import { formatDateITWithHour } from '../../utils/date';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './taxReturnStatusBox.json';
import './taxReturnStatusBox.css';

interface Props {
  type: 'incomeForm' | 'taxReturn';
  isCompleted: boolean;
  onClick: () => void;
  completedAt?: Date;
}

export function TaxReturnStatusBox(props: Props) {
  const { type } = props;
  const { isCompleted } = props;
  const { onClick } = props;
  const { completedAt } = props;

  return (
    <div className="row mt-3 mb-5">
      <div className="col-12">
        <div
          className={`${
            isCompleted
              ? 'tax-return-status-box-container-completed'
              : 'tax-return-status-box-container-not-completed'
          } p-3`}
        >
          <div className="row d-flex">
            <div className="col-12 tax-return-status-box-title">
              {isCompleted ? (
                <>
                  <QuickfiscoIcon
                    name={'status-completed.svg'}
                    className="pe-2"
                  />
                  {lang[type].titleCompleted}
                </>
              ) : (
                <>
                  <QuickfiscoIcon
                    name={'status-not-completed.svg'}
                    className="pe-2"
                  />
                  {lang[type].titleNotCompleted}
                </>
              )}
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-7 d-flex align-items-center tax-return-status-box-description">
              {isCompleted ? (
                <>
                  {lang[type].descriptionCompleted}
                  <br />
                  {completedAt && formatDateITWithHour(completedAt)}
                </>
              ) : (
                lang[type].descriptionNotCompleted
              )}
            </div>
            <div className="col-5">
              {!(isCompleted && type === 'incomeForm') && (
                <QuickfiscoButton
                  id={'tax-return-status-box-cta'}
                  label={
                    isCompleted ? lang.buttonCompleted : lang.buttonNotCompleted
                  }
                  type={'primary'}
                  onClick={() => onClick()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
