import { format } from 'date-fns';
import config from '../../../config.json';
import {
  FinanceOutcomeEnum,
  FinanceStatusEnum,
} from '../../models/financeModel';
import { useAppSelector } from '../../redux/hooks';
import { ProgressStep } from '../progressStep/progressStep';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoLink } from '../quickfiscoLink/quickfiscoLink';
import './financeStatus.css';

export function FinanceStatus() {
  const financeState = useAppSelector((state) => state.finance);

  const stepMap = new Map<FinanceStatusEnum, number>([
    [FinanceStatusEnum.sent, 0],
    [FinanceStatusEnum.ongoing, 1],
    [FinanceStatusEnum.responseWaiting, 2],
    [FinanceStatusEnum.done, 3],
  ]);

  const outcomeLabelMap = new Map<FinanceOutcomeEnum, string>([
    [FinanceOutcomeEnum.approved, 'Finanziamento approvato'],
    [FinanceOutcomeEnum.rejected, 'Finanziamento rifiutato'],
  ]);

  return (
    <div className={'financeStatus-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 35,
          maxWidth: '50%',
        }}
      >
        <QuickfiscoIcon
          name={'check-circle.svg'}
          className="financeStatus-icons"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              textAlign: 'center',
              font: 'normal normal bold 17px/30px Poppins',
              color: '#1E1E23',
            }}
          >
            Pratica inviata!
          </span>
          <span className="financeStatus-text">
            Monitora da qui lo stato di avanzamento:
          </span>
        </div>
      </div>
      <div style={{ width: '80%' }}>
        <ProgressStep
          currentStep={
            stepMap.get(
              financeState.userFinance?.status || FinanceStatusEnum.sent
            ) || 0
          }
          numberOfSteps={4}
          labels={[
            'Richiesta inviata',
            'Pratica in lavorazione',
            'In attesa esito banca',
            financeState.userFinance?.status !== FinanceStatusEnum.done
              ? 'Esito finanziamento'
              : outcomeLabelMap.get(
                  financeState.userFinance?.outcome ||
                    FinanceOutcomeEnum.rejected
                ),
          ]}
          infoText={[
            'il ' +
              format(
                new Date(financeState.userFinance?.created_at || new Date()),
                'dd/LL/yy'
              ) +
              ' alle ore ' +
              format(
                new Date(financeState.userFinance?.created_at || new Date()),
                'HH.mm'
              ),
          ]}
        />
      </div>
      <span className="financeStatus-faire" style={{ width: '100%' }}>
        Ci vorranno circa 7 giorni lavorativi per l’esito.
        <br />
        Ti contatterà
        <span className="financeStatus-faire-blue">
          {' '}
          <b>fai credit</b>{' '}
        </span>
        dal numero
        <span className="financeStatus-faire-blue"> 02 *****177 </span>
        per eventuali verifiche.
      </span>
      <span className="financeStatus-faire mb-5" style={{ width: '100%' }}>
        Per domande scrivici a{' '}
        <QuickfiscoLink
          label=" finanziamenti@quickfisco.it"
          id={'faire-link'}
          onClick={() => {
            window.open(
              'mailto:finanziamenti@quickfisco.it',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        />
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
    </div>
  );
}
