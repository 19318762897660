import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './stsInvoiceRejected.css';

interface Props {
  error?: string | null;
}

export function StsInvoiceRejected(props: Props) {
  const { error } = props;

  const showPopup = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => showPopup.setIsVisible(true)}
          onMouseOut={() => showPopup.setIsVisible(false)}
        >
          <QuickfiscoIcon
            name={'info.svg'}
          />
        </div>
        <div
          className={'sts-invoice-rejected-popup'}
          ref={showPopup.ref}
        >
          {
            showPopup.isVisible && (
              <div className={'sts-invoice-rejected-popup-container'}>
                <span className={'sts-invoice-rejected-text'}>
                  {error}
                </span>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
