import { useAppSelector } from '../../redux/hooks';
import { delItem } from '../../utils/localStorage';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import './onboardingInitErrorModal.css';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

export function OnboardingInitErrorModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const user = useAppSelector((state) => state.onboarding.user);

  return (
    <QuickfiscoModal
      modalClassName={'onboarding-init-error-modal-component'}
      isOpen={open}
      hide={() => {
        delItem('onboardingInitError');
        setOpen(false);
      }}
      size="sm"
    >
      <div className="onboarding-init-error-modal-container pb-5">
        <QuickfiscoIcon name={'alert-yellow.svg'} />
        <div className="onboarding-init-error-modal-title mt-4">
          Ehi {user.name}!
        </div>
        <div className="onboarding-init-error-modal-text mt-2">
          Mancano alcuni dati all’interno della tua scheda,
          <br />
          <b>se visualizzi questo messaggio scrivici subito</b>
          <br />
          così posso aggiornare i dati e sbloccarti
          <br />
          l’attivazione.
        </div>
      </div>
    </QuickfiscoModal>
  );
}
