import { InvoiceStatusType } from '../../models/invoiceModel';
import { InvoiceSign } from '../../types/strings';
import './invoiceStatus.css';
import lang from './invoiceStatus.json';

interface Props {
  status?: InvoiceStatusType;
  previousStatus?: InvoiceStatusType;
  invoiceSign?: InvoiceSign;
}

export function InvoiceStatus(props: Props) {
  const { status } = props;
  const { previousStatus } = props;
  const { invoiceSign } = props;

  let stline;
  let sColor;

  let invoiceStatus;

  if ((
    status === InvoiceStatusType.PAID ||
    status === InvoiceStatusType.REVERSED
  ) && invoiceSign !== 'supplier' && invoiceSign !== 'occasionalJob'
  ) {
    invoiceStatus = previousStatus;
  } else {
    invoiceStatus = status;
  }

  if (invoiceStatus) {
    if (invoiceSign === 'sts') {
      stline = stsStatusMap.get(invoiceStatus)?.stline;
      sColor = stsStatusMap.get(invoiceStatus)?.sColor;
    } else if (invoiceSign === 'proforma'){
      stline = proformaStatusMap.get(invoiceStatus)?.stline;
      sColor = proformaStatusMap.get(invoiceStatus)?.sColor;
    } else if (invoiceSign === 'occasionalJob'){
      stline = occasionalJobStatusMap.get(invoiceStatus)?.stline;
      sColor = occasionalJobStatusMap.get(invoiceStatus)?.sColor;
    } else {
      stline = statusMap.get(invoiceStatus)?.stline;
      sColor = statusMap.get(invoiceStatus)?.sColor;
    }
  }

  const stColor = 'invoice-status-info ' + sColor;

  return (
    <div className={stColor}>{stline}</div>
  );
}

export const stsStatusMap: Map<InvoiceStatusType, { sColor: string, stline: string }> = new Map([
  [InvoiceStatusType.DRAFT, {
    sColor: 'invoice-status-to-send-sts',
    stline: lang.draftSTS,
  }],
  [InvoiceStatusType.ALREADY_TRANSMITTED, {
    sColor: 'invoice-status-paid',
    stline: 'Già Trasmessa'
  }],
  [InvoiceStatusType.QUEUED, {
    sColor: 'invoice-status-to-pay',
    stline: lang.queuedSTS,
  }],
  [InvoiceStatusType.QUEUED_NACK, {
    sColor: 'invoice-status-expired',
    stline: lang.rejectedSTS,
  }],
  [InvoiceStatusType.REJECTED, {
    sColor: 'invoice-status-expired',
    stline: lang.rejectedSTS,
  }],
  [InvoiceStatusType.ACCEPTED, {
    sColor: 'invoice-status-paid',
    stline: lang.sentSTS,
  }],
  [InvoiceStatusType.COMMUNICATE_CHANGE, {
    sColor: 'invoice-status-to-pay',
    stline: lang.queuedSTS,
  }],
  [InvoiceStatusType.COMMUNICATE_CHANGE_NACK, {
    sColor: 'invoice-status-expired',
    stline: 'Com. var. fallita',
  }],
  [InvoiceStatusType.CANCEL_SENDING, {
    sColor: 'invoice-status-to-pay',
    stline: lang.queuedSTS,
  }],
  [InvoiceStatusType.CANCEL_SENDING_ACK, {
    sColor: 'invoice-status-expired',
    stline: lang.deleted,
  }],
  [InvoiceStatusType.CANCEL_SENDING_NACK, {
    sColor: 'invoice-status-expired',
    stline: lang.cancelNack,
  }],
  [InvoiceStatusType.REJECTED_BUT_CREATED, {
    sColor: 'invoice-status-expired',
    stline: lang.rejectedSTS,
  }],
]);

export const statusMap: Map<InvoiceStatusType, { sColor: string, stline: string }> = new Map([
  [InvoiceStatusType.DRAFT,
  {
    sColor: 'invoice-status-to-pay',
    stline: lang.draft,
  }],
  [InvoiceStatusType.QUEUED,
  {
    sColor: 'invoice-status-to-pay',
    stline: lang.pending,
  }],
  [InvoiceStatusType.QUEUE_ACK,
  {
    sColor: 'invoice-status-to-pay',
    stline: lang.pending,
  }],
  [InvoiceStatusType.ACCEPTED,
  {
    sColor: 'invoice-status-paid',
    stline: lang.accepted,
  }],
  [InvoiceStatusType.DELIVERED,
  {
    sColor: 'invoice-status-paid',
    stline: lang.delivered,
  }],
  [InvoiceStatusType.REJECTED,
  {
    sColor: 'invoice-status-expired',
    stline: lang.rejected,
  }],
  [InvoiceStatusType.NOT_RECEIVED,
  {
    sColor: 'invoice-status-paid',
    stline: lang.received,
  }],
  [InvoiceStatusType.DUE_TERMS,
  {
    sColor: 'invoice-status-to-pay',
    stline: lang.dueTerms,
  }],
  [InvoiceStatusType.RECEIVED,
  {
    sColor: 'invoice-status-to-pay',
    stline: lang.toBePaid,
  }],
  [InvoiceStatusType.PAYMENT_DUE,
  {
    sColor: 'invoice-status-expired',
    stline: lang.expired,
  }],
  [InvoiceStatusType.PAID,
  {
    sColor: 'invoice-status-paid',
    stline: lang.paid,
  }],
  [InvoiceStatusType.REVERSED,
  {
    sColor: 'invoice-status-expired',
    stline: lang.reversed,
  }],
  [InvoiceStatusType.QUEUE_NACK,
  {
    sColor: 'invoice-status-expired',
    stline: lang.rejected,
  }],
  [InvoiceStatusType.ALREADY_TRANSMITTED,
  {
    sColor: 'invoice-status-paid',
    stline: 'Già Trasmessa'
  }]
]);

export const proformaStatusMap: Map<InvoiceStatusType, { sColor: string, stline: string }> = new Map([
  [InvoiceStatusType.DRAFT, {
    sColor: 'invoice-status-to-pay',
    stline: "Pronta per l'invio",
  }],
  [InvoiceStatusType.SENT, {
    sColor: 'invoice-status-paid',
    stline: 'E-mail inviata'
  }],
]);

export const occasionalJobStatusMap: Map<InvoiceStatusType, { sColor: string, stline: string }> = new Map([
  [InvoiceStatusType.DRAFT, {
    sColor: 'invoice-status-to-pay',
    stline: "Pronta per l'invio",
  }],
  [InvoiceStatusType.SENT, {
    sColor: 'invoice-status-paid',
    stline: 'E-mail inviata'
  }],
  [InvoiceStatusType.REVERSED, {
      sColor: 'invoice-status-expired',
      stline: lang.reversed,
  }],
]);
