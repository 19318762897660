import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getMe,
  setProfileNotSavedModalIsOpen,
  updateMe,
} from '../../redux/slices/userSlice';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './notSavedProfileModal.css';
import { UserService } from '../../services/userService';

export function NotSavedProfileModal() {
  const [isError, setIsError] = useState<boolean>(false);

  const userState = useAppSelector((state) => state.user);

  const editStatus = userState.editStatus;
  const editUserRequest = userState.editUserRequest;
  const profileNotSavedModalIsOpen = userState.profileNotSavedModalIsOpen;

  const dispatch = useAppDispatch();

  const userService = new UserService(dispatch);

  useEffect(() => {
    if (editStatus === 'successfully' && profileNotSavedModalIsOpen) {
      dispatch(getMe());
      dispatch(setProfileNotSavedModalIsOpen(false));
    }
  }, [editStatus]);

  return (
    <QuickfiscoModal
      hide={() => {
        dispatch(setProfileNotSavedModalIsOpen(false));
        setIsError(false);
      }}
      isOpen={profileNotSavedModalIsOpen}
    >
      {editStatus === 'loading' ? (
        <div className="row justify-content-center align-items-center">
          <div className="col-auto">
            <QuickfiscoSpinner />
          </div>
        </div>
      ) : (
        <div className="p-4 pt-0">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <p className="not-saved-profile-modal-text">
                    <span className="fw-normal">
                      Hai aggiornato il tuo profilo ma{' '}
                    </span>
                    non hai salvato le modifiche.
                  </p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <p className="not-saved-profile-modal-text fw-normal">
                    Vuoi salvarle?
                  </p>
                </div>
              </div>
              {!isError && (
                <div className="row justify-content-center mt-1">
                  <div className="col-4">
                    <QuickfiscoButton
                      id={'not-saved-profile-modal-continue'}
                      label={'NO, NON SALVARLE'}
                      type={'primary'}
                      onClick={() => {
                        dispatch(getMe());
                        dispatch(setProfileNotSavedModalIsOpen(false));
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <QuickfiscoButton
                      id={'not-saved-profile-modal-continue'}
                      label={'SALVA'}
                      type={'secondary'}
                      onClick={async () => {
                        if (
                          await userService.validate(editUserRequest, {
                            skipConfirmPassword: true,
                            skipPassword: true,
                            skipEmailAvailable: true,
                          })
                        ) {
                          dispatch(updateMe(editUserRequest));
                        } else {
                          setIsError(true);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {isError && (
                <div className="row justify-content-center mt-4 text-center">
                  <div className="col-6 not-saved-profile-modal-error">
                    Sono presenti alcuni errori nei form.
                    <br />
                    Chiudi la modale con la (X) in alto a destra.
                    <br />
                    Correggili e prova a salvare nuovamente.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </QuickfiscoModal>
  );
}
