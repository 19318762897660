import { isValid as isDateValid } from 'date-fns';
import { PaymentErrorSetters } from '../models/paymentErrorSetters';
import { InvoicePaymentModel } from '../models/invoicePaymentModel';
import { TotalPaidModel } from '../models/totalPaidModel';
import { InvoiceSign, PaymentType } from '../types/strings';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class InvoicePaymentService {

  private readonly url = host + '/api/v1/invoices';

  private readonly http: Http;
  private readonly errorSetters?: PaymentErrorSetters;

  public constructor(errorSetters?: PaymentErrorSetters) {
    this.http = Http.getInstance();
    this.errorSetters = errorSetters;
  }

  public findAll(type: InvoiceSign, invoiceId: string): Promise<InvoicePaymentModel[]> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + type.toString() + '/' + invoiceId + '/payments'
    })
      .then(res => res.json())
      .then((data: InvoicePaymentModel[]) => data);
  }

  public findById(type: InvoiceSign, invoiceId: string, paymentId: string): Promise<InvoicePaymentModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + type.toString() + '/' + invoiceId + '/payments/' + paymentId
    })
      .then(res => res.json())
      .then((data: InvoicePaymentModel) => data);
  }

  public save(type: InvoiceSign, invoiceId: string, payment: InvoicePaymentModel): Promise<InvoicePaymentModel> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + type + '/' + invoiceId + '/payments',
      body: JSON.stringify(payment),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: InvoicePaymentModel) => data);
  }

  public edit(type: InvoiceSign, invoiceId: string, payment: InvoicePaymentModel, paymentId: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + '/' + type.toString() + '/' + invoiceId + '/payments/' + paymentId,
      body: JSON.stringify(payment),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public del(type: InvoiceSign, invoiceId: string, paymentId: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + '/' + type.toString() + '/' + invoiceId + '/payments/' + paymentId,
    })
      .then(() => undefined);
  }

  public getTotalPaid(type: InvoiceSign, invoiceId: string): Promise<TotalPaidModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + type.toString() + '/' + invoiceId + '/payments/total'
    })
      .then(res => res.json())
      .then((data: TotalPaidModel) => data);
  }

  public validateDate(date?: Date): boolean {
    this.errorSetters?.setErrorDate(false);

    if (!date || !isDateValid(date)) {
      this.errorSetters?.setErrorDateMessage && this.errorSetters?.setErrorDateMessage('Inserire una data di emissione');
      this.errorSetters?.setErrorDate(true);
      return false;
    }

    return true;
  }

  public validateAmount(paymentType: PaymentType, leftAmount: number, amount?: number): boolean {
    this.errorSetters?.setErrorAmount(false);

    if (paymentType === 'partial' && (!amount || amount > leftAmount)) {
      this.errorSetters?.setErrorAmount(true);
      return false;
    }

    return true;
  }

  public validate(payment: InvoicePaymentModel, paymentType: PaymentType, leftAmount: number): boolean {
    let isValid = true;

    if (!this.validateDate(payment.receiptDate)) {
      isValid = false;
    }
    if (!this.validateAmount(paymentType, leftAmount, payment.amount)) {
      isValid = false;
    }

    return isValid;
  }

}
