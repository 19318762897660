import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './contributionsPaidFundSelect.json';

interface Props {
  id: string;
  value?: number;
  error?: boolean;
  requiredField?: boolean;
  onChange?: (tax: number) => void;
}

interface TaxationModel {
  desc: string;
  taxationValue: number;
}

export function ContributionsPaidFundSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { requiredField } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.label}
      onChange={(e) => e && onChange && onChange(Number(e.value))}
      options={getOptions(value)}
      requiredField={requiredField}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      styleType={'default'}
    />
  );
}

export const taxationList: TaxationModel[] = [
  {
    desc: '5%',
    taxationValue: 0.05,
  },
  {
    desc: '6%',
    taxationValue: 0.06,
  },
  {
    desc: '7%',
    taxationValue: 0.07,
  },
  {
    desc: '8%',
    taxationValue: 0.08,
  },
  {
    desc: '9%',
    taxationValue: 0.09,
  },
  {
    desc: '10%',
    taxationValue: 0.1,
  },
  {
    desc: '11%',
    taxationValue: 0.11,
  },
  {
    desc: '12%',
    taxationValue: 0.12,
  },
  {
    desc: '13%',
    taxationValue: 0.13,
  },
  {
    desc: '14%',
    taxationValue: 0.14,
  },
  {
    desc: '15%',
    taxationValue: 0.15,
  },
  {
    desc: '16%',
    taxationValue: 0.16,
  },
  {
    desc: '17%',
    taxationValue: 0.17,
  },
  {
    desc: '18%',
    taxationValue: 0.18,
  },
  {
    desc: '19%',
    taxationValue: 0.19,
  },
  {
    desc: '20%',
    taxationValue: 0.20,
  },
];

function getOptions(value?: number): OptionModel[] {
  let taxationOptions: OptionModel[] = [];

  for (let taxationCode of taxationList) {
    taxationOptions.push({
      label: taxationCode.desc,
      value: taxationCode.taxationValue.toString(),
      selected: value === taxationCode.taxationValue,
    });
  }

  return taxationOptions;
}
