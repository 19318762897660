import { TaxDeadlineModel } from '../models/taxDeadlineModel';
import { TaxForecastModel } from '../models/taxForecastModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class TaxCalculationService {

  private readonly url = host + '/api/v1/tax-calculation';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public get(year: string): Promise<TaxForecastModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '?year=' + year
    })
      .then(res => res.json())
      .then((data: TaxForecastModel) => data);
  }

  public editDeadline(deadline: TaxDeadlineModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + '/deadline/' + deadline.id,
      body: JSON.stringify(deadline),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

}
