import { useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { InvoiceFilters } from "../../components/invoiceFilters/invoiceFilters";
import { InvoiceList } from "../../components/invoiceList/invoiceList";
import { QuickfiscoHeader } from "../../components/quickfiscoHeader/quickfiscoHeader";
import { QuickfiscoIcon } from "../../components/quickfiscoIcon/quickfiscoIcon";
import { MenuItems, QuickfiscoMenu } from "../../components/quickfiscoMenu/quickfiscoMenu";
import { QuickfiscoPagination } from "../../components/quickfiscoPagination/quickfiscoPagination";
import { ViewportWarning } from "../../components/viewportWarning/viewportWarning";
import useNewInvoice from "../../hooks/newInvoice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getProformaInvoiceList, setProformaInvoiceListPage } from "../../redux/slices/proformaInvoiceListSlice";
import { RegimeLimitStatusType } from "../../models/userModel";
import { ExceedingThresholds100k } from "../../components/exceedingThresholds100k/exceedingThresholds100k";

export function ProformaInvoiceList() {
    const proformaInvoiceListState = useAppSelector(state => state.proformaInvoiceList)
    const userState = useAppSelector((state) => state.user.user);

    const proformaInvoices = proformaInvoiceListState

    const dispatch = useAppDispatch()

    const newInvoice = useNewInvoice('proforma')
    const { collapsed } = useProSidebar();

    useEffect(() => {
        if (proformaInvoiceListState.page === undefined) {
            dispatch(setProformaInvoiceListPage(0))
        }
    }, [])

    useEffect(() => {
        dispatch(getProformaInvoiceList({
            page: proformaInvoiceListState.page,
            month: proformaInvoiceListState.monthFilter,
            year: proformaInvoiceListState.yearFilter,
            companyName: proformaInvoiceListState.companyNameFilter,
            proformaStatus: proformaInvoiceListState.statusFilter
        }))
    }, [
        proformaInvoiceListState.page,
        proformaInvoiceListState.monthFilter,
        proformaInvoiceListState.yearFilter,
        proformaInvoiceListState.companyNameFilter,
        proformaInvoiceListState.statusFilter
    ])

    useEffect(() => {
        if (proformaInvoiceListState.page !== undefined && proformaInvoiceListState.page > 0 && proformaInvoiceListState.invoices.content.length === 0) {
            dispatch(setProformaInvoiceListPage(proformaInvoiceListState.page - 1))
        }
    }, [proformaInvoiceListState.invoices.content])

    return (
        <div className="full-screen bg-blue">
            <ViewportWarning />
            <div className="row no-gutters">
                <div className="col-auto no-gutters bg-blue">
                    <QuickfiscoMenu
                        id='proforma-invoice-list-menu'
                        menuItem={MenuItems.PROFORMA_INVOICE}
                    />
                </div>
                <div className="col p-4 ps-5" style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col">
                                    <QuickfiscoHeader title="Proforma" />
                                    {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-11">
                                    <InvoiceFilters invoiceSign={'proforma'} />
                                </div>
                                <div className="col-1 d-flex justify-content-end">
                                    <QuickfiscoIcon
                                        name={'new.svg'}
                                        className={'w-f-35'}
                                        isButton={true}
                                        onClick={() => newInvoice()}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <InvoiceList invoiceSign={'proforma'} />
                                </div>
                            </div>
                            <div className={'row mt-3'}>
                                <div className={'col-12'}>
                                    <div className={'d-flex justify-content-center'}>
                                        <QuickfiscoPagination
                                            totalPages={proformaInvoices.invoices.totalPages}
                                            number={proformaInvoices.invoices.number}
                                            empty={proformaInvoices.invoices.empty}
                                            doPagination={(page: number) => dispatch(setProformaInvoiceListPage(page))}
                                            first={proformaInvoices.invoices.first}
                                            last={proformaInvoices.invoices.last}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}