import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import {
  ChildrenTaxReturnModel,
  MarriedTaxReturnModel,
  SectionTaxReturn2,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoYesNoSelect } from '../quickfiscoYesNoSelect/quickfiscoYesNoSelect';
import { ChildrenAnswersInput } from '../childrenAnswersInput/childrenAnswersInput';
import { fiscalCodeRegex } from '../../regex/regex';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './taxReturnSection2.json';
import './taxReturnSection2.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn2) => void;
  onClickPrevStep: (data: SectionTaxReturn2) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnSection2(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;

  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [marriedQuestion, setMarriedQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [marriedAnswer, setMarriedAnswer] = useState<MarriedTaxReturnModel>({
    taxCodeSpouse: '',
    consortTaxDependent: undefined,
  });

  const [childrenQuestion, setChildrenQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [childrenAnswer, setChildrenAnswer] = useState<
    ChildrenTaxReturnModel[]
  >([
    {
      taxCodeChild: '',
      deductionChild: undefined,
    },
  ]);

  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);
  const [taxCodeSpouseError, setTaxCodeSpouseError] = useState<boolean>(false);

  const getSurveyStep = (): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(2)
      .then((res) => {
        setLoadingStatus('successfully');
        const data = res as SectionTaxReturn2;
        setMarriedQuestion(data.marriedQuestion);
        setMarriedAnswer({
          taxCodeSpouse: data.marriedAnswer?.taxCodeSpouse,
          consortTaxDependent: data.marriedAnswer?.consortTaxDependent,
        });
        setChildrenQuestion(data.childrenQuestion);
        setChildrenAnswer(
          data.childrenAnswer || [
            {
              taxCodeChild: '',
              deductionChild: undefined,
            },
          ]
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep();
    refreshApiCall(0, true);
  }, []);

  useEffect(() => {
    marriedAnswer.taxCodeSpouse &&
      marriedAnswer.taxCodeSpouse.length > 0 &&
      setTaxCodeSpouseError(!fiscalCodeRegex.test(marriedAnswer.taxCodeSpouse));
  }, [marriedAnswer.taxCodeSpouse]);

  useEffect(() => {
    if (marriedQuestion === false) {
      setMarriedAnswer({
        taxCodeSpouse: '',
        consortTaxDependent: undefined,
      });
      setTaxCodeSpouseError(false);
    }
  }, [marriedQuestion]);

  useEffect(() => {
    childrenQuestion === false &&
      setChildrenAnswer([
        {
          taxCodeChild: '',
          deductionChild: undefined,
        },
      ]);
  }, [childrenQuestion]);

  useEffect(() => {
    setCheckgoNext(
      taxReturnService.validateSection2(dataToSend, taxCodeSpouseError)
    );
  }, [
    marriedQuestion,
    marriedAnswer,
    childrenQuestion,
    childrenAnswer,
    taxCodeSpouseError,
  ]);

  const dataToSend: SectionTaxReturn2 = {
    marriedQuestion,
    marriedAnswer: marriedQuestion === true ? marriedAnswer : undefined,
    childrenQuestion,
    childrenAnswer: childrenQuestion === true ? childrenAnswer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-2-title">{lang.title}</div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-2-married-question"
                  title={lang.marriedQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={marriedQuestion}
                  onChange={(checked) => setMarriedQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {marriedQuestion === true && (
              <div className={'row no-gutters mt-3'}>
                <div className="col-6">
                  <QuickfiscoInput
                    id="tax-return-section-2-tax-code-spouse"
                    type="text"
                    styleType={'default'}
                    label={lang.taxCodeSpouseLabel}
                    placeholder={lang.taxCodeSpousePlaceholder}
                    defaultValue={marriedAnswer.taxCodeSpouse}
                    onChange={(e) =>
                      setMarriedAnswer({
                        ...marriedAnswer,
                        taxCodeSpouse: e.target.value,
                      })
                    }
                    required={true}
                    error={taxCodeSpouseError}
                    errorLabel={lang.taxCodeError}
                  />
                </div>
                <div className="col-6">
                  <QuickfiscoYesNoSelect
                    id={'tax-return-section-2-consort-tax-dependent'}
                    label={lang.consortTaxDependent}
                    value={marriedAnswer.consortTaxDependent}
                    onChange={(value) => {
                      if (value !== undefined) {
                        setMarriedAnswer({
                          ...marriedAnswer,
                          consortTaxDependent: value,
                        });
                        setChildrenQuestion(undefined);
                        setChildrenAnswer([
                          {
                            taxCodeChild: '',
                            deductionChild: undefined,
                          },
                        ]);
                      }
                    }}
                    requiredField={true}
                  />
                </div>
              </div>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-2-children-question"
                  title={lang.childrenQuestion}
                  description={lang.childrenQuestionDescription}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={childrenQuestion}
                  onChange={(checked) => setChildrenQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {childrenQuestion === true && (
              <ChildrenAnswersInput
                id="tax-return-section-2-children-answers"
                childrenAnswers={childrenAnswer}
                setChildrenAnswers={(childrenAnswers) =>
                  setChildrenAnswer(childrenAnswers)
                }
                consortTaxDependent={marriedAnswer.consortTaxDependent === true}
              />
            )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-2-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-2-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-2-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-2-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
