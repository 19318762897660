import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaxProfileModel } from '../../models/taxProfileModel';
import { TaxProfileService } from '../../services/taxProfileService';
import { PromiseStatuses, TaxProfileSteps } from '../../types/strings';

export interface TaxProfileStatus {
  profile: TaxProfileModel;
  status: PromiseStatuses;
  saveStatus: PromiseStatuses;
  editStatus: PromiseStatuses;
  step: TaxProfileSteps;
  sendEmailStatus: PromiseStatuses;
}

const initialState: TaxProfileStatus = {
  profile: {
    currentYearRevenues: 0,
    otherCurrentYearRevenues: 0,
    lastYearRevenues: 0,
    lastYearContributionBalance: 0,
    lastYearContributionDeposit: 0,
    lastYearTaxDeposit: 0,
    lastUpdate: new Date(),
    fundYearRegistration: 0,
    maternityContribution: 0,
    taxContributionRate: 0,
    minTaxContribution: 0,
    minSupplementaryContribution: 0,
    lastTaxContributionRate: 0
  },
  status: 'idle',
  saveStatus: 'idle',
  editStatus: 'idle',
  step: 'welcome',
  sendEmailStatus: "idle"
};

export const getTaxProfile = createAsyncThunk(
  'taxProfile/get',
  async (): Promise<TaxProfileModel> => {
    return new TaxProfileService().get();
  }
);

export const saveTaxProfile = createAsyncThunk(
  'taxProfile/save',
  async (request: TaxProfileModel): Promise<string> => {
    return new TaxProfileService().save(request);
  }
);

export const editTaxProfile = createAsyncThunk(
  'taxProfile/edit',
  async (request: TaxProfileModel): Promise<void> => {
    return new TaxProfileService().edit(request);
  }
);

export const sendEmail = createAsyncThunk(
  'taxProfile/sendEmail',
  async (): Promise<void> => {
    return new TaxProfileService().sendEmail()
  }
)

const taxProfileSlice = createSlice({
  name: 'taxProfile',
  initialState,
  reducers: {
    setOtherCurrentYearRevenues: (state, action: PayloadAction<number>) => {
      state.profile.otherCurrentYearRevenues = action.payload;
    },
    setLastYearRevenues: (state, action: PayloadAction<number>) => {
      state.profile.lastYearRevenues = action.payload;
    },
    setLastYearContributionBalance: (state, action: PayloadAction<number>) => {
      state.profile.lastYearContributionBalance = action.payload;
    },
    setLastYearContributionDeposit: (state, action: PayloadAction<number>) => {
      state.profile.lastYearContributionDeposit = action.payload;
    },
    setLastYearTaxDeposit: (state, action: PayloadAction<number>) => {
      state.profile.lastYearTaxDeposit = action.payload;
    },
    setCurrentYearRevenues: (state, action: PayloadAction<number>) => {
      state.profile.currentYearRevenues = action.payload;
    },
    setFundYearRegistration: (state, action: PayloadAction<number>) => {
      state.profile.fundYearRegistration = action.payload
    },
    setMaternityContribution: (state, action: PayloadAction<number>) => {
      state.profile.maternityContribution = action.payload
    },
    setTaxContributionRate: (state, action: PayloadAction<number>) => {
      state.profile.taxContributionRate = action.payload
    },
    setMinTaxContribution: (state, action: PayloadAction<number>) => {
      state.profile.minTaxContribution = action.payload
    },
    setMinSupplementaryContribution: (state, action: PayloadAction<number>) => {
      state.profile.minSupplementaryContribution = action.payload
    },
    setLastTaxContributionRate: (state, action: PayloadAction<number>) => {
      state.profile.lastTaxContributionRate = action.payload
    },
    setStep: (state, action: PayloadAction<TaxProfileSteps>) => {
      state.step = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTaxProfile.fulfilled, (state, action) => {
        state.status = 'successfully';
        state.profile = action.payload;
      })
      .addCase(getTaxProfile.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(saveTaxProfile.pending, (state) => {
        state.saveStatus = 'loading';
      })
      .addCase(saveTaxProfile.fulfilled, (state) => {
        state.saveStatus = 'successfully';
      })
      .addCase(saveTaxProfile.rejected, (state) => {
        state.saveStatus = 'failed';
      })
      .addCase(editTaxProfile.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editTaxProfile.fulfilled, (state) => {
        state.editStatus = 'successfully';
      })
      .addCase(editTaxProfile.rejected, (state) => {
        state.editStatus = 'failed';
      })
      .addCase(sendEmail.pending, (state) => {
        state.sendEmailStatus = 'loading';
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.sendEmailStatus = 'successfully';
      })
      .addCase(sendEmail.rejected, (state) => {
        state.sendEmailStatus = 'failed';
      });
  },
});

export const {
  setOtherCurrentYearRevenues,
  setLastYearRevenues,
  setLastYearContributionBalance,
  setLastYearContributionDeposit,
  setLastYearTaxDeposit,
  setCurrentYearRevenues,
  setFundYearRegistration,
  setMaternityContribution,
  setTaxContributionRate, 
  setMinTaxContribution,
  setMinSupplementaryContribution,
  setLastTaxContributionRate,
  setStep
} = taxProfileSlice.actions;

export default taxProfileSlice.reducer;
