import { useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import {
    ProformaInvoiceSaveOrDuplicateOrEdit as ProformaInvoiceSaveOrDuplicateOrEditComponent
} from '../../components/proformaInvoiceSaveOrDuplicateOrEdit/proformaInvoiceSaveOrDuplicateOrEdit';
import { QuickfiscoHeader } from "../../components/quickfiscoHeader/quickfiscoHeader";
import { MenuItems, QuickfiscoMenu } from "../../components/quickfiscoMenu/quickfiscoMenu";
import { QuickfiscoSpinner } from "../../components/quickfiscoSpinner/quickfiscoSpinner";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { findProformaInvoiceById } from "../../redux/slices/proformaInvoiceSlice";
import { RegimeLimitStatusType } from "../../models/userModel";
import { ExceedingThresholds100k } from "../../components/exceedingThresholds100k/exceedingThresholds100k";
import lang from "./proformaInvoiceSaveOrDuplicateOrEdit.json"

export function ProformaInvoiceSaveOrDuplicateOrEdit() {
    const { collapsed } = useProSidebar();

    const proformaInvoiceState = useAppSelector(state => state.proformaInvoice)
    const proformaInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.proformaInvoiceSaveOrDuplicateOrEdit)
    const userState = useAppSelector((state) => state.user.user);

    const dispatch = useAppDispatch();

    const invoiceId = proformaInvoiceSaveOrDuplicateOrEditState.invoiceId
    const operation = proformaInvoiceSaveOrDuplicateOrEditState.operation

    useEffect(() => {
        if (invoiceId !== undefined && (operation === 'duplicate' || operation === 'edit')) {
            dispatch(findProformaInvoiceById(invoiceId))
        }
    }, [invoiceId, operation]);

    useEffect(() => {
        if(invoiceId !== undefined && proformaInvoiceSaveOrDuplicateOrEditState.editStatus === 'successfully' && operation !== 'save'){
            dispatch(findProformaInvoiceById(invoiceId))
        }
    }, [proformaInvoiceSaveOrDuplicateOrEditState.editStatus])

    if (proformaInvoiceState.findByIdStatus === 'loading') {
        return (
            <div className="full-screen d-flex justify-content-center align-items-center">
                <QuickfiscoSpinner />
            </div>
        );
    }

    if (proformaInvoiceState.findByIdStatus === 'failed') {
        return (
            <div className="full-screen d-flex justify-content-center align-items-center">
                "Errore durante il caricamento della fattura."
            </div>
        );
    }
    
    return (
        <div className="full-screen bg-blue">
            <div className="row no-gutters">
                <div className="col-auto no-gutters bg-blue">
                    <QuickfiscoMenu
                        id='proforma-invoice-edit-menu'
                        menuItem={MenuItems.PROFORMA_INVOICE}
                    />
                </div>
                <div className="col p-4 ps-5" style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
                    <div className="row">
                        <div className="col">
                            <QuickfiscoHeader title={lang.Title} />
                            {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ProformaInvoiceSaveOrDuplicateOrEditComponent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}