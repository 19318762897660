import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuAttributes } from '../quickfiscoMenu/quickfiscoMenu';
import './subMenuCollapsedInfo.css';

interface Props {
    label: string,
    subMenuItems: MenuAttributes[]
}

export function SubMenuCollapsedInfo(props: Props) {
    const { label } = props;
    const { subMenuItems } = props;

    let subMenuItemsList: React.ReactElement[] = []

    const navigate = useNavigate();
    const location = useLocation();

    subMenuItems?.forEach((value) => {
        subMenuItemsList.push(
            <div
                key={'sub-menu-collapsed-info-item-' + value.label}
                className='sub-menu-collapsed-info-item'
            >
                <div className='row'>
                    <div className='col-12'>
                        <span 
                            className={value.linkTo === location.pathname ? 'sub-menu-collapsed-info-item-active' : ''}
                            onClick={(e: React.SyntheticEvent) => {
                                e.preventDefault();
                                navigate(value.linkTo);
                            }
                        }>
                            {value.label}
                        </span>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className='sub-menu-collapsed-info-container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='sub-menu-collapsed-info-title'>
                        {label}
                    </div>
                </div>
            </div>
            {subMenuItemsList}
        </div>
    );
}
