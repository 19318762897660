import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import uri from '../../routers/quickfiscoUri.json';
import { ImportInvoiceService } from '../../services/importInvoiceService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './invoiceImportStsBoxHome.css';
import lang from './invoiceImportStsBoxHome.json';
import { useEffect, useState } from 'react';
import { PromiseStatuses } from '../../types/strings';
import { UserService } from '../../services/userService';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { ProfileStatus } from '../../models/stsProfileModel';
import { getItemWithExipiration, setItemWithExipiration } from '../../utils/localStorage';
import { getStsProfile } from '../../redux/slices/stsProfileSlice';

interface Props {
  isHome?: boolean;
}

export function InvoiceImportStsBoxHome(props: Props) {
  const { isHome } = props;

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const userState = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (stsProfile.profile === undefined && userState.importInvoicesSts?.activationSts) {
      dispatch(getStsProfile());
    }
  }, [userState]);

  const stsProfile = useAppSelector((state) => state.stsProfile);

  useEffect(() => {
    if (stsProfile.profile?.status !== undefined && stsProfile.profile?.status === ProfileStatus.ACTIVE) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [stsProfile.profile?.status]);

  return (
    <div
      className={`${isHome ? 'invoice-import-sts-box-home-container' : ''
        } text-center my-3 p-4`}
    >
      <QuickfiscoError
        active={status === 'failed'}
        message={lang.savingError}
        close={() => setStatus('idle')}
      />
      <QuickfiscoSuccess
        active={status === 'successfully'}
        message={lang.savingSuccess}
        close={() => setStatus('idle')}
      />
      {isHome ? <>
        <div className={'invoice-import-sts-box-home-action-home'}>{lang.action}</div>
        <div className={'invoice-import-sts-box-home-title-bold-home mt-3 mb-3'}>
          {lang.titleBold}
        </div>
        <div className={'invoice-import-sts-box-home-subtitle-home'}>
          {lang.subTitle} <br></br>
          <b>{lang.subTitle_0}</b> {lang.subTitle_1}
          <a className={"invoice-import-sts-box-home-link-white"} onClick={() => navigate(uri.StsConfiguration)} >
            DA QUI
          </a>
          {lang.subTitle_2}
        </div>
        {
          status === 'loading' ? (
            <div className="row mt-4">
              <div className={'col-12 d-flex align-items-center justify-content-center'}>
                <QuickfiscoSpinner />
              </div>
            </div>
          ) : (
            <div className="row mt-4">
              <div className="col-4 offset-4">
                <QuickfiscoButton
                  id={'invoice-import-sts-box-home-cta-btn'}
                  label={lang.ctaButton}
                  onClick={() => sendToSupportImportInvoiceMail(setStatus, setDisabledButton)}
                  type={disabledButton || getItemWithExipiration('stopSendSupportImportInvoiceMail') === "true" ? 'disabled' : 'ninth'} />
              </div>
            </div>)}</>
        :
        <>
          <QuickfiscoIcon name={'alert-yellow-full-2.svg'} className="mb-3" />
          <div className={'invoice-import-sts-box-home-action mb-3'}>{lang.action_2}</div>
          <div className={'invoice-import-sts-box-home-title'}>
            {lang.titleBold_1} <b>{lang.titleBold_2}</b> {lang.titleBold_3} <b>{new Date().getFullYear()}</b> {lang.titleBold_4} <b>{new Date().getFullYear() - 1}</b>
          </div>
          <div className={'invoice-import-sts-box-home-subtitle mt-2'}>
            {lang.subTitle_3}
          </div>
          <div className="row mt-4">
            <div className="col-4 offset-4">
              <QuickfiscoButton
                id={'invoice-import-sts-box-cta-btn'}
                label={lang.ctaButton_2}
                onClick={() => navigate(uri.InvoiceImportSts)}
                type={'eighth'} />
            </div>
          </div></>
      }
    </div>
  );
}

export function InvoiceImportStsBoxEmptyHome() {
  const navigate = useNavigate();
  const importInvoiceService = new ImportInvoiceService();
  const userState = useAppSelector((state) => state.user.user);
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  useEffect(() => {
    if (status === "successfully") {
      window.location.reload();
    }
  }, [status])

  return (
    <div className={'text-center my-4 p-5'}>
      <QuickfiscoIcon name={'alert-blue-2.svg'} className="mb-4" />
      <div className={'invoice-import-box-home-action'}>{lang.invoiceList.action_2}</div>
      <div className={'invoice-import-box-home-title-bold mt-3 mb-2'}>
        {lang.invoiceList.titleBold_4} {new Date().getFullYear()} {lang.invoiceList.titleBold_5}
        {new Date().getFullYear() - 1}
      </div>
      <div className={'invoice-import-box-home-subtitle'}>
        {lang.invoiceList.subTitle_1}
        <a className={"invoice-import-sts-box-home-link"} onClick={() => navigate("https://quickfisco.it/tutorial/migrazione-fatture-note-di-credito")} >{lang.invoiceList.subTitle_2}</a>
        {lang.invoiceList.subTitle_3}<br></br>
        {lang.invoiceList.subTitle_4}
      </div>
      <div className="row mt-4">
        <div className="col-4 offset-4">
          <QuickfiscoButton
            id={'invoice-import-box-home-cta-btn'}
            label={lang.invoiceList.ctaButton_1}
            onClick={() => {
              if (!userState.id) {
                return
              }

              setStatus("loading")
              importInvoiceService
                .resetImportInvoice(userState.id, "sts")
                .then(() => {
                  setStatus("successfully")
                }).catch((err) => {
                  console.error(err);
                  setStatus("failed")
                });
            }}
            type={'tertiary'}
          />
        </div>
      </div>
    </div>
  );
}

export function InvoiceImportStsBoxRequestHome() {
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const stsProfile = useAppSelector((state) => state.stsProfile);

  useEffect(() => {
    if (stsProfile.profile?.status !== undefined && stsProfile.profile?.status === ProfileStatus.ACTIVE) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [stsProfile.profile?.status]);

  return (
    <div className={'text-center my-4 p-5'}>
      <QuickfiscoError
        active={status === 'failed'}
        message={lang.savingError}
        close={() => setStatus('idle')}
      />
      <QuickfiscoSuccess
        active={status === 'successfully'}
        message={lang.savingSuccess}
        close={() => setStatus('idle')}
      />
      <QuickfiscoIcon name={'refused-not-empty.svg'} className="mb-4" />
      <div className={'invoice-import-box-home-action'}>{lang.requestList.action}</div>
      <div className={'invoice-import-box-home-title-bold mt-3 mb-2'}>
        {lang.requestList.titleBold}
      </div>
      <div className={'invoice-import-box-home-subtitle'}>
        {lang.requestList.subTitle}
      </div>{
        status === 'loading' ? (
          <div className="row mt-4">
            <div className={'col-12 d-flex align-items-center justify-content-center'}>
              <QuickfiscoSpinner />
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-6 offset-3">
              <QuickfiscoButton
                id={'invoice-import-box-home-cta-btn'}
                label={lang.requestList.ctaButton}
                onClick={() => sendToSupportImportInvoiceMail(setStatus, setDisabledButton)}
                type={disabledButton || getItemWithExipiration('stopSendSupportImportInvoiceMail') === "true" ? 'disabled' : 'ninth'} />
            </div>
          </div>
        )}
    </div>
  );
}

export function InvoiceImportStsBoxNotLoginRequestHome() {
  const navigate = useNavigate();

  return (
    <div className={'text-center my-4 p-5'}>
      <QuickfiscoIcon name={'refused-not-empty.svg'} className="mb-4" />
      <div className={'invoice-import-box-home-action'}>{lang.notLogin.action}</div>
      <div className={'invoice-import-box-home-title-bold mt-3 mb-2'}>
        {lang.notLogin.titleBold}
      </div>
      <div className={'invoice-import-box-home-subtitle'}>
        {lang.notLogin.subTitle}
      </div>
      <div className="row mt-4">
        <div className="col-4 offset-4">
          <QuickfiscoButton
            id={'invoice-import-box-home-cta-btn'}
            label={lang.notLogin.ctaButton}
            onClick={() => navigate(uri.StsConfiguration)}
            type={'secondary'}
          />
        </div>
      </div>
    </div>
  );
}


function sendToSupportImportInvoiceMail(
  setStatus: React.Dispatch<React.SetStateAction<PromiseStatuses>>, setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>) {
  const userService = new UserService();

  setStatus('loading');
  userService.sendToSupportImportInvoiceMail()
    .then(() => {
      setStatus('successfully');
      setItemWithExipiration('stopSendSupportImportInvoiceMail', {
        value: 'true',
        expireTime: Date.now() + 1000 * 60 * 60 * 24 * 50,
      });
      setDisabledButton(true)
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}