export interface DocumentsFilters {
  page?: number;
  type?: DocumentsStatusFiltersType;
}

export enum DocumentsStatusFiltersType {
  F24 = 'F24',
  DECLARATION = 'DECLARATION',
  TAX_IDENTITY = 'TAX_IDENTITY',
  OTHERS = 'OTHERS'
}