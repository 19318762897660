
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { v4 as uiidv4 } from 'uuid';
import { ContactType } from "../../models/contactModel";
import { InvoiceErrorSetters } from "../../models/invoiceErrorSetters";
import { InvoiceModel, InvoiceStatusType, InvoiceType, ReimbursementOfExpenseModel } from "../../models/invoiceModel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setGlobalStatus } from "../../redux/slices/operationStatusSlice";
import { editProformaInvoice, setProformaInvoiceSaveOrDuplicateOrEditInvoiceId, setProformaInvoiceSaveOrDuplicateOrEditOperation, setProformaInvoiceSaveOrDuplicateOrEditSuccessfullySaved } from "../../redux/slices/proformaInvoiceSaveOrDuplicateOrEditSlice";
import { setProformaInvoiceAteco, setProformaInvoiceBankAccount, setProformaInvoiceCommunicationData, setProformaInvoiceDate, setProformaInvoiceFileList, setProformaInvoiceFund, setProformaInvoiceGoodServices, setProformaInvoiceNotes, setProformaInvoicePaymentExpiration, setProformaInvoicePaymentMode, setProformaInvoicePaymentTerms, setProformaInvoicePdfMailLanguage, setProformaInvoiceProforma, setProformaInvoiceReimbursementOfExpensesAmount, setProformaInvoiceReimbursementsOfExpenses, setProformaInvoiceSocialContribution, setProformaInvoiceSocialEnasarco, setProformaInvoiceStamp, setProformaInvoiceStatus, setProformaInvoiceTrackedPayment, setProformaStampId } from "../../redux/slices/proformaInvoiceSlice";
import uri from '../../routers/quickfiscoUri.json';
import { ProformaInvoiceService } from "../../services/proformaInvoiceService";
import { UserService } from "../../services/userService";
import { SetState } from "../../types/functions";
import { Operation, PromiseStatuses } from "../../types/strings";
import { formatDateUS, resolveUndefinedDate } from "../../utils/date";
import { downloadPdf, validateFiles } from "../../utils/file";
import { formatNumberIT } from "../../utils/number";
import { AtecoSelect } from "../atecoSelect/atecoSelect";
import { ContactSelect } from "../contactSelect/contactSelect";
import { fundsMap } from '../fundSelect/fundSelect';
import { GoodServicesInput } from "../goodServicesInput/goodServicesInput";
import { InvoiceAmount } from "../invoiceAmount/invoiceAmount";
import { PaymentExpirationInput } from "../paymentExpirationInput/paymentExpirationInput";
import { PaymentModeSelect } from "../paymentModeSelect/paymentModeSelect";
import { QuickfiscoButton } from "../quickfiscoButton/quickfiscoButton";
import { QuickfiscoCheckbox } from "../quickfiscoCheckbox/quickfiscoCheckbox";
import { QuickfiscoDatePicker } from "../quickfiscoDatePicker/quickfiscoDatePicker";
import { QuickfiscoError } from "../quickfiscoError/quickfiscoError";
import { QuickfiscoInput } from "../quickfiscoInput/quickfiscoInput";
import { QuickfiscoInputFile } from "../quickfiscoInputFile/quickfiscoInputFile";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { QuickfiscoSuccess } from "../quickfiscoSuccess/quickfiscoSuccess";
import { QuickfiscoSwitchInput } from "../quickfiscoSwitchInput/quickfiscoSwitchInput";
import { QuickfiscoTextarea } from "../quickfiscoTextarea/quickfiscoTextarea";
import { ReimbursementsOfExpense } from "../reimbursementsOfExpenses/reimbursementsOfExpense";
import { SendProformaInvoice } from '../sendProformaInvoice/sendProformaInvoice';
import { SwitchToCustomerInvoice } from '../switchToCustomerInvoice/switchToCustomerInvoice';
import { SwitchToSts } from '../switchToSts/switchToSts';
import { StsProfileNotice } from "../stsProfileNotice/stsProfileNotice";
import { CustomerProfileNotice } from "../customerProfileNotice/customerProfileNotice";
import './proformaInvoiceSaveOrDuplicateOrEdit.css';
import { SeePreviewPdfModal } from "../seePreviewPdfModal/seePreviewPdfModal";
import { QuickfiscoBankSelect } from "../quickfiscoBankSelect/quickfiscoBankSelect";
import { CategoryType } from "../../models/userModel";

export function ProformaInvoiceSaveOrDuplicateOrEdit() {
    const [errorCustomer, setErrorCustomer] = useState(false);
    const [errorGoodServices, setErrorGoodServices] = useState(false);
    const [errorGoodServicesInput, setErrorGoodServicesInput] = useState(false);
    const [errorPaymentMode, setErrorPaymentMode] = useState(false);
    const [errorPaymentExpiration, setErrorPaymentExpiration] = useState(false);
    const [errorTrackedPayment, setErrorTrackedPayment] = useState(false);
    const [errorSendData, setErrorSendData] = useState(false);
    const [errorFiles, setErrorFiles] = useState(false);
    const [errorReimbursementsOfExpenses, setErrorReimbursementsOfExpenses] = useState(false);
    const [errorDateMessage, setErrorDateMessage] = useState("Inserire una data di emissione");
    const [errorEnasarco, setErrorEnasarco] = useState(false);
    const [errorStampId, setErrorStampId] = useState(false);

    const [status, setStatus] = useState<PromiseStatuses>('idle');
    const [sendSectionOpen, setSendSectionOpen] = useState(false);
    const [downloadPdfStatus, setDownloadPdfStatus] = useState<PromiseStatuses>('idle');
    const [seePreviewPdfOpen, setSeePreviewPdfOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const proformaInvoiceState = useAppSelector(state => state.proformaInvoice);
    const globalStatus = useAppSelector(state => state.operationStatus.status);
    const userState = useAppSelector(state => state.user)
    const proformaInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.proformaInvoiceSaveOrDuplicateOrEdit);

    const invoice = proformaInvoiceState.invoice
    const fileList = proformaInvoiceState.fileList
    const reimbursementsOfExpenses = proformaInvoiceState.reimbursementsOfExpenses
    const user = userState.user
    const operation = proformaInvoiceSaveOrDuplicateOrEditState.operation
    const successfullySaved = proformaInvoiceSaveOrDuplicateOrEditState.successfullySaved

    const [userIsNotEnabled, setUserIsNotEnabled] = useState(UserService.isNotEnabled(user))
    const [invoiceIsSendable, setInvoiceIsSendable] = useState(ProformaInvoiceService.isSendable(invoice))

    const service = new ProformaInvoiceService(
        {
            setErrorCustomer,
            setErrorGoodServicesInput,
            setErrorGoodServices,
            setErrorPaymentExpiration,
            setErrorPaymentMode,
            setErrorDateMessage,
            setErrorReimbursementsOfExpenses,
            setErrorTrackedPayment,
            setErrorSendData,
            setErrorEnasarco,
            setErrorStampId,
        }
    );

    useEffect(() => {
        if (
            operation === 'duplicate' &&
            invoice.status !== InvoiceStatusType.DRAFT &&
            invoice.status !== InvoiceStatusType.SENT
        ) {
            dispatch(setProformaInvoiceStatus(InvoiceStatusType.DRAFT))
        }

        if (user.fund !== undefined && operation === 'save') {
            dispatch(setProformaInvoiceFund(user.fund))
        }

    }, [invoice, operation])

    useEffect(() => {
        setUserIsNotEnabled(UserService.isNotEnabled(user))
    }, [user.enabled, user.status])

    useEffect(() => {
        setInvoiceIsSendable(ProformaInvoiceService.isSendable(invoice))
    }, [invoice.status])

    if (userState.status === 'failed') {
        return (
            <div className="full-screen d-flex justify-content-center align-items-center">
                Errore durante il caricamento dei dati.
            </div>
        )
    }

    if (userState.status === 'loading') {
        return (
            <div className="full-screen d-flex justify-content-center align-items-center">
                <QuickfiscoSpinner />
            </div>
        );
    }

    let recourseValue: number | undefined = 0
    if (invoice.fund !== undefined) {
        recourseValue = fundsMap.get(invoice.fund)?.recourseValue
    }

    return (
        <div className="row">
            <QuickfiscoSuccess
                message={"Fattura salvata con successo"}
                active={successfullySaved}
                close={() => dispatch(setProformaInvoiceSaveOrDuplicateOrEditSuccessfullySaved(false))}
            />
            <QuickfiscoSuccess
                message={"Fattura modificata con successo"}
                active={status === 'successfully'}
                close={() => setStatus('idle')}
            />
            <QuickfiscoError
                message={"Errore durante il salvataggio della fattura"}
                active={status === 'failed'}
                close={() => setStatus('idle')}
            />
            {
                (user.stsEnabled || (invoice.stsIsActive && operation === 'edit') || (invoice.stsIsActive && operation === 'duplicate')) &&
                    invoice.stsIsActive ? (
                    <div className={'row mt-4'}>
                        <div className={'col-12'}>
                            <StsProfileNotice isProforma={true} />
                        </div>
                    </div>
                ) : (
                    <>
                        {user.stsEnabled &&
                            <div className={'row mt-4'}>
                                <div className={'col-12'}>
                                    <CustomerProfileNotice isProforma={true} />
                                </div>
                            </div>
                        }
                    </>
                )
            }
            <div className="col-12 col-xl-5 mt-4">
                {
                    (user.stsEnabled || (invoice.stsIsActive && operation === 'edit') || (invoice.stsIsActive && operation === 'duplicate')) && (
                        !invoice.stsIsActive ? (
                            <div className={'row mb-4'}>
                                <div className={'col-12'}>
                                    <SwitchToSts
                                        operation={operation}
                                        invoiceSign={'proforma'}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={'row mb-4'}>
                                <div className={'col-12'}>
                                    <SwitchToCustomerInvoice
                                        operation={operation}
                                        invoiceSign={'proforma'}
                                        goodServices={invoice.goodServices}
                                        setTrackedPayment={(_trackedPayment?: string) => {
                                            dispatch(setProformaInvoiceTrackedPayment(_trackedPayment === 'SI'));
                                            service.validateTrackedPayment(_trackedPayment === 'SI');
                                        }}
                                        trackedPayment={invoice.trackedPayment === true ? 'SI' : 'NO'}
                                        notSendData={invoice.customerDataCommunication}
                                        setNotSendData={(notSendData: boolean) => {
                                            dispatch(setProformaInvoiceCommunicationData(notSendData));
                                        }}
                                        errorTrackedPayment={errorTrackedPayment}
                                        errorSendData={errorSendData}
                                    />
                                </div>
                            </div>
                        )
                    )
                }
                <div className="row no-gutters">
                    <div className="col-12 no-gutters">
                        <ContactSelect
                            type={ContactType.CUSTOMER}
                            selected={invoice.customer}
                            setContact={contact => {
                                dispatch(setProformaInvoiceProforma(contact));
                                service.validateContact(contact);
                            }}
                            error={errorCustomer}
                            requiredField={true}
                        />
                    </div>
                </div>
                <div className="row no-gutters mt-4">
                    <div className="col-12 invoice-proforma-saveOrEdit-container p-4">
                        {user && user.atecos && user.atecos.length > 1 && user.atecos.some(ateco => ateco === invoice.ateco) ?
                            (
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <AtecoSelect
                                            id={'proforma-invoice-ateco-select'}
                                            onChange={(_ateco) => dispatch(setProformaInvoiceAteco(_ateco))}
                                            value={invoice.ateco === null ? undefined : invoice.ateco}
                                            type={'own'}
                                        />
                                    </div>
                                </div>
                            ) :
                            <div className={'row mt-4 mb-0'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id={'invoice-proforma-save-ateco'}
                                        type={'text'}
                                        label={'Codice ateco'}
                                        value={invoice.ateco || "-"}
                                        disabled={true}
                                        styleType={'default'}
                                    />
                                </div>
                            </div>
                        }
                        {user && user?.fund !== "TC07" && (user.category !== CategoryType.ARTISAN && user.category !== CategoryType.TRADER) ?
                            <div className="invoice-customer-save-or-duplicate-enasarco">
                                <div className={"col d-flex align-items-center"}>
                                    <QuickfiscoSwitchInput
                                        id={'invoice-proforma-save-or-duplicate-social-contribution'}
                                        label={"Contributo previdenziale"}
                                        onChange={checked => dispatch(setProformaInvoiceSocialContribution(checked))}
                                        checked={invoice.socialContribution === null ? undefined : invoice.socialContribution}
                                    />
                                </div>
                            </div> : ""
                        }
                        <div className={'row mt-4'}>
                            <QuickfiscoDatePicker
                                id={'invoice-proforma-save-date'}
                                label={"Data emissione"}
                                onChange={e => e && dispatch(setProformaInvoiceDate(resolveUndefinedDate(formatDateUS(e))))}
                                value={new Date(invoice.date)}
                                errorLabel={errorDateMessage}
                                required={true}
                                onBlur={(e) => {
                                    service.validateDate(resolveUndefinedDate(e.target.value));
                                }}
                                styleType={'default'}
                            />
                        </div>
                        <div className={'row '}>
                            <PaymentModeSelect
                                id={'invoice-proforma-save-payment-mode'}
                                onChange={paymentMode => {
                                    dispatch(setProformaInvoicePaymentMode(paymentMode));
                                    service.validatePaymentMode(paymentMode);
                                }}
                                value={invoice.paymentMode}
                                error={errorPaymentMode}
                            />
                        </div>
                        <div className={'mt-2'}>
                            <PaymentExpirationInput
                                id={'invoice-proforma-save-payment-expiration'}
                                value={invoice.paymentExpiration}
                                onChange={(value, valueTerms) => {
                                    dispatch(setProformaInvoicePaymentExpiration(value));
                                    dispatch(setProformaInvoicePaymentTerms(valueTerms))
                                }
                                }
                                startDate={invoice.date}
                                error={errorPaymentExpiration}
                                invoicePaymentTerms={invoice.paymentTerms}
                                onBlur={(value) => service.validatePaymentExpiration(invoice.date, value)}
                            />
                        </div>
                        {user.bankAccount && user.bankAccount.some(bank => bank.name === invoice.supplier?.bankAccount.bankName) ? (
                            <div className={'row mt-2'}>
                                <QuickfiscoBankSelect
                                    id={'invoice-proforma-save-bank'}
                                    requiredField={true}
                                    onChange={(bank) => { dispatch(setProformaInvoiceBankAccount({ name: user.bankAccount.find(bankTemp => bankTemp.iban == bank)?.name ?? '', iban: bank })) }}
                                    value={
                                        operation === "edit" || invoice.supplier?.bankAccount != undefined ?
                                            {
                                                name: invoice.supplier?.bankAccount.bankName || "",
                                                iban: invoice.supplier?.bankAccount.bankIban || ""
                                            }
                                            :
                                            (user.bankAccount.find(bank => bank.default === true) || { name: '', iban: '' })
                                    }
                                />
                            </div>
                        ) :
                            <div className={'row mt-2'}>
                                <QuickfiscoInput
                                    id={'invoice-proforma-save-bank'}
                                    type={'text'}
                                    label={'Banca'}
                                    value={`${invoice.supplier?.bankAccount.bankName} - ${invoice.supplier?.bankAccount.bankIban}`}
                                    disabled={true}
                                    styleType={'default'}
                                />
                            </div>
                        }
                        <div className={'row mt-4'}>
                            <QuickfiscoTextarea
                                id={'invoice-proforma-save-notes'}
                                label={"Nota"}
                                onChange={e => dispatch(setProformaInvoiceNotes(e.target.value))}
                                value={invoice.notes === null ? undefined : invoice.notes}
                                placeholder={"Aggiungi una nota che sarà visibile nel pdf fattura, ti ricordiamo che questo campo non apparirà all'interno della fattura elettronica."}
                                secondaryLabel={" (Campo facoltativo non presente in fattura elettronica)"}
                                maxLength={330}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-7 mt-4">
                <div className="row no-gutters">
                    <div className="col-12 invoice-proforma-saveOrEdit-container p-4">
                        <div className="row mt-4">
                            <div className="col-12">
                                <GoodServicesInput
                                    goodServices={invoice.goodServices !== undefined && invoice.goodServices !== null && invoice.goodServices.length > 0 ? invoice.goodServices : [{
                                        key: uiidv4(),
                                        quantity: 1,
                                        amount: 0
                                    }]}
                                    setGoodServices={goodServices => dispatch(setProformaInvoiceGoodServices(goodServices))}
                                    invoiceSign={'proforma'}
                                    stsIsActive={invoice.stsIsActive}
                                    error={errorGoodServices || errorGoodServicesInput}
                                    errorLabel={errorGoodServices ? "Aggiungi almeno un prodotto o servizio" : "Prodotti o servizi non validi"}
                                />
                            </div>
                        </div>
                        {
                            !invoice.stsIsActive && (
                                <div className={'row mt-5'}>
                                    <div className={'col-12'}>
                                        <ReimbursementsOfExpense
                                            customer={invoice.customer}
                                            error={errorReimbursementsOfExpenses}
                                            reimbursementsOfExpenses={reimbursementsOfExpenses}
                                            addReimbursementOfExpense={(newReimbursementOfExpenses: ReimbursementOfExpenseModel) => addReimbursementOfExpense(
                                                newReimbursementOfExpenses,
                                                reimbursementsOfExpenses,
                                                {
                                                    setErrorCustomer,
                                                    setErrorGoodServicesInput,
                                                    setErrorGoodServices,
                                                    setErrorPaymentExpiration,
                                                    setErrorPaymentMode,
                                                    setErrorDateMessage,
                                                    setErrorReimbursementsOfExpenses
                                                },
                                                dispatch,
                                                invoice
                                            )}
                                            deleteReimbursementOfExpense={(index: number) => deleteReimbursementOfExpense(
                                                reimbursementsOfExpenses,
                                                index,
                                                dispatch,
                                                invoice
                                            )}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div className={'row mt-5'}>
                            <div className={'col-12'}>
                                <div className={'invoice-proforma-saveOrEdit-line'} />
                            </div>
                        </div>
                        <div className={'mt-4 d-flex align-items-center'}>
                            <QuickfiscoSwitchInput
                                id={'invoice-customer-save-or-duplicate-stamp'}
                                label={"Addebito bollo"}
                                onChange={checked => dispatch(
                                    setProformaInvoiceStamp(
                                        checked &&
                                        invoice.taxBase !== null &&
                                        invoice.taxBase !== undefined &&
                                        invoice.taxBase > ProformaInvoiceService.stampActivationThreshold)
                                )}
                                checked={invoice.stamp === true}
                            />
                            <div
                                className={'invoice-proforma-saveOrEdit-title ms-5'}>{formatNumberIT(ProformaInvoiceService.stampAmount)}€
                            </div>
                        </div>
                        <div className={'row mt-3'}>
                            <div className={'col-12'}>
                                <div className={'invoice-customer-saveOrEdit-line'} />
                            </div>
                        </div>
                        <div className={'mt-3'}>
                            <InvoiceAmount
                                invoice={invoice}
                                invoiceSign={'proforma'}
                                stsIsActive={invoice.stsIsActive}
                                stampId={invoice.stampId}
                                setStampId={(id) => {
                                    if (id.length === 0) {
                                        dispatch(setProformaStampId(undefined));
                                    } else {
                                        dispatch(setProformaStampId(id));
                                    }
                                }}
                                error={errorStampId}
                                errorLabel={"La marca da bollo è obbligatoria per fatture superiori a 77,47€ o Idetificativo marca da bollo non corretto"}
                            />
                        </div>
                        <div className={'row mt-5'}>
                            <div className={'col-12'}>
                                <QuickfiscoInputFile
                                    preview={true}
                                    multiple={true}
                                    maxHeight={'300px'}
                                    fileList={fileList}
                                    setFileList={_fileList => {
                                        dispatch(setProformaInvoiceFileList(_fileList));
                                    }}
                                    error={errorFiles}
                                    errorLabel={"I file inseriti non sono validi"}
                                />
                            </div>
                        </div>
                        <div className={'row mt-4'}>
                            <div className={'col-xl-3 col-12'}>
                                <QuickfiscoCheckbox
                                    id={'invoice-proforma-save-or-duplicate-pdf-mail-language'}
                                    label={'Invia PDF in inglese'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setProformaInvoicePdfMailLanguage('EN'))
                                        } else {
                                            dispatch(setProformaInvoicePdfMailLanguage(null))
                                        }
                                    }}
                                    checked={invoice.language === 'EN'}
                                />
                            </div>
                            <div className={'col-xl-3 col-4'}>
                                {
                                    status === 'loading' ? (
                                        <div className={'w-100 d-flex justify-content-center align-items-center'}>
                                            <QuickfiscoSpinner />
                                        </div>
                                    ) : (
                                        <QuickfiscoButton
                                            id={'invoice-proforma-save-button'}
                                            label={"SALVA"}
                                            onClick={() => saveOrEdit(
                                                dispatch,
                                                operation,
                                                invoice,
                                                setStatus,
                                                {
                                                    setErrorCustomer,
                                                    setErrorPaymentExpiration,
                                                    setErrorGoodServices,
                                                    setErrorGoodServicesInput,
                                                    setErrorDateMessage,
                                                    setErrorReimbursementsOfExpenses,
                                                    setErrorTrackedPayment,
                                                    setErrorSendData,
                                                    setErrorEnasarco,
                                                    setErrorStampId,
                                                },
                                                fileList,
                                                setErrorFiles,
                                                reimbursementsOfExpenses
                                            )}
                                            type={(userIsNotEnabled) && (invoice.stsIsActive && user.stsEnabled) ? 'disabled' : 'primary'}
                                        />
                                    )
                                }
                            </div>
                            <div className={'col-xl-3 col-4'}>
                                <QuickfiscoButton
                                    id={'invoice-proforma-pdf-button'}
                                    label={"VEDI ANTEPRIMA"}
                                    type={operation !== 'edit' || userIsNotEnabled && (invoice.stsIsActive && user.stsEnabled) ? "disabled" : 'primary'}
                                    onClick={() => setSeePreviewPdfOpen(true)}
                                />
                            </div>
                            <div className={'col-xl-3 col-4'}>
                                <QuickfiscoButton
                                    id={'invoice-proforma-send-button'}
                                    label={"INVIA"}
                                    type={operation !== 'edit' || userIsNotEnabled && !invoiceIsSendable && (invoice.stsIsActive && user.stsEnabled) ? 'disabled' : 'secondary'}
                                    onClick={() => setSendSectionOpen(true)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SeePreviewPdfModal
                open={seePreviewPdfOpen}
                setOpen={(open: boolean) => setSeePreviewPdfOpen(open)}
                invoiceSign={'proforma'}
                onClick={() => {
                    if (invoice.type === undefined) {
                        return
                    }

                    getPdf(
                        setDownloadPdfStatus,
                        invoice.type,
                        invoice.id,
                        invoice.number,
                        invoice.customer?.companyName
                    )
                }}
                downloadPdfStatus={downloadPdfStatus}
            />
            <SendProformaInvoice
                open={sendSectionOpen}
                setOpen={(open: boolean) => setSendSectionOpen(open)}
                loading={globalStatus === 'loading'}
                onClick={() => send(
                    dispatch,
                    navigate,
                    setSendSectionOpen,
                    invoice.id
                )}
            />
        </div>
    )
}

function saveOrEdit(
    dispatch: Function,
    operation: Operation,
    invoice: InvoiceModel,
    setStatus: SetState<PromiseStatuses>,
    errorSetters: InvoiceErrorSetters,
    fileList: File[],
    setErrorFiles: SetState<boolean>,
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[]
): void {
    setErrorFiles(false);

    const service = new ProformaInvoiceService(errorSetters);

    if (!service.validate(invoice, 'proforma')) {
        return;
    }

    if (!validateFiles(fileList)) {
        setErrorFiles(true);
        return;
    }

    if (operation === 'duplicate' || operation === 'save') {
        setStatus('loading');
        service
            .save({ ...invoice }, [...fileList], [...reimbursementsOfExpenses])
            .then(id => {
                dispatch(setProformaInvoiceSaveOrDuplicateOrEditInvoiceId(id));
                dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('edit'));
                dispatch(setProformaInvoiceSaveOrDuplicateOrEditSuccessfullySaved(true));
            })
            .catch(err => handleSaveOrEditError(err, setStatus, errorSetters));
    } else {
        dispatch(editProformaInvoice({ invoice: { ...invoice }, fileList: [...fileList], reimbursementsOfExpenses: [...reimbursementsOfExpenses], errorSetters: errorSetters }))
        setStatus('successfully');
    }
}

function handleSaveOrEditError(
    err: any,
    setStatus: SetState<PromiseStatuses>,
    errorSetters: InvoiceErrorSetters,
): void {
    setStatus('failed');

    console.error(err);
}

function send(
    dispatch: Function,
    navigate: NavigateFunction,
    setModalOpen: SetState<boolean>,
    invoiceId?: string
): void {
    if (!invoiceId) {
        return;
    }

    const service = new ProformaInvoiceService();

    dispatch(setGlobalStatus({
        status: 'loading'
    }));
    service
        .send(invoiceId)
        .then(() => {
            dispatch(setGlobalStatus({
                status: 'successfully'
            }));
            navigate(uri.ProformaInvoice);
        })
        .catch(err => {
            err
                .json()
                .then((data: any) => {
                    if (data.message === 'error.invalid-date') {
                        dispatch(setGlobalStatus({
                            status: 'failed',
                            errorMessage: "La data o il numero della fattura non sono validi"
                        }));
                    }
                });
            dispatch(setGlobalStatus({
                status: 'failed',
                errorMessage: 'Si è verificato un errore durante l\'invio della fattura'
            }));
            setModalOpen(false);
            console.error(err);
        });
}

function getPdf(
    setStatus: SetState<PromiseStatuses>,
    invoiceType: InvoiceType,
    invoiceId?: string,
    invoiceNumber?: string,
    invoiceCompanyName?: string
): void {
    if (!invoiceId) {
        return;
    }

    const service = new ProformaInvoiceService();

    setStatus('loading');
    service
        .getPdf(invoiceId)
        .then(data => {
            setStatus('idle');
            downloadPdf(ProformaInvoiceService.getPdfName('proforma', invoiceType, invoiceNumber, invoiceCompanyName), data, 'application/pdf');
        })
        .catch(err => {
            setStatus('failed');
            console.error(err);
        });
}

function addReimbursementOfExpense(
    newReimbursementOfExpenses: ReimbursementOfExpenseModel,
    reimbursementOfExpenses: ReimbursementOfExpenseModel[],
    errorSetters: InvoiceErrorSetters,
    dispatch: Function,
    invoice: InvoiceModel
): boolean {
    const proformaInvoiceService = new ProformaInvoiceService(errorSetters);

    if (!proformaInvoiceService.validateReimbursementsOfExpenses([newReimbursementOfExpenses])) {
        return false;
    }

    const newReimbursementsOfExpenses = [...reimbursementOfExpenses];

    newReimbursementsOfExpenses.push({
        key: newReimbursementOfExpenses.key,
        description: newReimbursementOfExpenses.description,
        amount: newReimbursementOfExpenses.amount,
        file: newReimbursementOfExpenses.file
    });

    let newReimbursementOfExpensesAmount = 0;
    let currentReimbursementOfExpensesAmount = 0;

    if (newReimbursementOfExpenses.amount) {
        newReimbursementOfExpensesAmount = newReimbursementOfExpenses.amount;
    }
    if (invoice.reimbursementsOfExpensesAmount) {
        currentReimbursementOfExpensesAmount = invoice.reimbursementsOfExpensesAmount;
    }

    dispatch(setProformaInvoiceReimbursementsOfExpenses([
        ...newReimbursementsOfExpenses
    ]));

    dispatch(setProformaInvoiceReimbursementOfExpensesAmount(newReimbursementOfExpensesAmount + currentReimbursementOfExpensesAmount));

    return true;
}

function deleteReimbursementOfExpense(
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[],
    index: number,
    dispatch: Function,
    invoice: InvoiceModel
): void {
    let newReimbursementOfExpenseAmount = 0;
    let currentReimbursementsOfExpensesAmount = 0;

    if (reimbursementsOfExpenses[index].amount) {
        newReimbursementOfExpenseAmount = Number(reimbursementsOfExpenses[index].amount);
    }
    if (invoice.reimbursementsOfExpensesAmount) {
        currentReimbursementsOfExpensesAmount = invoice.reimbursementsOfExpensesAmount;
    }

    const newReimbursementsOfExpenses = reimbursementsOfExpenses.slice(0, index)
        .concat(reimbursementsOfExpenses.slice(index + 1));

    dispatch(setProformaInvoiceReimbursementsOfExpenses([
        ...newReimbursementsOfExpenses
    ]));

    dispatch(setProformaInvoiceReimbursementOfExpensesAmount(currentReimbursementsOfExpensesAmount - newReimbursementOfExpenseAmount));
}