import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './amountInvoicedInfo.json';
import './amountInvoicedInfo.css';

export function AmountInvoicedInfo() {
  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          className="ps-2"
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div
          ref={componentVisible.ref}
          className={'amount-invoiced-info-popup'}
        >
          {componentVisible.isVisible && (
            <div className={'amount-invoiced-info-popup-container pb-1'}>
              <p className={'amount-invoiced-info-text'}>{lang.text}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
