import { useEffect } from 'react';
import { InvoiceFilters } from '../../components/invoiceFilters/invoiceFilters';
import { InvoiceList } from '../../components/invoiceList/invoiceList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAutoInvoiceList, setAutoInvoiceListPage } from '../../redux/slices/autoInvoiceListSlice';
import { useProSidebar } from 'react-pro-sidebar';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './autoInvoiceList.json';

export function AutoInvoiceList() {
  const dispatch = useAppDispatch()
  const newInvoice = useNewInvoice('auto')

  const autoInvoiceListState = useAppSelector(state => state.autoInvoiceList)
  const userState = useAppSelector((state) => state.user.user);

  const autoInvoices = autoInvoiceListState.invoices

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (autoInvoiceListState.page === undefined) {
      dispatch(setAutoInvoiceListPage(0))
    }
  }, [])

  useEffect(() => {
    dispatch(getAutoInvoiceList({
      page: autoInvoiceListState.page,
      month: autoInvoiceListState.monthFilter,
      year: autoInvoiceListState.yearFilter,
      companyName: autoInvoiceListState.companyNameFilter,
      status: autoInvoiceListState.statusFilter
    }))
  }, [
    autoInvoiceListState.page,
    autoInvoiceListState.monthFilter,
    autoInvoiceListState.yearFilter,
    autoInvoiceListState.companyNameFilter,
    autoInvoiceListState.statusFilter
  ])

  useEffect(() => {
    if (autoInvoiceListState.page !== undefined && autoInvoiceListState.page > 0 && autoInvoiceListState.invoices.content.length === 0) {
      dispatch(setAutoInvoiceListPage(autoInvoiceListState.page - 1))
    }
  }, [autoInvoiceListState.invoices.content])


  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='auto-invoice-list-menu'
            menuItem={MenuItems.AUTO_INVOICE}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row'}>
                <div className={'col'}>
                  <QuickfiscoHeader title={lang.title} />
                  {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
                </div>
              </div>
              <div className={'row mt-4'}>
                <div className={'col-11'}>
                  <InvoiceFilters invoiceSign={'auto'} />
                </div>
                <div className={'col-1 d-flex justify-content-end'}>
                  <QuickfiscoIcon
                    name={'new.svg'}
                    className={'w-f-35'}
                    isButton={true}
                    onClick={() => newInvoice()}
                  />
                </div>
              </div>
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <InvoiceList invoiceSign={'auto'} />
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className={'col-12'}>
                  <div className={'d-flex justify-content-center'}>
                    <QuickfiscoPagination
                      totalPages={autoInvoices.totalPages}
                      number={autoInvoices.number}
                      empty={autoInvoices.empty}
                      doPagination={(page: number) => dispatch(setAutoInvoiceListPage(page))}
                      first={autoInvoices.first}
                      last={autoInvoices.last}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
