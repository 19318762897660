import { SingleValue } from 'react-select';
import { PaymentModeType } from '../../models/invoiceModel';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './paymentModeSelect.json';

interface Props {
  id: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?: ((paymentMode: PaymentModeType | undefined) => void);
}

export function PaymentModeSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { error } = props;
  const { value } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.Label}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(PaymentModeType[e.value])
        }
      }}
      error={error}
      errorLabel={lang.Error}
      options={getOptions(value)}
      styleType={'default'}
      requiredField={true}
      disabled={disabled}
      value={getOptions(value).filter(option => option.selected === true)[0]}

    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Seleziona la modalità di pagamento',
      value: undefined,
      selected: value === undefined
    },
    {
      label: 'Contanti',
      value: PaymentModeType.MP01,
      selected: value === PaymentModeType.MP01
    },
    {
      label: 'Assegno',
      value: PaymentModeType.MP02,
      selected: value === PaymentModeType.MP02
    },
    {
      label: 'Bonifico',
      value: PaymentModeType.MP05,
      selected: value === PaymentModeType.MP05
    },
    {
      label: 'Carta di credito / Pagamento Digitale(PayPal, Satispay ecc)',
      value: PaymentModeType.MP08,
      selected: value === PaymentModeType.MP08
    },
  ];
}
