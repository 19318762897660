import { PromiseStatuses } from '../../types/strings';
import { useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  getItemWithExipiration,
  setItemWithExipiration,
} from '../../utils/localStorage';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import lang from './taxReturnSectionTrial.json';
import './taxReturnSectionTrial.css';

export function TaxReturnSectionTrial() {
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector((state) => state.user.user);

  return (
    <div className={'row mt-4'}>
      <QuickfiscoSuccess
        message={lang.sendSuccess}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={lang.sendError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <div className="col-12">
        <div className="tax-return-section-trial-container box-shadow p-4">
          <div className="tax-return-section-trial-container-border d-flex align-items-center justify-content-center p-4">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-12 tax-return-section-trial-title">
                {lang.title1}
                <br />
                {lang.title2}
              </div>
              {userState.status === 4 && (
                <>
                  {getItemWithExipiration('stopSubscriptionEmail') ===
                  'true' ? (
                    <div className="col-3 my-5 tax-return-section-request-sent">
                      <QuickfiscoButton
                        id={'tax-return-section-trial-cta'}
                        label={lang.requestSent}
                        type={'disabled'}
                      />
                    </div>
                  ) : (
                    <>
                      {status === 'loading' ? (
                        <div
                          className={
                            'd-flex justify-content-center align-items-center w-100 my-5'
                          }
                        >
                          <QuickfiscoSpinner />
                        </div>
                      ) : (
                        <div className="col-3 my-5">
                          <QuickfiscoButton
                            id={'tax-return-section-trial-cta'}
                            label={lang.cta}
                            type={'secondary'}
                            onClick={() => activateSubscription(setStatus)}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-12 tax-return-section-trial-info mt-2">
                    {lang.info1}
                    <br />
                    {lang.info2}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function activateSubscription(setStatus: SetState<PromiseStatuses>) {
  const service = new UserService();

  setStatus('loading');
  service
    .sendActivateSubscriptionEmail()
    .then(() => {
      setStatus('successfully');
      setItemWithExipiration('stopSubscriptionEmail', {
        value: 'true',
        expireTime: Date.now() + 1000 * 60 * 60 * 24 * 10,
      });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
