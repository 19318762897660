import { useProSidebar } from 'react-pro-sidebar';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { StsProfileEdit } from '../../components/stsProfileEdit/stsProfileEdit';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import lang from './stsConfiguration.json';

export function StsConfiguration() {
  const { collapsed } = useProSidebar();
  
  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='sts-configuration-menu'
            menuItem={MenuItems.STS_CONFIGURATION}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row'}>
                <div className={'col'}>
                  <QuickfiscoHeader title={lang.Title} />
                </div>
              </div>
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <StsProfileEdit />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
