import { InvoiceErrorSetters } from '../models/invoiceErrorSetters';
import { InvoiceFilters } from '../models/invoiceFilters';
import { InvoiceModel, InvoiceStatusType } from '../models/invoiceModel';
import { PaginationModel } from '../models/paginationModel';
import { host } from '../utils/config';
import { getDateRangeByYearAndMonth } from '../utils/date';
import { CommonInvoiceService } from './commonInvoiceService';

export class AutoInvoiceService extends CommonInvoiceService {

  public constructor(errorSetters?: InvoiceErrorSetters) {
    super(host + '/api/v1/invoices/auto-invoice', errorSetters);
  }

  public static isEditable(invoice: InvoiceModel): boolean {
    return invoice.status === InvoiceStatusType.DRAFT || invoice.status === InvoiceStatusType.ALREADY_TRANSMITTED || invoice.status === InvoiceStatusType.REJECTED;
  }

  public static isSendable(invoice: InvoiceModel): boolean {
    return invoice.status === InvoiceStatusType.DRAFT || invoice.status === InvoiceStatusType.REJECTED;
  }

  public findAll(filters: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> {
    const {
      dateFrom,
      dateTo
    } = getDateRangeByYearAndMonth(filters.year, filters.month);

    return this.http.sendAndReceive({
      method: 'get',
      url: this.url +
        '?fromDate=' + dateFrom +
        '&toDate=' + dateTo +
        (filters.type !== undefined ? '&type=' + filters.type : '') +
        (filters.status !== undefined ? '&status=' + filters.status : '')
    })
      .then(res => res.json())
      .then((data: PaginationModel<InvoiceModel>) => data);
  }

  public async findById(id: string): Promise<{ invoice: InvoiceModel, files: File[] }> {
    let invoice = await this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + id
    })
      .then(res => res.json())
      .then((data: InvoiceModel) => data);

    invoice = CommonInvoiceService.setGoodServiceKey(invoice);

    let files: File[] = []
    if (invoice.documents !== undefined && invoice.documents !== null) {
      files = await super.getFiles(invoice.documents);
    }

    return {
      invoice,
      files
    };
  }

  public send(id: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + id + '/send'
    })
      .then(() => undefined);
  }

}
