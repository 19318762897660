import { useProSidebar } from 'react-pro-sidebar';
import { NotSavedProfileModal } from '../../components/notSavedProfileModal/notSavedProfileModal';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';
import { getMe, setProfileEditStatus } from '../../redux/slices/userSlice';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoError } from '../../components/quickfiscoError/quickfiscoError';
import { QuickfiscoSuccess } from '../../components/quickfiscoSuccess/quickfiscoSuccess';
import { ProfileSubscription } from '../../components/profileSubscription/profileSubscription';
import { ProfileLogo } from '../../components/profileLogo/profileLogo';
import { ProfilePassword } from '../../components/profilePassword/profilePassword';
import { ProfileConsent } from '../../components/profileConsent/profileConsent';
import { OnboardingStatusBoxBanner } from '../../components/onboardingStatusBoxBanner/onboardingStatusBoxBanner';
import { ExtraPaidServices } from '../../components/extraPaidServices/extraPaidServices';
import { BringAFriendBox } from '../../components/bringAFriendBox/bringAFriendBox';
import lang from './subscriptionAndSettings.json';

export function SubscriptionAndSettings() {
  const userState = useAppSelector((state) => state.user);

  const userStatus = userState.status;
  const editStatus = userState.editStatus;

  const dispatch = useAppDispatch();

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (editStatus === 'successfully') {
      dispatch(getMe());
    }
  }, [editStatus]);

  if (userStatus === 'loading' || editStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userStatus === 'failed') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        Si è verificato un errore durante il caricamento dei dati.
      </div>
    );
  }

  return (
    <div className="full-screen bg-blue">
      <QuickfiscoSuccess
        active={userState.editStatus === 'successfully'}
        message={lang.SuccessMessage}
        close={() => dispatch(setProfileEditStatus('idle'))}
      />
      <QuickfiscoError
        active={userState.editStatus === 'failed'}
        message={lang.ErrorMessage}
        close={() => dispatch(setProfileEditStatus('idle'))}
      />
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="subscription-settings-menu"
            menuItem={MenuItems.PROFILE}
          />
        </div>
        <div
          className="col no-gutters"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row no-gutters ps-4">
            <div className="col-12 p-4">
              <div className={'row'}>
                <div className={'col-12'}>
                  <QuickfiscoHeader title={lang.Title} />
                </div>
              </div>
              <div className="row mt-2 text-center">
                <div className={'col-12 mt-3'}>
                  {userState.user.status === 6 ? (
                    <OnboardingStatusBoxBanner />
                  ) : (
                    <div className={'row'}>
                      <div className={'col-12'}>
                        <ProfileSubscription />
                      </div>
                    </div>
                  )}
                  <div className={'row'}>
                    <div
                      className={'col-12 col-xxl-6 order-2 order-xxl-1 mt-4'}
                    >
                      <div className="row">
                        <div className="col-12">
                          <ProfileConsent />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <ExtraPaidServices />
                        </div>
                      </div>
                    </div>
                    <div
                      className={'col-12 col-xxl-6 order-1 order-xxl-2 mt-4'}
                    >
                      <div className={'row'}>
                        <div className={'col-6'}>
                          <div className={'row'}>
                            <div className={'col-12'}>
                              <ProfileLogo />
                            </div>
                          </div>
                          <div className={'row mt-4'}>
                            <div className={'col-12'}>
                              <BringAFriendBox />
                            </div>
                          </div>
                        </div>
                        <div className={'col-6'}>
                          <ProfilePassword />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotSavedProfileModal />
        </div>
      </div>
    </div>
  );
}
