import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { FinanceComponent } from '../../components/finance/finance';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { useAppDispatch } from '../../redux/hooks';
import { findUserFinance } from '../../redux/slices/financeSlice';

export function FinanceView() {
  const { collapsed } = useProSidebar();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findUserFinance());
  }, []);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters" style={{ minHeight: '100vh' }}>
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu id="finance" menuItem={MenuItems.FINANCE} />
        </div>
        <div
          className="col py-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="row">
            <div className="col">
              <QuickfiscoHeader title={'Finanziamenti'} />
            </div>
          </div>
          <div className={'row mt-4'} style={{ flexGrow: 1 }}>
            <div className={'col-12'} style={{ flexGrow: 1 }}>
              <FinanceComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
