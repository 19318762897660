import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './trackedPaymentSelect.json';

interface Props {
  id: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  errorLabel?: string;
  onChange?: ((trackedPayment?: string) => void);
}

export function TrackedPaymentSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { error } = props;
  const { disabled } = props;
  const { errorLabel } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.title}
      options={getOptions(value)}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          onChange && onChange(e.value)
        }
      }}
      disabled={disabled}
      error={error}
      errorLabel={errorLabel}
      requiredField={true}
      styleType={'default'}
      value={getOptions(value).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Effettua una selezione',
      value: undefined,
      selected: value === undefined
    },
    {
      label: 'SI',
      value: 'SI',
      selected: value === 'SI'
    },
    {
      label: 'NO',
      value: 'NO',
      selected: value === 'NO'
    },
  ];
}
