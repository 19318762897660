import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../views/login/login';
import { Register } from '../views/register/register';
import { ResetPassword } from '../views/resetPassword/resetPassword';
import uri from './quickfiscoUri.json';

export function DefaultRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={uri.ResetPassword + '/:userId/:token'}
          element={<ResetPassword />}
        />
        <Route path={uri.Register} element={<Register />} />
        <Route path={uri.Login} element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
