import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { BankAccount } from '../../models/userModel';

interface Props {
    id: string;
    error?: boolean;
    onChange?: ((bankIban: string) => void);
    errorLabel?: string;
    disabled?: boolean;
    requiredField?: boolean;
    value: BankAccount;
}

export function QuickfiscoBankSelect(props: Props) {
    const { id } = props;
    const { error } = props;
    const { onChange } = props;
    const { errorLabel } = props;
    const { disabled } = props;
    const { requiredField } = props;
    const { value } = props;

    const userState = useAppSelector(state => state.user);

    return (
        <QuickfiscoSelect
            id={id}
            requiredField={requiredField}
            label={"Banca"}
            styleType={'default'}
            error={error}
            errorLabel={errorLabel}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {

                    if (e.value !== undefined) {
                        onChange && onChange(e.value)
                    }
                }
            }}
            options={getAllBank(userState.user.bankAccount, value)}
            disabled={disabled}
            value={getAllBank(userState.user.bankAccount, value).filter(option => option.selected === true)[0]}
        />
    );
}

function getAllBank(
    bankList: BankAccount[],
    bankValue: BankAccount
): OptionModel[] {
    let bankOption: OptionModel[] = [];

    bankList.forEach((bank, index) => {
        bankOption.push(
            {
                value: `${bank.iban}`,
                label: `Banca 0${index + 1} - ${bank.name}`,
                selected: `${bankValue.iban}` === `${bank.iban}`
            }
        )
    });


    return bankOption;
}

