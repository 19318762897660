import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  type: '5x1000' | '8x1000';
  value?: string;
  onChange?: (institution?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoBeneficiaryInstitutionsSelect(props: Props) {
  const { id } = props;
  const { type } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'Seleziona una delle seguenti istituzioni beneficiarie'}
      options={getOptions(type)}
      styleType={'default'}
      value={
        getOptions(type, value).filter((option) => option.selected === true)[0]
      }
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
      isMenuPlacementTop={true}
    />
  );
}
function getOptions(type: '5x1000' | '8x1000', value?: string): OptionModel[] {
  if (type === '8x1000') {
    return [
      {
        label: 'Seleziona',
        value: undefined,
        selected: value === undefined || value === null,
      },
      { label: 'Stato', value: 'Stato', selected: value === 'Stato' },
      {
        label: 'Chiesa Cattolica',
        value: 'Chiesa Cattolica',
        selected: value === 'Chiesa Cattolica',
      },
      {
        label: 'Unione Chiese Cristiane Avventiste Del 7° Giorno',
        value: 'Unione Chiese Cristiane Avventiste Del 7° Giorno',
        selected: value === 'Unione Chiese Cristiane Avventiste Del 7° Giorno',
      },
      {
        label: 'Assemblee Di Dio In Italia',
        value: 'Assemblee Di Dio In Italia',
        selected: value === 'Assemblee Di Dio In Italia',
      },
      {
        label: 'Chiesa Evangelica Valdese',
        value: 'Chiesa Evangelica Valdese',
        selected: value === 'Chiesa Evangelica Valdese',
      },
      {
        label: 'Chiesa Evangelica Luterana In Italia',
        value: 'Chiesa Evangelica Luterana In Italia',
        selected: value === 'Chiesa Evangelica Luterana In Italia',
      },
      {
        label: 'Unione Comunità Ebraiche Italiane',
        value: 'Unione Comunità Ebraiche Italiane',
        selected: value === 'Unione Comunità Ebraiche Italiane',
      },
      {
        label:
          'Sacra Arcidiocesi Ortodossa D’Italia Ed Esarcato Per L’Europa Meridionale',
        value:
          'Sacra Arcidiocesi Ortodossa D’Italia Ed Esarcato Per L’Europa Meridionale',
        selected:
          value ===
          'Sacra Arcidiocesi Ortodossa D’Italia Ed Esarcato Per L’Europa Meridionale',
      },
      {
        label: 'Chiesa Apostolica In Italia',
        value: 'Chiesa Apostolica In Italia',
        selected: value === 'Chiesa Apostolica In Italia',
      },
      {
        label: 'Unione Cristiana Evangelica Battista D’Italia',
        value: 'Unione Cristiana Evangelica Battista D’Italia',
        selected: value === 'Unione Cristiana Evangelica Battista D’Italia',
      },
      {
        label: 'Unione Buddhista Italiana',
        value: 'Unione Buddhista Italiana',
        selected: value === 'Unione Buddhista Italiana',
      },
      {
        label: 'Unione Induista Italiana',
        value: 'Unione Induista Italiana',
        selected: value === 'Unione Induista Italiana',
      },
      {
        label: 'Istituto Buddista Italiano Soka Gakkai',
        value: 'Istituto Buddista Italiano Soka Gakkai',
        selected: value === 'Istituto Buddista Italiano Soka Gakkai',
      },
      {
        label: 'Non Ho Preferenze',
        value: 'Non Ho Preferenze',
        selected: value === 'Non Ho Preferenze',
      },
    ];
  } else {
    return [
      {
        label: 'Seleziona',
        value: undefined,
        selected: value === undefined || value === null,
      },
      {
        label: 'Sostegno degli enti del terzo settore iscritti nel runts',
        value: 'Sostegno degli enti del terzo settore iscritti nel runts',
        selected:
          value === 'Sostegno degli enti del terzo settore iscritti nel runts',
      },
      {
        label: 'Finanziamento della ricerca scientifica e della università',
        value: 'Finanziamento della ricerca scientifica e della università',
        selected:
          value ===
          'Finanziamento della ricerca scientifica e della università',
      },
      {
        label: 'Finanziamento della ricerca sanitaria',
        value: 'Finanziamento della ricerca sanitaria',
        selected: value === 'Finanziamento della ricerca sanitaria',
      },
      {
        label: 'Finanziamento delle attività di tutela',
        value: 'Finanziamento delle attività di tutela',
        selected: value === 'Finanziamento delle attività di tutela',
      },
      {
        label: 'Promozione e valorizzazione dei beni culturali e paesaggistici',
        value: 'Promozione e valorizzazione dei beni culturali e paesaggistici',
        selected:
          value ===
          'Promozione e valorizzazione dei beni culturali e paesaggistici',
      },
      {
        label: 'Sostegno delle attività sociali svolte dal comune di residenza',
        value: 'Sostegno delle attività sociali svolte dal comune di residenza',
        selected:
          value ===
          'Sostegno delle attività sociali svolte dal comune di residenza',
      },
      {
        label: 'Sostegno alle associazioni sportive dilettantistiche',
        value: 'Sostegno alle associazioni sportive dilettantistiche',
        selected:
          value === 'Sostegno alle associazioni sportive dilettantistiche',
      },
      {
        label: 'Sostegno degli enti gestori delle aree protette',
        value: 'Sostegno degli enti gestori delle aree protette',
        selected: value === 'Sostegno degli enti gestori delle aree protette',
      },
      {
        label: 'Non Ho Preferenze',
        value: 'Non Ho Preferenze',
        selected: value === 'Non Ho Preferenze',
      },
    ];
  }
}
