import { ContactType } from '../../models/contactModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCustomerListFilterCompanyName } from '../../redux/slices/customerListSlice';
import { setSupplierListFilterCompanyName } from '../../redux/slices/supplierListSlice';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import lang from './contactFilters.json';

interface Props {
  type: ContactType;
}

export function ContactFilters(props: Props) {
  const { type } = props;

  const dispatch = useAppDispatch();

  const customersListState = useAppSelector(state => state.customerList)
  const supplierListState = useAppSelector(state => state.supplierList)

  switch (type) {
    case ContactType.CUSTOMER:
      return (
        <div className={'row d-flex align-items-center'}>
          <div className={'col-10'}>
            <QuickfiscoInput
              id={'contact-filters-customer-input'}
              type={'text'}
              styleType={'no-borders'}
              placeholder={lang.CustomerPlaceholder}
              defaultValue={customersListState.filterCompanyName}
              onChange={e => dispatch(setCustomerListFilterCompanyName(e.target.value))}
            />
          </div>
        </div>
      )
    case ContactType.SUPPLIER:
      return (
        <div className={'row d-flex align-items-center'}>
          <div className={'col-10'}>
            <QuickfiscoInput
              id={'contact-filters-supplier-input'}
              type={'text'}
              styleType={'no-borders'}
              placeholder={lang.SupplierPlaceholder}
              defaultValue={supplierListState.filterCompanyName}
              onChange={e => dispatch(setSupplierListFilterCompanyName(e.target.value))}
            />
          </div>
        </div>
      )
    default:
      return (
        <div>Tipo contatto non valido</div>
      )
  }
}
