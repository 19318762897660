import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoInfoPopup.css';

interface Props {
  id: string;
  text: string;
}

export function QuickfiscoInfoPopup(props: Props) {
  const { id } = props;
  const { text } = props;

  const componentVisible = useComponentVisible(false);

  return (
    <div id={id}>
      <div className={'position-relative'}>
        <div
          className="ps-2"
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div ref={componentVisible.ref} className={'quickfisco-info-popup'}>
          {componentVisible.isVisible && (
            <div className={'quickfisco-info-popup-container pb-1'}>
              <p className={'quickfisco-info-popup-infotext'}>{text}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
