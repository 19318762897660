import './quickfiscoRadioWithBox.css';

interface Props {
  id: string;
  value: string;
  name?: string;
  label?: string;
  checked?: boolean;
  onClick?: Function;
}

export function QuickfiscoRadioWithBox(props: Props) {
  const { id } = props;
  const { value } = props;
  const { name } = props;
  const { label } = props;
  const { checked } = props;
  const { onClick } = props;

  return (
    <div
      className={`w-100 ${
        checked
          ? 'quickfisco-input-radio-box-container-selected'
          : 'quickfisco-input-radio-box-container'
      } d-flex align-items-start align-items-center justify-content-start`}
      onClick={() => onClick && onClick()}
    >
      <input
        id={id}
        className={'quickfisco-input-radio-box'}
        type={'radio'}
        name={name}
        checked={checked}
        value={value}
        onChange={() => onClick && onClick()}
      />
      <div>
        <label
          className={
            checked
              ? 'quickfisco-input-radio-box-label-selected ms-2'
              : 'quickfisco-input-radio-box-label ms-2'
          }
        >
          {label}
        </label>
      </div>
    </div>
  );
}
