import { useProSidebar } from 'react-pro-sidebar';
import { FeesRegisterFilters } from '../../components/feesRegisterFilters/feesRegisterFilters';
import { FeesRegisterTable } from '../../components/feesRegisterTable/feesRegisterTable';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { host } from '../../utils/config';
import { useAppSelector } from '../../redux/hooks';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './feesRegister.json';

export function FeesRegister() {
    const { collapsed } = useProSidebar();

    const feesRegisterState = useAppSelector(state => state.feesRegister);
    const userState = useAppSelector((state) => state.user.user);
    
    return (
        <div className="full-screen bg-blue">
            <ViewportWarning />
            <div className="row no-gutters">
                <div className='col-auto no-gutters bg-blue'>
                    <QuickfiscoMenu
                        id='fees-register-menu'
                        menuItem={MenuItems.FEES_REGISTER}
                    />
                </div>
                <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <QuickfiscoHeader title={lang.Title} />
                            {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <FeesRegisterFilters />
                                </div>
                            </div>
                            <div className='row mt-4' >
                                <div className='col-12'>
                                    <FeesRegisterTable />
                                </div>
                            </div>
                            <div className='row mt-4' >
                                <div className='col-6 offset-3'>
                                    <a href={host + '/api/v1/fees-register/excel?year=' + feesRegisterState.yearFilter} target={'_blank'} rel={'noreferrer'}>
                                        <QuickfiscoButton
                                            id={'tax-declaration-procedure'}
                                            label={`STAMPA REGISTRO CORRISPETTIVI ${feesRegisterState.yearFilter}`}
                                            type={'secondary'}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
