import { useState } from 'react';
import config from '../../../config.json';
import { InvoicePaymentModel } from '../../models/invoicePaymentModel';
import { useAppDispatch } from '../../redux/hooks';
import { findCustomerInvoiceById } from '../../redux/slices/customerInvoiceSlice';
import { findSupplierInvoiceById } from '../../redux/slices/supplierInvoiceSlice';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { InvoiceSupplierService } from '../../services/supplierInvoiceService';
import { SetState } from '../../types/functions';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { formatDateIT } from '../../utils/date';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './invoicePaymentRow.css';
import lang from './invoicePaymentRow.json';

interface Props {
  payment: InvoicePaymentModel;
  invoiceSign: InvoiceSign;
}

export function InvoicePaymentRow(props: Props) {
  const { payment } = props;
  const { invoiceSign } = props;

  const dispatch = useAppDispatch()

  const [deleteStatus, setDeleteStatus] = useState<PromiseStatuses>('idle');

  const paymentId = payment.id
  const invoiceId = payment.invoiceId

  if (paymentId === undefined || invoiceId === undefined) {
    return (
      <div>Errore durante il caricamento dei dati</div>
    )
  }

  return (
    <div className={'row mt-3'}>
      <QuickfiscoError
        message={'Si è verificato un errore'}
        active={deleteStatus === 'failed'}
        close={() => setDeleteStatus('idle')}
      />
      <div className={'col-7'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <span className={'invoice-payment-row-title text-left'}>
                  {lang.title}
                </span>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12'}>
                <span className={'invoice-payment-row-subtitle text-left'}>
                  {lang.paymentDate + (payment.receiptDate && formatDateIT(payment.receiptDate))}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'invoice-payment-row-line'} />
          </div>
        </div>
      </div>
      <div className={'col-2'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <span className={'invoice-payment-row-title text-right'}>
                  {lang.amount}
                </span>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12'}>
                <span className={'invoice-payment-row-subtitle text-right'}>
                  {payment.amount + config.EUR}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'invoice-payment-row-line'} />
          </div>
        </div>
      </div>
      <div className={'col-2'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <span className={'invoice-payment-row-title text-right'}>
                  {lang.leftAmount}
                </span>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12'}>
                <span className={'invoice-payment-row-subtitle text-right'}>
                  {payment.leftAmount + config.EUR}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'invoice-payment-row-line'} />
          </div>
        </div>
      </div>
      <div className={'col-1 d-flex align-items-center justify-content-center'}>
        {
          deleteStatus === 'loading' ? (
            <QuickfiscoSpinner />
          ) : (
            <QuickfiscoIcon
              name={'delete-icon.svg'}
              isButton={true}
              onClick={() => {
                deletePayment(
                  invoiceSign,
                  setDeleteStatus,
                  dispatch,
                  invoiceId,
                  paymentId
                );
              }}
            />
          )
        }
      </div>
    </div>
  );
}

function deletePayment(
  invoiceSign: InvoiceSign,
  setStatus: SetState<PromiseStatuses>,
  dispatch: Function,
  invoiceId: string,
  paymentId: string
): void {
  let service: CustomerInvoiceService | InvoiceSupplierService;
  if (invoiceSign === 'customer') {
    service = new CustomerInvoiceService();
  } else {
    service = new InvoiceSupplierService();
  }

  setStatus('loading');
  service
    .deletePayment(invoiceId, paymentId)
    .then(() => {
      setStatus('successfully');

      if (invoiceId !== undefined && invoiceSign === 'customer') {
        dispatch(findCustomerInvoiceById(invoiceId))
      }
      if (invoiceId !== undefined && invoiceSign === 'supplier') {
        dispatch(findSupplierInvoiceById(invoiceId))
      }
    })
    .catch(err => {
      console.error(err);
      setStatus('failed');
    });
}
