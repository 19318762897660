import { DocumentsStatusFiltersType } from '../../models/documentsFilters';
import './documentsReport.css';

interface Props {
  title: string;
  amount?: number;
  statusFilter?: DocumentsStatusFiltersType;
  setStatusFilter?: (type?: DocumentsStatusFiltersType) => void;
  selected?: boolean;
}

export function DocumentsReport(props: Props) {
  const { title } = props;
  const { amount } = props;
  const { statusFilter } = props;
  const { setStatusFilter } = props;
  const { selected } = props;

  return (
    <div
      className={
        selected === true
          ? 'row d-flex align-items-center documents-list-resume-border'
          : 'row d-flex align-items-center documents-list-resume-container'
      }
      onClick={() => {
        setStatusFilter &&
          (!selected
            ? setStatusFilter(statusFilter)
            : setStatusFilter(undefined));
      }}
    >
      <div className={'col-8'}>
        <div className={'documents-list-resume-title'}>{title}</div>
      </div>
      <div className={'col-4'}>
        <div className={'documents-list-resume-black'}>{amount}</div>
      </div>
    </div>
  );
}
