import { RegistrationStepsModel } from "../models/userModel";
import uri from '../routers/quickfiscoPaymentUri.json';

export function routing(obj: RegistrationStepsModel): string {
    if (obj.payment === true) return uri.Mandate
    else if (obj.contract === true) return uri.Payment
    else if (obj.your_documents === true) return uri.Contract
    else if (obj.profile_fiscal === true) return uri.Documents
    else if (obj.profile_registry === true) return uri.FiscalInfo
    else if (obj.email_verification === true) return uri.PersonalInfo
    else if (obj.account_created === true) return uri.EmailVerification
    else return uri.EmailVerification
}