import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import config from '../../../config.json';
import { useAppSelector } from '../../redux/hooks';
import lang from './ registrationCompleted.json';
import './registrationCompleted.css';

interface Props {
  isOnboarding: boolean;
}

export function RegistrationCompleted(props: Props) {
  const { isOnboarding } = props;

  const userState = useAppSelector((state) => state.user.user);

  return (
    <div className={'row pe-5 ps-5 pb-5 pt-4'}>
      <div className={'col-12'}>
        <div className={'row text-center'}>
          <div className={'col-12 mb-4'}>
            <QuickfiscoIcon name={'alert-success.svg'} />
          </div>
        </div>
        <div className={'row mt-3 text-center'}>
          <span className={'registration-cp-title'}>{lang.Title}</span>
        </div>
        {isOnboarding ? (
          <>
            <div className={'registration-cp-description-strong mt-3'}>
              {userState.previousVatStatus === 'new'
                ? lang.OnboardingDescription_1_new
                : lang.OnboardingDescription_1_surroga}
              <br />
              {userState.previousVatStatus === 'new'
                ? lang.OnboardingDescription_2_new
                : lang.OnboardingDescription_2_surroga}
            </div>
            <div className={'registration-cp-description-small mt-4'}>
              {lang.OnboardingDescription_3}
              <br />
              {lang.OnboardingDescription_4}
            </div>
            <div className={'row'}>
              <div className="col-12 no-gutters">
                <hr className="my-5" />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-12 text-center'}>
                <div className={'registration-cp-info-text'}>
                  {lang.info}
                  <br />
                  <a
                    className={'password-recovery-link'}
                    href={`mailto:${config.mail}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {lang.link}
                  </a>
                  {lang.info2}
                  <a
                    href={config.whatsappLink}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {lang.link2}
                  </a>
                </div>
                <p className="registration-cp-info-text mt-3 mx-5 px-5">
                  {lang.OnboardingDescription_info}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={'row mt-3 text-center'}>
              <div className={'registration-cp-description'}>
                {lang.Description1}
              </div>
              <div className={'registration-cp-description-strong'}>
                {lang.Description2}
              </div>
            </div>
            <div className={'row mt-4'}>
              <div
                className={'col-10 offset-1 registration-cp-container-2 p-4'}
              >
                <div className={'registration-cp-message-1 mb-3'}>
                  {lang.Message1}
                </div>
                <div className={'registration-cp-message-any'}>
                  {lang.Message2}
                </div>
                <div className={'registration-cp-message-any'}>
                  {lang.Message3}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
