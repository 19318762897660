import { ComponentVisibleOutput } from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './documentsMenu.json';
import './documentsMenu.css';

interface Props {
  componentVisible: ComponentVisibleOutput;
  openModal: (isOpen: boolean) => void;
  unreadMessage: boolean;
  markAsRead: () => void;
  downloadPdf: () => void;
  downloadPdfStatus: PromiseStatuses;
  setDownloadPdfStatus: (status: PromiseStatuses) => void;
}

export function DocumentsMenu(props: Props) {
  const { componentVisible } = props;
  const { openModal } = props;
  const { unreadMessage } = props;
  const { markAsRead } = props;
  const { downloadPdf } = props;
  const { downloadPdfStatus } = props;
  const { setDownloadPdfStatus } = props;

  let setOpenModal = () => {
    componentVisible.setIsVisible(false);
    openModal(true);
  };

  if (!componentVisible.isVisible) {
    return null;
  }

  return (
    <div className={'contactMenu-container'}>
      <QuickfiscoError
        message={lang.downloadPdfError}
        active={downloadPdfStatus === 'failed'}
        close={() => setDownloadPdfStatus('idle')}
      />
      <div
        className={'contactMenu-item'}
        onClick={() => {
          if (!unreadMessage) {
            setOpenModal();
          } else {
            markAsRead();
          }
        }}
      >
        <div className={'col-auto contactMenu-icon'}>
          <QuickfiscoIcon name={'find.svg'} />
        </div>
        {lang.Label1}
      </div>
      <div className={'contactMenu-line mt-2 mb-2'} />
      {downloadPdfStatus === 'loading' ? (
        <div
          className={'w-100 d-flex justify-content-center align-items-center'}
        >
          <QuickfiscoSpinner alignCenter={true} />
        </div>
      ) : (
        <div className={'contactMenu-item'} onClick={() => downloadPdf()}>
          <div className={'col-auto contactMenu-icon'}>
            <QuickfiscoIcon name={'download.svg'} />
          </div>
          {lang.Label2}
        </div>
      )}
    </div>
  );
}
