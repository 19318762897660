import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './taxationSelect.json';

interface Props {
  id: string;
  value: number;
  requiredField?: boolean;
  onChange?: ((tax: number) => void);
}

interface TaxationModel {
  desc: string;
  taxationValue: number;
}

export function TaxationSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { requiredField } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.label}
      onChange={(e) => e && onChange && onChange(Number(e.value))}
      options={getOptions(value)}
      requiredField={requiredField}
      value={getOptions(value).filter(option => option.selected === true)[0]}
      styleType={'default'}
    />
  );
}

export const taxationList: TaxationModel[] = [
  {
    desc: '5%',
    taxationValue: 0.05
  },
  {
    desc: '15%',
    taxationValue: 0.15
  }
];

function getOptions(value: number): OptionModel[] {
  let taxationOptions: OptionModel[] = [];

  for (let taxationCode of taxationList) {
    taxationOptions.push(
      {
        label: taxationCode.desc,
        value: taxationCode.taxationValue?.toString(),
        selected: value === taxationCode.taxationValue
      }
    );
  }

  return taxationOptions;
}
