import { CheckoutPaymentModel, IntentPaymentModel, PaymentTypeEnum } from '../models/paymentModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class PaymentService {

  private readonly url = host + '/api/v1/product-payments';
  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public paymentCheckout(): Promise<CheckoutPaymentModel> {
    return this.http.sendAndReceive({
      url: this.url + '/checkout',
      method: 'post',
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: CheckoutPaymentModel) => {
        return data
      });
  }

  public updateInvoice(invoiceId: string, promoCode?: string, receiptFileId?: string, receiptDelete?: boolean, removePromoCode?: boolean): Promise<CheckoutPaymentModel> {
    return this.http.sendAndReceive({
      url: this.url + '/invoice/' + invoiceId,
      method: 'put',
      body: JSON.stringify({
        promoCode,
        receiptFileId,
        receiptDelete,
        removePromoCode,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: CheckoutPaymentModel) => {
        return data
      });
  }

  public updateStripePaymentIntent(invoiceId: string): Promise<string> {
    return this.http.sendAndReceive({
      url: this.url + '/invoice/' + invoiceId + '/payment-intent',
      method: 'post',
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: IntentPaymentModel) => {
        return data.clientSecret;
      });
  }

  public completePayment(invoiceId: string, paymentMethod: PaymentTypeEnum): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/invoice/' + invoiceId + '/complete-payment',
      method: 'post',
      body: JSON.stringify({ paymentMethod }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }
}
