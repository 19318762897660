import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CodeModel, LoginModel } from '../../models/loginModel';
import { LoginService } from '../../services/loginService';
import { PromiseStatuses } from '../../types/strings';
import { delItem, getItem, setItem } from '../../utils/localStorage';
import uri from '../../routers/quickfiscoPaymentUri.json'


export enum LoginEnum {
  VALIDATE_LOGIN_THUNK = 'loginValidation/validation',
  RESEND_CODE = 'loginValidation/resend',
  SLICE_NAME = 'login',
  DO_LOGIN_THUNK = 'login/doLogin',
  IS_LOGGED_KEY = 'isLogged',
  TO_VALIDATE = 'toValidate',
  IS_LOGGED_OR_VALIDATE_VALUE = 'true',
  Require2FA = '"require2FA"'
}
interface LoginState {
  loggedIn: boolean;
  validateIn: boolean;
  status: PromiseStatuses;
  codeModel: CodeModel;
  statusSend: PromiseStatuses;
  errorMaxAttempts: boolean;
  errorCode: boolean;
  isOnboarding?: 'login' | 'register';
}

const initialState: LoginState = {
  loggedIn: isLoggedIn(),
  validateIn: isValidable(),
  statusSend: 'idle',
  status: 'idle',
  codeModel: { email: "", password: "", code: "" },
  errorMaxAttempts: false,
  errorCode: false,
};

function isLoggedIn(): boolean {
  return getItem(LoginEnum.IS_LOGGED_KEY) === LoginEnum.IS_LOGGED_OR_VALIDATE_VALUE;
}

function isValidable(): boolean {
  return getItem(LoginEnum.TO_VALIDATE) === LoginEnum.IS_LOGGED_OR_VALIDATE_VALUE;
}

export const doLogin = createAsyncThunk(
  LoginEnum.DO_LOGIN_THUNK,
  async (request: LoginModel): Promise<string> => {
    const service = new LoginService();
    return service.doLogin(request).catch(err => err.json().then((data: any) => Promise.reject(data.message)));;
  }
);

export const validateLogin = createAsyncThunk(
  LoginEnum.VALIDATE_LOGIN_THUNK,
  async (request: CodeModel): Promise<void> => {
    const service = new LoginService();
    return service.validateLogin(request).catch(err => err.json().then((data: any) => Promise.reject(data.message)));
  }
);

export const resendCode = createAsyncThunk(
  LoginEnum.RESEND_CODE,
  async (request: CodeModel): Promise<void> => {
    const service = new LoginService();
    return service.resendCode(request).catch(err => err.json().then((data: any) => Promise.reject(data.message)));;
  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    doLogout: (state) => {
      delItem(LoginEnum.IS_LOGGED_KEY);
      delItem(LoginEnum.TO_VALIDATE);
      state.loggedIn = false;
      state.validateIn = false;
      state.status = 'idle';
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setValidate: (state, action) => {
      state.validateIn = action.payload
    },
    setCode: (state, action) => {
      state.codeModel.code = action.payload
    },
    setEmail: (state, action) => {
      state.codeModel.email = action.payload
    },
    setPassword: (state, action) => {
      state.codeModel.password = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.validateIn = false;
        state.status = 'loading';
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        if (action.payload === LoginEnum.Require2FA) {
          setItem(LoginEnum.TO_VALIDATE, LoginEnum.IS_LOGGED_OR_VALIDATE_VALUE);
          state.validateIn = true;
        } else {
          setItem(LoginEnum.IS_LOGGED_KEY, LoginEnum.IS_LOGGED_OR_VALIDATE_VALUE);
          state.loggedIn = true;
        }
        state.status = 'idle';
      })
      .addCase(doLogin.rejected, (state, action) => {
        state.errorMaxAttempts = false;
        if (action.error.message === '429 Max attempts') {
          state.errorMaxAttempts = true
        }
        state.validateIn = false;
        state.status = 'failed';
      })
      .addCase(validateLogin.pending, (state) => {
        state.validateIn = false;
        state.loggedIn = false;
        state.status = 'loading';
      })
      .addCase(validateLogin.fulfilled, (state) => {
        setItem(LoginEnum.IS_LOGGED_KEY, LoginEnum.IS_LOGGED_OR_VALIDATE_VALUE);
        state.loggedIn = true;
        state.status = 'idle';
      })
      .addCase(validateLogin.rejected, ((state, action) => {
        state.validateIn = false;
        state.errorCode = false;
        if (action.error.message === 'error validation failed') {
          state.validateIn = true;
          state.errorCode = true;
        }
        state.errorMaxAttempts = false;
        if (action.error.message === '429 Max attempts') {
          state.errorMaxAttempts = true
        }
        state.status = 'failed';
      }))
      .addCase(resendCode.pending, (state) => {
        state.statusSend = 'loading';
      })
      .addCase(resendCode.fulfilled, (state) => {
        state.statusSend = 'idle';
      })
      .addCase(resendCode.rejected, ((state) => {
        state.statusSend = 'failed';
      }));
  },
});

export const { doLogout, setValidate, setStatus, setCode, setEmail, setPassword } = loginSlice.actions;

export default loginSlice.reducer;
