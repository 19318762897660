import { useEffect, useState } from 'react';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { UserModel } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import { ObjectService } from '../../services/objectService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import {
  downloadPdf,
  getExtension,
  toBase64,
  validateFile,
} from '../../utils/file';
import { QuickfiscoInputDoc } from '../quickfiscoInputDoc/quickfiscoInputDoc';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { sanitizeString } from '../../utils/string';
import { ObjectModel } from '../../models/objectModel';
import { ObjectFileTaxReturnModel } from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import './taxReturnUploadDoc.css';

interface Props {
  index: number;
  label: string;
  fileName: string;
  saveDoc: (doc: ObjectModel[]) => void;
  deleteDoc: () => void;
  sectionNumber: number;
  disabled?: boolean;
  doc?: ObjectFileTaxReturnModel[] | ObjectModel[];
}

export function TaxReturnUploadDoc(props: Props) {
  const { index } = props;
  const { label } = props;
  const { fileName } = props;
  const { saveDoc } = props;
  const { deleteDoc } = props;
  const { sectionNumber } = props;
  const { disabled } = props;
  const { doc } = props;

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [downloadStatus, setDownloadStatus] = useState<PromiseStatuses>('idle');
  const [docStatus, setDocStatus] = useState<'added' | 'saved' | undefined>(
    undefined
  );

  let currentDoc: ObjectFileTaxReturnModel | undefined = undefined;
  if (doc && doc[0].hasOwnProperty('objectId')) {
    currentDoc = doc[0] as ObjectFileTaxReturnModel;
  }

  useEffect(() => {
    if (doc && doc[0].hasOwnProperty('extension')) {
      setDocStatus('added');
    } else if (doc && doc[0].hasOwnProperty('objectId')) {
      setDocStatus('saved');
    } else {
      setDocStatus(undefined);
    }
  }, [doc]);

  const user = useAppSelector((state) => state.user.user);

  if (status === 'loading') {
    return (
      <div className="tax-return-upload-doc-box w-100 d-flex justify-content-center align-items-center tax-return-upload-doc-loading-container">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="row no-gutters align-items-center align-items-center tax-return-upload-doc-box">
        <div className="col-8 d-flex tax-return-upload-doc-title pe-5">
          {label}
        </div>
        {docStatus !== undefined && (
          <div
            className={`${
              downloadStatus === 'loading' ? 'col-4' : 'col-2'
            } d-flex justify-content-center`}
          >
            {downloadStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <>
                {doc && doc[0].hasOwnProperty('objectId') && (
                  <div
                    className={'tax-return-upload-doc-download-document'}
                    onClick={() => {
                      if (currentDoc && currentDoc.objectId) {
                        getDownloadDoc(
                          currentDoc && currentDoc.objectId,
                          setDownloadStatus
                        );
                      }
                    }}
                  >
                    <QuickfiscoIcon name={'download-negative.svg'} />
                  </div>
                )}
                {doc && doc[0].hasOwnProperty('extension') && (
                  <div className={'tax-return-upload-doc-download-document'}>
                    <QuickfiscoIcon
                      name={'alert-success-small.svg'}
                      className={disabled === true ? 'ms-5' : ''}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {downloadStatus !== 'loading' && (
          <QuickfiscoInputDoc
            docIsPresent={
              (doc && doc[0].hasOwnProperty('extension')) ||
              (doc && doc[0].hasOwnProperty('objectId'))
                ? true
                : false
            }
            onChange={(doc) =>
              saveFile(index, fileName, setStatus, saveDoc, doc, user)
            }
            deleteDoc={() => {
              if (doc && doc[0].hasOwnProperty('extension')) {
                deleteDoc();
              } else if (doc && doc[0].hasOwnProperty('objectId')) {
                deleteDoc();
                const docSaved = doc[0] as ObjectFileTaxReturnModel;
                deleteFileDoc(
                  setStatus,
                  docSaved.objectId,
                  sectionNumber,
                  deleteDoc
                );
              }
            }}
            disabled={disabled}
          />
        )}
      </div>
      {(status === 'failed' || downloadStatus === 'failed') && (
        <div className={'tax-return-upload-doc-container-error mt-2'}>
          Errore durante il salvataggio/cancellazione del file. Si prega di
          riprovare
        </div>
      )}
    </>
  );
}

function saveFile(
  index: number,
  fileName: string,
  setStatus: SetState<PromiseStatuses>,
  saveDoc: Function,
  doc?: File | null,
  user?: UserModel
): ObjectModel[] | undefined {
  setStatus('loading');
  if (!doc || !user) {
    return;
  }

  if (!validateFile(doc)) {
    setStatus('failed');
    return;
  }

  const fileNameString = sanitizeString(
    `${fileName}_${user.surname}_${user.name}_${index + 1}`
  ).toLowerCase();

  toBase64(doc)
    .then((res) => {
      setStatus('successfully');
      saveDoc([
        {
          name: fileNameString + '.' + getExtension(doc),
          extension: getExtension(doc),
          file: res,
        },
      ]);
    })
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}

function getDownloadDoc(
  docId: string,
  setStatus: SetState<PromiseStatuses>
): void {
  const service = new ObjectService();
  setStatus('loading');

  if (docId) {
    service
      .findById(docId)
      .then((data) => {
        const objFile = data;

        if (objFile.id) {
          service
            .findFile(objFile.id)
            .then((data) => {
              setStatus('idle');
              if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
            })
            .catch((err) => {
              setStatus('failed');
              console.error(err);
            });
        }
      })
      .catch((err) => {
        setStatus('failed');
        console.error(err);
      });
  }
}

function deleteFileDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  sectionNumber: number,
  deleteDoc: () => void
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(sectionNumber, docId)
      .then(() => {
        setStatus('successfully');
        deleteDoc();
      })
      .catch((err) => {
        setStatus('failed');
        console.error(err);
      });
  }
}
