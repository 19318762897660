import { NavigateFunction, useNavigate } from 'react-router-dom';
import { InvoiceFilters } from '../../models/invoiceFilters';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import { getStsInvoiceList } from '../../redux/slices/stsInvoiceListSlice';
import uri from '../../routers/quickfiscoUri.json';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './cancelStsSending.css';
import lang from './cancelStsSending.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  invoiceId: string;
}

export function CancelStsSending(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { invoiceId } = props;

  const globalStatus = useAppSelector(state => state.operationStatus.status);
  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)

  const stsInvoiceFilters: InvoiceFilters = {
    page: stsInvoiceListState.page,
    year: new Date().getFullYear().toString(),
    companyName: stsInvoiceListState.companyNameFilter,
    stsStatus: stsInvoiceListState.statusFilter
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
      <QuickfiscoError
        message={lang.error}
        active={globalStatus === 'failed'}
        close={() => dispatch(setGlobalStatus({
          status: 'idle'
        }))}
      />
      <div className={'row mb-4 p-4 pt-0'}>
        <div className={'row mt-2'}>
          <div className={'col-12'}>
            <p className={'cancel-sts-sending-title'}>
              {lang.title}
            </p>
            <p className={'cancel-sts-sending-text'}>
              {lang.text1}
              <span className={'cancel-sts-sending-blue'}>
                {lang.text2}
              </span>
            </p>
          </div>
        </div>
        <div className={'row mt-2'}>
          {
            globalStatus === 'loading' ? (
              <div className={'col-12 d-flex align-items-center justify-content-center'}>
                <QuickfiscoSpinner />
              </div>
            ) : (
              <div className={'col-12'}>
                <div className={'row'}>
                  <div className={'col-7'}>
                    <QuickfiscoButton
                      id={'cancel-sts-sending-email'}
                      label={lang.labelEmail}
                      onClick={() => cancelSending(invoiceId, true, stsInvoiceFilters, dispatch, navigate)}
                      type={'primary'}
                    />
                  </div>
                  <div className={'col-5'}>
                    <QuickfiscoButton
                      id={'cancel-sts-sending'}
                      label={lang.labelSts}
                      onClick={() => cancelSending(invoiceId, false, stsInvoiceFilters, dispatch, navigate)}
                      type={'secondary'}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function cancelSending(
  invoiceId: string,
  sendEmail: boolean,
  stsInvoiceFilters: InvoiceFilters,
  dispatch: Function,
  navigate: NavigateFunction
) {
  const service = new StsInvoiceService();

  dispatch(setGlobalStatus({
    status: 'loading'
  }));
  service.cancelSending(invoiceId, sendEmail)
    .then(() => {
      dispatch(setGlobalStatus({
        status: 'successfully'
      }));

      dispatch(getStsInvoiceList(stsInvoiceFilters));

      navigate(uri.StsHistory);
    })
    .catch(err => {
      dispatch(setGlobalStatus({
        status: 'failed'
      }));
      console.error(err);
    });
}
