import './taxReturnSectionCurrentYear.css';

export function TaxReturnSectionCurrentYear() {
  return (
    <div className={'row'}>
      <div className="col-12">
        <div className={'row'}>
          <div className="col-12 tax-return-section-current-year-title">
            La dichiarazione dei redditi selezionata{' '}
            <span>è inclusa nel tuo</span>
            <br />
            <span>abbonamento</span> e fa riferimento alla dichiarazione dei
            redditi
            <br />
            {(new Date().getFullYear() + 1).toString()} sui redditi prodotti
            nell’anno di imposta {new Date().getFullYear().toString()}.
          </div>
        </div>
        <div className={'row mt-4'}>
          <div className="col-12tax-return-section-current-year-text">
            Quando sarà il momento di predisporre la tua dichiarazione dei
            redditi
            <br />
            {(new Date().getFullYear() + 1).toString()} riceverai comunicazione
            da parte nostra.
          </div>
        </div>
      </div>
    </div>
  );
}
