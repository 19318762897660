import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './numberPeopleSelect.json';

interface Props {
  id: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?: (number: number | undefined) => void;
}

export function NumberPeopleSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { error } = props;
  const { value } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.Label}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(e.value);
        }
      }}
      error={error}
      errorLabel={lang.Error}
      options={getOptions(value)}
      styleType={'default'}
      requiredField={true}
      disabled={disabled}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: '1',
      value: '1',
      selected: value === '1',
    },
    {
      label: '2',
      value: '2',
      selected: value === '2',
    },
    {
      label: '3',
      value: '3',
      selected: value === '3',
    },
    {
      label: '4',
      value: '4',
      selected: value === '4',
    },
    {
      label: '5',
      value: '5',
      selected: value === '5',
    },
    {
      label: '6',
      value: '6',
      selected: value === '6',
    },
    {
      label: '7',
      value: '7',
      selected: value === '7',
    },
    {
      label: '8',
      value: '8',
      selected: value === '8',
    },
    {
      label: '9',
      value: '9',
      selected: value === '9',
    },
    {
      label: '10',
      value: '10',
      selected: value === '10',
    },
  ];
}
