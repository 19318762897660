import { CategoryType } from "../../models/userModel"
import { useAppSelector } from "../../redux/hooks"

export function TaxDeclarationFrame() {
    const user = useAppSelector(state => state.user)

    const categoryLabelMap = new Map<CategoryType | undefined, string>([
        [CategoryType.ARTISAN, 'Artigiano'],
        [CategoryType.FUND, 'Libero Professionista con iscrizione Cassa Ordine Professionale'],
        [CategoryType.NOT_FUND, 'Libero Professionista con iscrizione Gestione Separata INPS'],
        [CategoryType.TRADER, 'Commerciante']
    ])

    return (
        <div className="full-screen bg-blue">
            <iframe
                id="JotFormIFrame-230862519538362"
                title="Dichiarazione dei Redditi 2023 - Su redditi 2022"
                allowTransparency allowFullScreen allow="geolocation; microphone; camera"
                src={"https://form.jotform.com/230862519538362?nomeE[first]=" + user.user.name + "&nomeE[last]=" + user.user.surname + "&aliquota=" + user.user.taxation + "%&comuneDi=" + user.user.city + "&provinciaDi=" + user.user.province + "&piva=" + user.user.vat + "&liberoProfessionista=" + categoryLabelMap.get(user.user.category) + "&sessoprecompilato12=Maschio"}
                style={{ minWidth: '100%', maxWidth: '100%', height: '99vh', border: 'none', overflow: 'hidden' }}
            >
            </iframe>
        </div>
    )
}