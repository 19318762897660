import { useAppSelector } from '../../redux/hooks';
import { useProSidebar } from 'react-pro-sidebar';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { TaxReturnSurvey } from '../../components/taxReturnSurvey/taxReturnSurvey';
import { TaxReturnSectionTrial } from '../../components/taxReturnSectionTrial/taxReturnSectionTrial';
import { UserStatusType } from '../../models/userModel';
import lang from './taxReturn.json';
import './taxReturn.css';

export function TaxReturn() {
  const { collapsed } = useProSidebar();

  const userState = useAppSelector((state) => state.user.user);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="subscription-settings-menu"
            menuItem={MenuItems.TAX_RETURN}
          />
        </div>
        <div
          className="col no-gutters"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row no-gutters ps-4">
            <div className="col-12 pt-4 pe-4 ps-4">
              <div className={'row'}>
                <div className={'col-12'}>
                  <QuickfiscoHeader title={lang.title} />
                </div>
              </div>
              {!(
                userState.status === UserStatusType.ACTIVE ||
                userState.status === UserStatusType.INACTIVE ||
                userState.status === UserStatusType.PAYMENT_DUE
              ) ? (
                <TaxReturnSectionTrial />
              ) : (
                <TaxReturnSurvey />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
