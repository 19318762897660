import { useNavigate } from 'react-router-dom';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import uri from '../../routers/quickfiscoUri.json';
import './financeBox.css';

export function FinanceBox() {
  const navigate = useNavigate();

  return (
    <div className="finance-box-container box-shadow p-4">
      <div className={'row d-flex align-items-center h-100'}>
        <div className={'col-12'}>
          <div className="row">
            <div className="col-12">
              <QuickfiscoIcon
                name={'finance-home.svg'}
                className={'finance-box-img'}
              />
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-12">
              <span className={'finance-box-title'}>
                Cerchi un
                <span className={'finance-box-title-yellow'}>
                  {' '}
                  finanziamento{' '}
                </span>
                per i tuoi progetti?
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <QuickfiscoButton
                id={'finance-box-procedure'}
                label={'SIMULA FINANZIAMENTO'}
                type={'eighth'}
                bold={true}
                onClick={() => {
                  navigate(uri.Finance);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
