import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InvoiceErrorSetters } from "../../models/invoiceErrorSetters";
import { InvoiceModel, ReimbursementOfExpenseModel } from "../../models/invoiceModel";
import { ProformaInvoiceService } from "../../services/proformaInvoiceService";
import { Operation, PromiseStatuses } from "../../types/strings";

interface ProformaInvoiceSaveOrDuplicateOrEditState {
    invoiceId?: string;
    operation: Operation;
    successfullySaved: boolean;
    editStatus: PromiseStatuses
}

const initialState: ProformaInvoiceSaveOrDuplicateOrEditState = {
    operation: 'save',
    successfullySaved: false,
    editStatus: 'idle'
}

export const editProformaInvoice = createAsyncThunk(
    'proformaInvoiceSaveOrDuplicateOrEdit/editProformaInvoice',
    async (request: { invoice: InvoiceModel, fileList: File[], reimbursementsOfExpenses: ReimbursementOfExpenseModel[], errorSetters: InvoiceErrorSetters }): Promise<void> => {
        const proformaInvoiceService = new ProformaInvoiceService(request.errorSetters);

        return proformaInvoiceService.edit(request.invoice, request.fileList, request.reimbursementsOfExpenses)
            .catch(err => {
                err
                    .json()
                    .then((data: any) => {
                        if (data.message === 'error.invalid-date') {
                            request.errorSetters.setErrorDateMessage && request.errorSetters.setErrorDateMessage('Esiste già una fattura con data successiva ma numero inferiore.')
                            request.errorSetters.setErrorDate && request.errorSetters.setErrorDate(true)
                        }
                    })
            })
    }
)

const proformaInvoiceSaveOrDuplicateOrEditSlice = createSlice({
    name: 'proformaInvoiceSaveOrDuplicateOrEdit',
    initialState,
    reducers: {
        setProformaInvoiceSaveOrDuplicateOrEditInvoiceId: (state, action: PayloadAction<string | undefined>) => {
            state.invoiceId = action.payload
        },
        setProformaInvoiceSaveOrDuplicateOrEditOperation: (state, action: PayloadAction<Operation>) => {
            state.operation = action.payload
        },
        setProformaInvoiceSaveOrDuplicateOrEditSuccessfullySaved: (state, action: PayloadAction<boolean>) => {
            state.successfullySaved = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(editProformaInvoice.pending, (state) => {
                state.editStatus = 'loading';
            })
            .addCase(editProformaInvoice.fulfilled, (state) => {
                state.editStatus = 'successfully';
            })
            .addCase(editProformaInvoice.rejected, (state) => {
                state.editStatus = 'failed';
            })
    }
})

export const {
    setProformaInvoiceSaveOrDuplicateOrEditInvoiceId,
    setProformaInvoiceSaveOrDuplicateOrEditOperation,
    setProformaInvoiceSaveOrDuplicateOrEditSuccessfullySaved
} = proformaInvoiceSaveOrDuplicateOrEditSlice.actions

export default proformaInvoiceSaveOrDuplicateOrEditSlice.reducer