import { TaxProfileModel } from '../models/taxProfileModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class TaxProfileService {

  private readonly url = host + '/api/v1/tax-profile';

  private readonly http: Http

  public constructor() {
    this.http = Http.getInstance();
  }

  public get(): Promise<TaxProfileModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url
    })
      .then(res => res.json())
      .then((data: TaxProfileModel) => data);
  }

  public save(profile: TaxProfileModel): Promise<string> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url,
      body: JSON.stringify(profile),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: string) => data);
  }

  public edit(profile: TaxProfileModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url,
      body: JSON.stringify(profile),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public sendEmail(): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/send-email',
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined)
  }

}
