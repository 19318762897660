import AsyncSelect from 'react-select/async';
import { AsyncOptionModel } from '../../models/optionModel';
import { AddressService } from '../../services/addressService';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { CSSObjectWithLabel } from 'react-select';

interface Props {
  id: string;
  label: string;
  value?: string;
  placeholder?: string;
  onChange?: (city: AsyncOptionModel) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoCitySelect(props: Props) {
  const { id } = props;
  const { label } = props;
  const { value } = props;
  const { placeholder } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  const loadOptions = async (
    inputValue: string
  ): Promise<AsyncOptionModel[]> => {
    if (inputValue.length > 2) {
      try {
        const addressService = new AddressService();
        const response = await addressService.searchCity(inputValue);
        const data = response;

        const options: AsyncOptionModel[] = data.map((item) => ({
          value: item.denominazione_ita,
          label: item.denominazione_ita,
          cityZipCode: item.cap,
          cityProvince: item.sigla_provincia,
          addressExtra: {
            cityCodCatastale: item.codice_belfiore,
            cityRegion: item.denominazione_regione,
            cityRegionCode: item.codice_regione,
          },
        }));

        return options;
      } catch (err) {
        console.error(err);
        return [];
      }
    } else {
      return [];
    }
  };

  const styleDefault = {
    control: (
      base: CSSObjectWithLabel,
      optionProps: { isDisabled: boolean }
    ) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      font: 'normal normal normal 12px/22px Poppins',
      width: '100%',
      minHeight: '20px !important',
      height: '27px',
      borderBottom: '1px solid #EBEAEA !important',
      background: optionProps.isDisabled ? 'transparent' : '',
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none',
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      paddding: '0 !important',
      margin: 0,
    }),
    option: (
      base: CSSObjectWithLabel,
      optionProps: { isFocused: boolean; isSelected: boolean }
    ) => {
      return {
        ...base,
        backgroundColor: optionProps.isSelected
          ? '#DCE2FF'
          : optionProps.isFocused
          ? '#FAFAFF'
          : undefined,
        font: 'normal normal normal 13px/16px Poppins',
        letterSpacing: '0px',
        color: '#000000',
      };
    },
  };

  const DropdownIndicator: React.FC = () => {
    return <QuickfiscoIcon name={'arrow-down.svg'} />;
  };

  return (
    <div>
      <label className={'quickfisco-input-label'}>
        {label}
        {requiredField ? ' *' : null}
        {disabled && <QuickfiscoIcon name={'block.svg'} className={'ps-2'} />}
      </label>
      <div className="d-flex align-items-end">
        <div className={'d-flex align-items-end w-100'}>
          <AsyncSelect
            id={id}
            className={'w-100'}
            styles={styleDefault}
            placeholder={placeholder}
            loadOptions={loadOptions}
            cacheOptions
            onChange={(e) => e && onChange && onChange(e)}
            value={
              value
                ? {
                    value: value,
                    label: value,
                  }
                : undefined
            }
            isDisabled={disabled}
            components={{ DropdownIndicator }}
            noOptionsMessage={() => 'Ricerca comune'}
            loadingMessage={() => 'Caricamento'}
          />
        </div>
      </div>
      {error && (
        <div className="quickfisco-select-error mt-2">{errorLabel}</div>
      )}
    </div>
  );
}
