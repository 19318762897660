import config from '../../../config.json';
import useComponentVisible from "../../hooks/componentVisible";
import { formatNumberIT } from "../../utils/number";
import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";

interface Props {
    taxOrContribution: number
    className?: string
}

export function TaxInfoDeadline(props: Props) {
    const { taxOrContribution } = props
    const { className } = props

    const componentVisible = useComponentVisible(false);

    return (
        <div className={(className ?? 'tax-forecast-deadline-text fw-normal d-flex flex-row')}>
            {formatNumberIT(taxOrContribution)}{config.EUR}
            {taxOrContribution < 0 ?
                <div className={'ms-1 position-relative'}>
                    <div
                        onMouseOver={() => componentVisible.setIsVisible(true)}
                        onMouseOut={() => componentVisible.setIsVisible(false)}
                    >
                        <QuickfiscoIcon name={'info.svg'} className={'d-flex'} />
                    </div>
                    <div
                        ref={componentVisible.ref}
                        className={'position-absolute'}
                        style={{ left: "-90px", top: "30px", zIndex: "10", maxWidth: "300px" }}
                    >
                        {componentVisible.isVisible && (
                            <div className={'edit-tax-profile-popup-container'}>
                                {"L'importo negativo indica che sei in presenza di un possibile credito fiscale"}
                            </div>
                        )}
                    </div>
                </div> : <div></div>
            }
        </div>

    )
}