import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { GenderType } from '../../models/genderModel';
import lang from './genderSelect.json';

interface Props {
  id: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?: (gender: GenderType | undefined) => void;
}

export function GenderSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { error } = props;
  const { value } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.Label}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(GenderType[e.value]);
        }
      }}
      error={error}
      errorLabel={lang.Error}
      options={getOptions(value)}
      styleType={'default'}
      requiredField={true}
      disabled={disabled}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Seleziona il genere',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Uomo',
      value: GenderType.M,
      selected: value === GenderType.M,
    },
    {
      label: 'Donna',
      value: GenderType.F,
      selected: value === GenderType.F,
    },
  ];
}
