import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoProvinceSelect } from '../quickfiscoProvinceSelect/quickfiscoProvinceSelect';
import {
  AddressTaxReturnModel,
  SectionTaxReturn1,
  TvRadioTaxReturnModel,
} from '../../models/taxReturnModel';
import { TaxReturnSection1PersonalInfo } from '../taxReturnSection1PersonalInfo/taxReturnSection1PersonalInfo';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoBeneficiaryInstitutionsSelect } from '../quickfiscoBeneficiaryInstitutionsSelect/quickfiscoBeneficiaryInstitutionsSelect';
import { QuickfiscoCitySelect } from '../quickfiscoCitySelect/quickfiscoCitySelect';
import { QuickfiscoPoliticalPartiesSelect } from '../quickfiscoPoliticalPartiesSelect/quickfiscoPoliticalPartiesSelect';
import { TaxReturnService } from '../../services/taxReturnService';
import { fiscalCodeLegalEntities, zipCodeRegex } from '../../regex/regex';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { PromiseStatuses } from '../../types/strings';
import { resolveUndefinedDate, formatDateUS } from '../../utils/date';
import lang from './taxReturnSection1.json';
import './taxReturnSection1.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn1) => void;
  onClickPrevStep: (data: SectionTaxReturn1) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnSection1(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;

  const taxReturnService = new TaxReturnService();
  const userState = useAppSelector((state) => state.user.editUserRequest);

  const [enabledPrevStep, setEnabledPrevStep] = useState<boolean>(true);
  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [residence, setResidence] = useState<AddressTaxReturnModel>({
    address: userState.address ? userState.address : '',
    zipCode: userState.zipCode ? userState.zipCode : '',
    province: userState.province ? userState.province : '',
    city: userState.city ? userState.city : '',
    codCatastale: userState.addressExtra?.cityCodCatastale
      ? userState.addressExtra?.cityCodCatastale
      : '',
  });
  const [domicileQuestion, setDomicileQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [domicile, setDomicile] = useState<AddressTaxReturnModel>({
    address: '',
    zipCode: '',
    city: '',
  });
  const [domicilePrevYearQuestion, setDomicilePrevYearQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [domicilePrevYear, setDomicilePrevYear] =
    useState<AddressTaxReturnModel>({
      address: '',
      zipCode: '',
      city: '',
    });
  const [tvRadioQuestion, setTvRadioQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [tvRadioAnswer, setTvRadioAnswer] = useState<TvRadioTaxReturnModel>({
    subscriptionCode: '',
    subscriptionDate: undefined,
  });
  const [allocate8x1000Question, setAllocate8x1000Question] = useState<
    boolean | undefined
  >(undefined);
  const [allocate8x1000Answer, setAllocate8x1000Answer] = useState<
    string | undefined
  >(undefined);
  const [allocate5x1000Question, setAllocate5x1000Question] = useState<
    boolean | undefined
  >(undefined);
  const [allocate5x1000Answer, setAllocate5x1000Answer] = useState<
    string | undefined
  >(undefined);
  const [allocate5x1000FiscalCode, setAllocate5x1000FiscalCode] = useState<
    string | undefined
  >(undefined);
  const [allocate2x1000Question, setAllocate2x1000Question] = useState<
    boolean | undefined
  >(undefined);
  const [allocate2x1000Answer, setAllocate2x1000Answer] = useState<
    string | undefined
  >(undefined);

  const [zipCodeResidenceError, setZipCodeResidenceError] =
    useState<boolean>(false);
  const [zipCodeDomicileError, setZipCodeDomicileError] =
    useState<boolean>(false);
  const [zipCodeDomicilePrevYearError, setZipCodeDomicilePrevYearError] =
    useState<boolean>(false);
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);
  const [fiscalCodeError, setFiscalCodeError] = useState<boolean>(false);

  const getSurveyStep = (): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(1)
      .then((res) => {
        setLoadingStatus('successfully');
        setEnabledPrevStep(false);
        const data = res as SectionTaxReturn1;
        setResidence({
          address: data.residence.address,
          zipCode: data.residence.zipCode,
          province: data.residence.province,
          city: data.residence.city,
          codCatastale: data.residence.codCatastale,
        });
        setDomicileQuestion(data.domicileQuestion);
        setDomicile({
          address: data.domicileAnswer?.address,
          zipCode: data.domicileAnswer?.zipCode,
          province: data.domicileAnswer?.province,
          city: data.domicileAnswer?.city,
          codCatastale: data.domicileAnswer?.codCatastale,
        });
        setDomicilePrevYearQuestion(data.domicilePrevYearQuestion);
        setDomicilePrevYear({
          address: data.domicilePrevYearAnswer?.address,
          zipCode: data.domicilePrevYearAnswer?.zipCode,
          province: data.domicilePrevYearAnswer?.province,
          city: data.domicilePrevYearAnswer?.city,
          codCatastale: data.domicilePrevYearAnswer?.codCatastale,
          transferDate: data.domicilePrevYearAnswer?.transferDate,
        });
        setTvRadioQuestion(data.tvRadioQuestion);
        setTvRadioAnswer({
          subscriptionCode: data.tvRadioAnswer?.subscriptionCode,
          subscriptionDate: data.tvRadioAnswer?.subscriptionDate,
        });
        setAllocate8x1000Question(data.allocate8x1000Question);
        setAllocate8x1000Answer(data.allocate8x1000Answer);
        setAllocate5x1000Question(data.allocate5x1000Question);
        setAllocate5x1000Answer(data.allocate5x1000Answer);
        setAllocate5x1000FiscalCode(data.allocate5x1000FiscalCode);
        setAllocate2x1000Question(data.allocate2x1000Question);
        setAllocate2x1000Answer(data.allocate2x1000Answer);
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep();
    refreshApiCall(0, true);
  }, []);

  useEffect(() => {
    residence.zipCode &&
      setZipCodeResidenceError(!zipCodeRegex.test(residence.zipCode));
  }, [residence.zipCode]);

  useEffect(() => {
    domicile.zipCode &&
      domicile.zipCode.length > 0 &&
      setZipCodeDomicileError(!zipCodeRegex.test(domicile.zipCode));
  }, [domicile.zipCode]);

  useEffect(() => {
    domicilePrevYear.zipCode &&
      domicilePrevYear.zipCode.length > 0 &&
      setZipCodeDomicilePrevYearError(
        !zipCodeRegex.test(domicilePrevYear.zipCode)
      );
  }, [domicilePrevYear.zipCode]);

  useEffect(() => {
    domicileQuestion === true &&
      setDomicile({
        address: '',
        zipCode: '',
        city: '',
      });
  }, [domicileQuestion]);

  useEffect(() => {
    domicilePrevYearQuestion === true &&
      setDomicilePrevYear({
        address: '',
        zipCode: '',
        city: '',
        transferDate: undefined,
      });
  }, [domicilePrevYearQuestion]);

  useEffect(() => {
    tvRadioQuestion === false &&
      setTvRadioAnswer({
        subscriptionCode: '',
        subscriptionDate: undefined,
      });
  }, [tvRadioQuestion]);

  useEffect(() => {
    allocate8x1000Question === false && setAllocate8x1000Answer(undefined);
  }, [allocate8x1000Question]);

  useEffect(() => {
    allocate5x1000Question === false && setAllocate5x1000Answer(undefined);
    if (allocate5x1000Question === false) {
      setAllocate5x1000FiscalCode(undefined);
      setFiscalCodeError(false);
    }
  }, [allocate5x1000Question]);

  useEffect(() => {
    if (allocate5x1000Answer && allocate5x1000Answer === 'Non Ho Preferenze') {
      setAllocate5x1000FiscalCode(undefined);
      setFiscalCodeError(false);
    }
  }, [allocate5x1000Answer]);

  useEffect(() => {
    allocate5x1000FiscalCode &&
      allocate5x1000FiscalCode.length > 0 &&
      setFiscalCodeError(
        !fiscalCodeLegalEntities.test(allocate5x1000FiscalCode)
      );
    allocate5x1000FiscalCode?.length === 0 && setFiscalCodeError(false);
  }, [allocate5x1000FiscalCode]);

  useEffect(() => {
    allocate2x1000Question === false && setAllocate2x1000Answer(undefined);
  }, [allocate2x1000Question]);

  useEffect(() => {
    setCheckgoNext(
      taxReturnService.validateSection1(
        fiscalCodeError,
        dataToSend,
        zipCodeResidenceError,
        zipCodeDomicileError,
        zipCodeDomicilePrevYearError
      )
    );
  }, [
    residence,
    domicileQuestion,
    domicile,
    domicilePrevYearQuestion,
    domicilePrevYear,
    tvRadioQuestion,
    tvRadioAnswer,
    allocate8x1000Question,
    allocate8x1000Answer,
    allocate5x1000Question,
    allocate5x1000Answer,
    allocate5x1000FiscalCode,
    fiscalCodeError,
    allocate2x1000Question,
    allocate2x1000Answer,
    zipCodeResidenceError,
    zipCodeDomicileError,
    zipCodeDomicilePrevYearError,
  ]);

  const dataToSend: SectionTaxReturn1 = {
    personalInfo: {
      name: userState.name,
      surname: userState.surname,
      taxCode: userState.taxCode,
      gender: userState.gender,
      dateOfBirth: userState.dateOfBirth,
      birthPlace: userState.birthPlace,
      birthProvince: userState.birthProvince,
      category: userState.category,
      vat: userState.vat,
      vatOpeningDate: userState.vatOpeningDate,
      atecos: userState.atecos,
    },
    residence,
    domicileQuestion: domicileQuestion ? domicileQuestion : false,
    domicileAnswer: domicileQuestion === false ? domicile : undefined,
    domicilePrevYearQuestion: domicilePrevYearQuestion
      ? domicilePrevYearQuestion
      : false,
    domicilePrevYearAnswer:
      domicilePrevYearQuestion === false ? domicilePrevYear : undefined,
    tvRadioQuestion: tvRadioQuestion,
    tvRadioAnswer: tvRadioQuestion === true ? tvRadioAnswer : undefined,
    allocate8x1000Question: allocate8x1000Question,
    allocate8x1000Answer:
      allocate8x1000Question === true ? allocate8x1000Answer : undefined,
    allocate5x1000Question: allocate5x1000Question,
    allocate5x1000Answer:
      allocate5x1000Question === true ? allocate5x1000Answer : undefined,
    allocate5x1000FiscalCode:
      allocate5x1000Question === true ? allocate5x1000FiscalCode : undefined,
    allocate2x1000Question: allocate2x1000Question,
    allocate2x1000Answer:
      allocate2x1000Question === true ? allocate2x1000Answer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-1-title">{lang.title}</div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <TaxReturnSection1PersonalInfo />
            <div className={'row no-gutters'}>
              <div className={'col tax-return-section-1-subtitle'}>
                {lang.residenceTitle}
              </div>
            </div>
            <div className="row no-gutters mt-3">
              <div className="col-6">
                <QuickfiscoCitySelect
                  id="tax-return-section-1-city"
                  value={residence.city}
                  label={lang.cityLabel}
                  placeholder={lang.cityPlaceholder}
                  onChange={(e) => {
                    setResidence({
                      ...residence,
                      city: e.value,
                      // @ts-ignore
                      zipCode: e.cityZipCode,
                      // @ts-ignore
                      province: e.cityProvince,
                      // @ts-ignore
                      codCatastale: e.addressExtra.cityCodCatastale,
                    });
                  }}
                  requiredField={true}
                />
              </div>
              <div className="col-6">
                <QuickfiscoInput
                  id="tax-return-section-1-address"
                  label={lang.addressLabel}
                  placeholder={lang.addressPlaceholder}
                  type="text"
                  styleType={'default'}
                  defaultValue={residence.address}
                  onChange={(e) =>
                    setResidence({ ...residence, address: e.target.value })
                  }
                  required={true}
                />
              </div>
            </div>
            <div className={'row no-gutters mt-3'}>
              <div className="col-2">
                <QuickfiscoInput
                  id="tax-return-section-1-zipCode"
                  label={lang.zipCodeLabel}
                  type="text"
                  styleType={'default'}
                  placeholder={lang.zipCodePlaceholder}
                  value={residence.zipCode || ''}
                  onChange={(e) =>
                    setResidence({ ...residence, zipCode: e.target.value })
                  }
                  required={true}
                  error={zipCodeResidenceError}
                  errorLabel={lang.errorZipCode}
                  maxLength={5}
                />
              </div>
              <div className="col-4">
                <QuickfiscoProvinceSelect
                  id={'tax-return-section-1-province'}
                  value={residence.province}
                  onChange={(prov) =>
                    prov && setResidence({ ...residence, province: prov })
                  }
                  requiredField={true}
                />
              </div>
            </div>
            <div className={'row no-gutters mt-3'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-1-domicile"
                  title={lang.domicileQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={domicileQuestion}
                  onChange={(checked) => setDomicileQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {domicileQuestion === false && (
              <>
                <div className="row no-gutters mt-3">
                  <div className="col-6">
                    <QuickfiscoCitySelect
                      id="tax-return-section-1-domicile-city"
                      value={domicile.city}
                      label={lang.cityLabel}
                      placeholder={lang.cityPlaceholder}
                      onChange={(e) => {
                        setDomicile({
                          ...domicile,
                          city: e.value,
                          // @ts-ignore
                          zipCode: e.cityZipCode,
                          // @ts-ignore
                          province: e.cityProvince,
                          // @ts-ignore
                          codCatastale: e.addressExtra.cityCodCatastale,
                        });
                      }}
                      requiredField={true}
                    />
                  </div>
                  <div className="col-6">
                    <QuickfiscoInput
                      id="tax-return-section-1-domicile-address"
                      label={lang.addressLabel}
                      placeholder={lang.addressPlaceholder}
                      type="text"
                      styleType={'default'}
                      defaultValue={domicile.address}
                      onChange={(e) =>
                        setDomicile({ ...domicile, address: e.target.value })
                      }
                      required={true}
                    />
                  </div>
                </div>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-2">
                    <QuickfiscoInput
                      id="tax-return-section-1-domicile-zipCode"
                      label={lang.zipCodeLabel}
                      type="text"
                      styleType={'default'}
                      placeholder={lang.zipCodePlaceholder}
                      value={domicile.zipCode}
                      onChange={(e) =>
                        setDomicile({ ...domicile, zipCode: e.target.value })
                      }
                      required={true}
                      error={zipCodeDomicileError}
                      errorLabel={lang.errorZipCode}
                      maxLength={5}
                    />
                  </div>
                  <div className="col-4">
                    <QuickfiscoProvinceSelect
                      id={'tax-return-section-1-domicile-province'}
                      value={domicile.province}
                      onChange={(prov) =>
                        prov && setDomicile({ ...domicile, province: prov })
                      }
                      requiredField={true}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-1-domicile-prev-year"
                  title={lang.domicilePrevYearQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={domicilePrevYearQuestion}
                  onChange={(checked) => setDomicilePrevYearQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {domicilePrevYearQuestion === false && (
              <>
                <div className="row no-gutters mt-3">
                  <div className="col-6">
                    <QuickfiscoCitySelect
                      id="tax-return-section-1-domicile-prev-year-city"
                      value={domicilePrevYear.city}
                      label={lang.cityLabel}
                      placeholder={lang.cityPlaceholder}
                      onChange={(e) => {
                        setDomicilePrevYear({
                          ...domicilePrevYear,
                          city: e.value,
                          // @ts-ignore
                          zipCode: e.cityZipCode,
                          // @ts-ignore
                          province: e.cityProvince,
                          // @ts-ignore
                          codCatastale: e.addressExtra.cityCodCatastale,
                        });
                      }}
                      requiredField={true}
                    />
                  </div>
                  <div className="col-6">
                    <QuickfiscoInput
                      id="tax-return-section-1-domicile-prev-year-address"
                      label={lang.addressLabel}
                      placeholder={lang.addressPlaceholder}
                      type="text"
                      styleType={'default'}
                      defaultValue={domicilePrevYear.address}
                      onChange={(e) =>
                        setDomicilePrevYear({
                          ...domicilePrevYear,
                          address: e.target.value,
                        })
                      }
                      required={true}
                    />
                  </div>
                </div>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-2">
                    <QuickfiscoInput
                      id="tax-return-section-1-domicile-prev-year-zipCode"
                      label={lang.zipCodeLabel}
                      type="text"
                      styleType={'default'}
                      placeholder={lang.zipCodePlaceholder}
                      value={domicilePrevYear.zipCode}
                      onChange={(e) =>
                        setDomicilePrevYear({
                          ...domicilePrevYear,
                          zipCode: e.target.value,
                        })
                      }
                      required={true}
                      error={zipCodeDomicilePrevYearError}
                      errorLabel={lang.errorZipCode}
                      maxLength={5}
                    />
                  </div>
                  <div className="col-4">
                    <QuickfiscoProvinceSelect
                      id={'tax-return-section-1-domicile-prev-year-province'}
                      value={domicilePrevYear.province}
                      onChange={(prov) =>
                        prov &&
                        setDomicilePrevYear({
                          ...domicilePrevYear,
                          province: prov,
                        })
                      }
                      requiredField={true}
                    />
                  </div>

                  <div className="col-6">
                    <QuickfiscoDatePicker
                      id={
                        'tax-return-section-1-domicile-prev-year-transfer-date'
                      }
                      label={lang.transferDateLabel}
                      onChange={(value) => {
                        value &&
                          setDomicilePrevYear({
                            ...domicilePrevYear,
                            transferDate: resolveUndefinedDate(
                              formatDateUS(value)
                            ),
                          });
                      }}
                      value={
                        domicilePrevYear.transferDate &&
                        new Date(domicilePrevYear.transferDate)
                      }
                      required={true}
                      maxDate={new Date()}
                      styleType={'default'}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-1-tv-radio-question"
                  title={lang.tvRadioQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={tvRadioQuestion}
                  onChange={(checked) => setTvRadioQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {tvRadioQuestion === true && (
              <div className={'row no-gutters mt-3'}>
                <div className="col-6">
                  <QuickfiscoInput
                    id="tax-return-section-1-tv-radio-subscription-code"
                    label={lang.tvRadioSubscriptionCode}
                    placeholder={lang.tvRadioSubscriptionCodePlaceholder}
                    type="text"
                    defaultValue={tvRadioAnswer.subscriptionCode}
                    onChange={(e) =>
                      setTvRadioAnswer({
                        ...tvRadioAnswer,
                        subscriptionCode: e.target.value,
                      })
                    }
                    styleType={'default'}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <QuickfiscoDatePicker
                    id={'tax-return-section-1-tv-radio-subscription-date'}
                    label={lang.tvRadioSubscriptionDate}
                    onChange={(value) => {
                      value &&
                        setTvRadioAnswer({
                          ...tvRadioAnswer,
                          subscriptionDate: resolveUndefinedDate(
                            formatDateUS(value)
                          ),
                        });
                    }}
                    value={
                      tvRadioAnswer.subscriptionDate &&
                      new Date(tvRadioAnswer.subscriptionDate)
                    }
                    required={true}
                    maxDate={new Date()}
                    styleType={'default'}
                  />
                </div>
              </div>
            )}
            <div className={'row no-gutters'}>
              <div className={'col tax-return-section-1-subtitle'}>
                {lang.allocateTitle}
              </div>
            </div>
            <div className={'row no-gutters mt-3 d-flex align-items-center'}>
              <div className="col-4">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-1-8x1000-question"
                  title={lang.allocate8x1000Question}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={allocate8x1000Question}
                  onChange={(checked) => setAllocate8x1000Question(checked)}
                  required={true}
                />
              </div>
              {allocate8x1000Question === true && (
                <div className="col-8">
                  <QuickfiscoBeneficiaryInstitutionsSelect
                    id={'tax-return-section-1-8x1000-answer'}
                    value={allocate8x1000Answer}
                    onChange={(value) => setAllocate8x1000Answer(value)}
                    requiredField={true}
                    disabled={allocate8x1000Question !== true}
                    type={'8x1000'}
                  />
                </div>
              )}
            </div>
            <div className={'row no-gutters mt-3 d-flex align-items-top'}>
              <div className="col-4">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-1-5x1000-question"
                  title={lang.allocate5x1000Question}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={allocate5x1000Question}
                  onChange={(checked) => setAllocate5x1000Question(checked)}
                  required={true}
                />
              </div>
              {allocate5x1000Question === true && (
                <div className="col-8">
                  <div className={'row'}>
                    <div className="col-12">
                      <QuickfiscoBeneficiaryInstitutionsSelect
                        id={'tax-return-section-1-5x1000-answer'}
                        value={allocate5x1000Answer}
                        onChange={(value) => setAllocate5x1000Answer(value)}
                        requiredField={true}
                        disabled={allocate5x1000Question !== true}
                        type={'5x1000'}
                      />
                    </div>
                    {allocate5x1000Answer &&
                      allocate5x1000Answer !== 'Non Ho Preferenze' && (
                        <div className="col-12 mt-3">
                          <QuickfiscoInput
                            id="tax-return-section-1-5x1000-fiscal-code"
                            type="text"
                            styleType={'default'}
                            label={lang.allocate5x1000FiscalCodeLabel}
                            placeholder={
                              lang.allocate5x1000FiscalCodePlaceholder
                            }
                            defaultValue={allocate5x1000FiscalCode}
                            onChange={(e) =>
                              setAllocate5x1000FiscalCode(e.target.value)
                            }
                            error={fiscalCodeError}
                            errorLabel={lang.fiscalCodeError}
                          />
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
            <div className={'row no-gutters mt-3 d-flex align-items-center'}>
              <div className="col-4">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-1-2x1000-question"
                  title={lang.allocate2x1000Question}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={allocate2x1000Question}
                  onChange={(checked) => setAllocate2x1000Question(checked)}
                  required={true}
                />
              </div>
              {allocate2x1000Question === true && (
                <div className="col-8">
                  <QuickfiscoPoliticalPartiesSelect
                    id={'tax-return-section-1-2x1000-answer'}
                    value={allocate2x1000Answer}
                    onChange={(value) => setAllocate2x1000Answer(value)}
                    requiredField={true}
                    disabled={allocate2x1000Question !== true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-1-go-prev'}
              label={lang.goPrev}
              type={enabledPrevStep ? 'primary' : 'disabled'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-1-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-1-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-1-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
