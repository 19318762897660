import { useAppSelector } from '../../redux/hooks';
import { useEffect, useRef, useState } from 'react';
import {
  SectionTaxReturn1,
  SectionTaxReturn10,
  SectionTaxReturn11,
  SectionTaxReturn2,
  SectionTaxReturn3,
  SectionTaxReturn4,
  SectionTaxReturn5,
  SectionTaxReturn6,
  SectionTaxReturn7,
  SectionTaxReturn8,
  SectionTaxReturn9,
  SurveyStatusTaxReturnModel,
  SurveyStatusTypeTaxReturnModel,
  sectionTaxReturnAll,
} from '../../models/taxReturnModel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { TaxReturnService } from '../../services/taxReturnService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { ObjectService } from '../../services/objectService';
import { downloadPdf } from '../../utils/file';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxReturnIntro } from '../taxReturnIntro/taxReturnIntro';
import { TaxReturnSection1 } from '../taxReturnSection1/taxReturnSection1';
import { TaxReturnSection10 } from '../taxReturnSection10/taxReturnSection10';
import { TaxReturnSection11 } from '../taxReturnSection11/taxReturnSection11';
import { TaxReturnSection2 } from '../taxReturnSection2/taxReturnSection2';
import { TaxReturnSection3 } from '../taxReturnSection3/taxReturnSection3';
import { TaxReturnSection4 } from '../taxReturnSection4/taxReturnSection4';
import { TaxReturnSection5 } from '../taxReturnSection5/taxReturnSection5';
import { TaxReturnSection6 } from '../taxReturnSection6/taxReturnSection6';
import { TaxReturnSection7 } from '../taxReturnSection7/taxReturnSection7';
import { TaxReturnSection8 } from '../taxReturnSection8/taxReturnSection8';
import { TaxReturnSection9 } from '../taxReturnSection9/taxReturnSection9';
import { TaxReturnSectionFinance } from '../taxReturnSectionFinance/taxReturnSectionFinance';
import { TaxReturnSectionFinal } from '../taxReturnSectionFinal/taxReturnSectionFinal';
import { TaxReturnSectionRecap } from '../taxReturnSectionRecap/taxReturnSectionRecap';
import { TaxReturnSectionNotPresent } from '../taxReturnSectionNotPresent/taxReturnSectionNotPresent';
import { isEmptyValue } from '../../utils/values';
import { UserService } from '../../services/userService';
import { ObjDdrDocument, StatusDdrModel } from '../../models/userModel';
import config from '../../../config.json';
import lang from './taxReturnSurvey.json';
import './taxReturnSurvey.css';

export function TaxReturnSurvey() {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [loadingPageStatus, setPageLoadingStatus] =
    useState<PromiseStatuses>('idle');
  const [surveyStatus, setSurveyStatus] = useState<SurveyStatusTaxReturnModel>(
    {}
  );
  const [nextButtonStatus, setNextButtonStatus] =
    useState<PromiseStatuses>('idle');
  const [progressBarStatusAccordionData, setProgressBarStatusAccordionData] =
    useState<StatusDdrModel | undefined>(undefined);
  const [documentsAccordionData, setDocumentsAccordionData] = useState<
    ObjDdrDocument[] | undefined
  >(undefined);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const ddrArray = useAppSelector((state) => state.user.user.ddrYears);
  const userState = useAppSelector((state) => state.user.user);
  const prevYear = new Date().getFullYear() - 1;
  const [selectedYear, setSelectedYear] = useState<string | undefined>(
    ddrArray && ddrArray.indexOf(prevYear) > -1
      ? prevYear.toString()
      : ddrArray
      ? ddrArray[ddrArray?.length - 1].toString()
      : undefined
  );

  const numberOfSection: number = 12;
  let currentPage: React.ReactNode;

  const service = new UserService();

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  };

  const setScrollPositionFromProps = (
    position: number,
    skypScroll?: boolean
  ) => {
    if (skypScroll === true) {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo(0, 0);
      }
    } else {
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: position,
            behavior: 'smooth',
          });
        }
      }, 400);
    }
  };

  useEffect(() => {
    if (
      selectedYear &&
      selectedYear !== new Date().getFullYear().toString() &&
      selectedYear >= '2023'
    ) {
      getSurveyStatus(
        setPageLoadingStatus,
        setPageIndex,
        setSurveyStatus,
        selectedYear
      );
      service
        .getStatusDdr(userState.id, selectedYear)
        .then((res) => setProgressBarStatusAccordionData(res))
        .catch((err) => {
          console.error(err);
        });
      service
        .getDocumentsDdr(userState.id, selectedYear)
        .then((res) => setDocumentsAccordionData(res.documentsFile))
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedYear]);

  switch (pageIndex) {
    case 1:
      currentPage = (
        <TaxReturnIntro
          onClickNextStep={() => setPageIndex(pageIndex + 1)}
          onClickPrevStep={() => setPageIndex(pageIndex - 1)}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
    case 2:
      currentPage = (
        <TaxReturnSection1
          onClickNextStep={(data: SectionTaxReturn1) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn1) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
    case 3:
      currentPage = (
        <TaxReturnSection2
          onClickNextStep={(data: SectionTaxReturn2) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn2) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
    case 4:
      currentPage = (
        <TaxReturnSection3
          onClickNextStep={(data: SectionTaxReturn3) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn3) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
    case 5:
      currentPage = (
        <TaxReturnSection4
          onClickNextStep={(data: SectionTaxReturn4) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn4) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
    case 6:
      currentPage = (
        <TaxReturnSection5
          onClickNextStep={(data: SectionTaxReturn5) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn5) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          downloadDoc={(docId: string) => downloadDoc(docId)}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
          scrollPosition={scrollPosition}
        />
      );
      break;
    case 7:
      currentPage = (
        <TaxReturnSection6
          onClickNextStep={(data: SectionTaxReturn6) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn6) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          downloadDoc={(docId: string) => downloadDoc(docId)}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
          scrollPosition={scrollPosition}
        />
      );
      break;
    case 8:
      currentPage = (
        <TaxReturnSection7
          onClickNextStep={(data: SectionTaxReturn7) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn7) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          downloadDoc={(docId: string) => downloadDoc(docId)}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
          scrollPosition={scrollPosition}
        />
      );
      break;
    case 9:
      currentPage = (
        <TaxReturnSection8
          onClickNextStep={(data: SectionTaxReturn8) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn8) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          downloadDoc={(docId: string) => downloadDoc(docId)}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
          scrollPosition={scrollPosition}
        />
      );
      break;
    case 10:
      currentPage = (
        <TaxReturnSection9
          onClickNextStep={(data: SectionTaxReturn9) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn9) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          downloadDoc={(docId: string) => downloadDoc(docId)}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
          scrollPosition={scrollPosition}
        />
      );
      break;
    case 11:
      currentPage = (
        <TaxReturnSection10
          onClickNextStep={(data: SectionTaxReturn10) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn10) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          downloadDoc={(docId: string) => downloadDoc(docId)}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
          scrollPosition={scrollPosition}
        />
      );
      break;
    case 12:
      currentPage = (
        <TaxReturnSectionFinance
          onClickNextStep={() => setPageIndex(pageIndex + 1)}
          onClickPrevStep={() => setPageIndex(pageIndex - 1)}
        />
      );
      break;
    case 13:
      currentPage = (
        <TaxReturnSection11
          onClickNextStep={(data: SectionTaxReturn11) => {
            setSurveyStep(
              'next',
              pageIndex,
              data,
              setPageIndex,
              setNextButtonStatus
            );
          }}
          onClickPrevStep={(data: SectionTaxReturn11) => {
            setSurveyStep('prev', pageIndex, data, setPageIndex);
          }}
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
    case 14:
      currentPage = (
        <TaxReturnSectionFinal
          onClickNextStep={() =>
            getSurveyStatus(
              setPageLoadingStatus,
              setPageIndex,
              setSurveyStatus,
              selectedYear
            )
          }
          nextButtonStatus={nextButtonStatus}
          refreshApiCall={(scrollPositionProps, skypScroll) =>
            setScrollPositionFromProps(scrollPositionProps, skypScroll)
          }
        />
      );
      break;
  }

  if ((ddrArray && !ddrArray.some((el) => el >= 2023)) || !ddrArray) {
    return (
      <div className="tax-return-survey-container box-shadow py-5 px-4 mt-4">
        <div className="tax-return-survey-container-border p-4">
          <div className="row tax-return-survey-not-present-container">
            <div className="col-12">
              <TaxReturnSectionNotPresent />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loadingPageStatus === 'loading' ? (
        <div className="full-screen bg-blue d-flex justify-content-center align-items-center">
          <QuickfiscoSpinner />
        </div>
      ) : (
        <>
          {(surveyStatus?.surveyStatus ===
            SurveyStatusTypeTaxReturnModel.NOTSTARTED &&
            pageIndex === 0) ||
          surveyStatus?.surveyStatus ===
            SurveyStatusTypeTaxReturnModel.COMPLETED ||
          (surveyStatus?.surveyStatus ===
            SurveyStatusTypeTaxReturnModel.REVIEWING &&
            pageIndex === 0) ||
          (!isEmptyValue(selectedYear) && Number(selectedYear) > 2023) ? (
            <TaxReturnSectionRecap
              surveyStatus={surveyStatus}
              onClickNextStep={() => setPageIndex(pageIndex + 1)}
              setSelectedYear={setSelectedYear}
              selectedYear={selectedYear}
              progressBarStatusAccordionData={progressBarStatusAccordionData}
              documentsAccordionData={documentsAccordionData}
            />
          ) : (
            <div className={'row mt-4'}>
              <div className="col-12">
                <div className="tax-return-survey-container box-shadow p-0">
                  {pageIndex > 1 && pageIndex <= 13 && (
                    <div className="tax-return-survey-progressbar-container">
                      <ProgressBar
                        now={Math.trunc(
                          ((pageIndex - 1) / numberOfSection) * 100
                        )}
                        label={`${Math.trunc(
                          ((pageIndex - 1) / numberOfSection) * 100
                        )}%`}
                      />
                    </div>
                  )}
                  <div
                    ref={scrollContainerRef}
                    onScroll={handleScroll}
                    className={'tax-return-survey-section-container'}
                  >
                    {currentPage}
                  </div>
                  <div className="tax-return-survey-footer py-4 text-center">
                    <div className="tax-return-survey-footer-text-blue">
                      {lang.footerTextBlue}
                    </div>
                    <div className="tax-return-survey-footer-text-black">
                      <a
                        target={'_blank'}
                        href={`mailto:${config.mail}?subject=${lang.emailSubject} - ${userState.name} ${userState.surname}`}
                        rel={'noreferrer'}
                      >
                        {lang.footerTextLink}
                      </a>
                      {lang.footerTextBlack}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

function getSurveyStatus(
  setPageLoadingStatus: SetState<PromiseStatuses>,
  setPageIndex: SetState<number>,
  setSurveyStatus: SetState<SurveyStatusTaxReturnModel>,
  selectedYear?: string
): void {
  const service = new TaxReturnService();

  if (!selectedYear) return;

  setPageLoadingStatus('loading');
  service
    .getSurveyStatus(selectedYear)
    .then((res: SurveyStatusTaxReturnModel) => {
      setPageLoadingStatus('successfully');
      if (res) {
        res.lastStep &&
          setPageIndex(res.lastStep !== 0 ? res.lastStep + 2 : res.lastStep);
        setSurveyStatus(res);
      }
    })
    .catch((err) => {
      console.error(err);
      setPageLoadingStatus('failed');
    });
}

function setSurveyStep(
  direction: 'prev' | 'next',
  pageIndex: number,
  request: sectionTaxReturnAll,
  setPageIndex: SetState<number>,
  setButtonStatus?: SetState<PromiseStatuses>
): void {
  if (direction === 'prev') {
    setPageIndex(pageIndex - 1);
    return;
  }

  const service = new TaxReturnService();

  setButtonStatus && setButtonStatus('loading');
  service
    .setSurveyStep(pageIndex === 13 ? 11 : pageIndex - 1, request)
    .then(() => {
      setButtonStatus && setButtonStatus('successfully');
      setPageIndex(pageIndex + 1);
    })
    .catch((err) => {
      console.error(err);
      setButtonStatus && setButtonStatus('failed');
    });
}

function downloadDoc(docId: string): void {
  const service = new ObjectService();

  if (docId) {
    service
      .findById(docId)
      .then((data) => {
        const objFile = data;

        if (objFile.id) {
          service
            .findFile(objFile.id)
            .then((data) => {
              if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
