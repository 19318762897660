import { useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { useAppSelector } from '../../redux/hooks';
import { emailRegex } from '../../regex/regex';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { BringAFriendServices } from '../../services/bringAFriendService';
import { SendInviteFriendEmailModel } from '../../models/sendInviteFriendEmailModel';
import './bringAFriend.css';

interface Props {
  open: boolean;
  setOpen: ((isOpen: boolean) => void)
}

export function BringAFriend(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const [email, setEmail] = useState<string>('');
  const [statusSentEmail, setStatusSentEmail] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector(state => state.user);

  return (
    <>
      <QuickfiscoModal
        modalClassName={'bring-a-friend-modal-component'}
        isOpen={open}
        hide={() => {
          setOpen(false)
          setEmail('')
          setStatusSentEmail('idle')
        }}>
        <div className={'bring-a-friend-modal py-2'}>
          <div className={'row'}>
            <div className={'col-12 text-center'}>
              <div className={'bring-a-friend-modal-title'}>Porta un Forfettario!</div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-8 text-center offset-2 mt-2'}>
              <div className={'bring-a-friend-modal-subtitle'}>
                Condividi il tuo codice con amici e colleghi e ricevi un <span>buono Amazon dal valore di 35€!</span>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-4 text-center offset-4 mt-3 px-0 d-flex justify-content-center align-items-center'}>
              <div className={'bring-a-friend-modal-promo-code'}>
                {userState.user.assignedPromoCode}
                <QuickfiscoIcon
                  name={'copy-button.svg'}
                  className={'bring-a-friend-modal-copy-button'}
                  onClick={() => {navigator.clipboard.writeText(userState.user.assignedPromoCode || '')}}
                />
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-10 text-center offset-1 mt-3'}>
              <div className={'bring-a-friend-modal-info'}>
                Quando il tuo amico si abbonerà al nostro servizio, ti invieremo il buono via mail.
                <span className='d-block'>E se te lo stai chiedendo, SI. <span style={{fontWeight: '600'}}>I buoni sono cumulabili con altri buoni</span>.</span>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-10 text-center offset-1'}>
              <div className={'bring-a-friend-modal-info'}>
                <span style={{fontWeight: '600'}}>Ma non è finita qui! </span>
                Regaleremo al tuo amico 1 voucher dal valore di 35€ da poter utilizzare per il futuro rinnovo del suo abbonamento!
                <br/>
                <QuickfiscoIcon name={'gift-promocode.svg'} className={'my-4'} />
                <span className='d-block bring-a-friend-modal-blue-color'><span style={{fontWeight: '600'}}>COSA ASPETTI?</span> INVITA IL TUO AMICO!</span>
                <span className='d-block mt-2'>Gli invieremo una mail contenente il tuo codice e le istruzioni per “provare quickfisco” senza impegno.</span>
              </div>
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-7 offset-1 d-flex justify-content-center align-items-center'}>
              {statusSentEmail === 'idle' || statusSentEmail === 'loading' ? (
                <div className={'w-100'}>
                  <QuickfiscoInput
                    id={'promo-email-input'}
                    type={'text'}
                    styleType={'no-borders'}
                    placeholder={'Inserisci la mail del tuo amico'}
                    error={email.length > 0 && !emailRegex.test(email)}
                    errorLabel={'Mail non valida'}
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    required={true}
                  />
                </div>
              ) : (
                statusSentEmail === 'successfully' ? (
                  <div>
                    <span className={'bring-a-friend-modal-email-sent'}>Mail inviata con successo!</span>
                  </div>
                ) : (
                  <div>
                    <span className={'bring-a-friend-modal-email-rejected'}>Ops! C'è stato un problema, riprova</span>
                  </div>
                )
              )}
            </div>
            <div className={'col-3 d-flex justify-content-center align-items-center'}>
              {statusSentEmail === 'idle' ? (
                  <QuickfiscoButton
                    id={'promo-email-save'}
                    label={'INVIA CODICE'}
                    type={email && emailRegex.test(email) ? 'secondary' : 'disabled'}
                    onClick={() => {
                      if (email && emailRegex.test(email)) {
                        sendEmail({ email }, setStatusSentEmail)
                      }
                    }}
                  />
                ) : (
                  statusSentEmail === 'successfully' ? (
                    <div>
                      <QuickfiscoButton
                        id={'promo-email-close'}
                        label={'CHIUDI'}
                        type={'tertiary'}
                        onClick={() => {
                          setOpen(false)
                          setEmail('')
                          setStatusSentEmail('idle')
                        }}
                      />
                    </div>
                  ) : (
                    statusSentEmail === 'failed' ? (
                      <QuickfiscoButton
                        id={'promo-email-close'}
                        label={'RIPROVA'}
                        type={'tertiary'}
                        onClick={() => {
                          setStatusSentEmail('idle')
                        }}
                      />
                    ) : (
                      <div className={'d-flex justify-content-center'}>
                        <QuickfiscoSpinner />
                      </div>
                    )
                  )
                )}
            </div>
          </div>
          <div className={'row'}>
              <div className={'col-10 text-center offset-1 mt-3'}>
                <div className={'bring-a-friend-modal-terms'}>
                  Non utilizzeremo la mail che ci stai fornendo per attività promozionali, la mail che invieremo sarà puramente informativa e sarà l’unica.
                </div>
              </div>
          </div>
        </div>
      </QuickfiscoModal>
    </>
  );
}

function sendEmail(
  request: SendInviteFriendEmailModel,
  setStatus: SetState<PromiseStatuses>,
): void {
  const service = new BringAFriendServices();

  setStatus('loading');
  service
    .sendInviteFriendEmail(request)
      .then(() => {
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      })
}