import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './onboardingPopoverHelp.css';

export function OnboardingPopoverHelp() {
  const popoverTop = (
    <Popover
      id="onboarding-popover-positioned-top"
      className="onboarding-popover-positioned-top"
      title="onboarding-popover-top"
    >
      <p className="onboarding-popover-title">
        Qualcosa non ti convince o pensi ci sia stato un errore nella
        compilazione?
      </p>
      <p className="onboarding-popover-text">
        Scrivici rispondendo alla mail di recap che hai ricevuto
      </p>
    </Popover>
  );

  return (
    <div className="onboarding-popover-help-container">
      <OverlayTrigger
        trigger="click"
        placement="top-end"
        overlay={popoverTop}
        rootClose
      >
        <Button variant="link" className="onboarding-popover-help-button">
          <QuickfiscoIcon name={'help-icon.svg'} />
        </Button>
      </OverlayTrigger>
    </div>
  );
}
