import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoPayment } from '../../components/quickfiscoPayment/quickfiscoPayment';
import lang from './payment.json';
import './payment.css';

export function Payment() {
  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center payment-main-title">
              05. <span>{lang.title}</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className={'col-10 offset-1 payment-container box-shadow p-4'}>
              <QuickfiscoPayment />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
