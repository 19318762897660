import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  DocumentsTypeTaxReturnModel,
  ObjectFileTaxReturnModel,
  SectionTaxReturn6,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoFileList } from '../quickfiscoFileList.css/quickfiscoFileList';
import { getExtension, toBase64, validateFile } from '../../utils/file';
import { sanitizeString } from '../../utils/string';
import { useAppSelector } from '../../redux/hooks';
import { CategoryType, UserModel } from '../../models/userModel';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { counterFileRegex } from '../../regex/regex';
import lang from './taxReturnSection6.json';
import './taxReturnSection6.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn6) => void;
  onClickPrevStep: (data: SectionTaxReturn6) => void;
  downloadDoc: (docId: string) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
  scrollPosition?: number;
}

export function TaxReturnSection6(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { downloadDoc } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;
  const { scrollPosition } = props;

  const user = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [inpsContributionsQuestion, setInpsContributionsQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [inpsContributionsAnswer, setInpsContributionsAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);

  const [statusInpsContributions, setStatusInpsContributions] =
    useState<PromiseStatuses>('idle');
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (skypScroll?: boolean): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(6)
      .then((res) => {
        setLoadingStatus('successfully');
        scrollPosition && refreshApiCall(scrollPosition, skypScroll);
        const data = res as SectionTaxReturn6;
        setInpsContributionsQuestion(data.inpsContributionsQuestion);
        setInpsContributionsAnswer(
          data.inpsContributionsAnswer as ObjectFileTaxReturnModel[]
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep(true);
  }, []);

  useEffect(() => {
    if (
      inpsContributionsQuestion === false &&
      user.category === CategoryType.FUND
    ) {
      setInpsContributionsAnswer(undefined);
    }
  }, [inpsContributionsQuestion]);

  useEffect(() => {
    setCheckgoNext(
      taxReturnService.validateSection6(dataToSend, user.category)
    );
  }, [inpsContributionsQuestion, inpsContributionsAnswer]);

  const dataToSend: SectionTaxReturn6 = {
    inpsContributionsQuestion,
    inpsContributionsAnswer:
      inpsContributionsQuestion === true ? inpsContributionsAnswer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-6-title">{lang.title}</div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-6-inps-contributions-question-fund"
                  title={
                    user.category === CategoryType.FUND
                      ? lang.fundContributionsQuestion
                      : lang.inpsContributionsQuestion
                  }
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={inpsContributionsQuestion}
                  onChange={(checked) => {
                    setInpsContributionsQuestion(checked);
                    if (user.category === CategoryType.FUND) {
                      if (checked === false) {
                        saveQuestion(
                          setStatusInpsContributions,
                          false,
                          getSurveyStep
                        );
                      } else {
                        setStatusInpsContributions('idle');
                      }
                    }
                  }}
                  required={true}
                />
              </div>
            </div>
            {inpsContributionsQuestion === true &&
              user.category === CategoryType.FUND && (
                <>
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-12">
                      <QuickfiscoFileList
                        status={statusInpsContributions}
                        title={lang.inpsContributionsDocTitle}
                        fileList={inpsContributionsAnswer}
                        addDoc={(doc) =>
                          saveQuestion(
                            setStatusInpsContributions,
                            inpsContributionsQuestion,
                            getSurveyStep,
                            doc,
                            inpsContributionsAnswer,
                            user
                          )
                        }
                        deleteDoc={(docId, index) =>
                          deleteDoc(
                            setStatusInpsContributions,
                            docId,
                            index,
                            setInpsContributionsAnswer,
                            inpsContributionsAnswer
                          )
                        }
                        downloadDoc={(docId) => downloadDoc(docId)}
                        error={statusInpsContributions}
                      />
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-6-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-6-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-6-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-6-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function saveQuestion(
  setStatus: SetState<PromiseStatuses>,
  question: boolean,
  getSurveyStep: Function,
  doc?: File | null,
  answer?: ObjectFileTaxReturnModel[],
  user?: UserModel
): void {
  let objToSend = {};
  const counterRegex =
    answer && answer.length > 0
      ? answer[answer.length - 1].name.match(counterFileRegex)
      : undefined;
  const counter = counterRegex ? Number(counterRegex[0].split('.')[0]) + 1 : 1;

  setStatus('loading');

  const service = new TaxReturnService();

  if (question === true) {
    if (!doc || !user || !counter) {
      return;
    }

    if (!validateFile(doc)) {
      setStatus('failed');
      return;
    }

    const fileName = sanitizeString(
      `${DocumentsTypeTaxReturnModel.CONTRIBUTION}_${user.surname}_${user.name}_${counter}`
    ).toLowerCase();

    toBase64(doc)
      .then((res) => {
        const fileToSend = [
          {
            name: fileName + '.' + getExtension(doc),
            extension: getExtension(doc),
            file: res,
          },
        ];
        objToSend = {
          inpsContributionsQuestion: question,
          inpsContributionsAnswer: fileToSend,
        };
        service
          .setSurveyStep(6, objToSend)
          .then(() => {
            setStatus('successfully');
            getSurveyStep();
          })
          .catch((err) => {
            setStatus('failedUploadApi');
            getSurveyStep();
            console.error(err);
          });
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        console.error(err);
      });
  } else {
    objToSend = {
      inpsContributionsQuestion: question,
    };
    service
      .setSurveyStep(6, objToSend)
      .then(() => {
        setStatus('successfully');
        getSurveyStep();
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        getSurveyStep();
        console.error(err);
      });
  }
}

function deleteDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<ObjectFileTaxReturnModel[] | undefined>,
  answer?: ObjectFileTaxReturnModel[]
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(6, docId)
      .then(() => {
        if (answer) {
          const newAnswer = answer
            .slice(0, index)
            .concat(answer.slice(index + 1));
          setAnswer([...newAnswer]);
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}
