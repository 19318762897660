import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  DocumentsTypeTaxReturnModel,
  ObjectFileTaxReturnModel,
  SectionTaxReturn5,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoFileList } from '../quickfiscoFileList.css/quickfiscoFileList';
import { getExtension, toBase64, validateFile } from '../../utils/file';
import { sanitizeString } from '../../utils/string';
import { useAppSelector } from '../../redux/hooks';
import { UserModel } from '../../models/userModel';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { counterFileRegex } from '../../regex/regex';
import lang from './taxReturnSection5.json';
import './taxReturnSection5.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn5) => void;
  onClickPrevStep: (data: SectionTaxReturn5) => void;
  downloadDoc: (docId: string) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
  scrollPosition?: number;
}

export function TaxReturnSection5(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { downloadDoc } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;
  const { scrollPosition } = props;

  const user = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [capitalIncomeQuestion, setCapitalIncomeQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [capitalIncomeAnswer, setCapitalIncomeAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [selfEmploymentQuestion, setSelfEmploymentQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [selfEmploymentAnswer, setSelfEmploymentAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [commercialActivitiesQuestion, setCommercialActivitiesQuestion] =
    useState<boolean | undefined>(undefined);
  const [commercialActivitiesAnswer, setCommercialActivitiesAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [sportsActivitiesQuestion, setSportsActivitiesQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [sportsActivitiesAnswer, setSportsActivitiesAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [realEstateIncomeQuestion, setRealEstateIncomeQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [realEstateIncomeAnswer, setRealEstateIncomeAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [intellectualWorksQuestion, setIntellectualWorksQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [intellectualWorksAnswer, setIntellectualWorksAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);

  const [statusCapitalIncome, setStatusCapitalIncome] =
    useState<PromiseStatuses>('idle');
  const [statusSelfEmployment, setStatusSelfEmployment] =
    useState<PromiseStatuses>('idle');
  const [statusCommercialActivities, setStatusCommercialActivities] =
    useState<PromiseStatuses>('idle');
  const [statusSportsActivities, setStatusSportsActivities] =
    useState<PromiseStatuses>('idle');
  const [statusRealEstateIncome, setStatusRealEstateIncome] =
    useState<PromiseStatuses>('idle');
  const [statusIntellectualWorks, setStatusIntellectualWorks] =
    useState<PromiseStatuses>('idle');
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (skypScroll?: boolean): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(5)
      .then((res) => {
        setLoadingStatus('successfully');
        scrollPosition && refreshApiCall(scrollPosition, skypScroll);
        const data = res as SectionTaxReturn5;
        setCapitalIncomeQuestion(data.capitalIncomeQuestion);
        setCapitalIncomeAnswer(
          data.capitalIncomeAnswer as ObjectFileTaxReturnModel[]
        );
        setSelfEmploymentQuestion(data.selfEmploymentQuestion);
        setSelfEmploymentAnswer(
          data.selfEmploymentAnswer as ObjectFileTaxReturnModel[]
        );
        setCommercialActivitiesQuestion(data.commercialActivitiesQuestion);
        setCommercialActivitiesAnswer(
          data.commercialActivitiesAnswer as ObjectFileTaxReturnModel[]
        );
        setSportsActivitiesQuestion(data.sportsActivitiesQuestion);
        setSportsActivitiesAnswer(
          data.sportsActivitiesAnswer as ObjectFileTaxReturnModel[]
        );
        setRealEstateIncomeQuestion(data.realEstateIncomeQuestion);
        setRealEstateIncomeAnswer(
          data.realEstateIncomeAnswer as ObjectFileTaxReturnModel[]
        );
        setIntellectualWorksQuestion(data.intellectualWorksQuestion);
        setIntellectualWorksAnswer(
          data.intellectualWorksAnswer as ObjectFileTaxReturnModel[]
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep(true);
  }, []);

  useEffect(() => {
    if (capitalIncomeQuestion === false) {
      setCapitalIncomeAnswer(undefined);
    }
  }, [capitalIncomeQuestion]);

  useEffect(() => {
    if (selfEmploymentQuestion === false) {
      setSelfEmploymentAnswer(undefined);
    }
  }, [selfEmploymentQuestion]);

  useEffect(() => {
    if (commercialActivitiesQuestion === false) {
      setCommercialActivitiesAnswer(undefined);
    }
  }, [commercialActivitiesQuestion]);

  useEffect(() => {
    if (sportsActivitiesQuestion === false) {
      setSportsActivitiesAnswer(undefined);
    }
  }, [sportsActivitiesQuestion]);

  useEffect(() => {
    if (realEstateIncomeQuestion === false) {
      setRealEstateIncomeAnswer(undefined);
    }
  }, [realEstateIncomeQuestion]);

  useEffect(() => {
    if (intellectualWorksQuestion === false) {
      setIntellectualWorksAnswer(undefined);
    }
  }, [intellectualWorksQuestion]);

  useEffect(() => {
    setCheckgoNext(taxReturnService.validateSection5(dataToSend));
  }, [
    capitalIncomeQuestion,
    capitalIncomeAnswer,
    selfEmploymentQuestion,
    selfEmploymentAnswer,
    commercialActivitiesQuestion,
    commercialActivitiesAnswer,
    sportsActivitiesQuestion,
    sportsActivitiesAnswer,
    realEstateIncomeQuestion,
    realEstateIncomeAnswer,
    intellectualWorksQuestion,
    intellectualWorksAnswer,
  ]);

  const dataToSend: SectionTaxReturn5 = {
    capitalIncomeQuestion,
    capitalIncomeAnswer:
      capitalIncomeQuestion === true ? capitalIncomeAnswer : undefined,
    selfEmploymentQuestion,
    selfEmploymentAnswer:
      selfEmploymentQuestion === true ? selfEmploymentAnswer : undefined,
    commercialActivitiesQuestion,
    commercialActivitiesAnswer:
      commercialActivitiesQuestion === true
        ? commercialActivitiesAnswer
        : undefined,
    sportsActivitiesQuestion,
    sportsActivitiesAnswer:
      sportsActivitiesQuestion === true ? sportsActivitiesAnswer : undefined,
    realEstateIncomeQuestion,
    realEstateIncomeAnswer:
      realEstateIncomeQuestion === true ? realEstateIncomeAnswer : undefined,
    intellectualWorksQuestion,
    intellectualWorksAnswer:
      intellectualWorksQuestion === true ? intellectualWorksAnswer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-5-title">{lang.title}</div>
          <div className="col-12 tax-return-section-5-subtitle mt-3">
            <b>{lang.subtitleBold}</b>
          </div>
          <div className="col-12 tax-return-section-5-subtitle">
            {lang.subtitleText}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-5-capital-income-question"
                  title={lang.capitalIncomeQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={capitalIncomeQuestion}
                  onChange={(checked) => {
                    setCapitalIncomeQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        setStatusCapitalIncome,
                        DocumentsTypeTaxReturnModel.CAPITAL_INCOME,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusCapitalIncome('idle');
                    }
                  }}
                  required={true}
                  popupText={lang.capitalIncomeQuestionPopupText}
                />
              </div>
            </div>
            {capitalIncomeQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusCapitalIncome}
                      title={lang.capitalIncomeDocTitle}
                      fileList={capitalIncomeAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          setStatusCapitalIncome,
                          DocumentsTypeTaxReturnModel.CAPITAL_INCOME,
                          capitalIncomeQuestion,
                          getSurveyStep,
                          doc,
                          capitalIncomeAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusCapitalIncome,
                          docId,
                          index,
                          setCapitalIncomeAnswer,
                          capitalIncomeAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusCapitalIncome}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-5-self-employment-question"
                  title={lang.selfEmploymentQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={selfEmploymentQuestion}
                  onChange={(checked) => {
                    setSelfEmploymentQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        setStatusSelfEmployment,
                        DocumentsTypeTaxReturnModel.CU,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusSelfEmployment('idle');
                    }
                  }}
                  required={true}
                  popupText={lang.selfEmploymentQuestionPopupText}
                />
              </div>
            </div>
            {selfEmploymentQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusSelfEmployment}
                      title={lang.selfEmploymentDocTitle}
                      fileList={selfEmploymentAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          setStatusSelfEmployment,
                          DocumentsTypeTaxReturnModel.CU,
                          selfEmploymentQuestion,
                          getSurveyStep,
                          doc,
                          selfEmploymentAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusSelfEmployment,
                          docId,
                          index,
                          setSelfEmploymentAnswer,
                          selfEmploymentAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusSelfEmployment}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-5-commercial-activities-question"
                  title={lang.commercialActivitiesQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={commercialActivitiesQuestion}
                  onChange={(checked) => {
                    setCommercialActivitiesQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        setStatusCommercialActivities,
                        DocumentsTypeTaxReturnModel.RECEIPT,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusCommercialActivities('idle');
                    }
                  }}
                  required={true}
                  popupText={lang.commercialActivitiesQuestionPopupText}
                />
              </div>
            </div>
            {commercialActivitiesQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusCommercialActivities}
                      title={lang.commercialActivitiesAnswerDocTitle}
                      fileList={commercialActivitiesAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          setStatusCommercialActivities,
                          DocumentsTypeTaxReturnModel.RECEIPT,
                          commercialActivitiesQuestion,
                          getSurveyStep,
                          doc,
                          commercialActivitiesAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusCommercialActivities,
                          docId,
                          index,
                          setCommercialActivitiesAnswer,
                          commercialActivitiesAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusCommercialActivities}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-5-sports-activities-question"
                  title={lang.sportsActivitiesQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={sportsActivitiesQuestion}
                  onChange={(checked) => {
                    setSportsActivitiesQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        setStatusSportsActivities,
                        DocumentsTypeTaxReturnModel.CU_SPORT,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusSportsActivities('idle');
                    }
                  }}
                  required={true}
                  popupText={lang.sportsActivitiesQuestionPopupText}
                />
              </div>
            </div>
            {sportsActivitiesQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusSportsActivities}
                      title={lang.sportsActivitiesAnswerDocTitle}
                      fileList={sportsActivitiesAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          setStatusSportsActivities,
                          DocumentsTypeTaxReturnModel.CU_SPORT,
                          sportsActivitiesQuestion,
                          getSurveyStep,
                          doc,
                          sportsActivitiesAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusSportsActivities,
                          docId,
                          index,
                          setSportsActivitiesAnswer,
                          sportsActivitiesAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusSportsActivities}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-5-real-estate-question"
                  title={lang.realEstateIncomeQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={realEstateIncomeQuestion}
                  onChange={(checked) => {
                    setRealEstateIncomeQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        setStatusRealEstateIncome,
                        DocumentsTypeTaxReturnModel.REAL_ESTATE,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusRealEstateIncome('idle');
                    }
                  }}
                  required={true}
                  popupText={lang.realEstateIncomeQuestionPopupText}
                />
              </div>
            </div>
            {realEstateIncomeQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusRealEstateIncome}
                      title={lang.realEstateIncomeAnswerDocTitle}
                      fileList={realEstateIncomeAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          setStatusRealEstateIncome,
                          DocumentsTypeTaxReturnModel.REAL_ESTATE,
                          realEstateIncomeQuestion,
                          getSurveyStep,
                          doc,
                          realEstateIncomeAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusRealEstateIncome,
                          docId,
                          index,
                          setRealEstateIncomeAnswer,
                          realEstateIncomeAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusRealEstateIncome}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-5-intellectual-works-question"
                  title={lang.intellectualWorksQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={intellectualWorksQuestion}
                  onChange={(checked) => {
                    setIntellectualWorksQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        setStatusIntellectualWorks,
                        DocumentsTypeTaxReturnModel.AUTHOR,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusIntellectualWorks('idle');
                    }
                  }}
                  required={true}
                  popupText={lang.intellectualWorksQuestionPopupText}
                />
              </div>
            </div>
            {intellectualWorksQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusIntellectualWorks}
                      title={lang.intellectualWorksAnswerDocTitle}
                      fileList={intellectualWorksAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          setStatusIntellectualWorks,
                          DocumentsTypeTaxReturnModel.AUTHOR,
                          intellectualWorksQuestion,
                          getSurveyStep,
                          doc,
                          intellectualWorksAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusIntellectualWorks,
                          docId,
                          index,
                          setIntellectualWorksAnswer,
                          intellectualWorksAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusIntellectualWorks}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-5-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-5-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-5-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-5-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function saveQuestion(
  setStatus: SetState<PromiseStatuses>,
  docType: DocumentsTypeTaxReturnModel,
  question: boolean,
  getSurveyStep: Function,
  doc?: File | null,
  answer?: ObjectFileTaxReturnModel[],
  user?: UserModel
): void {
  const keyObjTypeMap = new Map<DocumentsTypeTaxReturnModel, string>([
    [DocumentsTypeTaxReturnModel.CAPITAL_INCOME, 'capitalIncome'],
    [DocumentsTypeTaxReturnModel.CU, 'selfEmployment'],
    [DocumentsTypeTaxReturnModel.RECEIPT, 'commercialActivities'],
    [DocumentsTypeTaxReturnModel.CU_SPORT, 'sportsActivities'],
    [DocumentsTypeTaxReturnModel.REAL_ESTATE, 'realEstateIncome'],
    [DocumentsTypeTaxReturnModel.AUTHOR, 'intellectualWorks'],
  ]);

  const questionKey = keyObjTypeMap.get(docType) + 'Question';
  const answerKey = keyObjTypeMap.get(docType) + 'Answer';
  let objToSend = {};
  const counterRegex =
    answer && answer.length > 0
      ? answer[answer.length - 1].name.match(counterFileRegex)
      : undefined;
  const counter = counterRegex ? Number(counterRegex[0].split('.')[0]) + 1 : 1;

  setStatus('loading');

  const service = new TaxReturnService();

  if (question === true) {
    if (!doc || !user || !counter) {
      return;
    }

    if (!validateFile(doc)) {
      setStatus('failed');
      return;
    }

    const fileName = sanitizeString(
      `${docType}_${user.surname}_${user.name}_${counter}`
    ).toLowerCase();

    toBase64(doc)
      .then((res) => {
        const fileToSend = [
          {
            name: fileName + '.' + getExtension(doc),
            extension: getExtension(doc),
            file: res,
          },
        ];
        objToSend = {
          [questionKey]: question,
          [answerKey]: fileToSend,
        };
        service
          .setSurveyStep(5, objToSend)
          .then(() => {
            setStatus('successfully');
            getSurveyStep();
          })
          .catch((err) => {
            setStatus('failedUploadApi');
            getSurveyStep();
            console.error(err);
          });
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        console.error(err);
      });
  } else {
    objToSend = {
      [questionKey]: question,
    };
    service
      .setSurveyStep(5, objToSend)
      .then(() => {
        setStatus('successfully');
        getSurveyStep();
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        getSurveyStep();
        console.error(err);
      });
  }
}

function deleteDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<ObjectFileTaxReturnModel[] | undefined>,
  answer?: ObjectFileTaxReturnModel[]
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(5, docId)
      .then(() => {
        if (answer) {
          const newAnswer = answer
            .slice(0, index)
            .concat(answer.slice(index + 1));
          setAnswer([...newAnswer]);
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}
