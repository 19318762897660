import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComponentVisibleOutput } from '../../hooks/componentVisible';
import { InvoiceFilters } from '../../models/invoiceFilters';
import { InvoiceModel, InvoiceStatusType, InvoiceType, ReimbursementOfExpenseModel } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAutoInvoiceList } from '../../redux/slices/autoInvoiceListSlice';
import { setAutoInvoiceSaveOrDuplicateOrEditInvoiceId, setAutoInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/autoInvoiceSaveOrDuplicateOrEditSlice';
import { getCustomerInvoiceList } from '../../redux/slices/customerInvoiceListSlice';
import { setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId, setCustomerInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/customerInvoiceSaveOrDuplicateOrEditSlice';
import { getProformaInvoiceList } from '../../redux/slices/proformaInvoiceListSlice';
import { setProformaInvoiceSaveOrDuplicateOrEditInvoiceId, setProformaInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/proformaInvoiceSaveOrDuplicateOrEditSlice';
import { findProformaInvoiceById } from '../../redux/slices/proformaInvoiceSlice';
import { setStsInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice';
import { getSupplierInvoiceList } from '../../redux/slices/supplierInvoiceListSlice';
import { setSupplierInvoiceSaveOrEditInvoiceId, setSupplierInvoiceSaveOrEditOperation } from '../../redux/slices/supplierInvoiceSaveOrEditSlice';
import { getOccasionalJobInvoiceList, setOccasionalJobInvoiceIdToDuplicate } from '../../redux/slices/occasionalJobInvoiceListSlice';
import uri from '../../routers/quickfiscoUri.json';
import { AutoInvoiceService } from '../../services/autoInvoiceService';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { ProformaInvoiceService } from '../../services/proformaInvoiceService';
import { InvoiceSupplierService } from '../../services/supplierInvoiceService';
import { OccasionalJobInvoiceService } from '../../services/occasionalJobInvoiceService';
import { SetState } from '../../types/functions';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { downloadPdf } from '../../utils/file';
import { InvoicePaymentSave } from '../invoicePaymentSave/invoicePaymentSave';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './invoiceMenu.css';
import lang from './invoiceMenu.json';

interface Props {
  componentVisible: ComponentVisibleOutput;
  invoiceSign: InvoiceSign;
  invoice: InvoiceModel;
}

export function InvoiceMenu(props: Props) {
  const { componentVisible } = props;
  const { invoiceSign } = props;
  const { invoice } = props;

  const [deletionStatus, setDeletionStatus] = useState<PromiseStatuses>('idle');
  const [downloadPdfStatus, setDownloadPdfStatus] = useState<PromiseStatuses>('idle');
  const [cancelStatus, setCancelStatus] = useState<PromiseStatuses>('idle');
  const [openPaymentSaveModal, setOpenPaymentSaveModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const proformaInvoiceListState = useAppSelector(state => state.proformaInvoiceList)
  const supplierInvoiceListState = useAppSelector(state => state.supplierInvoiceList)
  const autoInvoiceListState = useAppSelector(state => state.autoInvoiceList)
  const occasionalJobListState = useAppSelector(state => state.occasionalJobInvoiceList)

  const customerInvoiceFilters: InvoiceFilters = {
    page: customerInvoiceListState.page,
    month: customerInvoiceListState.monthFilter,
    year: customerInvoiceListState.yearFilter,
    type: customerInvoiceListState.typeFilter,
    companyName: customerInvoiceListState.companyNameFilter,
    status: customerInvoiceListState.statusFilter,
    isSts: customerInvoiceListState.isStsFilter,
  }
  const proformaInvoiceFilters: InvoiceFilters = {
    page: proformaInvoiceListState.page,
    month: proformaInvoiceListState.monthFilter,
    year: proformaInvoiceListState.yearFilter,
    companyName: proformaInvoiceListState.companyNameFilter,
    proformaStatus: proformaInvoiceListState.statusFilter
  }
  const supplierInvoiceFilters: InvoiceFilters = {
    page: supplierInvoiceListState.page,
    month: supplierInvoiceListState.monthFilter,
    year: supplierInvoiceListState.yearFilter,
    companyName: supplierInvoiceListState.companyNameFilter,
    status: supplierInvoiceListState.statusFilter
  }
  const autoInvoiceFilters: InvoiceFilters = {
    page: autoInvoiceListState.page,
    month: autoInvoiceListState.monthFilter,
    year: autoInvoiceListState.yearFilter,
    companyName: autoInvoiceListState.companyNameFilter,
    status: autoInvoiceListState.statusFilter
  }

  const occasionalJobInvoiceFilters: InvoiceFilters = {
    page: occasionalJobListState.page,
    month: occasionalJobListState.monthFilter,
    year: occasionalJobListState.yearFilter,
    companyName: occasionalJobListState.companyNameFilter,
  }

  let label1;
  let label2;
  let label3;
  let label4;
  let label5;
  let label6;
  let label7;
  let edit: () => void;
  let getPdf: () => void;
  let duplicate: () => void;
  let convertToInvoice: () => void;
  let cancel: () => void;

  const invoiceId = invoice.id

  if (invoiceId === undefined) {
    return (
      <div>Errore durante il caricamento dei dati</div>
    )
  }

  switch (invoiceSign) {
    case 'customer':
      label1 = lang.Active.Label1;
      label2 = lang.Active.Label2;
      label3 = lang.Active.Label3;
      label4 = lang.Active.Label4;
      label5 = lang.Active.Label5;

      edit = () => {
        componentVisible.setIsVisible(false);
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('edit'))
        navigate(uri.CustomerInvoiceSave);
      };

      duplicate = () => {
        componentVisible.setIsVisible(false);
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('duplicate'))
        navigate(uri.CustomerInvoiceSave);
      };

      getPdf = () => {
        if (invoice.id) {
          const service = new CustomerInvoiceService();

          setDownloadPdfStatus('loading');
          service
            .getPdf(invoice.id)
            .then(data => {
              componentVisible.setIsVisible(false);
              setDownloadPdfStatus('idle');
              downloadPdf(CustomerInvoiceService.getPdfName('customer', invoice.type, invoice.number, invoice.customer?.companyName), data, 'application/pdf');
            })
            .catch(err => {
              setDownloadPdfStatus('failed');
              console.error(err);
            });
        }
      };
      break;
    case 'proforma':
      label5 = lang.Proforma.Label1
      label1 = lang.Proforma.Label2
      label6 = lang.Proforma.Label3
      label3 = lang.Proforma.Label4
      label4 = lang.Proforma.Label5

      edit = () => {
        componentVisible.setIsVisible(false);
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('edit'))
        navigate(uri.ProformaInvoiceSave);
      };

      duplicate = () => {
        componentVisible.setIsVisible(false);
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('duplicate'))
        navigate(uri.ProformaInvoiceSave);
      };

      convertToInvoice = () => {
        componentVisible.setIsVisible(false);
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('convert'))
        dispatch(findProformaInvoiceById(invoice.id))
        if (invoice.stsIsActive) {
          dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('convert'))
          navigate(uri.StsInvoiceSave);
        } else {
          dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('convert'))
          navigate(uri.CustomerInvoiceSave);
        }
      }

      getPdf = () => {
        if (invoice.id) {
          const service = new ProformaInvoiceService();

          setDownloadPdfStatus('loading');
          service
            .getPdf(invoice.id)
            .then(data => {
              componentVisible.setIsVisible(false);
              setDownloadPdfStatus('idle');
              downloadPdf(ProformaInvoiceService.getPdfName('proforma', invoice.type, invoice.number, invoice.customer?.companyName), data, 'application/pdf');
            })
            .catch(err => {
              setDownloadPdfStatus('failed');
              console.error(err);
            });
        }
      };
      break;
    case 'supplier':
      label5 = lang.Passive.Label4;
      label1 = lang.Passive.Label1;
      label2 = lang.Passive.Label2;
      label4 = lang.Passive.Label3;

      edit = () => {
        componentVisible.setIsVisible(false);
        dispatch(setSupplierInvoiceSaveOrEditInvoiceId(invoice?.id))
        dispatch(setSupplierInvoiceSaveOrEditOperation('edit'))
        navigate(uri.SupplierInvoiceSave);
      };

      getPdf = () => {
        if (invoice.id) {
          const service = new InvoiceSupplierService();

          setDownloadPdfStatus('loading');
          service
            .getPdf(invoice.id)
            .then(data => {
              componentVisible.setIsVisible(false);
              setDownloadPdfStatus('idle');
              downloadPdf(InvoiceSupplierService.getPdfName('supplier', invoice.type, invoice.number, invoice.supplier?.companyName), data, 'application/pdf');
            })
            .catch(err => {
              setDownloadPdfStatus('failed');
              console.error(err);
            });
        }
      };
      break;
    case 'auto':
      label5 = lang.Auto.Label1;
      label1 = lang.Auto.Label2;
      label3 = lang.Auto.Label3;
      label4 = lang.Auto.Label4;

      edit = () => {
        componentVisible.setIsVisible(false);
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditOperation('edit'))
        navigate(uri.AutoInvoiceSave);
      };

      duplicate = () => {
        componentVisible.setIsVisible(false);
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setAutoInvoiceSaveOrDuplicateOrEditOperation('duplicate'))
        navigate(uri.AutoInvoiceSave);
      };

      getPdf = () => {
        if (invoice.id) {
          const service = new AutoInvoiceService();

          setDownloadPdfStatus('loading');
          service
            .getPdf(invoice.id)
            .then(data => {
              componentVisible.setIsVisible(false);
              setDownloadPdfStatus('idle');
              downloadPdf(AutoInvoiceService.getPdfName('auto', undefined, invoice.number, invoice.supplier?.companyName), data, 'application/pdf');
            })
            .catch(err => {
              setDownloadPdfStatus('failed');
              console.error(err);
            });
        }
      };
      break;
    case 'occasionalJob':
      label5 = lang.OccasionalJob.Label1;
      label1 = lang.OccasionalJob.Label2;
      label3 = lang.OccasionalJob.Label3;
      label4 = lang.OccasionalJob.Label4;
      label7 = lang.OccasionalJob.Label5;

      edit = () => {
        componentVisible.setIsVisible(false);
        navigate(uri.OccasionalJobInvoice + '/' + invoice.id);
      };

      getPdf = () => {
        if (invoice.id) {
          const service = new OccasionalJobInvoiceService();

          setDownloadPdfStatus('loading');
          service
            .getPdf(invoice.id)
            .then(data => {
              componentVisible.setIsVisible(false);
              setDownloadPdfStatus('idle');
              downloadPdf(OccasionalJobInvoiceService.getPdfName('occasionalJob', undefined, invoice.number, invoice.customer?.companyName), data, 'application/pdf');
            })
            .catch(err => {
              setDownloadPdfStatus('failed');
              console.error(err);
            });
        }
      };

      duplicate = () => {
        componentVisible.setIsVisible(false);
        if (invoice.id) dispatch(setOccasionalJobInvoiceIdToDuplicate(invoice.id));
        navigate(uri.OccasionalJobInvoiceSaveOrDuplicate);
      };

      cancel = () => {
        if (invoice.id) {
          const service = new OccasionalJobInvoiceService();

          setCancelStatus('loading');
          service
            .changeStatus(invoice.id)
            .then(() => {
              componentVisible.setIsVisible(false);
              setCancelStatus('idle');
              dispatch(getOccasionalJobInvoiceList(occasionalJobInvoiceFilters))
            })
            .catch(err => {
              setCancelStatus('failed');
              console.error(err);
            });
        }
      };
      break;
    case 'creditSlip':
      label1 = lang.CreditSlip.Label1;
      label3 = lang.CreditSlip.Label3;
      label4 = lang.CreditSlip.Label4;
      label5 = lang.CreditSlip.Label5;

      edit = () => {
        componentVisible.setIsVisible(false);
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('edit'))
        navigate(uri.CreditSlipInvoiceSave);
      };

      duplicate = () => {
        componentVisible.setIsVisible(false);
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
        dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('duplicate'))
        navigate(uri.CreditSlipInvoiceSave);
      };

      getPdf = () => {
        if (invoice.id) {
          const service = new CustomerInvoiceService();

          setDownloadPdfStatus('loading');
          service
            .getPdf(invoice.id)
            .then(data => {
              componentVisible.setIsVisible(false);
              setDownloadPdfStatus('idle');
              downloadPdf(CustomerInvoiceService.getPdfName('customer', invoice.type, invoice.number, invoice.customer?.companyName), data, 'application/pdf');
            })
            .catch(err => {
              setDownloadPdfStatus('failed');
              console.error(err);
            });
        }
      };
      break;
    default:
      return (
        <div>Tipo fattura non valido</div>
      )
  }

  return (
    <div>
      {
        componentVisible.isVisible && (
          <div className={'invoiceMenu-container'}>
            <QuickfiscoError
              message={lang.DeletionError}
              active={deletionStatus === 'failed'}
              close={() => setDeletionStatus('idle')}
            />
            <QuickfiscoError
              message={lang.DownloadPdfError}
              active={downloadPdfStatus === 'failed'}
              close={() => setDownloadPdfStatus('idle')}
            />
            <QuickfiscoError
              message={lang.CancelError}
              active={cancelStatus === 'failed'}
              close={() => setCancelStatus('idle')}
            />
            {
              (invoiceSign === 'customer' || invoiceSign === 'auto' || invoiceSign === 'supplier' || invoiceSign === 'proforma' || invoiceSign === 'occasionalJob' || invoiceSign === 'creditSlip') && (
                <div>
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => edit()}
                  >
                    <div className={'invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'find.svg'} />
                    </div>
                    {label5}
                  </div>
                  <div className={'invoiceMenu-line mt-2 mb-2'} />
                </div>
              )
            }
            {
              downloadPdfStatus === 'loading' ? (
                <div className={'w-100 d-flex justify-content-center align-items-center'}>
                  <QuickfiscoSpinner alignCenter={true} />
                </div>
              ) : (
                <div
                  className={'invoiceMenu-item'}
                  onClick={() => getPdf()}
                >
                  <div className={'invoiceMenu-icon'}>
                    <QuickfiscoIcon name={'download.svg'} />
                  </div>
                  {label1}
                </div>
              )
            }
            <div className={'invoiceMenu-line mt-2 mb-2'} />
            {
              (
                invoice.status === InvoiceStatusType.DELIVERED ||
                invoice.status === InvoiceStatusType.ACCEPTED ||
                invoice.status === InvoiceStatusType.DUE_TERMS ||
                invoice.status === InvoiceStatusType.NOT_RECEIVED ||
                invoice.status === InvoiceStatusType.ALREADY_TRANSMITTED ||
                invoice.status === InvoiceStatusType.RECEIVED ||
                invoice.status === InvoiceStatusType.PAYMENT_DUE
              ) && invoice.type !== InvoiceType.TD04 &&
              invoice.type !== InvoiceType.STS &&
              invoice.type !== InvoiceType.TD16 &&
              invoice.type !== InvoiceType.TD17 &&
              invoice.type !== InvoiceType.TD18 &&
              invoice.type !== InvoiceType.TD19 && (
                <div>
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => {
                      componentVisible.setIsVisible(false);
                      setOpenPaymentSaveModal(true)
                    }}
                  >
                    <div className={'col-auto invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'payment.svg'} />
                    </div>
                    {label2}
                  </div>
                  <div className={'invoiceMenu-line mt-2 mb-2'} />
                </div>
              )
            }
            {
              (invoiceSign === 'proforma') && (
                <div>
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => convertToInvoice()}
                  >
                    <div className={'invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'convert-to-invoice.svg'} />
                    </div>
                    {label6}
                  </div>
                  <div className={'invoiceMenu-line mt-2 mb-2'} />
                </div>
              )
            }
            {
              (invoiceSign === 'customer' || invoiceSign === 'auto' || invoiceSign === 'proforma' || invoiceSign === 'occasionalJob' || invoiceSign === 'creditSlip') && (
                <div>
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => duplicate()}
                  >
                    <div className={'invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'duplicate.svg'} />
                    </div>
                    {label3}
                  </div>
                  { !(invoiceSign === 'occasionalJob' && invoice.status === 'REVERSED') && 
                    <div className={'invoiceMenu-line mt-2 mb-2'} />
                  }
                </div>
              )
            }
            {
              (invoiceSign === 'occasionalJob' && invoice.status === 'SENT') && (
                <div>
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => cancel()}
                  >
                    <div className={'invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'cancel.svg'} />
                    </div>
                    {label7}
                  </div>
                </div>
              )
            }
            {
              (!(invoiceSign === 'occasionalJob' && (invoice.status === 'SENT' || invoice.status === 'REVERSED'))) && (
                deletionStatus === 'loading' ? (
                  <div className={'w-100 d-flex justify-content-center align-items-center'}>
                    <QuickfiscoSpinner alignCenter={true} />
                  </div>
                ) : (
                  <div
                    className={'invoiceMenu-item'}
                    onClick={() => del(
                      invoiceId,
                      invoiceSign,
                      customerInvoiceFilters,
                      supplierInvoiceFilters,
                      autoInvoiceFilters,
                      proformaInvoiceFilters,
                      occasionalJobInvoiceFilters,
                      componentVisible,
                      dispatch,
                      setDeletionStatus,
                      invoice.documents,
                      invoice.reimbursementsOfExpenses
                    )}
                  >
                    <div className={'invoiceMenu-icon'}>
                      <QuickfiscoIcon name={'delete-icon.svg'} />
                    </div>
                    {label4}
                  </div>
                )
              )
            }
          </div>
        )
      }
      {
        invoiceSign !== 'auto' && invoiceSign !== 'proforma' && (
          <InvoicePaymentSave
            open={openPaymentSaveModal}
            setOpen={open => setOpenPaymentSaveModal(open)}
            invoiceSign={invoiceSign}
            invoice={invoice}
          />
        )
      }
    </div>
  );
}

function del(
  invoiceId: string,
  invoiceSign: InvoiceSign,
  customerInvoiceListFilters: InvoiceFilters,
  supplierInvoiceListFilters: InvoiceFilters,
  autoInvoiceListFilters: InvoiceFilters,
  proformaInvoiceFilters: InvoiceFilters,
  occasionalJobInvoiceFilters: InvoiceFilters,
  componentVisible: ComponentVisibleOutput,
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  documents?: string[] | null,
  reimbursementOfExpenses?: ReimbursementOfExpenseModel[] | null,
) {
  let service;
  if (invoiceSign === 'customer') {
    service = new CustomerInvoiceService();
  } else if (invoiceSign === 'supplier') {
    service = new InvoiceSupplierService();
  } else if (invoiceSign === 'proforma') {
    service = new ProformaInvoiceService();
  } else if (invoiceSign === 'auto') {
    service = new AutoInvoiceService();
  } else if (invoiceSign === 'occasionalJob') {
    service = new OccasionalJobInvoiceService();
  } else if (invoiceSign === 'creditSlip') {
    service = new CustomerInvoiceService();
  } else {
    return
  }

  setStatus('loading');
  service
    .del(invoiceId, documents, reimbursementOfExpenses)
    .then(() => {
      componentVisible.setIsVisible(false);

      switch (invoiceSign) {
        case 'customer':
          dispatch(getCustomerInvoiceList(customerInvoiceListFilters))
          break;
        case 'proforma':
          dispatch(getProformaInvoiceList(proformaInvoiceFilters))
          break;
        case 'supplier':
          dispatch(getSupplierInvoiceList(supplierInvoiceListFilters))
          break;
        case 'auto':
          dispatch(getAutoInvoiceList(autoInvoiceListFilters))
          break;
        case 'occasionalJob':
          dispatch(getOccasionalJobInvoiceList(occasionalJobInvoiceFilters))
          break;
        case 'creditSlip':
          dispatch(getCustomerInvoiceList({...customerInvoiceListFilters, type: InvoiceType.TD04, status: undefined}))
          break;
      }
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}
