import { useEffect, useState } from 'react';
import { ValidationFormModal } from './app/components/validationFormModal/validationFormModal';
import { useAppDispatch, useAppSelector } from './app/redux/hooks';
import { getUnreadCounters } from './app/redux/slices/messagesListSlice';
import { getMe } from './app/redux/slices/userSlice';
import { doLogout } from './app/redux/slices/loginSlice';
import { DefaultRouter } from './app/routers/defaultRouter';
import { RouterToAuthenticated } from './app/routers/routerToAuthenticated';
import { PaymentRouterToAuthenticated } from './app/routers/paymentRouterToAuthenticated';
import { DefaultPaymentRouter } from './app/routers/defaultPaymentRouter';
import { Http } from './app/utils/http';

function App() {
  const [openValidationFormModal, setOpenValidationFormModal] = useState(false);

  const dispatch = useAppDispatch();

  const loggedIn = useAppSelector((state) => state.login.loggedIn);
  const userState = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getMe());
      dispatch(getUnreadCounters());
    }
  }, [loggedIn]);

  useEffect(() => {
    if (
      (userState.validationFormStatus === 0 &&
        userState.status === 4 &&
        userState.enabled === true) ||
      (userState.validationFormStatus === -1 &&
        userState.status === 4 &&
        userState.enabled === true) ||
      (userState.validationFormStatus === undefined &&
        userState.status === 4 &&
        userState.enabled === true)
    ) {
      setOpenValidationFormModal(true);
    } else {
      setOpenValidationFormModal(false);
    }
  }, [userState.validationFormStatus, userState.status, userState.enabled]);

  // Init http requests
  Http.getInstance(dispatch);

  if (window.location.pathname.startsWith('/onboarding/welcome') && loggedIn) {
    dispatch(doLogout());
    return <DefaultPaymentRouter />;
  }

  if (window.location.pathname.startsWith('/onboarding/') && loggedIn) {
    return <PaymentRouterToAuthenticated />;
  }

  if (window.location.pathname.startsWith('/onboarding/')) {
    return <DefaultPaymentRouter />;
  }

  if (loggedIn) {
    return (
      <>
        <RouterToAuthenticated />
        <ValidationFormModal
          open={openValidationFormModal}
          setOpen={setOpenValidationFormModal}
          validationFormStatus={userState.validationFormStatus}
        />
      </>
    );
  }

  return <DefaultRouter />;
}

export default App;
