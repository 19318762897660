import { useEffect, useState } from "react";
import config from "../../../config.json";
import { ProfileStatus, StsProfileModel } from "../../models/stsProfileModel";
import { CategoryType, UserModel } from "../../models/userModel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCustomerInvoiceListIsStsFilter } from "../../redux/slices/customerInvoiceListSlice";
import { getStsProfile } from "../../redux/slices/stsProfileSlice";
import {
  getMe,
  setAtecos,
  setCompanyName,
  setFund,
  setInitialInvoiceNumber,
  setInitialInvoiceNumberSTS,
  setPec,
  setRecourseValue,
  setVat,
  setVatOpeningDate,
  updateMe,
} from "../../redux/slices/userSlice";
import { StsProfileService } from "../../services/stsProfileService";
import { UserService } from "../../services/userService";
import { SetState } from "../../types/functions";
import { PromiseStatuses } from "../../types/strings";
import { formatDateUS } from "../../utils/date";
import { FundSelect } from "../fundSelect/fundSelect";
import { MultiAtecoInput } from "../multiAtecoInput/multiAtecoInput";
import { QuickfiscoButton } from "../quickfiscoButton/quickfiscoButton";
import { QuickfiscoCheckbox } from "../quickfiscoCheckbox/quickfiscoCheckbox";
import { QuickfiscoDatePicker } from "../quickfiscoDatePicker/quickfiscoDatePicker";
import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";
import { InpsFundDescriptionSelect } from "../inpsFundDescriptionSelect/inpsFundDescriptionSelect";
import { QuickfiscoInput } from "../quickfiscoInput/quickfiscoInput";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { QuickfiscoSwitchInput } from "../quickfiscoSwitchInput/quickfiscoSwitchInput";
import "./profileFiscalInformations.css";
import lang from "./profileFiscalInformations.json";
import { getItem, setItem } from "../../utils/sessionStorage";

export function ProfileFiscalInformations() {
  const userState = useAppSelector((state) => state.user);
  const invoices = useAppSelector(
    (state) => state.customerInvoiceList.invoices
  );

  const loggedIn = useAppSelector((state) => state.login.loggedIn);
  const stsProfile = useAppSelector((state) => state.stsProfile);

  const [stsProfileStatus, setStsProfileStatus] =
    useState<PromiseStatuses>("idle");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getStsProfile());
    }
  }, [loggedIn]);

  useEffect(() => {
    if (stsProfileStatus === "successfully") {
      dispatch(getMe());
    }
  }, [stsProfileStatus]);

  const userService = new UserService(dispatch);
  const userIsNotEnabled = UserService.isNotEnabled(userState.user);

  const editUserRequest = userState.editUserRequest;
  const editStatus = userState.editStatus;

  const categoryLabelMap = new Map<CategoryType | undefined, string>([
    [CategoryType.ARTISAN, "Artigiano"],
    [
      CategoryType.FUND,
      "Libero Professionista con iscrizione Cassa Ordine Professionale",
    ],
    [
      CategoryType.NOT_FUND,
      "Libero Professionista con iscrizione Gestione Separata INPS",
    ],
    [CategoryType.TRADER, "Commerciante"],
  ]);

  return (
    <div className="row p-4">
      <div className="col-12">
        <div className={"row no-gutters"}>
          <div className={"col profile-fiscal-informations-title"}>
            {lang.FiscalData}
          </div>
        </div>
        <div className="row no-gutters mt-3">
          <div className="col-6">
            <QuickfiscoInput
              id="profile-fiscal-informations-regime"
              label={lang.FiscalRegime}
              styleType={"default"}
              type="text"
              required={true}
              disabled={true}
              value={
                editUserRequest.fiscalRegime === "flat"
                  ? lang.FiscalRegimeFlat
                  : lang.FiscalRegimeOrdinary
              }
            />
          </div>
          <div className="col-6 d-none">
            <QuickfiscoInput
              id={"profile-fiscal-informations-save-taxation"}
              type={"text"}
              label={lang.Taxation}
              required={true}
              disabled={true}
              styleType={"default"}
              defaultValue={
                editUserRequest.taxation
                  ? String(editUserRequest.taxation) + "%"
                  : ""
              }
              placeholder={lang.TaxationPlaceholder}
            />
          </div>
        </div>
        <div className={"row no-gutters"}>
          <div className="col-12">
            <QuickfiscoInput
              id="profile-fiscal-informations-category"
              label={lang.Category}
              styleType={"default"}
              type="text"
              disabled={true}
              required={true}
              value={categoryLabelMap.get(editUserRequest.category)}
              placeholder={lang.CategoryPlaceholder}
            />
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-6">
            <QuickfiscoInput
              id="profile-fiscal-informations-vat"
              label={lang.VatLabel}
              styleType={"default"}
              type="text"
              placeholder={lang.VatPlaceholder}
              defaultValue={editUserRequest.vat}
              error={userState.errorVat}
              errorLabel={lang.ErrorVat}
              required={true}
              onChange={(e) => dispatch(setVat(e.target.value))}
              onBlur={(e) => userService.validateVat(e.target.value)}
            />
          </div>
          <div className="col-6">
            <QuickfiscoDatePicker
              id="profile-fiscal-informations-vat-opening-date"
              label={lang.VatOpeningDate}
              styleType={"default"}
              required={true}
              maxDate={new Date()}
              onChange={(e) =>
                e && dispatch(setVatOpeningDate(formatDateUS(e)))
              }
              value={
                editUserRequest.vatOpeningDate
                  ? new Date(editUserRequest.vatOpeningDate)
                  : undefined
              }
              onBlur={(e) => {
                userService.validateVatOpeningDate(e.target.value);
              }}
              error={userState.errorVatOpeningDate}
              errorLabel={lang.ErrorVatOpeningDate}
            />
          </div>
        </div>
        {(editUserRequest.category == CategoryType.ARTISAN ||
          editUserRequest.category == CategoryType.TRADER) && (
          <div className={"row no-gutters d-flex align-items-center"}>
            <div className="col-12">
              <QuickfiscoInput
                id="profile-fiscal-informations-taxation"
                label={lang.companyName}
                onChange={(e) => dispatch(setCompanyName(e.target.value))}
                placeholder={
                  lang.companyNameFirstPlaceholder +
                  editUserRequest.name +
                  " " +
                  editUserRequest.surname +
                  "'" +
                  lang.companyNameSecondPlaceholder
                }
                type={"text"}
                styleType={"default"}
                value={editUserRequest.companyName}
                disabled={userState.user.companyName !== undefined}
                required={true}
              />
            </div>
          </div>
        )}
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-6 mb-3">
            <QuickfiscoSwitchInput
              id="profile-fiscal-informations-taxation"
              label={lang.taxation}
              checked={editUserRequest.taxation === 5}
              required={true}
              disabled={true}
            />
          </div>
          {editUserRequest.taxation === 5 && (
            <div className="col-6">
              <QuickfiscoInput
                id="profile-fiscal-informations-last-year-taxation"
                label={lang.lastYearTaxation}
                styleType={"default"}
                type="text"
                value={editUserRequest.lastYearTaxation}
                placeholder={lang.lastYearTaxationPlaceholder}
                required={true}
                disabled={true}
              />
            </div>
          )}
        </div>
        <div className="row no-gutters">
          <div className="col-12 ">
            <MultiAtecoInput
              atecoList={editUserRequest.atecos}
              error={userState.errorAteco}
              onChange={(_ateco) => dispatch(setAtecos(_ateco))}
              requiredField={true}
              isReadOnly={true}
            />
          </div>
        </div>
        <div className="row no-gutters mb-3">
          <div className="col-12">
            <div className="profile-fiscal-informations-ateco-warn p-3">
              <QuickfiscoIcon
                name={"alert-triangle-blue.svg"}
                className="me-2"
              />
              {lang.atecoWarn}
              <a
                target={"_blank"}
                href={`mailto:${config.mail}`}
                rel={"noreferrer"}
              >
                {config.mail}
              </a>
            </div>
          </div>
        </div>
        <div className={"row no-gutters"}>
          <div className={"col profile-fiscal-informations-title"}>
            {lang.ContributionAndFund}
          </div>
        </div>
        <div className="row no-gutters mt-3">
          <div className="col-12">
            <FundSelect
              id={"profile-fiscal-informations-fund"}
              required={true}
              value={editUserRequest.fund}
              errorFund={userState.errorFund}
              setFund={(_fund) => {
                dispatch(setFund(_fund));
                userService.validateFund(_fund);
              }}
              setRecourseValue={(_recourseValue) =>
                dispatch(setRecourseValue(_recourseValue))
              }
              disabled={true}
            />
          </div>
          {editUserRequest.fund === "TC22" && (
            <div className={"row mt-3"}>
              <div className={"col-6"}>
                <InpsFundDescriptionSelect
                  id="profile-fiscal-informations-inps-description"
                  value={editUserRequest.inpsFundType}
                />
              </div>
            </div>
          )}
          <div className="row no-gutters">
            <div className="col-6">
              {(editUserRequest.category === CategoryType.ARTISAN ||
                editUserRequest.category === CategoryType.TRADER) && (
                <>
                  <div className="row mt-3">
                    <div className={"col-12"}>
                      <QuickfiscoSwitchInput
                        id="profile-fiscal-informations-contribution-reduction"
                        label={lang.ContributionReduction}
                        checked={editUserRequest.contributionReduction}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className={"col-12"}>
                      <QuickfiscoSwitchInput
                        id="profile-fiscal-informations-contribution-reduction"
                        label={lang.ContributionExemption}
                        checked={editUserRequest.contributionExemption}
                        disabled={true}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-12 no-gutters">
              <div className={"row no-gutters"}>
                <div className={"col profile-fiscal-informations-title"}>
                  {lang.TitleFulfillments}
                </div>
              </div>
              <div className="row no-gutters mt-3 align-items-start">
                <div className="col-3">
                  <QuickfiscoSwitchInput
                    id="profile-fiscal-informations-vies-sub"
                    label={lang.ViesSubscription}
                    checked={
                      editUserRequest.viesSubscription === true ? true : false
                    }
                    disabled={true}
                  />
                </div>
                <div className="col-3">
                  <QuickfiscoDatePicker
                    id="profile-fiscal-informations-vies-sub-date"
                    label={lang.ViesSubscriptionDate}
                    styleType={"default"}
                    maxDate={new Date()}
                    disabled={true}
                    value={
                      editUserRequest.viesSubscriptionDate
                        ? new Date(editUserRequest.viesSubscriptionDate)
                        : undefined
                    }
                  />
                </div>
                <div className="col-6">
                  <QuickfiscoSwitchInput
                    id="profile-fiscal-informations-intrastat-foreign-activities"
                    label={lang.IntrastatForeignActivities}
                    checked={
                      editUserRequest.intrastatForeignActivities === true
                        ? true
                        : false
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {editUserRequest.ddrYears && (
                <div className="row no-gutters mt-2">
                  <div className="col-12">
                    <div
                      className={"profile-fiscal-informations-ddr-years-label"}
                    >
                      {lang.DdrYears}
                    </div>
                    <div className={"d-flex"}>
                      {editUserRequest.ddrYears.map((year) => (
                        <div
                          className={"me-5"}
                          key={`profile-fiscal-informations-ddr-years-${year}`}
                        >
                          <QuickfiscoCheckbox
                            id={`profile-fiscal-informations-ddr-years-${year}`}
                            disabled={true}
                            label={year.toString()}
                            checked={true}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-12 no-gutters">
              <div className={"row no-gutters"}>
                <div className={"col profile-fiscal-informations-title"}>
                  {lang.TitleElectronicInvoicing}
                </div>
              </div>
              <div className="row no-gutters mt-3">
                <div className="col-6">
                  <QuickfiscoInput
                    type={"text"}
                    label={lang.InvoicingProtocol}
                    placeholder={lang.InvoicingProtocolPlaceholder}
                    id={"profile-fiscal-informations-save-invoicing-protocol"}
                    styleType={"default"}
                    defaultValue={editUserRequest.initialInvoiceNumber}
                    onChange={(e) =>
                      dispatch(setInitialInvoiceNumber(e.target.value))
                    }
                    error={userState.errorInitialInvoiceNumber}
                    errorLabel={lang.ErrorInvoicingProtocol}
                    required={true}
                    disabled={invoices && !invoices.empty}
                    onBlur={(e) =>
                      userService.validateInitialInvoiceNumber(e.target.value)
                    }
                  />
                </div>
                {editUserRequest.stsEnabled && (
                  <div className="col-6">
                    <QuickfiscoInput
                      type={"text"}
                      label={lang.InvoicingProtocolSTS}
                      placeholder={lang.InvoicingProtocolPlaceholderSTS}
                      id={"profile-fiscal-informations-save-invoicing-protocol"}
                      styleType={"default"}
                      defaultValue={editUserRequest.initialInvoiceNumberSTS}
                      onChange={(e) =>
                        dispatch(setInitialInvoiceNumberSTS(e.target.value))
                      }
                      error={userState.errorInitialInvoiceNumber}
                      errorLabel={lang.ErrorInvoicingProtocolSTS}
                      required={true}
                      disabled={invoices && !invoices.empty}
                      onBlur={(e) =>
                        userService.validateInitialInvoiceNumber(e.target.value)
                      }
                    />
                  </div>
                )}
                <div className="col-6">
                  <QuickfiscoInput
                    id="profile-fiscal-informations-pec"
                    label={lang.PecLabel}
                    styleType={"default"}
                    type="text"
                    placeholder={lang.PecPlaceholder}
                    onChange={(e) => dispatch(setPec(e.target.value))}
                    defaultValue={editUserRequest.pec}
                    error={userState.errorPec}
                    errorLabel={lang.ErrorPec}
                    onBlur={() => userService.validatePec(editUserRequest.pec)}
                  />
                </div>
              </div>
              {userState.user.status === 4 && (
                <div className="row no-gutters">
                  <div className="col-6 profile-fiscal-informations-wa-link">
                    {lang.InvoicingProtocolHelp_1}
                    <a
                      href={config.whatsappLink}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      {lang.InvoicingProtocolHelp_link}
                    </a>
                    {lang.InvoicingProtocolHelp_2}
                  </div>
                </div>
              )}
              <div className="row no-gutters align-items-center">
                <div className="col-5">
                  {stsProfileStatus === "loading" ? (
                    <QuickfiscoSpinner />
                  ) : (
                    <QuickfiscoSwitchInput
                      id={"profile-fiscal-informations-save-sts"}
                      label={lang.StsActivationLabel}
                      onChange={(checked) => {
                        if (!checked && stsProfile.httpCode === `200`) {
                          changeStsProfileStatus(
                            dispatch,
                            setStsProfileStatus,
                            ProfileStatus.INACTIVE
                          );
                          dispatch(setCustomerInvoiceListIsStsFilter(false));
                        } else if (checked && stsProfile.httpCode === `403`) {
                          changeStsProfileStatus(
                            dispatch,
                            setStsProfileStatus,
                            ProfileStatus.ACTIVE
                          );

                          dispatch(setCustomerInvoiceListIsStsFilter(true));
                        } else {
                          saveStsProfile(dispatch, setStsProfileStatus, {
                            identificationCode: editUserRequest.taxCode,
                            trackedPayment: false,
                          });
                          dispatch(setCustomerInvoiceListIsStsFilter(false));
                        }
                      }}
                      checked={
                        editUserRequest.stsEnabled ||
                        getItem("stsProfileActive") === "true"
                      }
                      disabled={userIsNotEnabled}
                      subLabel={lang.StsSubtitle}
                    />
                  )}
                </div>
                <div className="col-6 offset-1">
                  <div className="profile-fiscal-informations-sdi p-4">
                    <div className="profile-fiscal-informations-sdi-text">
                      <b>{lang.SdiText_bold}</b>
                      {lang.SdiText}
                    </div>
                    <div className="profile-fiscal-informations-sdi-code mt-3">
                      {config.SDI}
                      <QuickfiscoIcon
                        name={"copy-button.svg"}
                        className={"bring-a-friend-modal-copy-button"}
                        onClick={() => {
                          navigator.clipboard.writeText(config.SDI);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-4 offset-4 d-flex justify-content-center align-items-center">
                  {editStatus === "loading" ? (
                    <QuickfiscoSpinner />
                  ) : (
                    <QuickfiscoButton
                      type="secondary"
                      id="profile-fiscal-informations-edit"
                      label={lang.ButtonLabel}
                      onClick={() => update(editUserRequest, dispatch)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function saveStsProfile(
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  profile: StsProfileModel
) {
  const stsProfileService = new StsProfileService();

  setStatus("loading");
  stsProfileService
    .save(profile)
    .then(() => {
      setItem("stsProfileActive", "true");
      dispatch(getStsProfile());
      setStatus("successfully");
    })
    .catch((err) => {
      console.error(err);
      setStatus("failed");
    });
}

function changeStsProfileStatus(
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  status: ProfileStatus
) {
  const stsProfileService = new StsProfileService();

  setStatus("loading");
  stsProfileService
    .changeStatus(status)
    .then(() => {
      dispatch(getStsProfile());
      setStatus("successfully");
    })
    .catch((err) => {
      console.error(err);
      setStatus("failed");
    });
}

async function update(editUserRequest: UserModel, dispatch: Function) {
  const userService = new UserService(dispatch);

  if (
    !(await userService.validate(editUserRequest, {
      skipConfirmPassword: true,
      skipPassword: true,
      skipEmailAvailable: true,
      skipPersonalProfileSaving: true,
    }))
  ) {
    return;
  }

  dispatch(updateMe(editUserRequest));
}
