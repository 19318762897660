import { useState } from 'react';
import { PaymentTermsType } from '../../models/invoiceModel';
import { OptionModel } from '../../models/optionModel';
import { useAppSelector } from '../../redux/hooks';
import { InvoiceSign } from '../../types/strings';
import { formatDateUS, getDateFromPaymentTerms, toDate } from '../../utils/date';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './paymentExpirationInput.json';

interface Props {
  id: string;
  value?: Date;
  error?: boolean;
  startDate?: Date;
  disabled?: boolean;
  invoiceSign?: InvoiceSign;
  invoicePaymentTerms?: PaymentTermsType;
  onBlur?: ((expirationDate?: Date, date?: Date) => void);
  onChange?: ((value?: Date, valueTerms?: PaymentTermsType) => void);
}

export function PaymentExpirationInput(props: Props) {
  const { id } = props;
  const { error } = props;
  const { value } = props;
  const { startDate } = props;
  const { disabled } = props;
  const { invoiceSign } = props;
  const { invoicePaymentTerms } = props;
  const { onBlur } = props;
  const { onChange } = props;

  const [options, setOptions] = useState<OptionModel[]>(getOptions(invoicePaymentTerms));
  const invoiceDate=useAppSelector(state => state.stsInvoice.invoice.date)

  if (invoiceSign === 'sts') {
    return (
      <div className={'row'}>
        <div className={'col'}>
          <QuickfiscoDatePicker
            label={lang.Label3}
            id={'invoice-customer-save-expiration'}
            styleType={'default'}
            value={value && new Date(value)}
            error={error}
            errorLabel={lang.Error}
            maxDate={new Date()}
            minDate={new Date(invoiceDate)}
            onChange={(e) => {
              e && onChange && onChange(toDate(formatDateUS(e)));
            }}
            required={true}
            onBlur={(e) => onBlur && onBlur(toDate(e.target.value), startDate)}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className={'row'}>
        <div className={'col-8'}>
          <QuickfiscoSelect
            id={id}
            error={false}
            errorLabel={lang.Error}
            label={lang.Label1}
            options={options}
            disabled={disabled}
            styleType={'default'}
            value={getOptions(invoicePaymentTerms).filter(option => option.selected === true)[0]}
            onChange={(e) => {
              // @ts-ignore
              const date = getDateFromPaymentTerms(startDate ? startDate : new Date(), PaymentTermsType[e.value]);
              if (!date && e?.value !== PaymentTermsType.CUSTOMIZED) {
                onChange && onChange(undefined, undefined);
                return;
              }

              // @ts-ignore
              setOptions(getOptions(PaymentTermsType[e.value]));
              // @ts-ignore
              onChange && onChange(date, PaymentTermsType[e.value]);
            }}
          />
        </div>
        <div className={'col-4'}>
          <QuickfiscoDatePicker
            label={lang.Label2}
            id={'invoice-customer-save-expiration'}
            styleType={'default'}
            value={value && new Date(value)}
            error={error}
            disabled={disabled}
            errorLabel={lang.Error}
            onChange={(e) => {
              setOptions(getOptions(PaymentTermsType.CUSTOMIZED));
              e && onChange && onChange(toDate(formatDateUS(e)), PaymentTermsType.CUSTOMIZED);
            }}
            required={true}
            onBlur={(e) => onBlur && onBlur(toDate(e.target.value), startDate)}
          />
        </div>
      </div>
    );

  }
}

function getOptions(value?: PaymentTermsType): OptionModel[] {
  return [
    {
      label: 'Seleziona termini di pagamento',
      value: undefined,
      selected: value === undefined
    },
    {
      label: 'Vista Fatt.',
      value: PaymentTermsType.IMMEDIATE,
      selected: value === PaymentTermsType.IMMEDIATE
    },
    {
      label: '30gg DF',
      value: PaymentTermsType.DF30,
      selected: value === PaymentTermsType.DF30
    },
    {
      label: '60gg DF',
      value: PaymentTermsType.DF60,
      selected: value === PaymentTermsType.DF60
    },
    {
      label: '90gg DF',
      value: PaymentTermsType.DF90,
      selected: value === PaymentTermsType.DF90
    },
    {
      label: '30gg FM',
      value: PaymentTermsType.FM30,
      selected: value === PaymentTermsType.FM30
    },
    {
      label: '60gg FM',
      value: PaymentTermsType.FM60,
      selected: value === PaymentTermsType.FM60
    },
    {
      label: '90gg FM',
      value: PaymentTermsType.FM90,
      selected: value === PaymentTermsType.FM90
    },
    {
      label: 'Personalizzato',
      value: PaymentTermsType.CUSTOMIZED,
      selected: value === PaymentTermsType.CUSTOMIZED
    }
  ];
}
