import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AutoInvoiceList } from '../views/autoInvoiceList/autoInvoiceList';
import {
  AutoInvoiceSaveOrDuplicateOrEdit
} from '../views/autoInvoiceSaveOrDuplicateOrEdit/autoInvoiceSaveOrDuplicateOrEdit';
import { CreditSlipInvoiceList } from '../views/creditSlipInvoiceList/creditSlipInvoiceList';
import { CustomerEdit } from '../views/customerEdit/customerEdit';
import { CustomerInvoiceList } from '../views/customerInvoiceList/customerInvoiceList';
import {
  CustomerInvoiceSaveOrDuplicateOrEdit
} from '../views/customerInvoiceSaveOrDuplicateOrEdit/customerInvoiceSaveOrDuplicateOrEdit';
import { CustomerSave } from '../views/customerSave/customerSave';
import { CustomersList } from '../views/customersList/customersList';
import { Documents } from '../views/documents/documents';
import { FeesRegister } from '../views/feesRegister/feesRegister';
import { FinanceView } from '../views/finance/finance';
import { Home } from '../views/home/home';
import { MessagePage } from '../views/messagePage/messagePage';
import { Messages } from '../views/messages/messages';
import { OccasionalJobInvoiceEdit } from '../views/occasionalJobInvoiceEdit/occasionalJobInvoiceEdit';
import { OccasionalJobInvoiceList } from '../views/occasionalJobInvoiceList/occasionalJobInvoiceList';
import { OccasionalJobInvoiceSaveOrDuplicate } from '../views/occasionalJobInvoiceSaveOrDuplicate/occasionalJobInvoiceSaveOrDuplicate';
import { Profile } from '../views/profile/profile';
import { ProformaInvoiceList } from '../views/proformaInvoiceList/proformaInvoiceList';
import { ProformaInvoiceSaveOrDuplicateOrEdit } from '../views/proformaInvoiceSaveOrDuplicateOrEdit/proformaInvoiceSaveOrDuplicateOrEdit';
import { StsConfiguration } from '../views/stsConfiguration/stsConfiguration';
import { StsHistory } from '../views/stsHistory/stsHistory';
import {
  StsInvoiceSaveOrDuplicateOrEdit
} from '../views/stsInvoiceSaveOrDuplicateOrEdit/stsInvoiceSaveOrDuplicateOrEdit';
import { SubscriptionAndSettings } from '../views/subscriptionAndSettings/subscriptionAndSettings';
import { SupplierEdit } from '../views/supplierEdit/supplierEdit';
import { SupplierInvoiceList } from '../views/supplierInvoiceList/supplierInvoiceList';
import { SupplierInvoiceSave } from '../views/supplierInvoiceSave/supplierInvoiceSave';
import { SupplierSave } from '../views/supplierSave/supplierSave';
import { SuppliersList } from '../views/suppliersList/suppliersList';
import { Support } from '../views/support/support';
import { InvoiceImportList } from '../views/invoiceImportList/invoiceImportList';
import { TaxCalculation } from '../views/taxCalculation/taxCalculation';
import { TaxDeclaration } from '../views/taxDeclaration/taxDeclaration';
import { TaxReturn } from '../views/taxReturn/taxReturn';
import { InvoiceImportStsList } from '../views/invoiceImportStsList/invoiceImportStsList';
import { IncomeUploadModal } from '../components/incomeUploadModal/incomeUploadModal';
import { ExceedingThresholdsModal } from '../components/exceedingThresholdsModal/exceedingThresholdsModal';
import { RegimeLimitStatusType } from '../models/userModel';
import { useAppSelector } from '../redux/hooks';
import uri from './quickfiscoUri.json';

export function RouterToAuthenticated() {
  const userState = useAppSelector((state) => state.user.user);

  return (
    <BrowserRouter>
      <IncomeUploadModal />
      {(userState.regimeLimit?.status === RegimeLimitStatusType.OVER85k ||
        userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k) &&
        <ExceedingThresholdsModal
          type={'100k'}
        />
      }
      <Routes>
        <Route path={uri.TaxDeclaration} element={<TaxDeclaration />} />
        <Route path={uri.ProformaInvoice} element={<ProformaInvoiceList />} />
        <Route path={uri.ProformaInvoiceSave} element={<ProformaInvoiceSaveOrDuplicateOrEdit />} />
        <Route path={uri.OccasionalJobInvoice} element={<OccasionalJobInvoiceList />} />
        <Route path={uri.OccasionalJobInvoice + '/:id'} element={<OccasionalJobInvoiceEdit />} />
        <Route path={uri.OccasionalJobInvoiceSaveOrDuplicate} element={<OccasionalJobInvoiceSaveOrDuplicate />} />
        <Route path={uri.FeesRegister} element={<FeesRegister />} />
        <Route path={uri.TaxCalculation} element={<TaxCalculation />} />
        <Route path={uri.AutoInvoiceSave} element={<AutoInvoiceSaveOrDuplicateOrEdit />} />
        <Route path={uri.AutoInvoice} element={<AutoInvoiceList />} />
        <Route path={uri.StsInvoiceSave} element={<StsInvoiceSaveOrDuplicateOrEdit />} />
        <Route path={uri.StsHistory} element={<StsHistory />} />
        <Route path={uri.StsConfiguration} element={<StsConfiguration />} />
        <Route path={uri.SupplierEdit + '/:id'} element={<SupplierEdit />} />
        <Route path={uri.CustomerEdit + '/:id'} element={<CustomerEdit />} />
        <Route path={uri.CustomerInvoiceSave} element={<CustomerInvoiceSaveOrDuplicateOrEdit />} />
        <Route path={uri.CreditSlipInvoiceSave} element={<CustomerInvoiceSaveOrDuplicateOrEdit />} />
        <Route path={uri.SupplierInvoiceSave} element={<SupplierInvoiceSave />} />
        <Route path={uri.SupplierInvoiceList} element={<SupplierInvoiceList />} />
        <Route path={uri.CustomerInvoiceList} element={<CustomerInvoiceList />} />
        <Route path={uri.CreditSlipInvoiceList} element={<CreditSlipInvoiceList />} />
        <Route path={uri.StsInvoiceSave} element={<StsInvoiceSaveOrDuplicateOrEdit />} />
        <Route path={uri.SupplierSave} element={<SupplierSave />} />
        <Route path={uri.CustomerSave} element={<CustomerSave />} />
        <Route path={uri.SuppliersList} element={<SuppliersList />} />
        <Route path={uri.CustomersList} element={<CustomersList />} />
        <Route path={uri.InvoiceImport} element={<InvoiceImportList />} />
        <Route path={uri.InvoiceImportSts} element={<InvoiceImportStsList />} />
        <Route path={uri.Profile} element={<Profile />} />
        <Route path={uri.Support} element={<Support />} />
        <Route path={uri.SubscriptionAndSettings} element={<SubscriptionAndSettings />} />
        <Route path={uri.Documents} element={<Documents />} />
        <Route path={uri.Messages + '/:id'} element={<MessagePage />} />
        <Route path={uri.Messages} element={<Messages />} />
        <Route path={uri.Finance} element={<FinanceView />} />
        <Route path={uri.TaxReturn} element={<TaxReturn />} />
        <Route path={uri.Home} element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}
