import './onboardingStatusBoxBanner.css';

export function OnboardingStatusBoxBanner() {
  return (
    <div className={'onboarding-status-box-banner-container mb-4'}>
      <div>
        <b>ACCOUNT IN VERIFICA.</b> Molte funzionalità sono disabilitate, potrai
        utilizzare il software quando l’account risulterà “verificato” e verrà{' '}
        <b>ATTIVATO.</b>
      </div>
    </div>
  );
}
