import { DocumentsModel } from '../../models/documentsModel';
import { PaginationModel } from '../../models/paginationModel';
import { useAppSelector } from '../../redux/hooks';
import { PromiseStatuses } from '../../types/strings';
import { DocumentsListRow } from '../documentsListRow/documentsListRow';
import { EmptyList } from '../emptyList/emptyList';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './documentsList.json';
import './documentsList.css';

export function DocumentList() {
  const documentsListState = useAppSelector((state) => state.documentsList);

  let status: PromiseStatuses;
  let documents: PaginationModel<DocumentsModel>;
  status = documentsListState.status;
  documents = documentsListState.documents;

  if (status === 'loading') {
    return (
      <div className={'documentList-empty'}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="documentList-empty">
        <div className={'p-blue'}>{lang.loadingError}</div>
      </div>
    );
  }

  if (documents.content.length <= 0) {
    return (
      <div className={'documentList-empty'}>
        <EmptyList type={'documents'} />
      </div>
    );
  }

  const rows = documents.content.map((document) => {
    return <DocumentsListRow key={document.id} document={document} />;
  });

  return (
    <div className={'documentList-container'}>
      <div className={'row no-gutters mb-3'}>
        <div className={'col documentList-heading documentList-type'}>{lang.line1}</div>
        <div className={'col documentList-heading documentList-name'}>{lang.line2}</div>
        <div className={'col documentList-heading documentList-date'}>{lang.line3}</div>
        <div className={'col documentList-heading documentList-note'}>{lang.line4}</div>
        <div className={'col-auto'}>
          <div style={{ width: '25px' }} />
        </div>
      </div>
      {rows}
    </div>
  );
}
