import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  onChange?: (consumptionType?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoConsumptionTypeSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'Tipologia di consumo'}
      options={getOptions()}
      styleType={'default'}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
    />
  );
}
function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Servizi telefonici compresi quelli accessori',
      value: 'Servizi telefonici compresi quelli accessori',
      selected: value === 'Servizi telefonici compresi quelli accessori',
    },
    {
      label: 'Energia elettrica',
      value: 'Energia elettrica',
      selected: value === 'Energia elettrica',
    },
    {
      label:
        'Carburante, lubrificanti e simili utilizzati esclusivamente per la trazione di autoveicoli',
      value:
        'Carburante, lubrificanti e simili utilizzati esclusivamente per la trazione di autoveicoli',
      selected:
        value ===
        'Carburante, lubrificanti e simili utilizzati esclusivamente per la trazione di autoveicoli',
    },
  ];
}
