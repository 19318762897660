import { useEffect, useState } from "react";
import { InvoiceImportModel } from "../../models/invoiceImportModel";
import { InvoiceModel } from "../../models/invoiceModel";
import { PromiseStatuses } from "../../types/strings";
import { EmptyList } from "../emptyList/emptyList";
import { InvoiceImportListRow } from "../invoiceImportListRow/invoiceImportListRow";
import { InvoiceImportModal } from "../invoiceImportModal/invoiceImportModal";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { QuickfiscoSuccess } from "../quickfiscoSuccess/quickfiscoSuccess";
import "./invoiceImportListComponent.css";
import lang from "./invoiceImportListComponent.json";

interface Props {
  invoiceList: InvoiceModel[];
  status: PromiseStatuses;
  getPaginationInvoices: () => void;
  updatePaginationInvoices: (
    index: number,
    updates: InvoiceImportModel
  ) => void;
  type: "customer" | "sts";
}

export function InvoiceImportListComponent(props: Props) {
  const { invoiceList } = props;
  const { status } = props;
  const { updatePaginationInvoices } = props;
  const { type } = props;
  const { getPaginationInvoices } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [indexInvoice, setIndexInvoice] = useState<number | undefined>(
    undefined
  );
  const [statusSaving, setStatusSaving] = useState<PromiseStatuses>("idle");

  useEffect(() => {
    if (statusSaving === "successfully") {
      getPaginationInvoices();
    }
  }, [statusSaving]);

  useEffect(() => {
    if (indexInvoice && indexInvoice > 0) {
      setOpen(true);
    }
  }, [indexInvoice]);

  if (status === "loading") {
    return (
      <div className={"invoiceImportList-empty"}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === "failed") {
    return (
      <div className="invoiceImportList-empty">
        <div className={"p-blue"}>{lang.loadingError}</div>
      </div>
    );
  }

  if (invoiceList.length <= 0) {
    return (
      <div className={"invoiceImportList-empty"}>
        <EmptyList type={"customer"} />
      </div>
    );
  }

  const rows = invoiceList.map((invoice, index) => {
    return (
      <div className="invoiceImportList-container mb-3" key={invoice.id}>
        <InvoiceImportListRow
          invoice={invoice}
          index={index}
          updatePaginationInvoices={updatePaginationInvoices}
          openInvoiceModal={() => setIndexInvoice(index + 1)}
          setStatusSaving={setStatusSaving}
          type={type}
        />
      </div>
    );
  });

  return (
    <>
      <QuickfiscoSuccess
        message={lang.editSuccess}
        active={statusSaving === "successfully"}
        close={() => setStatusSaving("idle")}
      />
      {rows}
      {indexInvoice && invoiceList && (
        <InvoiceImportModal
          open={open}
          setOpen={(open: boolean) => {
            setOpen(open);
            setIndexInvoice(undefined);
          }}
          invoice={invoiceList[indexInvoice - 1]}
          index={indexInvoice}
          updatePaginationInvoices={updatePaginationInvoices}
          setStatusSaving={setStatusSaving}
          type={type}
        />
      )}
    </>
  );
}
