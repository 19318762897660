import React from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { ServiceInvoicesTaxReturnModel } from '../../models/taxReturnModel';
import { NumericFormat } from 'react-number-format';
import { QuickfiscoConsumptionTypeSelect } from '../quickfiscoConsumptionTypeSelect/quickfiscoConsumptionTypeSelect';
import lang from './serviceInvoicesAnswer.json';
import './serviceInvoicesAnswer.css';

interface Props {
  id: string;
  servicesAnswers: ServiceInvoicesTaxReturnModel[];
  setServicesAnswers: (servicesAnswers: ServiceInvoicesTaxReturnModel[]) => void;
  error?: boolean;
  errorLabel?: string;
}

export function ServiceInvoicesAnswer(props: Props) {
  const { id } = props;
  const { servicesAnswers } = props;
  const { setServicesAnswers } = props;
  const { error } = props;
  const { errorLabel } = props;

  const items = servicesAnswers.map((_, index) => {
    return getItem(index, setServicesAnswers, servicesAnswers);
  });

  return (
    <div className={`${id} mb-4`}>
      {items}
      <div className={'row mt-4'}>
        <div className={'col-7'}>
          {error && errorLabel && (
            <div className={'services-answers-input-error'}>{errorLabel}</div>
          )}
        </div>
        <div className={'col-5'}>
          <QuickfiscoButton
            id={'invoice-customer-save-add-product-button'}
            label={lang.AddButton}
            onClick={() =>
              setServicesAnswers([
                ...servicesAnswers,
                {
                  consumptionType: undefined,
                  totalAnnualAmount: undefined,
                },
              ])
            }
            type={'primary'}
          />
        </div>
      </div>
    </div>
  );
}

function getItem(
  index: number,
  setServicesAnswers: (servicesAnswers: ServiceInvoicesTaxReturnModel[]) => void,
  servicesAnswers: ServiceInvoicesTaxReturnModel[]
): React.ReactNode {
  return (
    <div
      className={`row no-gutters ${index === 0 ? 'mt-3' : ''}`}
      key={'item-' + index}
    >
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <span className="services-answers-input-input-title">
              {index + 1}
              {lang.serviceSubTitle}
            </span>
          </div>
        </div>
        <div className="row">
          <div className={'col-5'}>
            <QuickfiscoConsumptionTypeSelect
              id={'services-answers-deduction-child'}
              value={servicesAnswers[index].consumptionType}
              onChange={(_consumptionType) => {
                if (_consumptionType) {
                  const newServicesAnswer = [...servicesAnswers];
                  newServicesAnswer[index] = {
                    ...servicesAnswers[index],
                    consumptionType: _consumptionType,
                  };
                  setServicesAnswers([...newServicesAnswer]);
                }
              }}
              requiredField={true}
            />
          </div>
          <div className={'col-6'}>
            <NumericFormat
              id={'input-services-answers-total-annual-amount'}
              key={'services-answers-total-annual-amount'}
              suffix="€"
              value={servicesAnswers[index].totalAnnualAmount}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              customInput={QuickfiscoInput}
              onValueChange={(e) => {
                const newServicesAnswer = [...servicesAnswers];
                newServicesAnswer[index] = {
                  ...servicesAnswers[index],
                  totalAnnualAmount: e.floatValue,
                };
                setServicesAnswers([...newServicesAnswer]);
              }}
              styleType={'default'}
              label={lang.totalAnnualAmountLabel}
              placeholder={lang.totalAnnualAmountPlaceholder}
              fixedDecimalScale
              required={true}
            />
          </div>
          <div
            className={'col-1 d-flex align-items-center justify-content-end'}
          >
            <QuickfiscoIcon
              name={'delete-icon.svg'}
              isButton={true}
              onClick={() => {
                const array = servicesAnswers;
                const newGoodService = array
                  .slice(0, index)
                  .concat(array.slice(index + 1));
                setServicesAnswers([...newGoodService]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
