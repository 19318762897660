import { ChangeEvent, useState } from 'react';
import { PasswordChanged } from '../../components/passwordChanged/passwordChanged';
import { useParams } from 'react-router-dom';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../../components/quickfiscoError/quickfiscoError';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../../components/quickfiscoInput/quickfiscoInput';
import {
  LogoDim,
  QuickfiscoLogo,
} from '../../components/quickfiscoLogo/quickfiscoLogo';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import './resetPassword.css';
import lang from './resetPassword.json';

export function ResetPassword() {
  const params = useParams();
  const userId = params.userId;
  const token = params.token;

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [openModalResetPassword, setOpenModalResetPassword] = useState(false);

  if (userId === undefined || token === undefined) {
    return null;
  }

  return (
    <>
      <ViewportWarning />
      <QuickfiscoError
        active={status === 'failed'}
        message={lang.resetError}
        close={() => setStatus('idle')}
      />
      <div className="row mr-0 full-screen">
        <div className="d-none d-lg-flex col-6 login-left-side">
          <div>
            <h2>{lang.Start}</h2>
            <QuickfiscoIcon name={'running.png'} />
          </div>
        </div>
        <div className="col-12 col-lg-6 p-5 login-right-side">
          <div className="login-container mb-3">
            <QuickfiscoLogo id="login-logo" dim={LogoDim.MEDIUM} />
            <h2 className={'login-title mt-5 mb-4'}>{lang.PasswordRecovery}</h2>
            <QuickfiscoInput
              id="reset-new-password"
              label={lang.PasswordLabel}
              type="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNewPassword(event.target.value)
              }
              error={errorPassword}
              errorLabel={lang.ErrorPassword}
              placeholder={lang.PasswordPlaceholder}
              required={true}
              styleType={'default'}
              value={newPassword}
            />
            <QuickfiscoInput
              id="reset-confirm-new-password"
              label={lang.ConfirmPasswordLabel}
              type="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setConfirmNewPassword(event.target.value)
              }
              error={errorConfirmPassword}
              errorLabel={lang.ErrorConfirmPassword}
              styleType={'default'}
              placeholder={lang.ConfirmPasswordPlaceholder}
              required={true}
              value={confirmNewPassword}
            />
            <div className="row mt-4 d-flex justify-content-start align-items-center">
              <div className="col-5">
                <QuickfiscoButton
                  id="reset-password-btn"
                  label={lang.ButtonLabel}
                  type={'primary'}
                  onClick={() =>
                    resetPassword(
                      userId,
                      token,
                      newPassword,
                      confirmNewPassword,
                      setErrorPassword,
                      setErrorConfirmPassword,
                      setStatus,
                      setOpenModalResetPassword
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PasswordChanged
        open={openModalResetPassword}
        setOpen={setOpenModalResetPassword}
      />
    </>
  );
}

function resetPassword(
  userId: string,
  token: string,
  newPassword: string,
  confirmNewPassword: string,
  setErrorPassword: SetState<boolean>,
  setErrorConfirmPassword: SetState<boolean>,
  setStatus: SetState<PromiseStatuses>,
  setModalOpen: SetState<boolean>
): void {
  const service = new UserService();

  setErrorPassword(false);
  setErrorConfirmPassword(false);

  if (!service.validatePassword(newPassword)) {
    setErrorPassword(true);
    return;
  }

  if (!service.validateConfirmPassword(newPassword, confirmNewPassword)) {
    setErrorConfirmPassword(true);
    return;
  }

  setStatus('loading');
  service
    .resetPassword(userId, token, newPassword)
    .then(() => {
      setModalOpen(true);
    })
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}
