import { useAppSelector } from '../../redux/hooks';
import config from '../../../config.json';
import { host } from '../../utils/config';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxInfoDeadline } from '../taxInfoDeadline/taxInfoDeadline';
import './taxChart.css';
import lang from './taxChart.json';

export function TaxChart() {
  const taxProfileState = useAppSelector(state => state.taxProfile);
  const taxCalculationState = useAppSelector(state => state.taxCalculation);

  if (
    taxCalculationState.status === 'loading' ||
    taxProfileState.status === 'loading'
  ) {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (taxProfileState.status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    )
  }

  const profile = taxProfileState.profile;
  const forecast = taxCalculationState.forecast;

  if (
    taxCalculationState.status === 'failed' ||
    (profile.lastUpdate > taxCalculationState.forecast.lastUpdate && (taxCalculationState.forecast.year === new Date().getFullYear()
      || taxCalculationState.forecast.year === (new Date().getFullYear() + 1)))
  ) {
    return (
      <div className={'tax-forecast-deadlines-container'} style={{ height: "500px" }}>
        <div className={'row'}>
          <div className={'col-12'}>
            <span className={'tax-forecast-deadlines-title tax-forecast-deadlines-loading-title'}>
              {lang.title}
            </span>
          </div>
        </div>
        <div className={'row mt-4 d-flex align-items-center'}>
          <div className={'col-6'}>
            <div
              style={{
                backgroundImage: 'url("' + host + '/images/circle.gif")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                height: '415px'
              }}
            />
          </div>
          <div className={'col-6'}>
            <div className={'row'}>
              <div className={'col-12 d-flex justify-content-center align-items-center flex-column'}>
                <div className={'row'}>
                  <div className={'col-12 d-flex justify-content-center'}>
                    <QuickfiscoIcon name={'data-box.gif'} className={'w-75'} />
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-12 d-flex justify-content-center'}>
                    <QuickfiscoIcon name={'data-box.gif'} className={'w-75'} />
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-12 d-flex justify-content-center'}>
                    <QuickfiscoIcon name={'data-box.gif'} className={'w-75'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const data = [
    {
      name: 'Contributi versati',
      value: forecast.contribution > 0 ? forecast.contribution : 0,
      text: "black"
    },
    {
      name: 'Netto',
      value: forecast.balance > 0 ? forecast.balance : 0,
      text: "white"
    },
    {
      name: 'Tasse versate',
      value: forecast.taxes > 0 ? forecast.taxes : 0,
      text: "white"
    },
  ];

  const COLORS = ['#EBEAEA', '#4643D3', '#626272'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (renderType: {
    cx: number,
    cy: number,
    midAngle: number,
    innerRadius: number,
    outerRadius: number,
    percent: number,
    index: number,
    name: string,
    text: string
  }) => {
    const radius = renderType.innerRadius + (renderType.outerRadius - renderType.innerRadius) * 0.5;
    const x = renderType.cx + radius * Math.cos(-renderType.midAngle * RADIAN);
    const y = renderType.cy + radius * Math.sin(-renderType.midAngle * RADIAN);

    return (
      <text x={x} y={y} fill={renderType.text} textAnchor={'middle'} dominantBaseline="middle" fontSize={"12px"} fontFamily={"Poppins"}>
        <tspan x={x} textAnchor='middle' dominantBaseline="middle">{renderType.name}</tspan>
        <tspan x={x} dy={"1.2em"} textAnchor='middle' dominantBaseline="middle">{(renderType.percent * 100).toFixed(2) + '%'}</tspan>
      </text>
    );
  };

  return (
    <div className={'tax-forecast-deadlines-container'} style={{ height: "500px", width: "154%" }}>
      <div className={'row'}>
        <div className={'col-12'}>
          <span className={'tax-forecast-deadlines-title tax-forecast-deadlines-title'}>
            {lang.title}
          </span>
        </div>
      </div>
      <div className='row' style={{ height: "90%", width: "100%" }}>
        {/* <div className='col-8 no-gutters'>
          <div style={{ height: "100%", width: "100%" }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  fill="#9DADFC"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  data={data}
                  dataKey="value"
                >
                  {
                    data.map((_entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} strokeWidth={"2px"} />
                    ))
                  }
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div> */}
        <div className='col-4 d-flex flex-column justify-content-evenly'>
          <div className='row'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <span className='tax-chart-label'>
                    {lang.cashed}
                  </span>
                </div>
              </div>
              <div className='row'>
                <TaxInfoDeadline taxOrContribution={forecast.revenues} className='tax-chart-value d-flex flex-row ' />
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <span className='tax-chart-label'>
                    {lang.contribution}
                  </span>
                </div>
              </div>
              <div className='row'>
                <TaxInfoDeadline taxOrContribution={forecast.contribution} className='tax-chart-value d-flex flex-row ' />
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12'>
                  <span className='tax-chart-label'>
                    {lang.taxes}
                  </span>
                </div>
              </div>
              <div className='row'>
                <TaxInfoDeadline taxOrContribution={forecast.taxes} className='tax-chart-value d-flex flex-row ' />
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-auto'>
              <div className='tax-chart-revenues-container' style={{ width: "250%" }}>
                <div className='row'>
                  <div className='col-12'>
                    <span className='tax-chart-revenues-label'>
                      {lang.revenues}
                    </span>
                  </div>
                </div>
                <div className='col-12 list-crop'>
                  <span className='tax-chart-revenues-value'>
                    {formatNumberIT(forecast.balance) + config.EUR}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
