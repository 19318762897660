import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setFeesRegisterYearFilter } from "../../redux/slices/feesRegisterSlice";
import { FeesRegisterMonthFilterButton } from "../feesRegisterMonthFilterButton/feesRegisterMonthFilterButton";
import { QuickfiscoYearSelect } from "../quickfiscoYearSelect/quickfiscoYearSelect";

export function FeesRegisterFilters() {
    const feesRegisterState = useAppSelector(state => state.feesRegister);

    const monthFilter = feesRegisterState.monthFilter

    const dispatch = useAppDispatch()

    return (
        <div className="row align-items-center">
            <div className="col-10">
                <div className="row">
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Gen."
                            selected={monthFilter === '1'}
                            month={'1'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Feb."
                            selected={monthFilter === '2'}
                            month={'2'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Mar."
                            selected={monthFilter === '3'}
                            month={'3'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Apr."
                            selected={monthFilter === '4'}
                            month={'4'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Mag."
                            selected={monthFilter === '5'}
                            month={'5'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Giu."
                            selected={monthFilter === '6'}
                            month={'6'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Lug."
                            selected={monthFilter === '7'}
                            month={'7'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Ago."
                            selected={monthFilter === '8'}
                            month={'8'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Sett."
                            selected={monthFilter === '9'}
                            month={'9'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Ott."
                            selected={monthFilter === '10'}
                            month={'10'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Nov."
                            selected={monthFilter === '11'}
                            month={'11'}
                        />
                    </div>
                    <div className="col pe-0 list-crop">
                        <FeesRegisterMonthFilterButton
                            monthLabel="Dic."
                            selected={monthFilter === '12'}
                            month={'12'}
                        />
                    </div>
                </div>
            </div>
            <div className="col-2">
                <div className="row align-items-center justify-content-end">
                    <div className={'col-5 d-flex justify-content-end'}>
                        <span className={'fees-register-filters-label'}>
                            {'Anno'}
                        </span>
                    </div>
                    <div className={'col-7'}>
                        <QuickfiscoYearSelect
                            id="fees-register-year-select"
                            value={feesRegisterState.yearFilter}
                            onChange={year => dispatch(setFeesRegisterYearFilter(year))}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
