import { getMonth } from 'date-fns';
import config from '../../../config.json';
import { TaxDeadlineModel, TaxDeadlineStatus } from '../../models/taxDeadlineModel';
import { formatDateIT, formatDateUS } from '../../utils/date';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { TaxInfoDeadline } from '../taxInfoDeadline/taxInfoDeadline';
import './taxDeadlineINPS.css';
import lang from './taxDeadlineINPS.json';

interface Props {
  lastItem: boolean;
  deadline: TaxDeadlineModel;
}

export function TaxDeadlineINPS(props: Props) {
  const { lastItem } = props;
  const { deadline } = props;

  const expirationDate = new Date(deadline.expirationDate);
  const expirationDateMonth = expirationDate.getMonth();

  let totalAmount = 0;
  if (deadline.contributionAmount) {
    totalAmount = deadline.contributionAmount;
  }
  if (deadline.taxAmount) {
    totalAmount += deadline.taxAmount;
  }

  return (
    <div className={'tax-forecast-deadline-container'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <span className={'tax-forecast-deadline-text fw-normal'}>
            {lang.expiration}
          </span>
        </div>
      </div>
      <div className={'row mt-1'}>
        <div className={'col-12'}>
          <span className={'tax-forecast-deadline-date'}>
            {deadline.expirationDate && formatDateIT(deadline.expirationDate)}
          </span>
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-12'}>
          <TaxInfoDeadline taxOrContribution={totalAmount} className='tax-forecast-deadline-amount fw-normal d-flex flex-row ' />
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-12 d-flex justify-content-center align-items-center'}>
          <div className={'tax-forecast-deadline-timeline-circle'} />
          <div
            className={lastItem ? 'tax-forecast-deadline-timeline-last-line' : 'tax-forecast-deadline-timeline-line'} />
        </div>
      </div>
      {
        deadline.status === TaxDeadlineStatus.VALID ? (
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <span className={'tax-forecast-deadline-text-valid'}>
                <QuickfiscoIcon name='check-circle.svg' className='h-50 me-2' />
                {lang.valid}
              </span>
            </div>
          </div>
        ) : (
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <span className={'tax-forecast-deadline-text-not-valid'}>
                <QuickfiscoIcon name='pause-circle.svg' className='h-50 me-2' />
                {lang.notValid}
              </span>
            </div>
          </div>
        )
      }
      <div className={'row mt-3'}>
        <div className={'col-8 d-flex justify-content-between'}>
          <span className={'tax-forecast-deadline-text'}>
            {lang.contributions}
          </span>
          <span className={'tax-forecast-deadline-text'}>
            {formatNumberIT(deadline.contributionAmount)}{config.EUR}
          </span>
        </div>
      </div>
      {
        deadline.contributionBalance !== undefined && deadline.contributionBalance !== 0 && (expirationDateMonth !== 10) && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {lang.balance}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.contributionBalance)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      <div className={'row'}>
        <div className={'col-8 d-flex justify-content-between'}>
          <span className={'tax-forecast-deadline-text fw-normal'}>
            {lang.deposit + (getMonth(new Date(formatDateUS(deadline.expirationDate))) === 5 ? ' 1' : ' 2')}
          </span>
          <span className={'tax-forecast-deadline-text fw-normal'}>
            {formatNumberIT(deadline.contributionDeposit)}{config.EUR}
          </span>
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-8 d-flex justify-content-between'}>
          <span className={'tax-forecast-deadline-text'}>
            {lang.tax}
          </span>
          <span className={'tax-forecast-deadline-text'}>
            {formatNumberIT(deadline.taxAmount)}{config.EUR}
          </span>
        </div>
      </div>
      {
        deadline.taxBalance !== undefined && deadline.taxBalance !== 0 && (expirationDateMonth !== 10) &&(
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {lang.balance}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.taxBalance)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      <div className={'row'}>
        <div className={'col-8 d-flex justify-content-between'}>
          <span className={'tax-forecast-deadline-text fw-normal'}>
            {lang.deposit + (getMonth(new Date(formatDateUS(deadline.expirationDate))) === 5 ? ' 1' : ' 2')}
          </span>
          <span className={'tax-forecast-deadline-text fw-normal'}>
            {formatNumberIT(deadline.taxDeposit)}{config.EUR}
          </span>
        </div>
      </div>
    </div>
  );
}
