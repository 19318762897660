import { NumericFormat } from 'react-number-format';
import { CategoryType } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  editTaxProfile,
  getTaxProfile, setFundYearRegistration, setLastTaxContributionRate, setLastYearContributionBalance,
  setLastYearContributionDeposit,
  setLastYearRevenues,
  setLastYearTaxDeposit,
  setMaternityContribution,
  setMinSupplementaryContribution,
  setMinTaxContribution,
  setOtherCurrentYearRevenues,
  setTaxContributionRate,
} from '../../redux/slices/taxProfileSlice';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import { FundSelect } from '../fundSelect/fundSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './taxEditProfile.css';
import lang from './taxEditProfile.json';
import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';

interface Props {
  open: boolean,
  hide: Function,
}

export function TaxEditProfile(props: Props) {
  const { hide } = props;
  const { open } = props;

  const userState = useAppSelector(state => state.user);
  const taxProfileState = useAppSelector(state => state.taxProfile);
  const taxCalculationState = useAppSelector(state => state.taxCalculation);

  const componentVisible = useComponentVisible(false);

  const dispatch = useAppDispatch();

  if (userState.status === 'loading' || taxProfileState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;
  const taxProfile = taxProfileState.profile;

  return (
    <div>
      <QuickfiscoModal hide={hide} isOpen={open}>
        <div className={'p-4 pt-0'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'edit-tax-profile-resume-title'}>
                {lang.title1}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <QuickfiscoDatePicker
                id='tax-edit-profile-vat-input'
                label={lang.vat}
                value={user.vatOpeningDate ? new Date(user.vatOpeningDate) : undefined}
                disabled={true}
                styleType={'default'}
              />
            </div>
          </div>
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <AtecoSelect
                id='tax-edit-profile-ateco'
                type={'all'}
                value={user.atecos && user.atecos[0]}
                disabled={true}
              />
            </div>
          </div>
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <QuickfiscoInput
                id='tax-edit-profile-taxation-input'
                type={'text'}
                value={String(user.taxation) + '%'}
                disabled={true}
                label={lang.taxation}
                styleType={'default'}
              />
            </div>
          </div>
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <FundSelect
                id='tax-edit-profile-fund'
                value={user.fund}
                disabled={true}
                visibleNumber={true}
              />
            </div>
          </div>
          {
            (user.category === CategoryType.ARTISAN ||
              user.category === CategoryType.TRADER) &&
            (
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <QuickfiscoInput
                    styleType={'default'}
                    id='tax-edit-profile-contribution-reduction-input'
                    key={'input-tax-profile-contribution-reduction'}
                    type={'text'}
                    label={lang.contributionReduction}
                    defaultValue={user.contributionReduction ? 'SI' : "NO"}
                    disabled={true}
                  />
                </div>
                <div className={'col-12'}>
                  <QuickfiscoInput
                    styleType={'default'}
                    id='tax-edit-profile-contribution-reduction-input'
                    key={'input-tax-profile-contribution-reduction'}
                    type={'text'}
                    label={lang.ContributionExemption}
                    defaultValue={user.contributionExemption ? 'SI' : "NO"}
                    disabled={true}
                  />
                </div>
              </div>
            )
          }
          <div className={'row mt-3'}>
            <div className={'col-6'}>
              <span className={'edit-tax-profile-resume-title'}>
                {lang.title2}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12 position-relative'}>
              <NumericFormat
                id='tax-edit-profile-last-year-revenues-input'
                key={'input-edit-tax-profile-last-year-revenues'}
                suffix='€'
                defaultValue={taxProfile.lastYearRevenues}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearRevenues(e.floatValue))}
                customInput={QuickfiscoInput}
                styleType={'default'}
                label={lang.otherYearRevenues}
                placeholder={lang.placeholder + "€"}
                fixedDecimalScale
              />
            </div>
            {user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01") ?
              <div className={'col-6 offset-4 position-absolute mt-1 '}>
                <div
                  onMouseOver={() => componentVisible.setIsVisible(true)}
                  onMouseOut={() => componentVisible.setIsVisible(false)}
                >
                  <QuickfiscoIcon name={'info.svg'} className={'d-flex'} />
                </div>
                <div
                  ref={componentVisible.ref}
                >
                  {componentVisible.isVisible && (
                    <div className={'edit-tax-profile-popup-container'}>
                      {"Fatturato incassato anno precedente al netto del contributo integrativo"}
                    </div>
                  )}
                </div>
              </div> : <div></div>}
          </div>

          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <NumericFormat
                id='tax-edit-profile-current-year-balance-input'
                key={'input-tax-profile-current-year-balance'}
                suffix='€'
                defaultValue={taxProfile.otherCurrentYearRevenues}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                onValueChange={e => e.floatValue !== undefined && dispatch(setOtherCurrentYearRevenues(e.floatValue))}
                customInput={QuickfiscoInput}
                styleType={'default'}
                label={lang.currentYearRevenues}
                placeholder={lang.placeholder + "€"}
                fixedDecimalScale
              />
            </div>
          </div>
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <NumericFormat
                id='tax-edit-profile-last-year-balance-input'
                key={'input-edit-tax-profile-last-year-balance'}
                suffix='€'
                defaultValue={taxProfile.lastYearContributionBalance}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearContributionBalance(e.floatValue))}
                customInput={QuickfiscoInput}
                styleType={'default'}
                label={
                  (user.category !== CategoryType.FUND && (user.fund !== "TC04" && user.fund !== "TC21" && user.fund !== "TC01")) ?
                    lang.contributionBalance : lang.contributionBalanceWithFund
                }
                placeholder={lang.placeholder + "€"}
                fixedDecimalScale
              />
            </div>
          </div>
          {
            (user.category !== CategoryType.FUND && (user.fund !== "TC04" && user.fund !== "TC21" && user.fund !== "TC01")) &&
            (
              <div className={'row mt-2'}>
                <div className={'col-12'}>
                  <NumericFormat
                    id='tax-edit-profile-last-year-contribution-input'
                    key={'input-edit-tax-profile-last-year-contribution-deposit'}
                    suffix='€'
                    defaultValue={taxProfile.lastYearContributionDeposit}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearContributionDeposit(e.floatValue))}
                    customInput={QuickfiscoInput}
                    styleType={'default'}
                    label={lang.contributionDeposit}
                    placeholder={lang.placeholder + "€"}
                    fixedDecimalScale
                  />
                </div>
              </div>
            )
          }
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <NumericFormat
                id='tax-edit-profile-last-year-tax-deposit-input'
                key={'input-edit-tax-profile-last-year-tax-deposit'}
                suffix='€'
                defaultValue={taxProfile.lastYearTaxDeposit}
                decimalSeparator=","
                decimalScale={2}
                thousandSeparator="."
                onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearTaxDeposit(e.floatValue))}
                customInput={QuickfiscoInput}
                styleType={'default'}
                label={lang.taxationDeposit}
                placeholder={lang.placeholder + "€"}
                fixedDecimalScale
              />
            </div>
          </div>
          {
            (user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01")) &&
            (
              <div>
                <div className={'row mt-3'}>
                  <div className={'col-12'}>
                    <span className={'edit-tax-profile-resume-title'}>
                      {lang.title3}
                    </span>
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-12'}>
                    <NumericFormat
                      id='tax-edit-profile-fund-year-registration-input'
                      key={'input-edit-tax-profile-fund-year-registration'}
                      defaultValue={taxProfile.fundYearRegistration}
                      onValueChange={e => e.floatValue !== undefined && dispatch(setFundYearRegistration(e.floatValue))}
                      customInput={QuickfiscoInput}
                      styleType={'default'}
                      label={lang.fundYearRegistration}
                      placeholder={"0"}
                    />
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-12'}>
                    <NumericFormat
                      key={'input-edit-tax-profile-tax-contribution-rate'}
                      suffix='%'
                      defaultValue={taxCalculationState.year === (new Date().getFullYear() + 1).toString() ? taxProfile.lastTaxContributionRate * 100 : taxProfile.taxContributionRate * 100}
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={e => e.floatValue !== undefined && (taxCalculationState.year === (new Date().getFullYear() + 1).toString() ? dispatch(setLastTaxContributionRate(e.floatValue / 100)) : dispatch(setTaxContributionRate(e.floatValue / 100)))}
                      customInput={QuickfiscoInput}
                      thousandSeparator="."
                      styleType={'default'}
                      id='tax-edit-profile-tax-contribution-rate-input'
                      label={lang.taxContributionRate}
                      placeholder={"0%"}
                    />
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-12'}>
                    <NumericFormat
                      id='tax-edit-profile-min-tax-contribution-input'
                      key={'input-edit-tax-profile-min-tax-contribution'}
                      suffix='€'
                      defaultValue={taxProfile.minTaxContribution}
                      decimalSeparator=","
                      decimalScale={2}
                      thousandSeparator="."
                      onValueChange={e => e.floatValue !== undefined && dispatch(setMinTaxContribution(e.floatValue))}
                      customInput={QuickfiscoInput}
                      styleType={'default'}
                      label={lang.minTaxContribution}
                      placeholder={lang.placeholder + "€"}
                      fixedDecimalScale
                    />
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-12'}>
                    <NumericFormat
                      id='tax-edit-profile-maternity-contribution-input'
                      key={'input-edit-tax-profile-maternity-contribution'}
                      suffix='€'
                      defaultValue={taxProfile.maternityContribution}
                      decimalSeparator=","
                      decimalScale={2}
                      thousandSeparator="."
                      onValueChange={e => e.floatValue !== undefined && dispatch(setMaternityContribution(e.floatValue))}
                      customInput={QuickfiscoInput}
                      styleType={'default'}
                      label={lang.maternityContribution}
                      placeholder={lang.placeholder + "€"}
                      fixedDecimalScale
                    />
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-12'}>
                    <NumericFormat
                      id='tax-edit-profile-supplementary-contribution-rate-input'
                      key={'input-edit-tax-profile-supplementary-contribution-rate'}
                      suffix='%'
                      defaultValue={user.recourseValue}
                      decimalSeparator=","
                      decimalScale={2}
                      thousandSeparator="."
                      customInput={QuickfiscoInput}
                      styleType={'default'}
                      label={lang.supplementaryContributionRate}
                      placeholder={lang.placeholder + "%"}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-12'}>
                    <NumericFormat
                      id='tax-edit-profile-min-supplementary-contribution-input'
                      key={'input-edit-tax-profile-min-supplementary-contribution'}
                      suffix='€'
                      defaultValue={taxProfile.minSupplementaryContribution}
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={e => e.floatValue !== undefined && dispatch(setMinSupplementaryContribution(e.floatValue))}
                      customInput={QuickfiscoInput}
                      styleType={'default'}
                      thousandSeparator="."
                      label={lang.minSupplementaryContribution}
                      placeholder={lang.placeholder + "€"}
                      fixedDecimalScale
                    />
                  </div>
                </div>
              </div>
            )
          }
          <div className={'row mt-5'}>
            <div className={'col-4 offset-4'}>
              {
                taxProfileState.editStatus === 'loading' ? (
                  <QuickfiscoSpinner />
                ) : (
                  <QuickfiscoButton
                    type={'primary'}
                    id={'button-edit-tax-profile-update'}
                    label={lang.buttonLabel}
                    onClick={
                      () => dispatch(editTaxProfile(taxProfile))
                        .then(() => {
                          hide();
                          dispatch(getTaxProfile())
                        })
                    }
                  />
                )
              }
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}