import { useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import './quickfiscoInputLogo.css';
import lang from './quickfiscoInputLogo.json';

interface Props {
  onChange: (logo: File | null) => void;
  error?: boolean;
  errorLabel?: string;
  deleteLogo: () => void;
}

export function QuickfiscoInputLogo(props: Props) {
  const { onChange } = props;
  const { deleteLogo } = props;
  const { error } = props;
  const { errorLabel } = props;

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  return (
    <div className={'row'}>
      <div>
        <QuickfiscoButton
          type="primary"
          label={lang.label}
          id={'select-document'}
          onClick={() => inputRef?.click()}
        />
        {error && errorLabel && (
          <span className={'quickfisco-input-logo-error'}>{errorLabel}</span>
        )}
      </div>
      <div className={'quickfisco-input-logo-warn mt-2'}>{lang.warn}</div>
      <input
        type="file"
        className={'d-none'}
        accept={'.jpg, .jpeg, .png'}
        ref={(ref) => setInputRef(ref)}
        onChange={(e) => onChange(e.target.files && e.target.files.item(0))}
      />
      <div
        className="quickfisco-input-logo-remove mt-3"
        onClick={() => deleteLogo()}
      >
        {lang.remove}
      </div>
    </div>
  );
}
