import { useEffect } from 'react';
import { Appearance } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutStripeForm } from '../checkoutStripeForm/checkoutStripeForm';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { PaymentResponseStatus } from '../paymentResponseStatus/paymentResponseStatus';
import getStripe from '../../utils/stripe';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateStripePaymentIntent } from '../../redux/slices/paymentSlice';

interface Props {
  onPayClick: Function;
  getCheckout: Function;
  checkoutId?: string;
}

export function Stripe(props: Props) {
  const { onPayClick } = props;
  const { getCheckout } = props;
  const { checkoutId } = props;

  const paymentState = useAppSelector((state) => state.payment);
  const invoiceState = useAppSelector((state) => state.payment.invoice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checkoutId) {
      dispatch(updateStripePaymentIntent(checkoutId));
    }
  }, []);

  const appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      fontFamily: '"Poppins", "Segoe UI", sans-serif',
      fontSizeBase: '15px',
      borderRadius: '10px',
      colorText: '#000000',
      colorPrimary: '#9DADFC',
      colorTextSecondary: '#000000',
      colorTextPlaceholder: '#626272',
      colorDanger: '#FD5D69',
      spacingUnit: '5px',
    },
    rules: {
      '.Input': {
        border: '1px solid #D3D3E2',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '14px',
      },
    },
  };

  const options = {
    clientSecret: invoiceState.clientSecret,
    appearance,
  };

  if (paymentState.statusUpdateStripePaymentIntent === 'loading') {
    return (
      <div className="mt-5 pt-5 d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (paymentState.statusUpdateStripePaymentIntent === 'failed') {
    return (
      <div className="row">
        <div className="col-12 mt-5">
          <PaymentResponseStatus
            paymentStatus={paymentState.statusUpdateStripePaymentIntent}
            onClick={() => getCheckout()}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-12">
        {invoiceState.clientSecret && (
          <Elements options={options} stripe={getStripe()}>
            <CheckoutStripeForm onPayClick={onPayClick} />
          </Elements>
        )}
      </div>
    </div>
  );
}
