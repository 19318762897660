import { TaxDeadlineModel } from "../../models/taxDeadlineModel";
import { CategoryType } from "../../models/userModel";
import { useAppSelector } from "../../redux/hooks";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { TaxEditDeadlineArtisanAndTrader } from "../taxEditDeadlineArtisanAndTrader/taxEditDeadlineArtisanAndTraders";
import { TaxEditDeadlineEngineer } from "../taxEditDeadlineEngineer/taxEditDeadlineEngineer";
import { TaxEditDeadlineINPS } from "../taxEditDeadlineINPS/taxEditDeadlineINPS";
import { TaxEditDeadlineLawyer } from "../taxEditDeadlineLawyer/taxEditDeadlineLawyer";
import { TaxEditDeadlinePsychologists } from "../taxEditDeadlinePsychologists/taxEditDeadlinePsychologists";
interface Props {
    index: number
    deadline: TaxDeadlineModel
}

export function TaxEditDeadlineFactory(props: Props) {
    const { index } = props;
    const { deadline } = props;

    const userState = useAppSelector(state => state.user);

    if (userState.status === 'loading') {
        return (
            <div className="full-screen d-flex justify-content-center align-items-center">
                <QuickfiscoSpinner />
            </div>
        );
    }

    if (userState.status === 'failed' || !userState.user) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                Errore durante il caricamento dei dati
            </div>
        );
    }

    const user = userState.user;

    if (user.category === CategoryType.NOT_FUND) {
        return (
            <TaxEditDeadlineINPS
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.ARTISAN || user.category === CategoryType.TRADER) {
        return (
            <TaxEditDeadlineArtisanAndTrader
                index={index}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.FUND && user.fund === "TC21") {
        return (
            <TaxEditDeadlinePsychologists
                index={index}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.FUND && user.fund === "TC01") {
        return (
            <TaxEditDeadlineLawyer
                index={index}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.FUND && user.fund === "TC04") {
        return (
            <TaxEditDeadlineEngineer
                index={index}
                deadline={deadline}
            />
        )
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            Categoria non trovata
        </div>
    );
}
