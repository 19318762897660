import { useState } from 'react';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch } from '../../redux/hooks';
import {
  setProformaInvoiceReimbursementOfExpensesAmount,
  setProformaInvoiceReimbursementsOfExpenses,
  setStsIsActive,
} from '../../redux/slices/proformaInvoiceSlice';
import { InvoiceSign, Operation } from '../../types/strings';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSwitchInput } from '../quickfiscoSwitchInput/quickfiscoSwitchInput';
import './switchToSts.css';
import lang from './switchToSts.json';

interface Props {
  operation: Operation;
  invoiceSign: InvoiceSign;
}

export function SwitchToSts(props: Props) {
  const { operation } = props;
  const { invoiceSign } = props;

  const [open, setOpen] = useState<boolean>(false);

  const newInvoice = useNewInvoice(invoiceSign);
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={'switch-to-sts-container p-4'}>
        <QuickfiscoSwitchInput
          id={'switch-to-sts'}
          label={lang.label}
          checked={false}
          onChange={() => setOpen(true)}
          disabled={invoiceSign === 'sts' && operation !== 'save'}
        />
      </div>
      <QuickfiscoModal
        modalClassName={'switch-to-sts-modal-component'}
        isOpen={open}
        hide={() => {
          setOpen(false);
        }}
      >
        <div className={'p-5'}>
          <div className={'row text-center'}>
            <div className="col-10 offset-1">
              <div className={'row'}>
                <div className="col-12">
                  <div className="switch-to-sts-modal-title">
                    {lang.modalTitle}
                  </div>
                  <div className="switch-to-sts-modal-text mt-3">
                    <b>
                      {invoiceSign !== 'proforma'
                        ? lang.modalTextBold
                        : lang.modalProformaTextBold}
                    </b>
                    {invoiceSign !== 'proforma' && lang.modalText}
                  </div>
                  <div className="switch-to-sts-modal-question mt-3">
                    {lang.modalConfirmQuestion}
                  </div>
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className="col-6">
                  <QuickfiscoButton
                    id="register-btn"
                    label={lang.modalBack}
                    type={'primary'}
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className="col-6">
                  <QuickfiscoButton
                    id="register-btn"
                    label={lang.modalSwitch}
                    type={'secondary'}
                    onClick={() => {
                      if (invoiceSign === 'proforma') {
                        switchToSts(dispatch);
                      } else {
                        newInvoice();
                      }
                    }}
                  />
                </div>
              </div>
              <div className={'row mt-5'}>
                <div className="col-12">
                  <div className="switch-to-sts-modal-guide-text">
                    {lang.modalGuideText}
                  </div>
                  <div className="switch-to-sts-modal-guide-text-link">
                    <a
                      href={
                        'https://quickfisco.it/tutorial/settare-il-profilo-sts/'
                      }
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      {lang.modalSettingStsProfile}
                    </a>
                  </div>
                  <div className="switch-to-sts-modal-guide-text-link">
                    <a
                      href={
                        'https://quickfisco.it/tutorial/fattura-elettronica-sts/'
                      }
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      {lang.modalSettingStsInvoice}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </>
  );
}

function switchToSts(dispatch: Function) {
  dispatch(setProformaInvoiceReimbursementsOfExpenses([]));
  dispatch(setStsIsActive(true));
  dispatch(setProformaInvoiceReimbursementOfExpensesAmount(0));
}
