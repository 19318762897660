import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  BankAccountTaxReturnModel,
  DocumentsTypeTaxReturnModel,
  ObjectFileTaxReturnModel,
  SectionTaxReturn9,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoFileList } from '../quickfiscoFileList.css/quickfiscoFileList';
import { getExtension, toBase64, validateFile } from '../../utils/file';
import { sanitizeString } from '../../utils/string';
import { useAppSelector } from '../../redux/hooks';
import { UserModel } from '../../models/userModel';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { NumericFormat } from 'react-number-format';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoAccountHolderSelect } from '../quickfiscoAccountHolderSelect/quickfiscoAccountHolderSelect';
import { QuickfiscoBankAccountYearSelect } from '../quickfiscoBankAccountYearSelect/quickfiscoBankAccountYearSelect';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { TaxReturnSection9AcceptToContinue } from '../taxReturnSection9AcceptToContinue/TaxReturnSection9AcceptToContinue';
import { TaxReturnSection9Informations } from '../taxReturnSection9Informations/taxReturnSection9Informations';
import { resolveUndefinedDate, formatDateUS } from '../../utils/date';
import { counterFileRegex } from '../../regex/regex';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './taxReturnSection9.json';
import './taxReturnSection9.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn9) => void;
  onClickPrevStep: (data: SectionTaxReturn9) => void;
  downloadDoc: (docId: string) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
  scrollPosition?: number;
}

export function TaxReturnSection9(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { downloadDoc } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;
  const { scrollPosition } = props;

  const user = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [enabledSurvey, setEnabledSurvey] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [bankAccountQuestion, setBankAccountQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [bankAccountAnswer, setBankAccountAnswer] = useState<
    BankAccountTaxReturnModel | undefined
  >({
    accountHolder: undefined,
    accountOwnershipPerc: undefined,
    bankAccountYear: undefined,
  });
  const [multipleBankAccountQuestion, setMultipleBankAccountQuestion] =
    useState<boolean | undefined>(undefined);
  const [propertiesQuestion, setPropertiesQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [propertiesAnswer, setPropertiesAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [realEstateTaxesQuestion, setRealEstateTaxesQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [realEstateTaxesAnswer, setRealEstateTaxesAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [otherInvestmentsQuestion, setOtherInvestmentsQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [otherInvestmentsAnswer, setOtherInvestmentsAnswer] = useState<
    string | undefined
  >(undefined);
  const [continueQuestion, setContinueQuestion] = useState<boolean | undefined>(
    false
  );

  const [statusProperties, setStatusProperties] =
    useState<PromiseStatuses>('idle');
  const [statusRealEstateTaxes, setStatusRealEstateTaxes] =
    useState<PromiseStatuses>('idle');
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (skypScroll?: boolean): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(9)
      .then((res) => {
        setLoadingStatus('successfully');
        scrollPosition && refreshApiCall(scrollPosition, skypScroll);
        const data = res as SectionTaxReturn9;

        setBankAccountQuestion(data.bankAccountQuestion);
        setBankAccountAnswer(data.bankAccountAnswer);
        setMultipleBankAccountQuestion(data.multipleBankAccountQuestion);
        setPropertiesQuestion(data.propertiesQuestion);
        setPropertiesAnswer(
          data.propertiesAnswer as ObjectFileTaxReturnModel[]
        );
        setRealEstateTaxesQuestion(data.realEstateTaxesQuestion);
        setRealEstateTaxesAnswer(
          data.realEstateTaxesAnswer as ObjectFileTaxReturnModel[]
        );
        setOtherInvestmentsQuestion(data.otherInvestmentsQuestion);
        setOtherInvestmentsAnswer(data.otherInvestmentsAnswer);
        setContinueQuestion(data.continueQuestion);
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    if (bankAccountQuestion === false) {
      setBankAccountAnswer({
        accountHolder: undefined,
        accountOwnershipPerc: undefined,
        bankAccountYear: undefined,
      });
    }
  }, [bankAccountQuestion]);

  useEffect(() => {
    if (
      bankAccountAnswer?.bankAccountYear &&
      bankAccountAnswer.bankAccountYear < new Date().getFullYear() - 1
    ) {
      setBankAccountAnswer({
        ...bankAccountAnswer,
        bankAccountDate: undefined,
      });
    }
  }, [bankAccountAnswer?.bankAccountYear]);

  useEffect(() => {
    if (propertiesQuestion === false) {
      setPropertiesAnswer(undefined);
      setRealEstateTaxesQuestion(undefined);
      setRealEstateTaxesAnswer(undefined);
      setStatusRealEstateTaxes('idle');
    }
  }, [propertiesQuestion]);

  useEffect(() => {
    if (realEstateTaxesQuestion === false) {
      setRealEstateTaxesAnswer(undefined);
    }
  }, [realEstateTaxesQuestion]);

  useEffect(() => {
    if (otherInvestmentsQuestion === false) {
      setOtherInvestmentsAnswer(undefined);
    }
  }, [otherInvestmentsQuestion]);

  useEffect(() => {
    setCheckgoNext(taxReturnService.validateSection9(dataToSend));
  }, [
    bankAccountQuestion,
    bankAccountAnswer,
    multipleBankAccountQuestion,
    propertiesQuestion,
    propertiesAnswer,
    realEstateTaxesQuestion,
    realEstateTaxesAnswer,
    otherInvestmentsQuestion,
    otherInvestmentsAnswer,
    continueQuestion,
  ]);

  const dataToSend: SectionTaxReturn9 = {
    bankAccountQuestion,
    bankAccountAnswer:
      bankAccountQuestion === true ? bankAccountAnswer : undefined,
    multipleBankAccountQuestion,
    propertiesQuestion,
    propertiesAnswer:
      propertiesQuestion === true ? propertiesAnswer : undefined,
    realEstateTaxesQuestion,
    realEstateTaxesAnswer:
      realEstateTaxesQuestion === true ? realEstateTaxesAnswer : undefined,
    otherInvestmentsQuestion,
    otherInvestmentsAnswer:
      otherInvestmentsQuestion === true ? otherInvestmentsAnswer : undefined,
    continueQuestion,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-9-title">{lang.title}</div>
        </div>
        {!enabledSurvey ? (
          <TaxReturnSection9Informations
            setEnabledSurvey={() => {
              setEnabledSurvey(true);
              getSurveyStep(true);
            }}
            refreshApiCall={() => refreshApiCall(0, true)}
          />
        ) : (
          <>
            <div className="row mt-4">
              <div className="col-12">
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-9-bank-account-question"
                      title={lang.bankAccountQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={bankAccountQuestion}
                      onChange={(checked) => setBankAccountQuestion(checked)}
                      required={true}
                    />
                  </div>
                </div>
                {bankAccountQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-6">
                        <QuickfiscoAccountHolderSelect
                          id={'tax-return-section-9-account-holder'}
                          value={bankAccountAnswer?.accountHolder}
                          onChange={(value) =>
                            setBankAccountAnswer({
                              ...bankAccountAnswer,
                              accountHolder: value,
                            })
                          }
                          requiredField={true}
                        />
                      </div>
                      <div className="col-6">
                        <NumericFormat
                          id="tax-return-section-9-account-ownership-perc-input"
                          key={'tax-return-section-9-account-ownership-perc'}
                          suffix="%"
                          defaultValue={bankAccountAnswer?.accountOwnershipPerc}
                          maxLength={4}
                          customInput={QuickfiscoInput}
                          onValueChange={(e) => {
                            setBankAccountAnswer({
                              ...bankAccountAnswer,
                              accountOwnershipPerc: e.floatValue,
                            });
                          }}
                          styleType={'default'}
                          label={lang.accountOwnershipPercLabel}
                          placeholder={lang.accountOwnershipPercPlaceholder}
                          fixedDecimalScale
                          required={true}
                        />
                      </div>
                    </div>
                    <div
                      className={'row no-gutters d-flex align-items-start mt-3'}
                    >
                      <div className="col-6">
                        <QuickfiscoBankAccountYearSelect
                          id={'tax-return-section-9-bank-account-year'}
                          value={bankAccountAnswer?.bankAccountYear}
                          onChange={(value) =>
                            setBankAccountAnswer({
                              ...bankAccountAnswer,
                              bankAccountYear: value,
                            })
                          }
                          requiredField={true}
                        />
                      </div>
                      {bankAccountAnswer?.bankAccountYear &&
                        bankAccountAnswer.bankAccountYear >=
                          new Date().getFullYear() - 1 && (
                          <div className="col-6">
                            <QuickfiscoDatePicker
                              id={'tax-return-section-9-bank-account-date'}
                              label={lang.bankAccountDate}
                              onChange={(value) => {
                                value &&
                                  setBankAccountAnswer({
                                    ...bankAccountAnswer,
                                    bankAccountDate: resolveUndefinedDate(
                                      formatDateUS(value)
                                    ),
                                  });
                              }}
                              value={
                                bankAccountAnswer?.bankAccountDate &&
                                new Date(bankAccountAnswer?.bankAccountDate)
                              }
                              required={true}
                              maxDate={new Date()}
                              styleType={'default'}
                            />
                          </div>
                        )}
                    </div>
                  </>
                )}
                <div className="row no-gutters mt-4">
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-9-multiple-bank-account-question"
                      title={lang.multipleBankAccountQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={multipleBankAccountQuestion}
                      onChange={(checked) =>
                        setMultipleBankAccountQuestion(checked)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {multipleBankAccountQuestion === true && (
                  <div className="row mx-2 d-flex justify-content-center">
                    <div className="col-12 py-3 px-4 d-flex mt-3 align-items-center tax-return-section-9-confirm-container">
                      <QuickfiscoIcon
                        name={'alert-yellow-small.svg'}
                        className="quickfisco-input-cursor-pointer me-3"
                      />
                      <span>
                        {lang.multipleBankAccountMessageNormal}
                        <br />
                        <strong>{lang.multipleBankAccountMessageBold}</strong>
                      </span>
                    </div>
                  </div>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-9-properties-question"
                      title={lang.propertiesQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={propertiesQuestion}
                      onChange={(checked) => {
                        setPropertiesQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusProperties,
                            DocumentsTypeTaxReturnModel.PROPERTIES,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusProperties('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {propertiesQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusProperties}
                          title={lang.propertiesDocTitle}
                          fileList={propertiesAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusProperties,
                              DocumentsTypeTaxReturnModel.PROPERTIES,
                              propertiesQuestion,
                              getSurveyStep,
                              doc,
                              propertiesAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusProperties,
                              docId,
                              index,
                              setPropertiesAnswer,
                              propertiesAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusProperties}
                        />
                      </div>
                    </div>
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-9-real-estate-taxes-question"
                          title={lang.realEstateTaxesQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={realEstateTaxesQuestion}
                          onChange={(checked) => {
                            setRealEstateTaxesQuestion(checked);
                            if (checked === false) {
                              saveQuestion(
                                dataToSend,
                                setStatusRealEstateTaxes,
                                DocumentsTypeTaxReturnModel.REAL_ESTATE_TAXES,
                                false,
                                getSurveyStep
                              );
                            } else {
                              setStatusRealEstateTaxes('idle');
                            }
                          }}
                          required={true}
                        />
                      </div>
                    </div>
                    {realEstateTaxesQuestion === true && (
                      <>
                        <div className={'row no-gutters mt-3'}>
                          <div className="col-12">
                            <QuickfiscoFileList
                              status={statusRealEstateTaxes}
                              title={lang.realEstateTaxesDocTitle}
                              fileList={realEstateTaxesAnswer}
                              addDoc={(doc) =>
                                saveQuestion(
                                  dataToSend,
                                  setStatusRealEstateTaxes,
                                  DocumentsTypeTaxReturnModel.REAL_ESTATE_TAXES,
                                  realEstateTaxesQuestion,
                                  getSurveyStep,
                                  doc,
                                  realEstateTaxesAnswer,
                                  user
                                )
                              }
                              deleteDoc={(docId, index) =>
                                deleteDoc(
                                  setStatusRealEstateTaxes,
                                  docId,
                                  index,
                                  setRealEstateTaxesAnswer,
                                  realEstateTaxesAnswer
                                )
                              }
                              downloadDoc={(docId) => downloadDoc(docId)}
                              error={statusRealEstateTaxes}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className={'row no-gutters'}>
                  <div className="col-12">
                    <hr className="my-5" />
                  </div>
                </div>
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-9-other-investments-question"
                      title={lang.otherInvestmentsQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={otherInvestmentsQuestion}
                      onChange={(checked) =>
                        setOtherInvestmentsQuestion(checked)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {otherInvestmentsQuestion === true && (
                  <div className={'row no-gutters mt-3'}>
                    <div className="col-12">
                      <div className="tax-return-section-9-other-investments">
                        <QuickfiscoTextarea
                          id="tax-return-section-9-other-investments-answer"
                          label={lang.otherInvestmentsAnswer}
                          value={otherInvestmentsAnswer}
                          onChange={(e) =>
                            setOtherInvestmentsAnswer(e.target.value)
                          }
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <TaxReturnSection9AcceptToContinue
                  onChange={(checked) => setContinueQuestion(checked)}
                  isChecked={continueQuestion}
                />
              </div>
            </div>
            <div className="row d-flex mt-5 align-items-center">
              <div className="col-3">
                <QuickfiscoButton
                  id={'tax-return-section-9-go-prev'}
                  label={lang.goPrev}
                  type={'primary'}
                  onClick={() => onClickPrevStep(dataToSend)}
                />
              </div>
              <div className="col-6 text-center">
                <div className="tax-return-section-9-go-title">
                  {lang.goAlertTitle}
                </div>
                <div className="tax-return-section-9-go-description">
                  {lang.goAlertdescription}
                </div>
              </div>
              <div className="col-3">
                {nextButtonStatus === 'loading' ? (
                  <div
                    className={
                      'd-flex justify-content-center align-items-center w-100'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'tax-return-section-9-go-next'}
                    label={lang.goNext}
                    type={checkgoNext ? 'secondary' : 'disabled'}
                    onClick={() => onClickNextStep(dataToSend)}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function saveQuestion(
  dataToSend: SectionTaxReturn9,
  setStatus: SetState<PromiseStatuses>,
  docType: DocumentsTypeTaxReturnModel,
  question: boolean,
  getSurveyStep: Function,
  doc?: File | null,
  answer?: ObjectFileTaxReturnModel[],
  user?: UserModel
): void {
  const keyObjTypeMap = new Map<DocumentsTypeTaxReturnModel, string>([
    [DocumentsTypeTaxReturnModel.PROPERTIES, 'properties'],
    [DocumentsTypeTaxReturnModel.REAL_ESTATE_TAXES, 'realEstateTaxes'],
  ]);

  const questionKey = keyObjTypeMap.get(docType) + 'Question';
  const answerKey = keyObjTypeMap.get(docType) + 'Answer';
  let objToSend = {};
  const counterRegex =
    answer && answer.length > 0
      ? answer[answer.length - 1].name.match(counterFileRegex)
      : undefined;
  const counter = counterRegex ? Number(counterRegex[0].split('.')[0]) + 1 : 1;

  setStatus('loading');

  const service = new TaxReturnService();

  if (question === true) {
    if (!doc || !user || !counter) {
      return;
    }

    if (!validateFile(doc)) {
      setStatus('failed');
      return;
    }

    const fileName = sanitizeString(
      `${docType}_${user.surname}_${user.name}_${counter}`
    ).toLowerCase();

    toBase64(doc)
      .then((res) => {
        const fileToSend = [
          {
            name: fileName + '.' + getExtension(doc),
            extension: getExtension(doc),
            file: res,
          },
        ];

        objToSend = {
          [questionKey]: question,
          [answerKey]: fileToSend,
        };

        service
          .setSurveyStep(9, { ...dataToSend, ...objToSend })
          .then(() => {
            setStatus('successfully');
            getSurveyStep();
          })
          .catch((err) => {
            setStatus('failedUploadApi');
            getSurveyStep();
            console.error(err);
          });
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        console.error(err);
      });
  } else {
    objToSend = {
      [questionKey]: question,
    };
    service
      .setSurveyStep(9, { ...dataToSend, ...objToSend })
      .then(() => {
        setStatus('successfully');
        getSurveyStep();
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        getSurveyStep();
        console.error(err);
      });
  }
}

function deleteDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<ObjectFileTaxReturnModel[] | undefined>,
  answer?: ObjectFileTaxReturnModel[]
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(9, docId)
      .then(() => {
        if (answer) {
          const newAnswer = answer
            .slice(0, index)
            .concat(answer.slice(index + 1));
          setAnswer([...newAnswer]);
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}
