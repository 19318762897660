import { ContactModel } from './contactModel';
import { InvoicePaymentModel } from './invoicePaymentModel';
import { ExpenseType, ExpenseTypeFlag } from './stsProfileModel';

export interface InvoiceModel {
  id?: string;
  ownerId?: string;
  externalID?: string | null;
  date: Date;
  startDate?: Date;
  endDate?: Date;
  type?: InvoiceType;
  number?: string;
  ateco?: string;
  notes?: string | null;
  causal?: string | null;
  currency: CurrencyType;
  creationDate?: Date;
  status: InvoiceStatusType;
  previousStatus?: InvoiceStatusType;
  lastUpdateStatus?: Date;
  customer?: ContactModel;
  supplier?: ContactModel;
  stamp?: boolean | null;
  amount?: number | null;
  taxBase?: number | null;
  goodServices?: GoodServicesModel[] | null;
  socialContribution?: boolean | null;
  socialContributionAmount?: number | null;
  reimbursementsOfExpensesAmount?: number | null;
  reimbursementsOfExpenses?: ReimbursementOfExpenseModel[] | null;
  withholding?: Withholding | null;
  manual?: boolean | null;
  isPaymentDue?: boolean | null;
  error?: string | null;
  lastError?: string | null;
  stsNumber?: string | null;
  trackedPayment?: boolean | null;
  customerDataCommunication?: boolean;
  payments?: InvoicePaymentModel[] | null;
  paymentExpiration?: Date;
  paymentMode?: PaymentModeType;
  paymentTerms?: PaymentTermsType;
  documents?: string[] | null;
  additionalDatasPa?: AdditionalDataPAModel[] | null;
  stsIsActive?: boolean;
  enasarcoSocialContribution?: number;
  fund?: string;
  language?: string | null;
  applyWithholdingTax?: boolean;
  stampId?: string;
  customNumeration?: boolean ;
}

export interface GoodServicesModel {
  key: string;
  description?: string;
  amount: number;
  quantity: number;
  vatNature?: VatNature | null;
  expenseType?: ExpenseType | null;
  expenseTypeFlag?: ExpenseTypeFlag | null;
}

export interface ReimbursementOfExpenseModel {
  key: string;
  description: string;
  amount: number;
  file?: File;
  documentId?: string;
}

export interface Withholding {
  active: boolean;
  amount?: number;
  rate?: number;
  type?: TypeWithholding;
  causal?: WithholdingCausal;
  taxRate?: number;
}

export enum TypeWithholding {
  RT03 = "RT03",
  RT04 = "RT04",
  RT05 = "RT05",
  RT06 = "RT06"
}

export const MapTypeWithholding = new Map<TypeWithholding, string>([
  [TypeWithholding.RT03, 'Contributo INPS'],
  [TypeWithholding.RT04, 'Contributo ENASARCO'],
  [TypeWithholding.RT05, 'Contributo ENPAM'],
  [TypeWithholding.RT06, 'Altro contributo previdenziale'],
]);

export enum WithholdingCausal {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
  K = "K",
  L = "L",
  L1 = "L1",
  M = "M",
  M1 = "M1",
  M2 = "M2",
  N = "N",
  O = "O",
  O1 = "O1",
  P = "P",
  Q = "Q",
  R = "R",
  S = "S",
  T = "T",
  U = "U",
  V = "V",
  V1 = "V1",
  V2 = "V2",
  W = "W",
  X = "X",
  Y = "Y",
  ZO = "ZO"
}

export const MapWithholdingCausal = new Map<WithholdingCausal, string>([
  [WithholdingCausal.A, 'Prestazioni di lavoro autonomo rientranti nell’esercizio di arte o professione abituale.'],
  [WithholdingCausal.B, 'Utilizzazione economica, da parte dell’autore o dell’inventore, di opere dell’ingegno, di brevetti industriali e di processi, relativi a esperienze acquisite in campo industriale, commerciale o scientifico.'],
  [WithholdingCausal.C, 'Utili derivanti da contratti di associazione in partecipazione e da contratti di cointeressenza, quando l’apporto è costituito esclusivamente dalla prestazione di lavoro.'],
  [WithholdingCausal.D, 'Utili spettanti ai soci promotori e ai soci fondatori delle società di capitali.'],
  [WithholdingCausal.E, 'Levata di protesti cambiari da parte dei segretari comunali.'],
  [WithholdingCausal.G, 'Indennità corrisposte per la cessazione di attività sportiva professionale.'],
  [WithholdingCausal.H, 'Indennità corrisposte per la cessazione dei rapporti di agenzia delle persone fisiche e delle società di persone, con esclusione delle somme maturate entro il 31.12.2003, già imputate per competenza.'],
  [WithholdingCausal.I, 'Indennità corrisposte per la cessazione da funzioni notarili.'],
  [WithholdingCausal.J, 'Compensi corrisposti ai raccoglitori occasionali di tartufi non identificati ai fini dell’imposta sul valore'],
  [WithholdingCausal.K, 'Assegni di servizio civile di cui all’art. 16 del D.lgs. n. 40 del 6 marzo 2017'],
  [WithholdingCausal.L, 'Utilizzazione economica, da parte di soggetto diverso dall’autore o dall’inventore, di opere dell’ingegno, di brevetti industriali e di processi, formule e informazioni relative a esperienze acquisite.'],
  [WithholdingCausal.L1, 'Redditi derivanti dall’utilizzazione economica di opere dell’ingegno, di brevetti industriali e di processi, che sono percepiti da soggetti che abbiano acquistato a titolo oneroso i diritti alla loro utilizzazione.'],
  [WithholdingCausal.M, 'Prestazioni di lavoro autonomo non esercitate abitualmente, obblighi di fare, di non fare o permettere.'],
  [WithholdingCausal.M1, 'Redditi derivanti dall’assunzione di obblighi di fare, di non fare o permettere.'],
  [WithholdingCausal.M2, 'Prestazioni di lavoro autonomo non esercitate abitualmente per le quali sussiste l’obbligo di iscrizione alla Gestione Separata ENPAPI.'],
  [WithholdingCausal.N, 'Indennità di trasferta, rimborso forfettario di spese, premi e compensi erogati: .. nell’esercizio diretto di attività sportive dilettantistiche'],
  [WithholdingCausal.O, 'Prestazioni di lavoro autonomo non esercitate abitualmente, obblighi di fare, di non fare o permettere, per le quali non sussiste l’obbligo di iscrizione alla gestione separata (Circ. Inps 104/2001).'],
  [WithholdingCausal.O1, 'Redditi derivanti dall’assunzione di obblighi di fare, di non fare o permettere, per le quali non sussiste l’obbligo di iscrizione alla gestione separata (Circ. INPS n. 104/2001).'],
  [WithholdingCausal.P, 'Compensi corrisposti a soggetti non residenti privi di stabile organizzazione per l’uso o la concessione in uso di attrezzature industriali, commerciali o scientifiche che si trovano nel territorio dello Stato'],
  [WithholdingCausal.Q, 'Provvigioni corrisposte ad agente o rappresentante di commercio monomandatario.'],
  [WithholdingCausal.R, 'Provvigioni corrisposte ad agente o rappresentante di commercio plurimandatario.'],
  [WithholdingCausal.S, 'Provvigioni corrisposte a commissionario.'],
  [WithholdingCausal.T, 'Provvigioni corrisposte a mediatore.'],
  [WithholdingCausal.U, 'Provvigioni corrisposte a procacciatore di affari.'],
  [WithholdingCausal.V, 'Provvigioni corrisposte a incaricato per le vendite a domicilio e provvigioni corrisposte a incaricato per la vendita porta a porta e per la vendita ambulante di giornali quotidiani e periodici (L. 25.02.1987, n. 67).'],
  [WithholdingCausal.V1, 'Redditi derivanti da attività commerciali non esercitate abitualmente (ad esempio, provvigioni corrisposte per prestazioni occasionali ad agente o rappresentante di commercio, mediatore, procacciatore d’affari);'],
  [WithholdingCausal.V2, 'Redditi derivanti dalle prestazioni non esercitate abitualmente rese dagli incaricati alla vendita diretta a domicilio;'],
  [WithholdingCausal.W, 'Corrispettivi erogati nel 2015 per prestazioni relative a contratti d’appalto cui si sono resi applicabili le disposizioni contenute nell’art. 25-ter D.P.R. 600/1973.'],
  [WithholdingCausal.X, 'Canoni corrisposti nel 2004 da società o enti residenti, ovvero da stabili organizzazioni di società estere di cui all’art. 26-quater, c. 1, lett. a) e b) D.P.R. 600/1973.'],
  [WithholdingCausal.Y, 'Canoni corrisposti dal 1.01.2005 al 26.07.2005 da soggetti di cui al punto precedente.'],
  [WithholdingCausal.ZO, 'Titolo diverso dai precedenti.'],
]);

export interface AdditionalDataPAModel {
  date?: Date;
  documentId?: string;
  type?: AdditionalDataPAType;
  cupCode?: string | null;
  cigCode?: string | null;
  agreementJobCode?: string | null;
}

export enum InvoiceType {
  STS = 'STS',
  TD01 = 'TD01',
  TD02 = 'TD02',
  TD03 = 'TD03',
  TD04 = 'TD04', // CREDIT_SLIP
  TD06 = 'TD06',
  TD16 = 'TD16', // Auto
  TD17 = 'TD17', // Auto
  TD18 = 'TD18', // Auto
  TD19 = 'TD19', // Auto
  TD24 = 'TD24',
  TD25 = 'TD25',
  TD26 = 'TD26'
}

export enum InvoiceStatusType {
  DRAFT = 'DRAFT',
  ALREADY_TRANSMITTED = 'ALREADY_TRANSMITTED',
  QUEUED = 'QUEUED',
  QUEUE_ACK = 'QUEUE_ACK',
  QUEUE_NACK = 'QUEUE_NACK', // Customer
  QUEUED_NACK = 'QUEUED_NACK', // STS
  DELIVERED = 'DELIVERED',
  NOT_RECEIVED = 'NOT_RECEIVED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  DUE_TERMS = 'DUE_TERMS',
  COMMUNICATE_CHANGE = 'COMMUNICATE_CHANGE',
  COMMUNICATE_CHANGE_NACK = 'COMMUNICATE_CHANGE_NACK',
  CANCEL_SENDING = 'CANCEL_SENDING',
  CANCEL_SENDING_ACK = 'CANCEL_SENDING_ACK',
  CANCEL_SENDING_NACK = 'CANCEL_SENDING_NACK',
  RECEIVED = 'RECEIVED', // Supplier
  PAYMENT_DUE = 'PAYMENT_DUE', // Supplier
  REVERSED = 'REVERSED',
  PAID = 'PAID',
  REJECTED_BUT_CREATED = 'REJECTED_BUT_CREATED', // STS
  SENT = 'SENT' //PROFORMA
}

export enum PaymentModeType {
  MP01 = 'MP01',
  MP02 = 'MP02',
  MP05 = 'MP05',
  MP08 = 'MP08'
}

export enum CurrencyType {
  EUR = 'EUR'
}

export enum PaymentTermsType {
  IMMEDIATE = 'IMMEDIATE',
  DF30 = 'DF30',
  DF60 = 'DF60',
  DF90 = 'DF90',
  FM30 = 'FM30',
  FM60 = 'FM60',
  FM90 = 'FM90',
  CUSTOMIZED = 'CUSTOMIZED'
}

export enum AdditionalDataPAType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  CONTRACT = 'CONTRACT',
  CONVENTION = 'CONVENTION'
}

export enum VatNature {
  N1 = 'N1',
  N2_1 = 'N2_1',
  N2_2 = 'N2_2',
  N3_1 = 'N3_1',
  N3_2 = 'N3_2',
  N3_3 = 'N3_3',
  N3_4 = 'N3_4',
  N3_5 = 'N3_5',
  N3_6 = 'N3_6',
  N4 = 'N4',
  N5 = 'N5',
  N6_1 = 'N6_1',
  N6_2 = 'N6_2',
  N6_3 = 'N6_3',
  N6_4 = 'N6_4',
  N6_5 = 'N6_5',
  N6_6 = 'N6_6',
  N6_7 = 'N6_7',
  N6_8 = 'N6_8',
  N6_9 = 'N6_9',
  N7 = 'N7',
  NS_22 = 'NS_22',
  NS_4 = 'NS_4',
  NS_5 = 'NS_5',
  NS_10 = 'NS_10'
}

export const vatNatureMap = new Map<VatNature, string>([
  [VatNature.N1, 'N1 - Operazioni escluse ex. art. 15 DPR 633/72'],
  [VatNature.N2_1, 'N2.1 - Operazioni prive del requisito di territorialità IVA, ai sensi degli artt. da 7 a 7-septies del DPR 633/72'],
  [VatNature.N2_2, 'N2.2 - Altre operazioni non soggette ad IVA'],
  [VatNature.N3_1, 'N3.1 - Non imponibili - esportazioni'],
  [VatNature.N3_2, 'N3.2 - Non imponibili - cessioni intracomunitarie'],
  [VatNature.N3_3, 'N3.3 - Non imponibili - cessioni verso San Marino'],
  [VatNature.N3_4, 'N3.4 - Non imponibili - operazioni assimilate alle cessioni dell\'esportazione'],
  [VatNature.N3_5, 'N3.5 - Non imponibili - a seguito di dichiarazioni d\'intento'],
  [VatNature.N3_6, 'N3.6 - Non imponibili - altre operazioni che non concorrono alla formazione del plafond'],
  [VatNature.N4, 'N4 - Esenti'],
  [VatNature.N5, 'N5 - Operazioni in regime del margine / IVA non esposta in fattura'],
  [VatNature.N6_1, 'N6.1 - Inversione contabile - cessioni di rottami e altri materiali di recupero'],
  [VatNature.N6_2, 'N6.2 - Inversione contabile - cessioni di oro e argento puro'],
  [VatNature.N6_3, 'N6.3 - Inversione contabile - subappalti nel settore edile'],
  [VatNature.N6_4, 'N6.4 - Inversione contabile - cessioni di fabbricati'],
  [VatNature.N6_5, 'N6.5 - Inversione contabile - cessioni di telefoni cellulari'],
  [VatNature.N6_6, 'N6.6 - Inversione contabile - cessioni di prodotti elettronici'],
  [VatNature.N6_7, 'N6.7 - Inversione contabile - prestazioni comparto edile e settori connessi'],
  [VatNature.N6_8, 'N6.8 - Inversione contabile - operazioni settore energetico'],
  [VatNature.N6_9, 'N6.9 - Inversione contabile - altri casi'],
  [VatNature.N7, 'N7 - IVA assolta in altro stato UE (vendite a distanza, prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici)'],
  [VatNature.NS_4, '4%'],
  [VatNature.NS_5, '5%'],
  [VatNature.NS_10, '10%'],
  [VatNature.NS_22, '22%'],
]);
