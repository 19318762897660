import { OptionModel } from '../../models/optionModel';
import { generateArrayOfYears } from '../../utils/date';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: number;
  onChange?: (year?: number) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoBankAccountYearSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={
        'Da che anno hai il conto corrente e/o attività finanziarie all’estero?'
      }
      options={getOptions()}
      styleType={'default'}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      onChange={(e) =>
        e &&
        onChange &&
        onChange(e.value !== undefined ? Number(e.value) : undefined)
      }
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
    />
  );
}
function getOptions(value?: number): OptionModel[] {
  let optionList: OptionModel[] = [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined || value === null,
    },
  ];

  let years = generateArrayOfYears();
  years.map((el: number) =>
    optionList.push({
      value: el.toString(),
      label: el.toString(),
      selected: value === el,
    })
  );

  return optionList;
}
