import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { MessageType, MessagesModel } from '../../models/messagesModel';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { useProSidebar } from 'react-pro-sidebar';
import { MessagesReportList } from '../../components/messagesReportList/messagesReportList';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { formatDateITWithHour } from '../../utils/date';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { SetState } from '../../types/functions';
import { MessagesService } from '../../services/messagesService';
import { downloadPdf } from '../../utils/file';
import { DocumentsModel } from '../../models/documentsModel';
import {
  getMessagesReports,
  getUnreadCounters,
} from '../../redux/slices/messagesListSlice';
import DOMPurify from 'dompurify';
import { DocumentsPreviewModal } from '../../components/documentsPreviewModal/documentsPreviewModal';
import uri from '../../routers/quickfiscoUri.json';
import lang from './messagePage.json';
import './messagePage.css';

export function MessagePage() {
  const { collapsed } = useProSidebar();
  const params = useParams();
  const id = params.id;

  const [currentMessage, setCurrentMessage] = useState<
    MessagesModel | undefined
  >();
  const [statusCurrentMessage, setStatusCurrentMessage] =
    useState<PromiseStatuses>('idle');
  const [fileList, setFileList] = useState<File[]>([]);
  const [statusFileList, setStatusFileList] = useState<PromiseStatuses>('idle');
  const [open, setOpen] = useState<boolean>(false);
  const [filePreviewId, setFilePreviewId] = useState<string | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id !== undefined) {
      findById(id, setStatusCurrentMessage, setCurrentMessage);
    }
  }, [id]);

  useEffect(() => {
    if (currentMessage && currentMessage.documents) {
      getFileList(currentMessage?.documents, setStatusFileList, setFileList);
    }

    if (currentMessage && currentMessage.unread) {
      markAsRead();
    }
  }, [currentMessage]);

  if (statusCurrentMessage === 'loading' || statusCurrentMessage === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        {statusCurrentMessage === 'loading' && <QuickfiscoSpinner />}
        {statusCurrentMessage === 'failed' && <div>errore</div>}
      </div>
    );
  }

  let messageType: string;
  switch (currentMessage?.type) {
    case MessageType.FULLFILLMENTS:
      messageType = lang.fulfillments;
      break;
    case MessageType.HEY:
      messageType = lang.newFunc;
      break;
    case MessageType.NEWS:
      messageType = lang.news;
      break;
    case MessageType.WARNING:
      messageType = lang.warning;
      break;
    default:
      return <div>Tipo di messaggio non valido.</div>;
  }

  const goBack = () => {
    navigate(uri.Messages);
  };

  const markAsRead = () => {
    if (currentMessage.id) {
      const service = new MessagesService();

      service
        .markAsRead(currentMessage.id)
        .then(() => {
          dispatch(getMessagesReports());
          dispatch(getUnreadCounters());
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu id="messages" menuItem={MenuItems.MESSAGES} />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row">
            <div className="col">
              <QuickfiscoHeader title={lang.title} />
            </div>
          </div>
          <div className="row mt-4 message-page-report-list">
            <div className="col-12">
              <MessagesReportList readOnly={true} />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-1'}>
              <div className={'back-box'} onClick={() => goBack()}>
                <QuickfiscoIcon name={'back-msg-btn.svg'} />
                {lang.back}
              </div>
            </div>
            <div className={'col-10'}>
              <div className={'message-page-container p-4'}>
                <div className={'row no-gutters mb-4'}>
                  <div
                    className={
                      'col-4 d-flex align-items-center justify-content-start'
                    }
                  >
                    <div
                      className={`d-flex align-items-center justify-content-center message-page-type-${currentMessage.type?.toLowerCase()}`}
                    >
                      {messageType && messageType}
                    </div>
                  </div>
                  <div
                    className={
                      'col-4 message-page-date d-flex align-items-center justify-content-center'
                    }
                  >
                    {lang.msgDate}
                    {currentMessage.sentDate &&
                      formatDateITWithHour(currentMessage.sentDate)}
                  </div>
                  <div
                    className={
                      'col-4 d-flex align-items-center justify-content-end'
                    }
                  >
                    {currentMessage?.important && (
                      <span className={'message-page-important'}>
                        <QuickfiscoIcon name={'warning.svg'} className="me-2" />
                        {lang.importantLabel}
                      </span>
                    )}
                  </div>
                </div>
                <div className={'row mt-4'}>
                  <div className={'col-12 pe-4 ps-4'}>
                    <div className={'message-page-object'}>
                      {currentMessage.objectMessage}
                    </div>
                  </div>
                </div>
                <div className={'row mt-3 mb-4'}>
                  <div className={'col-12 pe-4 ps-4'}>
                    <div className={'message-page-text-area'}>
                      <div
                        className={'draft-message-modal-text-area pe-3'}
                        dangerouslySetInnerHTML={{
                          __html: currentMessage.textMessage
                            ? DOMPurify.sanitize(currentMessage.textMessage)
                            : '',
                        }}
                      />
                    </div>
                  </div>
                </div>
                {currentMessage.documents &&
                  currentMessage.documents?.length > 0 && (
                    <>
                      <div className={'row mt-3 align-items-center'}>
                        <div
                          className={'col-12 pe-4 ps-4 message-page-attachment'}
                        >
                          {lang.attachmentTitle}
                        </div>
                      </div>
                      <div className={'row mt-3'}>
                        <div className={'col-12 pe-4 ps-4'}>
                          <div
                            className={
                              'd-flex flex-wrap mb-2 message-page-file-list'
                            }
                          >
                            {statusFileList === 'loading' && (
                              <QuickfiscoSpinner />
                            )}
                            {fileList.length > 0 &&
                              fileList.map((file, index) => {
                                return (
                                  <div
                                    key={file.name + Math.random()}
                                    className={
                                      'message-page-attachment-container-box-file me-2 mt-1 mb-1 d-flex align-items-center'
                                    }
                                  >
                                    <QuickfiscoIcon name={'attachment.svg'} />
                                    <span className={'me-3 ms-3'}>
                                      {file.name}
                                    </span>
                                    <QuickfiscoIcon
                                      name={'download.svg'}
                                      isButton={true}
                                      onClick={() => {
                                        file
                                          .arrayBuffer()
                                          .then((data) =>
                                            getDownloadDocumentsName(
                                              file.name,
                                              data
                                            )
                                          );
                                      }}
                                    />
                                    <QuickfiscoIcon
                                      name={'find.svg'}
                                      className="ms-3"
                                      isButton={true}
                                      onClick={() => {
                                        setOpen(true);
                                        currentMessage.documents &&
                                          setFilePreviewId(
                                            currentMessage.documents[index]
                                              .object.id
                                          );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {filePreviewId && (
        <DocumentsPreviewModal
          open={open}
          setOpen={setOpen}
          docId={filePreviewId}
        />
      )}
    </div>
  );
}

function findById(
  id: string,
  setStatus: SetState<PromiseStatuses>,
  setCurrentMessage: SetState<MessagesModel | undefined>
) {
  const contactService = new MessagesService();

  setStatus('loading');
  contactService
    .findById(id)
    .then((customer) => {
      setStatus('idle');
      setCurrentMessage(customer);
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}

function getDownloadDocumentsName(name?: string, data?: ArrayBuffer): void {
  if (!name || !data) {
    return;
  }
  downloadPdf(name, data);
}

function getFilesId(documents: DocumentsModel[]): string[] {
  const fileIdList: string[] = [];

  for (let document of documents) {
    if (document.object && document.object.id)
      fileIdList.push(document.object.id);
  }

  return fileIdList;
}

function getFileList(
  documents: DocumentsModel[],
  setStatus: SetState<PromiseStatuses>,
  setFileListElement: SetState<File[]>
) {
  const service = new MessagesService();

  setStatus('loading');
  return service
    .getFiles(getFilesId(documents))
    .then((fileList) => {
      setStatus('idle');
      setFileListElement(fileList);
    })
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}
