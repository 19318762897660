import { DocumentsFilters } from '../models/documentsFilters';
import { DocumentsModel } from '../models/documentsModel';
import { DocumentsReportModel } from '../models/documentsReportModel';
import { PaginationModel } from '../models/paginationModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class DocumentsService {

  private readonly url = host + '/api/v1/messages-documents/documents';
  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public getReports(): Promise<DocumentsReportModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/report'
    })
      .then(res => res.json())
      .then((data: DocumentsReportModel) => data);
  }

  public findAll(filters: DocumentsFilters): Promise<PaginationModel<DocumentsModel>> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url +
        (filters.type !== undefined ? '?filterType=' + filters.type : '') +
        (filters.page !== undefined ? `${filters.type !== undefined ? '&' : '?'}page=` + filters.page : '')
    })
      .then(res => res.json())
      .then((data: PaginationModel<DocumentsModel>) => data)
  }

  public markAsRead(userId?: string): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/mark_as_read/' + userId,
      method: 'put'
    })
      .then(() => undefined);
  }
}
