import { PromiseStatuses } from '../../types/strings';
import { useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  getItemWithExipiration,
  setItemWithExipiration,
} from '../../utils/localStorage';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './trialSubscriptionActivationModal.json';
import './trialSubscriptionActivationModal.css';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function TrialSubscriptionActivationModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const [status, setStatus] = useState<PromiseStatuses>('idle');

  return (
    <div className={'row mt-4'}>
      <QuickfiscoError
        message={lang.sendError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoModal hide={() => setOpen(false)} isOpen={open}>
        {getItemWithExipiration('stopTrialSubscriptionActivationModal') ===
          'true' && open === true ? (
          <div className={'row'}>
            <div className={'col-12 text-center'}>
              <div className={'row'}>
                <div className={'col-12 my-5'}>
                  <QuickfiscoIcon name={'alert-success.svg'} />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-12 trial-subscription-activation-title'}>
                  {lang.titleSuccess}
                </div>
              </div>
              <div className={'row d-flex justify-content-center mt-3 mb-5'}>
                <div className={'col-10 trial-subscription-activation-text'}>
                  <b>{lang.textSuccessBold}</b>
                  {lang.textSuccess}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={'row'}>
            <div className={'col-12 text-center'}>
              <div className={'row'}>
                <div className={'col-12 trial-subscription-activation-title'}>
                  {lang.title}
                </div>
              </div>
              <div className={'row my-3'}>
                <div
                  className={'col-12 trial-subscription-activation-sub-title'}
                >
                  {lang.subTitle}
                </div>
              </div>
              <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 trial-subscription-activation-text'}>
                  {lang.text}
                </div>
              </div>
              <div
                className={
                  'row d-flex justify-content-center align-items-center my-5'
                }
              >
                <div className={'col-4'}>
                  <QuickfiscoButton
                    id={'trial-subscription-activation-button'}
                    label={lang.closeButton}
                    type={'primary'}
                    onClick={() => {
                      setOpen(false);
                      setItemWithExipiration(
                        'stopTrialSubscriptionActivationModal',
                        {
                          value: 'true',
                          expireTime: Date.now() + 1000 * 60 * 60 * 24 * 50,
                        }
                      );
                    }}
                  />
                </div>
                <div className={'col-5'}>
                  {status === 'loading' ? (
                    <div
                      className={
                        'd-flex justify-content-center align-items-center w-100'
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={'trial-subscription-activation-button'}
                      label={lang.renewButton}
                      type={'secondary'}
                      onClick={() => activateSubscription(setStatus)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </QuickfiscoModal>
    </div>
  );
}

function activateSubscription(setStatus: SetState<PromiseStatuses>) {
  const service = new UserService();

  setStatus('loading');
  service
    .sendActivateSubscriptionEmail()
    .then(() => {
      setStatus('successfully');
      setItemWithExipiration('stopTrialSubscriptionActivationModal', {
        value: 'true',
        expireTime: Date.now() + 1000 * 60 * 60 * 24 * 50,
      });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
