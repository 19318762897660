import { addDays, differenceInCalendarDays } from 'date-fns';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import conf from '../../../config.json';
import lang from './trialSubscriptionExpiration.json';
import './trialSubscriptionExpiration.css';

interface Props {
  lastPaymentDate?: string;
}

export function TrialSubscriptionExpiration(props: Props) {
  const { lastPaymentDate } = props;

  let paymentDate;
  let expirationDate;
  let days;

  if (lastPaymentDate) {
    paymentDate = new Date(lastPaymentDate);
    expirationDate = addDays(paymentDate, conf.trialSubscriptionPeriod);
    days = differenceInCalendarDays(expirationDate, new Date());
  }

  return (
    <div className={'row'}>
      <div className={'col-12'}>
        <div className={'trial-expiration-container'}>
          <QuickfiscoIcon name={'warning-small.svg'} className="me-1" />
          <span>{lang.trial}</span>
          <QuickfiscoIcon name={'lente-icon.svg'} className="mx-1" />
          {lang.stillHave}
          <div>&nbsp;</div>
          <b>
            {days && days > 1 ? (
              <>
                {days} {lang.days}
              </>
            ) : (
              <>{lang.lastDay}</>
            )}
          </b>
        </div>
      </div>
    </div>
  );
}
