import { useAppDispatch } from '../../redux/hooks';
import { setFeesRegisterMonthFilter } from '../../redux/slices/feesRegisterSlice';
import './feesRegisterMonthFilterButton.css';

interface Props {
    monthLabel: string;
    selected: boolean
    month: string
}

export function FeesRegisterMonthFilterButton(props: Props) {
    const { monthLabel } = props;
    const { selected } = props;
    const { month } = props;

    const dispatch = useAppDispatch()

    return (
        <div
            className={selected ? "fees-register-month-filter-button-container-selected" : "fees-register-month-filter-button-container"}
            onClick={() => dispatch(setFeesRegisterMonthFilter(month))}
        >
            <p className={selected ? "fees-register-month-filter-button-text-selected" : "fees-register-month-filter-button-text"}>
                {monthLabel}
            </p>
        </div>
    )
}
