import { NumericFormat } from 'react-number-format';
import { CategoryType } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setLastYearContributionBalance,
  setLastYearContributionDeposit,
  setLastYearRevenues,
  setLastYearTaxDeposit
} from '../../redux/slices/taxProfileSlice';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileChangeStep } from '../taxProfileChangeStep/taxProfileChangeStep';
import { TaxProfileStepCounter } from '../taxProfileStepCounter/taxProfileStepCounter';
import './taxProfileLastYearData.css';
import lang from './taxProfileLastYearData.json';

export function TaxProfileLastYearData() {
  const taxProfileState = useAppSelector(state => state.taxProfile)

  const dispatch = useAppDispatch();
  const userState = useAppSelector(state => state.user)


  if (taxProfileState.status === 'loading') {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <QuickfiscoSpinner />
      </div>
    )
  }

  const user = userState.user
  const taxProfile = taxProfileState.profile

  return (
    <div className={'tax-profile-last-year-data-container'}>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <TaxProfileStepCounter />
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-last-year-data-title'}>
            {lang.title}
          </span>
        </div>
      </div>
      <div className={'row mt-5 d-flex justify-content-center'}>
        <div className={'col-4'}>
          <NumericFormat
            id='tax-profile-last-year-data-revenues-input'
            key={'input-tax-profile-last-year-revenues'}
            suffix='€'
            defaultValue={taxProfile.lastYearRevenues}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearRevenues(e.floatValue))}
            customInput={QuickfiscoInput}
            styleType={'medium-wizard'}
            label={lang.revenues}
            placeholder={lang.placeholder + "€"}
            fixedDecimalScale
          />
          {
            user.category === CategoryType.NOT_FUND && (
              <div className={'row'}>
                <div className={'col-12'}>
                  <p className={'tax-profile-last-year-data-text'}>
                    {lang.revenuesInfo}
                  </p>
                </div>
              </div>
            )
          }
          {
            user.category === CategoryType.FUND && (
              <div className={'row'}>
                <div className={'col-12'}>
                  <p className={'tax-profile-last-year-data-text'}>
                    {"Fatturato incassato anno precedente al netto del contributo integrativo"}
                  </p>
                </div>
              </div>
            )
          }
        </div>
        {user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01") ?
          <div className={'col-4 offset-2'}>
            <NumericFormat
              id='tax-profile-last-year-data-balance-input'
              key={'input-tax-profile-last-year-balance'}
              suffix='€'
              defaultValue={taxProfile.lastYearContributionBalance}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearContributionBalance(e.floatValue))}
              customInput={QuickfiscoInput}
              styleType={'medium-wizard'}
              label={"Contributi deducibili versati nell' anno precedente"}
              placeholder={lang.placeholder + "€"}
              fixedDecimalScale
            />
            <div className={'row'}>
              <div className={'col-12'}>
                <p className={'tax-profile-last-year-data-text'}>
                  {"Contributo di marternità e contributo soggettivo minimo"}
                </p>
              </div>
            </div>
          </div>
          :
          <div className={'col-4 offset-2'}>
            <NumericFormat
              id='tax-profile-last-year-data-balance-input'
              key={'input-tax-profile-last-year-balance'}
              suffix='€'
              defaultValue={taxProfile.lastYearContributionBalance}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearContributionBalance(e.floatValue))}
              customInput={QuickfiscoInput}
              styleType={'medium-wizard'}
              label={lang.contributionBalance}
              placeholder={lang.placeholder + "€"}
              fixedDecimalScale
            />
            <div className={'row'}>
              <div className={'col-12'}>
                <p className={'tax-profile-last-year-data-text'}>
                  {lang.contributionInfo}
                </p>
              </div>
            </div>
          </div>
        }
      </div>
      <div className={'row mt-5 d-flex justify-content-center'}>
        {user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01") ? <div></div> :
          <div className={'col-4'}>
            <NumericFormat
              id='tax-profile-last-year-data-contribution-deposit-input'
              key={'input-tax-profile-last-year-contribution-deposit'}
              suffix='€'
              defaultValue={taxProfile.lastYearContributionDeposit}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearContributionDeposit(e.floatValue))}
              customInput={QuickfiscoInput}
              styleType={'medium-wizard'}
              label={lang.contributionDeposit}
              placeholder={lang.placeholder + "€"}
              fixedDecimalScale
            />
            <div className={'row'}>
              <div className={'col-12'}>
                <p className={'tax-profile-last-year-data-text'}>
                  {lang.contributionInfo}
                </p>
              </div>
            </div>
          </div>
        }
        <div className={user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01") ? 'col-10 justify-content-center' : 'col-4 offset-2'}>
          <NumericFormat
            id='tax-profile-last-year-data-tax-deposit-input'
            key={'input-tax-profile-last-year-tax-deposit'}
            suffix='€'
            defaultValue={taxProfile.lastYearTaxDeposit}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            onValueChange={e => e.floatValue !== undefined && dispatch(setLastYearTaxDeposit(e.floatValue))}
            customInput={QuickfiscoInput}
            styleType={'medium-wizard'}
            label={lang.taxDeposit}
            placeholder={lang.placeholder + "€"}
            fixedDecimalScale
          />
          <div className={'row'}>
            <div className={'col-12'}>
              <p className={'tax-profile-last-year-data-text'}>
                {lang.contributionInfo}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <TaxProfileChangeStep />
        </div>
      </div>
    </div >
  );
}
