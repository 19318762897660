import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import uri from '../../routers/quickfiscoUri.json';
import lang from './taxReturnSectionFinance.json';
import './taxReturnSectionFinance.css';

interface Props {
  onClickNextStep: () => void;
  onClickPrevStep: () => void;
}

export function TaxReturnSectionFinance(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-finance-title">
            {lang.title}
          </div>
          <div className="col-12 tax-return-section-finance-text mt-4">
            Manca l’ultimo passaggio per concludere il questionario che ci
            permetterà di predisporre la tua dichiarazione dei redditi.
            <br />
            <br />
            Nel prossimo e ultimo step{' '}
            <b>
              dovrai decidere in quante rate dividere il versamento di tasse e
              contributi
            </b>
            .
            <br />
            Se non l’hai ancora fatto, utilizza il nostro{' '}
            <a
              className="tax-calculation-guide-popup-text-blue"
              href={uri.TaxCalculation}
              target={'_blank'}
              rel={'noreferrer'}
            >
              PREVISIONALE TASSE
            </a>{' '}
            per simulare la tua situazione fiscale.
          </div>
        </div>
        <div className="row mt-5 text-center">
          <div className="col-12 ">
            <div className={'tax-return-section-finance-container p-5'}>
              <div className="row">
                <div className="col-12">
                  <QuickfiscoIcon name={'finance-tax-return.png'} />
                </div>
                <div className="col-12 tax-return-section-finance-container-title">
                  Ansia?{' '}
                  <span className="tax-return-section-finance-container-blue-text">
                    Non preoccuparti
                  </span>
                  .
                </div>
                <div className="col-12 tax-return-section-finance-container-italic-blue mt-3">
                  Non hai messo da parte i soldi per pagare tasse e contributi?
                  <br />
                  Hai avuto un imprevisto dell’ultimo minuto e temi di non
                  riuscire ad affrontare i versamenti?
                  <br />
                  Devi decidere se “pagare tasse e contributi” o “andare in
                  vacanza"?
                </div>
                <div className="col-12 tax-return-section-finance-text my-4">
                  Ti ricordiamo che tutti gli abbonati a Quickfisco possono{' '}
                  <b>simulare e richiedere finanziamenti</b>
                  <br />
                  direttamente dalla piattaforma grazie alla partnership con FAI
                  CREDIT. <br /> <br />
                  Scopri subito se sei finanziabile!
                </div>
                <div className="col-4 offset-4">
                  <QuickfiscoButton
                    id={'password-changed'}
                    label={'SIMULA FINANZIAMENTO'}
                    onClick={() => {
                      window.open(uri.Finance, '_blank');
                    }}
                    type={'senary'}
                  />
                </div>
                <div className="col-12 tax-return-section-finance-text-info mt-4">
                  Non sarai obbligato ad inviare la richiesta di finanziamento
                  una volta effettuata la simulazione.
                  <br />
                  Grazie alla partnership con FAI CREDIT{' '}
                  <b>accederai a un canale privilegiato</b> e in circa{' '}
                  <b>7 giorni lavorativi otterrai l’esito della tua pratica</b>.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="row d-flex mt-5 align-items-center">
            <div className="col-3">
              <QuickfiscoButton
                id={'tax-return-section-finance-go-prev'}
                label={lang.goPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            <div className="col-6 text-center">
              <div className="tax-return-section-finance-go-title">
                {lang.goAlertTitle}
              </div>
              <div className="tax-return-section-finance-go-description">
                {lang.goAlertdescription}
              </div>
            </div>
            <div className="col-3">
              <QuickfiscoButton
                id={'tax-return-section-finance-go-next'}
                label={lang.goNext}
                type={'secondary'}
                onClick={() => onClickNextStep()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
