import { host } from '../../utils/config';
import './quickfiscoIcon.css';

interface Props {
  name: string;
  onClick?: Function;
  isButton?: boolean;
  className?: string;
}

export function QuickfiscoIcon(props: Props) {
  const { name } = props;
  const { onClick } = props;
  const { isButton } = props;
  const { className } = props;

  return (
    <img
      alt={'Icon'}
      src={host + '/images/' + name}
      onClick={() => onClick && onClick()}
      className={(isButton ? 'quickfisco-icon' : '') + ' ' + className}
    />
  );
}
