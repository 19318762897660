import { ChangePasswordModel } from '../models/changePasswordModel';
import { ObjectType } from '../models/objectModel';
import { ProfileErrorSetters } from '../models/profileErrorSetters';
import { SendResetPasswordEmailModel } from '../models/sendResetPasswordEmailModel';
import { UserErrorSetters } from '../models/userErrorSetters';
import { BankAccount, DdrDocumentsModel, ObjIncomeUpload, StatusDdrModel, UserModel, UserStatusType } from '../models/userModel';
import { ValidationFormAnswers } from '../models/validationForm';
import { TotalRevenuesModel } from '../models/totalRevenuesModel';
import { setErrorAddress, setErrorAteco, setErrorBankIban, setErrorBankName, setErrorBirthPlace, setErrorBirthProvince, setErrorBirthState, setErrorCity, setErrorDateOfBirth, setErrorFund, setErrorGender, setErrorInitialInvoiceNumber, setErrorName, setErrorPec, setErrorPhone, setErrorProvince, setErrorSurname, setErrorTaxCode, setErrorVat, setErrorVatOpeningDate, setErrorZipCode } from '../redux/slices/userSlice';
import {
  emailRegex,
  fiscalCodeRegex,
  ibanRegex,
  numberRegex,
  passwordRegex,
  vatRegex,
  zipCodeRegex
} from '../regex/regex';
import { host } from '../utils/config';
import { getExtension, toBase64, toFile } from '../utils/file';
import { Http } from '../utils/http';
import { ObjectService } from './objectService';
import { ActivationOnboardingModel, RegisterOnboardingModel, UserRegistrationModel } from '../models/onboardingModel';
import { RegisterModel } from '../models/registerModel';

export interface UserValidateOptions {
  skipEmailAvailable?: boolean;
  skipPassword?: boolean;
  skipConfirmPassword?: boolean;
  skipFiscalProfileSaving?: boolean;
  skipPersonalProfileSaving?: boolean;
  confirmPassword?: string;
}

export class UserService {

  private readonly url = host + '/api/v1/users';

  private readonly http: Http;
  private readonly dispatch?: Function;
  private readonly userErrorSetters?: UserErrorSetters;
  private readonly profileErrorSetters?: ProfileErrorSetters;
  private readonly skipRegisterProfilePage?: boolean;
  private readonly objectService: ObjectService;

  public constructor(
    dispatch?: Function,
    userErrorSetters?: UserErrorSetters,
    profileErrorSetters?: ProfileErrorSetters,
    skipRegisterProfilePage?: boolean
  ) {
    this.http = Http.getInstance();
    this.dispatch = dispatch;
    this.userErrorSetters = userErrorSetters;
    this.profileErrorSetters = profileErrorSetters;
    this.skipRegisterProfilePage = skipRegisterProfilePage;
    this.objectService = new ObjectService();
  }

  public static editingMe(user: UserModel, editUser: UserModel, disabled?: boolean): boolean {
    if (disabled === true) return false
    if (!user.bankAccount || user.bankAccount.length === 0) return false
    return JSON.stringify(user) !== JSON.stringify(editUser)
  }

  public static isNotEnabled(user: UserModel): boolean {
    return !user.enabled || user.status === UserStatusType.PAYMENT_DUE || user.status === UserStatusType.REGISTERED;
  }

  public getMe(): Promise<UserModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/me'
    })
      .then(res => res.json())
      .then((data: UserModel) => data);
  }

  public updateMe(user: UserModel): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/me',
      method: 'put',
      body: JSON.stringify(user)
    })
      .then(() => undefined);
  }

  public doRegistration(user: RegisterModel): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/register',
      method: 'post',
      body: JSON.stringify(user)
    })
      .then(() => undefined);
  }

  public doOnboardingRegistration(user: RegisterOnboardingModel): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/registration',
      method: 'post',
      body: JSON.stringify(user)
    })
      .then(() => undefined);
  }

  public onboardingInit(crmUserId: string): Promise<UserModel> {
    return this.http.sendAndReceive({
      url: this.url + `/onboarding-init/?crmUserId=${crmUserId}`,
      method: 'post',
    })
      .then(res => res.json())
      .then(user => user);
  }

  public newUpdateUser(user: UserRegistrationModel | UserModel): Promise<UserModel> {
    return this.http.sendAndReceive({
      url: this.url + '/update',
      method: 'put',
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then((data: UserModel) => data);
  }

  public emailAvailable(email: string): Promise<boolean> {
    return this.http.sendAndReceive({
      url: this.url + '/email-available',
      method: 'post',
      body: JSON.stringify({ email: email })
    })
      .then(res => res.json())
      .then(data => data.available);
  }

  public emailVerificationOnboarding(code: string): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/check-confirm-email/' + code,
      method: 'post',
    })
      .then(() => undefined);
  }

  public reSendEmailVerificationOnboarding(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/resend-confirm-email',
      method: 'post',
    })
      .then(() => undefined);
  }

  public onboardingCompleted(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/onboarding-complete',
      method: 'post',
    })
      .then(() => undefined);
  }

  public onboardingActivation(): Promise<ActivationOnboardingModel> {
    return this.http.sendAndReceive({
      url: this.url + '/me/activation',
      method: 'get',
    })
      .then(res => res.json())
      .then((data: ActivationOnboardingModel) => data);
  }

  public changePassword(request: ChangePasswordModel): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/change-password',
      method: 'post',
      body: JSON.stringify(request)
    })
      .then(() => undefined);
  }

  public sendResetPasswordEmail(request: SendResetPasswordEmailModel): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/send-reset-password-email',
      method: 'post',
      body: JSON.stringify(request)
    })
      .then(() => undefined);
  }

  public sendConfirmEmail(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/send-confirm-email',
      method: 'post'
    })
      .then(() => undefined);
  }

  public sendActivateSubscriptionEmail(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/send-activate-subscription-email',
      method: 'post'
    })
      .then(() => undefined);
  }

  public sendIntegrationDdrEmail(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/send-integration-ddr',
      method: 'post'
    })
      .then(() => undefined);
  }

  public resetPassword(userId?: string, token?: string, newPassword?: string): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/reset-password?userId=' + userId + '&token=' + token,
      method: 'post',
      body: JSON.stringify({ newPassword })
    })
      .then(() => undefined);
  }

  public sendToSupportImportInvoiceMail(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/send-invoice-import-support-mail',
      method: 'post'
    })
      .then(() => undefined);
  }


  public async getLogo(profilePickId: string): Promise<File> {
    const object = await this.objectService.findById(profilePickId);
    const arrayBuffer = await this.objectService.findFile(profilePickId);

    return toFile(arrayBuffer, object);
  }

  public async saveLogo(file: File, profilePickId?: string): Promise<string | undefined> {
    if (profilePickId !== undefined && profilePickId !== '') {
      this.objectService.del(profilePickId)
    }

    const objectId = await this.objectService
      .save({
        name: file.name,
        type: ObjectType.OTHER_ENTITY_OBJECT,
        extension: getExtension(file),
        file: await toBase64(file)
      })
      .then(data => data.id)

    return objectId;
  }

  public async saveDoc(file: File, fileName: string): Promise<string | undefined> {
    const objectId = await this.objectService
      .save({
        name: fileName + getExtension(file),
        type: ObjectType.FISCAL_DOCUMENT,
        extension: getExtension(file),
        file: await toBase64(file),
      })
      .then(data => data.id)

    return objectId;
  }

  public sendValidationForm(answer: ValidationFormAnswers): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/validation-form',
      method: 'post',
      body: JSON.stringify(answer)
    })
      .then(() => undefined);
  }

  public async sendIncome(fileList: File[], fileNameSuffix: string, feesRegisterTotalInvoices?: number, telematicFeesRegister?: number): Promise<void> {
    const request: ObjIncomeUpload = {
      objectModel: []
    }
    let files: string[] = []

    for (let i = 0; i < fileList.length; i++) {
      request.objectModel.push({
        name: i === 0
          ? `lista_fatture_${fileNameSuffix}_01`
          : `corrispettivi_${fileNameSuffix}_02`,
        type: ObjectType.FISCAL_DOCUMENT,
        extension: getExtension(fileList[i]),
      })

      files = [...files, await toBase64(fileList[i])]
    }

    request.telematicFeesRegister = telematicFeesRegister
    request.feesRegisterTotalInvoices = feesRegisterTotalInvoices
    request.file = [...files]

    return this.http.sendAndReceive({
      url: this.url + '/ddr/income-upload',
      method: 'put',
      body: JSON.stringify(request)
    })
      .then(() => undefined);

  }

  public getStatusDdr(userId?: string, year?: string): Promise<StatusDdrModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + `/ddr/status-ddr/${userId}?year=${year}`
    })
      .then(res => res.json())
      .then((data: StatusDdrModel) => data);
  }

  public getDocumentsDdr(userId?: string, year?: string): Promise<DdrDocumentsModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + `/ddr/documents-ddr/${userId}?year=${year}`
    })
      .then(res => res.json())
      .then((data: DdrDocumentsModel) => data);
  }

  public validateName(name: string): boolean {
    this.userErrorSetters?.setErrorName(false);
    this.dispatch && this.dispatch(setErrorName(false))

    if (name === '' || name.length > 60) {
      this.userErrorSetters?.setErrorName(true);
      this.dispatch && this.dispatch(setErrorName(true))
      return false;
    }

    return true;
  }

  public regimeLimit85k(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/profile/regime-limit',
      method: 'put',
    })
      .then();
  }

  public regimeLimit100k(): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/profile/over100k',
      method: 'put',
    })
      .then();
  }

  public totalRevenuesByRegimeLimit(year?: string, regimeLimit?: '85000' | '100000'): Promise<TotalRevenuesModel> {
    return this.http.sendAndReceive({
      url: this.url + `/profile/total-revenues?year=${year}&regimeLimit=${regimeLimit}`,
      method: 'get'
    })
      .then(res => res.json())
      .then((data: TotalRevenuesModel) => data);
  }

  public validateSurname(surname: string): boolean {
    this.userErrorSetters?.setErrorSurname(false);
    this.dispatch && this.dispatch(setErrorSurname(false))

    if (surname === '' || surname.length > 60) {
      this.userErrorSetters?.setErrorSurname(true);
      this.dispatch && this.dispatch(setErrorSurname(true))
      return false;
    }

    return true;
  }

  public async validateEmail(email: string, options: UserValidateOptions): Promise<boolean> {
    this.userErrorSetters?.setErrorEmail(false);

    if (!emailRegex.test(email) || (!options.skipEmailAvailable && !await this.emailAvailable(email))) {
      this.userErrorSetters?.setErrorEmail(true);
      return false;
    }

    return true;
  }

  public async validatePhone(phone: string): Promise<boolean> {
    this.userErrorSetters?.setErrorPhone(false);
    this.dispatch && this.dispatch(setErrorPhone(false))

    if (phone === '' || phone.length < 7 || phone.length > 20) {
      this.userErrorSetters?.setErrorPhone(true);
      this.dispatch && this.dispatch(setErrorPhone(true))
      return false;
    }

    return true;
  }

  public validatePasswordInput(options: UserValidateOptions, password?: string): boolean {
    this.userErrorSetters?.setErrorPassword(false);

    if (!options.skipPassword && !this.validatePassword(password)) {
      this.userErrorSetters?.setErrorPassword(true);
      return false;
    }

    return true;
  }

  public validatePrivacy(privacy: boolean): boolean {
    this.userErrorSetters?.setErrorPrivacy(false);

    if (!privacy) {
      this.userErrorSetters?.setErrorPrivacy(true);
      return false;
    }

    return true;
  }

  public validateTerms(terms: boolean): boolean {
    this.userErrorSetters?.setErrorTerms(false);

    if (!terms) {
      this.userErrorSetters?.setErrorTerms(true);
      return false;
    }

    return true;
  }

  public validateConfirmPasswordInput(options: UserValidateOptions, password?: string): boolean {
    this.userErrorSetters?.setErrorConfirmPassword(false);

    if (!options.skipConfirmPassword && !this.validateConfirmPassword(password, options.confirmPassword)) {
      this.userErrorSetters?.setErrorConfirmPassword(true);
      return false;
    }

    return true;
  }

  public async validateUser(user: UserModel, options: UserValidateOptions): Promise<boolean> {
    let isValid = true;

    if (!this.validateName(user.name)) {
      isValid = false;
    }
    if (!this.validateSurname(user.surname)) {
      isValid = false;
    }
    if (!await this.validateEmail(user.email, options)) {
      isValid = false;
    }
    if (!await this.validatePhone(user.phone)) {
      isValid = false;
    }
    if (!this.validatePasswordInput(options, user.password)) {
      isValid = false;
    }
    if (!this.validatePrivacy(user.privacy)) {
      isValid = false;
    }
    if (!this.validateTerms(user.terms)) {
      isValid = false;
    }
    if (!this.validateConfirmPasswordInput(options, user.password)) {
      isValid = false;
    }

    return isValid;
  }

  public async validateOnboardingUser(user: RegisterOnboardingModel, options: UserValidateOptions): Promise<boolean> {
    let isValid = true;

    if (!await this.validateEmail(user.email, options)) {
      isValid = false;
    }
    if (!this.validatePasswordInput(options, user.password)) {
      isValid = false;
    }
    if (!this.validatePrivacy(user.privacy)) {
      isValid = false;
    }
    if (!this.validateTerms(user.terms)) {
      isValid = false;
    }
    if (!this.validateConfirmPasswordInput(options, user.password)) {
      isValid = false;
    }

    return isValid;
  }

  public validateVat(vat?: string, atecos?: string[], skipFiscalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorVat(false);
    this.dispatch && this.dispatch(setErrorVat(false))

    if (skipFiscalProfileSaving) return true;

    if (!vat || vat === '') {
      this.profileErrorSetters?.setErrorVat(true);
      this.dispatch && this.dispatch(setErrorVat(true))
      return false;
    }

    if (!!atecos && !vat) {
      this.profileErrorSetters?.setErrorVat(true);
      this.dispatch && this.dispatch(setErrorVat(true))
      return false;
    }

    if (vat && !vatRegex.test(vat)) {
      this.profileErrorSetters?.setErrorVat(true);
      this.dispatch && this.dispatch(setErrorVat(true))
      return false;
    }

    return true;
  }

  public validateVatOpeningDate(vatOpeningDate?: string, skipRegisterProfilePage?: boolean, skipFiscalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorVatOpeningDate && this.profileErrorSetters?.setErrorVatOpeningDate(false);
    this.dispatch && this.dispatch(setErrorVatOpeningDate(false))

    if (skipRegisterProfilePage || skipFiscalProfileSaving) return true;

    if (!vatOpeningDate || vatOpeningDate === '') {
      this.profileErrorSetters?.setErrorVatOpeningDate && this.profileErrorSetters?.setErrorVatOpeningDate(true);
      this.dispatch && this.dispatch(setErrorVatOpeningDate(true))
      return false;
    }

    return true;
  }

  public validateAddress(address?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorAddress(false);
    this.dispatch && this.dispatch(setErrorAddress(false))

    if (skipPersonalProfileSaving) return true;

    if (!address || address === '' || address.length > 60) {
      this.profileErrorSetters?.setErrorAddress(true);
      this.dispatch && this.dispatch(setErrorAddress(true))
      return false;
    }

    return true;
  }

  public validateCity(city?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorCity(false);
    this.dispatch && this.dispatch(setErrorCity(false))

    if (skipPersonalProfileSaving) return true;

    if (!city || city === '' || city.length > 60) {
      this.profileErrorSetters?.setErrorCity(true);
      this.dispatch && this.dispatch(setErrorCity(true))
      return false;
    }

    return true;
  }

  public validateZipCode(zipCode?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorZipCode(false);
    this.dispatch && this.dispatch(setErrorZipCode(false))

    if (skipPersonalProfileSaving) return true;

    if (!zipCode || !zipCodeRegex.test(zipCode)) {
      this.profileErrorSetters?.setErrorZipCode(true);
      this.dispatch && this.dispatch(setErrorZipCode(true))
      return false;
    }

    return true;
  }

  public validateProvince(province?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorProvince(false);
    this.dispatch && this.dispatch(setErrorProvince(false))

    if (skipPersonalProfileSaving) return true;

    if (!province || province === '') {
      this.profileErrorSetters?.setErrorProvince(true);
      this.dispatch && this.dispatch(setErrorProvince(true))
      return false;
    }

    return true;
  }


  public validateTaxCode(taxCode?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorTaxCode(false);
    this.dispatch && this.dispatch(setErrorTaxCode(false))

    if (skipPersonalProfileSaving) return true;

    if (!taxCode || !fiscalCodeRegex.test(taxCode)) {
      this.profileErrorSetters?.setErrorTaxCode(true);
      this.dispatch && this.dispatch(setErrorTaxCode(true))
      return false;
    }

    return true;
  }

  public validatePec(pec?: string, skipRegisterProfilePage?: boolean): boolean {
    this.profileErrorSetters?.setErrorPec && this.profileErrorSetters?.setErrorPec(false);
    this.dispatch && this.dispatch(setErrorPec(false))

    if (skipRegisterProfilePage) return true;

    if (pec && !emailRegex.test(pec)) {
      this.profileErrorSetters?.setErrorPec && this.profileErrorSetters?.setErrorPec(true);
      this.dispatch && this.dispatch(setErrorPec(true))
      return false;
    }

    return true;
  }

  public validateFund(fund?: string, skipFiscalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorFund(false);
    this.dispatch && this.dispatch(setErrorFund(false))

    if (skipFiscalProfileSaving) return true;

    if (!fund || fund === '') {
      this.profileErrorSetters?.setErrorFund(true);
      this.dispatch && this.dispatch(setErrorFund(true))
      return false;
    }

    return true;

  }

  public validateDateOfBirth(dateOfBirth?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorDateOfBirth && this.profileErrorSetters?.setErrorDateOfBirth(false);
    this.dispatch && this.dispatch(setErrorDateOfBirth(false))

    if (skipPersonalProfileSaving) return true;

    if (!dateOfBirth || dateOfBirth === '') {
      this.profileErrorSetters?.setErrorDateOfBirth && this.profileErrorSetters?.setErrorDateOfBirth(true);
      this.dispatch && this.dispatch(setErrorDateOfBirth(true))
      return false;
    }

    return true;
  }

  public validateBirthState(birthState?: string, birthProvince?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorBirthState && this.profileErrorSetters?.setErrorBirthState(false);
    this.dispatch && this.dispatch(setErrorBirthState(false))

    if (skipPersonalProfileSaving) return true;

    if (!birthState) {
      this.profileErrorSetters?.setErrorBirthState && this.profileErrorSetters?.setErrorBirthState(true);
      this.dispatch && this.dispatch(setErrorBirthState(true))
      return false;
    }

    if (birthState === 'Italia' && birthProvince === 'EE') {
      this.profileErrorSetters?.setErrorBirthState && this.profileErrorSetters?.setErrorBirthState(true);
      this.dispatch && this.dispatch(setErrorBirthState(true))
      return false;
    }

    return true;
  }

  public validateBirthPlace(birthPlace?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorBirthPlace && this.profileErrorSetters?.setErrorBirthPlace(false);
    this.dispatch && this.dispatch(setErrorBirthPlace(false))

    if (skipPersonalProfileSaving) return true;

    if (!birthPlace || birthPlace === '') {
      this.profileErrorSetters?.setErrorBirthPlace && this.profileErrorSetters?.setErrorBirthPlace(true);
      this.dispatch && this.dispatch(setErrorBirthPlace(true))
      return false;
    }

    return true;
  }

  public validateBirthProvince(birthProvince?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorBirthProvince && this.profileErrorSetters?.setErrorBirthProvince(false);
    this.dispatch && this.dispatch(setErrorBirthProvince(false))

    if (skipPersonalProfileSaving) return true;

    if (!birthProvince || birthProvince === '') {
      this.profileErrorSetters?.setErrorBirthProvince && this.profileErrorSetters?.setErrorBirthProvince(true);
      this.dispatch && this.dispatch(setErrorBirthProvince(true))
      return false;
    }

    return true;
  }

  public validateGender(gender?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorGender && this.profileErrorSetters?.setErrorGender(false);
    this.dispatch && this.dispatch(setErrorGender(false))

    if (skipPersonalProfileSaving) return true;

    if (!gender || gender === '') {
      this.profileErrorSetters?.setErrorGender && this.profileErrorSetters?.setErrorGender(true);
      this.dispatch && this.dispatch(setErrorGender(true))
      return false;
    }

    return true;
  }

  public validateBankName(bankName?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorBankName(false);
    this.dispatch && this.dispatch(setErrorBankName(false))

    if (skipPersonalProfileSaving) return true;

    if (!bankName || bankName === '') {
      this.profileErrorSetters?.setErrorBankName(true);
      this.dispatch && this.dispatch(setErrorBankName(true))
      return false;
    }

    return true;
  }

  public validateBankIban(bankIban?: string, skipPersonalProfileSaving?: boolean): boolean {
    this.profileErrorSetters?.setErrorBankIban(false);
    this.dispatch && this.dispatch(setErrorBankIban(false))

    if (skipPersonalProfileSaving) return true;

    if (!bankIban || bankIban === '' || !ibanRegex.test(bankIban)) {
      this.profileErrorSetters?.setErrorBankIban(true);
      this.dispatch && this.dispatch(setErrorBankIban(true))
      return false;
    }

    return true;
  }

  public validateBank(bank?: BankAccount[], skipPersonalProfileSaving?: boolean): boolean {
    let hasError = false;
    this.profileErrorSetters?.setErrorBankIban(false);
    this.dispatch && this.dispatch(setErrorBankIban(false))
    this.profileErrorSetters?.setErrorBankName(false);
    this.dispatch && this.dispatch(setErrorBankName(false))

    if (skipPersonalProfileSaving) return true;

    if (bank) {
      bank.forEach(item => {
        if (!item.name || item.name === '' || (!item.iban || !ibanRegex.test(item.iban))) {
          hasError = true;
        }
      });

      if (hasError) {
        this.profileErrorSetters?.setErrorBankIban(true);
        this.dispatch && this.dispatch(setErrorBankIban(true))
        this.profileErrorSetters?.setErrorBankName(true);
        this.dispatch && this.dispatch(setErrorBankName(true))
        return false;
      }
    }

    return true;
  }

  public validateInitialInvoiceNumber(initialInvoiceNumber?: string, skipRegisterProfilePage?: boolean): boolean {
    this.profileErrorSetters?.setErrorInitialInvoiceNumber && this.profileErrorSetters?.setErrorInitialInvoiceNumber(false);
    this.dispatch && this.dispatch(setErrorInitialInvoiceNumber(false))

    if (skipRegisterProfilePage) return true;

    if (initialInvoiceNumber && !numberRegex.test(initialInvoiceNumber)) {
      this.profileErrorSetters?.setErrorInitialInvoiceNumber && this.profileErrorSetters?.setErrorInitialInvoiceNumber(true);
      this.dispatch && this.dispatch(setErrorInitialInvoiceNumber(true))
      return false;
    }

    return true;
  }

  public validateAtecos(atecos?: string[], vat?: string): boolean {
    this.profileErrorSetters?.setErrorAtecos && this.profileErrorSetters?.setErrorAtecos(false);
    this.dispatch && this.dispatch(setErrorAteco(false))

    if (atecos?.length === 0) {
      this.profileErrorSetters?.setErrorAtecos && this.profileErrorSetters?.setErrorAtecos(true);
      this.dispatch && this.dispatch(setErrorAteco(true))
      return false;
    }

    if (!atecos && !!vat) {
      this.profileErrorSetters?.setErrorAtecos && this.profileErrorSetters?.setErrorAtecos(true);
      this.dispatch && this.dispatch(setErrorAteco(true))
      return false;
    }

    if (atecos && atecos.length > 1) {
      for (const ateco of atecos) {
        if (!ateco || ateco === 'Seleziona il codice ateco') {
          this.profileErrorSetters?.setErrorAtecos && this.profileErrorSetters?.setErrorAtecos(true);
          this.dispatch && this.dispatch(setErrorAteco(true))
          return false;
        }
      }
    }

    return true;
  }

  public validateProfile(user: UserModel, skipRegisterProfilePage?: boolean, skipFiscalProfileSaving?: boolean, skipPersonalProfileSaving?: boolean): boolean {
    let isValid = true;

    if (!this.validateVat(user.vat, user.atecos, skipFiscalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateVatOpeningDate(user.vatOpeningDate, skipRegisterProfilePage, skipFiscalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateAddress(user.address, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateCity(user.city, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateZipCode(user.zipCode, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateProvince(user.province, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateTaxCode(user.taxCode, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validatePec(user.pec, skipRegisterProfilePage)) {
      isValid = false;
    }
    if (!this.validateFund(user.fund, skipFiscalProfileSaving)) {
      isValid = false;
      this.profileErrorSetters?.setErrorFund(true);
    }
    if (!this.validateBankName(user.bankAccount[0].name, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateDateOfBirth(user.dateOfBirth, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateBirthPlace(user.birthPlace, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateBirthState(user.birthState, user.birthProvince, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateBirthProvince(user.birthProvince, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateGender(user.gender, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateBankIban(user.bankAccount[0].iban, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateBank(user.bankAccount, skipPersonalProfileSaving)) {
      isValid = false;
    }
    if (!this.validateInitialInvoiceNumber(user.initialInvoiceNumber, skipRegisterProfilePage)) {
      isValid = false;
      this.profileErrorSetters?.setErrorInitialInvoiceNumber && this.profileErrorSetters?.setErrorInitialInvoiceNumber(true);
    }
    if (!this.validateAtecos(user.atecos, user.vat)) {
      isValid = false;
      this.profileErrorSetters?.setErrorAtecos && this.profileErrorSetters?.setErrorAtecos(true);
    }

    return isValid;
  }

  public async validate(user: UserModel, options: UserValidateOptions): Promise<boolean> {
    return Promise.resolve(await this.validateUser(user, options) && this.validateProfile(user, this.skipRegisterProfilePage, options.skipFiscalProfileSaving, options.skipPersonalProfileSaving));
  }

  public validatePassword(password?: string): boolean {
    return password !== undefined && passwordRegex.test(password);
  }

  public validateConfirmPassword(password?: string, confirmPassword?: string): boolean {
    return password === confirmPassword;
  }

}
