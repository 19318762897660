import './taxReturnSectionNotPresent.css';

export function TaxReturnSectionNotPresent() {
  return (
    <div className={'row'}>
      <div className="col-12">
        <div className={'row'}>
          <div className="col-12 tax-return-section-not-present-title">
            Hey!
          </div>
        </div>
        <div className={'row my-4'}>
          <div className="col-12 tax-return-section-not-present-sub-title">
            Non ci sono dichiarazioni dei redditi salvate
            <br />
            all’interno di Quickfisco.
          </div>
        </div>
        <div className={'row'}>
          <div className="col-12 tax-return-section-not-present-text">
            Se pensi ci sia stato un errore o hai bisogno di aiuto scrivici su
            Whatsapp.
          </div>
        </div>
      </div>
    </div>
  );
}
