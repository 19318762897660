import { isValid } from 'date-fns';
import { FinanceCheckFinanceabilityErrorSetters, FinanceInformationsErrorSetters } from '../models/financeErrorSetters';
import { FinanceSimulationModel, FinanceUserModel, FinancingModel, PaymentPreferenceEnum } from '../models/financeModel';
import { ibanRegex } from '../regex/regex';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class FinanceService {

  private readonly url = host + '/api/v1/finance/faire';

  private readonly http: Http;
  private readonly financeCheckFinanceabilityErrorSetters?: FinanceCheckFinanceabilityErrorSetters;
  private readonly financeInformationsErrorSetters?: FinanceInformationsErrorSetters;

  public constructor(financeCheckFinanceabilityErrorSetters?: FinanceCheckFinanceabilityErrorSetters, financeInformationsErrorSetters?: FinanceInformationsErrorSetters) {
    this.http = Http.getInstance();
    this.financeCheckFinanceabilityErrorSetters = financeCheckFinanceabilityErrorSetters;
    this.financeInformationsErrorSetters = financeInformationsErrorSetters
  }

  public findUserFinance(): Promise<FinanceUserModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/user'
    })
      .then(res => res.json())
      .then((data: FinanceUserModel) => data);
  }

  public financeSimulation(value: number): Promise<FinanceSimulationModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/simulation?amountValue=' + value
    })
      .then(res => res.json())
      .then((data: FinanceSimulationModel) => data);
  }

  public sendFinanceUser(value: FinanceUserModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/send',
      body: JSON.stringify(value),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public sendEmail(): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/user/income-request-email',
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public checkFinanceability(user: FinanceUserModel): FinancingModel {
    if (user.data.income < 10000) {
      return {
        financeability: false,
        min: 0,
        max: 0
      }
    } else {
      let mortageAmount = user.data.mortgage_or_rental ? (user.data.mortgage_or_rental_amount || 0) : 0
      let financingTotal = user.data.financing_ongoing ? (user.data.financing_ongoing_total || 0) : 0
      const netIncome = (user.data.income / 12) - mortageAmount - financingTotal
      const max = netIncome * 15 / 100 * 90
      if (netIncome <= 0) {
        return {
          financeability: false,
          min: 0,
          max: 0
        }
      } else if (max > 35000) {
        return {
          financeability: true,
          min: 2000,
          max: 35000
        }
      }
      return {
        financeability: true,
        min: 2000,
        max: max - (max % 1000)
      }
    }
  }

  public validateMortage(mortage: boolean, mortageAmount?: number): boolean {
    this.financeCheckFinanceabilityErrorSetters?.setErrorMortage(false);

    if (mortage && (mortageAmount === undefined || mortageAmount <= 0)) {
      this.financeCheckFinanceabilityErrorSetters?.setErrorMortage(true);
      return false;
    }

    return true;
  }

  public validateFinancing(fundings: boolean, fundingsTotal?: number): boolean {
    this.financeCheckFinanceabilityErrorSetters?.setErrorFinancing(false);

    if (fundings && (fundingsTotal === undefined || fundingsTotal <= 0)) {
      this.financeCheckFinanceabilityErrorSetters?.setErrorFinancing(true);
      return false;
    }

    return true;
  }
  public validateCheckFinanceability(user: FinanceUserModel): boolean {
    let isValid = true;

    if (!this.validateMortage(user.data.mortgage_or_rental, user.data.mortgage_or_rental_amount)) {
      isValid = false;
    }
    if (!this.validateFinancing(user.data.financing_ongoing, user.data.financing_ongoing_total)) {
      isValid = false;
    }

    return isValid;
  }

  public validateBirthDate(birthDate?: Date): boolean {
    this.financeInformationsErrorSetters?.setErrorBirthDate(false);

    if (!birthDate || !isValid(new Date(birthDate))) {
      this.financeInformationsErrorSetters?.setErrorBirthDate(true);
      return false;
    }

    return true;
  }

  public validateCitizenship(citizenship: string): boolean {
    this.financeInformationsErrorSetters?.setErrorCitizenship(false);

    if (!citizenship || citizenship === '') {
      this.financeInformationsErrorSetters?.setErrorCitizenship(true);
      return false;
    }

    return true;
  }

  public validateCivilStatus(civilStatus: string): boolean {
    this.financeInformationsErrorSetters?.setErrorCivilStatus(false);

    if (!civilStatus || civilStatus === '') {
      this.financeInformationsErrorSetters?.setErrorCivilStatus(true);
      return false;
    }

    return true;
  }

  public validateFamilyUnit(familyUnit?: number): boolean {
    this.financeInformationsErrorSetters?.setErrorFamilyUnit(false);

    if (!familyUnit) {
      this.financeInformationsErrorSetters?.setErrorFamilyUnit(true);
      return false;
    }

    return true;
  }

  public validateLoanReason(loanReason: string): boolean {
    this.financeInformationsErrorSetters?.setErrorLoanReason(false);

    if (!loanReason || loanReason === '') {
      this.financeInformationsErrorSetters?.setErrorLoanReason(true);
      return false;
    }

    return true;
  }

  public validateAddress(address: string): boolean {
    this.financeInformationsErrorSetters?.setErrorAddress(false);

    if (!address || address === '') {
      this.financeInformationsErrorSetters?.setErrorAddress(true);
      return false;
    }

    return true;
  }

  public validateCap(cap: string): boolean {
    this.financeInformationsErrorSetters?.setErrorCAP(false);

    if (!cap || cap === '') {
      this.financeInformationsErrorSetters?.setErrorCAP(true);
      return false;
    }

    return true;
  }

  public validateProvince(province?: string): boolean {
    this.financeInformationsErrorSetters?.setErrorProvince(false);

    if (!province || province === '') {
      this.financeInformationsErrorSetters?.setErrorProvince(true);
      return false;
    }

    return true;
  }

  public validateCity(city: string): boolean {
    this.financeInformationsErrorSetters?.setErrorCity(false);

    if (!city || city === '') {
      this.financeInformationsErrorSetters?.setErrorCity(true);
      return false;
    }

    return true;
  }

  public validateRegion(region: string): boolean {
    this.financeInformationsErrorSetters?.setErrorRegion(false);

    if (!region || region === '') {
      this.financeInformationsErrorSetters?.setErrorRegion(true);
      return false;
    }

    return true;
  }

  public validateDomicileAddress(sameAsDomicile: boolean, address: string | null): boolean {
    this.financeInformationsErrorSetters?.setErrorDomicileAddress(false);

    if (!sameAsDomicile && (!address || address === '' || address === null)) {
      this.financeInformationsErrorSetters?.setErrorDomicileAddress(true);
      return false;
    }

    return true;
  }

  public validateDomicileCap(sameAsDomicile: boolean, cap: string | null): boolean {
    this.financeInformationsErrorSetters?.setErrorDomicileCAP(false);

    if (!sameAsDomicile && (!cap || cap === '' || cap === null)) {
      this.financeInformationsErrorSetters?.setErrorDomicileCAP(true);
      return false;
    }

    return true;
  }

  public validateDomicileProvince(sameAsDomicile: boolean, province?: string | null): boolean {
    this.financeInformationsErrorSetters?.setErrorDomicileProvince(false);

    if (!sameAsDomicile && (!province || province === '' || province === null)) {
      this.financeInformationsErrorSetters?.setErrorDomicileProvince(true);
      return false;
    }

    return true;
  }

  public validateDomicileCity(sameAsDomicile: boolean, city: string | null): boolean {
    this.financeInformationsErrorSetters?.setErrorDomicileCity(false);

    if (!sameAsDomicile && (!city || city === '' || city === null)) {
      this.financeInformationsErrorSetters?.setErrorDomicileCity(true);
      return false;
    }

    return true;
  }

  public validateDomicileRegion(sameAsDomicile: boolean, region: string | null): boolean {
    this.financeInformationsErrorSetters?.setErrorDomicileRegion(false);

    if (!sameAsDomicile && (!region || region === '' || region === null)) {
      this.financeInformationsErrorSetters?.setErrorDomicileRegion(true);
      return false;
    }

    return true;
  }

  public validateResidenceDate(residenceDate?: Date): boolean {
    this.financeInformationsErrorSetters?.setErrorResidenceDate(false);

    if (!residenceDate || !isValid(new Date(residenceDate))) {
      this.financeInformationsErrorSetters?.setErrorResidenceDate(true);
      return false;
    }

    return true;
  }

  public validateBankName(bankName: string): boolean {
    this.financeInformationsErrorSetters?.setErrorBankName(false);

    if (!bankName || bankName === '') {
      this.financeInformationsErrorSetters?.setErrorBankName(true);
      return false;
    }

    return true;
  }

  public validateBankIban(bankIban: string): boolean {
    this.financeInformationsErrorSetters?.setErrorBankIban(false);

    if (!bankIban || bankIban === '' || !ibanRegex.test(bankIban)) {
      this.financeInformationsErrorSetters?.setErrorBankIban(true);
      return false;
    }

    return true;
  }

  public validatePaymentPreference(paymentPreference?: PaymentPreferenceEnum): boolean {
    this.financeInformationsErrorSetters?.setErrorPreferencePaymentType(false);

    if (!paymentPreference) {
      this.financeInformationsErrorSetters?.setErrorPreferencePaymentType(true);
      return false;
    }

    return true;
  }

  public validateInformations(user: FinanceUserModel): boolean {
    let isValid = true;

    if (!this.validateBirthDate(user.data.birth_date)) {
      isValid = false;
    }
    if (!this.validateCitizenship(user.data.citizenship)) {
      isValid = false;
    }
    if (!this.validateCivilStatus(user.data.civil_status)) {
      isValid = false;
    }
    if (!this.validateFamilyUnit(user.data.family_unit)) {
      isValid = false;
    }
    if (!this.validateLoanReason(user.data.loan_reason)) {
      isValid = false;
    }
    if (!this.validateAddress(user.data.address)) {
      isValid = false;
    }
    if (!this.validateCap(user.data.postal_code)) {
      isValid = false;
    }
    if (!this.validateProvince(user.data.province)) {
      isValid = false;
    }
    if (!this.validateCity(user.data.city)) {
      isValid = false;
    }
    if (!this.validateRegion(user.data.region)) {
      isValid = false;
    }
    if (!this.validateDomicileAddress(user.data.same_as_domicile, user.data.domicile_address)) {
      isValid = false;
    }
    if (!this.validateDomicileCap(user.data.same_as_domicile, user.data.domicile_postal_code)) {
      isValid = false;
    }
    if (!this.validateDomicileProvince(user.data.same_as_domicile, user.data.domicile_province)) {
      isValid = false;
    }
    if (!this.validateDomicileCity(user.data.same_as_domicile, user.data.domicile_city)) {
      isValid = false;
    }
    if (!this.validateDomicileRegion(user.data.same_as_domicile, user.data.domicile_region)) {
      isValid = false;
    }
    if (!this.validateResidenceDate(user.data.residence_start_date)) {
      isValid = false;
    }
    if (!this.validateBankName(user.data.bank_name)) {
      isValid = false;
    }
    if (!this.validateBankIban(user.data.iban)) {
      isValid = false;
    }
    if (!this.validatePaymentPreference(user.data.payment_preference)) {
      isValid = false;
    }

    return isValid;
  }

}
