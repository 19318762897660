import config from '../../../config.json';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './financeRefused.css';

export function FinanceRefused() {
  return (
    <div className={'financeRefused-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 35,
          maxWidth: '60%',
          height: '100%',
        }}
      >
        <span
          style={{
            textAlign: 'left',
            font: 'normal normal 600 20px/30px Poppins',
            color: '#1E1E23',
          }}
        >
          2. Scegli se avviare la pratica di richiesta
        </span>
        <QuickfiscoIcon name={'refused.svg'} className="financeRefused-icon" />
        <span className="financeRefused-text">
          Abbiamo verificato i requisiti di partenza,
          <br />
          il tuo profilo <b>non è idoneo</b> alla richiesta di un finanziamento.
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 20,
          }}
        >
          <span className="financeRefused-text--blue">
            PERCHÈ IL MIO PROFILO NON È IDONEO?
          </span>
          <span className="financeRefused-text--black">
            I motivi possono essere diversi, tra i più comuni:
          </span>
          <div
            className="financeRefused-text--black p-0 m-0"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0,
              textAlign: 'left',
            }}
          >
            <span>
              • Il tuo reddito mensile non soddisfa i parametri richiesti
              dall’istituto di credito
            </span>
            <span>
              • Il tuo reddito complessivo annuale è inferiore ai 10.000,00€
            </span>
            <span>
              • I tuoi finanziamenti attivi o mutui/affitti sono, in
              proporzione, molto alti rispetto al tuo reddito netto mensile
            </span>
          </div>
        </div>
        <div className="financeRefused-text--gray-container px-3 py-2">
          Per saperne di più sul motivo di rigetto, scrivici una mail a{' '}
          <a
            className="financeRefused-link"
            href="mailto:finanziamenti@quickfisco.it"
            target={'_blank'}
            rel={'noreferrer'}
          >
            finaziamenti@quickfisco.it
          </a>{' '}
          indicando il tuo nome e cognome e n° p.iva nel corpo mail.
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
    </div>
  );
}
