import { FeesRegisterModel } from '../models/feesRegisterModel';
import { TotalRevenuesModel } from '../models/totalRevenuesModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class FeesRegisterService {

  private readonly url = host + '/api/v1/fees-register/';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public findAll(
    year: string,
    month: string
  ): Promise<FeesRegisterModel[]> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '?month=' + month + '&year=' + year
    })
      .then(res => res.json())
      .then((data: FeesRegisterModel[]) => data);
  }

  public findById(id: string): Promise<FeesRegisterModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + id
    })
      .then(res => res.json())
      .then((data: FeesRegisterModel) => data);
  }

  public edit(request: FeesRegisterModel, id?: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + id,
      body: JSON.stringify(request),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public delete(id?: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'delete',
      url: this.url + id,
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public save(request: FeesRegisterModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url,
      body: JSON.stringify(request),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public getTotalRevenues(year: number): Promise<TotalRevenuesModel> {
    return this.http.sendAndReceive(({
      method: 'get',
      url: this.url + 'totalRevenues?year=' + year
    }))
      .then((res) => res.json())
      .then((data: TotalRevenuesModel) => data);
  }

  public static calculateFeesRegisterTotalAmount(feesRegisterList: FeesRegisterModel[]): {
    totalAmount: number,
    amountIT: number,
    amountEU: number,
    amountEXTRAEU: number
  } {
    let totalAmount = 0;
    let amountIT = 0;
    let amountEU = 0;
    let amountEXTRAEU = 0;

    feesRegisterList.forEach((fee) => {
      if (fee.amount) {
        totalAmount += fee.amount;
      }
      if (fee.amount_IT) {
        amountIT += fee.amount_IT;
      }
      if (fee.amount_EU) {
        amountEU += fee.amount_EU;
      }
      if (fee.amount_EXTRA_EU) {
        amountEXTRAEU += fee.amount_EXTRA_EU
      }
    })

    return {
      totalAmount,
      amountIT,
      amountEU,
      amountEXTRAEU
    }
  }

}
