import { InvoiceStatusType } from '../../models/invoiceModel';
import { OnChange } from '../../types/functions';
import { AlreadyTransmittedInfo } from '../alreadyTransmittedInfo/alreadyTransmittedInfo';
import lang from '../customerInvoiceSaveOrDuplicateOrEdit/customerInvoiceSaveOrdDuplicateOrEdit.json';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';

interface Props {
  id: string;
  value?: InvoiceStatusType;
  disabled?: boolean;
  onChange?: OnChange;
}

export function AlreadyTransmittedCheckbox(props: Props) {
  const { id } = props;
  const { value } = props;
  const { disabled } = props;
  const { onChange } = props;

  return (
    <div className={'row'}>
      <div className={'col-auto'}>
        <QuickfiscoCheckbox
          id={id}
          disabled={disabled}
          label={lang.alreadyTrasmittedLabel}
          checked={value === InvoiceStatusType.ALREADY_TRANSMITTED}
          onChange={(e) => onChange && onChange(e)}
        />
      </div>
      <div className={'col-auto'}>
        <AlreadyTransmittedInfo />
      </div>
    </div>
  );
}
