import { doLogout } from '../redux/slices/loginSlice';

interface HttpRequest {
  url: string;
  method: string;
  headers?: Headers;
  body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array>;
  credentials?: RequestCredentials;
}

export class Http {

  private static instance: Http;

  public static getInstance(dispatch?: Function): Http {
    if (Http.instance == null) {
      if (dispatch == null) {
        throw new TypeError('dispatch cannot be null');
      }
      Http.instance = new Http(dispatch);
    }
    return Http.instance;
  }

  private readonly dispatch: Function;

  private constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  private base(request: HttpRequest): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      fetch(request.url, {
        method: request.method,
        headers: request.headers,
        credentials: request.credentials,
        body: request.body,
      })
        .then(res => {
          if (res.status > 399) {
            reject(res);
          } else {
            resolve(res);
          }
        })
        .catch(err => reject(err));
    });
  }

  private handleAuth(request: HttpRequest): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      this.base(request)
        .then(res => resolve(res))
        .catch(err => {
          if (err.status === 401) {
            // TODO refresh token
            this.base(request)
              .then(res => resolve(res))
              .catch(err => {
                if (err.status === 401) {
                  this.dispatch(doLogout());
                }
                reject(err);
              });
          } else {
            reject(err);
          }
        });
    });
  }

  public sendAndReceive(request: HttpRequest): Promise<Response> {
    return this.handleAuth(request);
  }

}
