export function setItem(key: string, value: string): void {
  window.localStorage.setItem(key, value);
}

export function getItem(key: string): string | null {
  return window.localStorage.getItem(key);
}

export function delItem(key: string): void {
  window.localStorage.removeItem(key);
}

export function setItemWithExipiration(key: string, obj: { value: string, expireTime: number }): void {
  window.localStorage.setItem(key, JSON.stringify(obj));
}

export function getItemWithExipiration(key: string): string | null {
  let result: string | null = window.localStorage.getItem(key);

  if (result) {
    const JsonResult: { value: string, expireTime: number } = JSON.parse(result);

    if (JsonResult.expireTime <= Date.now()) {
      delItem(key);
      return null;
    }

    return JsonResult.value;
  }

  return null;

}