import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StsProfileModel } from '../../models/stsProfileModel';
import { StsProfileService } from '../../services/stsProfileService';
import { PromiseStatuses } from '../../types/strings';

interface StsProfileState {
  httpCode?: string;
  status: PromiseStatuses;
  profile?: StsProfileModel;
}

const initialState: StsProfileState = {
  status: 'idle'
};

export const getStsProfile = createAsyncThunk(
  'stsProfile/getProfile',
  async (): Promise<StsProfileModel | undefined> => {
    const service = new StsProfileService();
    return service.get();
  }
);

const stsProfileSlice = createSlice({
  name: 'stsProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStsProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStsProfile.fulfilled, (state, action) => {
        state.status = 'successfully';
        state.httpCode = '200';
        state.profile = action.payload;
      })
      .addCase(getStsProfile.rejected, ((state, action) => {
        state.status = 'failed';
        state.httpCode = action.error.message;
      }));
  }
});

export default stsProfileSlice.reducer;
