import { ObjectModel } from '../models/objectModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class ObjectService {

  private readonly url = host + '/api/v1/objects';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public findById(id: string): Promise<ObjectModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + id
    })
      .then(res => res.json())
      .then((data: ObjectModel) => data);
  }

  public findFile(id: string): Promise<ArrayBuffer> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + id + '/file'
    })
      .then(data => data.arrayBuffer());
  }

  public getFileUrl(id: string): string {
    return this.url + '/' + id + '/file'
  }

  public save(object: ObjectModel): Promise<ObjectModel> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url,
      body: JSON.stringify(object),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: ObjectModel) => data);
  }

  public del(id: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'delete',
      url: this.url + '/' + id
    })
      .then(() => undefined);
  }

}
