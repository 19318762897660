import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { ExpenseType } from '../../models/stsProfileModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './expenseTypeSelect.json';

interface Props {
  id: string;
  onChange?: ((expenseType?: ExpenseType) => void);
  value?: ExpenseType;
  disabled?: boolean;
  error?: boolean;
  errorLabel?: string;
}

export function ExpenseTypeSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { value } = props;
  const { disabled } = props;
  const { error } = props;
  const { errorLabel } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.title}
      options={getOptions(value)}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(ExpenseType[e.value])
        }
      }}
      disabled={disabled}
      styleType={'default'}
      error={error}
      errorLabel={errorLabel}
      requiredField={true}
      value={getOptions(value).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: ExpenseType): OptionModel[] {
  return [
    {
      label: 'Seleziona il tipo di spesa',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: lang.types.TK,
      value: ExpenseType.TK,
      selected: value === ExpenseType.TK
    },
    {
      label: lang.types.FC,
      value: ExpenseType.FC,
      selected: value === ExpenseType.FC
    },
    {
      label: lang.types.FV,
      value: ExpenseType.FV,
      selected: value === ExpenseType.FV
    },
    {
      label: lang.types.SV,
      value: ExpenseType.SV,
      selected: value === ExpenseType.SV
    },
    {
      label: lang.types.SP,
      value: ExpenseType.SP,
      selected: value === ExpenseType.SP
    },
    {
      label: lang.types.AD,
      value: ExpenseType.AD,
      selected: value === ExpenseType.AD
    },
    {
      label: lang.types.AS,
      value: ExpenseType.AS,
      selected: value === ExpenseType.AS
    },
    {
      label: lang.types.SR,
      value: ExpenseType.SR,
      selected: value === ExpenseType.SR
    },
    {
      label: lang.types.CT,
      value: ExpenseType.CT,
      selected: value === ExpenseType.CT
    },
    {
      label: lang.types.PI,
      value: ExpenseType.PI,
      selected: value === ExpenseType.PI
    },
    {
      label: lang.types.IC,
      value: ExpenseType.IC,
      selected: value === ExpenseType.IC
    },
    {
      label: lang.types.AA,
      value: ExpenseType.AA,
      selected: value === ExpenseType.AA
    },
  ];
}
