import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel } from '../../models/contactModel';
import { CurrencyType, GoodServicesModel, InvoiceModel, InvoiceStatusType, InvoiceType, PaymentTermsType } from '../../models/invoiceModel';

interface AutoInvoiceState {
  fileList: File[];
  invoice: InvoiceModel;
}

const initialState: AutoInvoiceState = {
  fileList: [],
  invoice: {
    date: new Date(),
    type: InvoiceType.TD16,
    status: InvoiceStatusType.DRAFT,
    paymentExpiration: new Date(),
    currency: CurrencyType.EUR,
    stamp: false,
    amount: 0,
    taxBase: 0,
    goodServices: [],
    socialContribution: false,
    socialContributionAmount: 0,
    reimbursementsOfExpenses: [],
    reimbursementsOfExpensesAmount: 0,
    manual: false,
    isPaymentDue: false,
    trackedPayment: false,
    customerDataCommunication: false,
    payments: [],
    documents: [],
    additionalDatasPa: []
  }
};

const autoInvoiceSlice = createSlice({
  name: "autoInvoice",
  initialState,
  reducers: {
    setAutoInvoiceFileList: (state, action: PayloadAction<File[]>) => {
      state.fileList = action.payload;
    },
    setAutoInvoiceSupplier: (state, action: PayloadAction<ContactModel | undefined>) => {
      state.invoice.supplier = action.payload
    },
    setAutoInvoiceStatus: (state, action: PayloadAction<InvoiceStatusType>) => {
      state.invoice.status = action.payload
    },
    setAutoInvoiceDocumentType: (state, action: PayloadAction<InvoiceType | undefined>) => {
      state.invoice.type = action.payload
    },
    setAutoInvoiceDate: (state, action: PayloadAction<Date>) => {
      state.invoice.date = action.payload
    },
    setAutoInvoicePaymentExpiration: (state, action: PayloadAction<Date>) => {
      state.invoice.paymentExpiration = action.payload
    },
    setAutoInvoiceNotes: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.notes = action.payload
    },
    setAutoInvoiceGoodServices: (state, action: PayloadAction<GoodServicesModel[]>) => {
      state.invoice.goodServices = action.payload
    },
    setAutoInvoice: (state, action: PayloadAction<InvoiceModel>) => {
      state.invoice = action.payload
    },
    setAutoInvoicePaymentTerms: (state, action: PayloadAction<PaymentTermsType | undefined>) => {
      state.invoice.paymentTerms = action.payload
    },
    setAutoInvoiceAteco: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.ateco = action.payload
    },
    setAutoInvoiceTaxBase: (state, action: PayloadAction<number>) => {
      state.invoice.taxBase = action.payload
    }
  }
});

export const {
  setAutoInvoiceFileList,
  setAutoInvoiceDate,
  setAutoInvoiceDocumentType,
  setAutoInvoiceGoodServices,
  setAutoInvoiceStatus,
  setAutoInvoiceNotes,
  setAutoInvoicePaymentExpiration,
  setAutoInvoiceSupplier,
  setAutoInvoice,
  setAutoInvoicePaymentTerms,
  setAutoInvoiceAteco,
  setAutoInvoiceTaxBase
} = autoInvoiceSlice.actions;

export default autoInvoiceSlice.reducer;
