import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../../config.json';
import { InvoiceFilters, StsInvoiceStatusFilterType } from '../../models/invoiceFilters';
import { InvoiceModel } from '../../models/invoiceModel';
import { PaginationModel } from '../../models/paginationModel';
import { StsInvoiceReportModel } from '../../models/stsInvoiceReportReportModel';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { PromiseStatuses } from '../../types/strings';

interface StsInvoiceListState {
  status: PromiseStatuses;
  invoices: PaginationModel<InvoiceModel>;
  reports: StsInvoiceReportModel;
  reportsStatus: PromiseStatuses;
  page: number;
  companyNameFilter?: string;
  statusFilter?: StsInvoiceStatusFilterType;
  yearFilter: string;
  monthFilter?: string;
}

const initialState: StsInvoiceListState = {
  status: 'idle',
  invoices: { content: [] },
  reports: {
    toSend: 0,
    total: 0,
    rejected: 0,
    pending: 0,
    sent: 0
  },
  reportsStatus: 'idle',
  page: 0,
  yearFilter: new Date().getFullYear().toString()
};

export const getStsInvoiceList = createAsyncThunk(
  'stsInvoiceList/getStsInvoiceList',
  async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {
    return new StsInvoiceService().findAll(request);
  }
);

export const getStsInvoiceReports = createAsyncThunk(
  'stsInvoiceList/getStsInvoiceReports',
  async (request: string): Promise<StsInvoiceReportModel> => {
    return new StsInvoiceService().getStsReports(request);
  }
);

const stsInvoiceListSlice = createSlice({
  name: 'stsInvoiceList',
  initialState,
  reducers: {
    setStsInvoiceListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setStsInvoiceListCompanyNameFilter: (state, action: PayloadAction<string | undefined>) => {
      state.companyNameFilter = action.payload;
    },
    setStsInvoiceListStatusFilter: (state, action: PayloadAction<StsInvoiceStatusFilterType | undefined>) => {
      state.statusFilter = action.payload;
    },
    setStsInvoiceListYearFilter: (state, action: PayloadAction<string>) => {
      state.yearFilter = action.payload;
    },
    setStsInvoiceListMonthFilter: (state, action: PayloadAction<string | undefined>) => {
      state.monthFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStsInvoiceList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStsInvoiceList.fulfilled, (state, action) => {
        let stsInvoices = CommonInvoiceService.filter(
          'sts',
          {
            page: state.page,
            month: state.monthFilter,
            year: state.yearFilter,
            companyName: state.companyNameFilter,
            stsStatus: state.statusFilter
          },
          action.payload
        );

        stsInvoices = CommonInvoiceService.sort(stsInvoices);

        stsInvoices = CommonInvoiceService.paginate(state.page, config.pageSize, stsInvoices);

        state.status = 'successfully';
        state.invoices = stsInvoices
      })
      .addCase(getStsInvoiceList.rejected, ((state) => {
        state.status = 'failed';
      }))
      .addCase(getStsInvoiceReports.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(getStsInvoiceReports.fulfilled, (state, action) => {
        state.reportsStatus = 'idle';
        state.reports = action.payload;
      })
      .addCase(getStsInvoiceReports.rejected, ((state) => {
        state.reportsStatus = 'failed';
      }));
  },
});

export const {
  setStsInvoiceListPage,
  setStsInvoiceListCompanyNameFilter,
  setStsInvoiceListStatusFilter,
  setStsInvoiceListYearFilter,
  setStsInvoiceListMonthFilter
} = stsInvoiceListSlice.actions;

export default stsInvoiceListSlice.reducer;
