import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './invoiceConfirmImportModal.json';
import './invoiceConfirmImportModal.css';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  status: PromiseStatuses;
  importInvoices: () => void;
  type: "customer" | "sts"
}

export function InvoiceConfirmImportModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { status } = props;
  const { importInvoices } = props;
  const { type } = props;

  return (
    <div className={'row mt-4'}>
      <QuickfiscoModal
        isOpen={open}
        hide={() => setOpen(false)}
        modalClassName={'invoice-import-modal'}
      >
        <div className="px-5">
          <div className={'row'}>
            <div className={'col-12 text-center'}>
              <QuickfiscoIcon
                name={'alert-yellow-full.svg'}
                className={'ps-2 mb-4'}
              />
              <div className="invoice-confirm-import-modal-title">
                {lang.title}
              </div>
              <div className="invoice-confirm-import-modal-subtitle mb-4">
                {lang.subTitle}
              </div>
              {type === "sts" ?
                <div className="invoice-confirm-import-modal-text mb-4">
                  Se hai più di un <b>codice ATECO</b> seleziona quello corretto e relativo alla fattura emessa.
                </div> :
                <div className="invoice-confirm-import-modal-text mb-4">
                  Se hai più di un <b>codice ATECO</b> seleziona quello corretto e relativo alla fattura <br /> emessa. Lo <b>stato di incasso</b> potrai modificarlo anche in seguito.
                </div>}
            </div>
          </div>

          <div className={'row my-4'}>
            <div className={'col-4 offset-1'}>
              <QuickfiscoButton
                id={'invoice-confirm-import-modal-close'}
                type={'primary'}
                label={lang.closeModal}
                onClick={() => setOpen(false)}
              />
            </div>
            <div className={'col-6'}>
              {status === 'loading' ? (
                <div
                  className={
                    'd-flex justify-content-center align-items-center w-100'
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <QuickfiscoButton
                  id={'invoice-confirm-import-modal-import-btn'}
                  type={'secondary'}
                  label={lang.importBtn}
                  onClick={() => importInvoices()}
                />
              )}
            </div>
          </div>
          {status === 'failed' && (
            <div className="invoice-confirm-import-modal-error mb-4">
              {lang.importError}
            </div>
          )}
        </div>
      </QuickfiscoModal>
    </div>
  );
}
