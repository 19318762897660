import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { UserService } from '../../services/userService';
import { getCrmUserData } from '../../redux/slices/onboardingSlice';
import { getItem, setItem } from '../../utils/localStorage';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { LoginOnboardingComponent } from '../../components/loginOnboardingComponent/loginOnboardingComponent';
import { setEmail } from '../../redux/slices/loginSlice';
import { RegisterOnboardingComponent } from '../../components/registerOnboardingComponent/registerOnboardingComponent';
import { PromiseStatuses } from '../../types/strings';
import lang from './loginOnboarding.json';
import './loginOnboarding.css';

export function LoginOnboarding() {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('id');

  const [emailExist, setEmailExist] = useState<boolean>(false);
  const [statusEmailExist, setStatusEmailExist] =
    useState<PromiseStatuses>('idle');

  const user = useAppSelector((state) => state.onboarding.user);
  const statusCrm = useAppSelector(
    (state) => state.onboarding.statusCrmUserData
  );
  const status = useAppSelector((state) => state.login.status);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user.email) {
      if (userId) {
        dispatch(getCrmUserData(userId));
        setItem('userIdCrm', userId);
      } else if (getItem('userIdCrm')) {
        dispatch(getCrmUserData(getItem('userIdCrm') as string));
      }
    } else {
      dispatch(setEmail(user.email));

      const registerService = new UserService();
      setStatusEmailExist('loading');
      registerService
        .emailAvailable(user.email)
        .then((res) => {
          setEmailExist(res);
          setStatusEmailExist('successfully');
        })
        .catch((err) => {
          setStatusEmailExist('failed');
          console.error(err);
        });
    }
  }, [user.email]);

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (statusCrm === 'loading' || statusEmailExist === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (
    statusCrm === 'failed' ||
    statusEmailExist === 'failed' ||
    (!userId && !getItem('userIdCrm'))
  ) {
    return <OnboardingApiError statusFailed={statusCrm === 'failed'} />;
  }

  return (
    <div className="row full-screen mr-0 bg-blue">
      <OnboardingPopoverHelp />
      <div className="col-12">
        <div className="row d-flex align-items-center mt-4 mb-3">
          <div className="col-2">
            <QuickfiscoIcon
              name={'logo-onboarding.svg'}
              className="ms-4 d-none d-md-block"
            />
          </div>
          <div className="col-8 text-center welcome-onboarding-main-title">
            01.{' '}
            <span>
              {emailExist === true ? lang.registerTitle : lang.loginTitle}
            </span>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-11 col-md-5">
            <div className="login-onboarding-container py-5 my-2">
              <div className="row">
                <div className="col-10 offset-1">
                  {emailExist === true &&
                  statusEmailExist === 'successfully' ? (
                    <RegisterOnboardingComponent
                      crmUserId={getItem('userIdCrm') || userId}
                    />
                  ) : (
                    <LoginOnboardingComponent
                      crmUserId={getItem('userIdCrm') || userId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
