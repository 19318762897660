import { useEffect } from 'react';
import { InvoiceFilters } from '../../components/invoiceFilters/invoiceFilters';
import { InvoiceList } from '../../components/invoiceList/invoiceList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { QuickfiscoSuccess } from '../../components/quickfiscoSuccess/quickfiscoSuccess';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCustomerInvoiceList, setCustomerInvoiceListPage } from '../../redux/slices/customerInvoiceListSlice';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import lang from './creditSlipInvoiceList.json';
import { useProSidebar } from 'react-pro-sidebar';
import { InvoiceType } from '../../models/invoiceModel';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';

export function CreditSlipInvoiceList() {
  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const operationStatusState = useAppSelector(state => state.operationStatus);
  const userState = useAppSelector((state) => state.user.user);

  const customerInvoices = customerInvoiceListState.invoices
  const globalStatus = operationStatusState.status

  const dispatch = useAppDispatch();
  const newInvoice = useNewInvoice('creditSlip')

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (customerInvoiceListState.page === undefined) {
      dispatch(setCustomerInvoiceListPage(0))
    }
  }, [])

  useEffect(() => {
    dispatch(getCustomerInvoiceList({
      page: customerInvoiceListState.page,
      month: customerInvoiceListState.monthFilter,
      year: customerInvoiceListState.yearFilter,
      companyName: customerInvoiceListState.companyNameFilter,
      type: InvoiceType.TD04
    }))
  }, [
    customerInvoiceListState.page,
    customerInvoiceListState.monthFilter,
    customerInvoiceListState.yearFilter,
    customerInvoiceListState.companyNameFilter,
  ])

  useEffect(() => {
    if (customerInvoiceListState.page !== undefined && customerInvoiceListState.page > 0 && customerInvoiceListState.invoices.content.length === 0) {
      dispatch(setCustomerInvoiceListPage(customerInvoiceListState.page - 1))
    }
  }, [customerInvoiceListState.invoices.content])

  return (
    <div className="full-screen bg-blue">
      <QuickfiscoSuccess
        message={lang.sendSuccess}
        active={globalStatus === 'successfully'}
        close={() =>
          dispatch(setGlobalStatus({
            status: 'idle'
          }))
        }
      />
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='customer-invoice-list-menu'
            menuItem={MenuItems.CREDIT_SLIP}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.title} />
              {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-11'}>
              <InvoiceFilters invoiceSign={'creditSlip'} />
            </div>
            <div className={'col-1 d-flex justify-content-end'}>
              <QuickfiscoIcon
                name={'new.svg'}
                className={'w-f-35'}
                isButton={true}
                onClick={() => newInvoice()}
              />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <InvoiceList invoiceSign={'creditSlip'} />
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <div className={'d-flex justify-content-center'}>
                <QuickfiscoPagination
                  totalPages={customerInvoices.totalPages}
                  number={customerInvoices.number}
                  empty={customerInvoices.empty}
                  doPagination={(page: number) => dispatch(setCustomerInvoiceListPage(page))}
                  first={customerInvoices.first}
                  last={customerInvoices.last}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
