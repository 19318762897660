import { useAppSelector } from '../../redux/hooks';
import {
  SurveyStatusTaxReturnModel,
  SurveyStatusTypeTaxReturnModel,
} from '../../models/taxReturnModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { OptionModel } from '../../models/optionModel';
import { SingleValue } from 'react-select';
import { TaxReturnStatusBox } from '../taxReturnStatusBox/taxReturnStatusBox';
import { TaxReturnSectionCurrentYear } from '../taxReturnSectionCurrentYear/taxReturnSectionCurrentYear';
import { setItem } from '../../utils/localStorage';
import { TaxReturnProgressBarStatusAccordion } from '../taxReturnProgressBarStatusAccordion/taxReturnProgressBarStatusAccordion';
import { TaxReturnSplitPaymentsAccordion } from '../taxReturnSplitPaymentsAccordion/taxReturnSplitPaymentsAccordion';
import { TaxReturnDocumentsAccordion } from '../taxReturnDocumentsAccordion/taxReturnDocumentsAccordion';
import { ObjDdrDocument, StatusDdrModel } from '../../models/userModel';
import { host } from '../../utils/config';
import lang from './taxReturnSectionRecap.json';
import './taxReturnSectionRecap.css';

interface Props {
  surveyStatus: SurveyStatusTaxReturnModel;
  onClickNextStep: () => void;
  setSelectedYear: (year: string) => void;
  selectedYear?: string;
  progressBarStatusAccordionData?: StatusDdrModel;
  documentsAccordionData?: ObjDdrDocument[];
}

export function TaxReturnSectionRecap(props: Props) {
  const { surveyStatus } = props;
  const { onClickNextStep } = props;
  const { setSelectedYear } = props;
  const { selectedYear } = props;
  const { progressBarStatusAccordionData } = props;
  const { documentsAccordionData } = props;

  const ddrArray = useAppSelector((state) => state.user.user.ddrYears);

  const incomeFormCompleted = useAppSelector(
    (state) => state.user.user.incomeFormCompleted
  );

  return (
    <div className={'row mt-4'}>
      <div className="col-12">
        <div className="tax-return-section-recap-container box-shadow py-5 px-4">
          <div className="tax-return-section-recap-container-border p-4">
            {ddrArray && ddrArray.some((el) => el >= 2023) && (
              <div className={'row'}>
                <div className={'col-12 d-flex justify-content-center'}>
                  <div
                    className={'tax-return-section-recap-tax-year-container'}
                  >
                    <div className={'text-end'}>{lang.taxYears}</div>
                    {ddrArray && selectedYear && (
                      <div className="tax-return-section-recap-tax-year-select">
                        <QuickfiscoSelect
                          id={'tax-return-section-recap-ddr-years'}
                          styleType={'default'}
                          options={getDdrYears(ddrArray, selectedYear)}
                          onChange={(e: SingleValue<OptionModel>) => {
                            if (e !== null) {
                              setSelectedYear(e.label);
                            }
                          }}
                          value={
                            getDdrYears(ddrArray, selectedYear).filter(
                              (option) => option.selected === true
                            )[0]
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {selectedYear === new Date().getFullYear().toString() ? (
              <div className="row tax-return-section-recap-current-year">
                <div className="col-12">
                  <TaxReturnSectionCurrentYear />
                </div>
              </div>
            ) : (
              <>
                <div className="row mt-5 mb-4 d-flex justify-content-center text-center">
                  <div className="col-12 tax-return-section-recap-title">
                    {lang.title}
                  </div>
                  <div className="col-10 tax-return-section-recap-description mt-2">
                    {lang.description1}
                    <b>{lang.description2}</b>
                    <br />
                    {lang.description3}
                  </div>
                </div>
                <div className="row no-gutters align-items-center mt-3 mb-4">
                  <div className="col-6 offset-0 col-xxl-5 offset-xxl-1">
                    <TaxReturnStatusBox
                      type={'incomeForm'}
                      isCompleted={
                        !(
                          !incomeFormCompleted &&
                          ddrArray?.includes(new Date().getFullYear() - 1)
                        )
                      }
                      onClick={() => {
                        setItem('incomeUploadModalStatus', 'show');
                        const event = new Event('local-storage');
                        window.dispatchEvent(event);
                      }}
                      completedAt={
                        progressBarStatusAccordionData?.createAtIncomes
                      }
                    />
                  </div>
                  <div className="col-6 col-xxl-5">
                    <TaxReturnStatusBox
                      type={'taxReturn'}
                      isCompleted={
                        surveyStatus.surveyStatus ===
                        SurveyStatusTypeTaxReturnModel.COMPLETED
                      }
                      onClick={() => {
                        if (
                          surveyStatus.surveyStatus ===
                          SurveyStatusTypeTaxReturnModel.COMPLETED
                        ) {
                          window.open(
                            `${host}/api/v1/tax-return/survey/${selectedYear}/download`,
                            '_blank'
                          );
                        } else {
                          onClickNextStep();
                        }
                      }}
                      completedAt={surveyStatus.completedAt}
                    />
                  </div>
                </div>
                {surveyStatus?.surveyStatus ===
                  SurveyStatusTypeTaxReturnModel.COMPLETED && (
                  <div className="row no-gutters mb-4">
                    <div className="col-12 offset-0 col-xxl-10 offset-xxl-1">
                      <TaxReturnProgressBarStatusAccordion
                        currentStep={
                          progressBarStatusAccordionData?.status
                            ? progressBarStatusAccordionData?.status
                            : 0
                        }
                        createdAt={progressBarStatusAccordionData?.statusDate}
                      />
                    </div>
                  </div>
                )}
                {surveyStatus?.surveyStatus ===
                  SurveyStatusTypeTaxReturnModel.COMPLETED && (
                  <div className="row no-gutters mb-4">
                    <div className="col-12 offset-0 col-xxl-10 offset-xxl-1">
                      <TaxReturnDocumentsAccordion
                        documents={documentsAccordionData}
                      />
                    </div>
                  </div>
                )}
                {surveyStatus?.surveyStatus ===
                  SurveyStatusTypeTaxReturnModel.COMPLETED && (
                  <div className="row no-gutters mb-4">
                    <div className="col-12 offset-0 col-xxl-10 offset-xxl-1">
                      <TaxReturnSplitPaymentsAccordion
                        splitPaymentsNumber={surveyStatus.splitPayments || 1}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getDdrYears(ddrYears: number[], value: string): OptionModel[] {
  const ddrYearsOptions: OptionModel[] = [];

  ddrYears.map(
    (el: number) =>
      el >= 2023 &&
      ddrYearsOptions.unshift({
        value: el.toString(),
        label: el.toString(),
        selected: value === el.toString(),
      })
  );

  return ddrYearsOptions;
}
