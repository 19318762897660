export interface FinanceUserModel {
    status: FinanceStatusEnum,
    outcome: FinanceOutcomeEnum,
    data: {
        income: number,
        mortgage_or_rental: boolean,
        mortgage_or_rental_amount?: number,
        financing_ongoing: boolean,
        financing_ongoing_total?: number,
        birth_date: Date,
        civil_status: string,
        family_unit?: number,
        citizenship: string,
        politically_exposed: boolean,
        address: string,
        postal_code: string,
        province: string,
        city: string,
        region: string,
        housing_type: 'rent' | 'property',
        residence_start_date: Date,
        loan_reason: string,
        bank_name: string,
        iban: string,
        payment_preference?: PaymentPreferenceEnum,
        same_as_domicile: boolean,
        domicile_address: string | null,
        domicile_postal_code: string | null,
        domicile_province: string | null,
        domicile_city: string | null,
        domicile_region: string | null
    },
    total_amount: number,
    installment_amount: number,
    installment_count: number,
    created_at?: Date
    updated_at?: Date
}

export enum FinanceStatusEnum {
    sent = 'sent',
    ongoing = 'ongoing',
    responseWaiting = 'responseWaiting',
    done = 'done'
}

export enum FinanceOutcomeEnum {
    approved = 'approved',
    rejected = 'rejected',
    waiting = 'waiting'
}

export enum PaymentPreferenceEnum {
    first = 'first',
    half = 'half'
}

export interface FinancingModel {
    financeability: boolean
    min: number
    max: number
}

export interface FinanceSimulationModel {
    loanSimulations: LoanSimulation[]
}

export interface LoanSimulation {
    annualPercentageRate: number
    grossAnnualRate: number
    numberOfInstalments: number
    instalments: { amount: number }[]
}