import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import uri from '../../routers/quickfiscoUri.json';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './communicateStsChange.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  invoiceId: string;
  edit: () => Promise<void>;
}

export function CommunicateStsChange(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { invoiceId } = props;
  const { edit } = props;

  const globalStatus = useAppSelector(state => state.operationStatus.status);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
      <QuickfiscoError
        message={lang.error}
        active={globalStatus === 'failed'}
        close={() => dispatch(setGlobalStatus({
          status: 'idle'
        }))}
      />
      <div className={'p-4 pt-0'}>
        <div className={'row my-4'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <p className={'cancel-sts-sending-title'}>
                {lang.title}
              </p>
              <p className={'cancel-sts-sending-text'}>
                {lang.text1}
                <span className={'cancel-sts-sending-blue'}>
                  {lang.text2}
                </span>
              </p>
            </div>
          </div>
          <div className={'row mt-2'}>
            {
              globalStatus === 'loading' ? (
                <div className={'col-12 d-flex align-items-center justify-content-center'}>
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <div className={'col-12'}>
                  <div className={'row'}>
                    <div className={'col-7'}>
                      <QuickfiscoButton
                        id={'cancel-sts-sending-email'}
                        label={lang.labelEmail}
                        onClick={() => communicateChange(dispatch, navigate, invoiceId, true, edit)}
                        type={'primary'}
                      />
                    </div>
                    <div className={'col-5'}>
                      <QuickfiscoButton
                        id={'cancel-sts-sending'}
                        label={lang.labelSts}
                        type={'secondary'}
                        onClick={() => communicateChange(dispatch, navigate, invoiceId, false, edit)}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function communicateChange(
  dispatch: Function,
  navigate: NavigateFunction,
  id: string,
  sendEmail: boolean,
  edit: () => Promise<void>
) {
  const service = new StsInvoiceService();

  dispatch(setGlobalStatus({
    status: 'loading'
  }));
  edit()
    .then(() => {
      service
        .communicateChange(id, sendEmail)
        .then(() => {
          dispatch(setGlobalStatus({
            status: 'successfully'
          }));
          navigate(uri.StsHistory);
        })
        .catch(err => {
          dispatch(setGlobalStatus({
            status: 'failed'
          }));
          console.error(err);
        });
    })
    .catch(err => {
      dispatch(setGlobalStatus({
        status: 'failed'
      }));
      console.error(err);
    });
}
