import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { SetState } from '../../types/functions';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { useParams } from 'react-router-dom';
import { InvoiceModel } from '../../models/invoiceModel';
import { PromiseStatuses } from '../../types/strings';
import { OccasionalJobInvoiceService } from '../../services/occasionalJobInvoiceService';
import { useAppSelector } from '../../redux/hooks';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import { OccasionalJobInvoiceSaveOrDuplicateOrEdit } from '../../components/occasionalJobInvoiceSaveOrDuplicateOrEdit/occasionalJobInvoiceSaveOrDuplicateOrEdit';
import lang from './occasionalJobInvoiceEdit.json';

export function OccasionalJobInvoiceEdit() {
  const { collapsed } = useProSidebar();
  const params = useParams();
  const id = params.id;

  const [currentOccasionalJobInvoice, setOccasionalJobInvoice] = useState<
    InvoiceModel | undefined
  >();
  const [
    statusCurrentOccasionalJobInvoice,
    setStatusCurrentOccasionalJobInvoice,
  ] = useState<PromiseStatuses>('loading');

  const userState = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (id !== undefined) {
      findById(
        id,
        setStatusCurrentOccasionalJobInvoice,
        setOccasionalJobInvoice
      );
    }
  }, [id]);

  if (
    statusCurrentOccasionalJobInvoice === 'loading' ||
    statusCurrentOccasionalJobInvoice === 'failed'
  ) {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        {statusCurrentOccasionalJobInvoice === 'loading' && (
          <QuickfiscoSpinner />
        )}
        {statusCurrentOccasionalJobInvoice === 'failed' && (
          <div>Errore durante il caricamento della fattura.</div>
        )}
      </div>
    );
  }

  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="occasional-job-invoice-edit-menu"
            menuItem={MenuItems.OCCASIONAL_JOB_INVOICE}
          />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row">
            <div className="col">
              <QuickfiscoHeader
                title={lang.Title}
              />
              {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <OccasionalJobInvoiceSaveOrDuplicateOrEdit
                operation={'edit'}
                occasionalJobInvoiceToEdit={currentOccasionalJobInvoice}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function findById(
  id: string,
  setStatus: SetState<PromiseStatuses>,
  setCurrentMessage: SetState<InvoiceModel | undefined>
) {
  const service = new OccasionalJobInvoiceService();

  service
    .findById(id)
    .then((occasionalJobInvoice) => {
      setStatus('idle');
      setCurrentMessage(occasionalJobInvoice);
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
