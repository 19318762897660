import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { ContactFilters } from '../../components/contactFilters/contactFilters';
import { ContactList } from '../../components/contactList/contactList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { ContactType } from '../../models/contactModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCustomerList, setCustomerListPage } from '../../redux/slices/customerListSlice';
import uri from '../../routers/quickfiscoUri.json';
import lang from './customerList.json';

export function CustomersList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const customerListState = useAppSelector(state => state.customerList)

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (customerListState.page === undefined) {
      dispatch(setCustomerListPage(0))
    }
  }, [])

  useEffect(() => {
    dispatch(getCustomerList())
  }, [customerListState.page, customerListState.filterCompanyName])

  useEffect(() => {
    if (customerListState.page !== undefined && customerListState.page > 0 && customerListState.customers.content.length === 0) {
      dispatch(setCustomerListPage(customerListState.page - 1))
    }
  }, [customerListState.customers.content])

  const customers = customerListState.customers

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='customer-list-menu'
            menuItem={MenuItems.CONTACTS_CUSTOMER}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-11'}>
              <ContactFilters type={ContactType.CUSTOMER} />
            </div>
            <div className={'col-1 d-flex justify-content-end'}>
              <QuickfiscoIcon
                name={'new.svg'}
                className={'w-f-35'}
                onClick={() => navigate(uri.CustomerSave)}
                isButton={true}
              />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <ContactList type={ContactType.CUSTOMER} />
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <div className={'d-flex justify-content-center'}>
                <QuickfiscoPagination
                  totalPages={customers.totalPages}
                  number={customers.number}
                  empty={customers.empty}
                  doPagination={(page: number) => dispatch(setCustomerListPage(page))}
                  first={customers.first}
                  last={customers.last}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
