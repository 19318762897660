import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { NotSavedProfileModal } from '../../components/notSavedProfileModal/notSavedProfileModal';
import { Tab, Tabs } from 'react-bootstrap';
import { QuickfiscoError } from '../../components/quickfiscoError/quickfiscoError';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ProfilePersonalInformations } from '../../components/profilePersonalInformations/profilePersonalInformations';
import { ProfileFiscalInformations } from '../../components/profileFiscalInformations/profileFiscalInformations';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingStatusBoxBanner } from '../../components/onboardingStatusBoxBanner/onboardingStatusBoxBanner';
import { QuickfiscoSuccess } from '../../components/quickfiscoSuccess/quickfiscoSuccess';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getMe,
  setProfileEditStatus,
  setProfileNotSavedModalIsOpen,
} from '../../redux/slices/userSlice';
import { getItem, setItem } from '../../utils/localStorage';
import { UserService } from '../../services/userService';
import lang from './profile.json';
import './profile.css';

export function Profile() {
  const [tabKey, setTabKey] = useState(
    getItem('currentProfileTab') || 'personalInfo'
  );

  const userState = useAppSelector((state) => state.user);
  const userStatus = userState.status;
  const editStatus = userState.editStatus;
  const user = userState.user;
  const editUserRequest = userState.editUserRequest;

  const editingMe = UserService.editingMe(
    user,
    editUserRequest,
    user.status !== 4
  );

  const dispatch = useAppDispatch();

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (editStatus === 'successfully') {
      dispatch(getMe());
    }
  }, [editStatus]);

  if (userStatus === 'loading' || editStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userStatus === 'failed') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        {lang.loadingError}
      </div>
    );
  }

  return (
    <div className="full-screen bg-blue">
      <QuickfiscoSuccess
        active={userState.editStatus === 'successfully'}
        message={lang.SuccessMessage}
        close={() => dispatch(setProfileEditStatus('idle'))}
      />
      <QuickfiscoError
        active={userState.editStatus === 'failed'}
        message={lang.ErrorMessage}
        close={() => dispatch(setProfileEditStatus('idle'))}
      />
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu id="home-menu" menuItem={MenuItems.PROFILE} />
        </div>
        <div
          className="col no-gutters"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row no-gutters ps-4">
            <div className="col-12 p-4">
              <div className={'row'}>
                <div className={'col'}>
                  <QuickfiscoHeader title={lang.Title} />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  {user.status === 6 && <OnboardingStatusBoxBanner />}
                  <div className="profile-tabs-container">
                    <Tabs
                      className={'profile-tabs mx-auto'}
                      fill
                      defaultActiveKey={tabKey}
                      onSelect={(key) => {
                        if (editingMe) {
                          dispatch(setProfileNotSavedModalIsOpen(true));
                        }
                        if (key) {
                          setTabKey(key);
                          setItem('currentProfileTab', key);
                        }
                      }}
                    >
                      <Tab eventKey="personalInfo" title={lang.Tab1}>
                        <div className={'row'}>
                          <div
                            className={'col-10 offset-1 col-xxl-8 offset-xxl-2'}
                          >
                            <ProfilePersonalInformations />
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="fiscalInfo" title={lang.Tab2}>
                        <div className={'row'}>
                          <div
                            className={'col-10 offset-1 col-xxl-8 offset-xxl-2'}
                          >
                            <ProfileFiscalInformations />
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NotSavedProfileModal />
        </div>
      </div>
    </div>
  );
}
