import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import './onboardingApiError.css';

interface Props {
  statusFailed?: boolean;
}

export function OnboardingApiError(props: Props) {
  const { statusFailed } = props;

  const refreshPage = () => window.location.reload();

  return (
    <div className="row full-screen mr-0 bg-blue">
      <div className="col-2">
        <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4 mt-4" />
      </div>
      <div className="col-8 mt-5">
        <div className="onboarding-api-errorcontainer-failed text-center my-5">
          <div>
            <QuickfiscoIcon name={'eyes.png'} />
            <p className="onboarding-api-errorops-title mt-4">OPS...</p>
            <h1 className="onboarding-api-errorfailed-title">
              sembra che qualcosa non sia andato per il verso giusto
            </h1>
            <p className="onboarding-api-errorrequirements">
              Non siamo riusciti a recuperare i tuoi dati, riprova tra qualche
              minuto.
              <br />
              Se continui a riscontrare problemi scrivici a &nbsp;
              <a
                href={`mailto:info@quickfisco.it`}
                target={'_blank'}
                rel={'noreferrer'}
              >
                info@quickfisco.it
              </a>
            </p>
            {statusFailed && (
              <div className="row d-flex justify-content-center align-items-center mt-5">
                <div className="col-5">
                  <QuickfiscoButton
                    id={'onboarding-api-errorrefresh-page'}
                    label={'RIPROVA'}
                    type={'secondary'}
                    onClick={refreshPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
