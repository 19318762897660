import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { EmailVerificationOnboarding } from '../views/emailVerificationOnboarding/emailVerificationOnboarding';
import { PersonalInfoOnboarding } from '../views/personalInfoOnboarding/personalInfoOnboarding';
import { FiscalInfoOnboarding } from '../views/fiscalInfoOnboarding/fiscalInfoOnboarding';
import { DocumentsOnboarding } from '../views/documentsOnboarding/documentsOnboarding';
import { ContractOnboarding } from '../views/contractOnboarding/contractOnboarding';
import { Payment } from '../views/payment/payment';
import { MandateOnboarding } from '../views/mandateOnboarding/mandateOnboarding';
import { LoginOnboardingComponent } from '../components/loginOnboardingComponent/loginOnboardingComponent';
import uri from './quickfiscoPaymentUri.json';

export function PaymentRouterToAuthenticated() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={uri.LoginOnboarding}
          element={<LoginOnboardingComponent />}
        />
        <Route
          path={uri.EmailVerification}
          element={<EmailVerificationOnboarding />}
        />
        <Route path={uri.FiscalInfo} element={<FiscalInfoOnboarding />} />
        <Route path={uri.PersonalInfo} element={<PersonalInfoOnboarding />} />
        <Route path={uri.Documents} element={<DocumentsOnboarding />} />
        <Route path={uri.Contract} element={<ContractOnboarding />} />
        <Route path={uri.Payment} element={<Payment />} />
        <Route path={uri.Mandate} element={<MandateOnboarding />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Routes>
    </BrowserRouter>
  );
}
