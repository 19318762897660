import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { WelcomeOnboarding } from '../views/welcomeOnboarding/welcomeOnboarding';
import { LoginOnboarding } from '../views/loginOnboarding/loginOnboarding';
import uri from './quickfiscoPaymentUri.json';

export function DefaultPaymentRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={uri.WelcomeOnboarding} element={<WelcomeOnboarding />} />
        <Route path={uri.LoginOnboarding} element={<LoginOnboarding />} />
        <Route path="*" element={<Navigate to={uri.WelcomeOnboarding} />} />
      </Routes>
    </BrowserRouter>
  );
}
