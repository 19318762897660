import { useEffect, useState } from 'react';
import { ProgressStep } from '../progressStep/progressStep';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { PromiseStatuses } from '../../types/strings';
import {
  ActivationOnboardingModel,
  StatusOnboardingModel,
} from '../../models/onboardingModel';
import { UserService } from '../../services/userService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { formatDateITWithHour } from '../../utils/date';
import { OnboardingStatusBoxBanner } from '../onboardingStatusBoxBanner/onboardingStatusBoxBanner';
import { useAppSelector } from '../../redux/hooks';
import config from '../../../config.json';
import lang from './onboardingStatusBox.json';
import './onboardingStatusBox.css';

export function OnboardingStatusBox() {
  const [response, setResponse] = useState<ActivationOnboardingModel>({
    previousVatStatus: '',
    lastCompletedStatus: 0,
    status: StatusOnboardingModel.REGISTRATION_COMPLETED,
    statusList: [],
  });
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector((state) => state.user.user);

  const statusType: Map<StatusOnboardingModel, string> = new Map([
    [StatusOnboardingModel.REGISTRATION_COMPLETED, lang.onboardingCompleted],
    [StatusOnboardingModel.VERIFYING_ACCOUNT, lang.verifyingAccount],
    [StatusOnboardingModel.OPENING_VAT, lang.openingVat],
    [StatusOnboardingModel.VAT_OPENED, lang.vatOpened],
    [StatusOnboardingModel.ACCOUNT_VERIFIED, lang.accountVerified],
  ]);

  useEffect(() => {
    const service = new UserService();

    setStatus('loading');

    service
      .onboardingActivation()
      .then((res) => {
        setResponse(res);
        setStatus('successfully');
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  }, []);

  if (status === 'loading') {
    return (
      <div
        className={
          'onboarding-status-box-container box-shadow p-4 d-flex justify-content-center align-items-center'
        }
      >
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div
        className={
          'onboarding-status-box-container box-shadow text-center py-5'
        }
      >
        <div className={'row'}>
          <div className={'col-12 pt-5 pb-4'}>
            <QuickfiscoIcon name={'alert-yellow.svg'} />
          </div>
        </div>
        <div className={'row pb-5'}>
          <div className={'col-12 onboarding-status-box-error-message'}>
            {lang.responseError}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <OnboardingStatusBoxBanner />
      <div className="row no-gutters onboarding-status-box-container box-shadow">
        <div className={'col-12'}>
          <div className="row pt-5 pb-0 ps-4 pe-4">
            <div className="col-12 d-flex align-items-center">
              <h2 className="onboarding-status-box-title">
                {userState.previousVatStatus === 'new'
                  ? lang.titleNew
                  : lang.titleSurroga}
              </h2>
              {(response.status ===
                StatusOnboardingModel.REGISTRATION_COMPLETED ||
                response.status === StatusOnboardingModel.VERIFYING_ACCOUNT ||
                response.status === StatusOnboardingModel.VAT_TO_OPEN ||
                response.status === StatusOnboardingModel.OPENING_VAT ||
                response.status === StatusOnboardingModel.VAT_OPENED) && (
                <div className="onboarding-status-ongoing-title">
                  <QuickfiscoIcon
                    name={'status-not-completed.svg'}
                    className="me-1"
                  />
                  {lang.ongoingStatus}
                </div>
              )}
              {response.status === StatusOnboardingModel.ACCOUNT_VERIFIED && (
                <div className="onboarding-status-completed-title">
                  <QuickfiscoIcon
                    name={'status-completed.svg'}
                    className="me-1"
                  />
                  {lang.completedStatus}
                </div>
              )}
              {(response.status === StatusOnboardingModel.NOT_MANAGEABLE ||
                response.status === StatusOnboardingModel.TERMINATION ||
                response.status === StatusOnboardingModel.CHECK_NOT_OK ||
                response.status === StatusOnboardingModel.DESERTION) && (
                <div className="onboarding-status-blocked-title">
                  <QuickfiscoIcon
                    name={'status-blocked.svg'}
                    className="me-1"
                  />
                  {lang.blockedStatus}
                </div>
              )}
            </div>
          </div>
          <div className={'row'}>
            <div className="col-12 no-gutters">
              <hr className="my-5" />
            </div>
          </div>
          <div className="row pt-0 pb-5 ps-4 pe-4">
            <div className="col-12">
              <ProgressStep
                currentStep={response.lastCompletedStatus}
                numberOfSteps={response.statusList.length}
                labels={response.statusList.map((item) =>
                  statusType.get(item.status)
                )}
                infoText={response.statusList.map((item) =>
                  item.completedAt
                    ? `il ${formatDateITWithHour(new Date(item.completedAt))}`
                    : '-'
                )}
              />
            </div>
          </div>
          {(response.status === StatusOnboardingModel.REGISTRATION_COMPLETED ||
            response.status === StatusOnboardingModel.VERIFYING_ACCOUNT ||
            response.status === StatusOnboardingModel.VAT_TO_OPEN ||
            response.status === StatusOnboardingModel.OPENING_VAT ||
            response.status === StatusOnboardingModel.VAT_OPENED) && (
            <>
              {response.previousVatStatus === 'new' ? (
                <div className="row pt-0 pb-5 ps-4 pe-4">
                  <div className="col-12">
                    <div className="onboarding-status-ongoing-box px-5">
                      <h3 className="onboarding-status-ongoing-box-title">
                        {lang.ongoingBoxTitle}
                      </h3>
                      <p className="onboarding-status-text-box mt-2 mb-4">
                        {lang.ongoingBoxText}
                      </p>
                      <p className="onboarding-status-info-text-box">
                        {lang.ongoingBoxInfo}
                        <b>{lang.ongoingBoxInfoBold}</b>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row pt-0 pb-5 ps-4 pe-4">
                  <div className="col-12">
                    <div className="onboarding-status-ongoing-box px-5">
                      <h3 className="onboarding-status-ongoing-box-title">
                        {lang.ongoingBoxTitle}
                      </h3>
                      <p className="onboarding-status-text-box mt-2 mb-4">
                        {lang.ongoingSurrogaBoxText}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {response.status === StatusOnboardingModel.ACCOUNT_VERIFIED && (
            <div className="row pt-0 pb-5 ps-4 pe-4">
              <div className="col-12">
                <div className="onboarding-status-completed-box px-5">
                  <h3 className="onboarding-status-completed-box-title">
                    {lang.completedBoxTitle}
                  </h3>
                  <p className="onboarding-status-text-box mt-2 mb-4">
                    {lang.completedgBoxText}
                  </p>
                </div>
              </div>
            </div>
          )}
          {(response.status === StatusOnboardingModel.NOT_MANAGEABLE ||
            response.status === StatusOnboardingModel.TERMINATION ||
            response.status === StatusOnboardingModel.CHECK_NOT_OK ||
            response.status === StatusOnboardingModel.DESERTION) && (
            <div className="row pt-0 pb-5 ps-4 pe-4">
              <div className="col-12">
                <div className="onboarding-status-blocked-box px-5">
                  <h3 className="onboarding-status-blocked-box-title">
                    {lang.blockedBoxTitle}
                  </h3>
                  <p className="onboarding-status-text-box mt-2 mb-4">
                    {lang.blockedBoxText}
                  </p>
                  <p className="onboarding-status-text-box">
                    {lang.blockedBoxInfo}{' '}
                    <a
                      href={config.whatsappLink}
                      target={'_blank'}
                      rel={'noreferrer'}
                      className="onboarding-popover-link"
                    >
                      whatsapp
                    </a>{' '}
                    o{' '}
                    <a
                      target={'_blank'}
                      href={`mailto:${config.mail}`}
                      rel={'noreferrer'}
                    >
                      via email
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
