import { useState } from 'react';
import { InvoiceModel, InvoiceStatusType } from '../../models/invoiceModel';
import { UserModel } from '../../models/userModel';
import { findCustomerInvoiceById, setCustomerInvoiceStatus } from '../../redux/slices/customerInvoiceSlice';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { formatDateIT } from '../../utils/date';
import { InvoicePaymentRow } from '../invoicePaymentRow/invoicePaymentRow';
import { InvoicePaymentSave } from '../invoicePaymentSave/invoicePaymentSave';
import { InvoicePaymentStatus } from '../invoicePaymentStatus/invoicePaymentStatus';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './invoicePaymentList.css';
import lang from './invoicePaymentList.json';
import { useAppDispatch } from '../../redux/hooks';
import { getCustomerInvoiceList } from '../../redux/slices/customerInvoiceListSlice';
import { SetState } from '../../types/functions';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';

interface Props {
  invoiceSign: InvoiceSign;
  invoice: InvoiceModel;
  user?: UserModel
}

export function InvoicePaymentList(props: Props) {
  const { invoiceSign } = props;
  const { invoice } = props;
  const { user } = props;

  let totalAmount: number = 0

  if (user) {
    totalAmount = CustomerInvoiceService.calculateTotalAmount(invoice, user, invoiceSign)
  }

  let partialAmount: number = 0
  if (invoice.payments !== undefined && invoice.payments !== null) {
    invoice.payments.forEach(payment => {
      partialAmount = partialAmount + payment.amount
    });
  }

  const [openPaymentSaveModal, setOpenPaymentSaveModal] = useState(false);

  const [resetReversedStatus, setResetReversedStatus] = useState<PromiseStatuses>('idle');


  let paymentList: React.ReactNode[] = []

  const dispatch = useAppDispatch();

  if (invoice.payments !== undefined && invoice.payments !== null) {
    paymentList = invoice.payments.map(payment => {
      return (
        <InvoicePaymentRow
          key={payment.id}
          payment={payment}
          invoiceSign={invoiceSign}
        />
      )
    })
  }

  const invoiceId = invoice.id

  if (invoiceId === undefined) {
    return <></>
  }

  if (invoiceSign === 'sts') {
    return (
      <div className={'invoice-payment-list-container p-4'}>
        <div className={'row align-items-center'}>
          <div className={'col-auto'}>
            <span className={'invoice-payment-list-title'}>
              {lang.title}
            </span>
          </div>
          <div className={'col-auto align-self-center d-flex'}>
            <QuickfiscoIcon name={'block.svg'} />
          </div>
          <div className={'col d-flex justify-content-end align-items-center'}>
            <InvoicePaymentStatus
              status={InvoiceStatusType.PAID}
             />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'invoice-payment-list-container p-4'}>
      <QuickfiscoError
        message={'Si è verificato un errore'}
        active={resetReversedStatus === 'failed'}
        close={() => setResetReversedStatus('idle')}
      />
      <div className={'row'}>
        <div className={'col-12'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'invoice-payment-list-title'}>
                {lang.title}
              </span>
            </div>
          </div>
          <div className={'row d-flex justify-content-between align-items-center'}>
            <div className={'col'}>
              <span className={'invoice-payment-list-expiration'}>
                {invoice.paymentExpiration && (lang.expiration + formatDateIT(invoice.paymentExpiration))}
              </span>
            </div>
            <div className={'col-auto d-flex justify-content-end'}>
              <InvoicePaymentStatus
                status={invoice.status}
                partialPaid={partialAmount !== totalAmount && partialAmount !== 0}
              />
              {invoice.status === InvoiceStatusType.REVERSED &&
                <div className={'col-auto ms-3 d-flex justify-content-end'}>
                  {(
                    <QuickfiscoIcon
                      name={'delete-icon.svg'}
                      isButton={true}
                      onClick={() => {
                        invoice.previousStatus !== undefined && changeStatusFromReversed(invoiceId, dispatch, invoice.previousStatus, setResetReversedStatus)
                      }}
                    />
                  )
                  }
                </div>}

            </div>
          </div>
          {
            paymentList.length > 0 && (
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  {paymentList}
                </div>
              </div>
            )
          }
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <QuickfiscoButton
                id={'invoice-view-save-payment'}
                label={lang.payment}
                type={!(
                  invoice.status === InvoiceStatusType.DUE_TERMS ||
                  invoice.status === InvoiceStatusType.ACCEPTED ||
                  invoice.status === InvoiceStatusType.DELIVERED ||
                  invoice.status === InvoiceStatusType.RECEIVED ||
                  invoice.status === InvoiceStatusType.NOT_RECEIVED ||
                  invoice.status === InvoiceStatusType.ALREADY_TRANSMITTED ||
                  invoice.status === InvoiceStatusType.PAYMENT_DUE
                ) ? 'disabled' : 'primary'}
                onClick={() => setOpenPaymentSaveModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <InvoicePaymentSave
        open={openPaymentSaveModal}
        setOpen={open => setOpenPaymentSaveModal(open)}
        invoiceSign={invoiceSign}
        invoice={invoice}
      />
    </div>
  );

  function changeStatusFromReversed(
    invoiceId: string,
    dispatch: Function,
    status: InvoiceStatusType,
    setResetReversedStatus: SetState<PromiseStatuses>
  ) {

    let service: CustomerInvoiceService = new CustomerInvoiceService();

    setResetReversedStatus('successfully')
    service
      .changeStatus(invoiceId, { status })
      .then(() => {
        if (invoiceId !== undefined && invoiceSign === 'customer') {
          dispatch(findCustomerInvoiceById(invoiceId))
        }
        setResetReversedStatus('successfully')
      })
      .catch(err => {
        setResetReversedStatus('failed');
        console.error(err);
      });
  }
}
