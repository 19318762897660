import { ReactElement } from 'react';
import { DocumentsReportModel } from '../../models/documentsReportModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setDocumentsListStatusFilter } from '../../redux/slices/documentsListSlice';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { DocumentsReport } from '../documentsReport/documentsReport';
import { DocumentsStatusFiltersType } from '../../models/documentsFilters';

interface Attribute {
  title: string;
  amount?: number;
  statusFilter?: DocumentsStatusFiltersType;
  setStatusFilter?: (status?: DocumentsStatusFiltersType) => void;
  selected: boolean;
}

export function DocumentsReportList() {
  const dispatch = useAppDispatch();

  const documentsListState = useAppSelector((state) => state.documentsList);

  let status: PromiseStatuses;
  let attributes: Attribute[];
  let reports: DocumentsReportModel;

  status = documentsListState.reportsStatus;
  reports = documentsListState.reports;
  attributes = [
    {
      title: 'Tutti i documenti',
      amount: reports.allDocuments,
      statusFilter: undefined,
      setStatusFilter: () => dispatch(setDocumentsListStatusFilter(undefined)),
      selected: false,
    },
    {
      title: 'Modelli F24',
      amount: reports.f24Models,
      statusFilter: DocumentsStatusFiltersType.F24,
      setStatusFilter: () =>
        dispatch(setDocumentsListStatusFilter(DocumentsStatusFiltersType.F24)),
      selected:
        documentsListState.statusFilter === DocumentsStatusFiltersType.F24,
    },
    {
      title: 'Dichiarazioni redditi',
      amount: reports.taxDeclaration,
      statusFilter: DocumentsStatusFiltersType.DECLARATION,
      setStatusFilter: () =>
        dispatch(
          setDocumentsListStatusFilter(DocumentsStatusFiltersType.DECLARATION)
        ),
      selected:
        documentsListState.statusFilter ===
        DocumentsStatusFiltersType.DECLARATION,
    },
    {
      title: 'P.IVA e Doc. Identità',
      amount: reports.VatandIdDocuments,
      statusFilter: DocumentsStatusFiltersType.TAX_IDENTITY,
      setStatusFilter: () =>
        dispatch(
          setDocumentsListStatusFilter(DocumentsStatusFiltersType.TAX_IDENTITY)
        ),
      selected:
        documentsListState.statusFilter ===
        DocumentsStatusFiltersType.TAX_IDENTITY,
    },
    {
      title: 'Altro',
      amount: reports.OtherDocuments,
      statusFilter: DocumentsStatusFiltersType.OTHERS,
      setStatusFilter: () =>
        dispatch(
          setDocumentsListStatusFilter(DocumentsStatusFiltersType.OTHERS)
        ),
      selected:
        documentsListState.statusFilter === DocumentsStatusFiltersType.OTHERS,
    },
  ];

  if (status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || !reports) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className={'p-blue my-2'}>
          Errore durante il caricamento dei reports.
        </div>
      </div>
    );
  }

  let reportsList: ReactElement[] = [];

  attributes.forEach((value) => {
    reportsList.push(
      <div className={'col'} key={'report-' + JSON.stringify(value)}>
        <div>
          <DocumentsReport
            title={value.title}
            amount={value.amount}
            statusFilter={value.statusFilter}
            setStatusFilter={value.setStatusFilter}
            selected={value.selected}
          />
        </div>
      </div>
    );
  });

  return <div className={'row'}>{reportsList}</div>;
}
