export interface ObjectModel {
  id?: string;
  ownerId?: string;
  file?: string;
  name?: string;
  type?: ObjectType;
  extension?: string;
  description?: string;
  notes?: string;
  lastUpdate?: string;
  dueDate?: string;
}

export enum ObjectType {
  OTHER_ENTITY_OBJECT = 'otherEntityObject',
  FISCAL_DOCUMENT = 'fiscalDocument'
}

export enum DocType {
  ID_DOC = 'idDoc',
  TAX_DOC = 'taxDoc'
}
