import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseStatuses } from '../../types/strings';
import { CheckoutPaymentModel, InvoiceCheckoutPaymentModel, PaymentTypeEnum } from '../../models/paymentModel';
import { PaymentService } from '../../services/paymentService';

interface PaymentState {
  statusPaymentCheckout: PromiseStatuses;
  statusUpdateInvoice: PromiseStatuses;
  statusUpdateStripePaymentIntent: PromiseStatuses;
  statusCompletePayment: PromiseStatuses;
  invoice: InvoiceCheckoutPaymentModel;
  confirmedPromoCode?: 'confirmed' | 'not-valid';
  disabledPromoCode?: boolean;
  currentPromocode?: string;
  paymentMethod?: PaymentTypeEnum;
}

const initialState: PaymentState = {
  statusPaymentCheckout: 'idle',
  statusUpdateInvoice: 'idle',
  statusUpdateStripePaymentIntent: 'idle',
  statusCompletePayment: 'idle',
  invoice: {
    id: '',
    products: [],
    amount: 0,
    finalAmount: 0,
  },
  disabledPromoCode: false,
};

export const paymentCheckout = createAsyncThunk(
  'payment/paymentCheckout',
  async (): Promise<CheckoutPaymentModel> => {
    return await new PaymentService().paymentCheckout();
  }
);

export const updateInvoice = createAsyncThunk(
  'payment/updateInvoice',
  async (request: { invoiceId: string, promoCode?: string, receiptFileId?: string, receiptDelete?: boolean, removePromoCode?: boolean }): Promise<CheckoutPaymentModel> => {
    return await new PaymentService().updateInvoice(request.invoiceId, request.promoCode, request.receiptFileId, request.receiptDelete, request.removePromoCode);
  }
);

export const updateStripePaymentIntent = createAsyncThunk(
  'payment/updateStripePaymentIntent',
  async (invoiceId: string): Promise<string> => {
    return await new PaymentService().updateStripePaymentIntent(invoiceId);
  }
);

export const completePayment = createAsyncThunk(
  'payment/completePayment',
  async (request: { invoiceId: string, paymentMethod: PaymentTypeEnum }): Promise<void> => {
    return await new PaymentService().completePayment(request.invoiceId, request.paymentMethod);
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<PaymentTypeEnum>) => {
      state.paymentMethod = action.payload
    },
    setConfirmedPromoCode: (state, action: PayloadAction<'confirmed' | 'not-valid' | undefined>) => {
      state.confirmedPromoCode = action.payload
    },
    setStatusCompletePayment: (state, action: PayloadAction<PromiseStatuses>) => {
      state.statusCompletePayment = action.payload
    },
    setStatusPaymentCheckout: (state, action: PayloadAction<PromiseStatuses>) => {
      state.statusPaymentCheckout = action.payload
    },
    setStatusUpdateInvoice: (state, action: PayloadAction<PromiseStatuses>) => {
      state.statusUpdateInvoice = action.payload
    },
    setCurrentPromocode: (state, action: PayloadAction<string | undefined>) => {
      state.currentPromocode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(paymentCheckout.pending, (state) => {
        state.statusPaymentCheckout = 'loading';
      })
      .addCase(paymentCheckout.fulfilled, (state, action) => {
        state.statusPaymentCheckout = 'idle';
        state.invoice = action.payload;
        state.disabledPromoCode = action.payload.promoCode?.id ? true : false;
        state.confirmedPromoCode = action.payload.promoCode?.id ? 'confirmed' : undefined;
      })
      .addCase(paymentCheckout.rejected, ((state) => {
        state.statusPaymentCheckout = 'failed';
      }))
      .addCase(updateInvoice.pending, (state) => {
        state.statusUpdateInvoice = 'loading';
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.statusUpdateInvoice = 'idle';
        state.invoice = action.payload;
        state.confirmedPromoCode = action.payload.promoCode?.id ? 'confirmed' : undefined;
      })
      .addCase(updateInvoice.rejected, ((state) => {
        state.statusUpdateInvoice = 'failed';
        state.confirmedPromoCode = 'not-valid';
      }))
      .addCase(updateStripePaymentIntent.pending, (state) => {
        state.statusUpdateStripePaymentIntent = 'loading';
      })
      .addCase(updateStripePaymentIntent.fulfilled, (state, action) => {
        state.statusUpdateStripePaymentIntent = 'idle';
        state.invoice.clientSecret = action.payload;
      })
      .addCase(updateStripePaymentIntent.rejected, ((state) => {
        state.statusUpdateStripePaymentIntent = 'failed';
      }))
      .addCase(completePayment.pending, (state) => {
        state.statusCompletePayment = 'loading';
      })
      .addCase(completePayment.fulfilled, (state, action) => {
        state.statusCompletePayment = 'successfully';
      })
      .addCase(completePayment.rejected, ((state) => {
        state.statusCompletePayment = 'failed';
      }))
  },
});

export const {
  setPaymentMethod,
  setConfirmedPromoCode,
  setStatusCompletePayment,
  setStatusPaymentCheckout,
  setStatusUpdateInvoice,
  setCurrentPromocode,
} = paymentSlice.actions;

export default paymentSlice.reducer;
