import config from '../../../config.json';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoSuccess.css';

interface Props {
  active: boolean;
  close: () => void;
  message?: string;
  noClose?: boolean;
}

export function QuickfiscoSuccess(props: Props) {
  const { active } = props;
  const { close } = props;
  const { message } = props;
  const { noClose } = props;

  if (!active) {
    return null;
  }

  !noClose && setTimeout(() => close(), config.hidePopupTimeout);

  return (
    <div className={'quickfisco-success p-5 text-center'}>
      <div className={'quickfisco-success-close'}>
        <QuickfiscoIcon
          name={'close-icon-black.svg'}
          isButton={true}
          onClick={() => close()}
        />
      </div>
      <QuickfiscoIcon
        name={'alert-success.svg'}
        className={'quickfisco-success-icon'}
      />
      <div className={'mt-3'}>
        <span className={'quickfisco-success-text'}>{message}</span>
      </div>
    </div>
  );
}
