import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './atecoSelectInfo.json';
import './atecoSelectInfo.css';

export function AtecoSelectInfo() {
  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div ref={componentVisible.ref} className={'ateco-select-info-popup'}>
          {componentVisible.isVisible && (
            <div className={'ateco-select-info-popup-container pb-1'}>
              <p className={'ateco-select-info-text'}>{lang.line1}</p>
              <p className={'ateco-select-info-text'}>
                {lang.line2}
                <span className={'fw-bold'}>{lang.bold}</span>
              </p>
              <p className={'ateco-select-info-text fw-bold'}>{lang.line3}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
