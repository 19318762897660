import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InvoiceFilters, ProformaInvoiceStatusFilterType } from "../../models/invoiceFilters";
import { InvoiceModel } from "../../models/invoiceModel";
import { PaginationModel } from "../../models/paginationModel";
import { ProformaInvoiceService } from "../../services/proformaInvoiceService";
import { PromiseStatuses } from "../../types/strings";
import { CommonInvoiceService } from "../../services/commonInvoiceService";
import config from '../../../config.json';

interface ProformaInvoiceListState {
    status: PromiseStatuses;
    invoices: PaginationModel<InvoiceModel>;
    page: number;
    monthFilter?: string;
    yearFilter: string;
    companyNameFilter?: string;
    statusFilter?: ProformaInvoiceStatusFilterType;
}

const initialState: ProformaInvoiceListState = {
    status: 'idle',
    invoices: { content: [] },
    page: 0,
    yearFilter: new Date().getFullYear().toString()
};

export const getProformaInvoiceList = createAsyncThunk(
    'proformaInvoiceList/getProformaInvoiceList',
    async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {
        return new ProformaInvoiceService().findAll(request);
    }
)

const proformaInvoiceListSlice = createSlice({
    name: 'proformaInvoiceList',
    initialState,
    reducers: {
        setProformaInvoiceListPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setProformaInvoiceListMonthFilter: (state, action: PayloadAction<string | undefined>) => {
            state.monthFilter = action.payload;
        },
        setProformaInvoiceListYearFilter: (state, action: PayloadAction<string>) => {
            state.yearFilter = action.payload;
        },
        setProformaInvoiceListCompanyNameFilter: (state, action: PayloadAction<string | undefined>) => {
            state.companyNameFilter = action.payload;
        },
        setProformaInvoiceListStatusFilter: (state, action: PayloadAction<ProformaInvoiceStatusFilterType | undefined>) => {
            state.statusFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProformaInvoiceList.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getProformaInvoiceList.fulfilled, (state, action) => {
            let proformaInvoices = CommonInvoiceService.filter(
                'proforma',
                {
                    page: state.page,
                    month: state.monthFilter,
                    year: state.yearFilter,
                    companyName: state.companyNameFilter,
                    proformaStatus: state.statusFilter
                },
                action.payload
            );

            proformaInvoices = CommonInvoiceService.sort(proformaInvoices)

            proformaInvoices = CommonInvoiceService.paginate(state.page, config.pageSize, proformaInvoices)

            state.status = 'successfully';
            state.invoices = proformaInvoices
        })
        .addCase(getProformaInvoiceList.rejected, ((state) => {
            state.status = 'failed'
        }))
    }

})

export const {
    setProformaInvoiceListPage,
    setProformaInvoiceListMonthFilter,
    setProformaInvoiceListYearFilter,
    setProformaInvoiceListCompanyNameFilter,
    setProformaInvoiceListStatusFilter
} = proformaInvoiceListSlice.actions;

export default proformaInvoiceListSlice.reducer