import { useEffect, useState } from 'react';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { UserModel } from '../../models/userModel';
import { ObjectService } from '../../services/objectService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { downloadPdf } from '../../utils/file';
import { DocType } from '../../models/objectModel';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { DocumentsPreviewModal } from '../documentsPreviewModal/documentsPreviewModal';
import lang from './userDoc.json';
import './userDoc.css';

interface Props {
  docType: DocType;
  user: UserModel;
}

export function UserDoc(props: Props) {
  const { docType } = props;
  const { user } = props;

  const [downloadStatus, setDownloadStatus] = useState<PromiseStatuses>('idle');
  const [docIsPresent, setDocIsPresent] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (docType === DocType.ID_DOC) {
      setDocIsPresent(user?.idDocId || user?.idDoc?.objectId ? true : false);
    } else {
      setDocIsPresent(user?.taxDocId || user?.taxDoc?.objectId ? true : false);
    }
  }, [user]);

  return (
    <div className="row no-gutters align-items-center align-items-center user-doc-box">
      <QuickfiscoError
        message={lang.error}
        active={downloadStatus === 'failed'}
        close={() => setDownloadStatus('idle')}
      />
      <div className="col d-flex user-doc-title">
        {docType === DocType.ID_DOC ? lang.TitleIdDoc : lang.TitleTaxDoc}
      </div>
      {docIsPresent ? (
        <>
          <div className="col-2 d-flex justify-content-center">
            {downloadStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <div
                className={'user-doc-download-document'}
                onClick={() => getDownloadDoc(docType, setDownloadStatus, user)}
              >
                <QuickfiscoIcon name={'download.svg'} />
              </div>
            )}
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className={'user-doc-download-document'}
              onClick={() => setOpen(true)}
            >
              <QuickfiscoIcon name={'find.svg'} />
            </div>
          </div>
          <div className="col-2 pe-4 d-flex justify-content-center">
            <QuickfiscoIcon name={'alert-success-small.svg'} />
          </div>
        </>
      ) : (
        <div className="col-6 pe-4 d-flex justify-content-end">
          <QuickfiscoIcon name={'alert-error-small.svg'} />
        </div>
      )}
      <DocumentsPreviewModal
        open={open}
        setOpen={setOpen}
        docId={
          docType === DocType.ID_DOC
            ? user?.idDocId || user?.idDoc?.objectId
            : user?.taxDocId || user?.taxDoc?.objectId
        }
      />
    </div>
  );
}

function getDownloadDoc(
  docType: string,
  setStatus: SetState<PromiseStatuses>,
  user?: UserModel
): void {
  if (
    user?.idDocId ||
    user?.idDoc?.objectId ||
    user?.taxDocId ||
    user?.taxDoc?.objectId
  ) {
    const service = new ObjectService();
    setStatus('loading');

    let docId: string | undefined = undefined;

    if (docType === DocType.ID_DOC) {
      docId = user?.idDocId || user?.idDoc?.objectId;
    } else {
      docId = user?.taxDocId || user?.taxDoc?.objectId;
    }

    if (docId) {
      service
        .findById(docId)
        .then((data) => {
          const objFile = data;

          if (objFile.id) {
            service
              .findFile(objFile.id)
              .then((data) => {
                setStatus('idle');
                if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
              })
              .catch((err) => {
                setStatus('failed');
                console.error(err);
              });
          }
        })
        .catch((err) => {
          setStatus('failed');
          console.error(err);
        });
    }
  }
}
