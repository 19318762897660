import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { CategoryType, FundModel } from '../../models/userModel';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './fundSelect.json';
import { useAppSelector } from '../../redux/hooks';

interface Props {
  id: string;
  value?: string;
  errorFund?: boolean;
  setFund?: (fund?: string) => void;
  setRecourseValue?: (recourseValue?: number) => void;
  disabled?: boolean;
  required?: boolean;
  visibleNumber?: boolean;
}

export function FundSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { setFund } = props;
  const { errorFund } = props;
  const { setRecourseValue } = props;
  const { disabled } = props;
  const { required } = props;
  const { visibleNumber } = props;

  const userState = useAppSelector((state) => state.user.editUserRequest);

  let fund: FundModel | undefined;
  if (value) {
    fund = fundsMap.get(value);
  }

  return (
    <div className={'row'}>
      <div className={visibleNumber ? 'col' : 'col-6'}>
        <QuickfiscoSelect
          id={id}
          label={lang.FundLabel}
          onChange={(e: SingleValue<OptionModel>) => {
            if (e !== null) {
              setFund && setFund(e.value);
              e.value !== undefined &&
                setRecourseValue &&
                setRecourseValue(fundsMap.get(e.value)?.recourseValue);
            }
          }}
          error={errorFund}
          styleType={'default'}
          errorLabel={lang.ErrorFund}
          options={getOptions(value)}
          requiredField={required}
          disabled={disabled}
          value={
            getOptions(value).filter((option) => option.selected === true)[0]
          }
        />
      </div>
      {!visibleNumber &&
        (userState.category === CategoryType.FUND ||
          userState.category === CategoryType.NOT_FUND) && (
          <div className={'col-6'}>
            <QuickfiscoInput
              id="fund-input"
              styleType={'default'}
              type={'text'}
              label={lang.SocialContributionLabel}
              value={
                value && fund && fund.recourseValue !== undefined
                  ? String(fund.recourseValue) + '%'
                  : ''
              }
              required={required}
              disabled={true}
              placeholder={lang.SocialContributionPlaceholder}
            />
          </div>
        )}
    </div>
  );
}

function getOptions(value?: string): OptionModel[] {
  let fundsOptions: OptionModel[] = [];

  fundsMap.forEach((_, key) => {
    const label = fundsMap.get(key)?.desc;
    fundsOptions.push({
      label: label !== undefined ? label : '',
      value: key !== 'nobody' ? key : undefined,
      selected: key !== 'nobody' ? key === value : undefined === value,
    });
  });

  return fundsOptions;
}

export const fundsMap: Map<string, FundModel> = new Map([
  [
    'nobody',
    {
      desc: 'Seleziona la cassa',
      recourseValue: undefined,
    },
  ],
  [
    'TC01',
    {
      desc: 'Cassa nazionale previdenza e assistenza avvocati e procuratori legali',
      recourseValue: 4,
    },
  ],
  [
    'TC02',
    {
      desc: 'Cassa previdenza dottori commercialisti',
      recourseValue: 4,
    },
  ],
  [
    'TC03',
    {
      desc: 'Cassa previdenza e assistenza geometri',
      recourseValue: 5,
    },
  ],
  [
    'TC04',
    {
      desc: 'Cassa nazionale previdenza e assistenza ingegneri e architetti liberi professionisti',
      recourseValue: 4,
    },
  ],
  [
    'TC05',
    {
      desc: 'Cassa nazionale del notariato',
      recourseValue: 0,
    },
  ],
  [
    'TC06',
    {
      desc: 'Cassa nazionale previdenza e assistenza ragionieri e periti commerciali',
      recourseValue: 4,
    },
  ],
  [
    'TC07',
    {
      desc: 'Ente nazionale assistenza agenti e rappresentanti di commercio (ENASARCO)',
      recourseValue: 8.5,
    },
  ],
  [
    'TC08',
    {
      desc: 'Ente nazionale previdenza e assistenza consulenti del lavoro (ENPACL)',
      recourseValue: 4,
    },
  ],
  [
    'TC09',
    {
      desc: 'Ente nazionale previdenza e assistenza medici (ENPAM)',
      recourseValue: 0,
    },
  ],
  [
    'TC10',
    {
      desc: 'Ente nazionale previdenza e assistenza farmacisti (ENPAF)',
      recourseValue: 0,
    },
  ],
  [
    'TC11',
    {
      desc: 'Ente nazionale previdenza e assistenza veterinari (ENPAV)',
      recourseValue: 2,
    },
  ],
  [
    'TC12',
    {
      desc: "Ente nazionale previdenza e assistenza impiegati dell'agricoltura (ENPAIA)",
      recourseValue: 2,
    },
  ],
  [
    'TC13',
    {
      desc: 'Fondo previdenza impiegati imprese di spedizione e agenzie marittime',
      recourseValue: 0,
    },
  ],
  [
    'TC14',
    {
      desc: 'Istituto nazionale previdenza giornalisti italiani (INPGI)',
      recourseValue: 4,
    },
  ],
  [
    'TC15',
    {
      desc: 'Opera nazionale assistenza orfani sanitari italiani (ONAOSI)',
      recourseValue: 0,
    },
  ],
  [
    'TC16',
    {
      desc: 'Cassa autonoma assistenza integrativa giornalisti italiani (CASAGIT)',
      recourseValue: 0,
    },
  ],
  [
    'TC17',
    {
      desc: 'Ente previdenza periti industriali e periti industriali laureati (EPPI)',
      recourseValue: 5,
    },
  ],
  [
    'TC18',
    {
      desc: 'Ente previdenza e assistenza pluricategoriale (EPAP)',
      recourseValue: 4,
    },
  ],
  [
    'TC19',
    {
      desc: 'Ente nazionale previdenza e assistenza biologi (ENPAB)',
      recourseValue: 4,
    },
  ],
  [
    'TC20',
    {
      desc: 'Ente nazionale previdenza e assistenza professione infermieristica (ENPAPI)',
      recourseValue: 4,
    },
  ],
  [
    'TC21',
    {
      desc: 'Ente nazionale previdenza e assistenza psicologi (ENPAP)',
      recourseValue: 2,
    },
  ],
  [
    'TC22',
    {
      desc: 'INPS',
      recourseValue: 4,
    },
  ],
]);
