import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/redux/store';
import './index.css';

TagManager.initialize({ gtmId: 'GTM-W52HJJJ' })

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    </Provider>
  </React.StrictMode>
);
