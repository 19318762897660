import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './withholdingTaxInfo.json';
import './withholdingTaxInfo.css';

export function WithholdingTaxInfo() {
  const componentVisible = useComponentVisible(false);

  return (
    <div className={'ps-2'}>
      <div className={'position-relative'}>
        <div
          className={'withholding-tax-info-inline-flex'}
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div
          ref={componentVisible.ref}
          className={'withholding-tax-info-popup'}
        >
          {componentVisible.isVisible && (
            <div className={'withholding-tax-info-popup-container pb-1'}>
              <p className={'withholding-tax-info-text'}>
                <span className={'fw-bold'}>{lang.line1Bold}</span>
                {lang.line2}
                <span className={'fw-bold'}>{lang.line3Bold}</span>
              </p>
              <p className={'withholding-tax-info-text'}>
                {lang.line4}
                <span className={'fw-bold'}>{lang.line5Bold}</span>
                {lang.line6}
                <span className={'fw-bold'}>{lang.line7Bold}</span>
                {lang.line8}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
