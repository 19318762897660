import { useEffect, useState } from 'react';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { ObjectService } from '../../services/objectService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { downloadPdf, validateFile } from '../../utils/file';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoInputDoc } from '../quickfiscoInputDoc/quickfiscoInputDoc';
import { UserService } from '../../services/userService';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setStatusUpdateInvoice,
  updateInvoice,
} from '../../redux/slices/paymentSlice';
import { DocumentsPreviewModal } from '../documentsPreviewModal/documentsPreviewModal';
import lang from './uploadBankDoc.json';
import './uploadBankDoc.css';

interface Props {
  label: string;
}

export function UploadBankDoc(props: Props) {
  const { label } = props;

  const [error, setError] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState<PromiseStatuses>('idle');
  const [docIsPresent, setDocIsPresent] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const paymentState = useAppSelector((state) => state.payment);
  const invoiceState = useAppSelector((state) => state.payment.invoice);

  useEffect(() => {
    if (invoiceState.receiptFileId) {
      setDocIsPresent(true);
    } else {
      setDocIsPresent(false);
    }
  }, [invoiceState.receiptFileId]);

  if (paymentState.statusUpdateInvoice === 'loading') {
    return (
      <div className="upload-bank-doc-box w-100 d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters align-items-center align-items-center upload-bank-doc-box">
      <QuickfiscoSuccess
        message={lang.success}
        active={paymentState.statusUpdateInvoice === 'successfully'}
        close={() => dispatch(setStatusUpdateInvoice('idle'))}
      />
      <div className="col d-flex upload-bank-doc-title">{label}</div>
      {docIsPresent && (
        <>
          <div className="col-2 d-flex justify-content-center">
            {downloadStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <div
                className={'upload-bank-doc-download-document'}
                onClick={() =>
                  getDownloadDoc(setDownloadStatus, invoiceState.receiptFileId)
                }
              >
                <QuickfiscoIcon name={'download-negative.svg'} />
              </div>
            )}
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className={'user-doc-download-document'}
              onClick={() => setOpen(true)}
            >
              <QuickfiscoIcon name={'find-negative.svg'} />
            </div>
          </div>
        </>
      )}
      <QuickfiscoInputDoc
        docIsPresent={docIsPresent}
        error={error}
        errorLabel={lang.error}
        onChange={(doc) =>
          saveDoc(dispatch, doc, label, setError, invoiceState.id)
        }
        deleteDoc={() =>
          deleteDoc(
            dispatch,
            setDocIsPresent,
            invoiceState.receiptFileId,
            invoiceState.id
          )
        }
      />
      <DocumentsPreviewModal
        open={open}
        setOpen={setOpen}
        docId={invoiceState.receiptFileId}
      />
    </div>
  );
}

function saveDoc(
  dispatch: Function,
  doc: File | null,
  label: string,
  setError: SetState<boolean>,
  invoiceId?: string
): void {
  setError(false);

  if (doc === null || !invoiceId) {
    return;
  }

  if (!validateFile(doc)) {
    setError(true);
    return;
  }

  const userService = new UserService();

  const fileName = label.replace(/ /g, '_').toLowerCase() + '.';

  userService
    .saveDoc(doc, fileName)
    .then((id) => {
      dispatch(
        updateInvoice({
          invoiceId: invoiceId,
          promoCode: undefined,
          receiptFileId: id,
        })
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

function deleteDoc(
  dispatch: Function,
  setDocIsPresent: SetState<boolean>,
  docId?: string,
  invoiceId?: string
): void {
  if (!invoiceId) {
    return;
  }

  const objectService = new ObjectService();

  if (docId) {
    objectService
      .del(docId)
      .then(() => {
        dispatch(
          updateInvoice({
            invoiceId: invoiceId,
            promoCode: undefined,
            receiptFileId: undefined,
            receiptDelete: true,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setDocIsPresent(false);
      });
  }
}

function getDownloadDoc(
  setStatus: SetState<PromiseStatuses>,
  docId?: string
): void {
  const service = new ObjectService();
  setStatus('loading');
  if (docId) {
    service
      .findById(docId)
      .then((data) => {
        const objFile = data;

        if (objFile.id) {
          service
            .findFile(objFile.id)
            .then((data) => {
              setStatus('idle');
              if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
            })
            .catch((err) => {
              setStatus('failed');
              console.error(err);
            });
        }
      })
      .catch((err) => {
        setStatus('failed');
        console.error(err);
      });
  }
}
