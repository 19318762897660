export enum PaymentTypeEnum {
  CARD = 'card',
  WIRE_TRANSFER = 'wire_transfer',
  KLARNA = "KLARNA",
}

export interface ProductsPaymentModel {
  id: string;
  name: string;
  finalPrice: number;
}

export interface PromocodePaymentModel {
  id: string;
  code: string;
  amount: number;
}

export interface CheckoutPaymentModel {
  id: string;
  products: ProductsPaymentModel[];
  amount: number;
  finalAmount: number;
  promoCode?: PromocodePaymentModel;
  receiptFileId?: string;
  receiptCode?: string;
}

export interface IntentPaymentModel {
  clientSecret: string;
}

export interface InvoiceCheckoutPaymentModel {
  id: string;
  products: ProductsPaymentModel[];
  amount: number;
  finalAmount: number;
  promoCode?: PromocodePaymentModel;
  receiptFileId?: string;
  receiptCode?: string;
  clientSecret?: string;
}