import { useState } from 'react';
import { TotalRevenuesModel } from '../../models/totalRevenuesModel';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import lang from './trendGraphs.json';
import './trendGraphs.css';

interface Props {
  status: PromiseStatuses;
  stsStatus?: PromiseStatuses;
  feesStatus?: PromiseStatuses;
  revenues?: TotalRevenuesModel;
  stsRevenues?: TotalRevenuesModel;
  feesRevenues?: TotalRevenuesModel;
  year: number;
}

export function TrendGraphs(props: Props) {
  const { status } = props;
  const { stsStatus } = props;
  const { feesStatus } = props;
  const { revenues } = props;
  const { stsRevenues } = props;
  const { feesRevenues } = props;
  const { year } = props;

  let incomeData = [];
  let salesData = [];
  const currentMonth =
    new Date().getFullYear() === year ? new Date().getMonth() + 2 : 13;
  const months = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
  ];

  for (let i = 1; i < currentMonth; i++) {
    let totalIncomeMonth = 0;
    let totalSalesMonth = 0;
    if (
      revenues?.incomeReportByMonth &&
      stsRevenues?.incomeReportByMonth &&
      feesRevenues?.incomeReportByMonth
    ) {
      totalIncomeMonth =
        revenues?.incomeReportByMonth[i] +
        stsRevenues?.incomeReportByMonth[i] +
        feesRevenues?.incomeReportByMonth[i];
    }

    if (
      revenues?.salesReportByMonth &&
      stsRevenues?.salesReportByMonth &&
      feesRevenues?.salesReportByMonth
    ) {
      totalSalesMonth =
        revenues?.salesReportByMonth[i] +
        stsRevenues?.salesReportByMonth[i] +
        feesRevenues?.salesReportByMonth[i];
    }

    incomeData.push({
      name: months[i - 1],
      Totale: Number(totalIncomeMonth.toFixed(2)),
    });

    salesData.push({
      name: months[i - 1],
      Totale: Number(totalSalesMonth.toFixed(2)),
    });
  }

  const [selectedButton, setSelectedButton] = useState(0);
  if (
    status === 'loading' ||
    stsStatus === 'loading' ||
    feesStatus === 'loading'
  ) {
    return (
      <div
        className={
          'trend-graphs-container box-shadow p-4 d-flex justify-content-center align-items-center'
        }
      >
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (!revenues || !stsRevenues || !feesRevenues) {
    return (
      <div className={'d-flex justify-content-center align-items-center'}>
        {lang.loadingError}
      </div>
    );
  }

  return (
    <div className="row trend-graphs-container no-gutters box-shadow p-4">
      <div className={'col-xxl-12 col-10 offset-1 offset-xxl-0'}>
        <div className="row mt-1 align-items-center">
          <div className={'col-5 no-gutters d-flex trend-graphs-title'}>
            {lang.Title}
          </div>
          <div className={'col-7'}>
            <div className={'row trend-graphs-buttons-container'}>
              <div
                className={`col-6 ${
                  selectedButton === 0
                    ? 'trend-graphs-buttons-selected'
                    : 'trend-graphs-buttons'
                }`}
                onClick={() => setSelectedButton(0)}
              >
                <span>{lang.LeftButton}</span>
              </div>
              <div
                className={`col-6 ${
                  selectedButton === 1
                    ? 'trend-graphs-buttons-selected'
                    : 'trend-graphs-buttons'
                }`}
                onClick={() => setSelectedButton(1)}
              >
                <span>{lang.RightButton}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={'row mt-4'} style={{ height: '447px' }}>
          <div
            className={'col-12 no-gutters'}
            style={{ height: '100%', width: '100%' }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={selectedButton === 0 ? incomeData : salesData}
                margin={{
                  top: 5,
                  right: 13,
                  left: 3,
                  bottom: 5,
                }}
                barSize={10}
              >
                <XAxis
                  dataKey="name"
                  tick={{ fontFamily: 'Poppins', fontSize: '12px' }}
                />
                <YAxis
                  allowDecimals={false}
                  unit={'€'}
                  tick={{ fontFamily: 'Poppins', fontSize: '12px' }}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  itemStyle={{
                    color: selectedButton === 0 ? '#4643D3' : '#FFB810',
                  }}
                  labelFormatter={(name) =>
                    selectedButton === 0
                      ? `Incassato - ${name}`
                      : `Fatturato - ${name}`
                  }
                  formatter={(value) => `${value}€`}
                  contentStyle={{
                    borderRadius: '17px',
                    borderColor: selectedButton === 0 ? '#4643D3' : '#FFB810',
                    font: 'normal normal normal 14px/22px Poppins',
                  }}
                />
                <Bar
                  dataKey={'Totale'}
                  fill={selectedButton === 0 ? '#9DADFC' : '#FFB810'}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
