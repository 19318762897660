import './progressBar.css';
import lang from './progressBar.json';

interface Props {
  currentStep: number;
  numberOfSteps: number;
  noText?: boolean;
}

export function ProgressBar(props: Props) {
  const { currentStep } = props;
  const { numberOfSteps } = props;
  const { noText } = props;

  if (currentStep > numberOfSteps) {
    return null;
  }

  const activeWidth = (100 * currentStep) / numberOfSteps;
  const inactiveWidth = 100 - activeWidth;

  return (
    <>
      <div className={'progress-bar-text-top'}>
        {lang.Text1}0{currentStep}
        {lang.Text2}0{numberOfSteps}
      </div>
      <div className={'row quickfisco-progress-bar pe-5 ps-5 my-1 mx-auto'}>
        <div
          className={`${
            inactiveWidth === 0
              ? 'progress-bar-active quickfisco-progress-bar-fully'
              : 'progress-bar-active'
          }`}
          style={{ width: activeWidth + '%' }}
        />
        {inactiveWidth > 0 && (
          <div
            className={'progress-bar-inactive'}
            style={{ width: inactiveWidth + '%' }}
          />
        )}
      </div>
      {!noText && (
        <div className={'progress-bar-text-bottom'}>
          {currentStep === 1 ? lang.Text3 : lang.Text4}
        </div>
      )}
    </>
  );
}
