import { useEffect, useState } from 'react';
import config from '../../../config.json';
import { TaxDeadlineModel, TaxDeadlineStatus } from '../../models/taxDeadlineModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { editTaxCalculation, getTaxCalculation } from '../../redux/slices/taxCalculationSlice';
import { formatDateIT } from '../../utils/date';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './taxEditDeadlineArtisanAndTraders.json';

interface Props {
    deadline: TaxDeadlineModel;
    index: number;
}

interface DeadlineAttributes {
    minimalContribution?: string,
    variablesContributionBalance?: string,
    variablesContributionDeposit?: string,
    taxBalance?: string,
    taxDeposit?: string,
    contributionBalance?: string,
    contributionDeposit?: string,
}

export function TaxEditDeadlineArtisanAndTrader(props: Props) {
    const { deadline } = props;
    const { index } = props;

    const [totalAmount, setTotalAmount] = useState<number>(parseNumber(formatNumberIT(deadline.totalAmount)));
    const [taxAmount, setTaxAmount] = useState<number>(parseNumber(formatNumberIT(deadline.taxAmount)));
    const [taxBalance, setTaxBalance] = useState<number>(parseNumber(formatNumberIT(deadline.taxBalance)));
    const [taxDeposit, setTaxDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.taxDeposit)));
    const [contributionAmount, setContributionAmount] = useState<number>(parseNumber(formatNumberIT(deadline.contributionAmount)));
    const [contributionBalance, setContributionBalance] = useState<number>(parseNumber(formatNumberIT(deadline.contributionBalance)));
    const [contributionDeposit, setContributionDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.contributionDeposit)));
    const [minimalContribution, setMinimalContribution] = useState<number>(parseNumber(formatNumberIT(deadline.minimalContribution)));
    const [variablesContributionAmount, setVariablesContributionAmount] = useState<number>(parseNumber(formatNumberIT(deadline.variablesContributionAmount)));
    const [variablesContributionDeposit, setVariablesContributionDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.variablesContributionDeposit)));
    const [variablesContributionBalance, setVariablesContributionBalance] = useState<number>(parseNumber(formatNumberIT(deadline.variablesContributionBalance)));

    const taxCalculationState = useAppSelector(state => state.taxCalculation);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setTaxAmount(taxBalance + taxDeposit);
        setContributionAmount(contributionBalance + contributionDeposit);
        setTotalAmount(contributionBalance + contributionDeposit + taxBalance + taxDeposit + minimalContribution + variablesContributionBalance + variablesContributionDeposit);
        setVariablesContributionAmount(variablesContributionBalance + variablesContributionDeposit)
    }, [contributionDeposit, contributionBalance, taxDeposit, taxBalance, minimalContribution, variablesContributionBalance, variablesContributionDeposit]);

    const deadlineYear = new Date(deadline.expirationDate).getFullYear()
    const dataVisualizeMap: Map<number, DeadlineAttributes> = new Map([
        [
            0,
            {
                minimalContribution: '4º Rata ' + (deadlineYear - 1)
            }
        ],
        [
            1,
            {
                minimalContribution: '1º Rata ' + deadlineYear
            }
        ],
        [
            2,
            {
                variablesContributionBalance: 'Saldo ' + (deadlineYear - 1),
                variablesContributionDeposit: 'Acconto Rata 1 ' + deadlineYear,
                taxBalance: 'Saldo ' + (deadlineYear - 1),
                taxDeposit: 'Acconto Rata 1 ' + deadlineYear,
            }
        ],
        [
            3,
            {
                minimalContribution: '2º Rata ',
                year: deadlineYear
            }
        ],
        [
            4,
            {
                minimalContribution: '3º Rata ',
                year: deadlineYear
            }
        ],
        [
            5,
            {
                variablesContributionDeposit: 'Acconto Rata 2 ' + deadlineYear,
                taxDeposit: 'Acconto Rata 2 ' + deadlineYear,
            }
        ],
        [
            6,
            {
                minimalContribution: '4º Rata ' + (deadlineYear - 1)
            }
        ],
        [
            7,
            {
                minimalContribution: '1º Rata ' + deadlineYear
            }
        ],
        [
            8,
            {
                variablesContributionBalance: 'Saldo ' + (deadlineYear - 1),
                variablesContributionDeposit: 'Acconto Rata 1 ' + deadlineYear,
                taxBalance: 'Saldo ' + (deadlineYear - 1),
                taxDeposit: 'Acconto Rata 1 ' + deadlineYear,
            }
        ],
        [
            9,
            {
                minimalContribution: '2º Rata ',
                year: deadlineYear
            }
        ],
        [
            10,
            {
                minimalContribution: '3º Rata ',
                year: deadlineYear
            }
        ],
        [
            11,
            {
                variablesContributionDeposit: 'Acconto Rata 2 ' + deadlineYear,
                taxDeposit: 'Acconto Rata 2 ' + deadlineYear,
            }
        ],
    ])

    return (
        <div key={'tax-forecast-edit-deadline' + deadline.id}>
            <div className={'row mt-3'}>
                <div className={'col-12'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <span className={'tax-forecast-deadline-text fw-normal'}>
                                {lang.expiration}
                            </span>
                        </div>
                    </div>
                    <div className={'row d-flex justify-content-between align-items-end'}>
                        <div className={'col-auto'}>
                            <span className={'tax-forecast-deadline-date'}>
                                {deadline.expirationDate && formatDateIT(deadline.expirationDate)}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <span className={'tax-forecast-deadline-amount'}>
                                {formatNumberIT(totalAmount)}{config.EUR}
                            </span>
                        </div>
                    </div>
                    {
                        dataVisualizeMap.get(index)?.minimalContribution && (
                            <div>

                                <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {lang.minimalContributions}
                                        </span>
                                    </div>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {formatNumberIT(minimalContribution)}{config.EUR}
                                        </span>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'tax-forecast-edit-deadline-line'} />
                                    </div>
                                </div>
                                <div className={'row mt-3'}>
                                    <div className={'col-12'}>
                                        <QuickfiscoInput
                                            id='tax-edit-deadline-artisan-and-traders-minimal-contribution-input'
                                            key={'input-minimal-contribution-' + deadline.id}
                                            styleType={'default'}
                                            type={'text'}
                                            label={dataVisualizeMap.get(index)?.minimalContribution}
                                            placeholder={lang.placeholder}
                                            defaultValue={formatNumberIT(minimalContribution)}
                                            onChange={e => setMinimalContribution(parseNumber(e.target.value))}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (
                            dataVisualizeMap.get(index)?.variablesContributionBalance ||
                            dataVisualizeMap.get(index)?.variablesContributionDeposit
                        ) && (
                            <div>
                                <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {lang.variablesContribution}
                                        </span>
                                    </div>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {formatNumberIT(variablesContributionAmount)}{config.EUR}
                                        </span>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'tax-forecast-edit-deadline-line'} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.variablesContributionBalance && (
                            <div className={'row mt-3'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-variables-contribution-balance-input'
                                        key={'input-variables-contribution-balance' + deadline.id}
                                        type={'text'}
                                        label={dataVisualizeMap.get(index)?.variablesContributionBalance}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(variablesContributionBalance)}
                                        styleType={'default'}
                                        onChange={e => setVariablesContributionBalance(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.variablesContributionDeposit && (
                            <div className={'row mt-2'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-variables-contribution-deposit-input'
                                        key={'input-variables-contribution-deposit' + deadline.id}
                                        type={'text'}
                                        styleType={'default'}
                                        label={dataVisualizeMap.get(index)?.variablesContributionDeposit}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(variablesContributionDeposit)}
                                        onChange={e => setVariablesContributionDeposit(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        (
                            dataVisualizeMap.get(index)?.contributionBalance ||
                            dataVisualizeMap.get(index)?.contributionDeposit
                        ) && (
                            <div>
                                <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {lang.contributions}
                                        </span>
                                    </div>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {formatNumberIT(contributionAmount)}{config.EUR}
                                        </span>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'tax-forecast-edit-deadline-line'} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.contributionBalance && (
                            <div className={'row mt-3'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-contribution-balance-input'
                                        key={'input-contribution-balance' + deadline.id}
                                        type={'text'}
                                        label={dataVisualizeMap.get(index)?.contributionBalance}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(contributionBalance)}
                                        styleType={'default'}
                                        onChange={e => setContributionBalance(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.contributionDeposit && (
                            <div className={'row mt-2'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-contribution-deposit-input'
                                        key={'input-contribution-deposit' + deadline.id}
                                        type={'text'}
                                        label={dataVisualizeMap.get(index)?.contributionDeposit}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(contributionDeposit)}
                                        styleType={'default'}
                                        onChange={e => setContributionDeposit(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        (
                            dataVisualizeMap.get(index)?.taxBalance ||
                            dataVisualizeMap.get(index)?.taxDeposit
                        ) && (
                            <div>
                                <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {lang.tax}
                                        </span>
                                    </div>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {formatNumberIT(taxAmount)}{config.EUR}
                                        </span>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'tax-forecast-edit-deadline-line'} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.taxBalance && (
                            <div className={'row mt-3'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-tax-balance-input'
                                        key={'input-tax-balance' + deadline.id}
                                        type={'text'}
                                        label={dataVisualizeMap.get(index)?.taxBalance}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(taxBalance)}
                                        styleType={'default'}
                                        onChange={e => setTaxBalance(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.taxDeposit && (
                            <div className={'row mt-2'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-tax-deposit-input'
                                        key={'input-tax-deposit' + deadline.id}
                                        type={'text'}
                                        label={dataVisualizeMap.get(index)?.taxDeposit}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(taxDeposit)}
                                        styleType={'default'}
                                        onChange={e => setTaxDeposit(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className={'row mt-3 d-flex justify-content-between align-items-center'}>
                        <div className={'col-auto'}>
                            {
                                deadline.status === TaxDeadlineStatus.VALID ? (
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <span className={'tax-forecast-deadline-text-valid'}>
                                                {lang.valid}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <span className={'tax-forecast-deadline-text-not-valid'}>
                                                {lang.notValid}
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={'col-5'}>
                            {
                                taxCalculationState.editStatus === 'loading' ? (
                                    <QuickfiscoSpinner />
                                ) : (
                                    <QuickfiscoButton
                                        id={'save-tax-calculation-deadline'}
                                        label={lang.buttonLabel}
                                        type={'primary'}
                                        key={'edit-deadline' + deadline.id}
                                        onClick={() => dispatch(editTaxCalculation({
                                            contributionDeposit,
                                            contributionBalance,
                                            taxDeposit,
                                            taxBalance,
                                            id: deadline.id,
                                            expirationDate: deadline.expirationDate,
                                            totalAmount,
                                            status: deadline.status,
                                            taxAmount,
                                            contributionAmount,
                                            minimalContribution,
                                            variablesContributionAmount,
                                            variablesContributionBalance,
                                            variablesContributionDeposit
                                        }))}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
