import { ReactElement, useEffect } from 'react';
import { InvoiceStatusFiltersType, StsInvoiceStatusFilterType } from '../../models/invoiceFilters';
import { InvoiceType } from '../../models/invoiceModel';
import { InvoiceReportModel } from '../../models/invoiceReportModel';
import { StsInvoiceReportModel } from '../../models/stsInvoiceReportReportModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCustomerInvoiceReports, setCustomerInvoiceListStatusFilter, setCustomerInvoiceListTypeFilter } from '../../redux/slices/customerInvoiceListSlice';
import { getStsInvoiceReports, setStsInvoiceListStatusFilter } from '../../redux/slices/stsInvoiceListSlice';
import { getSupplierInvoiceReports, setSupplierInvoiceListStatusFilter } from '../../redux/slices/supplierInvoiceListSlice';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { InvoiceLRColor, InvoiceReport } from '../invoiceReport/invoiceReport';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';

interface Props {
  invoiceSign: InvoiceSign
}

interface Attribute {
  title: string,
  color: InvoiceLRColor,
  amount?: number
  typeFilter?: InvoiceType
  statusFilter?: InvoiceStatusFiltersType,
  stsStatusFilter?: StsInvoiceStatusFilterType,
  setTypeFilter?: (type?: InvoiceType) => void,
  setStatusFilter?: (status?: InvoiceStatusFiltersType) => void,
  setStsStatusFilter?: (status?: StsInvoiceStatusFilterType) => void,
  selected: boolean,
}

export function InvoiceReportList(props: Props) {
  const { invoiceSign } = props;

  const dispatch = useAppDispatch()

  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const supplierInvoiceListState = useAppSelector(state => state.supplierInvoiceList)
  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)

  useEffect(() => {
    dispatch(getCustomerInvoiceReports(customerInvoiceListState.yearFilter));
    dispatch(getSupplierInvoiceReports(supplierInvoiceListState.yearFilter));
    dispatch(getStsInvoiceReports(invoiceSign === 'sts' ? stsInvoiceListState.yearFilter : customerInvoiceListState.yearFilter));
  }, [customerInvoiceListState.yearFilter, supplierInvoiceListState.yearFilter, stsInvoiceListState.yearFilter])

  let status: PromiseStatuses;
  let attributes: Attribute[]
  let reports: InvoiceReportModel | StsInvoiceReportModel

  switch (invoiceSign) {
    case 'customer':
      status = customerInvoiceListState.reportsStatus;
      reports = customerInvoiceListState.reports;
      attributes = [
        {
          title: "Totale attive",
          color: InvoiceLRColor.BLACK,
          amount: reports.totalInvoice,
          typeFilter: undefined,
          statusFilter: undefined,
          setTypeFilter: () => dispatch(setCustomerInvoiceListTypeFilter(undefined)),
          setStatusFilter: () => dispatch(setCustomerInvoiceListStatusFilter(undefined)),
          selected: false,
        },
        {
          title: "Incassate",
          color: InvoiceLRColor.BLUE,
          amount: reports.paidInvoice,
          typeFilter: undefined,
          statusFilter: InvoiceStatusFiltersType.PAID,
          setTypeFilter: () => dispatch(setCustomerInvoiceListTypeFilter(undefined)),
          setStatusFilter: () => dispatch(setCustomerInvoiceListStatusFilter(InvoiceStatusFiltersType.PAID)),
          selected: customerInvoiceListState.statusFilter === InvoiceStatusFiltersType.PAID,
        },
        {
          title: 'Da incassare',
          color: InvoiceLRColor.YELLOW,
          amount: reports.toPayInvoice,
          typeFilter: undefined,
          statusFilter: InvoiceStatusFiltersType.TO_PAY,
          setTypeFilter: () => dispatch(setCustomerInvoiceListTypeFilter(undefined)),
          setStatusFilter: () => dispatch(setCustomerInvoiceListStatusFilter(InvoiceStatusFiltersType.TO_PAY)),
          selected: customerInvoiceListState.statusFilter === InvoiceStatusFiltersType.TO_PAY,
        },
        {
          title: 'Scadute',
          color: InvoiceLRColor.RED,
          amount: reports.expiredInvoice,
          typeFilter: undefined,
          statusFilter: InvoiceStatusFiltersType.EXPIRED,
          setTypeFilter: () => dispatch(setCustomerInvoiceListTypeFilter(undefined)),
          setStatusFilter: () => dispatch(setCustomerInvoiceListStatusFilter(InvoiceStatusFiltersType.EXPIRED)),
          selected: customerInvoiceListState.statusFilter === InvoiceStatusFiltersType.EXPIRED,
        }
      ]
      break;
    case 'supplier':
      status = supplierInvoiceListState.reportsStatus;
      reports = supplierInvoiceListState.reports;
      attributes = [
        {
          title: "Totale passive",
          color: InvoiceLRColor.BLACK,
          amount: reports.totalInvoice,
          statusFilter: undefined,
          setStatusFilter: () => dispatch(setSupplierInvoiceListStatusFilter(undefined)),
          selected: false,
        },
        {
          title: "Pagate",
          color: InvoiceLRColor.BLUE,
          amount: reports.paidInvoice,
          statusFilter: InvoiceStatusFiltersType.PAID,
          setStatusFilter: () => dispatch(setSupplierInvoiceListStatusFilter(InvoiceStatusFiltersType.PAID)),
          selected: supplierInvoiceListState.statusFilter === InvoiceStatusFiltersType.PAID,
        },
        {
          title: 'Da saldare',
          color: InvoiceLRColor.YELLOW,
          amount: reports.toPayInvoice,
          statusFilter: InvoiceStatusFiltersType.TO_PAY,
          setStatusFilter: () => dispatch(setSupplierInvoiceListStatusFilter(InvoiceStatusFiltersType.TO_PAY)),
          selected: supplierInvoiceListState.statusFilter === InvoiceStatusFiltersType.TO_PAY,
        },
        {
          title: 'Scadute',
          color: InvoiceLRColor.RED,
          amount: reports.expiredInvoice,
          statusFilter: InvoiceStatusFiltersType.EXPIRED,
          setStatusFilter: () => dispatch(setSupplierInvoiceListStatusFilter(InvoiceStatusFiltersType.EXPIRED)),
          selected: supplierInvoiceListState.statusFilter === InvoiceStatusFiltersType.EXPIRED,
        }
      ]
      break;
    case 'sts':
      status = stsInvoiceListState.reportsStatus;
      reports = stsInvoiceListState.reports;
      attributes = [
        {
          title: "Totale",
          color: InvoiceLRColor.BLACK,
          amount: reports.total,
          stsStatusFilter: undefined,
          setStsStatusFilter: () => dispatch(setStsInvoiceListStatusFilter(undefined)),
          selected: false,
        },
        {
          title: "Inviate STS",
          color: InvoiceLRColor.BLUE,
          amount: reports.sent,
          stsStatusFilter: StsInvoiceStatusFilterType.SEND,
          setStsStatusFilter: () => dispatch(setStsInvoiceListStatusFilter(StsInvoiceStatusFilterType.SEND)),
          selected: stsInvoiceListState.statusFilter === StsInvoiceStatusFilterType.SEND,
        },
        {
          title: "In attesa STS",
          color: InvoiceLRColor.YELLOW,
          amount: reports.pending,
          stsStatusFilter: StsInvoiceStatusFilterType.QUEUED,
          setStsStatusFilter: () => dispatch(setStsInvoiceListStatusFilter(StsInvoiceStatusFilterType.QUEUED)),
          selected: stsInvoiceListState.statusFilter === StsInvoiceStatusFilterType.QUEUED,
        },
        {
          title: 'Da inviare STS',
          color: InvoiceLRColor.PURPLE,
          amount: reports.toSend,
          stsStatusFilter: StsInvoiceStatusFilterType.TO_SEND,
          setStsStatusFilter: () => dispatch(setStsInvoiceListStatusFilter(StsInvoiceStatusFilterType.TO_SEND)),
          selected: stsInvoiceListState.statusFilter === StsInvoiceStatusFilterType.TO_SEND,
        },
        {
          title: 'Rifiutata STS',
          color: InvoiceLRColor.RED,
          amount: reports.rejected,
          stsStatusFilter: StsInvoiceStatusFilterType.REJECTED,
          setStsStatusFilter: () => dispatch(setStsInvoiceListStatusFilter(StsInvoiceStatusFilterType.REJECTED)),
          selected: stsInvoiceListState.statusFilter === StsInvoiceStatusFilterType.REJECTED,
        }
      ]
      break;
    default:
      return (
        <div>Tipo di fattura non valido.</div>
      )
  }

  if (status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || !reports) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className={'p-blue'}>
          Errore durante il caricamento dei reports.
        </div>
      </div>
    );
  }

  let reportsList: ReactElement[] = [];

  attributes.forEach((value) => {
    reportsList.push(
      <div className={'col'} key={'report-' + JSON.stringify(value)}>
        <div>
          <InvoiceReport
            title={value.title}
            color={value.color}
            amount={value.amount}
            typeFilter={value.typeFilter}
            statusFilter={value.statusFilter}
            stsStatusFilter={value.stsStatusFilter}
            setTypeFilter={value.setTypeFilter}
            setStatusFilter={value.setStatusFilter}
            setStsStatusFilter={value.setStsStatusFilter}
            selected={value.selected}
          />
        </div>
      </div>
    )
  })

  return (
    <div className={'row'}>
      {reportsList}
    </div>
  )
}
