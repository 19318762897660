import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import { InvoiceModel } from '../../models/invoiceModel';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { Operation, PromiseStatuses } from '../../types/strings';

interface StsInvoiceSaveOrDuplicateOrEditState {
  invoiceId?: string;
  operation: Operation;
  successfullySaved: boolean;
  editStatus: PromiseStatuses
}

const initialState: StsInvoiceSaveOrDuplicateOrEditState = {
  operation: 'save',
  successfullySaved: false,
  editStatus: 'idle'
};

export const editStsInvoice = createAsyncThunk(
  'stsInvoiceSaveOrDuplicateOrEdit/editStsInvoice',
  async (request: { invoice: InvoiceModel, fileList: File[], errorSetters: InvoiceErrorSetters }): Promise<void> => {
    const customerInvoiceService = new StsInvoiceService(request.errorSetters);

    return customerInvoiceService.edit(request.invoice, request.fileList, [])
      .catch(err => {
        err
          .json()
          .then((data: any) => {
            if (data.message === 'error.invalid-date') {
              request.errorSetters.setErrorDateMessage && request.errorSetters.setErrorDateMessage('Esiste già una fattura con data successiva ma numero inferiore.');
              request.errorSetters.setErrorDate && request.errorSetters.setErrorDate(true);
            }
          });
      })
  }
);

const stsInvoiceSaveOrDuplicateOrEditSlice = createSlice({
  name: 'stsInvoiceSaveOrDuplicateOrEdit',
  initialState,
  reducers: {
    setStsInvoiceSaveOrDuplicateOrEditInvoiceId: (state, action: PayloadAction<string | undefined>) => {
      state.invoiceId = action.payload
    },
    setStsInvoiceSaveOrDuplicateOrEditOperation: (state, action: PayloadAction<Operation>) => {
      state.operation = action.payload
    },
    setStsInvoiceSaveOrDuplicateOrEditSuccessfullySaved: (state, action: PayloadAction<boolean>) => {
      state.successfullySaved = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editStsInvoice.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editStsInvoice.fulfilled, (state) => {
        state.editStatus = 'successfully';
      })
      .addCase(editStsInvoice.rejected, (state) => {
        state.editStatus = 'failed';
      })
  }
});

export const {
  setStsInvoiceSaveOrDuplicateOrEditInvoiceId,
  setStsInvoiceSaveOrDuplicateOrEditOperation,
  setStsInvoiceSaveOrDuplicateOrEditSuccessfullySaved
} = stsInvoiceSaveOrDuplicateOrEditSlice.actions;

export default stsInvoiceSaveOrDuplicateOrEditSlice.reducer;
