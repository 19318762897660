import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './platformGuide.css';

export function PlatformGuide() {
  return (
    <div className="platform-guide-container box-shadow p-4">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 platform-guide-title">
              Inizia da qui!
              <QuickfiscoIcon name="hand-down.png" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 platform-guide-text">
              Consulta
              <b> i nostri tutorial </b>e se hai ancora dubbi{' '}
              <b>scrivici su whatsapp</b>
            </div>
          </div>
          <div className="row mt-2 mb-2">
            <div className="col-8 offset-2">
              <a
                href={'https://quickfisco.it/tutorial/'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <QuickfiscoButton
                  id="platform-guide-button"
                  label={'VAI AI TUTORIAL'}
                  type={'primary'}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
