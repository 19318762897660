import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import config from '../../../config.json';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  sendEmail,
  setFinanceStep,
  setFinanceUserRequestFinancingOngoing,
  setFinanceUserRequestFinancingOngoingTotal,
  setFinanceUserRequestIncome,
  setFinanceUserRequestMortageOrRental,
  setFinanceUserRequestMortageOrRentalAmount,
  setFinancing,
  setOpenSendEmailModal,
} from '../../redux/slices/financeSlice';
import { FinanceService } from '../../services/financeService';
import { FinanceSendEmailModal } from '../financeSendEmailModal/financeSendEmailModal';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputRadio } from '../quickfiscoInputRadio/quickfiscoInputRadio';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './financeCheckFinanceability.css';

export function FinanceCheckFinanceability() {
  const userState = useAppSelector((state) => state.user);
  const financeState = useAppSelector((state) => state.finance);

  const [errorMortage, setErrorMortage] = useState<boolean>(false);
  const [errorFinancing, setErrorFinancing] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setFinanceUserRequestIncome(userState.user.latestTotalIncome?.amount)
    );
  }, []);

  useEffect(() => {
    if (financeState.sendEmailStatus === 'successfully') {
      dispatch(setOpenSendEmailModal(true));
    }
  }, [financeState.sendEmailStatus]);

  const financeService = new FinanceService({
    setErrorMortage,
    setErrorFinancing,
  });

  return (
    <div className={'financeCheckFinanceability-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 35,
          width: '50%',
          justifyContent: 'space-between',
        }}
      >
        <span
          style={{
            textAlign: 'left',
            font: 'normal normal 600 20px/30px Poppins',
            color: '#1E1E23',
            width: '100%',
          }}
        >
          1. Scopri se sei finanziabile!
        </span>
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
              flexGrow: 1,
            }}
          >
            <span
              className="financeCheckFinanceability-title"
              style={{ width: '100%' }}
            >
              Il tuo reddito cumulato dell’anno precedente
            </span>
            <div style={{ width: '100%' }}>
              <NumericFormat
                id={'financeCheckFinanceability-last-year-incoming'}
                key={'financeCheckFinanceability-last-year-incoming'}
                styleType={'medium'}
                label="Reddito cumulato rilevato dal tuo ultimo Modello Redditi Persone fisiche"
                value={financeState.userFinanceRequest.data.income}
                prefix="€ "
                decimalSeparator=","
                thousandSeparator="."
                disabled
                decimalScale={2}
                customInput={QuickfiscoInput}
                placeholder="0.00€"
              />
            </div>
          </div>
          {financeState.userFinanceRequest.data.income > 0 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20,
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <span className="financeCheckFinanceability-title">
                  Hai un mutuo o un affitto?
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 40,
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <QuickfiscoInputRadio
                    onChange={() =>
                      dispatch(setFinanceUserRequestMortageOrRental(true))
                    }
                    id={'financeCheckFinanceability-true'}
                    key={'financeCheckFinanceability-true'}
                    value={'SI'}
                    label="SI"
                    name="financeCheckFinanceability"
                    checked={
                      financeState.userFinanceRequest.data.mortgage_or_rental
                    }
                  />
                  <QuickfiscoInputRadio
                    id={'financeCheckFinanceability-false'}
                    key={'financeCheckFinanceability-false'}
                    value={'NO'}
                    label="NO"
                    onChange={() => {
                      dispatch(setFinanceUserRequestMortageOrRental(false));
                      dispatch(setFinanceUserRequestMortageOrRentalAmount(''));
                      setErrorMortage(false);
                    }}
                    name="financeCheckFinanceability"
                    checked={
                      !financeState.userFinanceRequest.data.mortgage_or_rental
                    }
                  />
                </div>
                {financeState.userFinanceRequest.data.mortgage_or_rental && (
                  <div>
                    <NumericFormat
                      customInput={QuickfiscoInput}
                      key={'financeCheckFinanceability-loans'}
                      id={'financeCheckFinanceability-loans'}
                      styleType={'medium'}
                      label="Inserisci l’importo mensile complessivo dei tuoi mutui e/o affitti"
                      prefix="€ "
                      defaultValue={
                        financeState.userFinanceRequest.data
                          .mortgage_or_rental_amount
                      }
                      value={
                        financeState.userFinanceRequest.data
                          .mortgage_or_rental_amount
                      }
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      onValueChange={(e) => {
                        dispatch(
                          setFinanceUserRequestMortageOrRentalAmount(
                            e.floatValue
                          )
                        );
                        financeService.validateMortage(
                          financeState.userFinanceRequest.data
                            .mortgage_or_rental,
                          e.floatValue
                        );
                      }}
                      placeholder="0.00€"
                      error={errorMortage}
                      errorLabel="Inserire un importo maggiore di 0"
                    />
                    <span className="financeCheckFinanceability-info-text">
                      Se hai più mutui o affitti, inserisci l’importo
                      complessivo che sostieni mensilmente sommando ogni singola
                      spesa
                    </span>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20,
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <span className="financeCheckFinanceability-title">
                  Hai finanziamenti in corso?
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 40,
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <QuickfiscoInputRadio
                    id={'financeCheckFinanceability-true-finance'}
                    key={'financeCheckFinanceability-true-finance'}
                    value={'SI'}
                    label="SI"
                    name="financeCheckFinanceability-finance"
                    checked={
                      financeState.userFinanceRequest.data.financing_ongoing
                    }
                    onChange={() => {
                      dispatch(setFinanceUserRequestFinancingOngoing(true));
                    }}
                  />
                  <QuickfiscoInputRadio
                    id={'financeCheckFinanceability-false-finance'}
                    key={'financeCheckFinanceability-false-finance'}
                    value={'NO'}
                    label="NO"
                    name="financeCheckFinanceability-finance"
                    checked={
                      !financeState.userFinanceRequest.data.financing_ongoing
                    }
                    onChange={() => {
                      dispatch(setFinanceUserRequestFinancingOngoing(false));
                      dispatch(setFinanceUserRequestFinancingOngoingTotal(''));
                      setErrorFinancing(false);
                    }}
                  />
                </div>
                {financeState.userFinanceRequest.data.financing_ongoing && (
                  <div>
                    <NumericFormat
                      customInput={QuickfiscoInput}
                      id={'financeCheckFinanceability-finance'}
                      key={'financeCheckFinanceability-finance'}
                      styleType={'medium'}
                      label="Inserisci l’importo mensile totale dei tuoi finanziamenti attivi"
                      prefix="€ "
                      defaultValue={
                        financeState.userFinanceRequest.data
                          .financing_ongoing_total
                      }
                      value={
                        financeState.userFinanceRequest.data
                          .financing_ongoing_total
                      }
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      onValueChange={(e) => {
                        dispatch(
                          setFinanceUserRequestFinancingOngoingTotal(
                            e.floatValue
                          )
                        );
                        financeService.validateFinancing(
                          financeState.userFinanceRequest.data
                            .financing_ongoing,
                          e.floatValue
                        );
                      }}
                      placeholder="0.00€"
                      error={errorFinancing}
                      errorLabel="Inserire un importo maggiore di 0"
                    />
                    <span className="financeCheckFinanceability-info-text">
                      Se hai più finanziamenti attivi inserisci la somma di
                      tutti i finanziamenti ovvero l’importo complessivo che
                      sostieni mensilmente
                    </span>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 100,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
                className='mt-4'
              >
                <QuickfiscoButton
                  key={'finance-check-financiability-back-button'}
                  id={'finance-check-financiability-back-button'}
                  label={'INDIETRO'}
                  onClick={() => dispatch(setFinanceStep(0))}
                  type={'primary'}
                />
                <QuickfiscoButton
                  key={'finance-check-financiability-next-button'}
                  id={'finance-check-financiability-button-2'}
                  label={'CONTINUA'}
                  onClick={() => {
                    if (
                      financeService.validateCheckFinanceability(
                        financeState.userFinanceRequest
                      )
                    ) {
                      dispatch(
                        setFinancing(
                          financeService.checkFinanceability(
                            financeState.userFinanceRequest
                          )
                        )
                      );
                      dispatch(setFinanceStep(2));
                    }
                  }}
                  type={'secondary'}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 20,
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{ flexGrow: 1, flexBasis: '45%' }}
                className="financeCheckFinanceability-error"
              >
                Non è stato rilevato alcun reddito. Contattaci per maggiori
                informazioni.
              </span>
              <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                {financeState.sendEmailStatus === 'loading' ? (
                  <QuickfiscoSpinner />
                ) : (
                  <QuickfiscoButton
                    key={'finance-check-financiability-next-button'}
                    id={'finance-check-financiability-info'}
                    label={'RICHIEDI INFORMAZIONI'}
                    onClick={() => dispatch(sendEmail())}
                    type={
                      financeState.sendEmailStatus === 'successfully'
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
      <FinanceSendEmailModal />
    </div>
  );
}
