import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './taxProfileCurrentYearDataPopup.css';
import lang from './taxProfileCurrentYearDataPopup.json';

export function TaxProfileCurrentYearDataPopup() {
  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon
            name={'info.svg'}
          />
        </div>
        <div
          ref={componentVisible.ref}
          className={'tax-profile-current-year-data-popup'}
        >
          {
            componentVisible.isVisible && (
              <div className={'tax-profile-current-year-data-popup-container'}>
                <p className={'tax-profile-current-year-data-popup-text'}>
                  {lang.label1}
                  <span className={'fw-bold'}>
                    {lang.label2}
                  </span>
                  {lang.label3}
                  <span className={'fw-bold'}>
                    {lang.label4}
                  </span>
                  {lang.label5}
                  <span className={'text-decoration-underline'}>
                    {lang.label6}
                  </span>
                  {lang.label7}
                </p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
