import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTaxCalculation, setEditStatus } from '../../redux/slices/taxCalculationSlice';
import { editTaxProfile, getTaxProfile } from '../../redux/slices/taxProfileSlice';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxEditDeadlineFactory } from '../taxEditDeadlineFactory/taxEditDeadlineFactory';
import './taxEditDeadlinesList.css';
import lang from './taxEditDeadlinesList.json';

interface Props {
  open: boolean,
  hide: Function,
}

export function TaxEditDeadlinesList(props: Props) {
  const { open } = props;
  const { hide } = props;

  const dispatch = useAppDispatch()
  const taxProfileState = useAppSelector(state => state.taxProfile);
  const taxCalculationState = useAppSelector(state => state.taxCalculation);

  useEffect(() => {
    if (taxCalculationState.editStatus === 'successfully') {
      dispatch(getTaxCalculation(taxCalculationState.year))
      dispatch(editTaxProfile(taxProfileState.profile)).then(() => dispatch(getTaxProfile()))
      hide()
      dispatch(setEditStatus('idle'))
    }
  }, [taxCalculationState.editStatus]);

  if (taxCalculationState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (taxCalculationState.status === 'failed' || !taxCalculationState.forecast) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const deadlines = taxCalculationState.forecast.deadlinesResponse;

  const editableItems: React.ReactNode[] = [];
  deadlines.forEach((_, index) => {
    editableItems.push(<TaxEditDeadlineFactory
      key={'deadline-edit-input-' + index}
      index={index}
      deadline={deadlines[index]}
    />);
  });

  return (
    <div>
      <QuickfiscoModal hide={hide} isOpen={open}>
        <div className={'p-4 pt-0 tax-forecast-edit-deadlines-container'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-forecast-edit-deadlines-title'}>
                {lang.title}
              </span>
            </div>
          </div>
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <p className={'tax-forecast-edit-deadlines-info'}>
                {lang.info.line1}
                <span className={'tax-forecast-edit-deadlines-info-bold'}>
                  {lang.info.line2}
                </span>
                {lang.info.line3}
                <span className={'tax-forecast-edit-deadlines-info-blue'}>
                  {lang.info.line4}
                </span>
                {lang.info.line5}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              {editableItems}
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}
