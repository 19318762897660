import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { UserCategory } from '../incomeUploadModal/incomeUploadModal';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import lang from './incomeUploadModalStep4.json';
import './incomeUploadModalStep4.css';

interface Props {
  qfUsedFlag: boolean | undefined;
  userCategory: UserCategory;
  telematicFeesRegister: boolean | undefined;
  setTelematicFeesRegister: (response: boolean) => void;
  onClickPrevStep: () => void;
  onClickNextStep: () => void;
  onClickSend: () => void;
  setFiles: (_file: File[]) => void;
  status: PromiseStatuses;
  errorFile: boolean;
  files?: File[];
}

export function IncomeUploadModalStep4(props: Props) {
  const { qfUsedFlag } = props;
  const { telematicFeesRegister } = props;
  const { setTelematicFeesRegister } = props;
  const { userCategory } = props;
  const { onClickPrevStep } = props;
  const { onClickNextStep } = props;
  const { onClickSend } = props;
  const { files } = props;
  const { setFiles } = props;
  const { status } = props;
  const { errorFile } = props;

  return (
    <>
      {qfUsedFlag === true ? (
        <>
          <div className={'row'}>
            <div className={'col-12 income-upload-modal-step4-subtitle'}>
              {lang.subTitleYes}
            </div>
          </div>
          <div className={'row my-3'}>
            <div
              className={
                'col-10 offset-1 text-center income-upload-modal-step4-text'
              }
            >
              Carica il file excel secondo le istruzioni richieste nello step
              precedente
            </div>
          </div>
          <div className={'row mb-5'}>
            <div className={'col-8 offset-2 text-center'}>
              <QuickfiscoInputFile
                preview={true}
                multiple={false}
                maxHeight={'100px'}
                fileList={files ? files : []}
                setFileList={(_file) => setFiles([..._file])}
              />
            </div>
          </div>
          {((files?.length === 1 && userCategory === UserCategory.TRADER) ||
            (files?.length === 1 &&
              userCategory === UserCategory.TRADER479119)) && (
              <>
                <div className={'row pb-4'}>
                  <div className={'col-4 offset-4 d-flex justify-content-center'}>
                    <QuickfiscoIcon name={'alert-success.svg'} className="w-25" />
                  </div>
                </div>
                <div className={'row pb-4'}>
                  <div className={'col-12 text-center'}>
                    <div className={'income-upload-modal-step4-text-green'}>
                      File caricato correttamente
                    </div>
                  </div>
                </div>
              </>
            )}
          {files?.length === 1 && userCategory === UserCategory.ALL && (
            <>
              <div className={'row pb-4'}>
                <div className={'col-4 offset-4 d-flex justify-content-center'}>
                  <QuickfiscoIcon name={'alert-success.svg'} className="w-25" />
                </div>
              </div>
              <div className={'row pb-4'}>
                <div className={'col-12 text-center'}>
                  <div className={'income-upload-modal-step4-text-text'}>
                    <span className={'income-upload-modal-step4-text-blue'}>
                      <b>E adesso?</b>
                    </span>
                    <br />
                    <b>Verificheremo i tuoi incassi</b>. Potresti
                    ricevere delle richieste di approfondimento da un nostro
                    consulente per eventuali dubbi sugli incassi.
                    <br />
                    <br />
                    <span className={'income-upload-modal-step4-text-blue'}>
                      <b>Perchè questa eventuale verifica?</b>
                    </span>
                    <br />
                    Lo facciamo nel tuo interesse perchè vogliamo essere sicuri
                    di dichiarare l’importo corretto dei compensi affinchè tu
                    possa non avere problemi in futuro.
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={'row no-gutters my-4'}>
            <div className={'col-3 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step4-button'}
                label={lang.step4ButtonPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            {userCategory === UserCategory.TRADER ||
              userCategory === UserCategory.TRADER479119 ? (
              <div className={'col-3 offset-6 d-flex justify-content-center'}>
                <QuickfiscoButton
                  id={'income-upload-modal-step4-button'}
                  label={lang.step4buttonNext}
                  type={files?.length === 1 ? 'secondary' : 'disabled'}
                  onClick={() => onClickNextStep()}
                />
              </div>
            ) : (
              <div className={'col-3 offset-6 d-flex justify-content-center'}>
                {status === 'loading' ? (
                  <div
                    className={
                      'd-flex justify-content-center align-items-center w-100'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'income-upload-modal-step4-button'}
                    label={lang.step4buttonSend}
                    type={files?.length === 1 ? 'secondary' : 'disabled'}
                    onClick={() => onClickSend()}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {userCategory === UserCategory.TRADER && (
            <>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step4-subtitle'}>
                  {lang.subTitleTraderNo}
                </div>
              </div>
              <div
                className={'row my-3 p-4 income-upload-modal-step4-checkbox'}
              >
                <div
                  className={
                    'col-8 offset-2 d-flex align-items-start justify-content-center'
                  }
                >
                  <QuickfiscoCheckbox
                    id={'income-upload-modal-step4-response-true'}
                    label={lang.checkbox1}
                    checked={telematicFeesRegister === true}
                    onChange={(e) => setTelematicFeesRegister(true)}
                  />
                </div>
                <div
                  className={
                    'col-8 offset-2 mt-4 d-flex align-items-start justify-content-center'
                  }
                >
                  <QuickfiscoCheckbox
                    id={'income-upload-modal-step4-response-false'}
                    label={lang.checkbox2}
                    checked={telematicFeesRegister === false}
                    onChange={(e) => setTelematicFeesRegister(false)}
                  />
                </div>
              </div>
            </>
          )}
          {userCategory === UserCategory.TRADER479119 && (
            <>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step4-subtitle'}>
                  {lang.subTitleTrader479119No}
                </div>
              </div>
              <div className={'row my-3'}>
                <div
                  className={
                    'col-8 offset-2 text-center income-upload-modal-step4-text'
                  }
                >
                  Carica il file relativo ai tuoi corrispettivi emessi nel 2023
                </div>
              </div>
              <div className={'row mb-5'}>
                <div className={'col-8 offset-2 text-center'}>
                  <QuickfiscoInputFile
                    preview={true}
                    multiple={false}
                    maxHeight={'100px'}
                    fileList={files && files[1] ? [files[1]] : []}
                    setFileList={(_file) =>
                      files && files[0] && setFiles([files[0], _file[0]])
                    }
                  />
                </div>
              </div>
              {files?.length === 2 && files[1] !== undefined && (
                <>
                  <div className={'row pb-4'}>
                    <div
                      className={'col-4 offset-4 d-flex justify-content-center'}
                    >
                      <QuickfiscoIcon
                        name={'alert-success.svg'}
                        className="w-25"
                      />
                    </div>
                  </div>
                  <div className={'row pb-4'}>
                    <div className={'col-12 text-center'}>
                      <div className={'income-upload-modal-step4-text-green'}>
                        File caricato correttamente
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className={'row no-gutters my-4'}>
            <div className={'col-3 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step4-button'}
                label={lang.step4ButtonPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            <div className={'col-3 offset-6 d-flex justify-content-center'}>
              {status === 'loading' ? (
                <div
                  className={
                    'd-flex justify-content-center align-items-center w-100'
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (<QuickfiscoButton
                id={'income-upload-modal-step4-button'}
                label={lang.step4buttonSend}
                type={
                  telematicFeesRegister !== undefined ||
                    (files?.length === 2 && files[1] !== undefined)
                    ? 'secondary'
                    : 'disabled'
                }
                onClick={() => onClickSend()}
              />)}
            </div>
          </div>
        </>
      )}
      {errorFile && (
        <div className={'row my-4'}>
          <div className={'col-8 offset-2 income-upload-modal-step4-error'}>
            {lang.errorFile}
          </div>
        </div>
      )}
    </>
  );
}
