import React from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { ChildrenTaxReturnModel } from '../../models/taxReturnModel';
import { QuickfiscoDeductionSelect } from '../quickfiscoDeductionSelect/quickfiscoDeductionSelect';
import { fiscalCodeRegex } from '../../regex/regex';
import lang from './childrenAnswersInput.json';
import './childrenAnswersInput.css';

interface Props {
  id: string;
  childrenAnswers: ChildrenTaxReturnModel[];
  setChildrenAnswers: (childrenAnswers: ChildrenTaxReturnModel[]) => void;
  error?: boolean;
  errorLabel?: string;
  consortTaxDependent?: boolean;
}

export function ChildrenAnswersInput(props: Props) {
  const { id } = props;
  const { childrenAnswers } = props;
  const { setChildrenAnswers } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { consortTaxDependent } = props;

  const items = childrenAnswers.map((_, index) => {
    return getItem(index, setChildrenAnswers, childrenAnswers, consortTaxDependent);
  });

  return (
    <div className={`${id} mb-4`}>
      {items}
      <div className={'row mt-4'}>
        <div className={'col-7'}>
          {error && errorLabel && (
            <div className={'children-answers-input-error'}>{errorLabel}</div>
          )}
        </div>
        <div className={'col-5'}>
          <QuickfiscoButton
            id={'invoice-customer-save-add-product-button'}
            label={lang.AddButton}
            onClick={() =>
              setChildrenAnswers([
                ...childrenAnswers,
                {
                  taxCodeChild: '',
                  deductionChild: undefined,
                },
              ])
            }
            type={'primary'}
          />
        </div>
      </div>
    </div>
  );
}

function getItem(
  index: number,
  setChildrenAnswers: (childrenAnswers: ChildrenTaxReturnModel[]) => void,
  childrenAnswers: ChildrenTaxReturnModel[],
  consortTaxDependent?: boolean,
): React.ReactNode {
  return (
    <div
      className={`row no-gutters ${index === 0 ? 'mt-3' : ''}`}
      key={'item-' + childrenAnswers[index] + index}
    >
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <span className="children-answers-input-input-title">
              {index + 1}
              {lang.childrenSubTitle}
            </span>
          </div>
        </div>
        <div className="row">
          <div className={'col-6'}>
            <QuickfiscoInput
              id={'children-answers-tax-code-child'}
              type={'text'}
              styleType={'default'}
              label={lang.taxCodeChildLabel}
              placeholder={lang.taxCodeChildPlaceholder}
              onChange={(e) => {
                const newChildrenAnswer = [...childrenAnswers];
                newChildrenAnswer[index] = {
                  ...childrenAnswers[index],
                  taxCodeChild: e.target.value,
                };
                setChildrenAnswers([...newChildrenAnswer]);
              }}
              value={childrenAnswers[index].taxCodeChild}
              error={
                childrenAnswers[index].taxCodeChild.length > 0 &&
                !fiscalCodeRegex.test(childrenAnswers[index].taxCodeChild)
              }
              errorLabel={lang.taxCodeError}
              required={true}
            />
          </div>
          <div className={'col-5'}>
            <QuickfiscoDeductionSelect
              id={'children-answers-deduction-child'}
              value={childrenAnswers[index].deductionChild}
              onChange={(_deduction) => {
                if (_deduction) {
                  const newChildrenAnswer = [...childrenAnswers];
                  newChildrenAnswer[index] = {
                    ...childrenAnswers[index],
                    deductionChild: _deduction,
                  };
                  setChildrenAnswers([...newChildrenAnswer]);
                }
              }}
              requiredField={true}
              consortTaxDependent={consortTaxDependent}
            />
          </div>
          <div
            className={'col-1 d-flex align-items-center justify-content-end'}
          >
            <QuickfiscoIcon
              name={'delete-icon.svg'}
              isButton={true}
              onClick={() => {
                const newGoodService = childrenAnswers
                  .slice(0, index)
                  .concat(childrenAnswers.slice(index + 1));

                setChildrenAnswers([...newGoodService]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
