import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { CategoryType, UserModel } from '../../models/userModel';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { MandateService } from '../../services/mandateService';
import { MandateModel } from '../../models/mandateModel';
import { UserService } from '../../services/userService';
import uri from '../../routers/quickfiscoUri.json';
import lang from './mandateOnboarding.json';
import './mandateOnboarding.css';

export function MandateOnboarding() {
  const [allVerified, setAllVerified] = useState<
    'mandateAllVerified' | 'mandateNotVerified' | 'mandateApiError' | undefined
  >(undefined);
  const [mandateServiceResponse, setMandateServiceResponse] = useState<
    MandateModel | undefined
  >(undefined);
  const [mandateStatus, setMandateStatus] = useState<PromiseStatuses>('idle');
  const [onboardingCompletedStatus, setOnboardingCompletedStatus] =
    useState<PromiseStatuses>('idle');
  const [secondsRemaining, setSecondsRemaining] = useState<number>(60);
  const [isRetryButtonEnabled, setIsRetryButtonEnabled] =
    useState<boolean>(false);

  const user = useAppSelector((state) => state.user.editUserRequest);
  const status = useAppSelector((state) => state.user.status);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      allVerified === 'mandateNotVerified' ||
      allVerified === 'mandateApiError' ||
      !isRetryButtonEnabled
    ) {
      const timerId = setInterval(() => {
        setSecondsRemaining((prev) => {
          if (prev === 1) {
            clearInterval(timerId);
            setIsRetryButtonEnabled(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [allVerified, isRetryButtonEnabled]);

  const showSecondButton =
    (user.category === CategoryType.ARTISAN &&
      user.previousVatStatus === 'surroga') ||
    (user.category === CategoryType.TRADER &&
      user.previousVatStatus === 'surroga');

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return <OnboardingApiError statusFailed={status === 'failed'} />;
  }

  const mandateQuestion: React.ReactNode = (
    <div className="row">
      <div className="col-12">
        <QuickfiscoIcon name={'rocket.png'} />
        <h1 className="mandate-onboarding-title mb-4">
          <span>Welcome</span> on board!
        </h1>
        <p className="mandate-onboarding-text">
          L’ultimo step prima della verifica dell’account è il{' '}
          <b>
            conferimento delle deleghe necessarie
            <br />
            per poter gestire la tua partita iva
          </b>
          .
        </p>
        <p className="mandate-onboarding-text mb-0">
          Per il conferimento delle deleghe sarà necessario possedere{' '}
          <b>SPID o CIE</b> (Carta di Identità Elettronica).
        </p>
        <div className={'row no-gutters'}>
          <div className="col-12">
            <hr className="my-4" />
          </div>
        </div>
        <p className="mandate-onboarding-small-text">
          Di seguito trovi le{' '}
          <b>
            istruzioni necessarie per procedere al conferimento delle deleghe
            <br />
          </b>{' '}
          {showSecondButton ? (
            <span>
              (per il cassetto fiscale, per la fatturazione elettronica e per il
              cassetto previdenziale)
            </span>
          ) : (
            <span>
              (per il cassetto fiscale e per la fatturazione elettronica).
            </span>
          )}
        </p>
        <div className={'row no-gutters mt-4'}>
          <div className="col-6 offset-3">
            <QuickfiscoButton
              type={'primary'}
              id="fiscal-info-onboarding-edit"
              label={'DAI LE DELEGHE'}
              onClick={() =>
                window.open(
                  'https://quickfisco.it/tutorial/delega-cassetto-fiscale-fatturazione-elettronica/',
                  '_blank'
                )
              }
            />
          </div>
          {showSecondButton && (
            <div className="col-6 offset-3 mt-3">
              <QuickfiscoButton
                type={'primary'}
                id="fiscal-info-onboarding-edit"
                label={'DAI DELEGA CASSETTO PREVIDENZIALE'}
                onClick={() =>
                  window.open(
                    'https://quickfisco.it/tutorial/delega-cassetto-previdenziale/',
                    '_blank'
                  )
                }
              />
            </div>
          )}
          <p className="mandate-onboarding-small-text mt-5">
            <b>
              Una volta conferite le deleghe, clicca sul pulsante di conferma
              qui sotto.
            </b>
            <br />
            Il sistema verificherà il corretto conferimento prima di concludere
            la registrazione.
          </p>
        </div>
        <div className="row d-flex justify-content-center mt-3 mb-5">
          <div className="col-5">
            {mandateStatus === 'loading' ? (
              <QuickfiscoSpinner />
            ) : (
              <QuickfiscoButton
                type={'secondary'}
                id="fiscal-info-onboarding-edit"
                label={lang.goToHome}
                onClick={() =>
                  checkMandate(
                    setAllVerified,
                    setMandateServiceResponse,
                    setMandateStatus,
                    user,
                    dispatch
                  )
                }
              />
            )}
          </div>
        </div>
        <p className="mandate-onboarding-warning">
          <span>ATTENZIONE!</span>
          <br />
          In mancanza del conferimento deleghe, non potremo verificare e
          abilitare il tuo account.
        </p>
      </div>
    </div>
  );

  const mandateAllVerified: React.ReactNode = (
    <div className="mandate-onboarding-all-verified-container">
      <div className="row">
        <div className="col-12">
          <QuickfiscoIcon name={'doc-verified.svg'} />
          <h1 className="mandate-onboarding-all-verified-title mt-5 mb-3">
            Abbiamo verificato le tue deleghe.
          </h1>
          <p className="mandate-onboarding-text">
            È tutto ok, ora{' '}
            <b>possiamo aiutarti a gestire la tua partita iva</b>
          </p>
          <div className="row my-5">
            <div className="col-4 offset-1 d-flex justify-content-center align-items-center">
              <QuickfiscoButton
                type={'primary'}
                id="fiscal-info-onboarding-go-back"
                label={lang.goBack}
                onClick={() => setAllVerified(undefined)}
              />
            </div>
            <div className="col-4 offset-2 d-flex justify-content-center align-items-center">
              {onboardingCompletedStatus === 'loading' ? (
                <div
                  className={
                    'w-100 d-flex justify-content-center align-items-center'
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <QuickfiscoButton
                  type={'secondary'}
                  id="fiscal-info-onboarding-edit"
                  label={lang.goNext}
                  onClick={() =>
                    onboardingCompleted(setOnboardingCompletedStatus)
                  }
                />
              )}
            </div>
          </div>
          {onboardingCompletedStatus === 'failed' && (
            <div className="mandate-onboarding-completed-error mb-5">
              {lang.onboardingCompleted}
            </div>
          )}
          <p className="mandate-onboarding-warning">
            <span>ATTENZIONE!</span>
            <br />
            In mancanza del conferimento deleghe, non potremo verificare e
            abilitare il tuo account.
          </p>
        </div>
      </div>
    </div>
  );

  const mandateNotVerified: React.ReactNode = (
    <div className="mandate-onboarding-all-verified-container">
      <div className="row">
        <div className="col-12">
          <QuickfiscoIcon name={'doc-not-verified.svg'} />
          <h1 className="mandate-onboarding-all-verified-title mt-5 mb-3">
            Non siamo riusciti a verificare le tue deleghe.
          </h1>
          <p className="mandate-onboarding-text">
            Il conferimento deleghe non è andato a buon fine o non è stato
            effettuato correttamente.
          </p>
          <p className="mandate-onboarding-text">
            <b>Effettua di nuovo il conferimento delle seguenti deleghe:</b>
            <br />
            {mandateServiceResponse?.cassetto_fiscale.active === false && (
              <span>• CASSETTO FISCALE</span>
            )}
            {mandateServiceResponse?.fatt_elettronica.active === false && (
              <>
                <br />
                <span>• FATTURAZIONE ELETTRONICA</span>
              </>
            )}
          </p>
          <div className="row mt-5">
            <div className="col-4 offset-1 d-flex justify-content-center align-items-center">
              <QuickfiscoButton
                type={'primary'}
                id="fiscal-info-onboarding-go-back"
                label={lang.goBack}
                onClick={() => setAllVerified(undefined)}
              />
            </div>
            <div className="col-4 offset-2 d-flex justify-content-center align-items-center">
              {mandateStatus === 'loading' ? (
                <QuickfiscoSpinner />
              ) : (
                <QuickfiscoButton
                  type={isRetryButtonEnabled ? 'secondary' : 'disabled'}
                  id="fiscal-info-onboarding-edit"
                  label={lang.retry}
                  onClick={() => {
                    setSecondsRemaining(60);
                    setIsRetryButtonEnabled(false);
                    checkMandate(
                      setAllVerified,
                      setMandateServiceResponse,
                      setMandateStatus,
                      user,
                      dispatch
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4 offset-7 d-flex justify-content-center align-items-center fiscal-info-onboarding-countdown">
              {secondsRemaining > 0 && (
                <div>Riprova tra {secondsRemaining} s</div>
              )}
            </div>
          </div>
          <p className="mandate-onboarding-warning mt-5">
            <span>ATTENZIONE!</span>
            <br />
            In mancanza del conferimento deleghe, non potremo verificare e
            abilitare il tuo account.
          </p>
        </div>
      </div>
    </div>
  );

  const mandateApiError: React.ReactNode = (
    <div className="mandate-onboarding-all-verified-container">
      <div className="row">
        <div className="col-12">
          <QuickfiscoIcon name={'doc-not-verified.svg'} />
          <h1 className="mandate-onboarding-all-verified-title mt-5 mb-3">
            Potrebbe servire qualche minuto in più
          </h1>
          <p className="mandate-onboarding-text">
            ➔ L’esito del conferimento deleghe da parte di Agenzia della Entrate
            <br />
            non sempre è immediato.
          </p>
          <p className="mandate-onboarding-text my-4">
            <b>Attendi qualche minuto ed effettua una nuova verifica</b>{' '}
            cliccando sul pulsante “Riprova”.
          </p>
          <p className="mandate-onboarding-text">
            Se continui a riscontrare problemi, scrivici cliccando sull’icona in
            basso a destra “?”.
          </p>
          <div className="row mt-5">
            <div className="col-4 offset-1 d-flex justify-content-center align-items-center">
              <QuickfiscoButton
                type={'primary'}
                id="fiscal-info-onboarding-go-back"
                label={lang.goBack}
                onClick={() => setAllVerified(undefined)}
              />
            </div>
            <div className="col-4 offset-2 d-flex justify-content-center align-items-center">
              {mandateStatus === 'loading' ? (
                <QuickfiscoSpinner />
              ) : (
                <>
                  <QuickfiscoButton
                    type={isRetryButtonEnabled ? 'secondary' : 'disabled'}
                    id="fiscal-info-onboarding-edit"
                    label={lang.retry}
                    onClick={() => {
                      setSecondsRemaining(60);
                      setIsRetryButtonEnabled(false);
                      checkMandate(
                        setAllVerified,
                        setMandateServiceResponse,
                        setMandateStatus,
                        user,
                        dispatch
                      );
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4 offset-7 d-flex justify-content-center align-items-center fiscal-info-onboarding-countdown">
              {secondsRemaining > 0 && (
                <div>Riprova tra {secondsRemaining} s</div>
              )}
            </div>
          </div>
          <p className="mandate-onboarding-warning mt-5">
            <span>ATTENZIONE!</span>
            <br />
            In mancanza del conferimento deleghe, non potremo verificare e
            abilitare il tuo account.
          </p>
        </div>
      </div>
    </div>
  );

  let currentStep: React.ReactNode;

  switch (allVerified) {
    case undefined:
      currentStep = mandateQuestion;
      break;
    case 'mandateAllVerified':
      currentStep = mandateAllVerified;
      break;
    case 'mandateNotVerified':
      currentStep = mandateNotVerified;
      break;
    case 'mandateApiError':
      currentStep = mandateApiError;
      break;
  }

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center">
              <div className="mandate-onboarding-main-title">
                06. <span>{lang.mainTitle}</span>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className={
                'col-8 offset-2 mandate-onboarding-container box-shadow py-5'
              }
            >
              <div className="row">
                <OnboardingPopoverHelp />
                <div className={'col-10 offset-1 text-center'}>
                  {currentStep}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

async function checkMandate(
  setAllVerified: SetState<
    'mandateAllVerified' | 'mandateNotVerified' | 'mandateApiError' | undefined
  >,
  setMandateServiceResponse: SetState<MandateModel | undefined>,
  setStatus: SetState<PromiseStatuses>,
  user: UserModel,
  dispatch: Function
) {
  const mandateService = new MandateService();

  setStatus('loading');

  mandateService
    .findAll()
    .then((res) => {
      setMandateServiceResponse(res);
      if (
        res.cassetto_fiscale.active === false ||
        res.fatt_elettronica.active === false
      ) {
        setAllVerified('mandateNotVerified');
        setStatus('successfully');
      } else {
        setAllVerified('mandateAllVerified');
        const userService = new UserService();

        userService
          .newUpdateUser({
            electronicInvoicing: {
              active: true,
              fromDate: res.fatt_elettronica.from,
              toDate: res.fatt_elettronica.to,
            },
            fiscalDrawer: {
              active: true,
              fromDate: res.cassetto_fiscale.from,
              toDate: res.cassetto_fiscale.to,
            },
          })
          .then(() => {
            setStatus('successfully');
          })
          .catch((err) => {
            console.error(err);
            setStatus('failed');
          });
      }
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
      setAllVerified('mandateApiError');
    });
}

async function onboardingCompleted(setStatus: SetState<PromiseStatuses>) {
  const mandateService = new UserService();

  setStatus('loading');

  mandateService
    .onboardingCompleted()
    .then(() => {
      setStatus('successfully');
      window.location.href = uri.Home;
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
