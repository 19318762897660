import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseStatuses } from '../../types/strings';

interface OperationStatusState {
  status: PromiseStatuses;
  errorMessage?: string;
}

const initialState: OperationStatusState = {
  status: 'idle'
};

const operationStatusSlice = createSlice({
  name: 'operationStatus',
  initialState,
  reducers: {
    setGlobalStatus: (state, action: PayloadAction<OperationStatusState>) => {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    }
  }
});

export const {
  setGlobalStatus
} = operationStatusSlice.actions;

export default operationStatusSlice.reducer;
