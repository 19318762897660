import { SendInviteFriendEmailModel } from '../models/sendInviteFriendEmailModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class BringAFriendServices {

  private readonly url = host + '/api/v1/users/invite-friend';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public sendInviteFriendEmail(request: SendInviteFriendEmailModel): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url,
      method: 'post',
      body: JSON.stringify(request),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
    .then(() => undefined)
  }
}
