import { NumericFormat } from "react-number-format"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
    setFundYearRegistration,
    setLastTaxContributionRate,
    setMaternityContribution,
    setMinSupplementaryContribution,
    setMinTaxContribution,
    setTaxContributionRate
} from "../../redux/slices/taxProfileSlice"
import { parseNumber } from "../../utils/number"
import { QuickfiscoInput } from "../quickfiscoInput/quickfiscoInput"
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner"
import { TaxProfileChangeStep } from "../taxProfileChangeStep/taxProfileChangeStep"
import { TaxProfileStepCounter } from "../taxProfileStepCounter/taxProfileStepCounter"
import './taxProfileFourthStep.css'

interface Props {
    error?: boolean
}

export function TaxProfileFourthStep(props: Props) {
    let { error } = props

    const taxProfileState = useAppSelector(state => state.taxProfile)
    const userState = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()

    const taxProfile = taxProfileState.profile
    const user = userState.user

    if (taxProfileState.status === 'loading') {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <QuickfiscoSpinner />
            </div>
        )
    }

    return (
        <div className={'tax-profile-last-year-data-container'}>
            <div className={'row'}>
                <div className={'col-12 d-flex justify-content-center'}>
                    <TaxProfileStepCounter />
                </div>
            </div>
            <div className={'row mt-5'}>
                <div className={'col-12 d-flex justify-content-center'}>
                    <span className={'tax-profile-fourth-step-data-title'}>
                        CONFIGURA CASSA DI PREVIDENZA
                    </span>
                </div>
                <div className={'col-12 d-flex justify-content-center'}>
                    <span className={'tax-profile-fourth-step-data-text'}>
                        I valori inseriti di seguito sono relativi agli ultimi pubblicati dalla tua cassa di previdenza.
                        Se ti risultano variazioni, modifica gli importi in questa sezione. Se è tutto ok, procedi con il calcolo.
                    </span>
                </div>
            </div>
            <div className={'row mt-4 d-flex justify-content-center'}>
                <div className={'col-4'}>
                    <QuickfiscoInput
                        id='tax-profile-fourth-step-year-register-input'
                        key={'input-tax-profile-fourth-step-year-register'}
                        type={'text'}
                        label="Anno d'iscrizione alla cassa"
                        styleType={'medium-wizard'}
                        defaultValue=""
                        placeholder="2020"
                        onChange={(e) => dispatch(setFundYearRegistration(parseNumber(e.target.value)))}
                        error={taxProfile.fundYearRegistration === 0 && taxProfile.fundYearRegistration !== undefined && taxProfileState.step === "fourthStep" ? error = true : error = false}
                        errorLabel={error ? "Inserire la data di iscrizione alla cassa" : ""}
                    />
                </div>
                <div className={'col-4 offset-2'}>
                    <NumericFormat
                        id='tax.profile-fourth-step-data-amount-input'
                        key={'input-tax-profile-fourth-step-amount'}
                        suffix='€'
                        defaultValue={taxProfile.maternityContribution}
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        onValueChange={e => e.floatValue !== undefined && dispatch(setMaternityContribution(e.floatValue))}
                        customInput={QuickfiscoInput}
                        styleType={'medium-wizard'}
                        label="Ammontare contributo maternità"
                        placeholder={"0,00€"}
                        fixedDecimalScale
                    />
                </div>
            </div>
            <div className={'row mt-4 d-flex justify-content-center'}>
                <div className={'col-4'}>
                    <NumericFormat
                        id='tax-profile-fourth-step-year-individual-rate-input'
                        key={'input-tax-profile-fourth-step-year-individual-rate'}
                        suffix='%'
                        defaultValue={taxProfile.taxContributionRate * 100}
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        onValueChange={e => (e.floatValue !== undefined && dispatch(setTaxContributionRate(e.floatValue / 100)) && dispatch(setLastTaxContributionRate(e.floatValue / 100)))}
                        customInput={QuickfiscoInput}
                        styleType={'medium-wizard'}
                        label="Aliquota contributo soggettivo"
                        placeholder={"10%"}
                    />
                </div>
                <div className={'col-4 offset-2'}>
                    <QuickfiscoInput
                        id='tax.profile-fourth-step-data-integrative-rate-input'
                        key={'input-tax-profile-fourth-step-integrative-rate'}
                        type={'text'}
                        label="Aliquota contributo integrativo"
                        styleType={'medium-wizard'}
                        defaultValue={(user.recourseValue) + '%'}
                        placeholder="2%"
                        disabled={true}
                    />
                </div>
            </div>
            <div className={'row mt-4 d-flex justify-content-center'}>
                <div className={'col-4'}>
                    <NumericFormat
                        id='tax-profile-fourth-step-year-subjective-amount-input'
                        key={'input-tax-profile-fourth-step-year-subjective-amount'}
                        suffix='€'
                        defaultValue={taxProfile.minTaxContribution}
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        onValueChange={e => e.floatValue !== undefined && dispatch(setMinTaxContribution(e.floatValue))}
                        customInput={QuickfiscoInput}
                        styleType={'medium-wizard'}
                        label="Ammontare contributo soggettivo minimo"
                        placeholder={"0,00€"}
                        fixedDecimalScale
                    />
                </div>
                <div className={'col-4 offset-2'}>
                    <NumericFormat
                        id='tax.profile-fourth-step-data-integrative-amount-input'
                        key={'input-tax-profile-fourth-step-integrative-amount'}
                        suffix='€'
                        defaultValue={taxProfile.minSupplementaryContribution}
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        onValueChange={e => e.floatValue !== undefined && dispatch(setMinSupplementaryContribution(e.floatValue))}
                        customInput={QuickfiscoInput}
                        styleType={'medium-wizard'}
                        label="Ammontare contributo integrativo minimo"
                        placeholder={"0,00€"}
                        fixedDecimalScale
                    />
                </div>
            </div>
            <div className={'row mt-5'}>
                <div className={'col-12'}>
                    <TaxProfileChangeStep error={error} />
                </div>
            </div>
        </div>
    )
}