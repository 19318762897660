import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  label?: string;
  onChange?: (province?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
}

export function QuickfiscoCivilStatusSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { label } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={label || 'Stato civile'}
      options={getOptions()}
      styleType={'default'}
      value={
        getOptions(value).find(
          (option) => option.selected === true
        )
      }
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
    />
  );
}
function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona stato civile',
      value: undefined,
      selected: value === undefined,
    },
    { label: 'Celibe/Nubile', value: 'Celibe/Nubile', selected: value === 'Celibe/Nubile' },
    { label: 'Coniugato/a', value: 'Coniugato/a', selected: value === 'Coniugato/a' },
    { label: 'Vedovo/a', value: 'Vedovo/a', selected: value === 'Vedovo/a' },
    { label: 'Divorziato/a', value: 'Divorziato/a', selected: value === 'Divorziato/a' },
    { label: 'Non classificabile/ignoto/n.c', value: 'Non classificabile/ignoto/n.c', selected: value === 'Non classificabile/ignoto/n.c' },
    { label: 'Unito civilmente', value: 'Unito civilmente', selected: value === 'Unito civilmente' },
    { label: 'Stato libero a seguito di decesso della parte unita civilmente', value: 'Stato libero a seguito di decesso della parte unita civilmente', selected: value === 'Stato libero a seguito di decesso della parte unita civilmente' },
    { label: 'Stato libero a seguito di scioglimento dell’unione', value: 'Stato libero a seguito di scioglimento dell’unione', selected: value === 'Stato libero a seguito di scioglimento dell’unione' },
  ];
}
