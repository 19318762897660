import { OnBlur, OnChange } from '../../types/functions';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoInputWithDelete.css';

interface Props {
  id: string;
  value?: string;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: OnBlur;
  onChange?: OnChange;
  onDelete?: () => void;
}

export function QuickfiscoInputWithDelete(props: Props) {
  const { id } = props;
  const { value } = props;
  const { maxLength } = props;
  const { disabled } = props;
  const { placeholder } = props;
  const { onBlur } = props;
  const { onChange } = props;
  const { onDelete } = props;

  return (
    <div className="quickfisco-input-delete-container">
      <input
        id={id}
        type="text"
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        className={'quickfisco-input-delete'}
        placeholder={placeholder}
        onBlur={(e) => onBlur && onBlur(e)}
        onChange={(e) => onChange && onChange(e)}
      />
      {value && !disabled && (
        <button onClick={() => onDelete && onDelete()}>
          <QuickfiscoIcon name={'cancel.svg'} />
        </button>
      )}
    </div>
  );
}
