import { InvoiceModel } from '../models/invoiceModel';
import { InvoicePaymentModel } from '../models/invoicePaymentModel';
import { PaginationModel } from '../models/paginationModel';
import { host } from '../utils/config';
import { CommonInvoiceService } from './commonInvoiceService';

export class ImportInvoiceService extends CommonInvoiceService {

  public constructor() {
    super(host + '/api/v1/invoices');
  }

  public findAllCustomer(): Promise<PaginationModel<InvoiceModel>> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/import'
    })
      .then(res => res.json())
      .then((data: PaginationModel<InvoiceModel>) => data);
  }

  public editCustomerInvoice(invoiceId: string, request: { ateco?: string, payments?: InvoicePaymentModel[]}): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + `/import/${invoiceId}`,
      body: JSON.stringify(request),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public importCustomer(): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/import',
    })
      .then(() => undefined);
  }

  //STS

  public findAllSts(): Promise<PaginationModel<InvoiceModel>> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/import-sts'
    })
      .then(res => res.json())
      .then((data: PaginationModel<InvoiceModel>) => data);
  }

  public editStsInvoice(invoiceId: string, request: { ateco?: string, socialContribution?: boolean}): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + `/import-sts/${invoiceId}`,
      body: JSON.stringify(request),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public importSts(): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/import-sts',
    })
      .then(() => undefined);
  }

  //ALL

  public resetImportInvoice(id: string, types: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: host + '/api/v1/users/resetImportInvoice/' + id + "/" + types,
    })
      .then(() => undefined);
  }
}