import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './taxNotMappedFund.css';
import lang from './taxNotMappedFund.json';

export function TaxNotMappedFund() {
  return (
    <div className={'tax-calculation-not-mapped-fund-container'}>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <QuickfiscoIcon name={'alert-yellow.svg'} />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-calculation-not-mapped-fund-title mt-4'}>
            {lang.title}
          </span>
        </div>
      </div>
      <div className={'row mt-2'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-calculation-not-mapped-fund-text mb-4'}>
            {lang.subtitle}
          </span>
        </div>
      </div>
      <div className={'row d-flex justify-content-center'}>
        <div className={'col-6'}>
          <QuickfiscoButton
            id={'tax-calculation-trial-user'}
            label={lang.label}
            type={'secondary'}
          />
        </div>
      </div>
    </div>
  );
}
