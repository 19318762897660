import { ComponentVisibleOutput } from "../../hooks/componentVisible";
import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";
import './taxProfileConfigurationGuidePopup.css';
import lang from './taxProfileConfigurationGuidePopup.json';

interface Props {
    showPopup: ComponentVisibleOutput
}

export function TaxProfileConfigurationGuidePopup(props: Props) {
    const { showPopup } = props;

    return (
        <div className="tax-profile-configuration-guide-popup-container">
            <div className="row justify-content-end">
                <div className="col-auto">
                    <QuickfiscoIcon
                        name="close-black.png"
                        isButton={true}
                        onClick={() => showPopup.setIsVisible(false)}
                    />
                </div>
            </div>
            <div className="row pb-1" style={{ marginTop: '-15px' }}>
                <div className="col-11 m-auto">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <p className="tax-profile-configuration-guide-popup-text">
                                {lang.text1}
                                <a
                                    className="tax-profile-configuration-guide-popup-text-blue"
                                    href={'https://quickfisco.it/guide-previsionale-tasse/'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    {lang.text2}
                                </a>
                                {lang.text3}
                                <span className="tax-profile-configuration-guide-popup-text-bold">
                                    {lang.text4}
                                </span>
                                {lang.text5}
                                <span className="tax-profile-configuration-guide-popup-text-bold">
                                    {lang.text6}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}