import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { UserCategory } from '../incomeUploadModal/incomeUploadModal';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import lang from './incomeUploadModalStep5.json';
import './incomeUploadModalStep5.css';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { PromiseStatuses } from '../../types/strings';

interface Props {
  qfUsedFlag: boolean | undefined;
  userCategory: UserCategory;
  telematicFeesRegister: boolean | undefined;
  setTelematicFeesRegister: (response: boolean) => void;
  onClickPrevStep: () => void;
  onClickSend: () => void;
  setFiles: (_file: File[]) => void;
  errorFile: boolean;
  status: PromiseStatuses;
  files?: File[];
}

export function IncomeUploadModalStep5(props: Props) {
  const { qfUsedFlag } = props;
  const { telematicFeesRegister } = props;
  const { setTelematicFeesRegister } = props;
  const { userCategory } = props;
  const { onClickPrevStep } = props;
  const { onClickSend } = props;
  const { files } = props;
  const { setFiles } = props;
  const { errorFile } = props;
  const { status } = props;

  return (
    <>
      {qfUsedFlag === true ? (
        <>
          {userCategory === UserCategory.TRADER && (
            <>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step5-subtitle'}>
                  {lang.subTitleTraderYes}
                </div>
              </div>
              <div
                className={'row my-3 p-4 income-upload-modal-step5-checkbox'}
              >
                <div
                  className={
                    'col-8 offset-2 d-flex align-items-start justify-content-center'
                  }
                >
                  <QuickfiscoCheckbox
                    id={'income-upload-modal-step5-response-true'}
                    label={lang.checkbox1}
                    checked={telematicFeesRegister === true}
                    onChange={(e) => setTelematicFeesRegister(true)}
                  />
                </div>
                <div
                  className={
                    'col-8 offset-2 mt-4 d-flex align-items-start justify-content-center'
                  }
                >
                  <QuickfiscoCheckbox
                    id={'income-upload-modal-step5-response-false'}
                    label={lang.checkbox2}
                    checked={telematicFeesRegister === false}
                    onChange={(e) => setTelematicFeesRegister(false)}
                  />
                </div>
              </div>
            </>
          )}
          {userCategory === UserCategory.TRADER479119 && (
            <>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step5-subtitle'}>
                  {lang.subTitleTrader479119Yes}
                </div>
              </div>
              <div className={'row my-3'}>
                <div
                  className={
                    'col-10 offset-1 text-center income-upload-modal-step5-text'
                  }
                >
                  Verifica anche il totale dei corrispettivi dell'anno 2023.
                  <br />
                  <br />
                  Aggiorna il registro dei corrispettivi
                  <br />
                </div>
              </div>
              <div className={'row mt-4 mb-5'}>
                <div className={'col-6 offset-3'}>
                  <QuickfiscoButton
                    id={'income-upload-modal-step5-button'}
                    label={lang.goTofeesRegister}
                    type={'primary'}
                    onClick={() => {
                      window.open('/fees-register', '_self');
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className={'row no-gutters my-4'}>
            <div className={'col-3 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step5-button'}
                label={lang.step5ButtonPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            <div className={'col-3 offset-6 d-flex justify-content-center'}>
              {status === 'loading' ? (
                <div
                  className={
                    'd-flex justify-content-center align-items-center w-100'
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <QuickfiscoButton
                  id={'income-upload-modal-step5-button'}
                  label={lang.step5buttonSend}
                  type={
                    userCategory === UserCategory.TRADER479119 ||
                    telematicFeesRegister !== undefined
                      ? 'secondary'
                      : 'disabled'
                  }
                  onClick={() => onClickSend()}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {userCategory === UserCategory.TRADER && (
            <>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step5-subtitle'}>
                  {lang.subTitleTraderYes}
                </div>
              </div>
              <div
                className={'row my-3 p-4 income-upload-modal-step5-checkbox'}
              >
                <div
                  className={
                    'col-8 offset-2 d-flex align-items-start justify-content-center'
                  }
                >
                  <QuickfiscoCheckbox
                    id={'income-upload-modal-step5-response-true'}
                    label={lang.checkbox1}
                    checked={telematicFeesRegister === true}
                    onChange={(e) => setTelematicFeesRegister(true)}
                  />
                </div>
                <div
                  className={
                    'col-8 offset-2 mt-4 d-flex align-items-start justify-content-center'
                  }
                >
                  <QuickfiscoCheckbox
                    id={'income-upload-modal-step5-response-false'}
                    label={lang.checkbox2}
                    checked={telematicFeesRegister === false}
                    onChange={(e) => setTelematicFeesRegister(false)}
                  />
                </div>
              </div>
            </>
          )}
          {userCategory === UserCategory.TRADER479119 && (
            <>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step5-subtitle'}>
                  {lang.subTitleTrader479119Yes}
                </div>
              </div>
              <div className={'row my-3'}>
                <div
                  className={
                    'col-8 offset-2 text-center income-upload-modal-step5-text'
                  }
                >
                  Carica il file relativo ai tuoi corrispettivi emessi nel 2023
                </div>
              </div>
              <div className={'row mb-5'}>
                <div className={'col-8 offset-2 text-center'}>
                  <QuickfiscoInputFile
                    preview={true}
                    multiple={false}
                    maxHeight={'100px'}
                    fileList={files && files[1] ? [files[1]] : []}
                    setFileList={(_file) =>
                      files && files[0] && setFiles([files[0], _file[0]])
                    }
                  />
                </div>
              </div>
              {files?.length === 2 && files[1] !== undefined && (
                <>
                  <div className={'row pb-4'}>
                    <div
                      className={'col-4 offset-4 d-flex justify-content-center'}
                    >
                      <QuickfiscoIcon
                        name={'alert-success.svg'}
                        className="w-25"
                      />
                    </div>
                  </div>
                  <div className={'row pb-4'}>
                    <div className={'col-12 text-center'}>
                      <div className={'income-upload-modal-step5-text-green'}>
                        File caricato correttamente
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className={'row no-gutters my-4'}>
            <div className={'col-3 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step5-button'}
                label={lang.step5ButtonPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            <div className={'col-3 offset-6 d-flex justify-content-center'}>
              {status === 'loading' ? (
                <div
                  className={
                    'd-flex justify-content-center align-items-center w-100'
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <QuickfiscoButton
                  id={'income-upload-modal-step5-button'}
                  label={lang.step5buttonSend}
                  type={
                    telematicFeesRegister !== undefined ||
                    (files?.length === 2 && files[1] !== undefined)
                      ? 'secondary'
                      : 'disabled'
                  }
                  onClick={() => onClickSend()}
                />
              )}
            </div>
          </div>
        </>
      )}
      {errorFile && (
        <div className={'row my-4'}>
          <div className={'col-8 offset-2 income-upload-modal-step5-error'}>
            {lang.errorFile}
          </div>
        </div>
      )}
    </>
  );
}
