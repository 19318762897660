import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTaxProfile, saveTaxProfile } from '../../redux/slices/taxProfileSlice';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { TaxProfileChangeStep } from '../taxProfileChangeStep/taxProfileChangeStep';
import './taxProfileCompleted.css';
import lang from './taxProfileCompleted.json';

export function TaxProfileCompleted() {
  const dispatch = useAppDispatch();

  const taxProfileStatus = useAppSelector(state => state.taxProfile);

  return (
    <div className={'tax-profile-confirm-data-container'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
              <QuickfiscoIcon name='alert-yellow.svg' />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-completed-title d-flex justify-content-center'}>
                {lang.warning}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-8 offset-2'}>
              <p className={'tax-profile-completed-text'}>
                {lang.warningLabel}
                <span className='tax-profile-completed-text-bold'>
                  {lang.warningLabelBold}
                </span>
              </p>
            </div>
          </div>
          <div className={'row mt-5'}>
            <TaxProfileChangeStep
              completeProfile={() =>
                dispatch(saveTaxProfile(taxProfileStatus.profile))
                  .then(() => dispatch(getTaxProfile()))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
