import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import useComponentVisible from '../../hooks/componentVisible';
import { InvoiceModel, InvoiceStatusType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStsInvoiceSaveOrDuplicateOrEditInvoiceId, setStsInvoiceSaveOrDuplicateOrEditOperation } from '../../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice';
import uri from '../../routers/quickfiscoUri.json';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { formatDateIT } from '../../utils/date';
import { formatNumberIT } from '../../utils/number';
import { CancelStsSending } from '../cancelStsSending/cancelStsSending';
import lang from '../invoiceRow/invoiceRow.json';
import { InvoiceStatus } from '../invoiceStatus/invoiceStatus';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { SendStsInvoice } from '../sendStsInvoice/sendStsInvoice';
import { StsInvoiceMenu } from '../stsInvoiceMenu/stsInvoiceMenu';
import { StsInvoiceRejected } from '../stsInvoiceRejected/stsInvoiceRejected';
import './stsHistoryRow.css';

interface Props {
  invoice: InvoiceModel;
}

export function StsHistoryRow(props: Props) {
  const { invoice } = props;

  const showMenu = useComponentVisible(false);

  const [mouseOverMenu, setMouseOverMenu] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [openCancelSendingModal, setOpenCancelSendingModal] = useState(false);

  const userState = useAppSelector(state => state.user)

  const user = userState.user

  const amount = StsInvoiceService.calculateTotalAmount(invoice, user, 'sts');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let change = () => {
    dispatch(setStsInvoiceSaveOrDuplicateOrEditInvoiceId(invoice?.id))
    dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('edit'))
    navigate(uri.StsInvoiceSave);
  };

  const invoiceId = invoice.id

  if (invoiceId === undefined) {
    return (
      <div>Errore durante il caricamento dei dati</div>
    )
  }

  return (
    <div className={'row no-gutters row-sts-container'}>
      <div className={'row no-gutters'}>
        <div className={'col-12'}>
          <div className={'sts-history-row-line'} />
        </div>
      </div>
      <div className={'row no-gutters invoice-list-row-row align-items-center'}>
        <div className={"col no-gutters pb-3 pt-3"} onClick={() => change()}>
          <div className={'row no-gutters align-items-center'}>
            <div className="col sts-history-row-company d-flex">
              {invoice.customer?.companyName}
            </div>
            <div className={'col invoice-list-row-bold list-crop'}>
              {formatNumberIT(amount)}{config.EUR}
            </div>
            <div
              className={'col invoice-list-row-gray invoice-row-number list-crop'}>{invoice.number}</div>
            <div className={'col position-relative'}>
              <div className={'d-flex flex-row justify-content-start align-items-center'}>
                <div className={'list-crop'}>
                  <InvoiceStatus
                    status={invoice.status}
                    previousStatus={invoice.previousStatus}
                    invoiceSign={'sts'}
                  />
                </div>
                {
                  (
                    invoice.status === InvoiceStatusType.REJECTED ||
                    invoice.status === InvoiceStatusType.QUEUED_NACK ||
                    invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK
                  ) && (
                    <div className={'ms-2'}>
                      <StsInvoiceRejected error={invoice.error} />
                    </div>
                  )
                }
              </div>
            </div>
            <div className={'col d-none d-xxl-block invoice-row-date invoice-list-row-gray list-crop'}>
              {invoice.date && formatDateIT(invoice.date)}
            </div>
          </div>
        </div>
        <div className={'col-5 no-gutters'}>
          <div className={'row no-gutters align-items-center'}>
            {
              invoice.status === InvoiceStatusType.REJECTED ? (
                <div
                  className={'col-1 position-relative d-flex justify-content-end'}>
                  <div
                    onMouseOver={() => setMouseOverMenu(true)}
                    onMouseOut={() => setMouseOverMenu(false)}
                    onClick={() => showMenu.setIsVisible(true)}
                  >
                    <QuickfiscoIcon name={mouseOverMenu ? 'menu-hover.svg' : 'menu.svg'} />
                  </div>
                  <div
                    className={'sts-history-row-menu'}
                    ref={showMenu.ref}
                  >
                    <StsInvoiceMenu
                      invoice={invoice}
                      componentVisible={showMenu}
                      invoiceSign='sts'
                    />
                  </div>
                </div>
              ) : (
                <div className={'col-1'} />
              )
            }
            <div className={'col-3'}>
              <QuickfiscoButton
                id={'send-sts-invoice'}
                label={lang.send}
                type={
                  invoice.status === InvoiceStatusType.DRAFT ||
                    invoice.status === InvoiceStatusType.REJECTED ||
                    invoice.status === InvoiceStatusType.QUEUED_NACK
                    ? 'secondary'
                    : 'disabled'
                }
                onClick={() => setOpenSendModal(true)}
              />
            </div>
            <div className={'col-4'}>
              <QuickfiscoButton
                id={'variation-sts-invoice'}
                label={lang.variation}
                type={
                  (
                    invoice.status === InvoiceStatusType.ACCEPTED ||
                    invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK ||
                    invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED
                  ) ?
                    'primary'
                    : 'disabled'
                }
                onClick={() => change()}
              />
            </div>
            <div className={'col-4'}>
              <QuickfiscoButton
                id={'cancel-send-sts-invoice'}
                label={lang.cancelSending}
                type={
                  (
                    invoice.status === InvoiceStatusType.ACCEPTED ||
                    invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK ||
                    invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED
                  ) ?
                    'primary'
                    : 'disabled'
                }
                onClick={() => setOpenCancelSendingModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <SendStsInvoice
        open={openSendModal}
        setOpen={(open) => setOpenSendModal(open)}
        invoiceId={invoiceId}
      />
      <CancelStsSending
        open={openCancelSendingModal}
        setOpen={(open) => setOpenCancelSendingModal(open)}
        invoiceId={invoiceId}
      />
    </div>
  );
}
