import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { ExpenseTypeFlag } from '../../models/stsProfileModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './expenseTypeFlagSelect.json';

interface Props {
  id: string;
  value?: ExpenseTypeFlag;
  disabled?: boolean;
  onChange?: ((expenseTypeFlag?: ExpenseTypeFlag) => void);
}

export function ExpenseTypeFlagSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { disabled } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.title}
      styleType={'default'}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(ExpenseTypeFlag[e.value])
        }
      }}
      disabled={disabled}
      requiredField={true}
      options={getOptions(value)}
      value={getOptions(value).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: ExpenseTypeFlag): OptionModel[] {
  return [
    {
      label: 'Nessuno',
      value: undefined,
      selected: value === undefined
    },
    {
      label: 'Ticket di pronto soccorso',
      value: ExpenseTypeFlag.TPS,
      selected: value === ExpenseTypeFlag.TPS
    },
    {
      label: 'Visita in intramoenia',
      value: ExpenseTypeFlag.VI,
      selected: value === ExpenseTypeFlag.VI
    }
  ];
}
