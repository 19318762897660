import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel, ContactType } from '../../models/contactModel';
import { CurrencyType, GoodServicesModel, InvoiceModel, InvoiceStatusType, InvoiceType, PaymentModeType, PaymentTermsType } from '../../models/invoiceModel';
import { BankAccount } from '../../models/userModel';

interface StsInvoiceState {
  fileList: File[];
  invoice: InvoiceModel;
}

const initialState: StsInvoiceState = {
  fileList: [],
  invoice: {
    date: new Date(),
    type: InvoiceType.TD01,
    status: InvoiceStatusType.DRAFT,
    paymentTerms: PaymentTermsType.IMMEDIATE,
    paymentExpiration: new Date(),
    currency: CurrencyType.EUR,
    stamp: false,
    amount: 0,
    taxBase: 0,
    goodServices: [],
    socialContribution: false,
    socialContributionAmount: 0,
    reimbursementsOfExpenses: [],
    reimbursementsOfExpensesAmount: 0,
    manual: false,
    isPaymentDue: false,
    trackedPayment: false,
    customerDataCommunication: undefined,
    payments: [],
    documents: [],
    additionalDatasPa: [],
    supplier: {
      bankAccount: {
        bankIban: "",
        bankName: "",
      },
      type: ContactType.CUSTOMER
    }
  }
};

const stsInvoiceSlice = createSlice({
  name: 'stsInvoice',
  initialState,
  reducers: {
    setStsInvoiceFileList: (state, action: PayloadAction<File[]>) => {
      state.fileList = action.payload;
    },
    setStsInvoiceCustomer: (state, action: PayloadAction<ContactModel | undefined>) => {
      state.invoice.customer = action.payload
    },
    setStsInvoiceStatus: (state, action: PayloadAction<InvoiceStatusType>) => {
      state.invoice.status = action.payload
    },
    setStsInvoicePreviousStatus: (state, action: PayloadAction<InvoiceStatusType>) => {
      state.invoice.previousStatus = action.payload
    },
    setStsInvoiceAteco: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.ateco = action.payload
    },
    setStsInvoiceSocialContribution: (state, action: PayloadAction<boolean>) => {
      state.invoice.socialContribution = action.payload
    },
    setStsInvoiceFund: (state, action: PayloadAction<string>) => {
      state.invoice.fund = action.payload
    },
    setStsInvoiceSocialContributionAmount: (state, action: PayloadAction<number>) => {
      state.invoice.socialContributionAmount = action.payload
    },
    setStsInvoiceDate: (state, action: PayloadAction<Date>) => {
      state.invoice.date = action.payload
    },
    setStsInvoiceBankAccount: (state, action: PayloadAction<BankAccount>) => {
      if (state.invoice.supplier !== undefined) {
        state.invoice.supplier.bankAccount.bankName = action.payload.name;
        state.invoice.supplier.bankAccount.bankIban = action.payload.iban;
      }
    },
    setStsInvoicePaymentExpiration: (state, action: PayloadAction<Date | undefined>) => {
      state.invoice.paymentExpiration = action.payload
    },
    setStsInvoicePaymentMode: (state, action: PayloadAction<PaymentModeType | undefined>) => {
      state.invoice.paymentMode = action.payload
    },
    setStsInvoiceNotes: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.notes = action.payload
    },
    setStsInvoiceTrackedPayment: (state, action: PayloadAction<boolean>) => {
      state.invoice.trackedPayment = action.payload
    },
    setStsInvoiceCommunicationData: (state, action: PayloadAction<boolean>) => {
      state.invoice.customerDataCommunication = action.payload
    },
    setStsInvoiceGoodServices: (state, action: PayloadAction<GoodServicesModel[]>) => {
      state.invoice.goodServices = action.payload
    },
    setStsInvoiceStamp: (state, action: PayloadAction<boolean>) => {
      state.invoice.stamp = action.payload
    },
    setStsInvoiceType: (state, action: PayloadAction<InvoiceType>) => {
      state.invoice.type = action.payload
    },
    setStsInvoicePaymentTerms: (state, action: PayloadAction<PaymentTermsType | undefined>) => {
      state.invoice.paymentTerms = action.payload
    },
    setStsInvoiceTaxBase: (state, action: PayloadAction<number>) => {
      state.invoice.taxBase = action.payload
    },
    setStsInvoiceSocialEnasarco: (state, action: PayloadAction<number>) => {
      state.invoice.enasarcoSocialContribution = action.payload
    },
    setStsInvoice: (state, action: PayloadAction<InvoiceModel>) => {
      state.invoice = action.payload
    },
    setStsInvoiceNumber: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.number = action.payload
    },
    setStsStampId: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.stampId = action.payload
    }
  }
});

export const {
  setStsInvoiceFileList,
  setStsInvoiceAteco,
  setStsInvoiceBankAccount,
  setStsInvoiceCommunicationData,
  setStsInvoiceCustomer,
  setStsInvoiceDate,
  setStsInvoiceGoodServices,
  setStsInvoiceStatus,
  setStsInvoicePreviousStatus,
  setStsInvoiceNotes,
  setStsInvoicePaymentExpiration,
  setStsInvoicePaymentMode,
  setStsInvoiceSocialContribution,
  setStsInvoiceSocialContributionAmount,
  setStsInvoiceStamp,
  setStsInvoiceTrackedPayment,
  setStsInvoiceType,
  setStsInvoicePaymentTerms,
  setStsInvoiceTaxBase,
  setStsInvoiceSocialEnasarco,
  setStsInvoiceFund,
  setStsInvoice,
  setStsInvoiceNumber,
  setStsStampId
} = stsInvoiceSlice.actions;

export default stsInvoiceSlice.reducer;
