import { MandateModel } from '../models/mandateModel';
import { Http } from '../utils/http';

export class MandateService {
  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public findAll(): Promise<MandateModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: `https://${window.location.hostname === 'app.quickfisco.it' ? 'ade' : 'ade-dev'}.quickfisco.it/check_deleghe${window.location.hostname === 'localhost' ? '?skipAuth=true' : ''}`,
      credentials: 'include',
    })
      .then(res => res.json())
      .then((data: MandateModel) => data);
  }
}