import { Accordion } from 'react-bootstrap';
import { ObjDdrDocument } from '../../models/userModel';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { ObjectService } from '../../services/objectService';
import { downloadPdf } from '../../utils/file';
import { formatDateITWithHour } from '../../utils/date';
import lang from './taxReturnDocumentsAccordion.json';
import './taxReturnDocumentsAccordion.css';

interface Props {
  documents?: ObjDdrDocument[];
}

export function TaxReturnDocumentsAccordion(props: Props) {
  const { documents } = props;

  return (
    <Accordion className="tax-return-documents-accordion">
      <Accordion.Item eventKey={'first-item-documents-accordion'}>
        <Accordion.Header>
          {lang.accordionTitle}
          {documents && documents.length > 0 && ` (${documents.length})`}
        </Accordion.Header>
        <Accordion.Body>
          <div
            className={`row my-2 ${
              documents && documents.length > 2
                ? 'tax-return-documents-documents-list-file-list-container'
                : ''
            }`}
          >
            {!documents || (documents && documents.length === 0) ? (
              <div className="d-flex align-items-center justify-content-center my-5 tax-return-documents-documents-list-no-file">
                {lang.noFile}
              </div>
            ) : (
              documents &&
              documents.map((document) => {
                return (
                  <div className={'col-6 mt-3'} key={document.objectId}>
                    <div className="tax-return-documents-documents-list-file-box">
                      <div
                        className={'row d-flex align-items-center'}
                        key={document.objectId}
                      >
                        <div
                          className={
                            'col-10 d-flex align-items-center justify-content-start'
                          }
                        >
                          <div className={'row'}>
                            <div
                              className={
                                'col-12 d-block tax-return-documents-documents-list-file-name'
                              }
                            >
                              {document.fileName}
                              {document.createdAt && (
                                <div className="mt-2">
                                  {lang.receivedDate}{' '}
                                  {formatDateITWithHour(document.createdAt)}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            'col-2 d-flex align-items-center justify-content-center'
                          }
                        >
                          <QuickfiscoIcon
                            name={'download-negative.svg'}
                            className={'cursor-pointer'}
                            onClick={() =>
                              document.objectId &&
                              getDownloadDoc(document.objectId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function getDownloadDoc(docId: string): void {
  const service = new ObjectService();

  if (docId) {
    service
      .findById(docId)
      .then((data) => {
        const objFile = data;

        if (objFile.id) {
          service
            .findFile(objFile.id)
            .then((data) => {
              if (objFile.name) downloadPdf(objFile.name, data, objFile.type);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
