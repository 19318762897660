import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useComponentVisible from '../../hooks/componentVisible';
import { ContactModel, ContactType, PersonType } from '../../models/contactModel';
import uri from '../../routers/quickfiscoUri.json';
import { ContactMenu } from '../contactMenu/contactMenu';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './contactListRow.css';
import lang from './contactListRow.json';

interface Props {
  contact: ContactModel;
}

export function ContactListRow(props: Props) {
  const { contact } = props;

  const menuIsVisible = useComponentVisible(false);
  const [onMouseOver, setOnMouseOver] = useState(false);

  const navigate = useNavigate();

  let vat: React.ReactNode;
  if (contact.vat === undefined || contact.vat === '') {
    vat = <div className={'contactListRow-noVat'}>{lang.line}</div>;
  } else {
    vat = <div className={'contactListRow-gray'}>{contact.vat}</div>;
  }

  let contactType: string;
  switch (contact.personType) {
    case PersonType.GOV:
      contactType = lang.GOV;
      break;
    case PersonType.VAT:
      contactType = lang.VAT;
      break;
    case PersonType.FOREIGN:
      contactType = lang.FOREIGN;
      break;
    case PersonType.PRIVATE_INDIVIDUAL:
      contactType = lang.PRIVATE;
      break;
    default:
      return (
        <div>Tipo di contatto non valido.</div>
      )
  }

  const edit = () => {
    if (contact.type === ContactType.CUSTOMER) {
      navigate(uri.CustomerEdit + '/' + contact.id);
    } else {
      navigate(uri.SupplierEdit + '/' + contact.id);
    }
  };

  return (
    <div className={'row no-gutters row-contact-container'}>
      <div className={'row no-gutters'}>
        <div className={'col-12'}>
          <div className={'contactListRow-line'} />
        </div>
      </div>
      <div className={'row no-gutters contactListRow-row align-items-center'}>
        <div className={"col no-gutters pb-3 pt-3"} onClick={() => edit()}>
          <div className={'row no-gutters align-items-center'}>
            <div className="col contactListRow-company">
              {contact.companyName}
            </div>
            <div className={'col contactListRow-city list-crop'}>{contact.city}</div>
            <div className={'col contactListRow-gray list-crop'}>{contact.email}</div>
            <div className={'col contactListRow-gray list-crop'}>{contact.phone}</div>
            <div className={'col list-crop'}>{vat}</div>
            <div className={'col contactListRow-gray list-crop'}>
              {contactType}
            </div>
          </div>
        </div>
        <div className={'col-auto position-relative d-flex align-items-start justify-content-end'}>
          {
            menuIsVisible.isVisible ? (
              <div>
                <QuickfiscoIcon name={'menu-active.svg'} />
              </div>
            ) : (
              <div
                onMouseOver={() => setOnMouseOver(false)}
                onMouseOut={() => setOnMouseOver(false)}
                onClick={() => menuIsVisible.setIsVisible(true)}
              >
                <QuickfiscoIcon name={onMouseOver ? 'menu-hover.svg' : 'menu.svg'} />
              </div>
            )
          }
          <div
            className={'contactListRow-menu'}
            ref={menuIsVisible.ref}
          >
            <ContactMenu
              contact={contact}
              componentVisible={menuIsVisible}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
