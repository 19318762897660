import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string
  onChange?: ((year: string) => void);
  category?: string;
  isHeader?: boolean;
}

export function QuickfiscoYearSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { isHeader } = props;

  return (
    <QuickfiscoSelect
      id={id}
      options={getOptions(value)}
      onChange={(e) => e && e.value && onChange && onChange(e.value)}
      styleType={isHeader ? 'header' : 'no-borders'}
      value={getOptions(value).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string): OptionModel[] {
  const options: OptionModel[] = []
  const currentYear = new Date().getFullYear()
  const startYear = 2021;
  const index = currentYear - startYear;

  for (let i = 0; i <= index; i++) {
    options.push({
      label: (startYear + i).toString(),
      value: (startYear + i).toString(),
      selected: value === (startYear + i).toString()
    }
    )
  }

  return options.reverse()
}
