import { useEffect } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import config from '../../../config.json';
import lang from './taxReturnSectionFinal.json';
import './taxReturnSectionFinal.css';

interface Props {
  onClickNextStep: () => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnSectionFinal(props: Props) {
  const { onClickNextStep } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;

  useEffect(() => {
    refreshApiCall(0, true);
  }, []);

  return (
    <div className="row no-gutters text-center p-5 mt-5">
      <div className="col-12">
        <QuickfiscoIcon name={'alert-success.svg'} />
        <div className="row mt-5 mb-4">
          <div className="col-12 tax-return-section-final-title">
            {lang.title}
          </div>
          <div className="col-12 tax-return-section-final-subtitle">
            {lang.subTitle1}
            <br />
            {lang.subTitle2}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 tax-return-section-final-info">
            {lang.info}
            <br />
            <a
              href={`mailto:${config.mail}`}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {lang.emailSupport}
            </a>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 tax-return-section-final-thanks">
            {lang.thanks}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center justify-content-center">
          <div className="col-5">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-final-go-next'}
                label={lang.goNext}
                type={'secondary'}
                onClick={() => onClickNextStep()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
