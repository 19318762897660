import config from '../../../config.json';
import { TaxDeadlineModel, TaxDeadlineStatus } from '../../models/taxDeadlineModel';
import { formatDateIT } from '../../utils/date';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { TaxInfoDeadline } from '../taxInfoDeadline/taxInfoDeadline';
import './taxDeadlineArtisanAndTrader.css';
import lang from './taxDeadlineArtisanAndTrader.json';

interface Props {
  index: number;
  lastItem: boolean;
  deadline: TaxDeadlineModel;
}

interface DeadlineAttributes {
  minimalContribution?: string,
  variablesContributionBalance?: string,
  variablesContributionDeposit?: string,
  taxBalance?: string,
  taxDeposit?: string,
  contributionBalance?: string,
  contributionDeposit?: string,
}

export function TaxDeadlineArtisanAndTrader(props: Props) {
  const { index } = props;
  const { lastItem } = props;
  const { deadline } = props;

  let totalAmount = 0;
  if (deadline.contributionAmount) {
    totalAmount = deadline.contributionAmount;
  }
  if (deadline.taxAmount) {
    totalAmount += deadline.taxAmount;
  }
  if (deadline.minimalContribution) {
    totalAmount += deadline.minimalContribution
  }
  if (deadline.variablesContributionAmount) {
    totalAmount += deadline.variablesContributionAmount
  }

  const deadlineYear = new Date(deadline.expirationDate).getFullYear()

  const dataVisualizeMap: Map<number, DeadlineAttributes> = new Map([
    [
      0,
      {
        minimalContribution: '4º Rata ' + (deadlineYear - 1)
      }
    ],
    [
      1,
      {
        minimalContribution: '1º Rata ' + deadlineYear
      }
    ],
    [
      2,
      {
        variablesContributionBalance: 'Saldo ' + (deadlineYear - 1),
        variablesContributionDeposit: 'Acconto Rata 1 ' + deadlineYear,
        taxBalance: 'Saldo ' + (deadlineYear - 1),
        taxDeposit: 'Acconto Rata 1 ' + deadlineYear,
      }
    ],
    [
      3,
      {
        minimalContribution: '2º Rata ',
        year: deadlineYear
      }
    ],
    [
      4,
      {
        minimalContribution: '3º Rata ',
        year: deadlineYear
      }
    ],
    [
      5,
      {
        variablesContributionDeposit: 'Acconto Rata 2 ' + deadlineYear,
        taxDeposit: 'Acconto Rata 2 ' + deadlineYear,
      }
    ],
    [
      6,
      {
        minimalContribution: '4º Rata ' + (deadlineYear - 1)
      }
    ],
    [
      7,
      {
        minimalContribution: '1º Rata ' + deadlineYear
      }
    ],
    [
      8,
      {
        variablesContributionBalance: 'Saldo ' + (deadlineYear - 1),
        variablesContributionDeposit: 'Acconto Rata 1 ' + deadlineYear,
        taxBalance: 'Saldo ' + (deadlineYear - 1),
        taxDeposit: 'Acconto Rata 1 ' + deadlineYear,
      }
    ],
    [
      9,
      {
        minimalContribution: '2º Rata ',
        year: deadlineYear
      }
    ],
    [
      10,
      {
        minimalContribution: '3º Rata ',
        year: deadlineYear
      }
    ],
    [
      11,
      {
        variablesContributionDeposit: 'Acconto Rata 2 ' + deadlineYear,
        taxDeposit: 'Acconto Rata 2 ' + deadlineYear,
      }
    ],
  ])

  return (
    <div className={'tax-forecast-deadline-artisan-and-trader-container'}>
      <div
        className={'row'}
      >
        <div className={'col-12'}>
          <span className={'tax-forecast-deadline-text fw-normal'}>
            {lang.expiration}
          </span>
        </div>
      </div>
      <div className={'row mt-1'}>
        <div className={'col-12'}>
          <span className={'tax-forecast-deadline-date'}>
            {deadline.expirationDate && formatDateIT(deadline.expirationDate)}
          </span>
        </div>
      </div>
      <div className={'row mt-3'}>
        <TaxInfoDeadline taxOrContribution={totalAmount} className='tax-forecast-deadline-amount fw-normal d-flex flex-row ' />
      </div>
      <div className={'row mt-3'}>
        <div className={'col-12 d-flex justify-content-center align-items-center'}>
          <div className={'tax-forecast-deadline-timeline-circle'} />
          <div
            className={lastItem ? 'tax-forecast-deadline-timeline-last-line' : 'tax-forecast-deadline-timeline-line'} />
        </div>
      </div>
      {
        deadline.status === TaxDeadlineStatus.VALID ? (
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <QuickfiscoIcon name='check-circle.svg' className='h-50 me-2' />
              <span className={'tax-forecast-deadline-text-valid'}>
                {lang.valid}
              </span>
            </div>
          </div>
        ) : (
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <span className={'tax-forecast-deadline-text-not-valid'}>
                <QuickfiscoIcon name='pause-circle.svg' className='h-50 me-2' />
                {lang.notValid}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.minimalContribution && (
          <div>
            <div className={'row mt-3'}>
              <div className={'col-8 d-flex justify-content-between'}>
                <span className={'tax-forecast-deadline-text'}>
                  {lang.minimalContributions}
                </span>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-8 d-flex justify-content-between'}>
                <span className={'tax-forecast-deadline-text fw-normal'}>
                  {dataVisualizeMap.get(index)?.minimalContribution}
                </span>
                <span className={'tax-forecast-deadline-text fw-normal'}>
                  {formatNumberIT(deadline.minimalContribution)}{config.EUR}
                </span>
              </div>
            </div>
          </div>
        )
      }
      {
        (
          dataVisualizeMap.get(index)?.variablesContributionBalance ||
          dataVisualizeMap.get(index)?.variablesContributionDeposit
        ) && (
          <div className={'row mt-3'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text'}>
                {lang.variableContributions}
              </span>
              <span className={'tax-forecast-deadline-text'}>
                {formatNumberIT(deadline.variablesContributionAmount)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.variablesContributionBalance && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {dataVisualizeMap.get(index)?.variablesContributionBalance}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.variablesContributionBalance)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.variablesContributionDeposit && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {dataVisualizeMap.get(index)?.variablesContributionDeposit}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.variablesContributionDeposit)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        (
          dataVisualizeMap.get(index)?.contributionBalance ||
          dataVisualizeMap.get(index)?.contributionDeposit
        ) && (
          <div className={'row mt-3'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text'}>
                {lang.contributions}
              </span>
              <span className={'tax-forecast-deadline-text'}>
                {formatNumberIT(deadline.contributionAmount)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.contributionBalance && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {dataVisualizeMap.get(index)?.contributionBalance}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.contributionBalance)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.contributionDeposit && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {dataVisualizeMap.get(index)?.contributionDeposit}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.contributionDeposit)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        (
          dataVisualizeMap.get(index)?.taxBalance ||
          dataVisualizeMap.get(index)?.taxDeposit
        ) && (
          <div className={'row mt-3'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text'}>
                {lang.tax}
              </span>
              <span className={'tax-forecast-deadline-text'}>
                {formatNumberIT(deadline.taxAmount)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.taxBalance && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {dataVisualizeMap.get(index)?.taxBalance}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.taxBalance)}{config.EUR}
              </span>
            </div>
          </div>
        )
      }
      {
        dataVisualizeMap.get(index)?.taxDeposit && (
          <div className={'row'}>
            <div className={'col-8 d-flex justify-content-between'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {dataVisualizeMap.get(index)?.taxDeposit}
              </span>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {formatNumberIT(deadline.taxDeposit)}{config.EUR}
              </span>
            </div>
          </div>
        )}
    </div>
  );
}
