import React from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { CryptoTaxReturnModel } from '../../models/taxReturnModel';
import { NumericFormat } from 'react-number-format';
import lang from './cryptoAnswersInput.json';
import './cryptoAnswersInput.css';

interface Props {
  id: string;
  cryptoAnswers: CryptoTaxReturnModel[];
  setCryptoAnswers: (cryptoAnswers: CryptoTaxReturnModel[]) => void;
}

export function CryptoAnswersInput(props: Props) {
  const { id } = props;
  const { cryptoAnswers } = props;
  const { setCryptoAnswers } = props;

  const items = cryptoAnswers.map((_, index) => {
    return getItem(index, setCryptoAnswers, cryptoAnswers);
  });

  return (
    <div className={`${id} mb-4`}>
      {items}
      <div className={'row mt-4'}>
        <div className={'col-5 offset-7'}>
          <QuickfiscoButton
            id={'crypto-answers-add-button'}
            label={lang.AddButton}
            onClick={() =>
              setCryptoAnswers([
                ...cryptoAnswers,
                {
                  cryptoType: undefined,
                  counterValue: undefined,
                  counterValuePrevYear: undefined,
                },
              ])
            }
            type={'primary'}
          />
        </div>
      </div>
    </div>
  );
}

function getItem(
  index: number,
  setCryptoAnswers: (cryptoAnswers: CryptoTaxReturnModel[]) => void,
  cryptoAnswers: CryptoTaxReturnModel[]
): React.ReactNode {
  return (
    <div
      className={`row no-gutters ${index === 0 ? 'mt-3' : ''}`}
      key={'item-' + cryptoAnswers[index] + index}
    >
      <div className="col-12">
        <div className="row">
          <div className="col-auto">
            <span className="crypto-answers-input-input-title">
              {index + 1}
              {lang.cryptoSubTitle}
            </span>
          </div>
          <div
            className={'col-auto d-flex align-items-center justify-content-end'}
          >
            <QuickfiscoIcon
              name={'delete-icon.svg'}
              isButton={true}
              onClick={() => {
                const newGoodService = cryptoAnswers
                  .slice(0, index)
                  .concat(cryptoAnswers.slice(index + 1));

                setCryptoAnswers([...newGoodService]);
              }}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className={'col-6'}>
            <QuickfiscoInput
              id={'crypto-answers-type'}
              type={'text'}
              styleType={'default'}
              label={lang.cryptoTypeLabel}
              placeholder={lang.cryptoTypePlaceholder}
              onChange={(e) => {
                const newCryptoAnswer = [...cryptoAnswers];
                newCryptoAnswer[index] = {
                  ...cryptoAnswers[index],
                  cryptoType: e.target.value,
                };
                setCryptoAnswers([...newCryptoAnswer]);
              }}
              value={cryptoAnswers[index].cryptoType || ''}
              required={true}
            />
          </div>
          <div className={'col-6'}>
            <NumericFormat
              key={'crypto-answers-counter-value'}
              id={'crypto-answers-counter-value-input'}
              suffix="€"
              value={
                cryptoAnswers[index].counterValue
                  ? cryptoAnswers[index].counterValue
                  : ''
              }
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              onValueChange={(e) => {
                const newCryptoAnswer = [...cryptoAnswers];
                newCryptoAnswer[index] = {
                  ...cryptoAnswers[index],
                  counterValue: e.floatValue,
                };
                setCryptoAnswers([...newCryptoAnswer]);
              }}
              customInput={QuickfiscoInput}
              styleType={'default'}
              label={lang.counterValue}
              placeholder={'0,00€'}
              fixedDecimalScale
              required={true}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className={'col-6'}>
            <NumericFormat
              key={'crypto-answers-counter-value'}
              id={'crypto-answers-counter-value-input'}
              suffix="€"
              value={
                cryptoAnswers[index].counterValuePrevYear
                  ? cryptoAnswers[index].counterValuePrevYear
                  : ''
              }
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              onValueChange={(e) => {
                const newCryptoAnswer = [...cryptoAnswers];
                newCryptoAnswer[index] = {
                  ...cryptoAnswers[index],
                  counterValuePrevYear: e.floatValue,
                };
                setCryptoAnswers([...newCryptoAnswer]);
              }}
              customInput={QuickfiscoInput}
              styleType={'default'}
              label={lang.counterValuePrevYear}
              placeholder={'0,00€'}
              fixedDecimalScale
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
