export function setItem(key: string, value: string): void {
  window.sessionStorage.setItem(key, value);
}

export function getItem(key: string): string | null {
  return window.sessionStorage.getItem(key);
}

export function delItem(key: string): void {
  window.sessionStorage.removeItem(key);
}
