import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../../config.json';
import { ContactModel, ContactType } from '../../models/contactModel';
import { PaginationModel } from '../../models/paginationModel';
import { ContactService } from '../../services/contactService';
import { PromiseStatuses } from '../../types/strings';

interface SupplierListState {
  status: PromiseStatuses;
  suppliers: PaginationModel<ContactModel>;
  page?: number
  filterCompanyName?: string
}

const initialState: SupplierListState = {
  status: 'idle',
  suppliers: { content: [] }
};

export const getSupplierList = createAsyncThunk(
  'supplierList/getSupplierList',
  async (): Promise<PaginationModel<ContactModel>> => {
    return new ContactService().findAll(ContactType.SUPPLIER);
  }
);

const supplierListSlice = createSlice({
  name: 'supplierList',
  initialState,
  reducers: {
    setSupplierListPage: (state, action: PayloadAction<number | undefined>) => {
      state.page = action.payload;
    },
    setSupplierListFilterCompanyName: (state, action: PayloadAction<string | undefined>) => {
      state.filterCompanyName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSupplierList.fulfilled, (state, action) => {
        let suppliers = ContactService.filter(action.payload, state.filterCompanyName);

        if (state.page !== undefined) {
          suppliers = ContactService.paginate(suppliers, state.page, config.pageSize);
        }

        state.status = 'successfully';
        state.suppliers = suppliers;
      })
      .addCase(getSupplierList.rejected, ((state) => {
        state.status = 'failed';
      }));
  },
});

export const {
  setSupplierListPage,
  setSupplierListFilterCompanyName
} = supplierListSlice.actions;

export default supplierListSlice.reducer;
