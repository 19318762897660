import { useEffect, useState } from 'react';
import { formatPaymentNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInputWithDelete } from '../quickfiscoInputWithDelete/quickfiscoInputWithDelete';
import { QuickfiscoRadioWithBox } from '../quickfiscoRadioWithBox/quickfiscoRadioWithBox';
import { PaymentTypeEnum } from '../../models/paymentModel';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { BankTransfer } from '../bankTransfer/bankTransfer';
import { Stripe } from '../stripe/stripe';
import { Klarna } from '../klarna/klarna';
import { OnboardingPopoverHelp } from '../onboardingPopoverHelp/onboardingPopoverHelp';
import { PaymentResponseStatus } from '../paymentResponseStatus/paymentResponseStatus';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setConfirmedPromoCode,
  paymentCheckout,
  updateInvoice,
  completePayment,
  setStatusCompletePayment,
  setPaymentMethod,
  setCurrentPromocode,
} from '../../redux/slices/paymentSlice';
import uri from '../../routers/quickfiscoPaymentUri.json';
import lang from './quickfiscoPayment.json';
import './quickfiscoPayment.css';

interface Props {
  isRenewal?: boolean;
  renewalNextStep?: () => void;
}

export function QuickfiscoPayment(props: Props) {
  const { isRenewal } = props;
  const { renewalNextStep } = props;

  const [promoCodeAmount, setPromoCodeAmount] = useState<number | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const paymentState = useAppSelector((state) => state.payment);
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    getCheckout();
  }, []);

  useEffect(() => {
    setPromoCodeAmount(paymentState.invoice.promoCode?.amount);
  }, [paymentState]);

  useEffect(() => {
    if (user.registrationSteps?.payment === true) {
      navigate(uri.Mandate);
    }
  }, [user]);

  const getCheckout = () => {
    dispatch(paymentCheckout());
  };

  const applyPromoCode = () => {
    if (
      paymentState.currentPromocode &&
      paymentState.currentPromocode.length > 0 &&
      paymentState
    ) {
      dispatch(
        updateInvoice({
          invoiceId: paymentState.invoice.id,
          promoCode: paymentState.currentPromocode,
        })
      );
    }
  };

  const completePaymentFunc = () => {
    if (paymentState && paymentState.paymentMethod) {
      dispatch(
        completePayment({
          invoiceId: paymentState.invoice.id,
          paymentMethod: paymentState.paymentMethod,
        })
      );
    }
  };

  if (paymentState.statusPaymentCheckout === 'loading') {
    return (
      <div className="vh-75 d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row">
      <OnboardingPopoverHelp />
      <div className={'col-5'}>
        <div className="quickfisco-payment-left-blue-container vh-75 p-4">
          <p className="quickfisco-payment-subtitle">{lang.buying}</p>
          <ul className="quickfisco-payment-buying-list">
            {paymentState.invoice.products.map((item, index) => (
              <li key={index}>{item.name}</li>
            ))}
          </ul>
          <hr className="my-4" />
          <div className={'row d-flex align-items-center'}>
            <div className="col-9">
              <p className="quickfisco-payment-subtitle">
                <b>{lang.subtotal}</b>
                {lang.iva}
              </p>
            </div>
            <div className="col-3 text-end quickfisco-payment-subtitle">
              <b>{formatPaymentNumber(Number(paymentState.invoice.amount))}€</b>
            </div>
            <div className="col-12 mt-3">
              <div className={'row d-flex align-items-center'}>
                <div className="col-7">
                  <QuickfiscoInputWithDelete
                    id={'quickfisco-payment-promocode-input'}
                    value={
                      paymentState.currentPromocode ||
                      paymentState.invoice.promoCode?.code ||
                      ''
                    }
                    disabled={paymentState.disabledPromoCode}
                    placeholder={isRenewal ? lang.voucher : lang.promoCode}
                    onChange={(e) =>
                      dispatch(setCurrentPromocode(e.target.value))
                    }
                    onDelete={() => {
                      if (!paymentState.invoice.promoCode?.id) {
                        dispatch(setCurrentPromocode(undefined));
                        dispatch(setConfirmedPromoCode(undefined));
                        setPromoCodeAmount(undefined);
                      }
                      dispatch(
                        updateInvoice({
                          invoiceId: paymentState.invoice.id,
                          promoCode: undefined,
                          receiptFileId: undefined,
                          receiptDelete: undefined,
                          removePromoCode: true,
                        })
                      );
                    }}
                  />
                </div>
                <div className="col-5 text-end">
                  {paymentState.confirmedPromoCode === 'confirmed' ||
                  promoCodeAmount ? (
                    <b>{formatPaymentNumber(Number(promoCodeAmount))}€</b>
                  ) : (
                    <>
                      {paymentState.statusUpdateInvoice === 'loading' ? (
                        <div
                          className={
                            'd-flex justify-content-end align-items-center w-100 quickfisco-payment-spinner-promocode'
                          }
                        >
                          <QuickfiscoSpinner isWhite={true} />
                        </div>
                      ) : (
                        <QuickfiscoButton
                          id={'quickfisco-payment-apply-promo-code'}
                          label={'APPLICA'}
                          type={
                            paymentState.currentPromocode
                              ? 'tertiary'
                              : 'disabled'
                          }
                          onClick={() => applyPromoCode()}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {paymentState.confirmedPromoCode === 'not-valid' && (
              <div className="col-12 mt-2">
                <span className="quickfisco-payment-promo-code-not-valid">
                  {lang.promoCodeNotValid}
                </span>
              </div>
            )}
          </div>
          <hr className="my-4" />
          <div className={'row d-flex align-items-center'}>
            <div className="col-8">
              <p className="quickfisco-payment-buying-total">
                <b>{lang.total}</b>
                {lang.iva}
              </p>
            </div>
            <div className="col-4 quickfisco-payment-buying-total-amount text-end">
              {formatPaymentNumber(Number(paymentState.invoice.finalAmount))}€
            </div>
          </div>
        </div>
      </div>
      {paymentState.statusCompletePayment === 'successfully' ||
      paymentState.statusCompletePayment === 'failed' ? (
        <div className={'col-7'}>
          <PaymentResponseStatus
            isRenewal={true}
            paymentStatus={paymentState.statusCompletePayment}
            onClick={() => {
              if (paymentState.statusCompletePayment === 'successfully') {
                if (isRenewal === true) {
                  renewalNextStep && renewalNextStep();
                } else {
                  navigate(uri.Mandate);
                }
              } else {
                dispatch(setStatusCompletePayment('idle'));
                getCheckout();
              }
            }}
          />
        </div>
      ) : paymentState.statusPaymentCheckout === 'failed' ? (
        <div className={'col-7'}>
          <PaymentResponseStatus
            paymentStatus={paymentState.statusPaymentCheckout}
            onClick={() => getCheckout()}
          />
        </div>
      ) : (
        <div className={'col-7 ps-4'}>
          <div className={'row'}>
            <div className="col-12">
              <h2 className="quickfisco-payment-payments-type-title">
                {lang.paymentsTypeTitle}
              </h2>
              <p className="quickfisco-payment-payments-type-text mt-3">
                {lang.p1_t1}
                <b>{lang.p1_t2}</b>
                {lang.p1_t3}
              </p>
              {/* <p className="quickfisco-payment-payments-type-text">
                <b>{lang.p2_t1}</b>
                {lang.p2_t2}
                {paymentState.invoice.finalAmount &&
                  formatPaymentNumber(
                    Number((paymentState.invoice.finalAmount / 3).toFixed(2))
                  )}
                €{lang.p2_t3}
              </p> */}
            </div>
          </div>
          <div className={'row d-flex justify-content-between mt-4 mb-5'}>
            <div className="col-6">
              <QuickfiscoRadioWithBox
                id={'quickfisco-payment-cc-radio'}
                name={'quickfisco-payment-cc'}
                value={PaymentTypeEnum.CARD}
                label={lang.cc}
                checked={paymentState.paymentMethod === PaymentTypeEnum.CARD}
                onClick={() => dispatch(setPaymentMethod(PaymentTypeEnum.CARD))}
              />
            </div>
            <div className="col-6">
              <QuickfiscoRadioWithBox
                id={'quickfisco-payment-bank-radio'}
                name={'quickfisco-payment-bank'}
                value={PaymentTypeEnum.WIRE_TRANSFER}
                label={lang.bankTransfer}
                checked={
                  paymentState.paymentMethod === PaymentTypeEnum.WIRE_TRANSFER
                }
                onClick={() =>
                  dispatch(setPaymentMethod(PaymentTypeEnum.WIRE_TRANSFER))
                }
              />
            </div>
            {/* <div className="col-4">
              <QuickfiscoRadioWithBox
                id={'quickfisco-payment-klarna-radio'}
                name={'quickfisco-payment-klarna'}
                value={PaymentTypeEnum.KLARNA}
                label={lang.klarna}
                checked={paymentState.paymentMethod === PaymentTypeEnum.KLARNA}
                onClick={() =>
                  dispatch(setPaymentMethod(PaymentTypeEnum.KLARNA))
                }
              />
            </div> */}
          </div>
          {paymentState.paymentMethod === PaymentTypeEnum.CARD && (
            <Stripe
              onPayClick={() => {
                completePaymentFunc();
              }}
              getCheckout={() => getCheckout()}
              checkoutId={paymentState.invoice.id}
            />
          )}
          {paymentState.paymentMethod === PaymentTypeEnum.WIRE_TRANSFER && (
            <BankTransfer onPayClick={() => completePaymentFunc()} />
          )}
          {/* {paymentState.paymentMethod === PaymentTypeEnum.KLARNA &&
            paymentState.invoice.finalAmount && (
              <Klarna checkoutData={paymentState.invoice} />
            )} */}
        </div>
      )}
    </div>
  );
}
