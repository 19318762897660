import { useState, useEffect } from 'react';
import { ContactModel, ContactType } from '../../models/contactModel';
import {
  CurrencyType,
  GoodServicesModel,
  InvoiceModel,
  InvoiceStatusType,
  PaymentModeType,
} from '../../models/invoiceModel';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import { ContactSelect } from '../contactSelect/contactSelect';
import { SetState } from '../../types/functions';
import { OccasionalJobInvoiceService } from '../../services/occasionalJobInvoiceService';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { PaymentModeSelect } from '../paymentModeSelect/paymentModeSelect';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { OccasionalJobAmount } from '../occasionalJobAmount/occasionalJobAmount';
import { GoodServicesInput } from '../goodServicesInput/goodServicesInput';
import { Operation, PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SeePreviewPdfModal } from '../seePreviewPdfModal/seePreviewPdfModal';
import { downloadPdf } from '../../utils/file';
import uri from '../../routers/quickfiscoUri.json';
import { v4 as uiidv4 } from 'uuid';
import './occasionalJobInvoiceSaveOrDuplicateOrEdit.css';
import lang from './occasionalJobInvoiceSaveOrDuplicateOrEdit.json';

interface Props {
  occasionalJobInvoiceToEdit?: InvoiceModel;
  operation: Operation;
}

interface Setters {
  setCustomer: SetState<ContactModel | undefined>;
  setEmissionDate: SetState<Date>;
  setStartDate: SetState<Date | undefined>;
  setEndDate: SetState<Date | undefined>;
  setPaymentMode: SetState<PaymentModeType | undefined>;
  setNotes: SetState<string | undefined | null>;
  setGoodServices: SetState<GoodServicesModel[] | undefined | null>;
  setApplyWithholdingTax: SetState<boolean | undefined>;
  setStampId: SetState<string | undefined>;
  setOccasionalJobInvoiceStatus: SetState<InvoiceStatusType>;
}

export function OccasionalJobInvoiceSaveOrDuplicateOrEdit(props: Props) {
  const { occasionalJobInvoiceToEdit } = props;
  const { operation } = props;

  const navigate = useNavigate();

  const [saveStatus, setSaveStatus] = useState<PromiseStatuses>('idle');
  const [sendStatus, setSendStatus] = useState<PromiseStatuses>('idle');
  const [downloadPdfStatus, setDownloadPdfStatus] =
    useState<PromiseStatuses>('idle');
  const [seePreviewPdfOpen, setSeePreviewPdfOpen] = useState(false);

  const [customer, setCustomer] = useState<ContactModel | undefined>(undefined);
  const [emissionDate, setEmissionDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [paymentMode, setPaymentMode] = useState<PaymentModeType | undefined>(
    undefined
  );
  const [notes, setNotes] = useState<string | undefined | null>(undefined);
  const [goodServices, setGoodServices] = useState<
    GoodServicesModel[] | undefined | null
  >(undefined);
  const [applyWithholdingTax, setApplyWithholdingTax] = useState<
    boolean | undefined
  >(true);
  const [stampId, setStampId] = useState<string | undefined>(undefined);
  const [occasionalJobInvoiceStatus, setOccasionalJobInvoiceStatus] =
    useState<InvoiceStatusType>(InvoiceStatusType.DRAFT);

  const [errorCustomer, setErrorCustomer] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorStartEndDate, setErrorStartEndDate] = useState(false);
  const [errorPaymentMode, setErrorPaymentMode] = useState(false);
  const [errorGoodServices, setErrorGoodServices] = useState(false);
  const [errorGoodServicesInput, setErrorGoodServicesInput] = useState(false);
  const [errorStampId, setErrorStampId] = useState(false);

  const service = new OccasionalJobInvoiceService({
    setErrorCustomer,
    setErrorDate,
    setErrorStartEndDate,
    setErrorPaymentMode,
    setErrorGoodServices,
    setErrorGoodServicesInput,
    setErrorStampId,
  });

  let disabled =
    (operation === 'edit' &&
      occasionalJobInvoiceToEdit?.status === InvoiceStatusType.SENT) ||
    (operation === 'edit' &&
      occasionalJobInvoiceToEdit?.status === InvoiceStatusType.REVERSED);

  let occasionalJobInvoiceToSend: InvoiceModel = {
    customer,
    date: emissionDate,
    startDate,
    endDate,
    paymentMode,
    notes,
    goodServices,
    applyWithholdingTax,
    stampId,
    status: occasionalJobInvoiceStatus,
    currency: CurrencyType.EUR,
    supplier: {
      bankAccount: { bankIban: "", bankName: "" },
      type: ContactType.CUSTOMER
    }
  };

  useEffect(() => {
    if (occasionalJobInvoiceToEdit !== undefined) {
      setExistingValue(operation, occasionalJobInvoiceToEdit, {
        setCustomer,
        setEmissionDate,
        setStartDate,
        setEndDate,
        setPaymentMode,
        setNotes,
        setGoodServices,
        setApplyWithholdingTax,
        setStampId,
        setOccasionalJobInvoiceStatus,
      });
    }
  }, [occasionalJobInvoiceToEdit]);

  return (
    <div className={'row'}>
      <QuickfiscoSuccess
        message={lang.saveSuccess}
        active={saveStatus === 'successfully'}
        close={() => setSaveStatus('idle')}
      />
      <QuickfiscoError
        message={lang.saveError}
        active={saveStatus === 'failed'}
        close={() => setSaveStatus('idle')}
      />
      <QuickfiscoError
        message={lang.sendError}
        active={sendStatus === 'failed'}
        close={() => setSaveStatus('idle')}
      />
      <QuickfiscoError
        message={'Errore durante il download del pdf'}
        active={downloadPdfStatus === 'failed'}
        close={() => setDownloadPdfStatus('idle')}
      />
      <div className="col-12 col-xl-5 mt-4">
        <div className="row no-gutters">
          <div className="col-12 no-gutters">
            <ContactSelect
              type={ContactType.CUSTOMER}
              selected={customer}
              setContact={(contact) => {
                setCustomer(contact);
                service.validateContact(contact);
              }}
              error={errorCustomer}
              requiredField={true}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="row no-gutters mt-4">
          <div className="col-12 invoice-occasional-job-saveOrDuplicate-container p-4">
            <div className="row">
              <div className="col-12">
                <QuickfiscoDatePicker
                  id={'invoice-occasional-job-saveOrDuplicate-date'}
                  label={lang.emissionDate}
                  onChange={(e) =>
                    e && setEmissionDate(resolveUndefinedDate(formatDateUS(e)))
                  }
                  value={emissionDate && new Date(emissionDate)}
                  required={true}
                  styleType={'default'}
                  maxDate={new Date()}
                  disabled={disabled}
                  error={errorDate}
                  errorLabel={lang.emissionDateError}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <QuickfiscoDatePicker
                  id={'invoice-occasional-job-saveOrDuplicate-start-date'}
                  label={lang.startDate}
                  onChange={(e) =>
                    e && setStartDate(resolveUndefinedDate(formatDateUS(e)))
                  }
                  value={startDate && new Date(startDate)}
                  styleType={'default'}
                  maxDate={new Date()}
                  disabled={disabled}
                />
              </div>
              <div className="col-6">
                <QuickfiscoDatePicker
                  id={'invoice-occasional-job-saveOrDuplicate-end-date'}
                  label={lang.endDate}
                  onChange={(e) =>
                    e && setEndDate(resolveUndefinedDate(formatDateUS(e)))
                  }
                  value={endDate && new Date(endDate)}
                  styleType={'default'}
                  maxDate={new Date()}
                  disabled={disabled}
                />
              </div>
              <div className="col-12">
                {errorStartEndDate && (
                  <div
                    className={'invoice-occasional-job-saveOrDuplicate-error'}
                  >
                    {lang.errorStartEndDate}
                  </div>
                )}
              </div>
            </div>
            <div className={'row mt-3'}>
              <PaymentModeSelect
                id={'invoice-occasional-job-saveOrDuplicate-payment-mode'}
                onChange={(paymentMode) => {
                  setPaymentMode(paymentMode);
                  service.validatePaymentMode(paymentMode);
                }}
                value={paymentMode}
                error={errorPaymentMode}
                disabled={disabled}
              />
            </div>
            <div className={'row mt-5'}>
              <QuickfiscoTextarea
                id={'invoice-occasional-job-saveOrDuplicate-notes'}
                label={lang.notes}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    setNotes(undefined);
                  } else {
                    setNotes(e.target.value);
                  }
                }}
                value={notes ? notes : ''}
                placeholder={lang.notesPlaceholder1}
                secondaryLabel={lang.notesPlaceholder2}
                maxLength={330}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-7 mt-4">
        <div className="row no-gutters">
          <div className="col-12 invoice-occasional-job-saveOrDuplicate-container p-4">
            <div className="row">
              <div className="col-12">
                <GoodServicesInput
                  goodServices={
                    goodServices !== undefined &&
                    goodServices !== null &&
                    goodServices.length > 0
                      ? goodServices
                      : [
                          {
                            key: uiidv4(),
                            quantity: 1,
                            amount: 0,
                          },
                        ]
                  }
                  setGoodServices={(_goodServices) =>
                    setGoodServices(_goodServices)
                  }
                  invoiceSign={'occasionalJob'}
                  disabled={disabled}
                  error={errorGoodServices || errorGoodServicesInput}
                  errorLabel={
                    errorGoodServices
                      ? lang.goodServiceError
                      : lang.goodServiceInputError
                  }
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <OccasionalJobAmount
                  totalAmount={(goodServices && goodServices[0].amount) || 0}
                  applyWithholdingTaxFlag={applyWithholdingTax}
                  setApplyWithholdingTax={(checked) =>
                    setApplyWithholdingTax(checked)
                  }
                  stampId={stampId}
                  setStampId={(id) => {
                    if (id.length === 0) {
                      setStampId(undefined);
                    } else {
                      setStampId(id);
                    }
                  }}
                  disabled={disabled}
                  error={errorStampId}
                  errorLabel={lang.stampIdError}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-4'}>
                {downloadPdfStatus === 'loading' ? (
                  <div
                    className={
                      'w-100 d-flex justify-content-center align-items-center'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'invoice-occasional-job-saveOrDuplicate-pdf-button'}
                    label={lang.previewButton}
                    type={operation === 'edit' ? 'primary' : 'disabled'}
                    onClick={() => setSeePreviewPdfOpen(true)}
                  />
                )}
              </div>
              <div className={'col-4'}>
                {saveStatus === 'loading' ? (
                  <div
                    className={
                      'w-100 d-flex justify-content-center align-items-center'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'invoice-occasional-job-saveOrDuplicate-save-button'}
                    type={
                      operation === 'save' ||
                      operation === 'duplicate' ||
                      (operation === 'edit' &&
                        occasionalJobInvoiceToEdit?.status === 'DRAFT')
                        ? 'primary'
                        : 'disabled'
                    }
                    label={lang.saveButton}
                    onClick={() => {
                      if (operation === 'save' || operation === 'duplicate') {
                        saveDraft(
                          occasionalJobInvoiceToSend,
                          {
                            setErrorCustomer,
                            setErrorDate,
                            setErrorStartEndDate,
                            setErrorPaymentMode,
                            setErrorGoodServices,
                            setErrorGoodServicesInput,
                            setErrorStampId,
                          },
                          setSaveStatus,
                          navigate
                        );
                      } else {
                        occasionalJobInvoiceToSend.id =
                          occasionalJobInvoiceToEdit?.id;
                        edit(
                          occasionalJobInvoiceToSend,
                          {
                            setErrorCustomer,
                            setErrorDate,
                            setErrorStartEndDate,
                            setErrorPaymentMode,
                            setErrorGoodServices,
                            setErrorGoodServicesInput,
                            setErrorStampId,
                          },
                          setSaveStatus
                        );
                      }
                    }}
                  />
                )}
              </div>
              <div className={'col-4'}>
                {sendStatus === 'loading' ? (
                  <div
                    className={
                      'w-100 d-flex justify-content-center align-items-center'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'invoice-occasional-job-saveOrDuplicate-send-button'}
                    label={lang.sendButton}
                    type={
                      operation === 'edit' &&
                      occasionalJobInvoiceToEdit?.status === 'DRAFT'
                        ? 'secondary'
                        : 'disabled'
                    }
                    onClick={() => {
                      if (occasionalJobInvoiceToEdit?.id) {
                        send(
                          occasionalJobInvoiceToEdit?.id,
                          setSendStatus,
                          navigate
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SeePreviewPdfModal
        open={seePreviewPdfOpen}
        setOpen={(open: boolean) => setSeePreviewPdfOpen(open)}
        invoiceSign={'occasionalJob'}
        onClick={() => {
          getPdf(
            {
              ...occasionalJobInvoiceToSend,
              id: occasionalJobInvoiceToEdit?.id,
            },
            setDownloadPdfStatus
          );
        }}
        occasionalJob={{
          ...occasionalJobInvoiceToSend,
          id: occasionalJobInvoiceToEdit?.id,
        }}
        downloadPdfStatus={downloadPdfStatus}
      />
    </div>
  );
}

async function setExistingValue(
  operation: Operation,
  occasionalJobInvoiceToEdit: InvoiceModel,
  setters: Setters
) {
  let invoiceStatus = occasionalJobInvoiceToEdit.status;

  if (
    (operation === 'duplicate' &&
      occasionalJobInvoiceToEdit.status === InvoiceStatusType.SENT) ||
    (operation === 'duplicate' &&
      occasionalJobInvoiceToEdit.status === InvoiceStatusType.REVERSED)
  ) {
    invoiceStatus = InvoiceStatusType.DRAFT;
  }

  if (occasionalJobInvoiceToEdit.status) {
    setters.setCustomer(occasionalJobInvoiceToEdit.customer);
  }
  setters.setEmissionDate(occasionalJobInvoiceToEdit.date);
  setters.setStartDate(occasionalJobInvoiceToEdit.startDate);
  setters.setEndDate(occasionalJobInvoiceToEdit.endDate);
  setters.setPaymentMode(occasionalJobInvoiceToEdit.paymentMode);
  setters.setNotes(occasionalJobInvoiceToEdit.notes);
  setters.setGoodServices(occasionalJobInvoiceToEdit.goodServices);
  setters.setApplyWithholdingTax(
    occasionalJobInvoiceToEdit.applyWithholdingTax
  );
  setters.setStampId(occasionalJobInvoiceToEdit.stampId);
  setters.setOccasionalJobInvoiceStatus(invoiceStatus);
}

function handleSaveOrEditError(
  err: any,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters
): void {
  console.error(err);

  err.json().then((data: any) => {
    if (data.message === 'error.invalid-date') {
      errorSetters.setErrorDate && errorSetters.setErrorDate(true);
    }
  });

  setStatus('failed');
}

async function saveDraft(
  invoice: InvoiceModel,
  errorSetters: InvoiceErrorSetters,
  setSaveStatus: SetState<PromiseStatuses>,
  navigate: NavigateFunction
): Promise<void> {
  const service = new OccasionalJobInvoiceService(errorSetters);

  if (!service.validate(invoice, 'occasionalJob')) {
    return;
  }

  setSaveStatus('loading');
  service
    .save({ ...invoice }, [], [])
    .then((id) => {
      navigate(uri.OccasionalJobInvoice + '/' + id);
    })
    .catch((err) => {
      console.error(err);
      handleSaveOrEditError(err, setSaveStatus, errorSetters);
    });
}

async function edit(
  invoice: InvoiceModel,
  errorSetters: InvoiceErrorSetters,
  setStatus: SetState<PromiseStatuses>
): Promise<void> {
  const service = new OccasionalJobInvoiceService(errorSetters);

  if (!service.validate(invoice, 'occasionalJob')) {
    return;
  }

  setStatus('loading');
  service
    .edit({ ...invoice }, [], [])
    .then(() => {
      setStatus('successfully');
    })
    .catch((err) => {
      console.error(err);
      handleSaveOrEditError(err, setStatus, errorSetters);
    });
}

async function getPdf(
  invoice: InvoiceModel,
  setStatus: SetState<PromiseStatuses>
): Promise<void> {
  if (!invoice.id) {
    return;
  }

  const service = new OccasionalJobInvoiceService();

  setStatus('loading');
  service
    .getPdf(invoice.id)
    .then((data) => {
      setStatus('idle');
      downloadPdf(
        OccasionalJobInvoiceService.getPdfName(
          'occasionalJob',
          undefined,
          invoice.number,
          invoice.customer?.companyName
        ),
        data,
        'application/pdf'
      );
    })
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}

function send(
  invoiceId: string,
  setStatus: SetState<PromiseStatuses>,
  navigate: NavigateFunction
): void {
  if (!invoiceId) {
    return;
  }

  const service = new OccasionalJobInvoiceService();

  setStatus('loading');
  service
    .send(invoiceId)
    .then(() => {
      navigate(uri.OccasionalJobInvoice);
    })
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}
