import { ContactErrorSetters } from '../models/contactErrorSetters';
import { ClientResidence, ContactModel, ContactType, ForeignType, PersonType } from '../models/contactModel';
import { CountryType } from '../models/countryModel';
import { PaginationModel } from '../models/paginationModel';
import { emailRegex, fiscalCodeRegex, vatRegex, zipCodeRegex } from '../regex/regex';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class ContactService {

  private readonly url = host + '/api/v1/contacts';

  private readonly http: Http;
  private readonly contactErrorSetters?: ContactErrorSetters;

  public constructor(errorSetters?: ContactErrorSetters) {
    this.http = Http.getInstance();
    this.contactErrorSetters = errorSetters;
  }

  public static filter(contacts: PaginationModel<ContactModel>, companyName?: string): PaginationModel<ContactModel> {
    const output = { ...contacts };

    if (
      companyName !== undefined &&
      companyName !== ''
    ) {
      output.content = output.content.filter(contact => (
        contact.companyName?.toLowerCase().includes(companyName.toLowerCase()))
      );
    }

    return output;
  }

  public static paginate(contacts: PaginationModel<ContactModel>, page: number, pagesize: number): PaginationModel<ContactModel> {
    const output = { ...contacts };

    output.number = page;
    output.first = page === 0;
    output.empty = output.content.length === 0;
    output.totalPages = Math.ceil(output.content.length / pagesize);
    output.last = page === (output.totalPages - 1);

    const indexFrom = (page * pagesize);
    const indexTo = page * pagesize + pagesize;

    output.content = output.content.slice(indexFrom, indexTo);

    return output;
  }

  public findAll(type: ContactType): Promise<PaginationModel<ContactModel>> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + type.toString().toLowerCase() + '/all'
    })
      .then(res => res.json())
      .then((data: PaginationModel<ContactModel>) => data);
  }

  public findById(id: string): Promise<ContactModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + id
    })
      .then(res => res.json())
      .then((data: ContactModel) => data);
  }

  public save(type: ContactType, contact: ContactModel): Promise<ContactModel> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + type.toString().toLowerCase(),
      body: JSON.stringify(contact),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => res.json())
      .then((data: ContactModel) => data);
  }

  public edit(type: ContactType, contact: ContactModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'put',
      url: this.url + '/' + type.toString().toLowerCase() + '/' + contact.id,
      body: JSON.stringify(contact),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public del(id: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'delete',
      url: this.url + '/' + id
    })
      .then(() => undefined);
  }

  public validateSdi(sdi?: string | null, pec?: string | null, personType?: PersonType): boolean {
    this.contactErrorSetters?.setErrorSDI(false);

    if (
      personType === PersonType.VAT &&
      (sdi === undefined || sdi === null || sdi === '') &&
      (pec === undefined || pec === null || pec === '')
    ) {
      this.contactErrorSetters?.setErrorSDI(true);
      return false;
    }

    if (
      personType !== PersonType.VAT &&
      (sdi === undefined || sdi === null || sdi === '')
    ) {
      this.contactErrorSetters?.setErrorSDI(true);
      return false;
    }

    return true;
  }

  public validatePersonType(personType?: PersonType): boolean {
    this.contactErrorSetters?.setErrorPersonType(false);

    if (!personType) {
      this.contactErrorSetters?.setErrorPersonType(true);
      return false;
    }

    return true;
  }

  public validateForeignType(foreign?: ForeignType, personType?: PersonType, type?: ContactType): boolean {
    this.contactErrorSetters?.setErrorForeignCustomerType(false);

    if (personType === PersonType.FOREIGN && type === ContactType.CUSTOMER && !foreign) {
      this.contactErrorSetters?.setErrorForeignCustomerType(true);
      return false;
    }

    return true;
  }

  public validateClientResidence(residence?: ClientResidence, personType?: PersonType, type?: ContactType): boolean {
    this.contactErrorSetters?.setErrorClientResidence(false);

    if (personType === PersonType.FOREIGN && type === ContactType.CUSTOMER && !residence) {
      this.contactErrorSetters?.setErrorClientResidence(true);
      return false;
    }

    return true;
  }

  public validateVat(vat?: string, personType?: PersonType): boolean {
    this.contactErrorSetters?.setErrorVat(false);

    if (
      personType === PersonType.GOV &&
      vat &&
      !vatRegex.test(vat)
    ) {
      this.contactErrorSetters?.setErrorVat(true);
      return false;
    }

    if (
      personType !== PersonType.GOV &&
      personType !== PersonType.PRIVATE_INDIVIDUAL &&
      (
        !vat ||
        (personType !== PersonType.FOREIGN && !vatRegex.test(vat))
      )
    ) {
      this.contactErrorSetters?.setErrorVat(true);
      return false;
    }

    return true;
  }

  public validateCity(city?: string): boolean {
    this.contactErrorSetters?.setErrorCity(false);

    if (!city || city.length > 60) {
      this.contactErrorSetters?.setErrorCity(true);
      return false;
    }

    return true;
  }

  public validateCountry(country?: string, personType?: PersonType): boolean {
    this.contactErrorSetters?.setErrorCountry(false);

    if (!country ||
      (personType === PersonType.FOREIGN && country === CountryType.IT) ||
      (personType !== PersonType.FOREIGN && country !== CountryType.IT)
    ) {
      this.contactErrorSetters?.setErrorCountry(true);
      return false;
    }

    return true;
  }

  public validateAddress(address?: string): boolean {
    this.contactErrorSetters?.setErrorAddress(false);

    if (!address || address.length > 60) {
      this.contactErrorSetters?.setErrorAddress(true);
      return false;
    }

    return true;
  }

  public validateZipCode(zipCode?: string, personType?: PersonType): boolean {
    this.contactErrorSetters?.setErrorZipCode(false);

    if (
      !zipCode || (
        personType !== PersonType.FOREIGN &&
        !zipCodeRegex.test(zipCode)
      )
    ) {
      this.contactErrorSetters?.setErrorZipCode(true);
      return false;
    }

    return true;
  }

  public validateCompanyName(companyName?: string): boolean {
    this.contactErrorSetters?.setErrorCompanyName(false);

    if (!companyName || companyName.length > 80) {
      this.contactErrorSetters?.setErrorCompanyName(true);
      return false;
    }

    return true;
  }

  public validateEmail(email?: string): boolean {
    this.contactErrorSetters?.setErrorEmail(false);

    if (!email || !emailRegex.test(email)) {
      this.contactErrorSetters?.setErrorEmail(true);
      return false;
    }

    return true;
  }

  public validatePec(pec?: string | null): boolean {
    this.contactErrorSetters?.setErrorPEC(false);

    if (pec !== undefined && pec !== null && pec !== '' && !emailRegex.test(pec)) {
      this.contactErrorSetters?.setErrorPEC(true);
      return false;
    }

    return true;
  }

  public validateState(country?: string, state?: string): boolean {
    this.contactErrorSetters?.setErrorState(false);

    if (country === CountryType.IT && !state) {
      this.contactErrorSetters?.setErrorState(true);
      return false;
    }

    return true;
  }

  public validateFiscalCode(fiscalCode?: string, personType?: PersonType): boolean {
    this.contactErrorSetters?.setErrorFiscalCode(false);

    if (personType === PersonType.FOREIGN && fiscalCode) {
      this.contactErrorSetters?.setErrorFiscalCode(true);
      return false;
    }

    if (
      personType !== PersonType.FOREIGN && (
        !fiscalCode || (
          personType !== PersonType.GOV &&
          (
            !vatRegex.test(fiscalCode) &&
            !fiscalCodeRegex.test(fiscalCode)
          )
        )
      )
    ) {
      this.contactErrorSetters?.setErrorFiscalCode(true);
      return false;
    }

    return true;
  }

  public validate(contact: ContactModel): boolean {
    let isValid = true;

    if (!this.validatePersonType(contact.personType)) {
      isValid = false;
    }
    if (!this.validateVat(contact.vat, contact.personType)) {
      isValid = false;
    }
    if (!this.validateSdi(contact.sdi, contact.pec, contact.personType)) {
      isValid = false;
    }
    if (!this.validateCity(contact.city)) {
      isValid = false;
    }
    if (!this.validateCountry(contact.country, contact.personType)) {
      isValid = false;
    }
    if (!this.validateAddress(contact.address)) {
      isValid = false;
    }
    if (!this.validateZipCode(contact.zipCode, contact.personType)) {
      isValid = false;
    }
    if (!this.validateCompanyName(contact.companyName)) {
      isValid = false;
    }
    if (!this.validateEmail(contact.email)) {
      isValid = false;
    }
    if (!this.validatePec(contact.pec)) {
      isValid = false;
    }
    if (!this.validateState(contact.country, contact.state)) {
      isValid = false;
    }
    if (!this.validateFiscalCode(contact.fiscalCode, contact.personType)) {
      isValid = false;
    }
    if (!this.validateClientResidence(contact.residence, contact.personType, contact.type)) {
      isValid = false;
    }
    if (!this.validateForeignType(contact.foreign, contact.personType, contact.type)) {
      isValid = false;
    }

    return isValid;
  }

}
