import { SingleValue } from 'react-select';
import { VatNature, vatNatureMap } from '../../models/invoiceModel';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  disabled?: boolean;
  onChange?: ((vatNature?: VatNature) => void);
}

export function VatNatureSelect(props: Props) {
  const { onChange } = props;
  const { value } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={'vat-nature-select'}
      label={'Natura IVA'}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(VatNature[e.value])
        }
      }}
      options={getOptions(value)}
      requiredField={true}
      disabled={disabled}
      styleType={'default'}
      value={getOptions(value).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string): OptionModel[] {
  let optionList: OptionModel[] = [];
  optionList.push(
    {
      label: 'Natura IVA',
      value: undefined,
      selected: value === undefined
    }
  );
  vatNatureMap.forEach((_value, key) => {
    const label = vatNatureMap.get(key) ? vatNatureMap.get(key) : ''
    optionList.push(
      {
        label: label ? label : '',
        value: key,
        selected: value === key
      },
    )
  })
  return optionList
}
