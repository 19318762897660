import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './contributionsPaidFundInfo.json';
import './contributionsPaidFundInfo.css';

export function ContributionsPaidFundInfo() {
  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          className="ps-2"
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div
          ref={componentVisible.ref}
          className={'contributions-paid-fund-infopopup'}
        >
          {componentVisible.isVisible && (
            <div className={'contributions-paid-fund-infopopup-container pb-1'}>
              <p className={'contributions-paid-fund-infotext'}>{lang.text}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
