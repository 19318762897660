import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { getCrmUserData } from '../../redux/slices/onboardingSlice';
import { getItem, setItem } from '../../utils/localStorage';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { OnboardingInitErrorModal } from '../../components/onboardingInitErrorModal/onboardingInitErrorModal';
import uri from '../../routers/quickfiscoPaymentUri.json';
import './welcomeOnboarding.css';

export function WelcomeOnboarding() {
  const [open, setOpen] = useState(!!getItem('onboardingInitError'));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get('id');

  const user = useAppSelector((state) => state.onboarding.user);
  const status = useAppSelector((state) => state.onboarding.statusCrmUserData);

  useEffect(() => {
    if (userId) {
      dispatch(getCrmUserData(userId));
      setItem('userIdCrm', userId);
    } else if (getItem('userIdCrm')) {
      dispatch(getCrmUserData(getItem('userIdCrm') as string));
    }
  }, []);

  useEffect(() => {
    if (!getItem('onboardingInitError')) {
      setOpen(false);
    }
  }, [getItem('onboardingInitError')]);

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || (!userId && !getItem('userIdCrm'))) {
    return <OnboardingApiError statusFailed={status === 'failed'} />;
  }

  return (
    <div className="row full-screen mr-0 bg-blue">
      <OnboardingPopoverHelp />
      <ViewportWarning />
      <div className="col-2">
        <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4 mt-4" />
      </div>
      <div className="col-8 mt-5">
        <div className="welcome-onboarding-container text-center py-5 my-5">
          <div className="row">
            <div className="col-10 offset-1">
              <QuickfiscoIcon name={'handEmoji.svg'} />
              <h1 className="welcome-onboarding-title my-4">{`Ciao ${user?.name}, benvenutə nella procedura di attivazione del servizio Quickfisco.`}</h1>
              <p className="welcome-onboarding-requirements">
                Prima di iniziare assicurati di essere in possesso di:
              </p>
              <p className="welcome-onboarding-requirements">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>Documento di identità</b> (Carta identità o passaporto,
                tessera sanitaria)
              </p>
              <p className="welcome-onboarding-requirements">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>Indirizzo</b> di residenza Italiana
              </p>
              <p className="welcome-onboarding-requirements">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>SPID o CIE</b> (Carta di Identità Elettronica) per effettuare
                l’accesso al tuo cassetto fiscale
              </p>
              <hr className="hr-style my-4" />
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 text-start">
              <h2 className="welcome-onboarding-subtitle mb-3">
                <QuickfiscoIcon
                  name={'alert-success-small.svg'}
                  className="me-3"
                />
                QUALCHE INDICAZIONE PRIMA DI PROCEDERE
              </h2>
              <p className="welcome-onboarding-info">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>Ci vogliono circa 5 minuti</b> per compilare quanto
                richiesto.
              </p>
              <p className="welcome-onboarding-info">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>Assicurati di prenderti il tempo necessario</b> per
                concludere la procedura.
              </p>
              <p className="welcome-onboarding-info">
                <span className="welcome-onboarding-blue-arrow">➔</span>A
                conclusione della procedura di registrazione, prima di poter
                utilizzare il nostro servizio,{' '}
                <b>
                  dovremo verificare e successivamente attivare il tuo account
                </b>
                . Effettueremo le verifiche entro 24h lavorative dalla ricezione
                dei documenti e del pagamento.
              </p>
              <p className="welcome-onboarding-info">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>Se stai aprendo partita iva</b>, la procedura di verifica e
                attivazione del tuo account richiederanno dei tempi tecnici
                differenti, li trovi indicati all’interno della mail di recap.
              </p>
              <p className="welcome-onboarding-info">
                <span className="welcome-onboarding-blue-arrow">➔</span>
                <b>
                  I dati che inserirai per il pagamento non verranno salvati
                </b>{' '}
                e quindi non verranno utilizzati per rinnovi automatici.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-4">
            <div className="col-5">
              <QuickfiscoButton
                id={'welcome-onboarding-go-next'}
                label={'INIZIA'}
                type={'secondary'}
                onClick={() =>
                  navigate({
                    pathname: uri.LoginOnboarding,
                    search: `?id=${userId || getItem('userIdCrm')}`,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <OnboardingInitErrorModal open={open} setOpen={setOpen} />
    </div>
  );
}
