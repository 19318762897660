import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel } from '../../models/contactModel';
import { InvoiceSign, NewContactInvoiceStatus } from '../../types/strings';

interface NewContactToInvoiceState {
  contact?: ContactModel;
  invoiceSign?: InvoiceSign;
  status: NewContactInvoiceStatus;
}

const initialState: NewContactToInvoiceState = {
  status: 'idle'
};

const newContactToInvoiceSlice = createSlice({
  name: 'newContactToInvoice',
  initialState,
  reducers: {
    setNewContactToInvoice: (state, action: PayloadAction<ContactModel | undefined>) => {
      state.contact = action.payload;
    },
    setNewContactToInvoiceStatus: (state, action: PayloadAction<NewContactInvoiceStatus>) => {
      state.status = action.payload;
    },
    setNewContactToInvoiceSign: (state, action: PayloadAction<InvoiceSign | undefined>) => {
      state.invoiceSign = action.payload;
    }
  }
});

export const {
  setNewContactToInvoice,
  setNewContactToInvoiceStatus,
  setNewContactToInvoiceSign
} = newContactToInvoiceSlice.actions;

export default newContactToInvoiceSlice.reducer;
