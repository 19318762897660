import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './sendInvoice.css';
import lang from './sendInvoice.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClick: () => void;
  loading?: boolean;
}

export function SendInvoice(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { onClick } = props;
  const { loading } = props;

  return (
    <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
      <div className={'send-invoice-container'}>
        <div className={'row my-4'}>
          <div className={'col-12'}>
            <div className={'row'}>
              <div className={'col-4 offset-4 text-center send-invoice-title'}>
                {lang.title}
              </div>
            </div>
            <div className={'row mt-1'}>
              <div className={'col-10 offset-1 text-center'}>
                {lang.message1}
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-10 offset-1 text-center'}>
                {lang.message2}
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-4 offset-4'}>
                {
                  loading ? (
                    <div className={'w-100 d-flex justify-content-center align-items-center'}>
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={'send-invoice-button'}
                      label={lang.label}
                      onClick={() => onClick && onClick()}
                      type={'secondary'}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}
