import { InvoiceErrorSetters } from '../models/invoiceErrorSetters';
import { InvoiceFilters } from '../models/invoiceFilters';
import { InvoiceModel, InvoiceStatusType, InvoiceType } from '../models/invoiceModel';
import { PaginationModel } from '../models/paginationModel';
import { StsInvoiceReportModel } from '../models/stsInvoiceReportReportModel';
import { host } from '../utils/config';
import { getDateRangeByYearAndMonth } from '../utils/date';
import { CommonInvoiceService } from './commonInvoiceService';

export class StsInvoiceService extends CommonInvoiceService {

  public constructor(errorSetters?: InvoiceErrorSetters) {
    super(host + '/api/v1/invoices/sts', errorSetters);
  }

  public static isEditable(invoice: InvoiceModel): boolean {
    return invoice.status === InvoiceStatusType.DRAFT ||
      invoice.status === InvoiceStatusType.REJECTED ||
      invoice.status === InvoiceStatusType.QUEUED_NACK ||
      invoice.status === InvoiceStatusType.ACCEPTED ||
      invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK ||
      invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED;
  }

  public static isSendable(invoice: InvoiceModel): boolean {
    return invoice.status === InvoiceStatusType.DRAFT ||
      invoice.status === InvoiceStatusType.REJECTED ||
      invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED ||
      invoice.status === InvoiceStatusType.QUEUED_NACK ||
      invoice.status === InvoiceStatusType.ACCEPTED ||
      invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK;
  }

  private static setSTSTypeToInvoices(invoices: PaginationModel<InvoiceModel>): PaginationModel<InvoiceModel> {
    return {
      ...invoices,
      content: invoices.content.map(invoice => StsInvoiceService.setSTSTypeToInvoice(invoice))
    };
  }

  private static setSTSTypeToInvoice(invoice: InvoiceModel): InvoiceModel {
    return { ...invoice, type: InvoiceType.STS }
  }

  public findAll(filters: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> {
    const {
      dateFrom,
      dateTo
    } = getDateRangeByYearAndMonth(filters.year, filters.month);

    return this.http.sendAndReceive({
      method: 'get',
      url: this.url +
        '?fromDate=' + dateFrom +
        '&toDate=' + dateTo +
        (filters.stsStatus !== undefined ? '&status=' + filters.stsStatus : '')
    })
      .then(res => res.json())
      .then((data: PaginationModel<InvoiceModel>) => StsInvoiceService.setSTSTypeToInvoices(data));
  }

  public async findById(id: string): Promise<{ invoice: InvoiceModel, files: File[] }> {
    let invoice = await this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + id
    })
      .then(res => res.json())
      .then((data: InvoiceModel) => data);

    invoice = CommonInvoiceService.setGoodServiceKey(invoice);
    invoice = StsInvoiceService.setSTSTypeToInvoice(invoice)

    let files: File[] = []

    if (invoice.documents !== undefined && invoice.documents !== null) {
      files = await super.getFiles(invoice.documents);
    }

    return {
      invoice,
      files
    };
  }

  public send(id: string, sendEmail: boolean): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + id + '/send?email=' + sendEmail,
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public cancelSending(id: string, sendEmail: boolean): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + id + '/cancel-sending?email=' + sendEmail,
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public communicateChange(id: string, sendEmail: boolean): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + id + '/communicate-change?email=' + sendEmail,
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public sendEmail(id: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + id + '/send-email',
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public getStsReports(year: string): Promise<StsInvoiceReportModel> {
    return this.http.sendAndReceive(({
      method: 'get',
      url: this.url + '/reports?year=' + year
    }))
      .then((res) => res.json())
      .then((data: StsInvoiceReportModel) => data);
  }

}
