import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { ContactFilters } from '../../components/contactFilters/contactFilters';
import { ContactList } from '../../components/contactList/contactList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { ContactType } from '../../models/contactModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSupplierList, setSupplierListPage } from '../../redux/slices/supplierListSlice';
import uri from '../../routers/quickfiscoUri.json';
import lang from './supplierList.json';

export function SuppliersList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const supplierListState = useAppSelector(state => state.supplierList)
  
  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (supplierListState.page === undefined) {
      dispatch(setSupplierListPage(0))
    }
  }, [])

  useEffect(() => {
    dispatch(getSupplierList())
  }, [supplierListState.page, supplierListState.filterCompanyName])

  useEffect(() => {
    if (supplierListState.page !== undefined && supplierListState.page > 0 && supplierListState.suppliers.content.length === 0) {
      dispatch(setSupplierListPage(supplierListState.page - 1))
    }
  }, [supplierListState.suppliers.content])

  const suppliers = supplierListState.suppliers

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='supplier-list-menu'
            menuItem={MenuItems.CONTACTS_SUPPLIER}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-11'}>
              <ContactFilters type={ContactType.SUPPLIER} />
            </div>
            <div className={'col-1 d-flex justify-content-end'}>
              <QuickfiscoIcon
                name={'new.svg'}
                className={'w-f-35'}
                onClick={() => navigate(uri.SupplierSave)}
                isButton={true}
              />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <ContactList type={ContactType.SUPPLIER} />
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <div className={'d-flex justify-content-center'}>
                <QuickfiscoPagination
                  totalPages={suppliers.totalPages}
                  number={suppliers.number}
                  empty={suppliers.empty}
                  doPagination={(page: number) => dispatch(setSupplierListPage(page))}
                  first={suppliers.first}
                  last={suppliers.last}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
