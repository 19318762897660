import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AtecoModel } from '../../models/atecoModel';
import { AtecoService } from '../../services/atecoService';
import { PromiseStatuses } from '../../types/strings';

export interface AtecoState {
  status: PromiseStatuses;
  atecos: AtecoModel[];
}

const initialState: AtecoState = {
  status: 'idle',
  atecos: [],
};

export const getAtecos = createAsyncThunk(
  'ateco/findAll',
  async (): Promise<AtecoModel[]> => {
    return new AtecoService().findAll();
  }
);

const AtecoSlice = createSlice({
  name: 'ateco',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAtecos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAtecos.fulfilled, (state, action) => {
        state.status = 'successfully';
        state.atecos = action.payload;
      })
      .addCase(getAtecos.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default AtecoSlice.reducer; 
