import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { OnboardingApiError } from '../../components/onboardingApiError/onboardingApiError';
import { UserService } from '../../services/userService';
import { OnboardingPopoverHelp } from '../../components/onboardingPopoverHelp/onboardingPopoverHelp';
import { PromiseStatuses } from '../../types/strings';
import uri from '../../routers/quickfiscoPaymentUri.json';
import lang from './emailVerificationOnboarding.json';
import './emailVerificationOnboarding.css';

export function EmailVerificationOnboarding() {
  const [statusVerificationCode, setStatusVerificationCode] =
    useState<PromiseStatuses>('idle');
  const [pin, setPin] = useState<string[]>(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const isButtonDisabled = !pin.every(
    (value) => value !== undefined && value !== null && value.trim() !== ''
  );
  const [countdown, setCountdown] = useState<number | null>(null);
  const [linkClicked, setLinkClicked] = useState<boolean>(false);

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user.user);
  const status = useAppSelector((state) => state.user.status);

  const userService = new UserService();

  useEffect(() => {
    if (user.registrationSteps?.email_verification === true) {
     navigate(uri.PersonalInfo)
    }
  }, [user]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (linkClicked && countdown !== null) {
      if (countdown > 0) {
        timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      } else {
        setLinkClicked(false);
        setCountdown(null);
      }
    }
    return () => clearTimeout(timer);
  }, [countdown, linkClicked]);

  const handleChange = (value: string, index: number) => {
    if (/^\d$/.test(value) || value === '') {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Backspace' && pin[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setStatusVerificationCode('loading');

    userService
      .emailVerificationOnboarding(pin.join(''))
      .then(() => {
        setStatusVerificationCode('successfully');
        navigate(uri.PersonalInfo);
      })
      .catch((err) => {
        console.error(err);
        setStatusVerificationCode('failed');
      });
  };

  const resendEmail = () => {
    setPin(['', '', '', '', '', '']);
    setLinkClicked(true);
    setCountdown(60);

    userService
      .reSendEmailVerificationOnboarding()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return <OnboardingApiError statusFailed={status === 'failed'} />;
  }

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters ps-4">
        <div className="col-12 p-4">
          <div className="row d-flex align-items-center mt-4 mb-3">
            <div className="col-2">
              <QuickfiscoIcon name={'logo-onboarding.svg'} className="ms-4" />
            </div>
            <div className="col-8 text-center">
              <div className="email-verification-onboarding-main-title">
                <span>{lang.mainTitle}</span>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className={
                'col-6 offset-3 email-verification-onboarding-container box-shadow p-5'
              }
            >
              <div className="row">
                <OnboardingPopoverHelp />
                <div className={'col-12'}>
                  <div className="row text-center">
                    <div className="col-12">
                      <h2 className="email-verification-onboarding-title">
                        {lang.title_1}
                        <br />
                        {lang.title_2}
                      </h2>
                      <p className="email-verification-onboarding-text mt-3">
                        {lang.sentText} {user.email}
                      </p>
                      <form onSubmit={handleSubmit} className="mt-4">
                        <div className="d-flex justify-content-center gap-2 mt-5">
                          {pin.map((digit, index) => (
                            <React.Fragment key={index}>
                              <input
                                key={index}
                                className="email-verification-onboarding-input"
                                type="text"
                                value={digit}
                                maxLength={1}
                                onChange={(e) =>
                                  handleChange(e.target.value, index)
                                }
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                              />
                              {index === 2 && (
                                <span className="email-verification-onboarding-dash">
                                  —
                                </span>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        {statusVerificationCode === 'failed' && (
                          <p className="email-verification-onboarding-code-error mt-3">
                            {lang.errorCode}
                          </p>
                        )}
                        <div className="row text-center mt-3">
                          <div className="col-6 offset-3">
                            {statusVerificationCode === 'loading' ? (
                              <QuickfiscoSpinner alignCenter={true} />
                            ) : (
                              <button
                                type="submit"
                                disabled={isButtonDisabled}
                                className={
                                  isButtonDisabled
                                    ? 'quickfisco-button quickfisco-button-disabled'
                                    : 'quickfisco-button quickfisco-button-secondary'
                                }
                              >
                                {lang.confirmButton}
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                      <div className="email-verification-onboarding-resend-email mt-3">
                        {lang.resendText1}
                        <br />
                        {lang.resendText2}{' '}
                        {linkClicked ? (
                          <b>riprova tra {countdown} secondi</b>
                        ) : (
                          <span onClick={() => resendEmail()}>
                            {lang.resendText3}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
