import { SingleValue } from "react-select";
import { OptionModel } from "../../models/optionModel";
import { QuickfiscoSelect } from "../quickfiscoSelect/quickfiscoSelect";
import { CountryType } from "../../models/countryModel";

interface Props {
    id: string;
    value?: CountryType | string;
    label?: string;
    error?: boolean;
    errorLabel?: string;
    onChange?: ((foreign?: CountryType | string) => void);
    required?: boolean;
    isProfile?: boolean;
}

export function CountryTypeSelect(props: Props) {
    const { id } = props;
    const { value } = props;
    const { label } = props;
    const { error } = props;
    const { errorLabel } = props;
    const { onChange } = props;
    const { required } = props;
    const { isProfile } = props;

    return (
        <QuickfiscoSelect
            id={id}
            value={getOptions(value).filter(option => option.selected === true)[0]}
            error={error}
            errorLabel={errorLabel}
            label={label ? label : 'Nazione'}
            styleType={'default'}
            requiredField={required}
            options={getOptions(value)}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e && e.value) {
                    // @ts-ignore
                    onChange && onChange(isProfile ? e.label : CountryType[e.value]);
                }
            }}
        />
    );
}

function getOptions(value?: string): OptionModel[] {
    return [
        { value: undefined, label: 'Seleziona Nazione', selected: value === undefined },
        { value: CountryType.AF, label: 'Afghanistan', selected: value === CountryType.AF || value === 'Afghanistan' },	
        { value: CountryType.AL, label: 'Albania', selected: value === CountryType.AL || value === 'Albania' },	
        { value: CountryType.DZ, label: 'Algeria', selected: value === CountryType.DZ || value === 'Algeria' },	
        { value: CountryType.AD, label: 'Andorra', selected: value === CountryType.AD || value === 'Andorra' },		
        { value: CountryType.AO, label: 'Angola', selected: value === CountryType.AO || value === 'Angola' },	
        { value: CountryType.AI, label: 'Anguilla', selected: value === CountryType.AI || value === 'Anguilla' },	
        { value: CountryType.AQ, label: 'Antartide', selected: value === CountryType.AQ || value === 'Antartide' },	
        { value: CountryType.AG, label: 'Antigua e Barbuda', selected: value === CountryType.AG || value === 'Antigua e Barbuda' },	
        { value: CountryType.SA, label: 'Arabia Saudita', selected: value === CountryType.SA || value === 'Arabia Saudita' },	
        { value: CountryType.AR, label: 'Argentina', selected: value === CountryType.AR || value === 'Argentina' },	
        { value: CountryType.AM, label: 'Armenia', selected: value === CountryType.AM || value === 'Armenia' },	
        { value: CountryType.AW, label: 'Aruba', selected: value === CountryType.AW || value === 'Aruba' },	
        { value: CountryType.AU, label: 'Australia', selected: value === CountryType.AU || value === 'Australia' },	
        { value: CountryType.AT, label: 'Austria', selected: value === CountryType.AT || value === 'Austria' },	
        { value: CountryType.AZ, label: 'Azerbaijan', selected: value === CountryType.AZ || value === 'Azerbaijan' },	
        { value: CountryType.BS, label: 'Bahamas', selected: value === CountryType.BS || value === 'Bahamas' },	
        { value: CountryType.BH, label: 'Bahrain', selected: value === CountryType.BH || value === 'Bahrain' },	
        { value: CountryType.BD, label: 'Bangladesh', selected: value === CountryType.BD || value === 'Bangladesh' },	
        { value: CountryType.BB, label: 'Barbados', selected: value === CountryType.BB || value === 'Barbados' },	
        { value: CountryType.BE, label: 'Belgio', selected: value === CountryType.BE || value === 'Belgio' },	
        { value: CountryType.BZ, label: 'Belize', selected: value === CountryType.BZ || value === 'Belize' },	
        { value: CountryType.BJ, label: 'Benin', selected: value === CountryType.BJ || value === 'Benin' },	
        { value: CountryType.BM, label: 'Bermuda', selected: value === CountryType.BM || value === 'Bermuda' },	
        { value: CountryType.BT, label: 'Bhutan', selected: value === CountryType.BT || value === 'Bhutan' },	
        { value: CountryType.BY, label: 'Bielorussia', selected: value === CountryType.BY || value === 'Bielorussia' },	
        { value: CountryType.BO, label: 'Bolivia', selected: value === CountryType.BO || value === 'Bolivia' },	
        { value: CountryType.BA, label: 'Bosnia Erzegovina', selected: value === CountryType.BA || value === 'Bosnia Erzegovina' },	
        { value: CountryType.BW, label: 'Botswana', selected: value === CountryType.BW || value === 'Botswana' },	
        { value: CountryType.BR, label: 'Brasile', selected: value === CountryType.BR || value === 'Brasile' },	
        { value: CountryType.BN, label: 'Brunei Darussalam', selected: value === CountryType.BN || value === 'Brunei Darussalam' },	
        { value: CountryType.BG, label: 'Bulgaria', selected: value === CountryType.BG || value === 'Bulgaria' },	
        { value: CountryType.BF, label: 'Burkina Faso', selected: value === CountryType.BF || value === 'Burkina Faso' },	
        { value: CountryType.BI, label: 'Burundi', selected: value === CountryType.BI || value === 'Burundi' },	
        { value: CountryType.KH, label: 'Cambogia', selected: value === CountryType.KH || value === 'Cambogia' },	
        { value: CountryType.CM, label: 'Camerun', selected: value === CountryType.CM || value === 'Camerun' },	
        { value: CountryType.CA, label: 'Canada', selected: value === CountryType.CA || value === 'Canada' },	
        { value: CountryType.CV, label: 'Capo Verde', selected: value === CountryType.CV || value === 'Capo Verde' },	
        { value: CountryType.TD, label: 'Ciad', selected: value === CountryType.TD || value === 'Ciad' },	
        { value: CountryType.CL, label: 'Cile', selected: value === CountryType.CL || value === 'Cile' },	
        { value: CountryType.CN, label: 'Cina', selected: value === CountryType.CN || value === 'Cina' },	
        { value: CountryType.CY, label: 'Cipro', selected: value === CountryType.CY || value === 'Cipro' },	
        { value: CountryType.VA, label: 'Citta del Vaticano', selected: value === CountryType.VA || value === 'Citta del Vaticano' },	
        { value: CountryType.CO, label: 'Colombia', selected: value === CountryType.CO || value === 'Colombia' },	
        { value: CountryType.KM, label: 'Comore', selected: value === CountryType.KM || value === 'Comore' },	
        { value: CountryType.KP, label: 'Corea del Nord', selected: value === CountryType.KP || value === 'Corea del Nord' },	
        { value: CountryType.KR, label: 'Corea del Sud', selected: value === CountryType.KR || value === 'Corea del Sud' },	
        { value: CountryType.CI, label: "Costa d'Avorio", selected: value === CountryType.CI || value === "Costa d'Avorio" },	
        { value: CountryType.CR, label: 'Costa Rica', selected: value === CountryType.CR || value === 'Costa Rica' },	
        { value: CountryType.HR, label: 'Croazia', selected: value === CountryType.HR || value === 'Croazia' },	
        { value: CountryType.CU, label: 'Cuba', selected: value === CountryType.CU || value === 'Cuba' },	
        { value: CountryType.CW, label: 'Curaçao', selected: value === CountryType.CW || value === 'Curaçao' },	
        { value: CountryType.DK, label: 'Danimarca', selected: value === CountryType.DK || value === 'Danimarca' },	
        { value: CountryType.DM, label: 'Dominica', selected: value === CountryType.DM || value === 'Dominica' },	
        { value: CountryType.EC, label: 'Ecuador', selected: value === CountryType.EC || value === 'Ecuador' },	
        { value: CountryType.EG, label: 'Egitto', selected: value === CountryType.EG || value === 'Egitto' },	
        { value: CountryType.IE, label: 'Eire', selected: value === CountryType.IE || value === 'Eire' },	
        { value: CountryType.SV, label: 'El Salvador', selected: value === CountryType.SV || value === 'El Salvador' },	
        { value: CountryType.AE, label: 'Emirati Arabi Uniti', selected: value === CountryType.AE || value === 'Emirati Arabi Uniti' },	
        { value: CountryType.ER, label: 'Eritrea', selected: value === CountryType.ER || value === 'Eritrea' },	
        { value: CountryType.EE, label: 'Estonia', selected: value === CountryType.EE || value === 'Estonia' },	
        { value: CountryType.ET, label: 'Etiopia', selected: value === CountryType.ET || value === 'Etiopia' },	
        { value: CountryType.RU, label: 'Federazione Russa', selected: value === CountryType.RU || value === 'Federazione Russa' },	
        { value: CountryType.FJ, label: 'Fiji', selected: value === CountryType.FJ || value === 'Fiji' },	
        { value: CountryType.PH, label: 'Filippine', selected: value === CountryType.PH || value === 'Filippine' },	
        { value: CountryType.FI, label: 'Finlandia', selected: value === CountryType.FI || value === 'Finlandia' },	
        { value: CountryType.FR, label: 'Francia', selected: value === CountryType.FR || value === 'Francia' },	
        { value: CountryType.GA, label: 'Gabon', selected: value === CountryType.GA || value === 'Gabon' },	
        { value: CountryType.GM, label: 'Gambia', selected: value === CountryType.GM || value === 'Gambia' },	
        { value: CountryType.GE, label: 'Georgia', selected: value === CountryType.GE || value === 'Georgia' },	
        { value: CountryType.DE, label: 'Germania', selected: value === CountryType.DE || value === 'Germania' },	
        { value: CountryType.GH, label: 'Ghana', selected: value === CountryType.GH || value === 'Ghana' },	
        { value: CountryType.JM, label: 'Giamaica', selected: value === CountryType.JM || value === 'Giamaica' },	
        { value: CountryType.JP, label: 'Giappone', selected: value === CountryType.JP || value === 'Giappone' },	
        { value: CountryType.GI, label: 'Gibilterra', selected: value === CountryType.GI || value === 'Gibilterra' },	
        { value: CountryType.DJ, label: 'Gibuti', selected: value === CountryType.DJ || value === 'Gibuti' },	
        { value: CountryType.JO, label: 'Giordania', selected: value === CountryType.JO || value === 'Giordania' },	
        { value: CountryType.GR, label: 'Grecia', selected: value === CountryType.GR || value === 'Grecia' },	
        { value: CountryType.GD, label: 'Grenada', selected: value === CountryType.GD || value === 'Grenada' },	
        { value: CountryType.GL, label: 'Groenlandia', selected: value === CountryType.GL || value === 'Groenlandia' },	
        { value: CountryType.GP, label: 'Guadalupa', selected: value === CountryType.GP || value === 'Guadalupa' },	
        { value: CountryType.GU, label: 'Guam', selected: value === CountryType.GU || value === 'Guam' },	
        { value: CountryType.GT, label: 'Guatemala', selected: value === CountryType.GT || value === 'Guatemala' },	
        { value: CountryType.GG, label: 'Guernsey', selected: value === CountryType.GG || value === 'Guernsey' },	
        { value: CountryType.GQ, label: 'Guinea Equatoriale', selected: value === CountryType.GQ || value === 'Guinea Equatoriale' },	
        { value: CountryType.GN, label: 'Guinea', selected: value === CountryType.GN || value === 'Guinea' },	
        { value: CountryType.GW, label: 'Guinea-Bissau', selected: value === CountryType.GW || value === 'Guinea-Bissau' },	
        { value: CountryType.GF, label: 'Guyana Francese', selected: value === CountryType.GF || value === 'Guyana Francese' },	
        { value: CountryType.GY, label: 'Guyana', selected: value === CountryType.GY || value === 'Guyana' },	
        { value: CountryType.HT, label: 'Haiti', selected: value === CountryType.HT || value === 'Haiti' },	
        { value: CountryType.HN, label: 'Honduras', selected: value === CountryType.HN || value === 'Honduras' },	
        { value: CountryType.HK, label: 'Hong Kong', selected: value === CountryType.HK || value === 'Hong Kong' },	
        { value: CountryType.IN, label: 'India', selected: value === CountryType.IN || value === 'India' },	
        { value: CountryType.ID, label: 'Indonesia', selected: value === CountryType.ID || value === 'Indonesia' },	
        { value: CountryType.IR, label: 'Iran', selected: value === CountryType.IR || value === 'Iran' },	
        { value: CountryType.IQ, label: 'Iraq', selected: value === CountryType.IQ || value === 'Iraq' },	
        { value: CountryType.IS, label: 'Islanda', selected: value === CountryType.IS || value === 'Islanda' },	
        { value: CountryType.BV, label: 'Isola di Bouvet', selected: value === CountryType.BV || value === 'Isola di Bouvet' },	
        { value: CountryType.JE, label: 'Isola di Jersey', selected: value === CountryType.JE || value === 'Isola di Jersey' },	
        { value: CountryType.IM, label: 'Isola di Man', selected: value === CountryType.IM || value === 'Isola di Man' },	
        { value: CountryType.CX, label: 'Isola di Natale', selected: value === CountryType.CX || value === 'Isola di Natale' },	
        { value: CountryType.HM, label: 'Isola Heard e Isole McDonald', selected: value === CountryType.HM || value === 'Isola Heard e Isole McDonald' },	
        { value: CountryType.NF, label: 'Isola Norfolk', selected: value === CountryType.NF || value === 'Isola Norfolk' },	
        { value: CountryType.AX, label: 'Isole Åland', selected: value === CountryType.AX || value === 'Isole Åland' },	
        { value: CountryType.KY, label: 'Isole Cayman', selected: value === CountryType.KY || value === 'Isole Cayman' },	
        { value: CountryType.CC, label: 'Isole Cocos', selected: value === CountryType.CC || value === 'Isole Cocos' },	
        { value: CountryType.CK, label: 'Isole Cook', selected: value === CountryType.CK || value === 'Isole Cook' },	
        { value: CountryType.FK, label: 'Isole Falkland', selected: value === CountryType.FK || value === 'Isole Falkland' },	
        { value: CountryType.FO, label: 'Isole Faroe', selected: value === CountryType.FO || value === 'Isole Faroe' },	
        { value: CountryType.MP, label: 'Isole Marianne Settentrionali', selected: value === CountryType.MP || value === 'Isole Marianne Settentrionali' },	
        { value: CountryType.MH, label: 'Isole Marshall', selected: value === CountryType.MH || value === 'Isole Marshall' },	
        { value: CountryType.UM, label: "Isole Minori degli Stati Uniti d'America", selected: value === CountryType.UM || value === "Isole Minori degli Stati Uniti d'America" },	
        { value: CountryType.SB, label: 'Isole Solomon', selected: value === CountryType.SB || value === 'Isole Solomon' },	
        { value: CountryType.TC, label: 'Isole Turks e Caicos', selected: value === CountryType.TC || value === 'Isole Turks e Caicos' },	
        { value: CountryType.VI, label: 'Isole Vergini Americane', selected: value === CountryType.VI || value === 'Isole Vergini Americane' },	
        { value: CountryType.VG, label: 'Isole Vergini Britanniche', selected: value === CountryType.VG || value === 'Isole Vergini Britanniche' },	
        { value: CountryType.IL, label: 'Israele', selected: value === CountryType.IL || value === 'Israele' },	
        { value: CountryType.IT, label: 'Italia', selected: value === CountryType.IT || value === 'Italia' },	
        { value: CountryType.KZ, label: 'Kazakhistan', selected: value === CountryType.KZ || value === 'Kazakhistan' },	
        { value: CountryType.KE, label: 'Kenya', selected: value === CountryType.KE || value === 'Kenya' },	
        { value: CountryType.KG, label: 'Kirghizistan', selected: value === CountryType.KG || value === 'Kirghizistan' },	
        { value: CountryType.KI, label: 'Kiribati', selected: value === CountryType.KI || value === 'Kiribati' },	
        { value: CountryType.KW, label: 'Kuwait', selected: value === CountryType.KW || value === 'Kuwait' },	
        { value: CountryType.LA, label: 'Laos', selected: value === CountryType.LA || value === 'Laos' },	
        { value: CountryType.LS, label: 'Lesotho', selected: value === CountryType.LS || value === 'Lesotho' },	
        { value: CountryType.LV, label: 'Lettonia', selected: value === CountryType.LV || value === 'Lettonia' },	
        { value: CountryType.LB, label: 'Libano', selected: value === CountryType.LB || value === 'Libano' },	
        { value: CountryType.LR, label: 'Liberia', selected: value === CountryType.LR || value === 'Liberia' },	
        { value: CountryType.LY, label: 'Libia', selected: value === CountryType.LY || value === 'Libia' },	
        { value: CountryType.LI, label: 'Liechtenstein', selected: value === CountryType.LI || value === 'Liechtenstein' },	
        { value: CountryType.LT, label: 'Lituania', selected: value === CountryType.LT || value === 'Lituania' },	
        { value: CountryType.LU, label: 'Lussemburgo', selected: value === CountryType.LU || value === 'Lussemburgo' },	
        { value: CountryType.MO, label: 'Macao', selected: value === CountryType.MO || value === 'Macao' },	
        { value: CountryType.MK, label: 'Macedonia', selected: value === CountryType.MK || value === 'Macedonia' },	
        { value: CountryType.MG, label: 'Madagascar', selected: value === CountryType.MG || value === 'Madagascar' },	
        { value: CountryType.MW, label: 'Malawi', selected: value === CountryType.MW || value === 'Malawi' },	
        { value: CountryType.MV, label: 'Maldive', selected: value === CountryType.MV || value === 'Maldive' },	
        { value: CountryType.MY, label: 'Malesia', selected: value === CountryType.MY || value === 'Malesia' },	
        { value: CountryType.ML, label: 'Mali', selected: value === CountryType.ML || value === 'Mali' },	
        { value: CountryType.MT, label: 'Malta', selected: value === CountryType.MT || value === 'Malta' },	
        { value: CountryType.MA, label: 'Marocco', selected: value === CountryType.MA || value === 'Marocco' },	
        { value: CountryType.MQ, label: 'Martinica', selected: value === CountryType.MQ || value === 'Martinica' },	
        { value: CountryType.MR, label: 'Mauritania', selected: value === CountryType.MR || value === 'Mauritania' },	
        { value: CountryType.MU, label: 'Maurizio', selected: value === CountryType.MU || value === 'Maurizio' },	
        { value: CountryType.YT, label: 'Mayotte', selected: value === CountryType.YT || value === 'Mayotte' },	
        { value: CountryType.MX, label: 'Messico', selected: value === CountryType.MX || value === 'Messico' },	
        { value: CountryType.MD, label: 'Moldavia', selected: value === CountryType.MD || value === 'Moldavia' },	
        { value: CountryType.MC, label: 'Monaco', selected: value === CountryType.MC || value === 'Monaco' },	
        { value: CountryType.MN, label: 'Mongolia', selected: value === CountryType.MN || value === 'Mongolia' },	
        { value: CountryType.ME, label: 'Montenegro', selected: value === CountryType.ME || value === 'Montenegro' },	
        { value: CountryType.MS, label: 'Montserrat', selected: value === CountryType.MS || value === 'Montserrat' },	
        { value: CountryType.MZ, label: 'Mozambico', selected: value === CountryType.MZ || value === 'Mozambico' },	
        { value: CountryType.MM, label: 'Myanmar', selected: value === CountryType.MM || value === 'Myanmar' },	
        { value: CountryType.NA, label: 'Namibia', selected: value === CountryType.NA || value === 'Namibia' },	
        { value: CountryType.NR, label: 'Nauru', selected: value === CountryType.NR || value === 'Nauru' },	
        { value: CountryType.NP, label: 'Nepal', selected: value === CountryType.NP || value === 'Nepal' },	
        { value: CountryType.NI, label: 'Nicaragua', selected: value === CountryType.NI || value === 'Nicaragua' },	
        { value: CountryType.NE, label: 'Niger', selected: value === CountryType.NE || value === 'Niger' },	
        { value: CountryType.NG, label: 'Nigeria', selected: value === CountryType.NG || value === 'Nigeria' },	
        { value: CountryType.NU, label: 'Niue', selected: value === CountryType.NU || value === 'Niue' },	
        { value: CountryType.NO, label: 'Norvegia', selected: value === CountryType.NO || value === 'Norvegia' },	
        { value: CountryType.NC, label: 'Nuova Caledonia', selected: value === CountryType.NC || value === 'Nuova Caledonia' },	
        { value: CountryType.NZ, label: 'Nuova Zelanda', selected: value === CountryType.NZ || value === 'Nuova Zelanda' },	
        { value: CountryType.OM, label: 'Oman', selected: value === CountryType.OM || value === 'Oman' },	
        { value: CountryType.BQ, label: 'Paesi Bassi caraibici', selected: value === CountryType.BQ || value === 'Paesi Bassi caraibici' },	
        { value: CountryType.NL, label: 'Paesi Bassi', selected: value === CountryType.NL || value === 'Paesi Bassi' },	
        { value: CountryType.PK, label: 'Pakistan', selected: value === CountryType.PK || value === 'Pakistan' },	
        { value: CountryType.PW, label: 'Palau', selected: value === CountryType.PW || value === 'Palau' },	
        { value: CountryType.PA, label: 'Panama', selected: value === CountryType.PA || value === 'Panama' },	
        { value: CountryType.PG, label: 'Papua Nuova Guinea', selected: value === CountryType.PG || value === 'Papua Nuova Guinea' },	
        { value: CountryType.PY, label: 'Paraguay', selected: value === CountryType.PY || value === 'Paraguay' },	
        { value: CountryType.PE, label: 'Perù', selected: value === CountryType.PE || value === 'Perù' },	
        { value: CountryType.PN, label: 'Pitcairn', selected: value === CountryType.PN || value === 'Pitcairn' },	
        { value: CountryType.PF, label: 'Polinesia Francese', selected: value === CountryType.PF || value === 'Polinesia Francese' },	
        { value: CountryType.PL, label: 'Polonia', selected: value === CountryType.PL || value === 'Polonia' },	
        { value: CountryType.PR, label: 'Porto Rico', selected: value === CountryType.PR || value === 'Porto Rico' },	
        { value: CountryType.PT, label: 'Portogallo', selected: value === CountryType.PT || value === 'Portogallo' },	
        { value: CountryType.QA, label: 'Qatar', selected: value === CountryType.QA || value === 'Qatar' },	
        { value: CountryType.GB, label: 'Regno Unito', selected: value === CountryType.GB || value === 'Regno Unito' },	
        { value: CountryType.CZ, label: 'Repubblica Ceca', selected: value === CountryType.CZ || value === 'Repubblica Ceca' },	
        { value: CountryType.CF, label: 'Repubblica Centroafricana', selected: value === CountryType.CF || value === 'Repubblica Centroafricana' },	
        { value: CountryType.CG, label: 'Repubblica del Congo', selected: value === CountryType.CG || value === 'Repubblica del Congo' },	
        { value: CountryType.CD, label: 'Repubblica Democratica del Congo', selected: value === CountryType.CD || value === 'Repubblica Democratica del Congo' },	
        { value: CountryType.DO, label: 'Repubblica Dominicana', selected: value === CountryType.DO || value === 'Repubblica Dominicana' },	
        { value: CountryType.RE, label: 'Reunion', selected: value === CountryType.RE || value === 'Reunion' },	
        { value: CountryType.RO, label: 'Romania', selected: value === CountryType.RO || value === 'Romania' },	
        { value: CountryType.RW, label: 'Ruanda', selected: value === CountryType.RW || value === 'Ruanda' },	
        { value: CountryType.EH, label: 'Sahara Occidentale', selected: value === CountryType.EH || value === 'Sahara Occidentale' },	
        { value: CountryType.KN, label: 'Saint Kitts e Nevis', selected: value === CountryType.KN || value === 'Saint Kitts e Nevis' },	
        { value: CountryType.PM, label: 'Saint Pierre e Miquelon', selected: value === CountryType.PM || value === 'Saint Pierre e Miquelon' },	
        { value: CountryType.VC, label: 'Saint Vincent e Grenadine', selected: value === CountryType.VC || value === 'Saint Vincent e Grenadine' },	
        { value: CountryType.BL, label: 'Saint-Barthélemy', selected: value === CountryType.BL || value === 'Saint-Barthélemy' },	
        { value: CountryType.MF, label: 'Saint-Martin', selected: value === CountryType.MF || value === 'Saint-Martin' },	
        { value: CountryType.AS, label: 'Samoa Americane', selected: value === CountryType.AS || value === 'Samoa Americane' },	
        { value: CountryType.WS, label: 'Samoa', selected: value === CountryType.WS || value === 'Samoa' },	
        { value: CountryType.SM, label: 'San Marino', selected: value === CountryType.SM || value === 'San Marino' },	
        { value: CountryType.LC, label: 'Santa Lucia', selected: value === CountryType.LC || value === 'Santa Lucia' },	
        { value: CountryType.SH, label: "Sant'Elena", selected: value === CountryType.SH || value === "Sant'Elena" },	
        { value: CountryType.ST, label: 'Sao Tome e Principe', selected: value === CountryType.ST || value === 'Sao Tome e Principe' },	
        { value: CountryType.SN, label: 'Senegal', selected: value === CountryType.SN || value === 'Senegal' },	
        { value: CountryType.RS, label: 'Serbia', selected: value === CountryType.RS || value === 'Serbia' },	
        { value: CountryType.SC, label: 'Seychelles', selected: value === CountryType.SC || value === 'Seychelles' },	
        { value: CountryType.SL, label: 'Sierra Leone', selected: value === CountryType.SL || value === 'Sierra Leone' },	
        { value: CountryType.SG, label: 'Singapore', selected: value === CountryType.SG || value === 'Singapore' },	
        { value: CountryType.SX, label: 'Sint Maarten', selected: value === CountryType.SX || value === 'Sint Maarten' },	
        { value: CountryType.SY, label: 'Siria', selected: value === CountryType.SY || value === 'Siria' },	
        { value: CountryType.SK, label: 'Slovacchia', selected: value === CountryType.SK || value === 'Slovacchia' },	
        { value: CountryType.SI, label: 'Slovenia', selected: value === CountryType.SI || value === 'Slovenia' },	
        { value: CountryType.SO, label: 'Somalia', selected: value === CountryType.SO || value === 'Somalia' },	
        { value: CountryType.ES, label: 'Spagna', selected: value === CountryType.ES || value === 'Spagna' },	
        { value: CountryType.LK, label: 'Sri Lanka', selected: value === CountryType.LK || value === 'Sri Lanka' },	
        { value: CountryType.FM, label: 'Stati Federati della Micronesia', selected: value === CountryType.FM || value === 'Stati Federati della Micronesia' },	
        { value: CountryType.US, label: "Stati Uniti d'America", selected: value === CountryType.US || value === "Stati Uniti d'America" },	
        { value: CountryType.ZA, label: 'Sud Africa', selected: value === CountryType.ZA || value === 'Sud Africa' },	
        { value: CountryType.GS, label: 'Sud Georgia e Isole Sandwich', selected: value === CountryType.GS || value === 'Sud Georgia e Isole Sandwich' },	
        { value: CountryType.SS, label: 'Sudan del Sud', selected: value === CountryType.SS || value === 'Sudan del Sud' },	
        { value: CountryType.SD, label: 'Sudan', selected: value === CountryType.SD || value === 'Sudan' },	
        { value: CountryType.SR, label: 'Suriname', selected: value === CountryType.SR || value === 'Suriname' },	
        { value: CountryType.SJ, label: 'Svalbard e Jan Mayen', selected: value === CountryType.SJ || value === 'Svalbard e Jan Mayen' },	
        { value: CountryType.SE, label: 'Svezia', selected: value === CountryType.SE || value === 'Svezia' },	
        { value: CountryType.CH, label: 'Svizzera', selected: value === CountryType.CH || value === 'Svizzera' },	
        { value: CountryType.SZ, label: 'Swaziland', selected: value === CountryType.SZ || value === 'Swaziland' },	
        { value: CountryType.TJ, label: 'Tagikistan', selected: value === CountryType.TJ || value === 'Tagikistan' },	
        { value: CountryType.TH, label: 'Tailandia', selected: value === CountryType.TH || value === 'Tailandia' },	
        { value: CountryType.TW, label: 'Taiwan', selected: value === CountryType.TW || value === 'Taiwan' },	
        { value: CountryType.TZ, label: 'Tanzania', selected: value === CountryType.TZ || value === 'Tanzania' },	
        { value: CountryType.IO, label: "Territori Britannici dell'Oceano Indiano", selected: value === CountryType.IO || value === "Territori Britannici dell'Oceano Indiano" },	
        { value: CountryType.TF, label: 'Territori Francesi del Sud', selected: value === CountryType.TF || value === 'Territori Francesi del Sud' },	
        { value: CountryType.PS, label: 'Territori Palestinesi Occupati', selected: value === CountryType.PS || value === 'Territori Palestinesi Occupati' },	
        { value: CountryType.TL, label: 'Timor Est', selected: value === CountryType.TL || value === 'Timor Est' },	
        { value: CountryType.TG, label: 'Togo', selected: value === CountryType.TG || value === 'Togo' },	
        { value: CountryType.TK, label: 'Tokelau', selected: value === CountryType.TK || value === 'Tokelau' },	
        { value: CountryType.TO, label: 'Tonga', selected: value === CountryType.TO || value === 'Tonga' },	
        { value: CountryType.TT, label: 'Trinidad e Tobago', selected: value === CountryType.TT || value === 'Trinidad e Tobago' },	
        { value: CountryType.TN, label: 'Tunisia', selected: value === CountryType.TN || value === 'Tunisia' },	
        { value: CountryType.TR, label: 'Turchia', selected: value === CountryType.TR || value === 'Turchia' },	
        { value: CountryType.TM, label: 'Turkmenistan', selected: value === CountryType.TM || value === 'Turkmenistan' },	
        { value: CountryType.TV, label: 'Tuvalu', selected: value === CountryType.TV || value === 'Tuvalu' },	
        { value: CountryType.UA, label: 'Ucraina', selected: value === CountryType.UA || value === 'Ucraina' },	
        { value: CountryType.UG, label: 'Uganda', selected: value === CountryType.UG || value === 'Uganda' },	
        { value: CountryType.HU, label: 'Ungheria', selected: value === CountryType.HU || value === 'Ungheria' },	
        { value: CountryType.UY, label: 'Uruguay', selected: value === CountryType.UY || value === 'Uruguay' },	
        { value: CountryType.UZ, label: 'Uzbekistan', selected: value === CountryType.UZ || value === 'Uzbekistan' },	
        { value: CountryType.VU, label: 'Vanuatu', selected: value === CountryType.VU || value === 'Vanuatu' },	
        { value: CountryType.VE, label: 'Venezuela', selected: value === CountryType.VE || value === 'Venezuela' },	
        { value: CountryType.VN, label: 'Vietnam', selected: value === CountryType.VN || value === 'Vietnam' },	
        { value: CountryType.WF, label: 'Wallis e Futuna', selected: value === CountryType.WF || value === 'Wallis e Futuna' },	
        { value: CountryType.YE, label: 'Yemen', selected: value === CountryType.YE || value === 'Yemen' },	
        { value: CountryType.ZM, label: 'Zambia', selected: value === CountryType.ZM || value === 'Zambia' },	
        { value: CountryType.ZW, label: 'Zimbabwe', selected: value === CountryType.ZW || value === 'Zimbabwe' },	
    ];
  }
