import { OnChangeArea } from '../../types/functions';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoTextarea.css';

interface Props {
  id: string;
  label: string;
  onChange?: OnChangeArea;
  placeholder?: string;
  value?: string;
  secondaryLabel?: string;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: boolean;
  errorLabel?: string;
}

export function QuickfiscoTextarea(props: Props) {
  const { id } = props;
  const { label } = props;
  const { onChange } = props;
  const { placeholder } = props;
  const { value } = props;
  const { secondaryLabel } = props;
  const { disabled } = props;
  const { maxLength } = props;
  const { required } = props;
  const { error } = props;
  const { errorLabel } = props;

  return (
    <div>
      <div className={'d-flex align-items-end w-100'}>
        <label className="quickfisco-textarea-label">
          {label}
          {secondaryLabel && (
            <span className={'quickfisco-textarea-secondary-label'}>
              {secondaryLabel}
            </span>
          )}
          {label !== undefined && required && ' *'}
        </label>
        {disabled && (
          <div className="ms-2">
            <QuickfiscoIcon name={'block.svg'} />
          </div>
        )}
      </div>
      <div>
        <textarea
          id={id}
          placeholder={placeholder}
          className={'quickfisco-textarea-box mt-2'}
          value={value}
          onChange={(e) => onChange && onChange(e)}
          disabled={disabled}
          maxLength={maxLength}
        />
        {error && (
          <div className="quickfisco-select-error mt-2">{errorLabel}</div>
        )}
      </div>
    </div>
  );
}
