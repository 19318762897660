import { ContactModel, ContactType } from '../../models/contactModel';
import { PaginationModel } from '../../models/paginationModel';
import { useAppSelector } from '../../redux/hooks';
import { PromiseStatuses } from '../../types/strings';
import { ContactListRow } from '../contactListRow/contactListRow';
import { EmptyList } from '../emptyList/emptyList';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './contactList.css';
import lang from './contactList.json';

interface Props {
  type: ContactType
}

export function ContactList(props: Props) {
  const { type } = props;

  const customerListState = useAppSelector(state => state.customerList)
  const supplierListState = useAppSelector(state => state.supplierList)

  let status: PromiseStatuses
  let contacts: PaginationModel<ContactModel>
  if (type === ContactType.CUSTOMER) {
    status = customerListState.status
    contacts = customerListState.customers
  } else {
    status = supplierListState.status
    contacts = supplierListState.suppliers
  }

  if (status === 'loading') {
    return (
      <div className={'contactList-empty'}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="contactList-empty">
        <div className={'p-blue'}>Errore durante il caricamento dei contatti.</div>
      </div>
    );
  }

  if (contacts.content.length <= 0) {
    return (
      <div className={'contactList-empty'}>
        <EmptyList type={type} />
      </div>
    );
  }

  const rows = contacts.content.map(contact => {
    return (
      <ContactListRow
        key={contact.id}
        contact={contact}
      />
    )
  });

  return (
    <div className={'contactList-container'}>
      <div className={'row no-gutters mb-3'}>
        <div className={'col contactList-heading'}>
          {type === ContactType.CUSTOMER ? lang.line1 : lang.SupplierLabel}
        </div>
        <div className={'col contactList-heading'}>{lang.line2}</div>
        <div className={'col contactList-heading'}>{lang.line3}</div>
        <div className={'col contactList-heading'}>{lang.line4}</div>
        <div className={'col contactList-heading'}>{lang.line5}</div>
        <div className={'col contactList-heading'}>{lang.line6}</div>
        <div className={'col-auto'}>
          <div style={{ width: '25px' }} />
        </div>
      </div>
      {rows}
    </div>
  );
}
