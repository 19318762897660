import { useEffect, useState } from 'react';
import { UserModel } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMe } from '../../redux/slices/userSlice';
import { ObjectService } from '../../services/objectService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import {
  base64EmptyImage,
  renderBase64Img,
  toBase64,
  validateFile,
} from '../../utils/file';
import { QuickfiscoInputLogo } from '../quickfiscoInputLogo/quickfiscoInputLogo';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { host } from '../../utils/config';
import './profileLogo.css';
import lang from './profileLogo.json';

export function ProfileLogo() {
  const [error, setError] = useState(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const [logoBase64, setLogoBase64] = useState<string>(base64EmptyImage);

  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getLogo(setLogoBase64, user.profilePickId);
  }, [user]);

  if (status === 'loading') {
    return (
      <div className="profileLogo-box box-shadow w-100 d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="profileLogo-box box-shadow">
      <QuickfiscoSuccess
        message={lang.success}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <span className="profile-logo-title">{lang.Title}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <p className="profile-logo-subtitle">{lang.SubTitle}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-center">
              <div
                className="profile-logo-img"
                style={{
                  backgroundImage: user.profilePickId
                    ? renderBase64Img(logoBase64)
                    : 'url("' + host + '/images/uploadLogo.svg")',
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="mt-3">
                <QuickfiscoInputLogo
                  error={error}
                  errorLabel={lang.error}
                  onChange={(logo) =>
                    saveLogo(
                      logo,
                      setStatus,
                      setLogoBase64,
                      setError,
                      dispatch,
                      user
                    )
                  }
                  deleteLogo={() =>
                    deleteLogo(
                      setStatus,
                      setError,
                      dispatch,
                      setLogoBase64,
                      user
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getLogo(
  setLogoBase64: SetState<string>,
  profilePickId?: string
): void {
  if (!profilePickId) {
    return;
  }

  const userService = new UserService();

  userService
    .getLogo(profilePickId)
    .then((logo) => {
      toBase64(logo)
        .then((res) => setLogoBase64(res))
        .catch((err) => console.error(err));
    })
    .catch((err) => console.error(err));
}

function saveLogo(
  logo: File | null,
  setStatus: SetState<PromiseStatuses>,
  setLogoBase64: SetState<string>,
  setError: SetState<boolean>,
  dispatch: Function,
  user?: UserModel
): void {
  setError(false);

  if (logo === null || !user) {
    return;
  }

  if (!validateFile(logo)) {
    setError(true);
    return;
  }

  const userService = new UserService();

  setStatus('loading');
  userService
    .saveLogo(logo, user.profilePickId)
    .then((data) => {
      const newUser = {
        ...user,
        profilePickId: data,
      };
      userService
        .updateMe(newUser)
        .then(() => {
          dispatch(getMe());
          setStatus('successfully');
          toBase64(logo)
            .then((res) => setLogoBase64(res))
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          console.error(err);
          setStatus('failed');
        });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}

function deleteLogo(
  setStatus: SetState<PromiseStatuses>,
  setError: SetState<boolean>,
  dispatch: Function,
  setLogoBase64: SetState<string>,
  user?: UserModel
): void {
  setError(false);

  if (!user || !user.profilePickId) {
    return;
  }

  const userService = new UserService();
  const objectService = new ObjectService();

  setStatus('loading');
  objectService
    .del(user.profilePickId)
    .then(() => {
      const newUser = {
        ...user,
        profilePickId: undefined,
      };
      userService
        .updateMe(newUser)
        .then(() => {
          dispatch(getMe());
          setLogoBase64(base64EmptyImage);
          setStatus('successfully');
        })
        .catch((err: any) => {
          console.error(err);
          setStatus('failed');
        });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
