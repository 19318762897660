import config from '../../../config.json';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './noVatAdvise.css';

export function NoVatAdvise() {
  return (
    <div className={'no-vat-advise-container'}>
      <div>
        <div className="d-flex align-items-center mb-2">
          <QuickfiscoIcon name={'bolt-icon.svg'} className="me-1" />
          <QuickfiscoIcon name={'smile-small.svg'} className="me-1" />
          <span>
            Ti interessa l'attivazione del servizio Quickfisco o vuoi saperne di
            più?
          </span>
          <QuickfiscoIcon name={'hand-down-icon.svg'} className="ms-1" />
        </div>
        <div className="d-flex align-items-center">
          <a href={config.calendly} target={'_blank'} rel={'noreferrer'}>
            <b>Prenota una consulenza GRATIS</b>
          </a>
          <div>&nbsp;</div>
          è senza impegno
          <QuickfiscoIcon name={'hand-icon.svg'} className="ms-1" />
        </div>
      </div>
    </div>
  );
}
