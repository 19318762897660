import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  FreeServiceCreditTaxReturnModel,
  SectionTaxReturn11,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoPaymentsNumberSelect } from '../quickfiscoPaymentsNumberSelect/quickfiscoPaymentsNumberSelect';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { ibanRegex } from '../../regex/regex';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './taxReturnSection11.json';
import './taxReturnSection11.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn11) => void;
  onClickPrevStep: (data: SectionTaxReturn11) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnSection11(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;

  const userState = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [splitPaymentsQuestion, setSplitPaymentsQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [splitPaymentsAnswer, setSplitPaymentsAnswer] = useState<
    number | undefined
  >(undefined);

  const [useFreeServiceCreditQuestion, setUseFreeServiceCreditQuestion] =
    useState<boolean | undefined>(undefined);
  const [useFreeServiceCreditAnswer, setUseFreeServiceCreditAnswer] =
    useState<FreeServiceCreditTaxReturnModel>({
      iban: '',
      serviceAccepted: false,
    });

  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);
  const [ibanError, setIbanError] = useState<boolean>(false);

  const getSurveyStep = (): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(11)
      .then((res) => {
        setLoadingStatus('successfully');
        refreshApiCall(0, true);
        const data = res as SectionTaxReturn11;
        setSplitPaymentsQuestion(data.splitPaymentsQuestion);
        setSplitPaymentsAnswer(data.splitPaymentsAnswer);
        setUseFreeServiceCreditQuestion(data.useFreeServiceCreditQuestion);
        setUseFreeServiceCreditAnswer({
          iban: data.useFreeServiceCreditAnswer?.iban,
          serviceAccepted: data.useFreeServiceCreditAnswer?.serviceAccepted,
        });
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep();
  }, []);

  useEffect(() => {
    if (splitPaymentsQuestion === false) {
      setSplitPaymentsAnswer(undefined);
    }
  }, [splitPaymentsQuestion]);

  useEffect(() => {
    if (useFreeServiceCreditQuestion === false) {
      setUseFreeServiceCreditAnswer({
        iban: '',
        serviceAccepted: false,
      });
      setIbanError(false);
    }
  }, [useFreeServiceCreditQuestion]);

  useEffect(() => {
    setCheckgoNext(taxReturnService.validateSection11(dataToSend, ibanError));
  }, [
    splitPaymentsQuestion,
    splitPaymentsAnswer,
    useFreeServiceCreditQuestion,
    useFreeServiceCreditAnswer,
    ibanError,
  ]);

  useEffect(() => {
    useFreeServiceCreditAnswer.iban &&
      useFreeServiceCreditAnswer.iban.length > 0 &&
      setIbanError(!ibanRegex.test(useFreeServiceCreditAnswer.iban));
  }, [useFreeServiceCreditAnswer.iban]);

  const dataToSend: SectionTaxReturn11 = {
    splitPaymentsQuestion,
    splitPaymentsAnswer:
      splitPaymentsQuestion === true ? splitPaymentsAnswer : undefined,
    useFreeServiceCreditQuestion,
    useFreeServiceCreditAnswer:
      useFreeServiceCreditQuestion === true
        ? useFreeServiceCreditAnswer
        : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-11-title">{lang.title}</div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-11-split-payments-question"
                  title={lang.splitPaymentsQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={splitPaymentsQuestion}
                  onChange={(checked) => setSplitPaymentsQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {splitPaymentsQuestion === true && (
              <div className={'row no-gutters mt-3'}>
                <div className="col-6">
                  <QuickfiscoPaymentsNumberSelect
                    id={'tax-return-section-11-split-payments-answer'}
                    value={splitPaymentsAnswer}
                    onChange={(value) => setSplitPaymentsAnswer(value)}
                    requiredField={true}
                    disabled={splitPaymentsQuestion !== true}
                  />
                </div>
              </div>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <div
                  className={'tax-return-section-11-container-text py-3 px-5'}
                >
                  <div className="tax-return-section-11-container-title mb-2">
                    ATTENZIONE!
                  </div>
                  <ul>
                    <li className="tax-return-section-11-container-text">
                      Una volta confermata la scelta{' '}
                      <b>NON sarà possibile modificarla.</b>
                    </li>
                    <li className="tax-return-section-11-container-description">
                      Qualora non siate sicuri di poter affrontare agevolmente i
                      pagamenti in un’unica soluzione vi invitiamo a rateizzare.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 my-3">
                <hr />
              </div>
              <div className="col-12 tax-return-section-11-container-description">
                <p>
                  {lang.useFreeServiceCreditQuestionText.text1}
                  <b>{lang.useFreeServiceCreditQuestionText.textBold2}</b>
                </p>
                <p>
                  <b>{lang.useFreeServiceCreditQuestionText.textBold3}</b>
                  {lang.useFreeServiceCreditQuestionText.text4}
                  <b>{lang.useFreeServiceCreditQuestionText.textBold5}</b>
                  {lang.useFreeServiceCreditQuestionText.text6}
                </p>
              </div>
            </div>
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-11-free-service-credit-question"
                  title={lang.useFreeServiceCreditQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={useFreeServiceCreditQuestion}
                  onChange={(checked) =>
                    setUseFreeServiceCreditQuestion(checked)
                  }
                  required={true}
                />
              </div>
            </div>
            {useFreeServiceCreditQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-6">
                    <QuickfiscoInput
                      id="tax-return-section-11-use-free-service-credit-iban-answer"
                      type="text"
                      styleType={'default'}
                      label={lang.useFreeServiceCreditIbanAnswerLabel}
                      placeholder={
                        lang.useFreeServiceCreditIbanAnswerPlaceholder
                      }
                      defaultValue={useFreeServiceCreditAnswer.iban}
                      onChange={(e) =>
                        setUseFreeServiceCreditAnswer({
                          ...useFreeServiceCreditAnswer,
                          iban: e.target.value,
                        })
                      }
                      required={true}
                      error={ibanError}
                      errorLabel={lang.ibanError}
                    />
                  </div>
                </div>
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <p className="tax-return-section-11-container-title">
                      {lang.acceptTitle}
                    </p>
                    <div className="col-12 tax-return-section-11-container-text text-center py-3 px-5">
                      <p className="tax-return-section-11-container-credit-title">
                        {lang.creditTitle1}
                        <br />
                        {lang.creditTitle2}
                      </p>
                      <p className="tax-return-section-11-container-description">
                        {lang.userName}
                        {userState.name} {userState.surname}
                      </p>
                      <p className="tax-return-section-11-container-description my-4">
                        {lang.creditdescription1}
                        <b>{lang.creditdescription2bold}</b>
                        {lang.creditdescription3}
                        <b>{lang.creditdescription4bold}</b>
                        {lang.creditdescription5}
                      </p>
                      <p className="tax-return-section-11-container-description my-4">
                        {lang.creditdescription6}
                      </p>
                      <div
                        className={
                          'row no-gutters d-flex justify-content-center'
                        }
                      >
                        <div className="col-auto">
                          <QuickfiscoCheckbox
                            id={
                              'tax-return-section-11-use-free-service-credit-service-accepted-answer'
                            }
                            required={true}
                            label={
                              lang.useFreeServiceCreditServiceAcceptedAnswer
                            }
                            checked={
                              useFreeServiceCreditAnswer.serviceAccepted ||
                              false
                            }
                            onChange={(e) =>
                              setUseFreeServiceCreditAnswer({
                                ...useFreeServiceCreditAnswer,
                                serviceAccepted: e.target.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-11-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-11-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-11-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-11-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
