import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFinanceStep } from '../../redux/slices/financeSlice';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { LogoDim, QuickfiscoLogo } from '../quickfiscoLogo/quickfiscoLogo';
import './financeCheckSubscription.css';

export function FinanceCheckSubscription() {
  const userState = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  return (
    <div className={'financeCheckSubscription-container'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 16,
        }}
      >
        <QuickfiscoLogo id={''} dim={LogoDim.SMALL} />
        <span
          style={{
            textAlign: 'center',
            font: 'normal normal normal 14px/24px Poppins',
            color: '#1E1E23',
          }}
        >
          +
        </span>
        <QuickfiscoIcon name={'faicreditlogo.svg'} />
      </div>
      <div
        style={{
          textAlign: 'center',
          font: 'normal normal 600 28px/34px Poppins',
          color: '#4643D3',
        }}
      >
        Scopri come realizzare i tuoi progetti,
        <br />
        inizia{' '}
        <span
          style={{
            textAlign: 'center',
            font: 'normal normal 600 28px/34px Poppins',
            color: '#1E1E23',
          }}
        >
          simulando il tuo finanziamento
        </span>
      </div>
      <div
        style={{
          textAlign: 'center',
          font: 'normal normal 600 17px/30px Poppins',
          color: '#1E1E23',
        }}
      >
        Otterrai l’esito in massimo 3 giorni{' '}
        <QuickfiscoIcon name={'smile.svg'} />
      </div>
      <div className={'row'}>
        <div className={'col-10 offset-1'}>
          <div className="financeCheckSubscription-box-container">
            <div className="financeCheckSubscription-container--small">
              <span className="financeCheckSubscription-container-title">
                <span
                  style={{
                    color: '#FFB810',
                  }}
                >
                  NON SAI COME PAGARE LE TASSE
                </span>{' '}
                e non ci dormi la notte?
              </span>
              <QuickfiscoIcon name={'finance-card-1.svg'} />
              <span className="financeCheckSubscription-container-text">
                Bastano 3 giorni per avere una soluzione pratica.
              </span>
            </div>
            <div className="financeCheckSubscription-container--small-center">
              <span className="financeCheckSubscription-container-title-center">
                Hai bisogno di liquidità per investire nella tua attività?
              </span>
              <QuickfiscoIcon name={'finance-card-2.svg'} />
              <span className="financeCheckSubscription-container-text">
                Il tuo futuro non ha prezzo, pianifica senza paura.
              </span>
            </div>
            <div className="financeCheckSubscription-container--small">
              <span className="financeCheckSubscription-container-title">
                <span
                  style={{
                    color: '#FFB810',
                  }}
                >
                  SCADENZE FISCALI
                </span>{' '}
                o le{' '}
                <span
                  style={{
                    color: '#FFB810',
                  }}
                >
                  VACANZE ESTIVE?
                </span>
              </span>
              <QuickfiscoIcon name={'finance-card-3.svg'} />
              <span className="financeCheckSubscription-container-text">
                Non rinunciare a nulla, bastano 3 giorni per risolvere il
                dilemma.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 8,
          }}
        >
          <div
            style={{
              font: 'normal normal 600 20px/22px Poppins',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                color: '#4643D3',
              }}
            >
              La simulazione del finanziamento
            </span>{' '}
            NON È VINCOLANTE.
          </div>
          <div
            style={{
              font: 'normal normal normal 14px/20px Poppins',
              textAlign: 'center',
              width: '100%',
            }}
            className="mt-2"
          >
            Prova <b>senza impegno e senza vincoli</b> di sottoscrizione.
            <br />
            Simula un piano dettagliato con N° di rate e durata del
            finanziamento, sarai libero di decidere se proseguire con la
            richiesta.
          </div>
        </div>
        <div
          className="mt-3 w-75"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 14,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <QuickfiscoButton
            key={'finance-check-subscription-button'}
            id={'finance-check-subscription-button-1'}
            label={'PROVA LA SIMULAZIONE'}
            onClick={() => dispatch(setFinanceStep(1))}
            type={userState.user.status !== 4 ? 'disabled' : 'senary'}
          />
          {userState.user.status !== 4 && (
            <>
              <span className="financeCheckSubscription-info-text-container py-2 px-3">
                <QuickfiscoIcon
                  name={'alert-yellow-small.svg'}
                  className="me-3"
                />
                Funzionalità abilitata solo per i nostri <b>utenti abbonati</b>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
