import it from 'date-fns/locale/it';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OnBlur } from '../../types/functions';
import { formatDateIT, generateArrayOfYears, months } from '../../utils/date';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { OptionModel } from '../../models/optionModel';
import { SingleValue } from 'react-select';
import './quickfiscoDatePicker.css';

interface Props {
  id: string;
  value?: Date;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  futureDates?: boolean;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
  errorLabel?: string;
  styleType: 'default' | 'large-center';
  onBlur?: OnBlur;
  onChange?: (date?: Date) => void;
}

export function QuickfiscoDatePicker(props: Props) {
  const { id } = props;
  const { value } = props;
  const { label } = props;
  const { maxDate } = props;
  const { minDate } = props;
  const { futureDates } = props;
  const { disabled } = props;
  const { required } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { styleType } = props;
  const { onBlur } = props;
  const { onChange } = props;

  const styleTypeMap = new Map<string, string>([
    ['default', 'quickfisco-date-picker-input-default'],
    ['large-center', 'quickfisco-date-picker-input-large-center'],
  ]);

  return (
    <div className="mb-3">
      {label && (
        <label className={'quickfisco-date-picker-label'}>
          {label}
          {label !== undefined && required && ' *'}
          {disabled && <QuickfiscoIcon name={'block.svg'} className={'ps-2'} />}
        </label>
      )}
      <div className="d-flex align-items-end">
        <div className={'d-flex align-items-end w-100'}>
          <ReactDatePicker
            id={id}
            value={value && formatDateIT(value)}
            locale={it}
            maxDate={maxDate}
            minDate={minDate}
            disabled={disabled}
            className={styleTypeMap.get(styleType)}
            onBlur={(e) => onBlur && onBlur(e)}
            selected={value}
            onChange={(e) => e && onChange && onChange(e)}
            placeholderText={'gg/mm/aaaa'}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="row no-gutters">
                <div className="col-1 d-flex justify-content-center align-items-center px-0">
                  {!prevMonthButtonDisabled && (
                    <QuickfiscoIcon
                      isButton={true}
                      onClick={decreaseMonth}
                      name={'sx.svg'}
                    />
                  )}
                </div>
                <div className="col-10 d-flex justify-content-center align-items-center px-0">
                  <QuickfiscoSelect
                    id={`${id}-year`}
                    styleType={'no-borders'}
                    options={getYearOptions(date.getFullYear(), futureDates)}
                    onChange={(e: SingleValue<OptionModel>) => {
                      if (e !== null) {
                        changeYear(Number(e.value));
                      }
                    }}
                    value={
                      getYearOptions(date.getFullYear(), futureDates).filter(
                        (option) => option.selected === true
                      )[0]
                    }
                  />
                  <QuickfiscoSelect
                    id={`${id}-month`}
                    styleType={'no-borders'}
                    options={getMonthOptions(months[date.getMonth()])}
                    onChange={(e: SingleValue<OptionModel>) => {
                      if (e !== null) {
                        changeMonth(months.indexOf(e.label));
                      }
                    }}
                    value={
                      getMonthOptions(months[date.getMonth()]).filter(
                        (option) => option.selected === true
                      )[0]
                    }
                  />
                </div>
                <div className="col-1 d-flex justify-content-center align-items-center px-0">
                  {!nextMonthButtonDisabled && (
                    <QuickfiscoIcon
                      isButton={true}
                      onClick={increaseMonth}
                      name={'dx.svg'}
                    />
                  )}
                </div>
              </div>
            )}
          />
        </div>
      </div>
      {error && (
        <div className="quickfisco-date-picker-error mt-2">{errorLabel}</div>
      )}
    </div>
  );
}

function getMonthOptions(value: string): OptionModel[] {
  return months.map((el: string) => ({
    value: el,
    label: el,
    selected: value === el,
  }));
}

function getYearOptions(value: number, futureDates?: boolean): OptionModel[] {
  let years = generateArrayOfYears(futureDates);
  return years.map((el: number) => ({
    value: el.toString(),
    label: el.toString(),
    selected: value === el,
  }));
}
