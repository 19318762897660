import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { useNavigate } from 'react-router-dom';
import uri from '../../routers/quickfiscoUri.json';
import lang from './subscriptionExpired.json';
import './subscriptionExpired.css';

interface Props {
  open: boolean;
  setOpen: Function;
}

export function SubscriptionExpired(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const navigate = useNavigate();

  return (
    <QuickfiscoModal hide={() => setOpen(false)} isOpen={open}>
      <div className={'row'}>
        <div className={'col-12 text-center'}>
          <div className={'row'}>
            <div className={'col-12 trial-expired-title'}>{lang.title}</div>
          </div>
          <div className={'row my-3'}>
            <div className={'col-12 trial-expired-sub-title'}>
              {lang.subTitle}
            </div>
          </div>
          <div className={'row d-flex justify-content-center'}>
            <div className={'col-10 trial-expired-text'}>{lang.text}</div>
          </div>
          <div
            className={
              'row d-flex justify-content-center align-items-center my-5'
            }
          >
            <div className={'col-4'}>
              <QuickfiscoButton
                id={'trial-expired-button'}
                label={lang.closeButton}
                type={'primary'}
                onClick={() => setOpen(false)}
              />
            </div>
            <div className={'col-5'}>
              <QuickfiscoButton
                id={'trial-expired-button'}
                label={lang.renewButton}
                type={'secondary'}
                onClick={() => {
                  setOpen(false);
                  navigate(uri.SubscriptionAndSettings);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}
