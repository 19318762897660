import { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { MessagesStatusFiltersType } from '../../models/messagesFilters';
import { setMessagesListStatusFilter } from '../../redux/slices/messagesListSlice';
import { MessagesReportModel } from '../../models/messagesReportModel';
import { BoxColor, MessagesReport } from '../messagesReport/messagesReport';

interface Props {
  readOnly?: boolean;
}

interface Attribute {
  title: string;
  amount?: number;
  color: BoxColor;
  statusFilter?: MessagesStatusFiltersType;
  setStatusFilter?: (status?: MessagesStatusFiltersType) => void;
  selected: boolean;
}

export function MessagesReportList(props: Props) {
  const { readOnly } = props;

  const dispatch = useAppDispatch();

  const messagesListState = useAppSelector((state) => state.messagesList);

  let status: PromiseStatuses;
  let attributes: Attribute[];
  let reports: MessagesReportModel;

  status = messagesListState.reportsStatus;
  reports = messagesListState.reports;
  attributes = [
    {
      title: 'Tutti i messaggi',
      amount: reports.allMessages,
      color: BoxColor.BLACK,
      statusFilter: undefined,
      setStatusFilter: () => dispatch(setMessagesListStatusFilter(undefined)),
      selected: false,
    },
    {
      title: 'Importanti',
      amount: reports.importantMessages,
      color: BoxColor.BLUE,
      statusFilter: MessagesStatusFiltersType.IMPORTANT,
      setStatusFilter: () =>
        dispatch(
          setMessagesListStatusFilter(MessagesStatusFiltersType.IMPORTANT)
        ),
      selected:
        messagesListState.statusFilter === MessagesStatusFiltersType.IMPORTANT,
    },
    {
      title: 'Da leggere',
      amount: reports.allUnreadMessages,
      color: BoxColor.RED,
      statusFilter: MessagesStatusFiltersType.UNREAD,
      setStatusFilter: () =>
        dispatch(setMessagesListStatusFilter(MessagesStatusFiltersType.UNREAD)),
      selected:
        messagesListState.statusFilter === MessagesStatusFiltersType.UNREAD,
    },
  ];

  if (status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed' || !reports) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className={'p-blue my-2'}>
          Errore durante il caricamento dei reports.
        </div>
      </div>
    );
  }

  let reportsList: ReactElement[] = [];

  attributes.forEach((value) => {
    reportsList.push(
      <div className={'col'} key={'report-' + JSON.stringify(value)}>
        <div>
          <MessagesReport
            title={value.title}
            amount={value.amount}
            color={value.color}
            setStatusFilter={readOnly ? undefined : value.setStatusFilter}
            selected={value.selected}
          />
        </div>
      </div>
    );
  });

  return <div className={'row'}>{reportsList}</div>;
}
