import { useState } from 'react';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { ObjectFileTaxReturnModel } from '../../models/taxReturnModel';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './quickfiscoFileList.json';
import './quickfiscoFileList.css';

interface Props {
  status: PromiseStatuses;
  title: string;
  fileList?: ObjectFileTaxReturnModel[];
  error?: PromiseStatuses;
  addDoc: (doc: File | null) => void;
  downloadDoc: (docId: string) => void;
  deleteDoc: (docId: string, index: number) => void;
}

export function QuickfiscoFileList(props: Props) {
  const { status } = props;
  const { title } = props;
  const { fileList } = props;
  const { error } = props;
  const { addDoc } = props;
  const { downloadDoc } = props;
  const { deleteDoc } = props;

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  if (status === 'loading') {
    return (
      <div className="row mt-3 quickfisco-file-list-container">
        <div className="col-12 text-center">
          <QuickfiscoSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={'row ms-0 me-0 mt-3 quickfisco-file-list-container mb-5'}>
      <div className={'col-12'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'row'}>
              <div className={'col-auto d-flex align-items-center'}>
                <div className="quickfisco-file-list-title">
                  {title}{' '}
                  {typeof fileList?.length === 'number' &&
                    fileList?.length > 0 &&
                    `(${fileList.length})`}
                </div>
              </div>
              <div className="col-auto">
                <div
                  className="d-flex align-items-center"
                  onClick={() => inputRef?.click()}
                >
                  <QuickfiscoIcon
                    name={'new-small.svg'}
                    className="cursor-pointer"
                  />
                </div>
                <input
                  type="file"
                  accept={'.pdf, .jpg, .jpeg, .png'}
                  className={'d-none'}
                  ref={(ref) => setInputRef(ref)}
                  onChange={(e) =>
                    addDoc(e.target.files && e.target.files.item(0))
                  }
                />
              </div>
              <div className={'col-12'}>
                <div className="quickfisco-file-list-subtitle">
                  {lang.fileInfo}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`row my-2 ${
            fileList && fileList.length > 2
              ? 'quickfisco-file-list-file-list-container'
              : ''
          }`}
        >
          {!fileList || (fileList && fileList.length === 0) ? (
            <div className="d-flex align-items-center justify-content-center my-5 quickfisco-file-list-no-file">
              {lang.noFile}
            </div>
          ) : (
            fileList &&
            fileList.map((file, index) => {
              return (
                <div className={'col-6 mt-3'} key={file.objectId}>
                  <div className="quickfisco-file-list-file-box">
                    <div className={'row'} key={file.objectId}>
                      <div
                        className={
                          'col-8 d-flex align-items-center justify-content-start quickfisco-file-list-file-name'
                        }
                      >
                        {file.name}
                      </div>
                      <div
                        className={
                          'col-2 d-flex align-items-center justify-content-center'
                        }
                      >
                        <QuickfiscoIcon
                          name={'download-negative.svg'}
                          className={'cursor-pointer'}
                          onClick={() =>
                            file.objectId && downloadDoc(file.objectId)
                          }
                        />
                      </div>
                      <div
                        className={
                          'col-1 d-flex align-items-center justify-content-center'
                        }
                      >
                        <QuickfiscoIcon
                          name={'delete-negative.svg'}
                          className={'cursor-pointer'}
                          onClick={() =>
                            file.objectId && deleteDoc(file.objectId, index)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {error === 'failed' && (
          <div className={'quickfisco-file-list-error mt-2'}>
            {lang.savingError}
          </div>
        )}
        {error === 'failedUploadApi' && (
          <div className={'quickfisco-file-list-error mt-2'}>
            {lang.savingUploadApiError}
          </div>
        )}
        {error === 'failedDeleteApi' && (
          <div className={'quickfisco-file-list-error mt-2'}>
            {lang.savingDeleteApiError}
          </div>
        )}
      </div>
    </div>
  );
}
