import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";
import lang from './taxProfileEmailConfirmation.json'
import './taxProfileEmailConfirmation.css'

export function TaxProfileEmailConfirmation() {
    return (
        <div className="tax-profile-email-confirmation-container">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <QuickfiscoIcon name="alert-success.svg" />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 d-flex justify-content-center">
                    <span className="tax-profile-email-confirmation-title">
                        {lang.title}
                    </span>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-12 d-flex justify-content-center">
                    <span className="tax-profile-email-confirmation-message">
                        {lang.message}
                    </span>
                </div>
            </div>
        </div>
    )
}