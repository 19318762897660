import { TaxDeadlineModel } from "../../models/taxDeadlineModel";
import { CategoryType } from "../../models/userModel";
import { useAppSelector } from "../../redux/hooks";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { TaxDeadlineArtisanAndTrader } from "../taxDeadlineArtisanAndTrader/taxDeadlineArtisanAndTrader";
import { TaxDeadlineEngineer } from "../taxDeadlineEngineer/taxDeadlineEngineer";
import { TaxDeadlineINPS } from "../taxDeadlineINPS/taxDeadlineINPS";
import { TaxDeadlineLawyer } from "../taxDeadlineLawyer/taxDeadlineLawyer";
import { TaxDeadlinePsychologists } from "../taxDeadlinePsychologists/taxDeadlinePsychologists";

interface Props {
    index: number
    lastItem: boolean
    deadline: TaxDeadlineModel
}

export function TaxDeadlineFactory(props: Props) {
    const { index } = props;
    const { lastItem } = props;
    const { deadline } = props;

    const userState = useAppSelector(state => state.user);

    if (userState.status === 'loading') {
        return (
            <div className="full-screen d-flex justify-content-center align-items-center">
                <QuickfiscoSpinner />
            </div>
        );
    }

    if (userState.status === 'failed' || !userState.user) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                Errore durante il caricamento dei dati
            </div>
        );
    }

    const user = userState.user;

    if (user.category === CategoryType.NOT_FUND) {
        return (
            <TaxDeadlineINPS
                lastItem={lastItem}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.FUND && user.fund === "TC01") {
        return (
            <TaxDeadlineLawyer
                index={index}
                lastItem={lastItem}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.ARTISAN || user.category === CategoryType.TRADER) {
        return (
            <TaxDeadlineArtisanAndTrader
                index={index}
                lastItem={lastItem}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.FUND && user.fund === "TC21") {
        return (
            <TaxDeadlinePsychologists
                index={index}
                lastItem={lastItem}
                deadline={deadline}
            />
        )
    } else if (user.category === CategoryType.FUND && user.fund === "TC04") {
        return (
            <TaxDeadlineEngineer
                index={index}
                lastItem={lastItem}
                deadline={deadline}
            />
        )
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            Categoria non trovata
        </div>
    );
}
