export interface TaxDeadlineModel {
  id: string,
  expirationDate: Date,
  status: TaxDeadlineStatus,
  totalAmount: number,
  taxAmount: number,
  taxBalance: number,
  taxDeposit: number,
  contributionAmount: number,
  contributionBalance: number,
  contributionDeposit: number,
  minimalContribution: number,
  variablesContributionAmount: number,
  variablesContributionBalance: number,
  variablesContributionDeposit: number
}

export enum TaxDeadlineStatus {
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID'
}
