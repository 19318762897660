import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { MessagesReportList } from '../../components/messagesReportList/messagesReportList';
import {
  getMessagesList,
  getMessagesReports,
  setMessagesListPage,
} from '../../redux/slices/messagesListSlice';
import { MessagesList } from '../../components/messagesList/messagesList';
import usePrevious from '../../hooks/usePrevious';
import lang from './messages.json';
import './message.css';

export function Messages() {
  const { collapsed } = useProSidebar();

  const dispatch = useAppDispatch();

  const messagesListState = useAppSelector((state) => state.messagesList);
  const prevPage = usePrevious(messagesListState.page);
  const prevStatusFilter = usePrevious(messagesListState.statusFilter);

  useEffect(() => {
    dispatch(getMessagesReports());
  }, []);

  useEffect(() => {
    if (messagesListState.page !== prevPage) {
      dispatch(
        getMessagesList({
          page: messagesListState.page,
          type: messagesListState.statusFilter,
        })
      );
    }
  }, [messagesListState.page]);

  useEffect(() => {
    if (messagesListState.statusFilter !== prevStatusFilter) {
      if (messagesListState.page > 1) {
        dispatch(setMessagesListPage(1));
      } else {
        dispatch(
          getMessagesList({
            page: 1,
            type: messagesListState.statusFilter,
          })
        );
      }
    }
  }, [messagesListState.statusFilter]);

  const messages = messagesListState.messages;

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu id="messages" menuItem={MenuItems.MESSAGES} />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row">
            <div className="col">
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className="row mt-4 message-report-list">
            <div className="col-12">
              <MessagesReportList />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <MessagesList />
            </div>
          </div>
          {messages.totalPages !== 0 && (
            <div className={'row mt-3'}>
              <div className={'col-12'}>
                <div className={'d-flex justify-content-center'}>
                  <QuickfiscoPagination
                    totalPages={messages.totalPages}
                    number={
                      messages.number ? messages.number - 1 : messages.number
                    }
                    empty={messages.empty}
                    doPagination={(page: number) =>
                      dispatch(setMessagesListPage(page + 1))
                    }
                    first={messages.first}
                    last={messages.last}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
