import { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { QuickfiscoHeader } from "../../components/quickfiscoHeader/quickfiscoHeader";
import {
  MenuItems,
  QuickfiscoMenu,
} from "../../components/quickfiscoMenu/quickfiscoMenu";
import { ViewportWarning } from "../../components/viewportWarning/viewportWarning";
import { ImportInvoiceService } from "../../services/importInvoiceService";
import { PromiseStatuses } from "../../types/strings";
import { PaginationModel } from "../../models/paginationModel";
import { QuickfiscoPagination } from "../../components/quickfiscoPagination/quickfiscoPagination";
import { InvoiceImportListComponent } from "../../components/invoiceImportListComponent/invoiceImportListComponent";
import { InvoiceModel } from "../../models/invoiceModel";
import { CommonInvoiceService } from "../../services/commonInvoiceService";
import { useNavigate } from "react-router-dom";
import { QuickfiscoButton } from "../../components/quickfiscoButton/quickfiscoButton";
import { InvoiceConfirmImportModal } from "../../components/invoiceConfirmImportModal/invoiceConfirmImportModal";
import { InvoiceImportModel } from "../../models/invoiceImportModel";
import uri from "../../routers/quickfiscoUri.json";
import config from "../../../config.json";
import lang from "./invoiceImportStsList.json";
import "./invoiceImportStsList.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setImportInvoicesStsReset } from "../../redux/slices/userSlice";
import { QuickfiscoIcon } from "../../components/quickfiscoIcon/quickfiscoIcon";
import { QuickfiscoModal } from "../../components/quickfiscoModal/quickfiscoModal";

export function InvoiceImportStsList() {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<PromiseStatuses>("idle");
  const [statusImport, setStatusImport] = useState<PromiseStatuses>("idle");
  const [paginationInvoices, setPaginationInvoices] = useState<
    PaginationModel<InvoiceModel>
  >({
    content: [],
  });
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const userState = useAppSelector((state) => state.user);

  const { collapsed } = useProSidebar();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getPaginationStsInvoices = () => {
    const service = new ImportInvoiceService();

    setStatus("loading");
    service
      .findAllSts()
      .then((data) => {
        setPaginationInvoices(
          CommonInvoiceService.paginate(page, config.pageSize, data)
        );
        setStatus("successfully");
      })
      .catch((err) => {
        setStatus("failed");
        console.error(err);
      });
  };

  useEffect(() => {
    setOpenInfoModal(true);
  }, []);

  const InfoModal = () => {
    return (
      <QuickfiscoModal
        isOpen={openInfoModal}
        hide={() => setOpenInfoModal(false)}
        modalClassName={"invoice-import-modal"}
        closeButton={true}
        backdrop="static"
        keyboard={false}
      >
        <div className="p-4">
          <div className="invoice-import-modal-title ">
            <QuickfiscoIcon name={"alert-yellow-full.svg"} className="ms-2" />
          </div>
          <div className="row mt-4 d-flex align-items-center">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="invoice-import-modal-title">
                    {lang.warn_box_1}
                  </div>
                </div>
                <div className="col-12">
                  <div className="invoice-import-modal-text my-2">
                    {lang.info_box_2}
                  </div>
                </div>
                <div className="col-12">
                  <div className="invoice-import-modal-subtext my-4">
                    <strong>
                      {lang.info_box_blod_1}
                      <br></br>
                    </strong>{" "}
                    {lang.info_box_3} <strong>{lang.info_box_blod_2}</strong>{" "}
                    {lang.info_box_4}
                  </div>
                </div>
                <div className="col-6 offset-3 my-4">
                  <div className="invoice-import-modal-value">
                    <QuickfiscoButton
                      id={"invoice-import-modal-close"}
                      type={"secondary"}
                      label={"OK, HO CAPITO"}
                      onClick={() => setOpenInfoModal(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    );
  };

  const importStsInvoices = () => {
    const service = new ImportInvoiceService();

    setStatusImport("loading");
    service
      .importSts()
      .then(() => {
        setStatusImport("successfully");
        dispatch(setImportInvoicesStsReset());
        navigate(uri.CustomerInvoiceList);
      })
      .catch((err) => {
        setStatusImport("failed");
        console.error(err);
      });
  };

  const updatePaginationInvoices = (
    index: number,
    updates: InvoiceImportModel
  ) => {
    const invoicesArray = paginationInvoices.content.map((item, i) =>
      i === index ? { ...item, ...updates } : item
    );

    setPaginationInvoices({ ...paginationInvoices, content: invoicesArray });
  };

  useEffect(() => {
    getPaginationStsInvoices();
  }, [page]);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="customer-list-menu"
            menuItem={MenuItems.CUSTOMER_INVOICE}
          />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? "120px" : "280px",
            transition: "all .2s ease-in-out",
          }}
        >
          <div className={"row"}>
            <div className={"col"}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-10"}>
              <div className="invoice-import-sts-list-warn-box px-4 p-3">
                <b>{lang.warn_box_1}</b>
                <br />
                {lang.warn_box_2}
                <br />
                {lang.warn_box_3}
                <b>"{lang.warn_box_4}"</b>
                {lang.warn_box_5}
              </div>
            </div>
            {userState.user.fund !== "TC22" && (
              <div className={"col-10"}>
                <div className="invoice-import-sts-list-warn-box px-4 p-3 my-2">
                  {lang.warn_box_6} <strong>{lang.warn_box_6_bold}</strong>
                </div>
              </div>
            )}
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <InvoiceImportListComponent
                invoiceList={paginationInvoices.content}
                status={status}
                updatePaginationInvoices={updatePaginationInvoices}
                getPaginationInvoices={getPaginationStsInvoices}
                type="sts"
              />
            </div>
          </div>
          <div className={"row mt-3"}>
            <div className={"col-12"}>
              <div className={"d-flex justify-content-center"}>
                <QuickfiscoPagination
                  totalPages={paginationInvoices.totalPages}
                  number={paginationInvoices.number}
                  empty={paginationInvoices.empty}
                  doPagination={(page: number) => setPage(page)}
                  first={paginationInvoices.first}
                  last={paginationInvoices.last}
                />
              </div>
            </div>
          </div>
          <div className={"row mt-4 mb-5"}>
            <div className={"col-6 offset-3"}>
              <QuickfiscoButton
                id={"invoiceImportListRow-import-btn"}
                type={"secondary"}
                label={lang.importBtn}
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <InvoiceConfirmImportModal
        open={open}
        setOpen={(open: boolean) => setOpen(open)}
        status={statusImport}
        importInvoices={importStsInvoices}
        type="sts"
      />
      {userState.user.fund !== "TC22" && InfoModal()}
    </div>
  );
}
