import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContactModel, ContactType } from "../../models/contactModel";
import { CurrencyType, GoodServicesModel, InvoiceModel, InvoiceStatusType, PaymentModeType, PaymentTermsType, ReimbursementOfExpenseModel } from "../../models/invoiceModel";
import { ProformaInvoiceService } from "../../services/proformaInvoiceService";
import { PromiseStatuses } from "../../types/strings";
import { BankAccount } from "../../models/userModel";

interface ProformaInvoiceState {
    fileList: File[];
    findByIdStatus: PromiseStatuses
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[];
    invoice: InvoiceModel;
}

const initialState: ProformaInvoiceState = {
    fileList: [],
    findByIdStatus: 'idle',
    reimbursementsOfExpenses: [],
    invoice: {
        date: new Date(),
        status: InvoiceStatusType.DRAFT,
        currency: CurrencyType.EUR,
        stamp: false,
        amount: 0,
        taxBase: 0,
        goodServices: [],
        socialContribution: false,
        socialContributionAmount: 0,
        reimbursementsOfExpenses: [],
        reimbursementsOfExpensesAmount: 0,
        manual: false,
        isPaymentDue: false,
        trackedPayment: false,
        customerDataCommunication: undefined,
        payments: [],
        documents: [],
        additionalDatasPa: [],
        supplier: {
            bankAccount: {
                bankIban: "",
                bankName: ""
            },
            type: ContactType.CUSTOMER
        }
    },
}

export const findProformaInvoiceById = createAsyncThunk(
    'proformaInvoice/findProformaInvoiceById',
    async (invoiceId?: string): Promise<{ invoice: InvoiceModel, files: File[], reimbursementsOfExpenses: ReimbursementOfExpenseModel[] }> => {
        const service = new ProformaInvoiceService();

        return service.findById(invoiceId)
    }
)

const proformaInvoiceSlice = createSlice({
    name: "proformaInvoice",
    initialState,
    reducers: {
        setProformaInvoiceFileList: (state, action: PayloadAction<File[]>) => {
            state.fileList = action.payload
        },
        setProformaInvoiceReimbursementsOfExpenses: (state, action: PayloadAction<ReimbursementOfExpenseModel[]>) => {
            state.reimbursementsOfExpenses = action.payload
        },
        setProformaInvoice: (state, action: PayloadAction<InvoiceModel>) => {
            state.invoice = action.payload
        },
        setProformaInvoiceProforma: (state, action: PayloadAction<ContactModel | undefined>) => {
            state.invoice.customer = action.payload
        },
        setProformaInvoiceAteco: (state, action: PayloadAction<string | undefined>) => {
            state.invoice.ateco = action.payload
        },
        setProformaInvoiceSocialContribution: (state, action: PayloadAction<boolean>) => {
            state.invoice.socialContribution = action.payload
        },
        setProformaInvoiceFund: (state, action: PayloadAction<string>) => {
            state.invoice.fund = action.payload
        },
        setProformaInvoiceDate: (state, action: PayloadAction<Date>) => {
            state.invoice.date = action.payload
        },
        setProformaInvoicePaymentMode: (state, action: PayloadAction<PaymentModeType | undefined>) => {
            state.invoice.paymentMode = action.payload
        },
        setProformaInvoicePaymentExpiration: (state, action: PayloadAction<Date | undefined>) => {
            state.invoice.paymentExpiration = action.payload
        },
        setProformaInvoiceBankAccount: (state, action: PayloadAction<BankAccount>) => {
            if (state.invoice.supplier !== undefined) {
                state.invoice.supplier.bankAccount.bankName = action.payload.name;
                state.invoice.supplier.bankAccount.bankIban = action.payload.iban;
            }
        },
        setProformaInvoicePaymentTerms: (state, action: PayloadAction<PaymentTermsType | undefined>) => {
            state.invoice.paymentTerms = action.payload
        },
        setProformaInvoiceNotes: (state, action: PayloadAction<string | undefined>) => {
            state.invoice.notes = action.payload
        },
        setProformaInvoiceGoodServices: (state, action: PayloadAction<GoodServicesModel[]>) => {
            state.invoice.goodServices = action.payload
        },
        setProformaInvoiceReimbursementOfExpensesAmount: (state, action: PayloadAction<number>) => {
            state.invoice.reimbursementsOfExpensesAmount = action.payload
        },
        setProformaInvoiceStamp: (state, action: PayloadAction<boolean>) => {
            state.invoice.stamp = action.payload
        },
        setProformaInvoiceTaxBase: (state, action: PayloadAction<number>) => {
            state.invoice.taxBase = action.payload
        },
        setProformaInvoiceSocialContributionAmount: (state, action: PayloadAction<number>) => {
            state.invoice.socialContributionAmount = action.payload
        },
        setProformaInvoiceStatus: (state, action: PayloadAction<InvoiceStatusType>) => {
            state.invoice.status = action.payload
        },
        setProformaInvoiceTrackedPayment: (state, action: PayloadAction<boolean>) => {
            state.invoice.trackedPayment = action.payload
        },
        setProformaInvoiceCommunicationData: (state, action: PayloadAction<boolean>) => {
            state.invoice.customerDataCommunication = action.payload
        },
        setProformaInvoiceSocialEnasarco: (state, action: PayloadAction<number>) => {
            state.invoice.enasarcoSocialContribution = action.payload
        },
        setStsIsActive: (state, action: PayloadAction<boolean>) => {
            state.invoice.stsIsActive = action.payload
        },
        setProformaInvoicePdfMailLanguage: (state, action: PayloadAction<string | null>) => {
            state.invoice.language = action.payload
        },
        setProformaStampId: (state, action: PayloadAction<string | undefined>) => {
            state.invoice.stampId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findProformaInvoiceById.pending, (state) => {
                state.findByIdStatus = 'loading';
            })
            .addCase(findProformaInvoiceById.fulfilled, (state, action) => {
                state.findByIdStatus = 'successfully';
                state.invoice = action.payload.invoice;
                state.fileList = action.payload.files;
                state.reimbursementsOfExpenses = action.payload.reimbursementsOfExpenses
            })
            .addCase(findProformaInvoiceById.rejected, ((state) => {
                state.findByIdStatus = 'failed';
            }))
    }
})

export const {
    setProformaInvoiceFileList,
    setProformaInvoiceReimbursementsOfExpenses,
    setProformaInvoice,
    setProformaInvoiceBankAccount,
    setProformaInvoiceProforma,
    setProformaInvoiceAteco,
    setProformaInvoiceSocialContribution,
    setProformaInvoiceDate,
    setProformaInvoicePaymentMode,
    setProformaInvoicePaymentExpiration,
    setProformaInvoicePaymentTerms,
    setProformaInvoiceNotes,
    setProformaInvoiceGoodServices,
    setProformaInvoiceReimbursementOfExpensesAmount,
    setProformaInvoiceStamp,
    setProformaInvoiceTaxBase,
    setProformaInvoiceSocialContributionAmount,
    setProformaInvoiceStatus,
    setProformaInvoiceTrackedPayment,
    setProformaInvoiceCommunicationData,
    setProformaInvoiceSocialEnasarco,
    setProformaInvoiceFund,
    setStsIsActive,
    setProformaInvoicePdfMailLanguage,
    setProformaStampId
} = proformaInvoiceSlice.actions;

export default proformaInvoiceSlice.reducer