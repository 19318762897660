import config from '../../config.json';
import { ObjectModel } from '../models/objectModel';
import { removeDots } from './string';

export const base64EmptyImage = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export function downloadPdf(name: string, bytes: ArrayBuffer, type?: string): void {
  const link = document.createElement('a');
  const newNameFile = removeDots(name)

  link.download = newNameFile;

  link.href = URL.createObjectURL(new Blob([bytes], { type: type }));

  link.click();
}

export function toFile(arrayBuffer: ArrayBuffer, object: ObjectModel): File {
  if (object.name === undefined) {
    return new File(
      [new Uint8Array(arrayBuffer)],
      'unknown'
    );
  }

  return new File(
    [new Uint8Array(arrayBuffer)],
    object.name
  );
}

export async function toBase64(file: File): Promise<string> {
  const bytes = new Uint8Array(await file.arrayBuffer());

  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return btoa(binary);
}

export function getExtension(file: File): string {
  const nameSlitted = file.name.toLowerCase().split('.');
  return nameSlitted[nameSlitted.length - 1];
}

export function validateFile(file: File): boolean {
  if (file.size > config.maxFileSize) {
    return false;
  }

  return config.supportedFileTypes.includes(getExtension(file));
}

export function validateFiles(files: File[]): boolean {
  for (let file of files) {
    if (!validateFile(file)) {
      return false;
    }
  }

  return true;
}

export function renderBase64Img(img: string): string {
  return 'url(data:image/png;base64,' + img + ')';
}
