import { SingleValue } from 'react-select';
import { InvoiceType } from '../../models/invoiceModel';
import { OptionModel } from '../../models/optionModel';
import { InvoiceSign } from '../../types/strings';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import lang from './documentTypeSelect.json';

interface Props {
  id: string
  value?: string;
  error?: boolean;
  disabled?: boolean;
  invoiceSign?: InvoiceSign
  colorLabel?: string
  onChange?: ((type?: InvoiceType) => void);
}

export function DocumentTypeSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { error } = props;
  const { value } = props;
  const { disabled } = props;
  const { invoiceSign } = props;
  const { colorLabel } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.Label}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(InvoiceType[e.value])
        }
      }}
      error={error}
      errorLabel={lang.Error}
      options={invoiceSign === 'auto' ? getAutoInvoice(value) : getCustomerInvoice(value)}
      requiredField={true}
      disabled={disabled}
      styleType={'default'}
      value={invoiceSign === 'customer' ? getCustomerInvoice(value).filter(option => option.selected === true)[0] : getAutoInvoice(value).filter(option => option.selected === true)[0]}
      colorLabel={colorLabel}
    />
  );
}

function getCustomerInvoice(value?: string): OptionModel[] {
  return [
    {
      label: lang.td01,
      value: InvoiceType.TD01,
      selected: value === InvoiceType.TD01,
    },
    {
      label: lang.td02,
      value: InvoiceType.TD02,
      selected: value === InvoiceType.TD02,
    },
    {
      label: lang.td03,
      value: InvoiceType.TD03,
      selected: value === InvoiceType.TD03,
    },
    {
      label: lang.td06,
      value: InvoiceType.TD06,
      selected: value === InvoiceType.TD06,
    },
    {
      label: lang.td24,
      value: InvoiceType.TD24,
      selected: value === InvoiceType.TD24,
    },
    {
      label: lang.td25,
      value: InvoiceType.TD25,
      selected: value === InvoiceType.TD25,
    },
    {
      label: lang.td26,
      value: InvoiceType.TD26,
      selected: value === InvoiceType.TD26,
    }
  ];
}

function getAutoInvoice(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona tipo di documento',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: lang.td16,
      value: InvoiceType.TD16,
      selected: value === InvoiceType.TD16,
    },
    {
      label: lang.td17,
      value: InvoiceType.TD17,
      selected: value === InvoiceType.TD17,
    },
    {
      label: lang.td18,
      value: InvoiceType.TD18,
      selected: value === InvoiceType.TD18,
    },
    {
      label: lang.td19,
      value: InvoiceType.TD19,
      selected: value === InvoiceType.TD19,
    },
  ];
}
