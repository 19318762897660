import { useState } from 'react';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { TaxTimetable } from '../taxTimetable/taxTimetable';
import Dropdown from 'react-bootstrap/Dropdown';
import './dropdownFiscalEvent.css';

export function DropdownFiscalEvent() {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown drop={'down-centered'} onToggle={(isOpen) => setOpen(isOpen)}>
      <Dropdown.Toggle id="dropdown-fiscal-event">
        <QuickfiscoIcon
          name={open ? 'calendar-open-header.svg' : 'calendar-header.svg'}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className={'dropdown-fiscal-event-menu'}>
        <TaxTimetable />
      </Dropdown.Menu>
    </Dropdown>
  );
}
