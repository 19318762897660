import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { sendEmail } from '../../redux/slices/taxProfileSlice';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileEmailConfirmation } from '../taxProfileEmailConfirmation/taxProfileEmailConfirmation';
import './taxIncompleteProfile.css';
import lang from './taxIncompleteProfile.json';

export function TaxIncompleteProfile() {
  const taxProfileState = useAppSelector(state => state.taxProfile);

  const dispatch = useAppDispatch();

  if (taxProfileState.sendEmailStatus === 'successfully') {
    return (
      <TaxProfileEmailConfirmation />
    )
  }

  return (
    <div>
      <div className={'tax-calculation-incomplete-profile-container'}>
        <div className={'row'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <QuickfiscoIcon name={'alert-yellow.svg'} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <span className={'tax-calculation-incomplete-profile-title mt-4'}>
              {lang.title}
            </span>
          </div>
        </div>
        <div className={'row mt-2 d-flex justify-content-center'}>
          <div className={'col-10 d-flex justify-content-center'}>
            <span className={'tax-calculation-incomplete-profile-text mb-5'}>
              {lang.subtitle}
            </span>
          </div>
        </div>
        <div className={'row d-flex justify-content-center'}>
          {
            taxProfileState.sendEmailStatus === 'loading' ? (
              <div className='col-auto'>
                <QuickfiscoSpinner />
              </div>
            ) : (
              <div className={'col-6'}>
                <QuickfiscoButton
                  id={'tax-calculation-trial-user'}
                  label={lang.label}
                  type={'secondary'}
                  onClick={() => dispatch(sendEmail())}
                />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
