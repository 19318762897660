import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './paymentStatusInfo.json';
import './paymentStatusInfo.css';

interface Props {
  isCentered?: boolean;
}

export function PaymentStatusInfo(props: Props) {
  const { isCentered } = props;

  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon name={'info.svg'} />
        </div>
        <div
          ref={componentVisible.ref}
          className={
            isCentered
              ? 'payment-status-info-popup-centered'
              : 'payment-status-info-popup'
          }
        >
          {componentVisible.isVisible && (
            <div
              className={
                isCentered
                  ? 'payment-status-info-popup-container-centered pb-1'
                  : 'payment-status-info-popup-container pb-1'
              }
            >
              <p className={'payment-status-info-text'}>{lang.line1}</p>
              {isCentered && (
                <>
                  <p className={'payment-status-info-text'}>{lang.line2}</p>
                  <p className={'payment-status-info-text'}>
                    <b>{lang.line2_bold}</b>
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
