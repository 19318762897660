import React, { useEffect, useRef, useState } from 'react';
import { SetState } from '../types/functions';

export interface ComponentVisibleOutput {
  isVisible: boolean;
  setIsVisible: SetState<boolean>;
  ref: React.RefObject<HTMLDivElement> | null;
}

export default function useComponentVisible(initialIsVisible: boolean): ComponentVisibleOutput {
  const ref = useRef<HTMLDivElement>(null);

  const [isVisible, setIsVisible] = useState(initialIsVisible);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return {
    ref,
    isVisible,
    setIsVisible
  };
}
