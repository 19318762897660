import { QuickfiscoButton } from "../quickfiscoButton/quickfiscoButton";
import { QuickfiscoModal } from "../quickfiscoModal/quickfiscoModal";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import "./sendProformaInvoice.css"

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClick: () => void
    loading?: boolean
}

export function SendProformaInvoice(props: Props) {
    const { open } = props;
    const { setOpen } = props;
    const { onClick } = props;
    const { loading } = props;

    return (
        <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
            <div className={'row my-4'}>
                <div className={'col-12'}>
                    <div className={'row'}>
                        <div className={'col-4 offset-1 text-center send-proforma-invoice-title'}>
                            <span className="send-proforma-invoice-title-blue">
                                Proforma pronta!
                            </span>
                        </div>
                    </div>
                    <div className={'row mt-1'}>
                        <div className={'col-10 offset-1 text-center'}>
                            <span className="send-proforma-invoice-description">
                                Proseguendo verrà inviata una mail al tuo cliente con il pdf della proforma in allegato.
                            </span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-10 offset-1 text-center mt-4'}>
                            <span className="send-proforma-invoice-description-blue">
                                Procediamo?
                            </span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-10 offset-1 text-center mb-1'}>
                            <span className="send-proforma-invoice-description">
                                I dati non potranno più essere modificati.
                            </span>
                        </div>
                    </div>
                    <div className={'row mt-4'}>
                        <div className={'col-4 offset-4'}>
                            {
                                loading ? (
                                    <div className={'w-100 d-flex justify-content-center align-items-center'}>
                                        <QuickfiscoSpinner />
                                    </div>
                                ) : (
                                    <QuickfiscoButton
                                        id={'send-profoma-invoice-button'}
                                        label={"INVIA, È TUTTO OK!"}
                                        onClick={() => onClick && onClick()}
                                        type={'secondary'}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </QuickfiscoModal>
    );
}