import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setYear } from '../../redux/slices/taxCalculationSlice';
import { host } from '../../utils/config';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoTaxCalculationYearSelect } from '../quickfiscoTaxCalculationYearSelect/quickfiscoTaxCalculationYearSelect';
import { TaxDeadlineFactory } from '../taxDeadlineFactory/taxDeadlineFactory';
import { TaxEditDeadlinesList } from '../taxEditDeadlinesList/taxEditDeadlinesList';
import './taxDeadlinesList.css';
import lang from './taxDeadlinesList.json';

export function TaxDeadlinesList() {
  const [open, setOpen] = useState(false);

  const taxProfileState = useAppSelector(state => state.taxProfile);
  const taxCalculationState = useAppSelector(state => state.taxCalculation);

  const dispatch = useAppDispatch()

  const profile = taxProfileState.profile

  if (
    taxCalculationState.status === 'loading' ||
    taxProfileState.status === 'loading'
  ) {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (Number(taxCalculationState.year) < new Date().getFullYear() && (taxCalculationState.status === 'failed' || (taxCalculationState.forecast.year === new Date().getFullYear()
    || taxCalculationState.forecast.year === (new Date().getFullYear() + 1)))) {
    return (
      <div className={'tax-forecast-deadlines-container'}>
        <div className={'row align-items-center'}>
          <div className={'col-10'}>
            <span className={'tax-forecast-deadlines-title tax-forecast-deadlines-loading-title'}>
              {lang.title}
            </span>
          </div>
          <div className={'col-1 d-flex justify-content-end'}>
            <span className={'tax-forecast-deadlines-label'}>
              {lang.year}
            </span>
          </div>
          <div className={'col-1'}>
            <QuickfiscoTaxCalculationYearSelect
              id='tax-calculation-year-select'
              value={taxCalculationState.year}
              onChange={year => dispatch(setYear(year))}
            />
          </div>
        </div>
        <div className={'row mt-2'}>
          <div className={'col-12'}>
            <div
              className={'d-flex justify-content-center align-items-center'}
              style={{
                backgroundImage: 'url("' + host + '/images/timeline-box.gif")',
                backgroundSize: '350px',
                height: '350px'
              }}
            >
              <span className={'tax-forecast-deadlines-loading-error'}>
                {lang.errorYear}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    taxCalculationState.status === 'failed' ||
    (profile.lastUpdate > taxCalculationState.forecast.lastUpdate && (taxCalculationState.forecast.year === new Date().getFullYear()
      || taxCalculationState.forecast.year === (new Date().getFullYear() + 1)))
  ) {
    return (
      <div className={'tax-forecast-deadlines-container'}>
        <div className={'row align-items-center'}>
          <div className={'col-10'}>
            <span className={'tax-forecast-deadlines-title tax-forecast-deadlines-loading-title'}>
              {lang.title}
            </span>
          </div>
          <div className={'col-1 d-flex justify-content-end'}>
            <span className={'tax-forecast-deadlines-label'}>
              {lang.year}
            </span>
          </div>
          <div className={'col-1'}>
            <QuickfiscoTaxCalculationYearSelect
              id='tax-calculation-year-select'
              value={taxCalculationState.year}
              onChange={year => dispatch(setYear(year))}
            />
          </div>
        </div>
        <div className={'row mt-2'}>
          <div className={'col-12'}>
            <div
              className={'d-flex justify-content-center align-items-center'}
              style={{
                backgroundImage: 'url("' + host + '/images/timeline-box.gif")',
                backgroundSize: '350px',
                height: '350px'
              }}
            >
              <span className={'tax-forecast-deadlines-loading-error'}>
                {lang.error}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const forecast = taxCalculationState.forecast;

  const itemsList: React.ReactNode[] = [];
  forecast.deadlinesResponse.forEach((deadline, index) => {
    itemsList.push(
      <div
        key={'deadline-' + deadline.id}
        className='col ps-0 pe-0'
      >
        <TaxDeadlineFactory
          index={index}
          deadline={deadline}
          lastItem={index === forecast.deadlinesResponse.length - 1}
        />
      </div>
    );
  });

  return (
    <div className={'tax-forecast-deadlines-container'}>
      <div className={'row align-items-center justify-content-between'}>
        <div className={'col'}>
          <span className={'tax-forecast-deadlines-title'}>
            {lang.title}
          </span>
        </div>
        <div className={'col'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row align-items-center justify-content-end'}>
                <div className={'col-5'}>
                  <QuickfiscoButton
                    id={'button-edit-deadlines'}
                    label={lang.label}
                    onClick={() => setOpen(true)}
                    type={'primary'}
                  />
                </div>
                <div className={'col-1 offset-1 d-flex justify-content-end'}>
                  <span className={'tax-forecast-deadlines-label'}>
                    {lang.year}
                  </span>
                </div>
                <div className={'col-3'}>
                  <QuickfiscoTaxCalculationYearSelect
                    id='tax-calculation-year-select'
                    value={taxCalculationState.year}
                    onChange={year => dispatch(setYear(year))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'row mt-4'} >
        <div className={'col-12 tax-forecast-deadlines-overflow'}>
          <div className='row no-gutters flex-nowrap'>
            {itemsList}
          </div>
        </div>
      </div>
      <TaxEditDeadlinesList
        open={open}
        hide={() => setOpen(false)}
      />
    </div>
  );
}
