import useComponentVisible from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './alreadyTransmittedInfo.json';
import './alreadyTransmittedInfo.css';

export function AlreadyTransmittedInfo() {
  const componentVisible = useComponentVisible(false);

  return (
    <div>
      <div className={'position-relative'}>
        <div
          onMouseOver={() => componentVisible.setIsVisible(true)}
          onMouseOut={() => componentVisible.setIsVisible(false)}
        >
          <QuickfiscoIcon
            name={'info.svg'}
          />
        </div>
        <div
          ref={componentVisible.ref}
          className={'already-trasmitted-info-popup'}
        >
          {
            componentVisible.isVisible && (
              <div className={'already-trasmitted-info-popup-container pb-1'}>
                <p className={'already-trasmitted-info-text'}>
                  {lang.line1}
                </p>
                <p className={'already-trasmitted-info-text'}>
                  {lang.line2}
                  <span className={'fw-bold'}>
                    {lang.bold}
                  </span>
                  {lang.line3}
                </p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
