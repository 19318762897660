import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactModel, ContactType } from '../../models/contactModel';
import { PaginationModel } from '../../models/paginationModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCustomerList, setCustomerListFilterCompanyName, setCustomerListPage } from '../../redux/slices/customerListSlice';
import { setNewContactToInvoiceStatus } from '../../redux/slices/newContactToInvoiceSlice';
import { getSupplierList, setSupplierListFilterCompanyName, setSupplierListPage } from '../../redux/slices/supplierListSlice';
import uri from '../../routers/quickfiscoUri.json';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import './contactSelect.css';
import lang from './contactSelect.json';

interface Props {
  type: ContactType;
  selected?: ContactModel,
  error?: boolean;
  disabled?: boolean;
  requiredField?: boolean;
  setContact: ((contact: ContactModel | undefined) => void)
}

export function ContactSelect(props: Props) {
  const { type } = props;
  const { selected } = props;
  const { error } = props;
  const { disabled } = props;
  const { requiredField } = props;
  const { setContact } = props;

  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const customersListState = useAppSelector(state => state.customerList)
  const supplierListState = useAppSelector(state => state.supplierList)
  const newContactToInvoiceState = useAppSelector(state => state.newContactToInvoice)

  const newContactToInvoice = newContactToInvoiceState.contact
  const newContactToInvoiceStatus = newContactToInvoiceState.status

  let status: PromiseStatuses
  let contacts: PaginationModel<ContactModel>
  if (type === ContactType.CUSTOMER) {
    status = customersListState.status
    contacts = customersListState.customers
  } else {
    status = supplierListState.status
    contacts = supplierListState.suppliers
  }

  useEffect(() => {
    dispatch(setCustomerListPage(undefined))
    dispatch(setSupplierListPage(undefined))
  }, [])

  useEffect(() => {
    dispatch(getCustomerList())
  }, [customersListState.page, customersListState.filterCompanyName])


  useEffect(() => {
    dispatch(getSupplierList())
  }, [supplierListState.page, supplierListState.filterCompanyName])

  useEffect(() => {
    if (newContactToInvoiceStatus === 'idle' && newContactToInvoice !== undefined) {
      setContact({ ...newContactToInvoice });
    }
  }, [newContactToInvoiceStatus, newContactToInvoice]);

  if (status === 'failed') {
    return (
      <div>{type === ContactType.CUSTOMER ? lang.Error : lang.ErrorSupplier}</div>
    );
  }

  const contactsList = contacts.content.map(contact => {
    return (
      <div
        key={contact.id}
        className={'mt-4 contact-select-row'}
        onClick={() => {
          setContact(contact);
          setShow(false);
        }}
      >
        <div className={'contact-select-title'}>{contact.companyName}</div>
        <div className={'contact-select-desc'}>{contact.address}</div>
        <div className={'contact-select-desc'}>{contact.vat}</div>
      </div>
    )
  });

  let placeholder: React.ReactNode;
  if (error) {
    placeholder = (
      <div className={'contact-select-error mt-1'}>
        {type === ContactType.CUSTOMER ? lang.SelectionError.Customer : lang.SelectionError.Supplier}
      </div>
    );
  } else {
    placeholder = (
      <div className={'contact-select-desc mt-1'}>{lang.Placeholder}</div>
    );
  }

  return (
    <div className={'d-flex justify-content-between align-items-center'}>
      <div className={'contact-select contact-select-container p-4 w-100 me-3'}>
        <div
          onClick={() => {
            if (!disabled) {
              setShow(!show);
            }
          }}
          className={'d-flex justify-content-between'}
        >
          {
            selected ? (
              <div>
                <div className={'contact-select-title'}>{selected.companyName}</div>
                <div className={'contact-select-desc'}>{selected.address}</div>
                <div className={'contact-select-desc'}>{selected.vat}</div>
              </div>
            ) : (
              <div>
                <div className={'contact-select-title'}>
                  {type === ContactType.CUSTOMER ? lang.Title : lang.TitleSupplier}{requiredField && ' *'}
                </div>
                {placeholder}
              </div>
            )
          }
          {
            selected && !disabled ? (
              <QuickfiscoIcon
                name={'delete-icon.svg'}
                isButton={true}
                onClick={() => setContact(undefined)}
              />
            ) : (
              <QuickfiscoIcon
                name={'arrow-down.svg'}
              />
            )
          }
        </div>
        {
          show && (
            <div className={'mt-4'}>
              <QuickfiscoInput
                id={'contact-select-search'}
                type={'text'}
                styleType={'all-borders'}
                placeholder={type === ContactType.CUSTOMER ? lang.SearchPlaceholder : lang.SearchPlaceholderSupplier}
                defaultValue={type === ContactType.CUSTOMER ? customersListState.filterCompanyName : supplierListState.filterCompanyName}
                onChange={e => {
                  if (type === ContactType.CUSTOMER) {
                    dispatch(setCustomerListFilterCompanyName(e.target.value))
                  } else {
                    dispatch(setSupplierListFilterCompanyName(e.target.value))
                  }
                }}
              />
              <div className={'contact-select contact-select-list p-4 pt-1'}>
                {contactsList}
              </div>
            </div>
          )
        }
      </div>
      <div>
        <QuickfiscoIcon
          name={'new.svg'}
          className={'w-f-35'}
          isButton={!disabled}
          onClick={() => {
            if (!disabled) {
              dispatch(setNewContactToInvoiceStatus('toSave'));

              type === ContactType.CUSTOMER ? navigate(uri.CustomerSave) : navigate(uri.SupplierSave);
            }
          }}
        />
      </div>
    </div>
  );
}
