import './readOnlyNotice.css';
import lang from './readOnlyNotice.json';

export function ReadOnlyNotice() {
  return (
    <div className={'readonly-notice-container'}>
      <span className={'readonly-notice-text-bold'}>
        {lang.boldText}
      </span>
      <span className={'readonly-notice-text'}>
        {lang.text}
      </span>
    </div>
  );
}