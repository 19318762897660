import { DocType } from '../../models/objectModel';
import { UserModel } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setAddress,
  setAddressExtra,
  setBankAccount,
  setBirthPlace,
  setBirthProvince,
  setBirthState,
  setCity,
  setDateOfBirth,
  setGender,
  setName,
  setPhone,
  setProvince,
  setSurname,
  setTaxCode,
  setZipCode,
  updateMe,
} from '../../redux/slices/userSlice';
import { UserService } from '../../services/userService';
import { formatDateUS } from '../../utils/date';
import { BankInput } from '../bankInput/bankInput';
import { CountryTypeSelect } from '../countryTypeSelect/countryTypeSelect';
import { GenderSelect } from '../genderSelect/genderSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoCitySelect } from '../quickfiscoCitySelect/quickfiscoCitySelect';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoProvinceSelect } from '../quickfiscoProvinceSelect/quickfiscoProvinceSelect';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { UserDoc } from '../userDoc/userDoc';
import './profilePersonalInformations.css';
import lang from './profilePersonalInformations.json';

export function ProfilePersonalInformations() {
  const userState = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const userService = new UserService(dispatch);

  const editStatus = userState.editStatus;
  const editUserRequest = userState.editUserRequest;

  return (
    <>
      <div className="row p-4">
        <div className="col-12">
          <div className={'row no-gutters'}>
            <div className={'col profile-personal-informations-title'}>
              {lang.PersonalInfo}
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-name"
                label={lang.NameLabel}
                styleType={'default'}
                type="text"
                placeholder={lang.NamePlaceholder}
                onChange={(e) => dispatch(setName(e.target.value))}
                defaultValue={editUserRequest.name}
                error={userState.errorName}
                errorLabel={lang.ErrorName}
                required={true}
                onBlur={(e) => userService.validateName(e.target.value)}
              />
            </div>
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-surname"
                label={lang.SurnameLabel}
                type="text"
                styleType={'default'}
                placeholder={lang.SurnamePlaceholder}
                onChange={(e) => dispatch(setSurname(e.target.value))}
                defaultValue={editUserRequest.surname}
                error={userState.errorSurname}
                errorLabel={lang.ErrorSurname}
                required={true}
                onBlur={(e) => userService.validateSurname(e.target.value)}
              />
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-email"
                label={lang.EmailLabel}
                type="email"
                styleType={'default'}
                placeholder={lang.EmailPlaceholder}
                defaultValue={editUserRequest.email}
                required={true}
                onBlur={(e) =>
                  userService.validateEmail(e.target.value, {
                    skipConfirmPassword: true,
                    skipPassword: true,
                    skipEmailAvailable: true,
                  })
                }
                disabled={true}
              />
            </div>
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-phone"
                label={lang.PhoneLabel}
                type="text"
                styleType={'default'}
                placeholder={lang.PhonePlaceholder}
                onChange={(e) => dispatch(setPhone(e.target.value))}
                defaultValue={editUserRequest.phone}
                required={true}
                onBlur={(e) => userService.validatePhone(e.target.value)}
                error={userState.errorPhone}
                errorLabel={lang.ErrorPhone}
              />
            </div>
          </div>
          <div className={'row no-gutters'}>
            <div className={'col profile-personal-informations-title'}>
              {lang.AddressData}
            </div>
          </div>
          <div className="row no-gutters mt-3">
            <div className="col-6">
              <QuickfiscoCitySelect
                id={'profile-personal-info-city'}
                value={editUserRequest.city}
                placeholder={lang.CityPlaceholder}
                label={lang.CityLabel}
                onChange={(e) => {
                  dispatch(setCity(e.value));
                  // @ts-ignore
                  dispatch(setZipCode(e.cityZipCode));
                  // @ts-ignore
                  dispatch(setProvince(e.cityProvince));
                  // @ts-ignore
                  dispatch(setAddressExtra(e.addressExtra));
                }}
                error={userState.errorCity}
                errorLabel={lang.ErrorCity}
                requiredField={true}
              />
            </div>
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-address"
                label={lang.AddressLabel}
                type="text"
                styleType={'default'}
                placeholder={lang.AddressPlaceholder}
                onChange={(e) => dispatch(setAddress(e.target.value))}
                defaultValue={editUserRequest.address}
                error={userState.errorAddress}
                errorLabel={lang.ErrorAddress}
                required={true}
                onBlur={(e) => userService.validateAddress(e.target.value)}
              />
            </div>
          </div>
          <div className={'row no-gutters mt-3'}>
            <div className="col-2">
              <QuickfiscoInput
                id="profile-personal-info-zipCode"
                label={lang.ZipCodeLabel}
                type="text"
                styleType={'default'}
                placeholder={lang.ZipCodePlaceholder}
                onChange={(e) => dispatch(setZipCode(e.target.value))}
                value={editUserRequest.zipCode || ''}
                error={userState.errorZipCode}
                errorLabel={lang.ErrorZipCode}
                required={true}
                maxLength={5}
                onBlur={(e) => userService.validateZipCode(e.target.value)}
              />
            </div>
            <div className="col-4">
              <QuickfiscoProvinceSelect
                id={'profile-personal-info-province'}
                value={editUserRequest.province}
                onChange={(value) => dispatch(setProvince(value))}
                error={userState.errorProvince}
                errorLabel={lang.ErrorProvince}
                requiredField={true}
              />
            </div>
          </div>
          <div className={'row no-gutters'}>
            <div className={'col profile-personal-informations-title'}>
              {lang.PersonalData}
            </div>
          </div>
          <div className={'row no-gutters mt-3'}>
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-tax-code"
                label={lang.TaxCodeLabel}
                styleType={'default'}
                type="text"
                placeholder={lang.TaxCodePlaceholder}
                onChange={(e) =>
                  dispatch(setTaxCode(e.target.value.toUpperCase()))
                }
                value={editUserRequest.taxCode || ''}
                error={userState.errorTaxCode}
                errorLabel={lang.ErrorTaxCode}
                required={true}
                onBlur={(e) => userService.validateTaxCode(e.target.value)}
              />
            </div>
            <div className="col-3">
              <GenderSelect
                id={'profile-personal-info-gender-select'}
                onChange={(gender) => {
                  dispatch(setGender(gender?.toLowerCase()));
                  userService.validateGender(gender);
                }}
                value={editUserRequest.gender?.toUpperCase()}
                error={userState.errorGender}
              />
            </div>
            <div className="col-3">
              <QuickfiscoDatePicker
                id="profile-personal-info-date-of-birth"
                label={lang.DateOfBirth}
                required={true}
                styleType={'default'}
                value={
                  editUserRequest.dateOfBirth
                    ? new Date(editUserRequest.dateOfBirth)
                    : undefined
                }
                maxDate={new Date()}
                onChange={(e) => e && dispatch(setDateOfBirth(formatDateUS(e)))}
                onBlur={(e) => {
                  userService.validateDateOfBirth(e.target.value);
                }}
                error={userState.errorDateOfBirth}
                errorLabel={lang.ErrorDateOfBirth}
              />
            </div>
          </div>
          <div
            className={`row no-gutters d-flex ${
              userState.errorBirthState ||
              userState.errorBirthPlace ||
              userState.errorBirthProvince
                ? ''
                : 'align-items-end'
            } mt-3`}
          >
            <div className="col-4 mb-3">
              <CountryTypeSelect
                id="profile-personal-info-birth-state"
                value={editUserRequest.birthState}
                label={'Nazione di nascita'}
                onChange={(_type) => {
                  _type && dispatch(setBirthState(_type));
                  if (_type !== 'Italia') {
                    dispatch(setBirthProvince('EE'));
                  } else {
                    dispatch(setBirthProvince(undefined));
                  }
                }}
                required={true}
                error={userState.errorBirthState}
                errorLabel={lang.ErrorBirthState}
                isProfile={true}
              />
            </div>
            <div className="col-6">
              <QuickfiscoInput
                id="profile-personal-info-birth-place"
                label={lang.BirthPlace}
                type="text"
                placeholder={lang.BirthPlacePlaceholder}
                onChange={(e) => dispatch(setBirthPlace(e.target.value))}
                defaultValue={editUserRequest.birthPlace}
                error={userState.errorBirthPlace}
                errorLabel={lang.ErrorBirthPlace}
                styleType={'default'}
                required={true}
                onBlur={(e) => userService.validateBirthPlace(e.target.value)}
              />
            </div>
            <div className="col-2 mb-3">
              <QuickfiscoProvinceSelect
                id={'profile-personal-info-birth-province'}
                value={editUserRequest.birthProvince}
                label={lang.BirthProvince}
                onChange={(value) => dispatch(setBirthProvince(value))}
                error={userState.errorBirthProvince}
                errorLabel={lang.ErrorBirthProvince}
                requiredField={true}
                disabled={
                  !!(
                    editUserRequest.birthState &&
                    editUserRequest.birthState !== 'Italia'
                  )
                }
              />
            </div>
          </div>
          <div className={'row no-gutters'}>
            <div className={'col profile-personal-informations-title'}>
              {lang.BankData}
            </div>
          </div>
          <div className={'row no-gutters mt-3'}>
            <BankInput
              error={userState.errorBankIban || userState.errorBankName}
            />
          </div>
          <div className="row justify-content-end">
            <div className="col-6">
              <div className="mr-5">
                <QuickfiscoButton
                  id={'user-add-bank'}
                  label={'AGGIUNGI BANCA'}
                  type={
                    editUserRequest.bankAccount &&
                    editUserRequest.bankAccount.length === 2
                      ? 'disabled'
                      : 'primary'
                  }
                  onClick={() => {
                    const updatedBanks = [
                      ...(editUserRequest.bankAccount ?? []),
                    ];
                    updatedBanks.push({
                      default: updatedBanks.length <= 0,
                      iban: '',
                      name: '',
                    });
                    dispatch(setBankAccount([...updatedBanks]));
                  }}
                />
              </div>
            </div>
          </div>
          {(editUserRequest.idDocId || editUserRequest?.idDoc?.objectId) &&
            (editUserRequest.taxDocId || editUserRequest?.taxDoc?.objectId) && (
              <>
                <div className={'row no-gutters'}>
                  <div className={'col profile-personal-informations-title'}>
                    {lang.docIds}
                  </div>
                </div>
                <div className={'row no-gutters mt-4'}>
                  <div className="col-6 ">
                    <UserDoc docType={DocType.ID_DOC} user={editUserRequest} />
                  </div>
                  <div className="col-6">
                    <UserDoc docType={DocType.TAX_DOC} user={editUserRequest} />
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-4 offset-4 d-flex justify-content-center align-items-center">
          {editStatus === 'loading' ? (
            <QuickfiscoSpinner />
          ) : (
            <QuickfiscoButton
              type="secondary"
              id="profile-personal-info-edit"
              label={lang.ButtonLabel}
              onClick={() => update(editUserRequest, dispatch)}
            />
          )}
        </div>
      </div>
    </>
  );
}

async function update(editUserRequest: UserModel, dispatch: Function) {
  const userService = new UserService(dispatch);

  if (
    !(await userService.validate(editUserRequest, {
      skipConfirmPassword: true,
      skipPassword: true,
      skipEmailAvailable: true,
      skipFiscalProfileSaving: true,
    }))
  ) {
    return;
  }

  dispatch(updateMe(editUserRequest));
}
