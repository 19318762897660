import './messagesReport.css';

interface Props {
  title: string;
  amount?: number;
  color: BoxColor;
  setStatusFilter?: () => void;
  selected?: boolean;
}

export enum BoxColor {
  BLACK = 'messages-list-resume-black',
  BLUE = 'messages-list-resume-blue',
  RED = 'messages-list-resume-red',
}

export function MessagesReport(props: Props) {
  const { title } = props;
  const { amount } = props;
  const { color } = props;
  const { setStatusFilter } = props;
  const { selected } = props;

  return (
    <div
      className={
        selected === true
          ? 'row d-flex align-items-center messages-list-resume-border'
          : 'row d-flex align-items-center messages-list-resume-container'
      }
      onClick={() => {
        setStatusFilter && setStatusFilter();
      }}
    >
      <div className={'col-8'}>
        <div className={'messages-list-resume-title'}>{title}</div>
      </div>
      <div className={'col-4'}>
        <div className={color}>{amount}</div>
      </div>
    </div>
  );
}
