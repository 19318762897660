import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  DocumentsTypeTaxReturnModel,
  StartupSpendingTaxReturnModel,
  MaintenanceAllowanceTaxReturnModel,
  ObjectFileTaxReturnModel,
  SectionTaxReturn8,
  UniversitySpendingTaxReturnModel,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoFileList } from '../quickfiscoFileList.css/quickfiscoFileList';
import { getExtension, toBase64, validateFile } from '../../utils/file';
import { sanitizeString } from '../../utils/string';
import { useAppSelector } from '../../redux/hooks';
import { UserModel } from '../../models/userModel';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { NumericFormat } from 'react-number-format';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { counterFileRegex, fiscalCodeRegex } from '../../regex/regex';
import { ObjectModel } from '../../models/objectModel';
import lang from './taxReturnSection8.json';
import './taxReturnSection8.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn8) => void;
  onClickPrevStep: (data: SectionTaxReturn8) => void;
  downloadDoc: (docId: string) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
  scrollPosition?: number;
}

type FileAnswerType = ObjectFileTaxReturnModel | ObjectModel;

export function TaxReturnSection8(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { downloadDoc } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;
  const { scrollPosition } = props;

  const user = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [enabledSurvey, setEnabledSurvey] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [propertyRenovationQuestion, setPropertyRenovationQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [
    propertyRenovationPrevYearQuestion,
    setPropertyRenovationPrevYearQuestion,
  ] = useState<boolean | undefined>(undefined);
  const [
    propertyRenovationPrevYearAnswer,
    setPropertyRenovationPrevYearAnswer,
  ] = useState<ObjectFileTaxReturnModel[] | undefined>(undefined);
  const [medicalSpendingQuestion, setMedicalSpendingQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [medicalSpendingAnswer, setMedicalSpendingAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);
  const [
    mortgageInterestMainHomeQuestion,
    setMortgageInterestMainHomeQuestion,
  ] = useState<boolean | undefined>(undefined);
  const [mortgageInterestMainHomeAnswer, setMortgageInterestMainHomeAnswer] =
    useState<ObjectFileTaxReturnModel[] | undefined>(undefined);
  const [
    mortgageInterestOtherHomesQuestion,
    setMortgageInterestOtherHomesQuestion,
  ] = useState<boolean | undefined>(undefined);
  const [
    mortgageInterestOtherHomesAnswer,
    setMortgageInterestOtherHomesAnswer,
  ] = useState<ObjectFileTaxReturnModel[] | undefined>(undefined);
  const [universitySpendingQuestion, setUniversitySpendingQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [universitySpendingAnswer, setUniversitySpendingAnswer] = useState<
    UniversitySpendingTaxReturnModel | undefined
  >({
    totalAmountPrevYear: undefined,
    universityName: '',
    graduatingClass: '',
    spendingDocuments: undefined,
  });
  const [startupQuestion, setStartupQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [startupAnswer, setStartupAnswer] = useState<
    StartupSpendingTaxReturnModel | undefined
  >({
    totalAmountPrevYear: undefined,
    spendingDocuments: undefined,
  });
  const [maintenanceAllowanceQuestion, setMaintenanceAllowanceQuestion] =
    useState<boolean | undefined>(undefined);
  const [maintenanceAllowanceAnswer, setMaintenanceAllowanceAnswer] = useState<
    MaintenanceAllowanceTaxReturnModel | undefined
  >({
    totalAmountPrevYear: undefined,
    taxCodeSpouse: '',
  });
  const [lifeInsurancePremiumsQuestion, setLifeInsurancePremiumsQuestion] =
    useState<boolean | undefined>(undefined);
  const [lifeInsurancePremiumsAnswer, setLifeInsurancePremiumsAnswer] =
    useState<ObjectFileTaxReturnModel[] | undefined>(undefined);
  const [
    socialSecurityContributionsQuestion,
    setSocialSecurityContributionsQuestion,
  ] = useState<boolean | undefined>(undefined);
  const [
    socialSecurityContributionsAnswer,
    setSocialSecurityContributionsAnswer,
  ] = useState<ObjectFileTaxReturnModel[] | undefined>(undefined);
  const [otherSpendingQuestion, setOtherSpendingQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [otherSpendingAnswer, setOtherSpendingAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);

  const [
    statusPropertyRenovationPrevYear,
    setstatusPropertyRenovationPrevYear,
  ] = useState<PromiseStatuses>('idle');
  const [statusMedicalSpending, setStatusMedicalSpending] =
    useState<PromiseStatuses>('idle');
  const [statusMortgageInterestMainHome, setStatusMortgageInterestMainHome] =
    useState<PromiseStatuses>('idle');
  const [
    statusMortgageInterestOtherHomes,
    setStatusMortgageInterestOtherHomes,
  ] = useState<PromiseStatuses>('idle');
  const [statusUniversitySpending, setStatusUniversitySpending] =
    useState<PromiseStatuses>('idle');
  const [statusStartup, setStatusStartup] = useState<PromiseStatuses>('idle');
  const [statusLifeInsurancePremiums, setStatusLifeInsurancePremiums] =
    useState<PromiseStatuses>('idle');
  const [
    statusSocialSecurityContributions,
    setStatusSocialSecurityContributions,
  ] = useState<PromiseStatuses>('idle');
  const [statusOtherSpending, setStatusOtherSpending] =
    useState<PromiseStatuses>('idle');
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);
  const [taxCodeSpouseError, setTaxCodeSpouseError] = useState<boolean>(false);

  const getSurveyStep = (skypScroll?: boolean): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(8)
      .then((res) => {
        setLoadingStatus('successfully');
        scrollPosition && refreshApiCall(scrollPosition, skypScroll);
        const data = res as SectionTaxReturn8;
        setPropertyRenovationQuestion(data.propertyRenovationQuestion);
        setPropertyRenovationPrevYearQuestion(
          data.propertyRenovationPrevYearQuestion
        );
        setPropertyRenovationPrevYearAnswer(
          data.propertyRenovationPrevYearAnswer as ObjectFileTaxReturnModel[]
        );
        setMedicalSpendingQuestion(data.medicalSpendingQuestion);
        setMedicalSpendingAnswer(
          data.medicalSpendingAnswer as ObjectFileTaxReturnModel[]
        );
        setMortgageInterestMainHomeQuestion(
          data.mortgageInterestMainHomeQuestion
        );
        setMortgageInterestMainHomeAnswer(
          data.mortgageInterestMainHomeAnswer as ObjectFileTaxReturnModel[]
        );
        setMortgageInterestOtherHomesQuestion(
          data.mortgageInterestOtherHomesQuestion
        );
        setMortgageInterestOtherHomesAnswer(
          data.mortgageInterestOtherHomesAnswer as ObjectFileTaxReturnModel[]
        );
        setUniversitySpendingQuestion(data.universitySpendingQuestion);
        setUniversitySpendingAnswer(data.universitySpendingAnswer);
        setStartupQuestion(data.startupQuestion);
        setStartupAnswer(data.startupAnswer);
        setMaintenanceAllowanceQuestion(data.maintenanceAllowanceQuestion);
        setMaintenanceAllowanceAnswer(data.maintenanceAllowanceAnswer);
        setLifeInsurancePremiumsQuestion(data.lifeInsurancePremiumsQuestion);
        setLifeInsurancePremiumsAnswer(
          data.lifeInsurancePremiumsAnswer as ObjectFileTaxReturnModel[]
        );
        setSocialSecurityContributionsQuestion(
          data.socialSecurityContributionsQuestion
        );
        setSocialSecurityContributionsAnswer(
          data.socialSecurityContributionsAnswer as ObjectFileTaxReturnModel[]
        );
        setSocialSecurityContributionsQuestion(
          data.socialSecurityContributionsQuestion
        );
        setSocialSecurityContributionsAnswer(
          data.socialSecurityContributionsAnswer as ObjectFileTaxReturnModel[]
        );
        setOtherSpendingQuestion(data.otherSpendingQuestion);
        setOtherSpendingAnswer(
          data.otherSpendingAnswer as ObjectFileTaxReturnModel[]
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    if (propertyRenovationPrevYearQuestion === false) {
      setPropertyRenovationPrevYearAnswer(undefined);
    }
  }, [propertyRenovationPrevYearQuestion]);

  useEffect(() => {
    if (medicalSpendingQuestion === false) {
      setMedicalSpendingAnswer(undefined);
    }
  }, [medicalSpendingQuestion]);
  useEffect(() => {
    if (mortgageInterestMainHomeQuestion === false) {
      setMortgageInterestMainHomeAnswer(undefined);
    }
  }, [mortgageInterestMainHomeQuestion]);

  useEffect(() => {
    if (mortgageInterestOtherHomesQuestion === false) {
      setMortgageInterestOtherHomesAnswer(undefined);
    }
  }, [mortgageInterestOtherHomesQuestion]);

  useEffect(() => {
    if (universitySpendingQuestion === false) {
      setUniversitySpendingAnswer({
        totalAmountPrevYear: undefined,
        universityName: '',
        graduatingClass: '',
        spendingDocuments: undefined,
      });
      saveQuestion(
        dataToSend,
        setStatusUniversitySpending,
        DocumentsTypeTaxReturnModel.UNIVERISITY,
        false,
        getSurveyStep
      );
    } else {
      setStatusUniversitySpending('idle');
    }
  }, [universitySpendingQuestion]);

  useEffect(() => {
    if (startupQuestion === false) {
      setStartupAnswer({
        totalAmountPrevYear: undefined,
        spendingDocuments: undefined,
      });
      saveQuestion(
        dataToSend,
        setStatusStartup,
        DocumentsTypeTaxReturnModel.STARTUP,
        false,
        getSurveyStep
      );
    } else {
      setStatusStartup('idle');
    }
  }, [startupQuestion]);

  useEffect(() => {
    if (maintenanceAllowanceQuestion === false) {
      setMaintenanceAllowanceAnswer({
        totalAmountPrevYear: undefined,
        taxCodeSpouse: '',
      });
      setTaxCodeSpouseError(false);
    }
  }, [maintenanceAllowanceQuestion]);

  useEffect(() => {
    maintenanceAllowanceAnswer?.taxCodeSpouse &&
      maintenanceAllowanceAnswer?.taxCodeSpouse.length > 0 &&
      setTaxCodeSpouseError(
        !fiscalCodeRegex.test(maintenanceAllowanceAnswer.taxCodeSpouse)
      );
  }, [maintenanceAllowanceAnswer?.taxCodeSpouse]);

  useEffect(() => {
    if (lifeInsurancePremiumsQuestion === false) {
      setLifeInsurancePremiumsAnswer(undefined);
    }
  }, [lifeInsurancePremiumsQuestion]);
  useEffect(() => {
    if (socialSecurityContributionsQuestion === false) {
      setSocialSecurityContributionsAnswer(undefined);
    }
  }, [socialSecurityContributionsQuestion]);

  useEffect(() => {
    if (socialSecurityContributionsQuestion === false) {
      setSocialSecurityContributionsAnswer(undefined);
    }
  }, [socialSecurityContributionsQuestion]);

  useEffect(() => {
    if (otherSpendingQuestion === false) {
      setOtherSpendingAnswer(undefined);
    }
  }, [otherSpendingQuestion]);

  useEffect(() => {
    setCheckgoNext(
      taxReturnService.validateSection8(taxCodeSpouseError, dataToSend)
    );
  }, [
    propertyRenovationQuestion,
    propertyRenovationPrevYearQuestion,
    propertyRenovationPrevYearAnswer,
    medicalSpendingQuestion,
    medicalSpendingAnswer,
    mortgageInterestMainHomeQuestion,
    mortgageInterestMainHomeAnswer,
    mortgageInterestOtherHomesQuestion,
    mortgageInterestOtherHomesAnswer,
    universitySpendingQuestion,
    universitySpendingAnswer,
    startupQuestion,
    startupAnswer,
    maintenanceAllowanceQuestion,
    maintenanceAllowanceAnswer,
    taxCodeSpouseError,
    lifeInsurancePremiumsQuestion,
    lifeInsurancePremiumsAnswer,
    socialSecurityContributionsQuestion,
    socialSecurityContributionsAnswer,
    socialSecurityContributionsQuestion,
    socialSecurityContributionsAnswer,
    otherSpendingQuestion,
    otherSpendingAnswer,
  ]);

  const dataToSend: SectionTaxReturn8 = {
    propertyRenovationQuestion,
    propertyRenovationPrevYearQuestion,
    propertyRenovationPrevYearAnswer:
      propertyRenovationPrevYearQuestion === true
        ? propertyRenovationPrevYearAnswer
        : undefined,
    medicalSpendingQuestion,
    medicalSpendingAnswer:
      medicalSpendingQuestion === true ? medicalSpendingAnswer : undefined,
    mortgageInterestMainHomeQuestion,
    mortgageInterestMainHomeAnswer:
      mortgageInterestMainHomeQuestion === true
        ? mortgageInterestMainHomeAnswer
        : undefined,
    mortgageInterestOtherHomesQuestion,
    mortgageInterestOtherHomesAnswer:
      mortgageInterestOtherHomesQuestion === true
        ? mortgageInterestOtherHomesAnswer
        : undefined,
    universitySpendingQuestion,
    universitySpendingAnswer:
      universitySpendingQuestion === true
        ? universitySpendingAnswer
        : {
            totalAmountPrevYear: undefined,
            universityName: '',
            graduatingClass: '',
            spendingDocuments: undefined,
          },
    startupQuestion,
    startupAnswer:
      startupQuestion === true
        ? startupAnswer
        : {
            totalAmountPrevYear: undefined,
            spendingDocuments: undefined,
          },
    maintenanceAllowanceQuestion,
    maintenanceAllowanceAnswer:
      maintenanceAllowanceQuestion === true
        ? maintenanceAllowanceAnswer
        : undefined,
    lifeInsurancePremiumsQuestion,
    lifeInsurancePremiumsAnswer:
      lifeInsurancePremiumsQuestion === true
        ? lifeInsurancePremiumsAnswer
        : undefined,
    socialSecurityContributionsQuestion,
    socialSecurityContributionsAnswer:
      socialSecurityContributionsQuestion === true
        ? socialSecurityContributionsAnswer
        : undefined,
    otherSpendingQuestion,
    otherSpendingAnswer:
      otherSpendingQuestion === true ? otherSpendingAnswer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-8-title">{lang.title}</div>
        </div>
        {!enabledSurvey ? (
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-9">
              <div className={'row no-gutters mt-4'}>
                <div className="col-12">
                  <div className={'tax-return-section-8-container-text p-5'}>
                    <div className="row">
                      <div className="col-12">
                        Ti ricordiamo che è possibile beneficiare delle
                        detrazioni e deduzioni
                        <br /> per le spese sostenute nel 2023 solo in presenza
                        di
                        <br />
                        <b>redditi presenti nelle precedenti sezioni 4 e 5</b>
                        <br />
                        (es. redditi da dipendente, pensione etc)
                      </div>
                    </div>
                    <div className="row d-flex mt-4 justify-content-center align-items-center">
                      <div className="col-4">
                        <QuickfiscoButton
                          id={'tax-return-section-8-go-prev'}
                          label={lang.acceptedButton}
                          type={'primary'}
                          onClick={() => {
                            setEnabledSurvey(true);
                            getSurveyStep(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row mt-4">
              <div className="col-12">
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-7-electronic-invoicing-question"
                      title={lang.propertyRenovationQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={propertyRenovationQuestion}
                      onChange={(checked) => {
                        setPropertyRenovationQuestion(checked);
                      }}
                      required={true}
                    />
                  </div>
                </div>
                <div className={'row no-gutters'}>
                  <div className="col-12">
                    <hr className="my-5" />
                  </div>
                </div>
                <div className={'row no-gutters'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-property-renovation-pre-year-question"
                      title={lang.propertyRenovationPrevYearQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={propertyRenovationPrevYearQuestion}
                      onChange={(checked) => {
                        setPropertyRenovationPrevYearQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setstatusPropertyRenovationPrevYear,
                            DocumentsTypeTaxReturnModel.PAYMENTS,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setstatusPropertyRenovationPrevYear('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {propertyRenovationPrevYearQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusPropertyRenovationPrevYear}
                          title={lang.propertyRenovationPrevYearDocTitle}
                          fileList={propertyRenovationPrevYearAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setstatusPropertyRenovationPrevYear,
                              DocumentsTypeTaxReturnModel.PAYMENTS,
                              propertyRenovationPrevYearQuestion,
                              getSurveyStep,
                              doc,
                              propertyRenovationPrevYearAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setstatusPropertyRenovationPrevYear,
                              docId,
                              index,
                              setPropertyRenovationPrevYearAnswer,
                              propertyRenovationPrevYearAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusPropertyRenovationPrevYear}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters'}>
                  <div className="col-12">
                    <hr className="my-5" />
                  </div>
                </div>
                <div className={'row no-gutters'}>
                  <div className="col-12">
                    <div className="tax-return-section-8-section-title">
                      {lang.sectionTitle}
                    </div>
                    <div className="tax-return-section-8-section-description">
                      {lang.sectionDescription}
                    </div>
                  </div>
                </div>
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-medical-spending-question"
                      title={lang.medicalSpendingQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={medicalSpendingQuestion}
                      onChange={(checked) => {
                        setMedicalSpendingQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusMedicalSpending,
                            DocumentsTypeTaxReturnModel.MEDICAL,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusMedicalSpending('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {medicalSpendingQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusMedicalSpending}
                          title={lang.medicalSpendingDocTitle}
                          fileList={medicalSpendingAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusMedicalSpending,
                              DocumentsTypeTaxReturnModel.MEDICAL,
                              medicalSpendingQuestion,
                              getSurveyStep,
                              doc,
                              medicalSpendingAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusMedicalSpending,
                              docId,
                              index,
                              setMedicalSpendingAnswer,
                              medicalSpendingAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusMedicalSpending}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-mortgage-interest-main-home-question"
                      title={lang.mortgageInterestMainHomeQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={mortgageInterestMainHomeQuestion}
                      onChange={(checked) => {
                        setMortgageInterestMainHomeQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusMortgageInterestMainHome,
                            DocumentsTypeTaxReturnModel.MAIN_HOME_BANK,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusMortgageInterestMainHome('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {mortgageInterestMainHomeQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusMortgageInterestMainHome}
                          title={lang.mortgageInterestMainHomeDocTitle}
                          fileList={mortgageInterestMainHomeAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusMortgageInterestMainHome,
                              DocumentsTypeTaxReturnModel.MAIN_HOME_BANK,
                              mortgageInterestMainHomeQuestion,
                              getSurveyStep,
                              doc,
                              mortgageInterestMainHomeAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusMortgageInterestMainHome,
                              docId,
                              index,
                              setMortgageInterestMainHomeAnswer,
                              mortgageInterestMainHomeAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusMortgageInterestMainHome}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-mortgage-interest-other-homes-question"
                      title={lang.mortgageInterestOtherHomesQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={mortgageInterestOtherHomesQuestion}
                      onChange={(checked) => {
                        setMortgageInterestOtherHomesQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusMortgageInterestOtherHomes,
                            DocumentsTypeTaxReturnModel.OTHER_HOME_BANK,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusMortgageInterestOtherHomes('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {mortgageInterestOtherHomesQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusMortgageInterestOtherHomes}
                          title={lang.mortgageInterestOtherHomesDocTitle}
                          fileList={mortgageInterestOtherHomesAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusMortgageInterestOtherHomes,
                              DocumentsTypeTaxReturnModel.OTHER_HOME_BANK,
                              mortgageInterestOtherHomesQuestion,
                              getSurveyStep,
                              doc,
                              mortgageInterestOtherHomesAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusMortgageInterestOtherHomes,
                              docId,
                              index,
                              setMortgageInterestOtherHomesAnswer,
                              mortgageInterestOtherHomesAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusMortgageInterestOtherHomes}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-university-spending-question"
                      title={lang.universitySpendingQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={universitySpendingQuestion}
                      onChange={(checked) => {
                        setUniversitySpendingQuestion(checked);
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {universitySpendingQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-6">
                        <NumericFormat
                          id="tax-return-section-8-uni-total-amount-prev-year-input"
                          key={
                            'tax-return-section-8-uni-total-amount-prev-year'
                          }
                          suffix="€"
                          defaultValue={
                            universitySpendingAnswer?.totalAmountPrevYear
                          }
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          customInput={QuickfiscoInput}
                          onValueChange={(e) => {
                            setUniversitySpendingAnswer({
                              ...universitySpendingAnswer,
                              totalAmountPrevYear: e.floatValue,
                            });
                          }}
                          styleType={'default'}
                          label={lang.universityTotalAmountPrevYearLabel}
                          placeholder={
                            lang.universityTotalAmountPrevYearPlaceholder
                          }
                          fixedDecimalScale
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <QuickfiscoInput
                          id="tax-return-section-8-university-name"
                          type="text"
                          styleType={'default'}
                          label={lang.universityNameLabel}
                          placeholder={lang.universityNamePlaceholder}
                          defaultValue={
                            universitySpendingAnswer?.universityName
                          }
                          onChange={(e) =>
                            setUniversitySpendingAnswer({
                              ...universitySpendingAnswer,
                              universityName: e.target.value,
                            })
                          }
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <QuickfiscoInput
                          id="tax-return-section-8-graduating-class"
                          type="text"
                          styleType={'default'}
                          label={lang.graduatingClassLabel}
                          placeholder={lang.graduatingClassPlaceholder}
                          defaultValue={
                            universitySpendingAnswer?.graduatingClass
                          }
                          onChange={(e) =>
                            setUniversitySpendingAnswer({
                              ...universitySpendingAnswer,
                              graduatingClass: e.target.value,
                            })
                          }
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusUniversitySpending}
                          title={lang.universitySpendingDocTitle}
                          fileList={
                            universitySpendingAnswer?.spendingDocuments as ObjectFileTaxReturnModel[]
                          }
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusUniversitySpending,
                              DocumentsTypeTaxReturnModel.UNIVERISITY,
                              universitySpendingQuestion,
                              getSurveyStep,
                              doc,
                              universitySpendingAnswer?.spendingDocuments as ObjectFileTaxReturnModel[],
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteStartupOrUniversityDoc(
                              setStatusUniversitySpending,
                              docId,
                              index,
                              setUniversitySpendingAnswer,
                              universitySpendingAnswer,
                              universitySpendingAnswer?.spendingDocuments
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusUniversitySpending}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-startup-spending-question"
                      title={lang.startupQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={startupQuestion}
                      onChange={(checked) => {
                        setStartupQuestion(checked);
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {startupQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-6">
                        <NumericFormat
                          id="tax-return-section-8-startup-total-amount-prev-year-input"
                          key={
                            'tax-return-section-8-startup-total-amount-prev-year'
                          }
                          suffix="€"
                          defaultValue={startupAnswer?.totalAmountPrevYear}
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          customInput={QuickfiscoInput}
                          onValueChange={(e) => {
                            setStartupAnswer({
                              ...startupAnswer,
                              totalAmountPrevYear: e.floatValue,
                            });
                          }}
                          styleType={'default'}
                          label={lang.startupTotalAmountPrevYearLabel}
                          placeholder={
                            lang.startupTotalAmountPrevYearPlaceholder
                          }
                          fixedDecimalScale
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusStartup}
                          title={lang.startupDocTitle}
                          fileList={
                            startupAnswer?.spendingDocuments as ObjectFileTaxReturnModel[]
                          }
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusStartup,
                              DocumentsTypeTaxReturnModel.STARTUP,
                              startupQuestion,
                              getSurveyStep,
                              doc,
                              startupAnswer?.spendingDocuments as ObjectFileTaxReturnModel[],
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteStartupOrUniversityDoc(
                              setStatusStartup,
                              docId,
                              index,
                              setStartupAnswer,
                              startupAnswer,
                              startupAnswer?.spendingDocuments
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusStartup}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-maintenance-allowance-question"
                      title={lang.maintenanceAllowanceQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={maintenanceAllowanceQuestion}
                      onChange={(checked) =>
                        setMaintenanceAllowanceQuestion(checked)
                      }
                      required={true}
                    />
                  </div>
                </div>
                {maintenanceAllowanceQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-6">
                        <NumericFormat
                          id="tax-return-section-8-maintenance-allowance-total-amount-prev-year-input"
                          key={
                            'tax-return-section-8-maintenance-allowance-total-amount-prev-year'
                          }
                          suffix="€"
                          defaultValue={
                            maintenanceAllowanceAnswer?.totalAmountPrevYear
                          }
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          customInput={QuickfiscoInput}
                          onValueChange={(e) => {
                            setMaintenanceAllowanceAnswer({
                              ...maintenanceAllowanceAnswer,
                              totalAmountPrevYear: e.floatValue,
                            });
                          }}
                          styleType={'default'}
                          label={
                            lang.maintenanceAllowanceTotalAmountPrevYearLabel
                          }
                          placeholder={
                            lang.maintenanceAllowanceTotalAmountPrevYearPlaceholder
                          }
                          fixedDecimalScale
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <QuickfiscoInput
                          id="tax-return-section-8-maintenance-tax-code-prev-year"
                          type="text"
                          styleType={'default'}
                          label={lang.maintenanceAllowanceTaxCodeSpouseLabel}
                          placeholder={
                            lang.maintenanceAllowanceTaxCodeSpousePlaceholder
                          }
                          defaultValue={
                            maintenanceAllowanceAnswer?.taxCodeSpouse
                          }
                          onChange={(e) =>
                            setMaintenanceAllowanceAnswer({
                              ...maintenanceAllowanceAnswer,
                              taxCodeSpouse: e.target.value,
                            })
                          }
                          required={true}
                          error={taxCodeSpouseError}
                          errorLabel={lang.taxCodeError}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-life-insurance-question"
                      title={lang.lifeInsurancePremiumsQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={lifeInsurancePremiumsQuestion}
                      onChange={(checked) => {
                        setLifeInsurancePremiumsQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusLifeInsurancePremiums,
                            DocumentsTypeTaxReturnModel.INSURANCE,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusLifeInsurancePremiums('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {lifeInsurancePremiumsQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusLifeInsurancePremiums}
                          title={lang.lifeInsurancePremiumsDocTitle}
                          fileList={lifeInsurancePremiumsAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusLifeInsurancePremiums,
                              DocumentsTypeTaxReturnModel.INSURANCE,
                              lifeInsurancePremiumsQuestion,
                              getSurveyStep,
                              doc,
                              lifeInsurancePremiumsAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusLifeInsurancePremiums,
                              docId,
                              index,
                              setLifeInsurancePremiumsAnswer,
                              lifeInsurancePremiumsAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusLifeInsurancePremiums}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-social-security-contributions-question"
                      title={lang.socialSecurityContributionsQuestion}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={socialSecurityContributionsQuestion}
                      onChange={(checked) => {
                        setSocialSecurityContributionsQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusSocialSecurityContributions,
                            DocumentsTypeTaxReturnModel.CONTRIBUTION_CERTIFICATES,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusSocialSecurityContributions('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {socialSecurityContributionsQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusSocialSecurityContributions}
                          title={lang.socialSecurityContributionsDocTitle}
                          fileList={socialSecurityContributionsAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusSocialSecurityContributions,
                              DocumentsTypeTaxReturnModel.CONTRIBUTION_CERTIFICATES,
                              socialSecurityContributionsQuestion,
                              getSurveyStep,
                              doc,
                              socialSecurityContributionsAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusSocialSecurityContributions,
                              docId,
                              index,
                              setSocialSecurityContributionsAnswer,
                              socialSecurityContributionsAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusSocialSecurityContributions}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={'row no-gutters mt-4'}>
                  <div className="col-12">
                    <QuickfiscoBooleanCheckbox
                      id="tax-return-section-8-other-spending-question"
                      title={lang.otherSpendingQuestion}
                      description={lang.otherSpendingDescription}
                      yesLabel={lang.answerYes}
                      noLabel={lang.answerNo}
                      value={otherSpendingQuestion}
                      onChange={(checked) => {
                        setOtherSpendingQuestion(checked);
                        if (checked === false) {
                          saveQuestion(
                            dataToSend,
                            setStatusOtherSpending,
                            DocumentsTypeTaxReturnModel.OTHER_SPENDING,
                            false,
                            getSurveyStep
                          );
                        } else {
                          setStatusOtherSpending('idle');
                        }
                      }}
                      required={true}
                    />
                  </div>
                </div>
                {otherSpendingQuestion === true && (
                  <>
                    <div className={'row no-gutters mt-3'}>
                      <div className="col-12">
                        <QuickfiscoFileList
                          status={statusOtherSpending}
                          title={lang.otherSpendingDocTitle}
                          fileList={otherSpendingAnswer}
                          addDoc={(doc) =>
                            saveQuestion(
                              dataToSend,
                              setStatusOtherSpending,
                              DocumentsTypeTaxReturnModel.OTHER_SPENDING,
                              otherSpendingQuestion,
                              getSurveyStep,
                              doc,
                              otherSpendingAnswer,
                              user
                            )
                          }
                          deleteDoc={(docId, index) =>
                            deleteDoc(
                              setStatusOtherSpending,
                              docId,
                              index,
                              setOtherSpendingAnswer,
                              otherSpendingAnswer
                            )
                          }
                          downloadDoc={(docId) => downloadDoc(docId)}
                          error={statusOtherSpending}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row d-flex mt-5 align-items-center">
              <div className="col-3">
                <QuickfiscoButton
                  id={'tax-return-section-8-go-prev'}
                  label={lang.goPrev}
                  type={'primary'}
                  onClick={() => onClickPrevStep(dataToSend)}
                />
              </div>
              <div className="col-6 text-center">
                <div className="tax-return-section-8-go-title">
                  {lang.goAlertTitle}
                </div>
                <div className="tax-return-section-8-go-description">
                  {lang.goAlertdescription}
                </div>
              </div>
              <div className="col-3">
                {nextButtonStatus === 'loading' ? (
                  <div
                    className={
                      'd-flex justify-content-center align-items-center w-100'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'tax-return-section-8-go-next'}
                    label={lang.goNext}
                    type={checkgoNext ? 'secondary' : 'disabled'}
                    onClick={() => onClickNextStep(dataToSend)}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function saveQuestion(
  dataToSend: SectionTaxReturn8,
  setStatus: SetState<PromiseStatuses>,
  docType: DocumentsTypeTaxReturnModel,
  question: boolean,
  getSurveyStep: Function,
  doc?: File | null,
  answer?: ObjectFileTaxReturnModel[],
  user?: UserModel
): void {
  const keyObjTypeMap = new Map<DocumentsTypeTaxReturnModel, string>([
    [DocumentsTypeTaxReturnModel.PAYMENTS, 'propertyRenovationPrevYear'],
    [DocumentsTypeTaxReturnModel.MEDICAL, 'medicalSpending'],
    [DocumentsTypeTaxReturnModel.MAIN_HOME_BANK, 'mortgageInterestMainHome'],
    [DocumentsTypeTaxReturnModel.OTHER_HOME_BANK, 'mortgageInterestOtherHomes'],
    [DocumentsTypeTaxReturnModel.UNIVERISITY, 'universitySpending'],
    [DocumentsTypeTaxReturnModel.STARTUP, 'startup'],
    [DocumentsTypeTaxReturnModel.INSURANCE, 'lifeInsurancePremiums'],
    [
      DocumentsTypeTaxReturnModel.CONTRIBUTION_CERTIFICATES,
      'socialSecurityContributions',
    ],
    [DocumentsTypeTaxReturnModel.OTHER_SPENDING, 'otherSpending'],
  ]);

  const questionKey = keyObjTypeMap.get(docType) + 'Question';
  const answerKey = keyObjTypeMap.get(docType) + 'Answer';
  let objToSend = {};
  const counterRegex =
    answer && answer.length > 0 && answer[answer.length - 1]
      ? answer[answer.length - 1].name.match(counterFileRegex)
      : undefined;
  const counter = counterRegex ? Number(counterRegex[0].split('.')[0]) + 1 : 1;

  setStatus('loading');

  const service = new TaxReturnService();

  if (question === true) {
    if (!doc || !user || !counter) {
      return;
    }

    if (!validateFile(doc)) {
      setStatus('failed');
      return;
    }

    const fileName = sanitizeString(
      `${docType}_${user.surname}_${user.name}_${counter}`
    ).toLowerCase();

    toBase64(doc)
      .then((res) => {
        const fileToSend = [
          {
            name: fileName + '.' + getExtension(doc),
            extension: getExtension(doc),
            file: res,
          },
        ];

        objToSend = {
          [questionKey]: question,
          [answerKey]: fileToSend,
        };

        if (docType === DocumentsTypeTaxReturnModel.UNIVERISITY) {
          objToSend = {
            [questionKey]: question,
            [answerKey]: {
              totalAmountPrevYear:
                dataToSend.universitySpendingAnswer?.totalAmountPrevYear,
              universityName:
                dataToSend.universitySpendingAnswer?.universityName,
              graduatingClass:
                dataToSend.universitySpendingAnswer?.graduatingClass,
              spendingDocuments: fileToSend,
            },
          };
        } else if (docType === DocumentsTypeTaxReturnModel.STARTUP) {
          objToSend = {
            [questionKey]: question,
            [answerKey]: {
              totalAmountPrevYear:
                dataToSend.startupAnswer?.totalAmountPrevYear,
              spendingDocuments: fileToSend,
            },
          };
        }

        service
          .setSurveyStep(8, { ...dataToSend, ...objToSend })
          .then(() => {
            setStatus('successfully');
            getSurveyStep();
          })
          .catch((err) => {
            setStatus('failedUploadApi');
            getSurveyStep();
            console.error(err);
          });
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        console.error(err);
      });
  } else {
    objToSend = {
      [questionKey]: question,
    };
    service
      .setSurveyStep(8, { ...dataToSend, ...objToSend })
      .then(() => {
        setStatus('successfully');
        getSurveyStep();
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        getSurveyStep();
        console.error(err);
      });
  }
}

function deleteDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<ObjectFileTaxReturnModel[] | undefined>,
  answer?: ObjectFileTaxReturnModel[]
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(8, docId)
      .then(() => {
        if (answer) {
          const newAnswer = answer
            .slice(0, index)
            .concat(answer.slice(index + 1));
          setAnswer([...newAnswer]);
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}

function deleteStartupOrUniversityDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<
    UniversitySpendingTaxReturnModel | StartupSpendingTaxReturnModel | undefined
  >,
  globalAnswer:
    | UniversitySpendingTaxReturnModel
    | StartupSpendingTaxReturnModel
    | undefined,
  fileAnswer?: FileAnswerType[] | undefined
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(8, docId)
      .then(() => {
        if (fileAnswer) {
          const newAnswer = fileAnswer
            .slice(0, index)
            .concat(fileAnswer.slice(index + 1));
          setAnswer({
            ...globalAnswer,
            spendingDocuments: [...newAnswer],
          });
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}
