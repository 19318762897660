import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import './landsBuildingsAddButton.css';
import lang from './landsBuildingsAddButton.json';

interface Props {
  type: 'lands' | 'buildings';
  onClick: () => void;
}

export function LandsBuildingsAddButton(props: Props) {
  const { type } = props;
  const { onClick } = props;

  return (
    <div className="row my-5 text-center">
      <div className="col-12 pt-5">
        <div className="row">
          <div className="col-12 lands-buildings-add-button-title">
            {lang[type].title}
          </div>
        </div>
        <div className="row">
          <div className="col-12 lands-buildings-add-button-subtitle">
            {lang.subTitle}
          </div>
        </div>
        <div className="row mt-4 pb-5">
          <div className="col-4 offset-4">
            <QuickfiscoButton
              id={'lands-buildings-add-button-cta'}
              label={lang[type].cta}
              type={'secondary'}
              onClick={() => onClick()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
