import { useProSidebar } from 'react-pro-sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { DocumentsReportList } from '../../components/documentsReportList/documentsReportList';
import { getDocumentsList, getDocumentsReports, setDocumentsListPage } from '../../redux/slices/documentsListSlice';
import { useEffect } from 'react';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { DocumentList } from '../../components/documentsList/documentsList';
import usePrevious from '../../hooks/usePrevious';
import lang from './documents.json';

export function Documents() {
  const { collapsed } = useProSidebar();

  const dispatch = useAppDispatch()

  const documentsListState = useAppSelector(state => state.documentsList)
  const prevPage = usePrevious(documentsListState.page);
  const prevStatusFilter = usePrevious(documentsListState.statusFilter);

  useEffect(() => {
    dispatch(getDocumentsReports());
  }, [])

  useEffect(() => {
    if (documentsListState.page !== prevPage) {
      dispatch(getDocumentsList({
        page: documentsListState.page,
        type: documentsListState.statusFilter
      }))
    }
  }, [documentsListState.page])

  useEffect(() => {
    if (documentsListState.statusFilter !== prevStatusFilter) {
      if(documentsListState.page > 1) {
        dispatch(setDocumentsListPage(1));
      } else {
        dispatch(getDocumentsList({
          page: 1,
          type: documentsListState.statusFilter
        }))
      }
    }
  }, [documentsListState.statusFilter])

  const documents = documentsListState.documents

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='documents'
            menuItem={MenuItems.DOCUMENTS}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className='row'>
            <div className="col">
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-12'>
              <DocumentsReportList />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <DocumentList />
            </div>
          </div>
          {documents.totalPages !== 0 && 
            <div className={'row mt-3'}>
              <div className={'col-12'}>
                <div className={'d-flex justify-content-center'}>
                  <QuickfiscoPagination
                    totalPages={documents.totalPages}
                    number={documents.number ? documents.number - 1: documents.number}
                    empty={documents.empty}
                    doPagination={(page: number) => dispatch(setDocumentsListPage(page + 1))}
                    first={documents.first}
                    last={documents.last}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}