import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { SectionTaxReturn4 } from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { NumericFormat } from 'react-number-format';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './taxReturnSection4.json';
import './taxReturnSection4.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn4) => void;
  onClickPrevStep: (data: SectionTaxReturn4) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnSection4(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;

  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [receivedIncomeQuestion, setReceivedIncomeQuestion] = useState<
    boolean | undefined
  >(undefined);

  const [periodicAllowancesQuestion, setPeriodicAllowancesQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [periodicAllowancesAnswer, setPeriodicAllowancesAnswer] = useState<
    number | undefined
  >(undefined);

  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(4)
      .then((res) => {
        setLoadingStatus('successfully');
        const data = res as SectionTaxReturn4;
        setReceivedIncomeQuestion(data.receivedIncomeQuestion);
        setPeriodicAllowancesQuestion(data.periodicAllowancesQuestion);
        setPeriodicAllowancesAnswer(data.periodicAllowancesAnswer);
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep();
    refreshApiCall(0, true);
  }, []);

  useEffect(() => {
    if (periodicAllowancesQuestion === false) {
      setPeriodicAllowancesAnswer(undefined);
    }
  }, [periodicAllowancesQuestion]);

  useEffect(() => {
    setCheckgoNext(taxReturnService.validateSection4(dataToSend));
  }, [
    receivedIncomeQuestion,
    periodicAllowancesQuestion,
    periodicAllowancesAnswer,
  ]);

  const dataToSend: SectionTaxReturn4 = {
    receivedIncomeQuestion,
    periodicAllowancesQuestion,
    periodicAllowancesAnswer:
      periodicAllowancesQuestion === true
        ? periodicAllowancesAnswer
        : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-4-title">{lang.title}</div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-4-received-income-question"
                  title={lang.receivedIncomeQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={receivedIncomeQuestion}
                  list={lang.receivedIncomeQuestionList}
                  onChange={(checked) => setReceivedIncomeQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-4-periodic-allowances-question"
                  title={lang.periodicAllowancesQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={periodicAllowancesQuestion}
                  onChange={(checked) => setPeriodicAllowancesQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {periodicAllowancesQuestion === true && (
              <div className={'row no-gutters mt-3'}>
                <div className="col-6">
                  <NumericFormat
                    id="tax-return-section-4-periodic-allowances-question-input"
                    key={'tax-return-section-4-periodic-allowances-question'}
                    suffix="€"
                    defaultValue={periodicAllowancesAnswer}
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={2}
                    customInput={QuickfiscoInput}
                    onValueChange={(e) => {
                      setPeriodicAllowancesAnswer(e.floatValue);
                    }}
                    styleType={'default'}
                    label={lang.periodicAllowancesAnswerLabel}
                    placeholder={lang.periodicAllowancesAnswerPlaceholder}
                    fixedDecimalScale
                    required={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-4-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-4-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-4-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-4-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
