import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { TaxCalculation as TaxCalculationComponent } from '../../components/taxCalculation/taxCalculation';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';

export function TaxCalculation() {
  const [sidebarMargin, setSidebarMargin] = useState<'120px' | '280px'>(
    '120px'
  );

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (collapsed) {
      setSidebarMargin('120px');
    } else {
      setSidebarMargin('280px');
    }
  }, [collapsed]);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="tax-calculation-menu"
            menuItem={MenuItems.TAX_CALCULATION}
          />
        </div>
        <div
          className="col no-gutters"
          style={{
            width: 'calc(100% - ' + sidebarMargin + ')',
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <TaxCalculationComponent />
        </div>
      </div>
    </div>
  );
}
