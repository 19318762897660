import { SingleValue } from 'react-select';
import { InvoiceStatusFiltersType } from '../../models/invoiceFilters';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: InvoiceStatusFiltersType;
  onChange?: ((status?: InvoiceStatusFiltersType) => void);
}

export function InvoiceCustomerStatusSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      options={getOptions(value)}
      styleType={'no-borders'}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(InvoiceStatusFiltersType[e.value])
        }
      }}
      value={getOptions(value).filter(option => option.selected === true)[0]}

    />
  );
}

function getOptions(value?: InvoiceStatusFiltersType): OptionModel[] {
  return [
    {
      label: 'Stato',
      value: undefined,
      selected: value === undefined
    },
    {
      label: 'Incassato',
      value: InvoiceStatusFiltersType.PAID,
      selected: value === InvoiceStatusFiltersType.PAID
    },
    {
      label: 'Da incassare',
      value: InvoiceStatusFiltersType.TO_PAY,
      selected: value === InvoiceStatusFiltersType.TO_PAY
    },
    {
      label: 'Scaduta',
      value: InvoiceStatusFiltersType.EXPIRED,
      selected: value === InvoiceStatusFiltersType.EXPIRED
    }
  ];
}
