import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { getMe } from '../../redux/slices/userSlice';
import { RegimeLimitStatusType } from '../../models/userModel';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './exceedingThresholdsModal.json';
import './exceedingThresholdsModal.css';
import { useLocation } from 'react-router-dom';
import { CommonInvoiceService } from '../../services/commonInvoiceService';

interface Props {
  type: '85k' | '100k';
  isOpen?: boolean;
}

export function ExceedingThresholdsModal(props: Props) {
  const { type } = props;
  const { isOpen } = props;

  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const dispatch = useAppDispatch();
  const location = useLocation();

  const userState = useAppSelector((state) => state.user.user);

  let currentModal: React.ReactNode;

  useEffect(() => {
    if (
      type === '100k' &&
      userState.regimeLimit?.status === RegimeLimitStatusType.OVER85k
    ) {
      getTotalRevenues(setOpen);
    }
  }, [location]);

  switch (type) {
    case '85k':
      currentModal = (
        <>
          <div className={'row mb-5'}>
            <div className={'col-12 text-center'}>
              <QuickfiscoIcon name={'circle-red-x2.svg'} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row mb-4 pb-2'}>
                <div
                  className={
                    'col-12 text-center exceeding-thresholds-modal-title'
                  }
                >
                  {lang.title85k_1}
                  <br />
                  {lang.title85k_2}
                  <br />
                  <br />
                  {lang.title85k_3}
                  <br />
                  {lang.title85k_4}
                </div>
              </div>
              <div className={'row d-flex justify-content-center'}>
                <div
                  className={
                    'col-11 text-center exceeding-thresholds-modal-text'
                  }
                >
                  {userState.regimeLimit?.status ===
                  RegimeLimitStatusType.PENDING ? (
                    <>
                      <b>{lang.request_sent_1_85k}</b>
                      <br />
                      {lang.request_sent_2_bold_85k}
                    </>
                  ) : (
                    <>
                      {lang.text1_85k}
                      <b>{lang.text2_bold_85k}</b>
                    </>
                  )}
                </div>
              </div>
              <div className={'row my-5 px-4 d-flex justify-content-center'}>
                <div className={'col-6'}>
                  {userState.regimeLimit?.status ===
                  RegimeLimitStatusType.PENDING ? (
                    <QuickfiscoButton
                      id={'exceeding-thresholds-modal-request-sent'}
                      label={lang.requestSent}
                      type={'disabled'}
                    />
                  ) : (
                    <>
                      {status === 'loading' ? (
                        <div
                          className={
                            'd-flex justify-content-center align-items-center w-100'
                          }
                        >
                          <QuickfiscoSpinner />
                        </div>
                      ) : (
                        <QuickfiscoButton
                          id={'exceeding-thresholds-modal-cta'}
                          label={lang.cta_85K}
                          type={'secondary'}
                          onClick={() => regimeLimit85k(setStatus, dispatch)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case '100k':
      currentModal = (
        <>
          <div className={'row mb-5'}>
            <div className={'col-12 text-center'}>
              <QuickfiscoIcon name={'100k-exceeding-thresholds.svg'} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row mb-4 pb-2'}>
                <div
                  className={
                    'col-12 text-center exceeding-thresholds-modal-title'
                  }
                >
                  {lang.title100k}
                </div>
              </div>
              <div className={'row d-flex justify-content-center'}>
                <div
                  className={
                    'col-11 text-center exceeding-thresholds-modal-text'
                  }
                >
                  {lang.text_100k}
                </div>
              </div>
              <div className={'row my-5 px-4 d-flex justify-content-center'}>
                <div className={'col-5'}>
                  {userState.regimeLimit?.status ===
                  RegimeLimitStatusType.OVER85k ? (
                    <>
                      {status === 'loading' ? (
                        <div
                          className={
                            'd-flex justify-content-center align-items-center w-100'
                          }
                        >
                          <QuickfiscoSpinner />
                        </div>
                      ) : (
                        <QuickfiscoButton
                          id={'exceeding-thresholds-modal-cta'}
                          label={lang.cta_100K}
                          type={'secondary'}
                          onClick={() => regimeLimit100k(setStatus, dispatch)}
                        />
                      )}
                    </>
                  ) : (
                    <QuickfiscoButton
                      id={'exceeding-thresholds-modal-request-sent'}
                      label={lang.closeModal}
                      type={'senary'}
                      onClick={() => setOpen(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;
  }

  return (
    <div className={'row mt-4'}>
      <QuickfiscoSuccess
        message={lang.sendSuccess}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={lang.sendError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoModal
        isOpen={!!open}
        closeButton={true}
        hide={() => setOpen(false)}
        backdrop="static"
        keyboard={false}
        modalClassName={'exceeding-thresholds-modal-open'}
        centered={true}
        size={'lg'}
      >
        <div className={'exceeding-thresholds-modal-container py-4 px-5'}>
          {currentModal}
        </div>
      </QuickfiscoModal>
    </div>
  );
}

function regimeLimit85k(
  setStatus: SetState<PromiseStatuses>,
  dispatch: Function
) {
  const service = new UserService();

  setStatus('loading');
  service
    .regimeLimit85k()
    .then(() => {
      setStatus('successfully');
      dispatch(getMe());
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}

function regimeLimit100k(
  setStatus: SetState<PromiseStatuses>,
  dispatch: Function
) {
  const service = new UserService();

  setStatus('loading');
  service
    .regimeLimit100k()
    .then(() => {
      setStatus('successfully');
      dispatch(getMe());
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}

function getTotalRevenues(setOpen: SetState<boolean>): void {
  const service = new UserService();
  const year = new Date().getFullYear().toString();

  service
    .totalRevenuesByRegimeLimit(year, '100000')
    .then((data) => {
      if (
        CommonInvoiceService.calculateTotalPaid(data).totalPaid &&
        data.totalInvoicesPaidLastYear !== undefined
      ) {
        const total =
          CommonInvoiceService.calculateTotalPaid(data).totalPaid +
          data.totalInvoicesPaidLastYear;
        if (total > 100000) setOpen(true);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}
