import config from '../../../config.json';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFinanceStep } from '../../redux/slices/financeSlice';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './financeApproved.css';

export function FinanceApproved() {
  const financeState = useAppSelector((state) => state.finance);

  const dispatch = useAppDispatch();

  return (
    <div className={'financeApproved-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 35,
          maxWidth: '60%',
        }}
      >
        <span
          style={{
            textAlign: 'left',
            font: 'normal normal 600 20px/30px Poppins',
            color: '#1E1E23',
          }}
        >
          2. Scegli se avviare la pratica di richiesta
        </span>
        <QuickfiscoIcon
          name={'check-circle.svg'}
          className="financeApproved-icon"
        />
        <span className="financeApproved-text">
          Abbiamo verificato i requisiti di partenza,
          <br />
          il tuo profilo è idoneo alla richiesta di un finanziamento
        </span>
        <span className="financeApproved-text--blue">
          QUANTO PUOI RICHIEDERE?
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span className="financeApproved-limit">
              {'€ ' + formatNumberIT(financeState.financing?.min || 0)}
            </span>
            <span className="financeApproved-limit">
              {'€ ' + formatNumberIT(financeState.financing?.max || 0)}
            </span>
          </div>
          <div className="financeApproved-linear-bar" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span className="financeApproved-text--gray">
              Minimo finanziabile
            </span>
            <span className="financeApproved-text--gray">
              Massimo finanziabile
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <QuickfiscoButton
            id={'finance-approved-next-button-3'}
            key={'finance-approved-next-button'}
            label={'SIMULA FINANZIAMENTO'}
            onClick={() => dispatch(setFinanceStep(3))}
            type={'secondary'}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
    </div>
  );
}
