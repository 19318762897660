import { useEffect } from 'react';
import { InvoiceModel } from '../../models/invoiceModel';
import { PaginationModel } from '../../models/paginationModel';
import { ProfileStatus } from '../../models/stsProfileModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getStsProfile } from '../../redux/slices/stsProfileSlice';
import { InvoiceSign, PromiseStatuses } from '../../types/strings';
import { EmptyList } from '../emptyList/emptyList';
import { InvoiceImportBoxEmptyHome, InvoiceImportBoxHome } from '../invoiceImportBoxHome/invoiceImportBoxHome';
import { InvoiceImportStsBoxEmptyHome, InvoiceImportStsBoxHome, InvoiceImportStsBoxNotLoginRequestHome, InvoiceImportStsBoxRequestHome } from '../invoiceImportStsBoxHome/invoiceImportStsBoxHome';
import { InvoiceCustomerRowType, InvoiceRow } from '../invoiceRow/invoiceRow';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { StsHistoryRow } from '../stsHistoryRow/stsHistoryRow';
import './invoiceList.css';
import lang from './invoiceList.json';

interface Props {
  invoiceSign: InvoiceSign;
}

export function InvoiceList(props: Props) {
  const { invoiceSign } = props;

  const customerInvoiceListState = useAppSelector(
    (state) => state.customerInvoiceList
  );
  const supplierInvoiceListState = useAppSelector(
    (state) => state.supplierInvoiceList
  );
  const stsInvoiceListState = useAppSelector((state) => state.stsInvoiceList);
  const autoInvoiceListState = useAppSelector((state) => state.autoInvoiceList);
  const proformaInvoiceListState = useAppSelector(
    (state) => state.proformaInvoiceList
  );
  const occasionalJobListState = useAppSelector(
    (state) => state.occasionalJobInvoiceList
  );
  const userState = useAppSelector((state) => state.user.user);
  const stsProfile = useAppSelector((state) => state.stsProfile);

  const dispatch = useAppDispatch();;

  useEffect(() => {
    if (stsProfile.profile === undefined && userState.importInvoicesSts?.activationSts) {
      dispatch(getStsProfile());
    }
  }, [userState]);


  let status: PromiseStatuses;
  let invoices: PaginationModel<InvoiceModel>;
  let headers: React.ReactNode[];
  let rows: React.ReactNode[];

  switch (invoiceSign) {
    case 'customer':
      status = customerInvoiceListState.status;
      invoices = customerInvoiceListState.invoices;

      headers = lang.active.map((value, index) => {
        return (
          <div
            className={`col invoice-list-head invoice-list-customer-head-${index + 1
              }`}
            key={value}
          >
            {value}
          </div>
        );
      });
      headers.push(
        <div className={'col-auto'} key={'menu'}>
          <div style={{ width: '25px' }} />
        </div>
      );

      rows = invoices.content.map((invoice) => {
        return (
          <InvoiceRow
            invoiceSign={invoiceSign}
            key={invoice.id}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.INVOICE_LIST}
          />
        );
      });
      break;
    case 'proforma':
      status = proformaInvoiceListState.status;
      invoices = proformaInvoiceListState.invoices;

      headers = lang.proforma.map((value) => {
        return (
          <div className={'col invoice-list-head'} key={value}>
            {value}
          </div>
        );
      });
      headers.push(
        <div className={'col-auto'} key={'menu'}>
          <div style={{ width: '25px' }} />
        </div>
      );

      rows = invoices.content.map((invoice) => {
        return (
          <InvoiceRow
            invoiceSign={invoiceSign}
            key={invoice.id}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.INVOICE_LIST}
          />
        );
      });
      break;
    case 'supplier':
      status = supplierInvoiceListState.status;
      invoices = supplierInvoiceListState.invoices;

      headers = lang.passive.map((value) => {
        return (
          <div className={'col invoice-list-head'} key={value}>
            {value}
          </div>
        );
      });
      headers.push(
        <div className={'col-auto'} key={'menu'}>
          <div style={{ width: '25px' }} />
        </div>
      );

      rows = invoices.content.map((invoice) => {
        return (
          <InvoiceRow
            invoiceSign={invoiceSign}
            key={invoice.id}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.INVOICE_LIST}
          />
        );
      });

      break;
    case 'sts':
      status = stsInvoiceListState.status;
      invoices = stsInvoiceListState.invoices;

      headers = lang.sts.map((value, index) => {
        return (
          <div
            className={`col invoice-list-head ${index === 2 && 'invoice-list-customer-head-3'
              } ${index === 4 && 'invoice-list-customer-head-6'}`}
            key={value}
          >
            {value}
          </div>
        );
      });
      headers.push(<div className={'col-5'} key={'sts-menu'} />);

      rows = invoices.content.map((invoice) => {
        return <StsHistoryRow key={invoice.id} invoice={invoice} />;
      });

      break;
    case 'auto':
      status = autoInvoiceListState.status;
      invoices = autoInvoiceListState.invoices;

      headers = lang.auto.map((value, index) => {
        return (
          <div
            className={`col invoice-list-head invoice-list-auto-head-${index + 1
              }`}
            key={value}
          >
            {value}
          </div>
        );
      });
      headers.push(
        <div className={'col-auto'} key={'menu'}>
          <div style={{ width: '25px' }} />
        </div>
      );

      rows = invoices.content.map((invoice) => {
        return (
          <InvoiceRow
            invoiceSign={invoiceSign}
            key={invoice.id}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.INVOICE_LIST}
          />
        );
      });

      break;
    case 'occasionalJob':
      status = occasionalJobListState.status;
      invoices = occasionalJobListState.invoices;

      headers = lang.occasionalJob.map((value) => {
        return (
          <div className={'col invoice-list-head'} key={value}>
            {value}
          </div>
        );
      });
      headers.push(
        <div className={'col-auto'} key={'menu'}>
          <div style={{ width: '25px' }} />
        </div>
      );

      rows = invoices.content.map((invoice) => {
        return (
          <InvoiceRow
            invoiceSign={invoiceSign}
            key={invoice.id}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.INVOICE_LIST}
          />
        );
      });
      break;
    case 'creditSlip':
      status = customerInvoiceListState.status;
      invoices = customerInvoiceListState.invoices;

      headers = lang.creditSlip.map((value, index) => {
        return (
          <div
            className={`col invoice-list-head invoice-list-credit-slip-head-${index + 1
              }`}
            key={value}
          >
            {value}
          </div>
        );
      });
      headers.push(
        <div className={'col-auto'} key={'menu'}>
          <div style={{ width: '25px' }} />
        </div>
      );

      rows = invoices.content.map((invoice) => {
        return (
          <InvoiceRow
            invoiceSign={invoiceSign}
            key={invoice.id}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.INVOICE_LIST}
          />
        );
      });
      break;
    default:
      return <div>Tipo fattura non valido.</div>;
  }

  if (status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className={'p-blue'}>
          Errore durante il caricamento delle fatture.
        </div>
      </div>
    );
  }

  if (userState.importInvoices?.needToImportInvoices && invoiceSign === 'customer' && !customerInvoiceListState.isStsFilter) {

    if (userState.importInvoices.numberOfInvoices > 0) {
      return (
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-list-empty'}>
            <InvoiceImportBoxHome />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-list-empty'}>
            <InvoiceImportBoxEmptyHome />
          </div>
        </div>
      );
    }
  }

  if (userState.importInvoicesSts?.activationSts && invoiceSign === 'customer' && customerInvoiceListState.isStsFilter) {
    if (stsProfile.profile && stsProfile.profile?.status !== ProfileStatus.ACTIVE) {
      return (
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-list-empty'}>
            <InvoiceImportStsBoxNotLoginRequestHome />
          </div>
        </div>
      );
    } else if (!userState.importInvoicesSts?.needToImportInvoices) {
      return (
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-list-empty'}>
            <InvoiceImportStsBoxRequestHome />
          </div>
        </div>
      );
    } else if (userState.importInvoicesSts.numberOfInvoices > 0) {
      return (
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-list-empty'}>
            <InvoiceImportStsBoxHome />
          </div>
        </div>
      );
    } else {
      return (
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-list-empty'}>
            <InvoiceImportStsBoxEmptyHome />
          </div>
        </div>
      );
    }
  }

  if (invoices.content.length <= 0) {
    return (
      <div className={'row no-gutters'}>
        <div className={'col-12 invoice-list-empty'}>
          <EmptyList type={invoiceSign} />
        </div>
      </div>
    );
  }

  return (
    <div className={'invoice-list-container'}>
      <div className={'row no-gutters mb-3'}>{headers}</div>
      {rows}
    </div>
  );
}
