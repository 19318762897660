import { useState } from 'react';
import { SingleValue } from 'react-select';
import config from '../../../config.json';
import { PaymentPreferenceEnum } from '../../models/financeModel';
import { OptionModel } from '../../models/optionModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  sendFinanceUser,
  setFinanceStep,
  setFinanceUserRequestAddress,
  setFinanceUserRequestBankName,
  setFinanceUserRequestBirthDate,
  setFinanceUserRequestCitizienship,
  setFinanceUserRequestCity,
  setFinanceUserRequestCivilStatus,
  setFinanceUserRequestDomicileAddress,
  setFinanceUserRequestDomicileCity,
  setFinanceUserRequestDomicilePostalCode,
  setFinanceUserRequestDomicileProvince,
  setFinanceUserRequestDomicileRegion,
  setFinanceUserRequestFamilyUnit,
  setFinanceUserRequestHousingType,
  setFinanceUserRequestIban,
  setFinanceUserRequestLoanReason,
  setFinanceUserRequestPaymentPreference,
  setFinanceUserRequestPoliticallyExposed,
  setFinanceUserRequestPostalCode,
  setFinanceUserRequestProvince,
  setFinanceUserRequestRegion,
  setFinanceUserRequestResidenceStartDate,
  setFinanceUserRequestsameAsDomicile,
} from '../../redux/slices/financeSlice';
import { FinanceService } from '../../services/financeService';
import { formatDateUS } from '../../utils/date';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoCivilStatusSelect } from '../quickfiscoCivilStatusSelect/quickfiscoCivilStatusSelect';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputRadio } from '../quickfiscoInputRadio/quickfiscoInputRadio';
import { QuickfiscoProvinceSelect } from '../quickfiscoProvinceSelect/quickfiscoProvinceSelect';
import { QuickfiscoRegionSelect } from '../quickfiscoRegionSelect/quickfiscoRegionSelect';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './financeInformations.css';
import { NumberPeopleSelect } from '../numberPeopleSelect/numberPeopleSelect';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';

export function FinanceInformations() {
  const financeState = useAppSelector((state) => state.finance);

  const [errorBirthDate, setErrorBirthDate] = useState<boolean>(false);
  const [errorCitizenship, setErrorCitizenship] = useState<boolean>(false);
  const [errorCivilStatus, setErrorCivilStatus] = useState<boolean>(false);
  const [errorFamilyUnit, setErrorFamilyUnit] = useState<boolean>(false);
  const [errorAddress, setErrorAddress] = useState<boolean>(false);
  const [errorCAP, setErrorCAP] = useState<boolean>(false);
  const [errorProvince, setErrorProvince] = useState<boolean>(false);
  const [errorCity, setErrorCity] = useState<boolean>(false);
  const [errorRegion, setErrorRegion] = useState<boolean>(false);
  const [errorDomicileAddress, setErrorDomicileAddress] =
    useState<boolean>(false);
  const [errorDomicileCAP, setErrorDomicileCAP] = useState<boolean>(false);
  const [errorDomicileProvince, setErrorDomicileProvince] =
    useState<boolean>(false);
  const [errorDomicileCity, setErrorDomicileCity] = useState<boolean>(false);
  const [errorDomicileRegion, setErrorDomicileRegion] =
    useState<boolean>(false);
  const [errorResidenceDate, setErrorResidenceDate] = useState<boolean>(false);
  const [errorLoanReason, setErrorLoanReason] = useState<boolean>(false);
  const [errorBankName, setErrorBankName] = useState<boolean>(false);
  const [errorBankIban, setErrorBankIban] = useState<boolean>(false);
  const [errorPreferencePaymentType, setErrorPreferencePaymentType] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const financeService = new FinanceService(undefined, {
    setErrorAddress,
    setErrorBankIban,
    setErrorBankName,
    setErrorLoanReason,
    setErrorBirthDate,
    setErrorCAP,
    setErrorCitizenship,
    setErrorCity,
    setErrorCivilStatus,
    setErrorFamilyUnit,
    setErrorDomicileAddress,
    setErrorDomicileCAP,
    setErrorDomicileCity,
    setErrorDomicileProvince,
    setErrorDomicileRegion,
    setErrorPreferencePaymentType,
    setErrorProvince,
    setErrorRegion,
    setErrorResidenceDate,
  });

  return (
    <div className={'financeInformations-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 35,
          width: '60%',
        }}
      >
        <span
          style={{
            textAlign: 'left',
            font: 'normal normal 600 20px/30px Poppins',
            color: '#1E1E23',
          }}
        >
          4. Abbiamo bisogno di qualche informazione aggiuntiva!
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%',
            gap: 40,
            flexWrap: 'wrap',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <QuickfiscoDatePicker
              id={'financeInformations-birth-date'}
              key={'financeInformations-birth-date'}
              required
              label="Data di nascita"
              styleType={'default'}
              value={new Date(financeState.userFinanceRequest.data.birth_date)}
              onChange={(date) => {
                date &&
                  dispatch(setFinanceUserRequestBirthDate(formatDateUS(date)));
                financeService.validateBirthDate(date);
              }}
              error={errorBirthDate}
              errorLabel="Inserire data di nascita"
            />
            <QuickfiscoCivilStatusSelect
              id={'finance-modal-civil-status'}
              key={'finance-modal-civil-status'}
              requiredField
              error={errorCivilStatus}
              errorLabel="Selezionare uno stato civile"
              value={
                financeState.userFinanceRequest.data.civil_status === ''
                  ? undefined
                  : financeState.userFinanceRequest.data.civil_status
              }
              onChange={(e) => {
                dispatch(setFinanceUserRequestCivilStatus(e));
              }}
            />
            <div className="mt-3">
              <NumberPeopleSelect
                id={'finance-modal-civil-number-people-select'}
                onChange={(number) => {
                  dispatch(setFinanceUserRequestFamilyUnit(Number(number)));
                }}
                value={
                  financeState.userFinanceRequest.data.family_unit
                    ? financeState.userFinanceRequest.data.family_unit.toString()
                    : undefined
                }
                error={errorFamilyUnit}
              />
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <QuickfiscoInput
              id={'financeInformations-citizenship'}
              key={'financeInformations-citizenship'}
              type={'text'}
              styleType={'default'}
              label="Cittadinanza"
              required
              placeholder="Cittadinanza"
              defaultValue={financeState.userFinanceRequest.data.citizenship}
              onChange={(e) =>
                dispatch(setFinanceUserRequestCitizienship(e.target.value))
              }
              onBlur={(e) => financeService.validateCitizenship(e.target.value)}
              error={errorCitizenship}
              errorLabel="Inserire cittadinanza"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <span className="financeInformations-input-label">
                Sei una persona politicamente esposta?
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 40,
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <QuickfiscoInputRadio
                  onChange={() =>
                    dispatch(setFinanceUserRequestPoliticallyExposed(true))
                  }
                  id={'financeInformations-politically-exposed-true'}
                  key={'financeInformations-politically-exposed-true'}
                  value={'SI'}
                  label="SI"
                  name="financeInformations-politically-exposed"
                  checked={
                    financeState.userFinanceRequest.data.politically_exposed
                  }
                />
                <QuickfiscoInputRadio
                  id={'financeInformations-politically-exposed-false'}
                  key={'financeInformations-politically-exposed-false'}
                  value={'NO'}
                  label="NO"
                  onChange={() => {
                    dispatch(setFinanceUserRequestPoliticallyExposed(false));
                  }}
                  name="financeInformations-politically-exposed"
                  checked={
                    !financeState.userFinanceRequest.data.politically_exposed
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <span className="financeInformations-label-blue">
            RESIDENZA E TIPO DI ABITAZIONE
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%',
              gap: 40,
              flexWrap: 'wrap',
            }}
          >
            <div style={{ width: 'calc(50% - 20px)' }}>
              <QuickfiscoInput
                id={'financeInformations-address'}
                key={'financeInformations-address'}
                required
                label="Indirizzo"
                styleType={'default'}
                placeholder="Inserisci indirizzo"
                defaultValue={financeState.userFinanceRequest.data.address}
                onChange={(e) =>
                  dispatch(setFinanceUserRequestAddress(e.target.value))
                }
                type={'text'}
                onBlur={(e) => financeService.validateAddress(e.target.value)}
                error={errorAddress}
                errorLabel="Inserire indirizzo"
              />
              <QuickfiscoInput
                id={'financeInformations-city'}
                key={'financeInformations-city'}
                type={'text'}
                styleType={'default'}
                label="Comune"
                required
                placeholder="Inserisci comune"
                defaultValue={financeState.userFinanceRequest.data.city}
                onChange={(e) =>
                  dispatch(setFinanceUserRequestCity(e.target.value))
                }
                onBlur={(e) => financeService.validateCity(e.target.value)}
                error={errorCity}
                errorLabel="Inserire città"
              />
            </div>
            <div style={{ width: 'calc(50% - 20px)' }}>
              <div style={{ display: 'flex', gap: 20, width: '100%' }}>
                <QuickfiscoInput
                  id={'financeInformations-cap'}
                  key={'financeInformations-cap'}
                  type={'text'}
                  styleType={'default'}
                  label="CAP"
                  maxLength={5}
                  required
                  placeholder="Inserisci cap"
                  defaultValue={
                    financeState.userFinanceRequest.data.postal_code
                  }
                  onChange={(e) =>
                    dispatch(setFinanceUserRequestPostalCode(e.target.value))
                  }
                  onBlur={(e) => financeService.validateCap(e.target.value)}
                  error={errorCAP}
                  errorLabel="Inserire CAP"
                />
                <div style={{ flexGrow: 1 }}>
                  <QuickfiscoProvinceSelect
                    requiredField
                    id={'financeInformations-province'}
                    key={'financeInformations-province'}
                    value={financeState.userFinanceRequest.data.province}
                    onChange={(e) => {
                      dispatch(setFinanceUserRequestProvince(e));
                      financeService.validateProvince(e);
                    }}
                    error={errorProvince}
                    errorLabel="Inserire provincia"
                  />
                </div>
              </div>
              <QuickfiscoRegionSelect
                id={'finance-modal-region'}
                key={'finance-modal-region'}
                requiredField
                error={errorRegion}
                errorLabel="Selezionare una regione"
                value={
                  financeState.userFinanceRequest.data.region === ''
                    ? undefined
                    : financeState.userFinanceRequest.data.region
                }
                onChange={(e) => {
                  dispatch(setFinanceUserRequestRegion(e));
                }}
              />
            </div>
          </div>
          <QuickfiscoCheckbox
            id={'financeInformations-domicile'}
            key={'financeInformations-domicile'}
            label={'Il mio indirizzo di residenza coincide con il domicilio'}
            checked={financeState.userFinanceRequest.data.same_as_domicile}
            onChange={(e) => {
              dispatch(
                setFinanceUserRequestsameAsDomicile(
                  !financeState.userFinanceRequest.data.same_as_domicile
                )
              );
              if (e.target.checked) {
                dispatch(setFinanceUserRequestDomicileAddress(null));
                dispatch(setFinanceUserRequestDomicileCity(null));
                dispatch(setFinanceUserRequestDomicilePostalCode(null));
                dispatch(setFinanceUserRequestDomicileProvince(null));
                dispatch(setFinanceUserRequestDomicileRegion(null));
              } else {
                dispatch(setFinanceUserRequestDomicileAddress(''));
                dispatch(setFinanceUserRequestDomicileCity(''));
                dispatch(setFinanceUserRequestDomicilePostalCode(''));
                dispatch(setFinanceUserRequestDomicileProvince(''));
                dispatch(setFinanceUserRequestDomicileRegion(''));
              }
            }}
          />
          <div
            className="mt-3"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%',
              gap: 40,
              flexWrap: 'wrap',
            }}
          >
            <div style={{ width: 'calc(50% - 20px)' }}>
              {!financeState.userFinanceRequest.data.same_as_domicile && (
                <>
                  <QuickfiscoInput
                    id={'financeInformations-address--domicile'}
                    key={'financeInformations-address--domicile'}
                    required
                    label="Indirizzo di domicilio"
                    styleType={'default'}
                    placeholder="Inserisci indirizzo"
                    defaultValue={
                      financeState.userFinanceRequest.data.domicile_address ??
                      ''
                    }
                    onChange={(e) =>
                      dispatch(
                        setFinanceUserRequestDomicileAddress(e.target.value)
                      )
                    }
                    type={'text'}
                    onBlur={(e) =>
                      financeService.validateDomicileAddress(
                        financeState.userFinanceRequest.data.same_as_domicile,
                        e.target.value
                      )
                    }
                    error={errorDomicileAddress}
                    errorLabel="Inserire indirizzo di domicilio"
                  />
                  <QuickfiscoInput
                    id={'financeInformations-city--domicile'}
                    key={'financeInformations-city--domicile'}
                    type={'text'}
                    styleType={'default'}
                    label="Comune di domicilio"
                    required
                    placeholder="Inserisci comune"
                    defaultValue={
                      financeState.userFinanceRequest.data.domicile_city ?? ''
                    }
                    onChange={(e) =>
                      dispatch(
                        setFinanceUserRequestDomicileCity(e.target.value)
                      )
                    }
                    onBlur={(e) =>
                      financeService.validateDomicileCity(
                        financeState.userFinanceRequest.data.same_as_domicile,
                        e.target.value
                      )
                    }
                    error={errorDomicileCity}
                    errorLabel="Inserire città di domicilio"
                  />
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100%',
                  marginTop: '40px',
                }}
              >
                <span className="financeInformations-input-label">
                  Tipo di immobile:
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%',
                  }}
                >
                  <QuickfiscoInputRadio
                    onChange={() =>
                      dispatch(setFinanceUserRequestHousingType('rent'))
                    }
                    id={'financeInformations-house-type-true'}
                    key={'financeInformations-house-type-true'}
                    value={'rent'}
                    label="IN AFFITTO"
                    name="financeInformations-house-type"
                    checked={
                      financeState.userFinanceRequest.data.housing_type ===
                      'rent'
                    }
                  />
                  <QuickfiscoInputRadio
                    id={'financeInformations-house-type-false'}
                    key={'financeInformations-house-type-false'}
                    value={'property'}
                    label="IMMOBILE DI PROPRIETÀ"
                    onChange={() => {
                      dispatch(setFinanceUserRequestHousingType('property'));
                    }}
                    name="financeInformations-house-type"
                    checked={
                      financeState.userFinanceRequest.data.housing_type ===
                      'property'
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ width: 'calc(50% - 20px)' }}>
              {!financeState.userFinanceRequest.data.same_as_domicile && (
                <>
                  <div style={{ display: 'flex', width: '100%', gap: 20 }}>
                    <QuickfiscoInput
                      id={'financeInformations-cap--domicile'}
                      key={'financeInformations-cap--domicile'}
                      type={'text'}
                      styleType={'default'}
                      label="CAP di domicilio"
                      maxLength={5}
                      required
                      placeholder="Inserisci cap"
                      defaultValue={
                        financeState.userFinanceRequest.data
                          .domicile_postal_code ?? ''
                      }
                      onChange={(e) =>
                        dispatch(
                          setFinanceUserRequestDomicilePostalCode(
                            e.target.value
                          )
                        )
                      }
                      onBlur={(e) =>
                        financeService.validateDomicileCap(
                          financeState.userFinanceRequest.data.same_as_domicile,
                          e.target.value
                        )
                      }
                      error={errorDomicileCAP}
                      errorLabel="Inserire cap di domicilio"
                    />
                    <div style={{ flexGrow: 1 }}>
                      <QuickfiscoProvinceSelect
                        requiredField
                        id={'financeInformations-province--domicile'}
                        key={'financeInformations-province--domicile'}
                        value={
                          financeState.userFinanceRequest.data
                            .domicile_province ?? ''
                        }
                        onChange={(e) => {
                          dispatch(setFinanceUserRequestDomicileProvince(e));
                          financeService.validateDomicileProvince(
                            financeState.userFinanceRequest.data
                              .same_as_domicile,
                            e
                          );
                        }}
                        error={errorDomicileProvince}
                        errorLabel="Inserire provincia di domicilio"
                      />
                    </div>
                  </div>
                  <QuickfiscoRegionSelect
                    id={'finance-modal-region--domicile'}
                    key={'finance-modal-region--domicile'}
                    requiredField
                    error={errorDomicileRegion}
                    errorLabel="Selezionare una regione"
                    value={
                      financeState.userFinanceRequest.data.domicile_region ===
                      ''
                        ? undefined
                        : financeState.userFinanceRequest.data
                            .domicile_region ?? ''
                    }
                    onChange={(e) => {
                      dispatch(setFinanceUserRequestDomicileRegion(e));
                    }}
                  />
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '40px',
                }}
              >
                <QuickfiscoDatePicker
                  id={'financeInformations-residence-date'}
                  key={'financeInformations-residence-date'}
                  required
                  label="Data inizio residenza"
                  styleType={'default'}
                  value={
                    new Date(
                      financeState.userFinanceRequest.data.residence_start_date
                    )
                  }
                  onChange={(date) => {
                    date &&
                      dispatch(
                        setFinanceUserRequestResidenceStartDate(
                          formatDateUS(date)
                        )
                      );
                    financeService.validateResidenceDate(date);
                  }}
                  error={errorResidenceDate}
                  errorLabel="Inserire data di inizio residenza"
                />
                <span className="financeInformations-info-text">
                  La data può essere indicativa ma il più possibile precisa, non
                  è necessario che sia inserito il giorno esatto di assegnazione
                  residenza.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <span className="financeInformations-label-blue">
            MOTIVO DEL PRESTITO
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%',
              gap: 40,
              flexWrap: 'wrap',
            }}
          >
            <div style={{ flexGrow: 1 }} className="mt-3">
              <QuickfiscoTextarea
                id={'financeInformations-loan-reason'}
                label={
                  'Inserisci una breve descrizione del motivo per cui stai richiedendo il prestito (la banca esclude le motivazioni per liquidità, investimento e immobiliari)'
                }
                onChange={(e) =>
                  dispatch(setFinanceUserRequestLoanReason(e.target.value))
                }
                value={financeState.userFinanceRequest.data.loan_reason || ''}
                placeholder={'Descrivi il motivo di richiesta prestito'}
                error={errorLoanReason}
                errorLabel="Inserisci la descrizione"
                required={true}
              />
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <span className="financeInformations-label-blue">
            COORDINATE BANCARIE
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              width: '100%',
              gap: 40,
              flexWrap: 'wrap',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <QuickfiscoInput
                id={'financeInformations-bank-name'}
                key={'financeInformations-bank-name'}
                type={'text'}
                styleType={'default'}
                label="Denominazione Banca"
                required
                placeholder="Es. Unicredit, Che Banca, ING Direct ecc"
                defaultValue={financeState.userFinanceRequest.data.bank_name}
                onChange={(e) =>
                  dispatch(setFinanceUserRequestBankName(e.target.value))
                }
                onBlur={(e) => financeService.validateBankName(e.target.value)}
                error={errorBankName}
                errorLabel="Inserire nome banca"
              />
              <QuickfiscoSelect
                id={'financeInformations-payment-type'}
                key={'financeInformations-payment-type'}
                label={'Preferenza sul pagamento della rata'}
                onChange={(e: SingleValue<OptionModel>) => {
                  if (e !== null) {
                    dispatch(setFinanceUserRequestPaymentPreference(e.value));
                    financeService.validatePaymentPreference(
                      e.value as PaymentPreferenceEnum
                    );
                  }
                }}
                options={getPaymentTypeOptions(
                  financeState.userFinanceRequest.data.payment_preference
                )}
                requiredField
                styleType={'default'}
                value={getPaymentTypeOptions(
                  financeState.userFinanceRequest.data.payment_preference
                ).find((option) => option.selected === true)}
                error={errorPreferencePaymentType}
                errorLabel="Inserire preferenza sul pagamento della rata"
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <QuickfiscoInput
                id={'financeInformations-bank-iban'}
                key={'financeInformations-bank-iban'}
                type={'text'}
                styleType={'default'}
                label="IBAN"
                required
                placeholder="Inserisci il tuo iban includendo il prefisso “IT”"
                defaultValue={financeState.userFinanceRequest.data.iban}
                onChange={(e) =>
                  dispatch(setFinanceUserRequestIban(e.target.value))
                }
                onBlur={(e) => financeService.validateBankIban(e.target.value)}
                error={errorBankIban}
                errorLabel="Inserire IBAN"
              />
              <div className="financeInformations-bank-iban-warn">
                <span>ATTENZIONE!</span> L’iban deve essere italiano
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 100,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <QuickfiscoButton
            id={'finance-informations-back-button'}
            key={'finance-informations-back-button'}
            label={'INDIETRO'}
            onClick={() => dispatch(setFinanceStep(0))}
            type={'primary'}
          />
          {financeState.sendFinanceUserStatus === 'loading' ? (
            <QuickfiscoSpinner />
          ) : (
            <QuickfiscoButton
              id={'finance-informations-next-button-6'}
              key={'finance-informations-next-button'}
              label={'CONTINUA'}
              onClick={() => {
                if (
                  financeService.validateInformations(
                    financeState.userFinanceRequest
                  )
                )
                  dispatch(sendFinanceUser(financeState.userFinanceRequest));
              }}
              type={'secondary'}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
    </div>
  );
}

function getPaymentTypeOptions(value?: PaymentPreferenceEnum): OptionModel[] {
  return [
    {
      label: 'Seleziona quando far passare la rata',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Inizio del mese',
      value: PaymentPreferenceEnum.first,
      selected: value === PaymentPreferenceEnum.first,
    },
    {
      label: 'Metà del mese',
      value: PaymentPreferenceEnum.half,
      selected: value === PaymentPreferenceEnum.half,
    },
  ];
}
