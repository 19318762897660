import { DocumentsModel } from "./documentsModel";

export interface MessagesModel {
  id: string;
  type: MessageType;
  objectMessage: string;
  textMessage: string;
  sentDate: Date;
  important: boolean;
  documents?: DocumentsModel[];
  unread: boolean;
}

export enum MessageType {
  WARNING = 'WARNING',
  FULLFILLMENTS = 'FULLFILLMENTS',
  NEWS = 'NEWS',
  HEY = 'HEY'
}