import { getUrlParameter } from '../../utils/url';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { useState } from 'react';
import lang from './confirmationError.json';

export function ConfirmationError() {
  const parameter = getUrlParameter();

  const [wrongTokenOpen, setWrongTokenOpen] = useState(parameter === 'confirm.wrongToken');
  const [internalErrorOpen, setInternalErrorOpen] = useState(parameter === 'confirm.internalError');

  if (wrongTokenOpen) {
    return (
      <QuickfiscoError
        active={true}
        message={lang.wrongTokenError}
        close={() => setWrongTokenOpen(false)}
      />
    );
  } else if (internalErrorOpen) {
    return (
      <QuickfiscoError
        active={true}
        message={lang.internalError}
        close={() => setInternalErrorOpen(false)}
      />
    );
  }

  return null;
}
