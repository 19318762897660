import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setBankAccount, setErrorBankIban, setErrorBankName } from '../../redux/slices/userSlice';
import { ibanRegex } from '../../regex/regex';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import './bankInput.css';
import lang from './bankInput.json';

interface Props {
  error: boolean;
}

export function BankInput(props: Props) {
  const { error } = props;

  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.user);
  const user = userState.editUserRequest;
  const [open, setOpen] = useState(false)

  const [indexSelect, setIndexSelect] = useState(0)

  useEffect(() => {
    if (!user.bankAccount) {
      dispatch(
        setBankAccount([
          {
            name: '',
            iban: '',
            default: true,
          },
        ])
      );
    }
  }, [user.bankAccount]);

  return (
    <div>
      {user.bankAccount?.map((item, index) => (
        <div className="row mt-3 align-items-center" key={index}>
          <div className={'col-auto profile-personal-bank-numeration '}>
            {`Banca 0${index + 1}`}
          </div>
          <div className={'col-auto'}>
            {
              user.bankAccount.length > 1 ?
                <QuickfiscoIcon
                  name={'delete-icon.svg'}
                  className={'cursor-pointer mb-1'}
                  onClick={() => {
                    if ((item.name !== undefined && item.name !== "") && (item.iban !== undefined && item.iban !== "")) {
                      setIndexSelect(index)
                      setOpen(true);
                    } else {
                      const updatedBanks = [...user.bankAccount];
                      updatedBanks.splice(index, 1);
                      dispatch(setBankAccount(updatedBanks));
                      dispatch(setErrorBankName(false));
                      dispatch(setErrorBankIban(false));
                    }
                  }}
                  isButton={true}
                /> :
                <div></div>
            }
          </div>
          <QuickfiscoModal
            isOpen={open}
            hide={() => setOpen(false)}
            modalClassName={'delete-file-modal-open'}
          >
            <div className="px-5">
              <div className={'row mt-4 mb-5'}>
                <div className={'col-12 delete-file-modal-text'}>
                  {"Stai eliminando la seguente banca e non sarà più possibile recuperarla"}
                  <div className="my-4">
                    <i> {` ${user.bankAccount[indexSelect].name} - ${user.bankAccount[indexSelect].iban}`}</i>

                  </div>
                  <strong>{"Sei sicuro di voler proseguire?"}</strong>
                </div>
              </div>
              <div className={'row my-4'}>
                <div className={'col-4'}>
                  <QuickfiscoButton
                    id={'delete-file-close'}
                    type={'primary'}
                    label={"ANNULLA"}
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className={'col-6 offset-2'}>
                  <QuickfiscoButton
                    id={'delete-file-delete-doc'}
                    type={'secondary'}
                    label={"ELIMINA E PROSEGUI"}
                    onClick={() => {
                      setIndexSelect(0)
                      const updatedBanks = [...user.bankAccount];
                      updatedBanks.splice(indexSelect, 1);

                      const atLeastOneTrue = updatedBanks.some(item => item.default);
                      if (!atLeastOneTrue && updatedBanks.length > 0) {
                        updatedBanks[0] = { ...updatedBanks[0], default: true };
                      }

                      dispatch(setBankAccount([...updatedBanks]));
                      setOpen(false);
                    }}

                  />
                </div>
              </div>
            </div>
          </QuickfiscoModal>
          <div className="row mt-3" >
            <div className="col-6">
              <QuickfiscoInput
                id={`profile-personal-informations-bank-name-${index}`}
                label={lang.placeholder1}
                styleType={'default'}
                type="text"
                placeholder={lang.placeholder1}
                onChange={e => {
                  const newBank = [...user.bankAccount];
                  newBank[index] = {
                    ...user.bankAccount[index],
                    name: e.target.value
                  };
                  dispatch(setBankAccount([
                    ...newBank,
                  ]));
                }}
                error={
                  error && (user.bankAccount[index].name === "" || user.bankAccount[index].name === undefined)
                }
                errorLabel={lang.bankNotValid}
                value={item.name || ''}
              />
            </div>
            <div className="col-6">
              <QuickfiscoInput
                id={`profile-personal-informations-bank-iban-${index}`}
                label={lang.placeholder2}
                styleType={'default'}
                type="text"
                placeholder={lang.placeholder2}
                onChange={(e) => {
                  const newBank = [...user.bankAccount];
                  newBank[index] = {
                    ...user.bankAccount[index],
                    iban: e.target.value
                  };
                  dispatch(setBankAccount([
                    ...newBank,
                  ]));
                }}
                value={item.iban || ''}
                error={
                  error && (user.bankAccount[index].iban === "" ||
                    !ibanRegex.test(user.bankAccount[index].iban))
                }
                errorLabel={lang.bankNotValid}
              />
            </div>
            <QuickfiscoCheckbox
              id={`profile-personal-informations-bank-default-${index}`}
              label='Imposta come banca predefinita'
              checked={item.default}
              onChange={(e) => {
                const updatedBank = user.bankAccount.map((bankItem, idx) => ({
                  ...bankItem,
                  default: idx === index ? e.target.checked : false
                }));
                const atLeastOneTrue = updatedBank.some(item => item.default);
                if (!atLeastOneTrue) {
                  return;
                }
                dispatch(setBankAccount(updatedBank));
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
