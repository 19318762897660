import { isAfter } from 'date-fns';
import { ContactModel } from '../models/contactModel';
import { InvoiceFilters } from '../models/invoiceFilters';
import { InvoiceModel, InvoiceStatusType } from '../models/invoiceModel';
import { PaginationModel } from '../models/paginationModel';
import { SupplierInvoiceErrorSetters } from '../models/supplierInvoiceErrorSetters';
import { host } from '../utils/config';
import { getDateRangeByYearAndMonth, removeTime } from '../utils/date';
import { CommonInvoiceService } from './commonInvoiceService';

export class InvoiceSupplierService extends CommonInvoiceService {

  private readonly supplierInvoiceErrorSetters?: SupplierInvoiceErrorSetters;

  public constructor(errorSetters?: SupplierInvoiceErrorSetters) {
    super(host + '/api/v1/invoices/supplier');

    this.supplierInvoiceErrorSetters = errorSetters;
  }

  public static isEditable(invoice: InvoiceModel): boolean {
    return (invoice.status === InvoiceStatusType.RECEIVED || invoice.status === InvoiceStatusType.PAYMENT_DUE) && invoice.manual === true
  }

  public findAll(filters: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> {
    const {
      dateFrom,
      dateTo
    } = getDateRangeByYearAndMonth(filters.year, filters.month);

    return this.http.sendAndReceive({
      method: 'get',
      url: this.url +
        '?fromDate=' + dateFrom +
        '&toDate=' + dateTo +
        (filters.status !== undefined ? '&status=' + filters.status : '')
    })
      .then(res => res.json())
      .then((data: PaginationModel<InvoiceModel>) => data);
  }

  public async findById(id: string): Promise<{ invoice: InvoiceModel, files: File[] }> {
    let files: File[] = []

    let invoice = await this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/' + id
    })
      .then(res => res.json())
      .then((data: InvoiceModel) => data)

    if (invoice.documents !== undefined && invoice.documents !== null) {
      files = await super.getFiles(invoice.documents);
    }

    return {
      invoice,
      files
    };
  }

  public validate(invoice: InvoiceModel): boolean {
    let isValid = true;

    if (!this.validateSupplier(invoice.supplier)) {
      isValid = false;
    }
    if (!this.validateDate(invoice.date)) {
      isValid = false;
    }
    if (!this.validateAmount(invoice.amount)) {
      isValid = false;
    }
    if (!this.validatePaymentExpiration(invoice.date, invoice.paymentExpiration)) {
      isValid = false;
    }

    return isValid;
  }

  public validateSupplier(supplier?: ContactModel): boolean {
    this.supplierInvoiceErrorSetters?.setErrorSupplier(false);

    if (supplier === undefined) {
      this.supplierInvoiceErrorSetters?.setErrorSupplier(true);
      return false;
    }

    return true;
  }

  public validateDate(date?: Date): boolean {
    this.supplierInvoiceErrorSetters?.setErrorDate(false);

    if (!date) {
      this.supplierInvoiceErrorSetters?.setErrorDate(true);
      return false;
    }

    return true;
  }

  public validateAmount(amount?: number | null): boolean {
    this.supplierInvoiceErrorSetters?.setErrorAmount(false);

    if (amount === undefined || amount === null || amount === 0) {
      this.supplierInvoiceErrorSetters?.setErrorAmount(true);
      return false;
    }

    return true;
  }

  public validatePaymentExpiration(date: Date, paymentExpiration?: Date): boolean {
    this.supplierInvoiceErrorSetters?.setErrorPaymentExpiration(false);

    if (!paymentExpiration || isAfter(removeTime(date), removeTime(paymentExpiration))) {
      this.supplierInvoiceErrorSetters?.setErrorPaymentExpiration(true);
      return false;
    }

    return true;
  }

}
