import { PromiseStatuses } from '../../types/strings';
import { UserCategory } from '../incomeUploadModal/incomeUploadModal';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './incomeUploadModalFinalStep.css';

interface Props {
  userCategory: UserCategory;
  status: PromiseStatuses;
  closeModal: () => void;
}

export function IncomeUploadModalFinalStep(props: Props) {
  const { userCategory } = props;
  const { status } = props;
  const { closeModal } = props;

  return (
    <>
      <div className={'row pb-4'}>
        <div className={'col-4 offset-4 d-flex justify-content-center'}>
          <QuickfiscoIcon
            name={
              status === 'successfully'
                ? 'alert-success.svg'
                : 'alert-yellow.svg'
            }
            className={status === 'successfully' ? 'w-25' : ''}
          />
        </div>
      </div>
      {status === 'successfully' ? (
        <div className={'row px-4 pb-5'}>
          <div className={'col-12 text-center'}>
            <div className={'income-upload-modal-final-step-text'}>
              <b>Invio avvenuto con successo!</b>
              <br />
              Ora possiamo iniziare a lavorare alla tua
              <br />
              dichiarazione dei redditi.
              <br />
              <br />
              {userCategory !== UserCategory.ALL && (
                <div className={'income-upload-modal-final-step-text-optional'}>
                  <span className={'income-upload-modal-final-step-text-blue'}>
                    <b>E adesso?</b>
                  </span>
                  <br />
                  <b>Verificheremo i tuoi incassi</b>. Potresti ricevere delle
                  richieste di approfondimento da un nostro consulente per
                  eventuali dubbi sugli incassi.
                  <br />
                  <br />
                  <span className={'income-upload-modal-final-step-text-blue'}>
                    <b>Perchè questa eventuale verifica?</b>
                  </span>
                  <br />
                  Lo facciamo nel tuo interesse perchè vogliamo essere sicuri di
                  dichiarare l’importo corretto dei compensi affinchè tu possa
                  non avere problemi in futuro.
                  <br />
                  <br />
                </div>
              )}
              <span className={'income-upload-modal-final-step-text-small'}>
                <b>Grazie per la tua collaborazione!</b>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className={'row px-4 pb-5'}>
          <div className={'col-12 text-center'}>
            <div className={'income-upload-modal-final-step-text'}>
              <b>Invio non avvenuto con successo!</b>
              <br />
              <br />
              Si prega di ripetere la procedura e reinviare i file
            </div>
          </div>
        </div>
      )}
      <div className={'row pb-5 d-flex justify-content-center'}>
        <div className={'col-6'}>
          <QuickfiscoButton
            id={'income-upload-modal-intro-button'}
            label={'CHIUDI'}
            type={'secondary'}
            onClick={() => closeModal()}
          />
        </div>
      </div>
    </>
  );
}
