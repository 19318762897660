import { InvoiceErrorSetters } from "../models/invoiceErrorSetters";
import { v4 as uiidv4 } from 'uuid';
import { InvoiceFilters } from "../models/invoiceFilters";
import { InvoiceModel, InvoiceStatusType, ReimbursementOfExpenseModel } from "../models/invoiceModel";
import { PaginationModel } from "../models/paginationModel";
import { host } from "../utils/config";
import { getDateRangeByYearAndMonth } from "../utils/date";
import { CommonInvoiceService } from "./commonInvoiceService";

export class ProformaInvoiceService extends CommonInvoiceService {

    public constructor(errorSetters?: InvoiceErrorSetters) {
        super(host + '/api/v1/invoices/proforma-invoice', errorSetters);
    }

    public static isSendable(invoice: InvoiceModel): boolean {
        return invoice.status === InvoiceStatusType.DRAFT;
    }

    public findAll(filters: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> {
        const {
            dateFrom,
            dateTo
        } = getDateRangeByYearAndMonth(filters.year, filters.month);

        return this.http.sendAndReceive({
            method: 'get',
            url: this.url +
                '?fromDate=' + dateFrom +
                '&toDate=' + dateTo +
                (filters.type !== undefined ? '&type=' + filters.type : '') +
                (filters.proformaStatus !== undefined ? '&status=' + filters.proformaStatus : '')
        })
            .then(res => res.json())
            .then((data: PaginationModel<InvoiceModel>) => data);
    }

    public async findById(id?: string): Promise<{ invoice: InvoiceModel, files: File[], reimbursementsOfExpenses: ReimbursementOfExpenseModel[] }> {
        let files: File[] = [];
        const reimbursementsOfExpenses: ReimbursementOfExpenseModel[] = [];

        let invoice = await this.http.sendAndReceive({
            method: 'get',
            url: this.url + '/' + id
        })
            .then(res => res.json())
            .then((data: InvoiceModel) => data);

        invoice = CommonInvoiceService.setGoodServiceKey(invoice);

        if (invoice.documents !== undefined && invoice.documents !== null) {
            files = await super.getFiles(invoice.documents);
        }

        if (invoice.reimbursementsOfExpenses !== undefined && invoice.reimbursementsOfExpenses !== null) {
            for (let reimbursementOfExpenses of invoice.reimbursementsOfExpenses) {
                if (reimbursementOfExpenses.documentId) {
                    const reimbursementOfExpensesFile = await super.getFiles([reimbursementOfExpenses.documentId]);
          
                    reimbursementsOfExpenses.push({
                      key: uiidv4(),
                      description: reimbursementOfExpenses.description,
                      amount: reimbursementOfExpenses.amount,
                      file: reimbursementOfExpensesFile[0]
                    });
                } else {
                    reimbursementsOfExpenses.push({
                      key: uiidv4(),
                      description: reimbursementOfExpenses.description,
                      amount: reimbursementOfExpenses.amount
                    });
                  }
            }
        }

        return {
            invoice,
            files,
            reimbursementsOfExpenses
        };
    }

    public send(id: string): Promise<void> {
        return this.http.sendAndReceive({
            method: 'post',
            url: this.url + '/' + id + '/send'
        })
            .then(() => undefined);
    }
}