import { useNavigate } from 'react-router-dom';
import uri from '../../routers/quickfiscoUri.json';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import config from '../../../config.json';
import './passwordChanged.css';
import lang from './passwordChanged.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function PasswordChanged(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const navigate = useNavigate();

  return (
    <QuickfiscoModal hide={() => setOpen(false)} isOpen={open}>
      <div className={'p-5 pt-0 text-center'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <QuickfiscoIcon name={'alert-success.svg'} />
            <div className={'password-changed-title my-4'}>{lang.title}</div>
            <div className={'password-changed-subtitle my-4'}>
              {lang.subtitle}
            </div>
          </div>
        </div>
        <div className={'row my-3'}>
          <div className={'col-4 offset-4'}>
            <QuickfiscoButton
              id={'password-changed'}
              label={lang.label}
              onClick={() => navigate(uri.Login)}
              type={'secondary'}
            />
          </div>
        </div>
        <div className={'row mt-5 mb-3'}>
          <div className={'col-8 offset-2'}>
            <div className={'passsword-recovery-info'}>
              {lang.info}{' '}
              <a
                className={'password-recovery-link'}
                href={`mailto:${config.mail}`}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {lang.link}
              </a>
              {lang.info2}
              <a
                href={config.whatsappLink}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {lang.link2}
              </a>
            </div>
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}
