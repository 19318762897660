import { host } from '../utils/config';
import { Http } from '../utils/http';

export class ContractSignatureService {

  private readonly url = host + '/api/v1/contract-signature';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public async getSignatureLink(): Promise<string> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/yousign'
    })
      .then(res => res.json())
      .then((urlIframe: string) => urlIframe);
  }

}
