import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComponentVisibleOutput } from '../../hooks/componentVisible';
import { ContactModel, ContactType } from '../../models/contactModel';
import { useAppDispatch } from '../../redux/hooks';
import { getCustomerList } from '../../redux/slices/customerListSlice';
import { getSupplierList } from '../../redux/slices/supplierListSlice';
import uri from '../../routers/quickfiscoUri.json';
import { ContactService } from '../../services/contactService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './contactMenu.css';
import lang from './contactMenu.json';

interface Props {
  contact: ContactModel
  componentVisible: ComponentVisibleOutput;
}

export function ContactMenu(props: Props) {
  const { contact } = props;
  const { componentVisible } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<PromiseStatuses>('idle');

  let label1: string;
  let label2: string;
  let action1: () => void;
  let action2: () => void = () => del(contact, componentVisible, dispatch, setStatus);

  if (contact.type === ContactType.CUSTOMER) {
    label1 = lang.CustomerLabel1;
    label2 = lang.CustomerLabel2;
    action1 = () => {
      componentVisible.setIsVisible(false);

      navigate(uri.CustomerEdit + '/' + contact.id);
    };
  } else {
    label1 = lang.SupplierLabel1;
    label2 = lang.SupplierLabel2;
    action1 = () => {
      componentVisible.setIsVisible(false);
      navigate(uri.SupplierEdit + '/' + contact.id);
    };
  }

  if (!componentVisible.isVisible) {
    return null;
  }

  return (
    <div className={'contactMenu-container'}>
      <QuickfiscoError
        active={status === 'failed'}
        message={lang.DeletionError}
        close={() => setStatus('idle')}
      />
      <div className={'contactMenu-item'} onClick={() => action1()}>
        <div className={'col-auto contactMenu-icon'}>
          <QuickfiscoIcon name={'find.svg'} />
        </div>
        {label1}
      </div>
      <div className={'contactMenu-line mt-2 mb-2'} />
      {
        status === 'loading' ? (
          <QuickfiscoSpinner alignCenter={true} />
        ) : (
          <div className={'contactMenu-item'} onClick={() => action2()}>
            <div className={'col-auto contactMenu-icon'}>
              <QuickfiscoIcon name={'delete-icon.svg'} />
            </div>
            {label2}
          </div>
        )
      }
    </div>
  );
}

function del(
  contact: ContactModel,
  componentVisible: ComponentVisibleOutput,
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>
) {
  const contactId = contact.id

  if (contactId === undefined) {
    return
  }

  const service = new ContactService();

  setStatus('loading');
  service
    .del(contactId)
    .then(() => {
      componentVisible.setIsVisible(false);

      if (contact.type === ContactType.CUSTOMER) {
        dispatch(getCustomerList())
      } else {
        dispatch(getSupplierList())
      }
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}
