import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { host } from '../../utils/config';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { UserCategory } from '../incomeUploadModal/incomeUploadModal';
import config from '../../../config.json';
import lang from './incomeUploadModalStep3.json';
import './incomeUploadModalStep3.css';

interface Props {
  qfUsedFlag: boolean | undefined;
  userCategory: UserCategory;
  totalInvoicesPaidLastYear: number;
  onClickPrevStep: () => void;
  onClickNextStep: () => void;
  onClickSend: () => void;
  setFiles: (_file: File[]) => void;
  status: PromiseStatuses;
  errorFile: boolean;
  files?: File[];
}

export function IncomeUploadModalStep3(props: Props) {
  const { qfUsedFlag } = props;
  const { userCategory } = props;
  const { onClickPrevStep } = props;
  const { onClickNextStep } = props;
  const { totalInvoicesPaidLastYear } = props;
  const { onClickSend } = props;
  const { files } = props;
  const { setFiles } = props;
  const { status } = props;
  const { errorFile } = props;

  return (
    <>
      {qfUsedFlag === true ? (
        <>
          <div className={'row'}>
            <div className={'col-12 income-upload-modal-step3-subtitle'}>
              {lang.subTitleYes}
            </div>
          </div>
          <div className={'row my-4'}>
            <div className={'col-12'}>
              <div className="row mt-1 mb-4">
                <div className="col-4 offset-4">
                  <a
                    href={
                      host +
                      '/api/v1/invoices/customer/excel?year=' +
                      (new Date().getFullYear() - 1)
                    }
                    rel={'noreferrer'}
                  >
                    <QuickfiscoButton
                      id={'tax-declaration-procedure'}
                      label={`DOWNLOAD EXCEL`}
                      type={'primary'}
                    />
                  </a>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-12 income-upload-modal-step3-text'}>
                  <span className={'income-upload-modal-step3-text-blue'}>
                    Se <b>hai utilizzato Quickfisco</b> per emettere tutte le
                    tue fatture nel 2023
                  </span>{' '}
                  <b>scarica il file excel dal pulsante sopra.</b>
                </div>
              </div>
              <div className={'row my-3'}>
                <div className={'col-12 income-upload-modal-step3-text-small'}>
                  Apri il file e{' '}
                  <b>verifica che siano presenti tutti gli incassi nel 2023</b>.
                  Presta attenzione ad
                  <b>
                    {' '}
                    eventuali fatture emesse in anni precedenti ma incassate nel
                    2023
                  </b>
                  , devi segnalare nel file aggiungendole nelle prime righe
                  disponibili.
                </div>
              </div>
              {totalInvoicesPaidLastYear > 0 && (
                <div className={'row my-3'}>
                  <div className={'col-12'}>
                    <div className="income-upload-modal-step3-box p-4">
                      Ci risulta che{' '}
                      <b>
                        nel 2023 hai incassato fatture emesse negli anni
                        precedenti
                      </b>{' '}
                      per un totale di{' '}
                      <b>
                        {formatNumberIT(totalInvoicesPaidLastYear)}
                        {config.EUR}
                      </b>
                      . Ricorda di inserire questi importi nel file excel prima
                      di inviarlo.
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={'row no-gutters my-4'}>
            <div className={'col-3 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step3-button'}
                label={lang.step3ButtonPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            <div className={'col-3 offset-6 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step3-button'}
                label={lang.step3buttonNext}
                type={qfUsedFlag !== undefined ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep()}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={'row'}>
            <div className={'col-12 income-upload-modal-step3-subtitle'}>
              {lang.subTitleNo}
            </div>
          </div>
          <div className={'row my-3'}>
            <div
              className={
                'col-10 offset-1 text-center income-upload-modal-step3-text'
              }
            >
              Carica il file excel secondo le istruzioni richieste nello step
              precedente
            </div>
          </div>
          <div className={'row mb-5'}>
            <div className={'col-8 offset-2 text-center'}>
              <QuickfiscoInputFile
                preview={true}
                multiple={false}
                maxHeight={'100px'}
                fileList={files ? files : []}
                setFileList={(_file) => setFiles([..._file])}
              />
            </div>
          </div>
          {((files?.length === 1 && userCategory === UserCategory.TRADER) ||
            (files?.length === 1 &&
              userCategory === UserCategory.TRADER479119)) && (
            <>
              <div className={'row pb-4'}>
                <div className={'col-4 offset-4 d-flex justify-content-center'}>
                  <QuickfiscoIcon name={'alert-success.svg'} className="w-25" />
                </div>
              </div>
              <div className={'row pb-4'}>
                <div className={'col-12 text-center'}>
                  <div className={'income-upload-modal-step3-text-green'}>
                    File caricato correttamente
                  </div>
                </div>
              </div>
            </>
          )}
          {files?.length === 1 && userCategory === UserCategory.ALL && (
            <>
              <div className={'row pb-4'}>
                <div className={'col-4 offset-4 d-flex justify-content-center'}>
                  <QuickfiscoIcon name={'alert-success.svg'} className="w-25" />
                </div>
              </div>
              <div className={'row pb-4'}>
                <div className={'col-12 text-center'}>
                  <div className={'income-upload-modal-step3-text-text'}>
                    <span className={'income-upload-modal-step3-text-blue'}>
                      <b>E adesso?</b>
                    </span>
                    <br />
                    <b>Verificheremo i tuoi incassi</b>. Potresti ricevere delle
                    richieste di approfondimento da un nostro consulente per
                    eventuali dubbi sugli incassi.
                    <br />
                    <br />
                    <span className={'income-upload-modal-step3-text-blue'}>
                      <b>Perchè questa eventuale verifica?</b>
                    </span>
                    <br />
                    Lo facciamo nel tuo interesse perchè vogliamo essere sicuri
                    di dichiarare l’importo corretto dei compensi affinchè tu
                    possa non avere problemi in futuro.
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={'row no-gutters my-4'}>
            <div className={'col-3 d-flex justify-content-center'}>
              <QuickfiscoButton
                id={'income-upload-modal-step3-button'}
                label={lang.step3ButtonPrev}
                type={'primary'}
                onClick={() => onClickPrevStep()}
              />
            </div>
            {userCategory === UserCategory.TRADER ||
            userCategory === UserCategory.TRADER479119 ? (
              <div className={'col-3 offset-6 d-flex justify-content-center'}>
                <QuickfiscoButton
                  id={'income-upload-modal-step3-button'}
                  label={lang.step3buttonNext}
                  type={files?.length === 1 ? 'secondary' : 'disabled'}
                  onClick={() => onClickNextStep()}
                />
              </div>
            ) : (
              <div className={'col-3 offset-6 d-flex justify-content-center'}>
                {status === 'loading' ? (
                  <div
                    className={
                      'd-flex justify-content-center align-items-center w-100'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'income-upload-modal-step3-button'}
                    label={lang.step3buttonSend}
                    type={files?.length === 1 ? 'secondary' : 'disabled'}
                    onClick={() => onClickSend()}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
      {errorFile && (
        <div className={'row my-4'}>
          <div className={'col-8 offset-2 income-upload-modal-step3-error'}>
            {lang.errorFile}
          </div>
        </div>
      )}
    </>
  );
}
