import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import lang from './taxReturnSection9AcceptToContinue.json';
import './taxReturnSection9AcceptToContinue.css';

interface Props {
  onChange: (checked: boolean) => void;
  isChecked?: boolean;
}

export function TaxReturnSection9AcceptToContinue(props: Props) {
  const { onChange } = props;
  const { isChecked } = props;

  return (
    <>
      <div className="row no-gutters mt-5">
        <div className="col-12 tax-return-section-9-accept-to-continue-title">
          {lang.title}
        </div>
      </div>
      <div
        className={
          'tax-return-section-9-accept-to-continue-container-text mt-3 p-4'
        }
      >
        <div className="row no-gutters">
          <div className="col-12 tax-return-section-9-accept-to-continue-description">
            Sono stato informato degli obblighi dichiarativi delle persone
            fisiche residenti in Italia che detengono investimenti all’estero e
            attività estere di natura finanziaria a titolo di proprietà o a
            altro diritto reale e, in ogni caso, ai fini dell’imposta sul valore
            degli immobili all’estero (IVIE) e dell’imposta sul valore delle
            attività finanziarie all’estero (IVAFE) che determinano obblighi ai
            fini del monitoraggio delle attività estere con conseguente
            compilazione e presentazione del modello RW di Redditi; versamento
            dell’imposta su immobili e attività detenute all’estero ai sensi
            dell’art. 19, commi da 13 a 22 del DL 201/2011 e s.m.i. (imposta da
            liquidare con la compilazione del modello RW) e versamento
            dell’IRPEF e relative addizionali sugli eventuali redditi derivanti
            dai predetti investimenti mobiliari o immobiliari;
            <div className="mt-3">
              <b>Pertanto dichiaro</b>
            </div>
            <ul className="ps-3">
              <li>
                di possedere uno o più dei redditi e/o investimenti sopra
                elencati e quindi di essere a conoscenza dell’obbligo di
                compilare e presentare il modello RW di Redditi; di essere
                inoltre consapevole che dalla compilazione del modello RW
                potrebbero scaturire imposte da versare nei termini del saldo
                della dichiarazione dei redditi relativa all’anno d’imposta
                2023.
              </li>
              <li>
                di avere ricevuto tutte le informazioni in riferimento ai
                suddetti redditi e/o investimenti esteri e di sollevare la
                società QUICKFISCO SRL e l’operatore in ordine ad eventuali
                future contestazioni da parte dell’Amministrazione Finanziaria.
              </li>
            </ul>
          </div>
        </div>
        <div className="row no-gutters d-flex justify-content-center mt-3">
          <div className="col-auto">
            <QuickfiscoCheckbox
              id={'tax-return-section-9-accept-to-continue-answer'}
              required={true}
              label={lang.accept}
              checked={isChecked === true}
              onChange={(e) => onChange(e.target.checked)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
