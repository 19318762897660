import { useEffect, useState } from "react";
import config from "../../../config.json";
import {
  ExpenseType,
  ExpenseTypeFlag,
  ProfileStatus,
  StsProfileModel,
} from "../../models/stsProfileModel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getStsProfile } from "../../redux/slices/stsProfileSlice";
import { StsProfileService } from "../../services/stsProfileService";
import { SetState } from "../../types/functions";
import { PromiseStatuses } from "../../types/strings";
import { QuickfiscoButton } from "../quickfiscoButton/quickfiscoButton";
import { QuickfiscoError } from "../quickfiscoError/quickfiscoError";
import { QuickfiscoInput } from "../quickfiscoInput/quickfiscoInput";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { QuickfiscoSuccess } from "../quickfiscoSuccess/quickfiscoSuccess";
import { TrackedPaymentSelect } from "../trackedPaymentSelect/trackedPaymentSelect";
import "./stsProfileEdit.css";
import lang from "./stsProfileEdit.json";
import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";
import { UserService } from "../../services/userService";
import {
  getItemWithExipiration,
  setItemWithExipiration,
} from "../../utils/localStorage";

interface SetterFunctions {
  setPassword: SetState<string | undefined>;
  setPincode: SetState<string | undefined>;
  setExpenseType: SetState<ExpenseType | undefined>;
  setExpenseTypeFlag: SetState<ExpenseTypeFlag | undefined>;
  setTrackedPayment: SetState<boolean>;
  setIdentificationCode: SetState<string | undefined>;
}

export function StsProfileEdit() {
  const [status, setStatus] = useState<PromiseStatuses>("idle");
  const [statusImport, setStatusImport] = useState<PromiseStatuses>("idle");

  const [pincode, setPincode] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [trackedPayment, setTrackedPayment] = useState<boolean>(false);
  const [expenseType, setExpenseType] = useState<ExpenseType | undefined>(
    undefined
  );
  const [expenseTypeFlag, setExpenseTypeFlag] = useState<
    ExpenseTypeFlag | undefined
  >(undefined);
  const [identificationCode, setIdentificationCode] = useState<
    string | undefined
  >(undefined);

  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user.user);
  const profile = useAppSelector((state) => state.stsProfile.profile);
  const profileStatus = useAppSelector((state) => state.stsProfile.status);
  const [checkCredentialError, setCheckCredentialError] = useState<string>("");

  const loggedIn = useAppSelector((state) => state.login.loggedIn);

  const dispatch = useAppDispatch();

  const stsProfileService = new StsProfileService();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getStsProfile());
    }
  }, [loggedIn]);

  useEffect(() => {
    if (profile !== undefined) {
      setDefaultValues(
        profile,
        {
          setPassword,
          setPincode,
          setExpenseType,
          setExpenseTypeFlag,
          setTrackedPayment,
          setIdentificationCode,
        },
        user?.taxCode
      );
    }
  }, [profile]);

  if (profileStatus === "failed") {
    return (
      <div
        className={
          "h-100 w-100 d-flex align-items-center justify-content-center"
        }
      >
        Errore durante il caricamento del profilo sts.
      </div>
    );
  }

  if (profileStatus === "loading") {
    return (
      <div
        className={
          "h-100 w-100 d-flex align-items-center justify-content-center"
        }
      >
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className={"sts-profile-edit-container box-shadow"}>
      <QuickfiscoSuccess
        active={status === "successfully"}
        message={lang.successMessage}
        close={() => setStatus("idle")}
      />
      <QuickfiscoError
        active={status === "failed"}
        message={lang.errorMessage}
        close={() => setStatus("idle")}
      />
      <QuickfiscoError
        active={statusImport === "failed"}
        message={lang.savingErrorImport}
        close={() => setStatus("idle")}
      />
      <QuickfiscoSuccess
        active={statusImport === "successfully"}
        message={lang.savingSuccessImport}
        close={() => setStatus("idle")}
      />
      <div className={"row"}>
        <div className={"col-6"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <b className={"sts-profile-edit-title"}>{lang.stsTitle}</b>
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <QuickfiscoInput
                id="sts-profile-edit-identification-code-input"
                type={"text"}
                styleType={"default"}
                label={lang.identificationCode}
                required={true}
                value={identificationCode || ""}
                placeholder={lang.identificationCodePlaceholder}
                onChange={(e) => {
                  setIdentificationCode(e.target.value);
                  stsProfileService.validateIdentificationCode(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <QuickfiscoInput
                id="sts-profile-edit-password-input"
                type={"password"}
                styleType={"default"}
                label={lang.password}
                value={password || ""}
                onChange={(e) => {
                  setPassword(e.target.value);
                  stsProfileService.validatePassword(e.target.value);
                }}
                placeholder={lang.passwordPlaceholder}
              />
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <QuickfiscoInput
                id="sts-profile-edit-pincode-input"
                type={"text"}
                label={lang.pincode}
                value={pincode || ""}
                styleType={"default"}
                onChange={(e) => {
                  setPincode(e.target.value);
                  stsProfileService.validatePincode(e.target.value);
                }}
                placeholder={lang.pincodePlaceholder}
              />
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <QuickfiscoInput
                id="sts-profile-edit-taxcode-input"
                type={"text"}
                label={lang.taxCode}
                value={user?.taxCode || ""}
                styleType={"default"}
                disabled={true}
              />
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <p className={"sts-profile-edit-gray-text"}>
                {lang.identificationCodeInfo.line1}
              </p>
              <p className={"sts-profile-edit-gray-text"}>
                {lang.identificationCodeInfo.line2}
                <a
                  className={"sts-profile-edit-blue-text"}
                  href={config.officialOperatingInstructions}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {lang.identificationCodeInfo.line3}
                </a>
              </p>
            </div>
          </div>
          <div className={"mt-5"}>
            <div className={"col-12"}>
              <div className={"sts-profile-edit-blue-container"}>
                <p className={"sts-profile-edit-gray-text text-center"}>
                  {lang.stsInfo.line1}
                  <a
                    className={"sts-profile-edit-blue-text"}
                    href={config.sts}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {lang.stsInfo.line2}
                  </a>
                  {lang.stsInfo.line3}
                </p>
                <p className={"sts-profile-edit-gray-text text-center mb-0"}>
                  {lang.stsInfo.line4}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-6"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <b className={"sts-profile-edit-title"}>{lang.stsExpense}</b>
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <QuickfiscoInput
                id="sts-profile-edit-vat-input"
                type={"text"}
                label={lang.vat}
                value={user?.vat || ""}
                styleType={"default"}
                disabled={true}
              />
            </div>
          </div>
          <div className={"row mt-4"}>
            <div className={"col-12"}>
              <TrackedPaymentSelect
                id={"sts-profile-edit-expense"}
                value={trackedPayment === true ? "SI" : "NO"}
                onChange={(_trackedPayment) => {
                  setTrackedPayment(_trackedPayment === "SI");
                }}
              />
            </div>
          </div>
          <div className={"row mt-5"}>
            <div className={"col-12"}>
              <div className={"row"}>
                <div className={"col-12"}>
                  {status === "loading" ? (
                    <div
                      className={
                        "w-100 d-flex justify-content-center align-items-center"
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={"sts-profile-edit-save"}
                      label={lang.buttonLabel}
                      type={
                        !stsProfileService.validate({
                          identificationCode: identificationCode,
                          password,
                          pinCode: pincode,
                          expenseType,
                          expenseTypeFlag,
                          trackedPayment,
                        })
                          ? "disabled"
                          : "secondary"
                      }
                      onClick={() =>
                        updateProfile(
                          dispatch,
                          setStatus,
                          {
                            identificationCode: identificationCode,
                            password,
                            pinCode: pincode,
                            expenseType,
                            expenseTypeFlag,
                            trackedPayment,
                          },
                          setCheckCredentialError
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                (profile?.status === ProfileStatus.ACTIVE
                  ? "sts-profile-edit-access-green"
                  : "sts-profile-edit-access-red") + " my-4"
              }
            >
              {profile?.status === ProfileStatus.ACTIVE
                ? lang.loginActive
                : lang.loginInactive}
            </div>
            <div
              className={
                (profile?.status === ProfileStatus.ACTIVE
                  ? "sts-profile-edit-access-green"
                  : "sts-profile-edit-access-red")
              }
            >
              {checkCredentialError}
            </div>
          </div>
          {user.importInvoicesSts?.activationSts && user.stsEnabled && (
            <div
              className="d-flex flex-column justify-content-end"
              style={profile?.status !== ProfileStatus.ACTIVE?{ height: "45%" }:{ height: "50%" }}
            >
              <div className="sts-profile-edit-box text-center my-3 p-4">
                <QuickfiscoIcon
                  name={"ionic-ios-alert-sts-profile.svg"}
                  className="mb-3"
                />
                <div className={"sts-profile-edit-box-import-title"}>
                  {lang.importMessage}
                </div>
                {statusImport === "loading" ? (
                  <div className="row mt-4">
                    <div
                      className={
                        "col-12 d-flex align-items-center justify-content-center"
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <div className="col-8 offset-3 mb-4 w-50">
                      <QuickfiscoButton
                        id={"invoice-import-sts-box-cta-btn"}
                        label={lang.ImportButton}
                        onClick={() =>
                          sendToSupportImportInvoiceMail(
                            setStatusImport,
                            setDisabledButton
                          )
                        }
                        type={
                          disabledButton ||
                          getItemWithExipiration(
                            "stopSendSupportImportInvoiceMail"
                          ) === "true"
                            ? "disabled"
                            : "ninth"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function sendToSupportImportInvoiceMail(
  setStatusImport: React.Dispatch<React.SetStateAction<PromiseStatuses>>,
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>
) {
  const userService = new UserService();

  setStatusImport("loading");
  userService
    .sendToSupportImportInvoiceMail()
    .then(() => {
      setStatusImport("successfully");
      setItemWithExipiration("stopSendSupportImportInvoiceMail", {
        value: "true",
        expireTime: Date.now() + 1000 * 60 * 60 * 24 * 50,
      });
      setDisabledButton(true);
    })
    .catch((err) => {
      setStatusImport("failed");
      console.error(err);
    });
}

function setDefaultValues(
  stsProfile: StsProfileModel,
  setterFunctions: SetterFunctions,
  taxCode?: string
) {
  setterFunctions.setPassword(stsProfile.password);
  setterFunctions.setPincode(stsProfile.pinCode);
  setterFunctions.setExpenseType(stsProfile.expenseType);
  setterFunctions.setExpenseTypeFlag(stsProfile.expenseTypeFlag);
  setterFunctions.setTrackedPayment(stsProfile.trackedPayment);
  setterFunctions.setIdentificationCode(
    stsProfile.identificationCode === undefined ||
      stsProfile.identificationCode === ""
      ? taxCode
      : stsProfile.identificationCode
  );
}

function updateProfile(
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  profile: StsProfileModel,
  setCheckCredentialError: SetState<string>
) {
  const stsProfileService = new StsProfileService();

  setStatus("loading");
  stsProfileService
    .edit(profile)
    .then((errorMsg) => {
      setStatus("successfully");
      setCheckCredentialError(errorMsg);
      dispatch(getStsProfile());
    })
    .catch((err) => {
      setStatus("failed");
      console.error(err);
      dispatch(getStsProfile());
    });
}
