import { useEffect, useState } from 'react';
import { TotalRevenuesModel } from '../../models/totalRevenuesModel';
import { CategoryType } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMe } from '../../redux/slices/userSlice';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { FeesRegisterService } from '../../services/feesRegisterService';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { validateFiles } from '../../utils/file';
import { getItem, setItem } from '../../utils/localStorage';
import { sanitizeString } from '../../utils/string';
import { isEmptyValue } from '../../utils/values';
import { IncomeUploadModalFinalStep } from '../incomeUploadModalFinalStep/incomeUploadModalFinalStep';
import { IncomeUploadModalIntro } from '../incomeUploadModalIntro/incomeUploadModalIntro';
import { IncomeUploadModalStep1 } from '../incomeUploadModalStep1/incomeUploadModalStep1';
import { IncomeUploadModalStep2 } from '../incomeUploadModalStep2/incomeUploadModalStep2';
import { IncomeUploadModalStep3 } from '../incomeUploadModalStep3/incomeUploadModalStep3';
import { IncomeUploadModalStep4 } from '../incomeUploadModalStep4/incomeUploadModalStep4';
import { IncomeUploadModalStep5 } from '../incomeUploadModalStep5/incomeUploadModalStep5';
import { ProgressBar } from '../progressBar/progressBar';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { useLocation } from 'react-router-dom';
import './incomeUploadModal.css';
import lang from './incomeUploadModal.json';

export enum UserCategory {
  ALL = 'ALL',
  TRADER = 'TRADER',
  TRADER479119 = 'TRADER479119',
}

export function IncomeUploadModal() {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [numberOfSteps, setNumberOfSteps] = useState<number>(2);
  const [qfUsedFlag, setQfUsedFlag] = useState<boolean | undefined>(undefined);
  const [telematicFeesRegister, setTelematicFeesRegister] = useState<
    boolean | undefined
  >(undefined);
  const [files, setFiles] = useState<File[]>([]);
  const [errorFile, setErrorFile] = useState(false);
  const [revenues, setRevenues] = useState<TotalRevenuesModel | undefined>(
    undefined
  );
  const [isFinalStep, setIsFinalStep] = useState<boolean>(false);
  const [stsRevenues, setStsRevenues] = useState<
    TotalRevenuesModel | undefined
  >(undefined);
  const [feesRevenues, setFeesRevenues] = useState<
    TotalRevenuesModel | undefined
  >(undefined);
  const [yearFilter, setYearFilter] = useState<number>(
    new Date().getFullYear() - 1
  );

  const location = useLocation();

  const userState = useAppSelector((state) => state.user.editUserRequest);
  const incomeFormCompleted = useAppSelector(
    (state) => state.user.user.incomeFormCompleted
  );

  const disabledCloseModal =
    new Date() >= new Date('2024-03-13') &&
    userState.validationFormStatus === 1 &&
    (userState.ddrYears?.indexOf(2023) ?? -1) > -1 &&
    incomeFormCompleted !== true &&
    !isEmptyValue(incomeFormCompleted);
  const fileNameSuffix = sanitizeString(
    `${userState.surname}_${userState.name}_${userState.vat}`
  );

  const dispatch = useAppDispatch();

  let positionStep: React.ReactNode;
  let totalInvoicesPaidLastYear: number = 0;
  let userCategory: UserCategory;
  const fund479110string =
    '47.91.10 - Commercio al dettaglio di qualsiasi tipo di prodotto effettuato via internet';

  if (revenues && stsRevenues && feesRevenues) {
    totalInvoicesPaidLastYear = CommonInvoiceService.calculateTotalPaid(
      CommonInvoiceService.mergeTotalRevenues(
        revenues,
        stsRevenues,
        feesRevenues
      )
    ).totalPaidLastYear;
  }

  if (
    userState.category === CategoryType.TRADER &&
    userState.atecos !== undefined &&
    userState.atecos.indexOf(fund479110string) > -1
  ) {
    userCategory = UserCategory.TRADER479119;
  } else if (
    userState.category === CategoryType.TRADER &&
    userState.atecos !== undefined &&
    userState.atecos.indexOf(fund479110string) === -1
  ) {
    userCategory = UserCategory.TRADER;
  } else {
    userCategory = UserCategory.ALL;
  }

  useEffect(() => {
    function checkShowModal() {
      setOpen(getItem('incomeUploadModalStatus') === 'show');
    }

    window.addEventListener('local-storage', checkShowModal);

    return () => {
      window.removeEventListener('local-storage', checkShowModal);
    };
  }, []);

  useEffect(() => {
    if (disabledCloseModal) {
      setOpen(true);
    }
  }, [disabledCloseModal, location]);

  useEffect(() => {
    if (open === true) {
      getTotalRevenues(setRevenues, 'customer', yearFilter);
      getTotalRevenues(setStsRevenues, 'sts', yearFilter);
      getTotalRevenues(setFeesRevenues, 'fees-register', yearFilter);
    }

    if (
      (qfUsedFlag === true && userCategory === UserCategory.ALL) ||
      (qfUsedFlag === false && userCategory === UserCategory.TRADER) ||
      (qfUsedFlag === false && userCategory === UserCategory.TRADER479119)
    ) {
      setNumberOfSteps(3);
    } else if (
      (qfUsedFlag === true && userCategory === UserCategory.TRADER) ||
      (qfUsedFlag === true && userCategory === UserCategory.TRADER479119)
    ) {
      setNumberOfSteps(4);
    } else {
      setNumberOfSteps(2);
    }
  }, [qfUsedFlag]);

  switch (stepIndex) {
    case 0:
      positionStep = (
        <IncomeUploadModalIntro
          onClickPrevStep={() => {
            setItem('incomeUploadModalStatus', 'hide');
            const event = new Event('local-storage');
            window.dispatchEvent(event);
            setFiles([]);
          }}
          onClickNextStep={() => setStepIndex(stepIndex + 1)}
          userCategory={userCategory}
          disabledCloseModal={disabledCloseModal}
        />
      );
      break;
    case 1:
      positionStep = (
        <IncomeUploadModalStep1
          qfUsedFlag={qfUsedFlag}
          setQfUsedFlag={(response: boolean) => setQfUsedFlag(response)}
          onClickPrevStep={() => {
            setQfUsedFlag(undefined);
            setStepIndex(stepIndex - 1);
          }}
          onClickNextStep={() => setStepIndex(stepIndex + 1)}
        />
      );
      break;
    case 2:
      positionStep = (
        <IncomeUploadModalStep2
          qfUsedFlag={qfUsedFlag}
          onClickPrevStep={() => setStepIndex(stepIndex - 1)}
          onClickNextStep={() => setStepIndex(stepIndex + 1)}
        />
      );
      break;
    case 3:
      positionStep = (
        <IncomeUploadModalStep3
          qfUsedFlag={qfUsedFlag}
          userCategory={userCategory}
          totalInvoicesPaidLastYear={totalInvoicesPaidLastYear}
          onClickPrevStep={() => setStepIndex(stepIndex - 1)}
          onClickNextStep={() => setStepIndex(stepIndex + 1)}
          onClickSend={() => {
            if (files) {
              sendTotalIncomeFile(
                dispatch,
                files,
                fileNameSuffix,
                setStatus,
                setErrorFile,
                stepIndex,
                setStepIndex,
                setIsFinalStep,
                telematicFeesRegister
              );
            }
          }}
          setFiles={setFiles}
          status={status}
          files={files}
          errorFile={errorFile}
        />
      );
      break;
    case 4:
      if (isFinalStep === true) {
        positionStep = (
          <IncomeUploadModalFinalStep
            userCategory={userCategory}
            status={status}
            closeModal={() => setOpen(false)}
          />
        );
      } else {
        positionStep = (
          <IncomeUploadModalStep4
            qfUsedFlag={qfUsedFlag}
            userCategory={userCategory}
            telematicFeesRegister={telematicFeesRegister}
            setTelematicFeesRegister={(response: boolean) =>
              setTelematicFeesRegister(response)
            }
            onClickPrevStep={() => setStepIndex(stepIndex - 1)}
            onClickNextStep={() => setStepIndex(stepIndex + 1)}
            onClickSend={() => {
              if (files) {
                sendTotalIncomeFile(
                  dispatch,
                  files,
                  fileNameSuffix,
                  setStatus,
                  setErrorFile,
                  stepIndex,
                  setStepIndex,
                  setIsFinalStep,
                  telematicFeesRegister
                );
              }
            }}
            setFiles={setFiles}
            status={status}
            files={files}
            errorFile={errorFile}
          />
        );
      }
      break;
    case 5:
      if (isFinalStep === true) {
        positionStep = (
          <IncomeUploadModalFinalStep
            userCategory={userCategory}
            status={status}
            closeModal={() => setOpen(false)}
          />
        );
      } else {
        positionStep = (
          <IncomeUploadModalStep5
            qfUsedFlag={qfUsedFlag}
            userCategory={userCategory}
            status={status}
            telematicFeesRegister={telematicFeesRegister}
            setTelematicFeesRegister={(response: boolean) =>
              setTelematicFeesRegister(response)
            }
            onClickPrevStep={() => setStepIndex(stepIndex - 1)}
            onClickSend={() => {
              if (files) {
                sendTotalIncomeFile(
                  dispatch,
                  files,
                  fileNameSuffix,
                  setStatus,
                  setErrorFile,
                  stepIndex,
                  setStepIndex,
                  setIsFinalStep,
                  telematicFeesRegister,
                  qfUsedFlag === true &&
                    userCategory === UserCategory.TRADER479119
                    ? feesRevenues?.totalInvoices
                    : undefined
                );
              }
            }}
            setFiles={setFiles}
            files={files}
            errorFile={errorFile}
          />
        );
      }
      break;
    case 6:
      positionStep = (
        <IncomeUploadModalFinalStep
          userCategory={userCategory}
          status={status}
          closeModal={() => setOpen(false)}
        />
      );
      break;
  }

  return (
    <QuickfiscoModal
      hide={() => {
        setItem('incomeUploadModalStatus', 'hide');
        const event = new Event('local-storage');
        window.dispatchEvent(event);
        setStepIndex(0);
        setFiles([]);
        setQfUsedFlag(undefined);
        setIsFinalStep(false);
        setTelematicFeesRegister(undefined);
      }}
      isOpen={open}
    >
      <div className={'row'}>
        <div className={'col-12'}>
          {!isFinalStep && (
            <div className={'row'}>
              <div className={'col-12'}>
                <span
                  className={
                    'income-upload-modal-title d-flex justify-content-center'
                  }
                >
                  {lang.title} {new Date().getFullYear() - 1}
                </span>
              </div>
            </div>
          )}
          {stepIndex > 1 && (
            <div className={'row my-4'}>
              <div className={'col-6 offset-3'}>
                <ProgressBar
                  numberOfSteps={numberOfSteps}
                  currentStep={stepIndex - 1}
                  noText={true}
                />
              </div>
            </div>
          )}
          <div className={'row mt-4 px-5'}>
            <div className={'col-12'}>{positionStep}</div>
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function sendTotalIncomeFile(
  dispatch: Function,
  files: File[],
  fileNameSuffix: string,
  setStatus: SetState<PromiseStatuses>,
  setErrorFile: SetState<boolean>,
  stepIndex: number,
  setStepIndex: SetState<number>,
  setIsFinalStep: SetState<boolean>,
  telematicFeesRegister?: boolean,
  feesRegisterTotalInvoices?: number
) {
  setErrorFile(false);

  if (!files) {
    return;
  }

  if (!validateFiles(files)) {
    setErrorFile(true);
    return;
  }

  const service = new UserService();

  setStatus('loading');
  const telematicFeesRegisterNumber =
    telematicFeesRegister === true
      ? 1
      : telematicFeesRegister === false
      ? -1
      : 0;

  service
    .sendIncome(
      files,
      fileNameSuffix,
      feesRegisterTotalInvoices,
      telematicFeesRegisterNumber
    )
    .then(() => {
      setStatus('successfully');
      setStepIndex(stepIndex + 1);
      setIsFinalStep(true);
      dispatch(getMe());
    })
    .catch((err) => {
      setStatus('failed');
      setStepIndex(stepIndex + 1);
      setIsFinalStep(true);
      console.error(err);
    });
}

function getTotalRevenues(
  setReports: SetState<TotalRevenuesModel | undefined>,
  type: 'customer' | 'sts' | 'fees-register',
  year: number
): void {
  let invoiceService;
  if (type === 'customer') {
    invoiceService = new CustomerInvoiceService();
  } else if (type === 'sts') {
    invoiceService = new StsInvoiceService();
  } else {
    invoiceService = new FeesRegisterService();
  }

  invoiceService
    .getTotalRevenues(year)
    .then((data) => {
      setReports(data);
    })
    .catch((err) => {
      console.error(err);
    });
}
