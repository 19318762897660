import { PromiseStatuses } from '../../types/strings';
import { useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  getItemWithExipiration,
  setItemWithExipiration,
} from '../../utils/localStorage';
import { SetState } from '../../types/functions';
import { UserService } from '../../services/userService';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './sendTrialInvoiceModal.json';
import './sendTrialInvoiceModal.css';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: 'invoice' | 'creditSlip' | 'sts';
}

export function SendTrialInvoiceModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { type } = props;

  const [status, setStatus] = useState<PromiseStatuses>('idle');

  return (
    <div className={'row mt-4'}>
      <QuickfiscoSuccess
        message={lang.sendSuccess}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={lang.sendError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoModal
        isOpen={open}
        hide={() => setOpen(false)}
        modalClassName={'send-trial-invoice-modal-open'}
        centered={true}
        size={'lg'}
      >
        <div className={'send-trial-invoice-modal-container py-4 px-5'}>
          <div className={'row mb-4'}>
            <div className={'col-12 text-center'}>
              <QuickfiscoIcon name={'alert-error.svg'} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row mb-4'}>
                <div
                  className={
                    'col-12 text-center send-trial-invoice-modal-title'
                  }
                >
                  {type === 'invoice'
                    ? lang.title1Invoice
                    : type === 'creditSlip'
                    ? lang.title1CreditSlip
                    : lang.title1Sts}
                  <br />
                  {lang.title2}
                </div>
              </div>
              <div className={'row'}>
                <div
                  className={'col-12 text-center send-trial-invoice-modal-text'}
                >
                  {lang.info1}
                  <br />
                  {lang.info2}
                </div>
              </div>
              <div className={'row mt-4 px-4'}>
                <div className={'col-4'}>
                  <QuickfiscoButton
                    id={'send-trial-invoice-modal-close'}
                    type={'primary'}
                    label={lang.closeModal}
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className={'col-6 offset-2'}>
                  {getItemWithExipiration('stopSubscriptionEmail') ===
                  'true' ? (
                    <QuickfiscoButton
                      id={'send-trial-invoice-modal-request-sent'}
                      label={lang.requestSent}
                      type={'disabled'}
                    />
                  ) : (
                    <>
                      {status === 'loading' ? (
                        <div
                          className={
                            'd-flex justify-content-center align-items-center w-100'
                          }
                        >
                          <QuickfiscoSpinner />
                        </div>
                      ) : (
                        <QuickfiscoButton
                          id={'send-trial-invoice-modal-cta'}
                          label={lang.cta}
                          type={'secondary'}
                          onClick={() => activateSubscription(setStatus)}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}

function activateSubscription(setStatus: SetState<PromiseStatuses>) {
  const service = new UserService();

  setStatus('loading');
  service
    .sendActivateSubscriptionEmail()
    .then(() => {
      setStatus('successfully');
      setItemWithExipiration('stopSubscriptionEmail', {
        value: 'true',
        expireTime: Date.now() + 1000 * 60 * 60 * 24 * 10,
      });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
