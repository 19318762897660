import { ReactNode, useEffect, useState } from 'react';
import config from '../../../config.json';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  financeSimulation,
  setFinanceStep,
  setFinanceUserRequestInstallmentAmount,
  setFinanceUserRequestInstallmentCount,
  setFinanceUserRequestTotalAmount,
} from '../../redux/slices/financeSlice';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInputRange } from '../quickfiscoInputRange/quickfiscoInputRange';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './financeRate.css';

export function FinanceRate() {
  const financeState = useAppSelector((state) => state.finance);

  const [value, setValue] = useState<number>(financeState.financing?.min || 0);
  const [index, setIndex] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(financeSimulation(value));
  }, []);

  useEffect(() => {
    if (
      financeState.financeSimulationStatus === 'successfully' &&
      financeState.financeSimulationResponse !== undefined
    ) {
      dispatch(setFinanceUserRequestTotalAmount(value));
      dispatch(
        setFinanceUserRequestInstallmentCount(
          financeState.financeSimulationResponse.loanSimulations[index]
            .numberOfInstalments
        )
      );
      dispatch(
        setFinanceUserRequestInstallmentAmount(
          financeState.financeSimulationResponse.loanSimulations[index]
            .instalments[0].amount
        )
      );
    }
  }, [
    financeState.financeSimulationStatus,
    financeState.financeSimulationResponse,
  ]);

  const selectedLoanSimulation =
    financeState.financeSimulationResponse?.loanSimulations[index];

  let loanSimulationsList: ReactNode[] = [];

  if (
    financeState.financeSimulationStatus === 'successfully' &&
    financeState.financeSimulationResponse !== undefined
  ) {
    financeState.financeSimulationResponse.loanSimulations.forEach(
      (loan, _index) => {
        loanSimulationsList.push(
          <div
            key={'financeRate-instalments-' + _index}
            className={
              index === _index
                ? 'financeRate-instalments--container-selected'
                : 'financeRate-instalments--container'
            }
            onClick={() => {
              setIndex(_index);
              dispatch(setFinanceUserRequestTotalAmount(value));
              dispatch(
                setFinanceUserRequestInstallmentAmount(
                  loan.instalments[0].amount
                )
              );
              dispatch(
                setFinanceUserRequestInstallmentCount(loan.numberOfInstalments)
              );
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-label-selected'
                    : 'financeRate-instalments-label'
                }
              >
                Rata mensile
              </span>
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-values-selected'
                    : 'financeRate-instalments-values'
                }
              >
                <b>{formatNumberIT(loan.instalments[0].amount) + ' €'}</b>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-label-selected'
                    : 'financeRate-instalments-label'
                }
              >
                Mesi
              </span>
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-values-selected'
                    : 'financeRate-instalments-values'
                }
              >
                {loan.numberOfInstalments}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-label-selected'
                    : 'financeRate-instalments-label'
                }
              >
                TAN
              </span>
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-values-selected'
                    : 'financeRate-instalments-values'
                }
              >
                {formatNumberIT(loan.annualPercentageRate * 100) + ' %'}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-label-selected'
                    : 'financeRate-instalments-label'
                }
              >
                TAEG
              </span>
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-values-selected'
                    : 'financeRate-instalments-values'
                }
              >
                {formatNumberIT(loan.grossAnnualRate * 100) + ' %'}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-label-selected'
                    : 'financeRate-instalments-label'
                }
              >
                Importo totale
              </span>
              <span
                className={
                  index === _index
                    ? 'financeRate-instalments-values-selected'
                    : 'financeRate-instalments-values'
                }
              >
                {formatNumberIT(
                  loan.instalments[0].amount * loan.numberOfInstalments
                ) + ' €'}
              </span>
            </div>
          </div>
        );
      }
    );
  }

  return (
    <div className={'financeRate-container'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 20,
          width: '60%',
        }}
      >
        <span
          style={{
            textAlign: 'left',
            font: 'normal normal 600 20px/30px Poppins',
            color: '#1E1E23',
          }}
        >
          3. Quanto vuoi finanziare?
        </span>
        <span className="financeRate-text">Scegli l'importo</span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 15,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <span className="financeRate-amounts">
              {'€ ' + formatNumberIT(financeState.financing?.min || 0)}
            </span>
            <span className="financeRate-value">
              {'€ ' + formatNumberIT(value)}
            </span>
            <span className="financeRate-amounts">
              {'€ ' + formatNumberIT(financeState.financing?.max || 0)}
            </span>
          </div>
          <QuickfiscoInputRange
            key={'finance-rate-slider'}
            max={financeState.financing?.max || 0}
            min={financeState.financing?.min || 0}
            step={1000}
            value={value}
            onDrag={(value) => dispatch(financeSimulation(value))}
            onChange={(value) => {
              setValue(value);
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <span className="financeRate-amounts--label">
              Minimo finanziabile
            </span>
            <span className="financeRate-amounts--label">
              Massimo finanziabile
            </span>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            maxHeight: '500px',
            overflow: 'auto',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          {financeState.financeSimulationStatus === 'loading' ? (
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <QuickfiscoSpinner />
            </div>
          ) : (
            loanSimulationsList
          )}
        </div>
        <div className="financeRate-selected-rate-container">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 20,
            }}
          >
            <span className="financeRate-selected-rate-selected">
              Hai selezionato
            </span>
            <div style={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
              <span className="financeRate-selected-rate-labels">
                Importo finanziabile
              </span>
              <span className="financeRate-selected-rate-amounts">
                {formatNumberIT(
                  (selectedLoanSimulation?.instalments[0].amount || 0) *
                    (selectedLoanSimulation?.numberOfInstalments || 0)
                ) + ' €'}
              </span>
            </div>
            <div style={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
              <span className="financeRate-selected-rate-labels">rate da</span>
              <span className="financeRate-selected-rate-amounts">
                {formatNumberIT(
                  selectedLoanSimulation?.instalments[0].amount || 0
                ) + ' € '}
                <span className="financeRate-selected-rate-amounts--label">
                  /mese
                </span>
              </span>
            </div>
            <div style={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
              <span className="financeRate-selected-rate-labels">per</span>
              <span className="financeRate-selected-rate-amounts">
                {selectedLoanSimulation?.numberOfInstalments + ' mesi'}
              </span>
            </div>
          </div>
          <span className="financeRate-selected-rate-example">
            Esempio rappresentativo: Importo richiesto 5.000.00 • da rimborsare
            in 48 mesi con rate da 122,71 € al mese. TAN fisso 7,3%. TAEG
            10,7%.11 Il TAEG rappresenta Il costo totale del credito espresso in
            percentuale annua e include: interessi, spese di gestione pratica e
            imposta di bollo 16,00 €. Importo totale dovuto (importo totale del
            credito + costo totale del credito) 6.092,28€.
          </span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 100,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <QuickfiscoButton
            id={'finance-informations-back-button'}
            key={'finance-informations-back-button'}
            label={'INDIETRO'}
            onClick={() => dispatch(setFinanceStep(0))}
            type={'primary'}
          />
          <QuickfiscoButton
            id={'finance-informations-next-button-5'}
            key={'finance-informations-next-button'}
            label={'AVVIA LA PRATICA DI RICHIESTA'}
            onClick={() => {
              dispatch(setFinanceStep(5));
            }}
            type={'secondary'}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
    </div>
  );
}
