import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { GenderSelect } from '../genderSelect/genderSelect';
import { QuickfiscoProvinceSelect } from '../quickfiscoProvinceSelect/quickfiscoProvinceSelect';
import { CategoryType } from '../../models/userModel';
import { MultiAtecoInput } from '../multiAtecoInput/multiAtecoInput';
import lang from './taxReturnSection1PersonalInfo.json';

export function TaxReturnSection1PersonalInfo() {
  const userState = useAppSelector((state) => state.user.editUserRequest);

  const categoryLabelMap = new Map<CategoryType | undefined, string>([
    [CategoryType.ARTISAN, 'Artigiano'],
    [
      CategoryType.FUND,
      'Libero Professionista con iscrizione Cassa Ordine Professionale',
    ],
    [
      CategoryType.NOT_FUND,
      'Libero Professionista con iscrizione Gestione Separata INPS',
    ],
    [CategoryType.TRADER, 'Commerciante'],
  ]);

  return (
    <>
      <div className="row no-gutters mt-3">
        <div className="col-6">
          <QuickfiscoInput
            id="tax-return-section-1-name"
            label={lang.NameLabel}
            styleType={'default'}
            type="text"
            disabled={true}
            required={true}
            defaultValue={userState.name}
          />
        </div>
        <div className="col-6">
          <QuickfiscoInput
            id="tax-return-section-1-surname"
            label={lang.SurnameLabel}
            styleType={'default'}
            type="text"
            disabled={true}
            required={true}
            defaultValue={userState.surname}
          />
        </div>
      </div>
      <div className={'row no-gutters mt-3'}>
        <div className="col-6">
          <QuickfiscoInput
            id="tax-return-section-1-tax-code"
            label={lang.TaxCodeLabel}
            styleType={'default'}
            type="text"
            value={userState.taxCode || ''}
            required={true}
            disabled={true}
          />
        </div>
        <div className="col-3">
          <GenderSelect
            id={'tax-return-section-1-gender-select'}
            value={userState.gender?.toUpperCase()}
            disabled={true}
          />
        </div>
        <div className="col-3">
          <QuickfiscoDatePicker
            id="tax-return-section-1-date-of-birth"
            label={lang.DateOfBirth}
            required={true}
            styleType={'default'}
            disabled={true}
            value={
              userState.dateOfBirth
                ? new Date(userState.dateOfBirth)
                : undefined
            }
          />
        </div>
      </div>
      <div className={'row no-gutters mt-3'}>
        <div className="col-6">
          <QuickfiscoInput
            id="tax-return-section-1-birth-place"
            label={lang.BirthPlace}
            type="text"
            defaultValue={userState.birthPlace}
            styleType={'default'}
            required={true}
            disabled={true}
          />
        </div>
        <div className="col-3">
          <QuickfiscoProvinceSelect
            id={'tax-return-section-1-birth-province'}
            value={userState.birthProvince}
            label={lang.BirthProvince}
            requiredField={true}
            disabled={true}
          />
        </div>
      </div>
      <div className={'row no-gutters mt-3'}>
        <div className="col-12">
          <QuickfiscoInput
            id="tax-return-section-1-category"
            label={lang.Category}
            styleType={'default'}
            type="text"
            disabled={true}
            required={true}
            value={categoryLabelMap.get(userState.category)}
          />
        </div>
      </div>
      <div className="row no-gutters mt-3">
        <div className="col-6">
          <QuickfiscoInput
            id="tax-return-section-1-vat"
            label={lang.VatLabel}
            styleType={'default'}
            type="text"
            defaultValue={userState.vat}
            required={true}
            disabled={true}
          />
        </div>
        <div className="col-6">
          <QuickfiscoDatePicker
            id="tax-return-section-1-vat-opening-date"
            label={lang.VatOpeningDate}
            styleType={'default'}
            required={true}
            disabled={true}
            value={
              userState.vatOpeningDate
                ? new Date(userState.vatOpeningDate)
                : undefined
            }
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-12">
          <MultiAtecoInput
            atecoList={userState.atecos}
            isReadOnly={true}
            requiredField={true}
          />
        </div>
      </div>
    </>
  );
}
