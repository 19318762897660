import React from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { UserService } from '../../services/userService';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './multiAtecoInput.css';
import lang from './multiAtecoInput.json';

interface Props {
  atecoList?: string[];
  error?: boolean;
  onChange?: (atecoList: string[]) => void;
  isReadOnly?: boolean;
  requiredField?: boolean;
}

export function MultiAtecoInput(props: Props) {
  const { atecoList } = props;
  const { error } = props;
  const { onChange } = props;
  const { isReadOnly } = props;
  const { requiredField } = props;

  const dispatch = useAppDispatch();

  const atecoComponents = getAtecoComponents(
    dispatch,
    atecoList,
    onChange,
    isReadOnly,
    requiredField
  );

  return (
    <div>
      {atecoComponents}
      <div className={'row mt-4'}>
        <div className="col-6">
          {error && (
            <span className="multi-ateco-input-error">{lang.error}</span>
          )}
        </div>
        {!isReadOnly && (
          <div className={'col-6 mt-2'}>
            <QuickfiscoButton
              id={'add-ateco'}
              label={lang.buttonLabel}
              type={atecoList?.length === 3 ? 'disabled' : 'primary'}
              onClick={() =>
                atecoList && onChange && onChange([...atecoList, ''])
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

function getAtecoComponents(
  dispatch: Function,
  atecoList?: string[],
  onChange?: (atecos: string[]) => void,
  isReadOnly?: boolean,
  requiredField?: boolean
): React.ReactNode[] {
  let atecoComponents: React.ReactNode[] = [];

  if (atecoList === undefined) {
    return (atecoComponents = [
      getAtecoComponent(0, [], dispatch, onChange, isReadOnly, requiredField),
    ]);
  }

  atecoList.forEach((_, index) => {
    atecoComponents.push(
      getAtecoComponent(
        index,
        atecoList,
        dispatch,
        onChange,
        isReadOnly,
        requiredField
      )
    );
  });

  return atecoComponents;
}

function getAtecoComponent(
  index: number,
  atecoList: string[],
  dispatch: Function,
  onChange?: (atecos: string[]) => void,
  isReadOnly?: boolean,
  requiredField?: boolean
): React.ReactNode {
  const userService = new UserService(dispatch);
  return (
    <div key={'item-' + atecoList[index]} className={'row'}>
      <div className={'col mt-3'}>
        <AtecoSelect
          id="ateco-input"
          type={'all'}
          requiredField={requiredField}
          disabled={isReadOnly}
          value={atecoList[index] !== '' ? atecoList[index] : undefined}
          onChange={(_ateco) => {
            if (_ateco === undefined || atecoList.includes(_ateco)) {
              return;
            }

            const newAtecos = [...atecoList];

            newAtecos[index] = _ateco;
            onChange && onChange([...newAtecos]);
            userService.validateAtecos(newAtecos);
          }}
          index={index}
        />
      </div>
      {index !== 0 && !isReadOnly && (
        <div
          className={'col-1 mt-3 d-flex align-items-end justify-content-end'}
        >
          <QuickfiscoIcon
            name={'delete-icon.svg'}
            isButton={true}
            onClick={() => {
              const newAtecos = atecoList
                .slice(0, index)
                .concat(atecoList.slice(index + 1));

              onChange && onChange([...newAtecos]);
            }}
          />
        </div>
      )}
    </div>
  );
}
