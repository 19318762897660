import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Dropdown from 'react-bootstrap/Dropdown';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserService } from '../../services/userService';
import { setProfileNotSavedModalIsOpen } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoUri.json';
import './dropdownUserMenu.css';

export function DropdownUserMenu() {
  const [open, setOpen] = useState(false);

  const userState = useAppSelector((state) => state.user);
  const user = userState.user;
  const editUserRequest = userState.editUserRequest;

  const editingMe = UserService.editingMe(user, editUserRequest, user.status !== 4);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Dropdown align={'end'} onToggle={(isOpen) => setOpen(isOpen)}>
      <Dropdown.Toggle id="dropdown-user">
        <QuickfiscoIcon
          name={open ? 'user-header-hover-icon.svg' : 'user-header-icon.svg'}
          className={'pe-2'}
        />
        Ciao, {userState.user.name}
        <QuickfiscoIcon
          name={
            open
              ? 'arrow-open-header-user-menu.svg'
              : 'arrow-header-user-menu.svg'
          }
          className={'ps-3'}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className={'dropdown-user-menu'}>
        <Dropdown.Item
          className={'dropdown-user-item'}
          active={location.pathname === uri.Profile ? true : false}
          onClick={() => {
            if (editingMe) {
              dispatch(setProfileNotSavedModalIsOpen(true));
            } else {
              navigate(uri.Profile);
            }
          }}
        >
          Profilo
        </Dropdown.Item>
        <Dropdown.Item
          className={'dropdown-user-item'}
          active={
            location.pathname === uri.SubscriptionAndSettings ? true : false
          }
          onClick={() => {
            if (editingMe) {
              dispatch(setProfileNotSavedModalIsOpen(true));
            } else {
              navigate(uri.SubscriptionAndSettings);
            }
          }}
        >
          Abbonamento e impostazioni
        </Dropdown.Item>
        {user.status === 4 && (
          <Dropdown.Item
            className={'dropdown-user-item'}
            active={location.pathname === uri.Support ? true : false}
            onClick={() => {
              if (editingMe) {
                dispatch(setProfileNotSavedModalIsOpen(true));
              } else {
                navigate(uri.Support);
              }
            }}
          >
            Assistenza
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
