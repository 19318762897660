import { AddressTaxReturnModel, BankAccountTaxReturnModel, BuildingsTaxReturnModel, ChildrenTaxReturnModel, CryptoTaxReturnModel, FreeServiceCreditTaxReturnModel, StartupSpendingTaxReturnModel, LandsTaxReturnModel, MarriedTaxReturnModel, ObjectFileTaxReturnModel, SectionTaxReturn1, SectionTaxReturn10, SectionTaxReturn11, SectionTaxReturn2, SectionTaxReturn3, SectionTaxReturn4, SectionTaxReturn5, SectionTaxReturn6, SectionTaxReturn7, SectionTaxReturn8, SectionTaxReturn9, ServiceInvoicesTaxReturnModel, SurveyStatusTaxReturnModel, TvRadioTaxReturnModel, UniversitySpendingTaxReturnModel, sectionTaxReturnAll } from '../models/taxReturnModel';
import { ObjectModel } from '../models/objectModel';
import { CategoryType } from '../models/userModel';
import { fiscalCodeRegex } from '../regex/regex';
import { host } from '../utils/config';
import { Http } from '../utils/http';
import { isEmptyValue } from '../utils/values';
import { showBuldingExtraField } from '../utils/taxReturn';

export class TaxReturnService {

  private readonly url = host + '/api/v1/tax-return';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public getSurveyStatus(year: string): Promise<SurveyStatusTaxReturnModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + `/survey/status?year=${year}`,
    })
      .then(res => res.json())
      .then((data: SurveyStatusTaxReturnModel) => data);
  }

  public getSurveyStep(step: number): Promise<sectionTaxReturnAll> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + `/survey/step/${step}`,
    })
      .then(res => res.json())
      .then((data: sectionTaxReturnAll) => data);
  }

  public getLandsBuildings(): Promise<SectionTaxReturn3> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/survey/lands-buildings',
    })
      .then(res => res.json())
      .then((data: SectionTaxReturn3) => data);
  }

  public setSurveyStep(step: number, request: sectionTaxReturnAll): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + `/survey/step/${step}`,
      method: 'post',
      body: JSON.stringify(request),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
      .then(() => undefined);
  }

  public deleteTaxReturnDocuments(step: number, docId: string): Promise<void> {
    return this.http.sendAndReceive(({
      method: 'delete',
      url: this.url + `/survey/step/${step}/document/${docId}`,
    }))
      .then(() => undefined);
  }

  public validateResidence(zipCodeResidenceError: boolean, residence: AddressTaxReturnModel): boolean {
    if (!Object.values(residence).every(el => (el !== '' && !isEmptyValue(el))) || zipCodeResidenceError
    ) {
      return false;
    }
    return true;
  }

  public validateDomicile(zipCodeDomicileError: boolean, domicileQuestion?: boolean, domicileAnswer?: AddressTaxReturnModel): boolean {
    if ((domicileQuestion === false && isEmptyValue(domicileAnswer)) ||
      (domicileQuestion === false && zipCodeDomicileError) ||
      (domicileQuestion === false && (domicileAnswer && Object.keys(domicileAnswer).length < 4)) ||
      (domicileQuestion === false &&
        (!(domicileAnswer && Object.values(domicileAnswer).every(el => (el !== '' && !isEmptyValue(el)))))
      )
    ) {
      return false;
    }

    return true;
  }

  public validatedomicilePrevYear(zipCodeDomicilePrevYearError: boolean, domicilePrevYearQuestion?: boolean, domicilePrevYearAnswer?: AddressTaxReturnModel): boolean {
    if ((domicilePrevYearQuestion === false && isEmptyValue(domicilePrevYearAnswer)) ||
      (domicilePrevYearQuestion === false && zipCodeDomicilePrevYearError) ||
      (domicilePrevYearQuestion === false && (domicilePrevYearAnswer && Object.keys(domicilePrevYearAnswer).length < 5)) ||
      (domicilePrevYearQuestion === false &&
        (!(domicilePrevYearAnswer && Object.values(domicilePrevYearAnswer).every(el => (el !== '' && !isEmptyValue(el)))))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateTvRadio(tvRadioQuestion?: boolean, tvRadioAnswer?: TvRadioTaxReturnModel): boolean {
    if ((isEmptyValue(tvRadioQuestion) && isEmptyValue(tvRadioAnswer)) ||
      (tvRadioQuestion === true && isEmptyValue(tvRadioAnswer)) ||
      (tvRadioQuestion === true &&
        (!(tvRadioAnswer && Object.values(tvRadioAnswer).every(el => (el !== '' && !isEmptyValue(el)))))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateAllocation(allocateQuestion?: boolean, allocateAnswer?: string, fiscalCodeError?: boolean,): boolean {
    if ((isEmptyValue(allocateQuestion) && isEmptyValue(allocateAnswer)) ||
      (allocateQuestion === true && isEmptyValue(allocateAnswer)) ||
      (allocateQuestion === true && allocateAnswer !== 'ALTRO - inserimento Codice Fiscale ad hoc' && fiscalCodeError)
    ) {
      return false;
    }

    return true;
  }

  public validateSection1(fiscalCodeError: boolean, data: SectionTaxReturn1, zipCodeResidenceError: boolean, zipCodeDomicileError: boolean, zipCodeDomicilePrevYearError: boolean): boolean {
    let isValid = true;

    if (!this.validateResidence(zipCodeResidenceError, data.residence)) {
      isValid = false;
    }

    if (!this.validateDomicile(zipCodeDomicileError, data.domicileQuestion, data.domicileAnswer)) {
      isValid = false;
    }

    if (!this.validatedomicilePrevYear(zipCodeDomicilePrevYearError, data.domicilePrevYearQuestion, data.domicilePrevYearAnswer)) {
      isValid = false;
    }

    if (!this.validateTvRadio(data.tvRadioQuestion, data.tvRadioAnswer)) {
      isValid = false;
    }

    if (!this.validateAllocation(data.allocate8x1000Question, data.allocate8x1000Answer)) {
      isValid = false;
    }

    if (!this.validateAllocation(data.allocate5x1000Question, data.allocate5x1000Answer, fiscalCodeError)) {
      isValid = false;
    }

    if (!this.validateAllocation(data.allocate2x1000Question, data.allocate2x1000Answer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateMarried(taxCodeSpouseError: boolean, marriedQuestion?: boolean, marriedAnswer?: MarriedTaxReturnModel): boolean {
    if ((isEmptyValue(marriedQuestion) && isEmptyValue(marriedAnswer)) ||
      (marriedQuestion === true && taxCodeSpouseError) ||
      (marriedQuestion === true && isEmptyValue(marriedAnswer)) ||
      (marriedQuestion === true &&
        (!(marriedAnswer && Object.values(marriedAnswer).every(el => (el !== '' && !isEmptyValue(el)))))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateChildren(childrenQuestion?: boolean, childrenAnswer?: ChildrenTaxReturnModel[]): boolean {
    if ((isEmptyValue(childrenQuestion) && isEmptyValue(childrenAnswer)) ||
      (childrenQuestion === true && (childrenAnswer && childrenAnswer.length === 0)) ||
      (childrenQuestion === true && (childrenAnswer && childrenAnswer.filter(e => !fiscalCodeRegex.test(e.taxCodeChild)).length > 0)) ||
      (childrenQuestion === true &&
        (childrenAnswer && childrenAnswer.map(answer => {
          return Object.values(answer).every(el => (el !== '' && !isEmptyValue(el)))
        }).includes(false))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateSection2(data: SectionTaxReturn2, taxCodeSpouseError: boolean): boolean {
    let isValid = true;

    if (!this.validateMarried(taxCodeSpouseError, data.marriedQuestion, data.marriedAnswer)) {
      isValid = false;
    }

    if (!this.validateChildren(data.childrenQuestion, data.childrenAnswer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateSectionLands(answer?: LandsTaxReturnModel[]): boolean {
    if (
      (answer && answer.map(answer => {
        return !(answer.isConfirmed === false || answer.isConfirmed === undefined)
      }).includes(false))
    ) {
      return false;
    }

    return true;
  }

  public validateSectionLand(answer?: LandsTaxReturnModel): boolean {
    const newAnswer = {
      city: answer?.city,
      foglio: answer?.foglio,
      particella: answer?.particella,
      agriculturalIncome: answer?.agriculturalIncome,
      dominicalIncome: answer?.dominicalIncome,
      possessionDaysFrom: answer?.possessionDaysFrom,
      possessionDaysTo: answer?.possessionDaysTo,
      percentagePossession: answer?.percentagePossession,
      imuSubject: answer?.imuSubject,
    };

    if (
      (newAnswer && Object.values(newAnswer).every(el => (el !== '' && !isEmptyValue(el))) === false)
    ) {
      return false;
    }

    return true;
  }


  public validateSectionBuildings(answer?: BuildingsTaxReturnModel[]): boolean {
    if (
      (answer && answer.map(answer => {
        return !(answer.isConfirmed === false || answer.isConfirmed === undefined)
      }).includes(false))
    ) {
      return false;
    }

    return true;
  }

  public validateSectionBuilding(answer?: BuildingsTaxReturnModel): boolean {
    const newAnswer = {
      city: answer?.city,
      foglio: answer?.foglio,
      particella: answer?.particella,
      landRegistryCategory: answer?.landRegistryCategory,
      landRegistryIncome: answer?.landRegistryIncome,
      possessionDaysFrom: answer?.possessionDaysFrom,
      possessionDaysTo: answer?.possessionDaysTo,
      percentagePossession: answer?.percentagePossession,
      imuSubject: answer?.imuSubject,
      usage: answer?.usage,
      shortRentals: answer?.shortRentals,
    };

    if (
      (newAnswer && Object.values(newAnswer).every(el => (el !== '' && !isEmptyValue(el))) === false) ||
      (
        answer?.shortRentals === false &&
        answer?.usage && showBuldingExtraField(answer?.shortRentals, answer?.usage) &&
        (answer?.amountAnnualRent === undefined || answer?.cedolareSecca === undefined || answer?.leaseContract === undefined)
      )
    ) {
      return false;
    }

    return true;
  }

  public validateSection3(data: SectionTaxReturn3): boolean {
    let isValid = true;

    if (!this.validateSectionLands(data.landsAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionBuildings(data.buildingsAnswer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateReceivedIncome(receivedIncomeQuestion?: boolean): boolean {
    if (isEmptyValue(receivedIncomeQuestion)) {
      return false;
    }

    return true;
  }

  public validatePeriodicAllowances(periodicAllowancesQuestion?: boolean, periodicAllowancesAnswer?: number): boolean {
    if ((isEmptyValue(periodicAllowancesQuestion) && isEmptyValue(periodicAllowancesAnswer)) ||
      (periodicAllowancesQuestion === true && isEmptyValue(periodicAllowancesAnswer))
    ) {
      return false;
    }

    return true;
  }

  public validateSection4(data: SectionTaxReturn4): boolean {
    let isValid = true;

    if (!this.validateReceivedIncome(data.receivedIncomeQuestion)) {
      isValid = false;
    }

    if (!this.validatePeriodicAllowances(data.periodicAllowancesQuestion, data.periodicAllowancesAnswer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateSectionWithFiles(question?: boolean, answer?: ObjectFileTaxReturnModel[] | ObjectModel[]): boolean {
    if ((isEmptyValue(question) && isEmptyValue(answer)) ||
      (question === true && (Array.isArray(answer) && answer?.length === 0)) ||
      (question === true && isEmptyValue(answer))
    ) {
      return false;
    }

    return true;
  }

  public validateSection5(data: SectionTaxReturn5): boolean {
    let isValid = true;

    if (!this.validateSectionWithFiles(data.capitalIncomeQuestion, data.capitalIncomeAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.selfEmploymentQuestion, data.selfEmploymentAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.commercialActivitiesQuestion, data.commercialActivitiesAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.sportsActivitiesQuestion, data.sportsActivitiesAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.realEstateIncomeQuestion, data.realEstateIncomeAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.intellectualWorksQuestion, data.intellectualWorksAnswer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateSection6(data: SectionTaxReturn6, category?: CategoryType): boolean {
    let isValid = true;

    if (category === CategoryType.FUND) {
      if (!this.validateSectionWithFiles(data.inpsContributionsQuestion, data.inpsContributionsAnswer)) {
        isValid = false;
      }
    } else {
      if (isEmptyValue(data.inpsContributionsQuestion)) {
        isValid = false;
      }
    }

    return isValid;
  }

  public validateElectronicInvoicing(electronicInvoicingQuestion?: boolean, serviceInvoicesQuestion?: boolean, serviceInvoicesAnswer?: ServiceInvoicesTaxReturnModel[]): boolean {
    if (isEmptyValue(electronicInvoicingQuestion) ||
      (electronicInvoicingQuestion === true && isEmptyValue(serviceInvoicesQuestion)) ||
      (electronicInvoicingQuestion === true && serviceInvoicesQuestion === true && isEmptyValue(serviceInvoicesAnswer)) ||
      (electronicInvoicingQuestion === true && serviceInvoicesQuestion === true &&
        (serviceInvoicesAnswer && serviceInvoicesAnswer.map(answer => {
          return Object.values(answer).every(el => (el !== '' && !isEmptyValue(el)))
        }).includes(false))
      )
    ) {
      return false;
    }

    return true;
  }


  public validateSection7Answer(meansTransportQuestion?: boolean, meansTransportAnswer?: number): boolean {
    if ((isEmptyValue(meansTransportQuestion) && isEmptyValue(meansTransportAnswer)) ||
      (meansTransportQuestion === true && isEmptyValue(meansTransportAnswer))
    ) {
      return false;
    }

    return true;
  }

  public validateSection7(data: SectionTaxReturn7, category?: CategoryType): boolean {
    let isValid = true;

    if (category === CategoryType.FUND ||
      category === CategoryType.NOT_FUND) {
      if (!this.validateElectronicInvoicing(data.electronicInvoicingQuestion, data.serviceInvoicesQuestion, data.serviceInvoicesAnswer)) {
        isValid = false;
      }
    } else {
      if (isEmptyValue(data.electronicInvoicingQuestion)) {
        isValid = false;
      }
    }


    if (!this.validateSectionWithFiles(data.documentsWithholdingTaxQuestion, data.documentsWithholdingTaxAnswer)) {
      isValid = false;
    }

    if (category === CategoryType.TRADER ||
      category === CategoryType.ARTISAN) {
      if (!this.validateSection7Answer(data.meansTransportQuestion, data.meansTransportAnswer)) {
        isValid = false;
      }

      if (!this.validateSection7Answer(data.purchaseGoodsQuestion, data.purchaseGoodsAnswer)) {
        isValid = false;
      }

      if (!this.validateSection7Answer(data.financialFeeQuestion, data.financialFeeAnswer)) {
        isValid = false;
      }

      if (!this.validateSection7Answer(data.rentalFeeQuestion, data.rentalFeeAnswer)) {
        isValid = false;
      }

      if (!this.validateSection7Answer(data.royaltyFeeQuestion, data.royaltyFeeAnswer)) {
        isValid = false;
      }

      if (!this.validateSection7Answer(data.fuelFeeQuestion, data.fuelFeeAnswer)) {
        isValid = false;
      }
    }

    if (!this.validateSectionWithFiles(data.electronicDevicesQuestion, data.electronicDevicesAnswer)) {
      isValid = false;
    }

    return isValid;
  }


  public validateUniversitySpending(universitySpendingQuestion?: boolean, universitySpendingAnswer?: UniversitySpendingTaxReturnModel): boolean {
    if (isEmptyValue(universitySpendingQuestion) ||
      (universitySpendingQuestion === true && isEmptyValue(universitySpendingAnswer)) ||
      (universitySpendingQuestion === true &&
        (!(universitySpendingAnswer && Object.values(universitySpendingAnswer).every(el => (el !== '' && !isEmptyValue(el)))))
      ) ||
      (universitySpendingQuestion === true && (Array.isArray(universitySpendingAnswer?.spendingDocuments) && universitySpendingAnswer?.spendingDocuments?.length === 0)) ||
      (universitySpendingQuestion === true && isEmptyValue(universitySpendingAnswer?.spendingDocuments))
    ) {
      return false;
    }

    return true;
  }

  public validateStartup(startupQuestion?: boolean, startupAnswer?: StartupSpendingTaxReturnModel): boolean {
    if (isEmptyValue(startupQuestion) ||
      (startupQuestion === true && isEmptyValue(startupAnswer)) ||
      (startupQuestion === true && isEmptyValue(startupAnswer?.totalAmountPrevYear)) ||
      (startupQuestion === true && (Array.isArray(startupAnswer?.spendingDocuments) && startupAnswer?.spendingDocuments?.length === 0)) ||
      (startupQuestion === true && isEmptyValue(startupAnswer?.spendingDocuments))
    ) {
      return false;
    }

    return true;
  }

  public validateMaintenanceAllowance(taxCodeSpouseError: boolean, maintenanceAllowanceQuestion?: boolean, maintenanceAllowanceAnswer?: MarriedTaxReturnModel): boolean {
    if ((isEmptyValue(maintenanceAllowanceQuestion) && isEmptyValue(maintenanceAllowanceAnswer)) ||
      (maintenanceAllowanceQuestion === true && taxCodeSpouseError) ||
      (maintenanceAllowanceQuestion === true && isEmptyValue(maintenanceAllowanceAnswer)) ||
      (maintenanceAllowanceQuestion === true &&
        (!(maintenanceAllowanceAnswer && Object.values(maintenanceAllowanceAnswer).every(el => (el !== '' && !isEmptyValue(el)))))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateSection8(taxCodeSpouseError: boolean, data: SectionTaxReturn8): boolean {
    let isValid = true;

    if (isEmptyValue(data.propertyRenovationQuestion)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.propertyRenovationPrevYearQuestion, data.propertyRenovationPrevYearAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.medicalSpendingQuestion, data.medicalSpendingAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.mortgageInterestMainHomeQuestion, data.mortgageInterestMainHomeAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.mortgageInterestOtherHomesQuestion, data.mortgageInterestOtherHomesAnswer)) {
      isValid = false;
    }

    if (!this.validateUniversitySpending(data.universitySpendingQuestion, data.universitySpendingAnswer)) {
      isValid = false;
    }

    if (!this.validateStartup(data.startupQuestion, data.startupAnswer)) {
      isValid = false;
    }

    if (!this.validateMaintenanceAllowance(taxCodeSpouseError, data.maintenanceAllowanceQuestion, data.maintenanceAllowanceAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.lifeInsurancePremiumsQuestion, data.lifeInsurancePremiumsAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.socialSecurityContributionsQuestion, data.socialSecurityContributionsAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.otherSpendingQuestion, data.otherSpendingAnswer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateBankAccount(bankAccountQuestion?: boolean, bankAccountAnswer?: BankAccountTaxReturnModel): boolean {
    if ((isEmptyValue(bankAccountQuestion) && isEmptyValue(bankAccountAnswer)) ||
      (bankAccountQuestion === true && isEmptyValue(bankAccountAnswer)) ||
      (bankAccountQuestion === true && isEmptyValue(bankAccountAnswer?.bankAccountYear) && (isEmptyValue(bankAccountAnswer?.accountHolder) || isEmptyValue(bankAccountAnswer?.accountOwnershipPerc) || isEmptyValue(bankAccountAnswer?.bankAccountYear))
      ) ||
      (bankAccountQuestion === true &&
        bankAccountAnswer?.bankAccountYear && (bankAccountAnswer?.bankAccountYear < (new Date().getFullYear() - 1)) &&
        (isEmptyValue(bankAccountAnswer?.accountHolder) || isEmptyValue(bankAccountAnswer?.accountOwnershipPerc))
      ) ||
      (bankAccountQuestion === true && bankAccountAnswer?.bankAccountYear && bankAccountAnswer?.bankAccountYear >= (new Date().getFullYear() - 1) &&
        (isEmptyValue(bankAccountAnswer?.accountHolder) || isEmptyValue(bankAccountAnswer?.accountOwnershipPerc) || isEmptyValue(bankAccountAnswer?.bankAccountDate))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateOtherInvestments(otherInvestmentsQuestion?: boolean, otherInvestmentsAnswer?: string): boolean {
    if ((isEmptyValue(otherInvestmentsQuestion) && isEmptyValue(otherInvestmentsAnswer)) ||
      (otherInvestmentsQuestion === true && isEmptyValue(otherInvestmentsAnswer))
    ) {
      return false;
    }

    return true;
  }

  public validateSection9(data: SectionTaxReturn9): boolean {
    let isValid = true;

    if (!this.validateBankAccount(data.bankAccountQuestion, data.bankAccountAnswer)) {
      isValid = false;
    }

    if (isEmptyValue(data.multipleBankAccountQuestion)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.propertiesQuestion, data.propertiesAnswer)) {
      isValid = false;
    }

    if (data.propertiesQuestion === true && !this.validateSectionWithFiles(data.realEstateTaxesQuestion, data.realEstateTaxesAnswer)) {
      isValid = false;
    }

    if (!this.validateOtherInvestments(data.otherInvestmentsQuestion, data.otherInvestmentsAnswer)) {
      isValid = false;
    }

    if (isEmptyValue(data.continueQuestion) || data.continueQuestion === false) {
      isValid = false;
    }

    return isValid;
  }

  public validateCryptoAnswer(cryptoQuestion?: boolean, cryptoAnswer?: CryptoTaxReturnModel[]): boolean {
    if ((isEmptyValue(cryptoQuestion) && isEmptyValue(cryptoAnswer)) ||
      (cryptoQuestion === true && (cryptoAnswer && cryptoAnswer.length === 0)) ||
      (cryptoQuestion === true &&
        (cryptoAnswer && cryptoAnswer.map(answer => {
          return Object.values(answer).every(el => (el !== '' && !isEmptyValue(el)))
        }).includes(false))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateSection10(data: SectionTaxReturn10): boolean {
    let isValid = true;

    if (!this.validateCryptoAnswer(data.cryptoQuestion, data.cryptoAnswer)) {
      isValid = false;
    }

    if (!this.validateSectionWithFiles(data.achieveProfitGainQuestion, data.achieveProfitGainAnswer)) {
      isValid = false;
    }

    return isValid;
  }

  public validateSplitPaymentsQuestion(splitPaymentsQuestion?: boolean, splitPaymentsAnswer?: number): boolean {
    if ((isEmptyValue(splitPaymentsQuestion) && isEmptyValue(splitPaymentsAnswer)) ||
      (splitPaymentsQuestion === true && isEmptyValue(splitPaymentsAnswer))
    ) {
      return false;
    }

    return true;
  }

  public validateUseFreeServiceCredit(ibanError: boolean, useFreeServiceCreditQuestion?: boolean, useFreeServiceCreditAnswer?: FreeServiceCreditTaxReturnModel): boolean {
    if ((isEmptyValue(useFreeServiceCreditQuestion) && isEmptyValue(useFreeServiceCreditAnswer)) ||
      (useFreeServiceCreditQuestion === true && ibanError) ||
      (useFreeServiceCreditQuestion === true &&
        (!(useFreeServiceCreditAnswer && Object.values(useFreeServiceCreditAnswer).every(el => (el !== '' && !isEmptyValue(el) && el !== false))))
      )
    ) {
      return false;
    }

    return true;
  }

  public validateSection11(data: SectionTaxReturn11, ibanError: boolean): boolean {
    let isValid = true;

    if (!this.validateSplitPaymentsQuestion(data.splitPaymentsQuestion, data.splitPaymentsAnswer)) {
      isValid = false;
    }

    if (!this.validateUseFreeServiceCredit(ibanError, data.useFreeServiceCreditQuestion, data.useFreeServiceCreditAnswer)) {
      isValid = false;
    }

    return isValid;
  }
}