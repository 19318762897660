import { NavigateFunction, useNavigate } from 'react-router-dom';
import { InvoiceFilters } from '../../models/invoiceFilters';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import { getStsInvoiceList } from '../../redux/slices/stsInvoiceListSlice';
import uri from '../../routers/quickfiscoUri.json';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './sendStsInvoice.json';

interface Props {
  invoiceId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function SendStsInvoice(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { invoiceId } = props;

  const globalStatus = useAppSelector(state => state.operationStatus.status);
  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)

  const stsInvoiceFilters: InvoiceFilters = {
    page: stsInvoiceListState.page,
    year: new Date().getFullYear().toString(),
    companyName: stsInvoiceListState.companyNameFilter,
    stsStatus: stsInvoiceListState.statusFilter
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
      <QuickfiscoError
        message={lang.error}
        active={globalStatus === 'failed'}
        close={() => dispatch(setGlobalStatus({
          status: 'idle'
        }))}
      />
      <div className={'row mb-4 p-4 pt-0'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <p className={'cancel-sts-sending-title'}>
              {lang.title}
            </p>
            <p className={'cancel-sts-sending-text'}>
              Proseguendo verrà inviata al Sistema TS. <br />
              Potrai comunque <b>comunicare una variazione</b> o <b>annullare l’invio
                successivamente</b>. <br />
              Puoi inviare anche una {' '}
              <span className={'cancel-sts-sending-blue'}>
                copia via e-mail al tuo cliente.
              </span>
            </p>
          </div>
        </div>
        <div className={'row mt-2'}>
          {
            globalStatus === 'loading' ? (
              <div className={'col-12 d-flex align-items-center justify-content-center'}>
                <QuickfiscoSpinner />
              </div>
            ) : (
              <div className={'col-12'}>
                <div className={'row'}>
                  <div className={'col-7'}>
                    <QuickfiscoButton
                      id={'cancel-sts-sending-email'}
                      label={lang.labelEmail}
                      onClick={() => send(invoiceId, true, stsInvoiceFilters, dispatch, navigate, setOpen)}
                      type={'primary'}
                    />
                  </div>
                  <div className={'col-5'}>
                    <QuickfiscoButton
                      id={'cancel-sts-sending'}
                      label={lang.labelSts}
                      type={'secondary'}
                      onClick={() => send(invoiceId, false, stsInvoiceFilters, dispatch, navigate, setOpen)}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function send(
  invoiceId: string,
  sendEmail: boolean,
  stsInvoiceFilters: InvoiceFilters,
  dispatch: Function,
  navigate: NavigateFunction,
  setOpen: (open: boolean) => void
) {
  const service = new StsInvoiceService();

  dispatch(setGlobalStatus({
    status: 'loading'
  }));
  service
    .send(invoiceId, sendEmail)
    .then(() => {
      setOpen(false);

      dispatch(setGlobalStatus({
        status: 'successfully'
      }));

      dispatch(getStsInvoiceList(stsInvoiceFilters));

      navigate(uri.StsHistory);
    })
    .catch(err => {
      dispatch(setGlobalStatus({
        status: 'failed'
      }));
      console.error(err);
    });
}
