import { ObjectModel } from "./objectModel";
import { CategoryType } from "./userModel";

export interface SurveyStatusTaxReturnModel {
    surveyStatus?: SurveyStatusTypeTaxReturnModel,
    lastStep?: number,
    completedAt?: Date,
    splitPayments?: number,
}

export enum SurveyStatusTypeTaxReturnModel {
    NOTSTARTED = "notStarted",
    ONGOING = "ongoing",
    REVIEWING = "reviewing",
    COMPLETED = "completed",
}

export type sectionTaxReturnAll = SectionTaxReturn1 | SectionTaxReturn2 | SectionTaxReturn3 | SectionTaxReturn4 | SectionTaxReturn5 | SectionTaxReturn6 | SectionTaxReturn7 | SectionTaxReturn8 | SectionTaxReturn9 | SectionTaxReturn10 | SectionTaxReturn11

export interface SectionTaxReturn1 {
    personalInfo: PersonalInfoTaxReturnModel,
    residence: AddressTaxReturnModel,
    domicileQuestion: boolean,
    domicileAnswer?: AddressTaxReturnModel,
    domicilePrevYearQuestion: boolean,
    domicilePrevYearAnswer?: AddressTaxReturnModel,
    tvRadioQuestion?: boolean,
    tvRadioAnswer?: TvRadioTaxReturnModel,
    allocate8x1000Question?: boolean,
    allocate8x1000Answer?: string,
    allocate5x1000Question?: boolean,
    allocate5x1000Answer?: string,
    allocate2x1000Question?: boolean,
    allocate5x1000FiscalCode?: string,
    allocate2x1000Answer?: string,
}

export interface SectionTaxReturn2 {
    marriedQuestion?: boolean,
    marriedAnswer?: MarriedTaxReturnModel,
    childrenQuestion?: boolean,
    childrenAnswer?: ChildrenTaxReturnModel[],
}

export interface SectionTaxReturn3 {
    landsAnswer?: LandsTaxReturnModel[],
    buildingsAnswer?: BuildingsTaxReturnModel[],
}

export interface SectionTaxReturn4 {
    receivedIncomeQuestion?: boolean,
    periodicAllowancesQuestion?: boolean,
    periodicAllowancesAnswer?: number,
}

export interface SectionTaxReturn5 {
    capitalIncomeQuestion?: boolean,
    capitalIncomeAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    selfEmploymentQuestion?: boolean,
    selfEmploymentAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    commercialActivitiesQuestion?: boolean,
    commercialActivitiesAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    sportsActivitiesQuestion?: boolean,
    sportsActivitiesAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    realEstateIncomeQuestion?: boolean,
    realEstateIncomeAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    intellectualWorksQuestion?: boolean,
    intellectualWorksAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
}

export interface SectionTaxReturn6 {
    inpsContributionsQuestion?: boolean,
    inpsContributionsAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
}

export interface SectionTaxReturn7 {
    electronicInvoicingQuestion?: boolean,
    serviceInvoicesQuestion?: boolean,
    serviceInvoicesAnswer?: ServiceInvoicesTaxReturnModel[],
    documentsWithholdingTaxQuestion?: boolean,
    documentsWithholdingTaxAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    meansTransportQuestion?: boolean,
    meansTransportAnswer?: number,
    purchaseGoodsQuestion?: boolean,
    purchaseGoodsAnswer?: number,
    financialFeeQuestion?: boolean,
    financialFeeAnswer?: number,
    rentalFeeQuestion?: boolean,
    rentalFeeAnswer?: number,
    royaltyFeeQuestion?: boolean,
    royaltyFeeAnswer?: number,
    fuelFeeQuestion?: boolean,
    fuelFeeAnswer?: number,
    electronicDevicesQuestion?: boolean,
    electronicDevicesAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
}

export interface SectionTaxReturn8 {
    propertyRenovationQuestion?: boolean,
    propertyRenovationPrevYearQuestion?: boolean,
    propertyRenovationPrevYearAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    medicalSpendingQuestion?: boolean,
    medicalSpendingAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    mortgageInterestMainHomeQuestion?: boolean,
    mortgageInterestMainHomeAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    mortgageInterestOtherHomesQuestion?: boolean,
    mortgageInterestOtherHomesAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    universitySpendingQuestion?: boolean,
    universitySpendingAnswer?: UniversitySpendingTaxReturnModel,
    startupQuestion?: boolean,
    startupAnswer?: StartupSpendingTaxReturnModel,
    maintenanceAllowanceQuestion?: boolean,
    maintenanceAllowanceAnswer?: MaintenanceAllowanceTaxReturnModel,
    lifeInsurancePremiumsQuestion?: boolean,
    lifeInsurancePremiumsAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    socialSecurityContributionsQuestion?: boolean,
    socialSecurityContributionsAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    otherSpendingQuestion?: boolean,
    otherSpendingAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
}

export interface SectionTaxReturn9 {
    bankAccountQuestion?: boolean,
    bankAccountAnswer?: BankAccountTaxReturnModel,
    multipleBankAccountQuestion?: boolean,
    propertiesQuestion?: boolean,
    propertiesAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    realEstateTaxesQuestion?: boolean,
    realEstateTaxesAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],
    otherInvestmentsQuestion?: boolean,
    otherInvestmentsAnswer?: string,
    continueQuestion?: boolean
}

export interface SectionTaxReturn10 {
    cryptoQuestion?: boolean,
    cryptoAnswer?: CryptoTaxReturnModel[],
    achieveProfitGainQuestion?: boolean,
    achieveProfitGainAnswer?: ObjectFileTaxReturnModel[] | ObjectModel[],

}

export interface SectionTaxReturn11 {
    splitPaymentsQuestion?: boolean,
    splitPaymentsAnswer?: number,
    useFreeServiceCreditQuestion?: boolean,
    useFreeServiceCreditAnswer?: FreeServiceCreditTaxReturnModel,
}

export interface PersonalInfoTaxReturnModel {
    name?: string,
    surname?: string,
    taxCode?: string,
    gender?: string,
    dateOfBirth?: string,
    birthPlace?: string,
    birthProvince?: string,
    category?: CategoryType,
    vat?: string,
    vatOpeningDate?: string,
    atecos?: string[],
}

export interface AddressTaxReturnModel {
    address?: string,
    zipCode?: string,
    province?: string,
    city?: string,
    codCatastale?: string;
    transferDate?: Date,
}

export interface TvRadioTaxReturnModel {
    subscriptionCode?: string,
    subscriptionDate?: Date,
}

export interface MarriedTaxReturnModel {
    taxCodeSpouse?: string,
    consortTaxDependent?: boolean,
}

export interface ChildrenTaxReturnModel {
    taxCodeChild: string,
    deductionChild?: string,
}

export interface FreeServiceCreditTaxReturnModel {
    iban?: string,
    serviceAccepted?: boolean,
}

export interface ObjectFileTaxReturnModel {
    objectId: string,
    name: DocumentsTypeTaxReturnModel,
}

export enum DocumentsTypeTaxReturnModel {
    CAPITAL_INCOME = "redditi_capitale",
    CU = "cu",
    RECEIPT = "ricevute",
    CU_SPORT = "cu_sport",
    REAL_ESTATE = "redditi_immobili_esteri",
    AUTHOR = "ricevuta_autore_inventore",
    PAYMENTS = "pagamenti",
    MEDICAL = "spese_mediche",
    MAIN_HOME_BANK = "certificazione_banca_ab_principale",
    OTHER_HOME_BANK = "certificazione_banca_altri_imm",
    UNIVERISITY = "universita",
    STARTUP = "startup",
    INSURANCE = "certificazione_assicurazione",
    CONTRIBUTION_CERTIFICATES = "certificazione_contributi_compl",
    CONTRIBUTION = "contributi",
    OTHER_SPENDING = "altre_spese",
    PROPERTIES = "immobili_esteri",
    REAL_ESTATE_TAXES = "imposte_immobili_anno_precedente",
    WITHHOLDING_TAX = "ricevuta_ritenuta",
    ELECTRONIC_DEVICES = "dispositivi_elettronici",
    CRYPTO = "certificazione_CRYPTO",
}

export interface LandsTaxReturnModel {
    province?: string,
    city?: string,
    foglio?: string,
    particella?: string,
    sub?: string,
    classe?: string,
    agriculturalIncome?: number,
    dominicalIncome?: number,
    possessionDaysFrom?: Date,
    possessionDaysTo?: Date,
    percentagePossession?: number,
    imuSubject?: boolean,
    haareca?: string,
    quality?: string,
    otherData?: string,
    isExternalSource?: boolean,
    isConfirmed?: boolean,
}

export interface BuildingsTaxReturnModel {
    province?: string,
    city?: string,
    foglio?: string,
    particella?: string,
    sub?: string,
    classe?: string,
    income?: number,
    landRegistryCategory?: string,
    landRegistryIncome?: number,
    possessionDaysFrom?: Date,
    possessionDaysTo?: Date,
    percentagePossession?: number,
    imuSubject?: boolean,
    usage?: string,
    shortRentals?: boolean,
    amountAnnualRent?: number,
    cedolareSecca?: boolean,
    leaseContract?: ObjectFileTaxReturnModel[] | ObjectModel[],
    zoneCategory?: string,
    address?: string,
    consistency?: string,
    isExternalSource?: boolean,
    isConfirmed?: boolean,
}

export interface ServiceInvoicesTaxReturnModel {
    consumptionType?: string,
    totalAnnualAmount?: number,
}

export interface UniversitySpendingTaxReturnModel {
    totalAmountPrevYear?: number,
    universityName?: string,
    graduatingClass?: string,
    spendingDocuments?: ObjectFileTaxReturnModel[] | ObjectModel[],
}

export interface StartupSpendingTaxReturnModel {
    totalAmountPrevYear?: number,
    spendingDocuments?: ObjectFileTaxReturnModel[] | ObjectModel[],
}

export interface MaintenanceAllowanceTaxReturnModel {
    totalAmountPrevYear?: number,
    taxCodeSpouse?: string,
}

export interface BankAccountTaxReturnModel {
    accountHolder?: string,
    accountOwnershipPerc?: number,
    bankAccountYear?: number,
    bankAccountDate?: Date,
}

export interface CryptoTaxReturnModel {
    cryptoType?: string,
    counterValue?: number,
    counterValuePrevYear?: number,
}