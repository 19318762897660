import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InvoiceModel } from "../../models/invoiceModel";
import { PromiseStatuses } from "../../types/strings";
import { PaginationModel } from "../../models/paginationModel";
import { OccasionalJobInvoiceService } from "../../services/occasionalJobInvoiceService";
import { InvoiceFilters } from "../../models/invoiceFilters";
import { CommonInvoiceService } from "../../services/commonInvoiceService";
import config from '../../../config.json';

interface OccasionalJobInvoiceListState {
    status: PromiseStatuses
    invoices: PaginationModel<InvoiceModel>;
    occasionalJobInvoiceIdToDuplicate?: string;
    page: number;
    companyNameFilter?: string;
    monthFilter?: string;
    yearFilter: string;
}

const initialState: OccasionalJobInvoiceListState = {
    status: 'idle',
    invoices: { content: [] },
    page: 0,
    yearFilter: new Date().getFullYear().toString()
}

export const getOccasionalJobInvoiceList = createAsyncThunk(
    'occasionalJobInvoice/getOccasionalJobInvoiceList',
    async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {
        return await new OccasionalJobInvoiceService().findAll(request);
    }
);

const OccasionalJobInvoiceSlice = createSlice({
    name: "occasionalJobInvoice",
    initialState,
    reducers: {
        setOccasionalJobInvoiceListPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setOccasionalJobInvoiceListCompanyNameFilter: (state, action: PayloadAction<string | undefined>) => {
            state.companyNameFilter = action.payload;
        },
        setOccasionalJobInvoiceListMonthFilter: (state, action: PayloadAction<string | undefined>) => {
            state.monthFilter = action.payload;
        },
        setOccasionalJobInvoiceListYearFilter: (state, action: PayloadAction<string>) => {
            state.yearFilter = action.payload;
        },
        setOccasionalJobInvoiceIdToDuplicate: (state, action: PayloadAction<string | undefined>) => {
            state.occasionalJobInvoiceIdToDuplicate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOccasionalJobInvoiceList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOccasionalJobInvoiceList.fulfilled, (state, action) => {
                let occasionalJobInvoices = CommonInvoiceService.filter(
                    'occasionalJob',
                    {
                        page: state.page,
                        companyName: state.companyNameFilter,
                        month: state.monthFilter,
                        year: state.yearFilter
                    },
                    action.payload
                );

                occasionalJobInvoices = CommonInvoiceService.sort(occasionalJobInvoices);

                occasionalJobInvoices = CommonInvoiceService.paginate(state.page, config.pageSize, occasionalJobInvoices);

                state.status = 'successfully';
                state.invoices = occasionalJobInvoices;
            })
            .addCase(getOccasionalJobInvoiceList.rejected, ((state) => {
                state.status = 'failed';
            }))
    }
})

export const {
    setOccasionalJobInvoiceListPage,
    setOccasionalJobInvoiceListCompanyNameFilter,
    setOccasionalJobInvoiceListMonthFilter,
    setOccasionalJobInvoiceListYearFilter,
    setOccasionalJobInvoiceIdToDuplicate
} = OccasionalJobInvoiceSlice.actions;

export default OccasionalJobInvoiceSlice.reducer