import './quickfiscoSpinner.css';

interface Props {
  alignCenter?: boolean;
  isWhite?: boolean;
}

export function QuickfiscoSpinner(props: Props) {
  const { alignCenter } = props;
  const { isWhite } = props;

  let classAlignCenter = '';
  if (alignCenter) {
    classAlignCenter = 'd-flex justify-content-center';
  }

  return (
    <div className={classAlignCenter}>
      <div
        className={`spinner-border ${
          isWhite ? 'quickfisco-spinner-white' : 'quickfisco-spinner'
        }`}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
