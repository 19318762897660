import { useEffect, useState } from 'react';
import { FeesRegisterModel } from '../../models/feesRegisterModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { feesRegisterEdit, feesRegisterFindAll, feesRegisterSave, initialState, setFeesRegisterAmountEu, setFeesRegisterAmountExtraEu, setFeesRegisterAmountIt, setFeesRegisterDate, setFeesRegisterDescription, setFeesRegisterFindByIdStatus, setResetFeesRegister } from '../../redux/slices/feesRegisterSlice';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './feesRegisterEdit.css';
import lang from './feesRegisterEdit.json';

interface Props {
    feesRegister: FeesRegisterModel,
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function FeesRegisterEditOrSave(props: Props) {
    const { feesRegister } = props;
    const { open } = props;
    const { setOpen } = props;

    const feesRegisterState = useAppSelector(state => state.feesRegister)

    const findByIdStatus = feesRegisterState.findByIdStatus
    const editStatus = feesRegisterState.editStatus
    const saveStatusSlice = feesRegisterState.saveStatus
    const editFeesRegister = feesRegisterState.feesRegisterToEdit

    const [saveStatus, setSaveStatus] = useState(true);

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (feesRegisterState.findByIdStatus === 'successfully') {
            setSaveStatus(false);
        }
    }, [feesRegisterState.findByIdStatus]);

    return (
        <QuickfiscoModal isOpen={open} hide={() => {
            setOpen(false);
            dispatch(setFeesRegisterFindByIdStatus('idle'));
            dispatch(setResetFeesRegister(initialState.feesRegisterToEdit))
        }} >
            {
                findByIdStatus === 'loading' ? (
                    <div className={'d-flex justify-content-center'}>
                        <QuickfiscoSpinner />
                    </div>
                ) : (
                    <div className={'p-4 pt-0'}>
                        <div className='row'>
                            <div className='col-auto m-auto'>
                                <span className='fees-register-edit-title'>
                                    {lang.title}
                                </span>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-auto m-auto'>
                                <span className='fees-register-edit-subtitle'>
                                    {lang.subtitle}
                                </span>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-6'>
                                <QuickfiscoInput
                                    id='fees-register-description-input'
                                    type={'text'}
                                    styleType={'default'}
                                    label={lang.description}
                                    placeholder={lang.placeholderDescription}
                                    defaultValue={feesRegister.description}
                                    onChange={(e) => dispatch(setFeesRegisterDescription(e.target.value))}
                                />
                            </div>
                            <div className='col-6'>
                                <QuickfiscoDatePicker
                                    id='fees-register-date-input'
                                    label={lang.date}
                                    disabled={true}
                                    value={new Date(feesRegister.date)}
                                    styleType={'default'}
                                />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-4'>
                                <QuickfiscoInput
                                    id='fees-register-amount-it-input'
                                    type={'text'}
                                    styleType={'default'}
                                    label={lang.itAmount}
                                    placeholder={lang.amountPlaceholder}
                                    onChange={e => { dispatch(setFeesRegisterAmountIt(parseNumber(e.target.value))) }}
                                    defaultValue={formatNumberIT(feesRegister.amount_IT)}
                                />
                            </div>
                            <div className='col-4'>
                                <QuickfiscoInput
                                    id='fees-register-amount-eu-input'
                                    type={'text'}
                                    styleType={'default'}
                                    label={lang.euAmount}
                                    placeholder={lang.amountPlaceholder}
                                    onChange={e => { dispatch(setFeesRegisterAmountEu(parseNumber(e.target.value))) }}
                                    defaultValue={formatNumberIT(feesRegister.amount_EU)}
                                />
                            </div>
                            <div className='col-4'>
                                <QuickfiscoInput
                                    id='fees-register-amount-extra-eu-input'
                                    type={'text'}
                                    styleType={'default'}
                                    label={lang.extraEuAmount}
                                    placeholder={lang.amountPlaceholder}
                                    onChange={e => { dispatch(setFeesRegisterAmountExtraEu(parseNumber(e.target.value))) }}
                                    defaultValue={formatNumberIT(feesRegister.amount_EXTRA_EU)}
                                />
                            </div>
                        </div>
                        <div className='row mt-3 justify-content-center align-items-center'>
                            <div className='col-6 d-flex justify-content-center align-items-center'>
                                {
                                    editStatus === 'loading' || saveStatusSlice ==='loading' ? (
                                        <QuickfiscoSpinner />
                                    ) : (
                                        <QuickfiscoButton
                                            id={'fees-register-editOrSave'}
                                            type={'primary'}
                                            label={lang.buttonLabel}
                                            onClick={() => saveStatus ? 
                                                (
                                                    
                                                    dispatch(feesRegisterSave({
                                                feesRegister: { ...editFeesRegister, date: feesRegister.date }
                                            }))
                                                .then(() => {
                                                    dispatch(feesRegisterFindAll({
                                                        month: feesRegisterState.monthFilter,
                                                        year: feesRegisterState.yearFilter
                                                    }));
                                                    setOpen(false)
                                                    dispatch(setFeesRegisterFindByIdStatus('idle'))
                                                    dispatch(setResetFeesRegister(initialState.feesRegisterToEdit))
                                                }) ): dispatch(feesRegisterEdit({
                                                    id: feesRegister.id,
                                                    feesRegister: editFeesRegister
                                                }))
                                                    .then(() => {
                                                        dispatch(feesRegisterFindAll({
                                                            month: feesRegisterState.monthFilter,
                                                            year: feesRegisterState.yearFilter
                                                        }));
                                                        setOpen(false)
                                                        dispatch(setFeesRegisterFindByIdStatus('idle'))
                                                        dispatch(setResetFeesRegister(initialState.feesRegisterToEdit))
                                                    })
                                            }
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </QuickfiscoModal >
    );
}
