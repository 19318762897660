import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setOpenSendEmailModal } from '../../redux/slices/financeSlice';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import './financeSendEmailModal.css';

export function FinanceSendEmailModal() {
  const financeState = useAppSelector((state) => state.finance);

  const dispatch = useAppDispatch();

  return (
    <QuickfiscoModal
      isOpen={financeState.openSendEmailModal}
      hide={() => dispatch(setOpenSendEmailModal(false))}
    >
      <div className={'row mb-4 p-4 pt-0'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <QuickfiscoIcon
            name={'check-circle.svg'}
            className="finance-send-email-modal-icon"
          />
          <p className="finance-send-email-modal-text">
            <span className="finance-send-email-modal-title">
              Richiesta inviata
            </span>
            <br />
            <br />
            Abbiamo ricevuto la tua richiesta, risponderemo entro 48h
            lavorative.
          </p>
          <span className="finance-send-email-modal-info">
            Ti contatteremo noi attraverso i canali ufficiali (mail o whatsapp).
          </span>
          <span className="finance-send-email-modal-info">
            <b>Grazie per la tua collaborazione!</b>
          </span>
        </div>

        {/* <div className={'row'}>
                    <div className={'col-12'}>
                        <p className={'cancel-sts-sending-title'}>
                            {lang.title}
                        </p>
                        <p className={'cancel-sts-sending-text'}>
                            Proseguendo verrà inviata al Sistema TS. <br />
                            Potrai comunque <b>comunicare una variazione</b> o <b>annullare l’invio
                                successivamente</b>. <br />
                            Puoi inviare anche una {' '}
                            <span className={'cancel-sts-sending-blue'}>
                                copia via e-mail al tuo cliente.
                            </span>
                        </p>
                    </div>
                </div>
                <div className={'row mt-2'}>
                    {
                        globalStatus === 'loading' ? (
                            <div className={'col-12 d-flex align-items-center justify-content-center'}>
                                <QuickfiscoSpinner />
                            </div>
                        ) : (
                            <div className={'col-12'}>
                                <div className={'row'}>
                                    <div className={'col-7'}>
                                        <QuickfiscoButton
                                            id={'cancel-sts-sending-email'}
                                            label={lang.labelEmail}
                                            onClick={() => send(invoiceId, true, stsInvoiceFilters, dispatch, navigate, setOpen)}
                                            type={'primary'}
                                        />
                                    </div>
                                    <div className={'col-5'}>
                                        <QuickfiscoButton
                                            id={'cancel-sts-sending'}
                                            label={lang.labelSts}
                                            type={'secondary'}
                                            onClick={() => send(invoiceId, false, stsInvoiceFilters, dispatch, navigate, setOpen)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div> */}
      </div>
    </QuickfiscoModal>
  );
}
