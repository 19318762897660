import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  CryptoTaxReturnModel,
  DocumentsTypeTaxReturnModel,
  ObjectFileTaxReturnModel,
  SectionTaxReturn10,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { CryptoAnswersInput } from '../cryptoAnswersInput/cryptoAnswersInput';
import { QuickfiscoFileList } from '../quickfiscoFileList.css/quickfiscoFileList';
import { TaxReturnSection10Informations } from '../taxReturnSection10Informations/taxReturnSection10Informations';
import { SetState } from '../../types/functions';
import { UserModel } from '../../models/userModel';
import { counterFileRegex } from '../../regex/regex';
import { getExtension, toBase64, validateFile } from '../../utils/file';
import { sanitizeString } from '../../utils/string';
import lang from './taxReturnSection10.json';
import './taxReturnSection10.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn10) => void;
  onClickPrevStep: (data: SectionTaxReturn10) => void;
  downloadDoc: (docId: string) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
  scrollPosition?: number;
}

export function TaxReturnSection10(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { downloadDoc } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;
  const { scrollPosition } = props;

  const user = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [cryptoQuestion, setCryptoQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [cryptoAnswer, setCryptoAnswer] = useState<CryptoTaxReturnModel[]>([
    {
      cryptoType: '',
      counterValue: undefined,
      counterValuePrevYear: undefined,
    },
  ]);
  const [achieveProfitGainQuestion, setAchieveProfitGainQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [achieveProfitGainAnswer, setAchieveProfitGainAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);

  const [statusAchieveProfitGain, setStatusAchieveProfitGain] =
    useState<PromiseStatuses>('idle');
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (skypScroll?: boolean): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(10)
      .then((res) => {
        setLoadingStatus('successfully');
        scrollPosition && refreshApiCall(scrollPosition, skypScroll);
        const data = res as SectionTaxReturn10;
        setCryptoQuestion(data.cryptoQuestion);
        setCryptoAnswer(
          data.cryptoAnswer || [
            {
              cryptoType: '',
              counterValue: undefined,
              counterValuePrevYear: undefined,
            },
          ]
        );
        setAchieveProfitGainQuestion(data.achieveProfitGainQuestion);
        setAchieveProfitGainAnswer(
          data.achieveProfitGainAnswer as ObjectFileTaxReturnModel[]
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep();
  }, []);

  useEffect(() => {
    if (cryptoQuestion === false) {
      setCryptoAnswer([
        {
          cryptoType: '',
          counterValue: undefined,
          counterValuePrevYear: undefined,
        },
      ]);
    }
  }, [cryptoQuestion]);

  useEffect(() => {
    if (achieveProfitGainQuestion === false) {
      setAchieveProfitGainAnswer(undefined);
    }
  }, [achieveProfitGainQuestion]);

  useEffect(() => {
    setCheckgoNext(taxReturnService.validateSection10(dataToSend));
  }, [
    cryptoQuestion,
    cryptoAnswer,
    achieveProfitGainQuestion,
    achieveProfitGainAnswer,
  ]);

  const dataToSend: SectionTaxReturn10 = {
    cryptoQuestion,
    cryptoAnswer: cryptoQuestion === true ? cryptoAnswer : undefined,
    achieveProfitGainQuestion,
    achieveProfitGainAnswer:
      achieveProfitGainQuestion === true ? achieveProfitGainAnswer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-10-title">{lang.title}</div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <TaxReturnSection10Informations />
              </div>
            </div>
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-10-crypto-question"
                  title={lang.cryptoQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={cryptoQuestion}
                  onChange={(checked) => setCryptoQuestion(checked)}
                  required={true}
                />
              </div>
            </div>
            {cryptoQuestion === true && (
              <CryptoAnswersInput
                id="tax-return-section-10-crypto-answers"
                cryptoAnswers={cryptoAnswer}
                setCryptoAnswers={(cryptoAnswers) =>
                  setCryptoAnswer(cryptoAnswers)
                }
              />
            )}
            <div className={'row no-gutters'}>
              <div className="col-12">
                <hr className="mt-5 mb-4" />
              </div>
            </div>
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-10-achieve-profit-gain-question"
                  title={lang.achieveProfitGainQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={achieveProfitGainQuestion}
                  onChange={(checked) => {
                    setAchieveProfitGainQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        dataToSend,
                        setStatusAchieveProfitGain,
                        DocumentsTypeTaxReturnModel.CRYPTO,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusAchieveProfitGain('idle');
                    }
                  }}
                  required={true}
                />
              </div>
            </div>
            {achieveProfitGainQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusAchieveProfitGain}
                      title={lang.achieveProfitDocTitle}
                      fileList={achieveProfitGainAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          dataToSend,
                          setStatusAchieveProfitGain,
                          DocumentsTypeTaxReturnModel.CRYPTO,
                          achieveProfitGainQuestion,
                          getSurveyStep,
                          doc,
                          achieveProfitGainAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusAchieveProfitGain,
                          docId,
                          index,
                          setAchieveProfitGainAnswer,
                          achieveProfitGainAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusAchieveProfitGain}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-10-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-10-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-10-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-10-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function saveQuestion(
  dataToSend: SectionTaxReturn10,
  setStatus: SetState<PromiseStatuses>,
  docType: DocumentsTypeTaxReturnModel,
  question: boolean,
  getSurveyStep: Function,
  doc?: File | null,
  answer?: ObjectFileTaxReturnModel[],
  user?: UserModel
): void {
  const keyObjTypeMap = new Map<DocumentsTypeTaxReturnModel, string>([
    [DocumentsTypeTaxReturnModel.CRYPTO, 'achieveProfitGain'],
  ]);

  const questionKey = keyObjTypeMap.get(docType) + 'Question';
  const answerKey = keyObjTypeMap.get(docType) + 'Answer';
  let objToSend = {};
  const counterRegex =
    answer && answer.length > 0
      ? answer[answer.length - 1].name.match(counterFileRegex)
      : undefined;
  const counter = counterRegex ? Number(counterRegex[0].split('.')[0]) + 1 : 1;

  setStatus('loading');

  const service = new TaxReturnService();

  if (question === true) {
    if (!doc || !user || !counter) {
      return;
    }

    if (!validateFile(doc)) {
      setStatus('failed');
      return;
    }

    const fileName = sanitizeString(
      `${docType}_${user.surname}_${user.name}_${counter}`
    ).toLowerCase();

    toBase64(doc)
      .then((res) => {
        const fileToSend = [
          {
            name: fileName + '.' + getExtension(doc),
            extension: getExtension(doc),
            file: res,
          },
        ];
        objToSend = {
          [questionKey]: question,
          [answerKey]: fileToSend,
        };
        service
          .setSurveyStep(10, { ...dataToSend, ...objToSend })
          .then(() => {
            setStatus('successfully');
            getSurveyStep();
          })
          .catch((err) => {
            setStatus('failedUploadApi');
            getSurveyStep();
            console.error(err);
          });
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        console.error(err);
      });
  } else {
    objToSend = {
      [questionKey]: question,
    };
    service
      .setSurveyStep(10, { ...dataToSend, ...objToSend })
      .then(() => {
        setStatus('successfully');
        getSurveyStep();
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        getSurveyStep();
        console.error(err);
      });
  }
}

function deleteDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<ObjectFileTaxReturnModel[] | undefined>,
  answer?: ObjectFileTaxReturnModel[]
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(10, docId)
      .then(() => {
        if (answer) {
          const newAnswer = answer
            .slice(0, index)
            .concat(answer.slice(index + 1));
          setAnswer([...newAnswer]);
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}
