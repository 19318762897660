import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStep } from '../../redux/slices/taxProfileSlice';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import './taxProfileWelcome.css';
import lang from './taxProfileWelcome.json';

export function TaxProfileWelcome() {
  const userState = useAppSelector(state => state.user);

  const dispatch = useAppDispatch();

  return (
    <div className={'tax-profile-welcome-container'}>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <p className={'tax-profile-welcome-blue mt-4'}>
            {lang.title}
            <span className={'fw-bold'}>
              {userState.user?.name}!
            </span>
          </p>
        </div>
      </div>
      <div className={'row mt-1'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <p className={'tax-profile-welcome-text mb-5'}>
            {lang.subtitle}
            <span className={'fw-bold'}>
              {lang.subtitleBold}
            </span>
          </p>
        </div>
      </div>
      <div className={'row d-flex justify-content-center'}>
        <div className={'col-6'}>
          <QuickfiscoButton
            id={'tax-calculation-trial-user'}
            label={lang.label}
            type={'secondary'}
            onClick={() => dispatch(setStep('vatOpeningDate'))}
          />
        </div>
      </div>
    </div>
  );
}
