import { OptionModel } from '../../models/optionModel';
import { CategoryType } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string
  onChange?: ((year: string) => void);
  category?: string;
}

export function QuickfiscoTaxCalculationYearSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;

  const userState = useAppSelector(state => state.user)

  return (
    <QuickfiscoSelect
      id={id}
      options={getOptions(value, userState.user.fund, userState.user.category)}
      onChange={(e) => e && e.value && onChange && onChange(e.value)}
      styleType={'no-borders'}
      value={getOptions(value, userState.user.fund, userState.user.category).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string, fund?: string, category?: CategoryType): OptionModel[] {
  const options: OptionModel[] = []
  const currentYear = (category !== CategoryType.FUND || (fund !== "TC01" && fund !== "TC04" && fund !== "TC21")) ? new Date().getFullYear() : (new Date().getFullYear() + 1);
  const startYear = new Date().getFullYear();
  const index = currentYear - startYear;

  for (let i = 0; i <= index; i++) {
    options.push({
      label: (startYear + i).toString(),
      value: (startYear + i).toString(),
      selected: value === (startYear + i).toString()
    }
    )
  }

  return options.reverse()
}
