import { useState } from 'react';
import config from '../../../config.json';
import { useAppDispatch } from '../../redux/hooks';
import { setFinanceStep } from '../../redux/slices/financeSlice';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { LogoDim, QuickfiscoLogo } from '../quickfiscoLogo/quickfiscoLogo';
import './financeAuthorization.css';

export function FinanceAuthorization() {
  const [authorize, setAuthorize] = useState<boolean>(false);
  const [authorizeError, setAuthorizeError] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  return (
    <div className={'financeAuthorization-container'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ flex: '0.6 1 0', padding: '0 55px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 -55px',
            }}
          >
            <QuickfiscoLogo id={''} dim={LogoDim.SMALL} />
            <span
              style={{
                textAlign: 'center',
                font: 'normal normal normal 10px/24px Poppins',
                color: '#1E1E23',
              }}
            >
              in partnership con
            </span>
            <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
          </div>
        </div>
      </div>
      <span
        style={{
          textAlign: 'center',
          font: 'normal normal 600 20px/30px Poppins',
          color: '#1E1E23',
        }}
      >
        Autorizzazione alla condivisione dei tuoi dati
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className="financeAuthorization-container--purple">
          <p>
            Faicredit è la soluzione di Sella Personal Credit per freelance e
            aziende che hanno bisogno di liquidità.
          </p>
          <p>
            Faicredit è partner di Quickfisco per la gestione di richieste di
            finanziamento fino a 35.000€.
          </p>
          <p>
            Scopri come ottenere subito un finanziamento, completa la richiesta
            in pochi passaggi e ricevi un esito in meno di 48h.
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
            className="mt-5"
          >
            <QuickfiscoCheckbox
              fontNormal
              key={'financeAuthorization-authorize'}
              checked={authorize}
              error={authorizeError}
              onChange={() => setAuthorize(!authorize)}
              id={''}
              label="Autorizzo QuickFisco a condividere i dati della mia richiesta di prestito, i miei dati reddituali, carta d’identità e codice fiscale a Faicredit per completare la richiesta di finanziamento"
            />
            <span className="financeAuthorization-checkbox-info ms-3 mt-2">
              {'(campo obbligatorio)'}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ flex: '0.6 1 0', padding: '0 55px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 -55px',
            }}
          >
            <div style={{ flexGrow: 0.3 }}>
              <QuickfiscoButton
                id={'finance-authorization-back-button'}
                key={'finance-authorization-back-button'}
                label={'ANNULLA'}
                onClick={() => dispatch(setFinanceStep(0))}
                type={'primary'}
              />
            </div>
            <div style={{ flexGrow: 0.3 }}>
              <QuickfiscoButton
                id={'finance-authorization-next-button-4'}
                key={'finance-authorization-next-button'}
                label={'ACCETTA'}
                onClick={() => {
                  if (!authorize) {
                    setAuthorizeError(true);
                  } else {
                    dispatch(setFinanceStep(4));
                  }
                }}
                type={'secondary'}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <QuickfiscoIcon name={'faicreditlogo-footer.svg'} />
        <span className="financeCheckFinanceability-partnership-text">
          in partnership con
        </span>
        <QuickfiscoIcon name={'sella.svg'} />
        <div
          className="financeCheckFinanceability-partnership-text-privacy"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <span>
            <b>faicredit</b> è un servizio di Faire Agent S.r.l. (P.IVA
            11592420969), agente in attività finanziaria iscritto all’OAM con il
            numero A13955.
          </span>
          <span>
            Consulta la{' '}
            <span
              className="finance-privacy-policy-link"
              onClick={() => {
                window.open(
                  config.faicreditPrivacy,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              {'privacy policy'}
            </span>
            . Copyright © 2023 faire.ai - Tutti i diritti riservati | Via Santa
            Maria Valle, 3 - 20123 Milano (MI) | P.IVA 11235270961
          </span>
        </div>
      </div>
    </div>
  );
}
