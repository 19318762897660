import config from '../../../config.json';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoError.css';

interface Props {
  active: boolean;
  close: () => void;
  hideCloseBtn?: boolean;
  message?: string;
}

export function QuickfiscoError(props: Props) {
  const { active } = props;
  const { close } = props;
  const { hideCloseBtn } = props;
  const { message } = props;

  if (!active) {
    return null;
  }

  setTimeout(() => close(), config.hidePopupTimeout);

  return (
    <div className={'quickfisco-error p-5 text-center'}>
      {!hideCloseBtn && (
        <div className={'quickfisco-success-close'}>
          <QuickfiscoIcon
            isButton={true}
            name={'close-icon-black.svg'}
            onClick={() => close()}
            className={'quickfisco-error-close'}
          />
        </div>
      )}
      <QuickfiscoIcon
        name={'alert-error.svg'}
        className={'quickfisco-error-icon'}
      />
      <div className={'mt-3'}>
        <span className={'quickfisco-error-text'}>{message}</span>
      </div>
    </div>
  );
}
