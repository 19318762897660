import { useState } from 'react';
import useNewInvoice from '../../hooks/newInvoice';
import { GoodServicesModel } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setProformaInvoiceGoodServices,
  setStsIsActive,
} from '../../redux/slices/proformaInvoiceSlice';
import { InvoiceSign, Operation } from '../../types/strings';
import { QuickfiscoSwitchInput } from '../quickfiscoSwitchInput/quickfiscoSwitchInput';
import { StsInvoiceRejected } from '../stsInvoiceRejected/stsInvoiceRejected';
import { DataSendSelect } from '../dataSendSelect/dataSendSelect';
import { TrackedPaymentSelect } from '../trackedPaymentSelect/trackedPaymentSelect';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import './switchToCustomerInvoice.css';
import lang from './switchToCustomerInvoice.json';

interface Props {
  operation: Operation;
  trackedPayment?: string;
  setTrackedPayment: (trackedPayment?: string) => void;
  notSendData?: boolean;
  setNotSendData: (notSendData: boolean) => void;
  errorTrackedPayment?: boolean;
  errorSendData?: boolean;
  disabled?: boolean;
  invoiceSign: InvoiceSign;
  goodServices?: GoodServicesModel[] | null;
}

export function SwitchToCustomerInvoice(props: Props) {
  const { operation } = props;
  const { trackedPayment } = props;
  const { setTrackedPayment } = props;
  const { notSendData } = props;
  const { setNotSendData } = props;
  const { errorTrackedPayment } = props;
  const { errorSendData } = props;
  const { disabled } = props;
  const { invoiceSign } = props;
  const { goodServices } = props;

  const [open, setOpen] = useState<boolean>(false);

  const userState = useAppSelector(state => state.user);

  const newInvoice = useNewInvoice(invoiceSign);
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={'switch-to-customer-container p-4'}>
        <div className={'row align-items-center'}>
          <div className={'col-auto'}>
            <QuickfiscoSwitchInput
              id={'switch-to-sts'}
              label={lang.label}
              checked={true}
              onChange={() => setOpen(true)}
              disabled={invoiceSign === 'customer' && operation !== 'save' || userState.user.importInvoices?.needToImportInvoices===true}
            />
          </div>
        </div>
        <div className={'row mt-4'}>
          <div className={'col-5'}>
            <DataSendSelect
              id={'switch-to-customer-invoice-data-send'}
              value={
                notSendData === true
                  ? 'true'
                  : notSendData === false
                    ? 'false'
                    : undefined
              }
              disabled={disabled}
              onChange={(_dataSend) => {
                setNotSendData(_dataSend === 'true' ? true : false);
              }}
              error={errorSendData}
              errorLabel={lang.errorSendData}
            />
          </div>
          <div className={'col-1 p-0'}>
            <StsInvoiceRejected error={lang.info} />
          </div>
          <div className={'col-6'}>
            <TrackedPaymentSelect
              id="switch-to-customer-invoice-tracked-payment"
              onChange={(_trackedPayment) => setTrackedPayment(_trackedPayment)}
              disabled={disabled}
              value={trackedPayment}
              error={errorTrackedPayment}
              errorLabel={lang.errorTrackedPayment}
            />
          </div>
        </div>
      </div>
      <QuickfiscoModal
        modalClassName={'switch-to-customer-modal-component'}
        isOpen={open}
        hide={() => {
          setOpen(false);
        }}
      >
        <div className={'p-5'}>
          <div className={'row text-center'}>
            <div className="col-10 offset-1">
              <div className={'row'}>
                <div className="col-12">
                  <div className="switch-to-customer-modal-title">
                    {lang.modalTitle}
                  </div>
                  <div className="switch-to-customer-modal-text mt-3">
                    <b>
                      {invoiceSign !== 'proforma'
                        ? lang.modalTextBold
                        : lang.modalProformaTextBold}
                    </b>
                    {invoiceSign !== 'proforma' && lang.modalText}
                  </div>
                  <div className="switch-to-customer-modal-question mt-3">
                    {lang.modalConfirmQuestion}
                  </div>
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className="col-6">
                  <QuickfiscoButton
                    id="register-btn"
                    label={lang.modalBack}
                    type={'primary'}
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className="col-6">
                  <QuickfiscoButton
                    id="register-btn"
                    label={lang.modalSwitch}
                    type={'secondary'}
                    onClick={() => {
                      if (invoiceSign === 'proforma') {
                        switchToProforma(
                          dispatch,
                          setTrackedPayment,
                          setNotSendData,
                          goodServices
                        );
                      } else {
                        newInvoice();
                      }
                    }}
                  />
                </div>
              </div>
              <div className={'row mt-5'}>
                <div className="col-12">
                  <div className="switch-to-customer-modal-guide-text">
                    {lang.modalGuideText}
                  </div>
                  <div className="switch-to-customer-modal-guide-text-link">
                    <a
                      href={
                        'https://quickfisco.it/tutorial/settare-il-profilo-sts/'
                      }
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      {lang.modalSettingStsProfile}
                    </a>
                  </div>
                  <div className="switch-to-customer-modal-guide-text-link">
                    <a
                      href={
                        'https://quickfisco.it/tutorial/fattura-elettronica-sts/'
                      }
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      {lang.modalSettingStsInvoice}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </>
  );
}

function switchToProforma(
  dispatch: Function,
  setTrackedPayment: (trackedPayment?: string) => void,
  setNotSendData: (notSendData: boolean) => void,
  goodServices?: GoodServicesModel[] | null
) {
  if (goodServices === undefined || goodServices === null) {
    return;
  }
  const newGoodServices = [...goodServices];
  goodServices.forEach((_, index) => {
    newGoodServices[index] = {
      ...goodServices[index],
      expenseTypeFlag: undefined,
      expenseType: undefined,
    };
    dispatch(setProformaInvoiceGoodServices([...newGoodServices]));
  });
  setTrackedPayment(undefined);
  setNotSendData(false);
  dispatch(setStsIsActive(false));
}
