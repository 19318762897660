import lang from './taxTimetable.json';
import './taxTimetable.css';

export function TaxTimetable() {
  const getMonthName = (index: number): string => {
    switch (index) {
      case 0:
        return lang.jan;
      case 1:
        return lang.feb;
      case 2:
        return lang.mar;
      case 3:
        return lang.apr;
      case 4:
        return lang.may;
      case 5:
        return lang.jun;
      case 6:
        return lang.jul;
      case 7:
        return lang.aug;
      case 8:
        return lang.sep;
      case 9:
        return lang.oct;
      case 10:
        return lang.nov;
      case 11:
        return lang.dec;
      default:
        return lang.jan;
    }
  };

  let events = lang.events.map((months, indexMonth) => {
    return (
      <div className={'row'} key={indexMonth + '-tax-deadline-month'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className="tax-time-table-sub-title">
              {getMonthName(indexMonth)} {new Date().getFullYear().toString()}
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12'}>
            {months.map((month, indexEvent) => {
              return (
                <div
                  className={`row ${indexEvent > 0 ? 'mt-3' : 'mt-1'}`}
                  key={indexMonth + indexEvent + '-tax-deadline-month-event'}
                >
                  <div className={'col-12'}>
                    <div className="tax-time-table-event">
                      <strong>{month.date}</strong> {month.event}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={'row my-3'}>
              {indexMonth < 11 && <div className={'col-12'}>—</div>}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={'tax-time-table-container'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <div className={'tax-time-main-title'}>
            {lang.title} {new Date().getFullYear().toString()}
          </div>
        </div>
      </div>
      <div className={'row mt-4'}>
        <div className={'col-12'}>{events}</div>
      </div>
    </div>
  );
}
