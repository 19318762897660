import { useNavigate } from 'react-router-dom';
import { CurrencyType, InvoiceStatusType, InvoiceType, PaymentTermsType } from "../models/invoiceModel";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setAutoInvoiceSaveOrDuplicateOrEditOperation } from "../redux/slices/autoInvoiceSaveOrDuplicateOrEditSlice";
import { setAutoInvoice, setAutoInvoiceFileList } from "../redux/slices/autoInvoiceSlice";
import { setCustomerInvoiceSaveOrDuplicateOrEditOperation } from "../redux/slices/customerInvoiceSaveOrDuplicateOrEditSlice";
import { setCustomerInvoice, setCustomerInvoiceFileList, setCustomerInvoiceReimbursementsOfExpenses } from "../redux/slices/customerInvoiceSlice";
import { setNewContactToInvoice, setNewContactToInvoiceSign, setNewContactToInvoiceStatus } from "../redux/slices/newContactToInvoiceSlice";
import { setProformaInvoiceSaveOrDuplicateOrEditOperation } from "../redux/slices/proformaInvoiceSaveOrDuplicateOrEditSlice";
import { setProformaInvoice, setProformaInvoiceFileList, setProformaInvoiceReimbursementsOfExpenses } from "../redux/slices/proformaInvoiceSlice";
import { setStsInvoiceSaveOrDuplicateOrEditOperation } from "../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice";
import { setStsInvoice, setStsInvoiceFileList } from "../redux/slices/stsInvoiceSlice";
import { setSupplierInvoiceSaveOrEditOperation } from "../redux/slices/supplierInvoiceSaveOrEditSlice";
import { setSupplierInvoice, setSupplierInvoiceFileList } from "../redux/slices/supplierInvoiceSlice";
import uri from '../routers/quickfiscoUri.json';
import { InvoiceSign } from "../types/strings";
import { ContactType } from '../models/contactModel';

export default function useNewInvoice(invoiceSign: InvoiceSign): () => void {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const userState = useAppSelector(state => state.user)
    const stsProfileState = useAppSelector(state => state.stsProfile)

    const user = userState.user
    const stsProfile = stsProfileState.profile

    return () => {
        dispatch(setNewContactToInvoiceStatus('idle'));
        dispatch(setNewContactToInvoice(undefined));

        if (invoiceSign === 'supplier') {
            dispatch(setSupplierInvoice({
                date: new Date(),
                type: InvoiceType.TD01,
                status: InvoiceStatusType.RECEIVED,
                currency: CurrencyType.EUR,
                stamp: false,
                amount: 0,
                taxBase: 0,
                goodServices: [],
                socialContribution: false,
                socialContributionAmount: 0,
                reimbursementsOfExpenses: [],
                reimbursementsOfExpensesAmount: 0,
                manual: true,
                isPaymentDue: false,
                trackedPayment: false,
                customerDataCommunication: false,
                payments: [],
                documents: [],
                additionalDatasPa: [],
                customer: {
                    bankAccount: {
                        bankIban: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.iban || "",
                        bankName: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.name || ""
                    } || "", type: ContactType.SUPPLIER
                }
            }));
            dispatch(setSupplierInvoiceFileList([]));
            dispatch(setNewContactToInvoiceSign('supplier'));
            dispatch(setSupplierInvoiceSaveOrEditOperation('save'));

            navigate(uri.SupplierInvoiceSave);
        } else if (invoiceSign === 'customer') {
            dispatch(setCustomerInvoice({
                date: new Date(),
                type: InvoiceType.TD01,
                status: InvoiceStatusType.DRAFT,
                ateco: user && user.atecos && user.atecos[0],
                currency: CurrencyType.EUR,
                stamp: false,
                amount: 0,
                taxBase: 0,
                goodServices: [],
                socialContribution: false,
                socialContributionAmount: 0,
                reimbursementsOfExpenses: [],
                reimbursementsOfExpensesAmount: 0,
                manual: true,
                isPaymentDue: false,
                trackedPayment: false,
                customerDataCommunication: false,
                payments: [],
                documents: [],
                additionalDatasPa: [],
                supplier: {
                    bankAccount: {
                        bankIban: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.iban || "",
                        bankName: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.name || ""
                    }
                        || "", type: ContactType.CUSTOMER

                }
            }));
            dispatch(setCustomerInvoiceFileList([]));
            dispatch(setCustomerInvoiceReimbursementsOfExpenses([]));
            dispatch(setNewContactToInvoiceSign('customer'));
            dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('save'));

            navigate(uri.CustomerInvoiceSave);
        } else if (invoiceSign === 'proforma') {
            dispatch(setProformaInvoice({
                date: new Date(),
                type: InvoiceType.TD01,
                status: InvoiceStatusType.DRAFT,
                ateco: user && user.atecos && user.atecos[0],
                currency: CurrencyType.EUR,
                stamp: false,
                amount: 0,
                taxBase: 0,
                goodServices: [],
                socialContribution: false,
                socialContributionAmount: 0,
                reimbursementsOfExpenses: [],
                reimbursementsOfExpensesAmount: 0,
                manual: true,
                isPaymentDue: false,
                trackedPayment: stsProfile !== undefined ? stsProfile.trackedPayment : false,
                customerDataCommunication: undefined,
                payments: [],
                documents: [],
                additionalDatasPa: [],
                stsIsActive: user.stsEnabled,
                supplier: {
                    bankAccount: {
                        bankIban: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.iban || "",
                        bankName: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.name || ""
                    }
                        || "", type: ContactType.CUSTOMER

                }
            }));
            dispatch(setProformaInvoiceFileList([]));
            dispatch(setProformaInvoiceReimbursementsOfExpenses([]));
            dispatch(setNewContactToInvoiceSign('proforma'));
            dispatch(setProformaInvoiceSaveOrDuplicateOrEditOperation('save'));

            navigate(uri.ProformaInvoiceSave);
        } else if (invoiceSign === 'sts') {
            dispatch(setStsInvoice({
                date: new Date(),
                type: InvoiceType.TD01,
                status: InvoiceStatusType.DRAFT,
                paymentExpiration: new Date(),
                paymentTerms: PaymentTermsType.IMMEDIATE,
                ateco: user && user.atecos && user.atecos[0],
                currency: CurrencyType.EUR,
                stamp: false,
                amount: 0,
                taxBase: 0,
                goodServices: [],
                socialContribution: false,
                socialContributionAmount: 0,
                reimbursementsOfExpenses: [],
                reimbursementsOfExpensesAmount: 0,
                manual: true,
                isPaymentDue: false,
                trackedPayment: stsProfile !== undefined ? stsProfile.trackedPayment : false,
                customerDataCommunication: undefined,
                payments: [],
                documents: [],
                additionalDatasPa: [],
                supplier: {
                    bankAccount: {
                        bankIban: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.iban || "",
                        bankName: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.name || ""
                    }
                        || "", type: ContactType.CUSTOMER

                }
            }));
            dispatch(setStsInvoiceFileList([]));
            dispatch(setNewContactToInvoiceSign('sts'));
            dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('save'));

            navigate(uri.StsInvoiceSave);
        } else if (invoiceSign === 'auto') {
            dispatch(setAutoInvoice({
                date: new Date(),
                status: InvoiceStatusType.DRAFT,
                paymentExpiration: new Date(),
                paymentTerms: PaymentTermsType.IMMEDIATE,
                ateco: user && user.atecos && user.atecos[0],
                currency: CurrencyType.EUR,
                stamp: false,
                amount: 0,
                taxBase: 0,
                goodServices: [],
                socialContribution: false,
                socialContributionAmount: 0,
                reimbursementsOfExpenses: [],
                reimbursementsOfExpensesAmount: 0,
                manual: true,
                isPaymentDue: false,
                trackedPayment: false,
                customerDataCommunication: false,
                payments: [],
                documents: [],
                additionalDatasPa: [],
                customer: {
                    bankAccount: {
                        bankIban: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.iban || "",
                        bankName: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.name || ""
                    } || "", type: ContactType.SUPPLIER
                }
            }));
            dispatch(setAutoInvoiceFileList([]));
            dispatch(setNewContactToInvoiceSign('auto'));
            dispatch(setAutoInvoiceSaveOrDuplicateOrEditOperation('save'));

            navigate(uri.AutoInvoiceSave);
        } else if (invoiceSign === 'occasionalJob') {
            navigate(uri.OccasionalJobInvoiceSaveOrDuplicate);
        } else if (invoiceSign === 'creditSlip') {
            dispatch(setCustomerInvoice({
                date: new Date(),
                type: InvoiceType.TD04,
                status: InvoiceStatusType.DRAFT,
                ateco: user && user.atecos && user.atecos[0],
                currency: CurrencyType.EUR,
                stamp: false,
                amount: 0,
                taxBase: 0,
                goodServices: [],
                socialContribution: false,
                socialContributionAmount: 0,
                reimbursementsOfExpenses: [],
                reimbursementsOfExpensesAmount: 0,
                manual: true,
                isPaymentDue: false,
                trackedPayment: false,
                customerDataCommunication: false,
                payments: [],
                documents: [],
                additionalDatasPa: [],
                supplier: {
                    bankAccount: {
                        bankIban: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.iban || "",
                        bankName: (user.bankAccount && user.bankAccount.find(bank => bank.default === true))?.name || ""
                    }
                        || "", type: ContactType.CUSTOMER

                }
            }));
            dispatch(setCustomerInvoiceFileList([]));
            dispatch(setCustomerInvoiceReimbursementsOfExpenses([]));
            dispatch(setNewContactToInvoiceSign('customer'));
            dispatch(setCustomerInvoiceSaveOrDuplicateOrEditOperation('save'));

            navigate(uri.CreditSlipInvoiceSave);
        }
    }
}
