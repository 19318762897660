import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './quickfiscoSwitchInput.css';

interface Props {
  id: string;
  label?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  subLabel?: string;
  required?: boolean;
  colorLabel?: string;
}

export function QuickfiscoSwitchInput(props: Props) {
  const { id } = props;
  const { label } = props;
  const { onChange } = props;
  const { checked } = props;
  const { disabled } = props;
  const { subLabel } = props;
  const { required } = props;
  const { colorLabel } = props;

  return (
    <div
      className={
        'form-switch d-flex justify-content-between align-items-center w-100 ps-0'
      }
    >
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <label className={colorLabel ? colorLabel + " d-flex" : "quickfisco-switch-input-label d-flex"}>
            {label}
            {label !== undefined && required && ' *'}
            {disabled && (
              <QuickfiscoIcon name={'block.svg'} className={'ps-2'} />
            )}
          </label>
          {subLabel && (
            <span className="quickfisco-switch-input-sublabel">{subLabel}</span>
          )}
        </div>
      </div>
      <input
        id={id}
        type="checkbox"
        className="form-check-input quickfisco-switch-input ms-3"
        onChange={(e) => onChange && onChange(e.target.checked)}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
}
