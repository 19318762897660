import { useAppDispatch } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { ComponentVisibleOutput } from '../../hooks/componentVisible';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { useState } from 'react';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { MessagesModel } from '../../models/messagesModel';
import './messagesMenu.css';
import { MessagesService } from '../../services/messagesService';
import {
  getMessagesList,
  getMessagesReports,
  getUnreadCounters,
  setMessagesListPage,
  setMessagesListStatusFilter,
} from '../../redux/slices/messagesListSlice';
import uri from '../../routers/quickfiscoUri.json';
import lang from './messagesMenu.json';

interface Props {
  message: MessagesModel;
  componentVisible: ComponentVisibleOutput;
  markAsRead: () => void;
  unreadStatus: PromiseStatuses;
  setUnreadStatus: (status: PromiseStatuses) => void;
}

export function MessagesMenu(props: Props) {
  const { message } = props;
  const { componentVisible } = props;
  const { markAsRead } = props;
  const { unreadStatus } = props;
  const { setUnreadStatus } = props;

  const dispatch = useAppDispatch();

  const [deletionStatus, setDeletionStatus] = useState<PromiseStatuses>('idle');
  const [readStatus, setReadStatus] = useState<PromiseStatuses>('idle');

  const navigate = useNavigate();

  if (!componentVisible.isVisible) {
    return null;
  }

  const goToMessage = () => {
    navigate(uri.Messages + '/' + message.id);
    componentVisible.setIsVisible(false);
  };

  const deleteMessage = () => {
    const service = new MessagesService();

    setDeletionStatus('loading');

    if (message.id) {
      service
        .del(message.id)
        .then(() => {
          componentVisible.setIsVisible(false);
          setDeletionStatus('idle');
          dispatch(getMessagesReports());
          dispatch(getUnreadCounters());
          dispatch(setMessagesListPage(1));
          dispatch(
            getMessagesList({
              page: 1,
            })
          );
          dispatch(setMessagesListStatusFilter(undefined));
        })
        .catch((err) => {
          setDeletionStatus('failed');
          console.error(err);
        });
    }
  };

  const markAsUnread = async () => {
    componentVisible.setIsVisible(false);

    if (message.id) {
      const service = new MessagesService();

      setReadStatus('loading');
      service
        .markAsUnread(message.id)
        .then(() => {
          componentVisible.setIsVisible(false);
          setReadStatus('idle');
          dispatch(getMessagesReports());
          dispatch(getUnreadCounters());
          dispatch(setMessagesListPage(1));
          dispatch(
            getMessagesList({
              page: 1,
            })
          );
          dispatch(setMessagesListStatusFilter(undefined));
        })
        .catch((err) => {
          setReadStatus('failed');
          console.error(err);
        });
    }
  };

  return (
    <div className={'messagesMenu-container'}>
      <QuickfiscoError
        message={lang.unreadError}
        active={unreadStatus === 'failed'}
        close={() => setUnreadStatus('idle')}
      />
      <QuickfiscoError
        message={lang.deletionError}
        active={deletionStatus === 'failed'}
        close={() => setDeletionStatus('idle')}
      />
      <QuickfiscoError
        message={lang.readError}
        active={readStatus === 'failed'}
        close={() => setReadStatus('idle')}
      />
      <div className={'messagesMenu-item'} onClick={() => goToMessage()}>
        <div className={'col-auto messagesMenu-icon'}>
          <QuickfiscoIcon name={'find.svg'} />
        </div>
        {lang.Label1}
      </div>
      <div className={'messagesMenu-line mt-2 mb-2'} />
      {!message.unread && unreadStatus === 'loading' && (
        <div
          className={'w-100 d-flex justify-content-center align-items-center'}
        >
          <QuickfiscoSpinner />
        </div>
      )}
      {!message.unread && unreadStatus !== 'loading' && (
        <div className={'messagesMenu-item'} onClick={() => markAsUnread()}>
          <div className={'col-auto messagesMenu-icon'}>
            <QuickfiscoIcon name={'message-unread.svg'} />
          </div>
          {lang.Label2}
        </div>
      )}
      {message.unread && unreadStatus === 'loading' && (
        <div
          className={'w-100 d-flex justify-content-center align-items-center'}
        >
          <QuickfiscoSpinner />
        </div>
      )}
      {message.unread && unreadStatus !== 'loading' && (
        <div className={'messagesMenu-item'} onClick={() => markAsRead()}>
          <div className={'col-auto messagesMenu-icon'}>
            <QuickfiscoIcon name={'message-read.svg'} />
          </div>
          {lang.Label4}
        </div>
      )}
      <div className={'messagesMenu-line mt-2 mb-2'} />
      {deletionStatus === 'loading' ? (
        <div
          className={'w-100 d-flex justify-content-center align-items-center'}
        >
          <QuickfiscoSpinner />
        </div>
      ) : (
        <div className={'messagesMenu-item'} onClick={() => deleteMessage()}>
          <div className={'col-auto messagesMenu-icon'}>
            <QuickfiscoIcon name={'delete-icon.svg'} />
          </div>
          {lang.Label3}
        </div>
      )}
    </div>
  );
}
