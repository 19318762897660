import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  onChange?: (usage?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoBuildingUsageSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'Utilizzo'}
      options={getOptions()}
      styleType={'default'}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
      isMenuPlacementTop={true}
    />
  );
}
function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined || value === null,
    },
    {
      label: 'Abitazione principale',
      value: 'Abitazione principale',
      selected: value === 'Abitazione principale',
    },
    {
      label: 'A disposizione / Comodato gratuito a persone non familiari',
      value: 'A disposizione / Comodato gratuito a persone non familiari',
      selected:
        value === 'A disposizione / Comodato gratuito a persone non familiari',
    },
    {
      label: 'Locato in regime di libero mercato',
      value: 'Locato in regime di libero mercato',
      selected: value === 'Locato in regime di libero mercato',
    },
    {
      label: 'Locato in regime di equo canone',
      value: 'Locato in regime di equo canone',
      selected: value === 'Locato in regime di equo canone',
    },
    {
      label: "Pertinenza dell'abitazione principale",
      value: "Pertinenza dell'abitazione principale",
      selected: value === "Pertinenza dell'abitazione principale",
    },
    {
      label: 'Abitazione principale e attività lavorativa',
      value: 'Abitazione principale e attività lavorativa',
      selected: value === 'Abitazione principale e attività lavorativa',
    },
    {
      label: 'Locato a canone concordato',
      value: 'Locato a canone concordato',
      selected: value === 'Locato a canone concordato',
    },
    {
      label: 'Altri casi, quali immobili privi di allacciamenti alle utenze',
      value: 'Altri casi, quali immobili privi di allacciamenti alle utenze',
      selected:
        value ===
        'Altri casi, quali immobili privi di allacciamenti alle utenze',
    },
    {
      label: 'Comodato gratuito a familiari',
      value: 'Comodato gratuito a familiari',
      selected: value === 'Comodato gratuito a familiari',
    },
    {
      label: 'Sia abitaz. principale sia locato in regime di libero mercato',
      value: 'Sia abitaz. principale sia locato in regime di libero mercato',
      selected:
        value ===
        'Sia abitaz. principale sia locato in regime di libero mercato',
    },
    {
      label: 'Sia abitaz. principale sia locato in regime di equo canone',
      value: 'Sia abitaz. principale sia locato in regime di equo canone',
      selected:
        value === 'Sia abitaz. principale sia locato in regime di equo canone',
    },
    {
      label: 'Immobile situato in Abruzzo e locato a soggetti residenti',
      value: 'Immobile situato in Abruzzo e locato a soggetti residenti',
      selected:
        value === 'Immobile situato in Abruzzo e locato a soggetti residenti',
    },
    {
      label:
        "Immobile situato in Abruzzo in comodato d'uso a soggetti residenti",
      value:
        "Immobile situato in Abruzzo in comodato d'uso a soggetti residenti",
      selected:
        value ===
        "Immobile situato in Abruzzo in comodato d'uso a soggetti residenti",
    },
  ];
}
