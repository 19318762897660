import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInfoPopup } from '../quickfiscoInfoPopup/quickfiscoInfoPopup';
import './quickfiscoBooleanCheckbox.css';

interface Props {
  id: string;
  title: string;
  yesLabel: string;
  noLabel: string;
  value?: boolean;
  description?: string;
  list?: string[];
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  required?: boolean;
  popupText?: string;
}

export function QuickfiscoBooleanCheckbox(props: Props) {
  const { id } = props;
  const { title } = props;
  const { yesLabel } = props;
  const { noLabel } = props;
  const { value } = props;
  const { description } = props;
  const { list } = props;
  const { onChange } = props;
  const { disabled } = props;
  const { required } = props;
  const { popupText } = props;

  return (
    <div className={'row'} id={id}>
      <div className={'col-12'}>
        <div className={'row'}>
          <div
            className={
              'col-12 d-flex align-items-center quickfisco-boolean-checkbox-title'
            }
          >
            {title}
            {required && ' *'}
            {disabled && (
              <QuickfiscoIcon name={'block.svg'} className={'ps-2'} />
            )}
            {popupText && (
              <QuickfiscoInfoPopup id={`pop-id-${id}`} text={popupText} />
            )}
          </div>
          {description && (
            <div
              className={
                'col-12 mt-2 mb-1 quickfisco-boolean-checkbox-description'
              }
            >
              {description}
            </div>
          )}
          {list && (
            <div className={'col-12 mt-2 mb-1'}>
              <ul className="ps-3">
                {list.map((li, index) => {
                  return (
                    <li
                      key={index}
                      className={'quickfisco-boolean-checkbox-description'}
                    >
                      {li}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className={'row mt-2'}>
          <div className={'col-auto d-flex align-content-center'}>
            <QuickfiscoCheckbox
              id={`quickfisco-boolean-checkbox-answer-yes-${id}`}
              label={yesLabel}
              checked={value === true}
              onChange={() => onChange && onChange(true)}
              disabled={disabled}
            />
          </div>
          <div className={'col-auto ms-4 d-flex align-content-center'}>
            <QuickfiscoCheckbox
              id={`quickfisco-boolean-checkbox-answer-yes-${id}`}
              label={noLabel}
              checked={value === false}
              onChange={() => onChange && onChange(false)}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
