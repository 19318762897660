import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import uri from '../../routers/quickfiscoUri.json';
import { ImportInvoiceService } from '../../services/importInvoiceService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './invoiceImportBoxHome.css';
import lang from './invoiceImportBoxHome.json';
import { useEffect, useState } from 'react';
import { PromiseStatuses } from '../../types/strings';

interface Props {
  isHome?: boolean;
}
export function InvoiceImportBoxHome(props: Props) {
  const { isHome } = props;

  const navigate = useNavigate();

  return (
    <div
      className={`${isHome ? 'invoice-import-box-home-container' : ''
        } text-center my-4 p-5`}
    >
      {!isHome && <QuickfiscoIcon name={'alert-yellow-full-2.svg'} className="mb-5" />}
      <div className={'invoice-import-box-home-action'}>{lang.action}</div>
      <div className={'invoice-import-box-home-title-bold mt-3 mb-2'}>
        {isHome
          ? `${lang.titleHome} ${new Date().getFullYear()} ${lang.titleBold_2} ${new Date().getFullYear() - 1}`
          : `${lang.titleBold_1} ${new Date().getFullYear()} ${lang.titleBold_2} ${new Date().getFullYear() - 1}`}
      </div>
      <div className={'invoice-import-box-home-subtitle'}>{lang.subTitle}</div>
      <div className="row mt-4">
        <div className="col-4 offset-4">
          <QuickfiscoButton
            id={'invoice-import-box-home-cta-btn'}
            label={lang.ctaButton}
            onClick={() => navigate(uri.InvoiceImport)}
            type={!isHome ? 'eighth' : "ninth"}
          />
        </div>
      </div>
    </div>
  );
}

export function InvoiceImportBoxEmptyHome() {
  const navigate = useNavigate();
  const importInvoiceService = new ImportInvoiceService();
  const userState = useAppSelector((state) => state.user.user);
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  useEffect(() => {
    if (status === "successfully") {
      window.location.reload();
    }
  }, [status])

  return (
    <div className={'text-center my-4 p-5'}>
      <QuickfiscoIcon name={'alert-blue-2.svg'} className="mb-4" />
      <div className={'invoice-import-box-home-action-blue'}>{lang.invoiceList.action_2}</div>
      <div className={'invoice-import-box-home-title-bold mt-4 mb-2'}>
        {lang.invoiceList.titleBold_4} {new Date().getFullYear()} {lang.invoiceList.titleBold_5}
        {new Date().getFullYear() - 1}
      </div>
      <div className={'invoice-import-box-home-subtitle'}>
        {lang.invoiceList.subTitle_1}
        <a className={"invoice-import-box-home-link"} onClick={() => navigate("https://quickfisco.it/tutorial/migrazione-fatture-note-di-credito")}>{lang.invoiceList.subTitle_2}</a>
        {lang.invoiceList.subTitle_3}<br></br>
        {lang.invoiceList.subTitle_4}
      </div>
      <div className="row mt-4">
        <div className="col-4 offset-4">
          <QuickfiscoButton
            id={'invoice-import-box-home-cta-btn'}
            label={lang.invoiceList.ctaButton_1}
            onClick={() => {
              if (!userState.id) {
                return
              }

              setStatus("loading")
              importInvoiceService
                .resetImportInvoice(userState.id, "customer")
                .then(() => {
                  setStatus("successfully")
                }).catch((err) => {
                  console.error(err);
                  setStatus("failed")

                });
            }}
            type={'tertiary'}
          />
        </div>
      </div>
    </div >
  );
}