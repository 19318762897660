import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Operation, PromiseStatuses } from '../../types/strings';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import { InvoiceModel } from '../../models/invoiceModel';
import { AutoInvoiceService } from '../../services/autoInvoiceService';

interface AutoInvoiceSaveOrDuplicateOrEditState {
  invoiceId?: string;
  operation: Operation;
  successfullySaved: boolean;
  editStatus: PromiseStatuses
}

const initialState: AutoInvoiceSaveOrDuplicateOrEditState = {
  operation: 'save',
  successfullySaved: false,
  editStatus: 'idle'
};


export const editAutoInvoice = createAsyncThunk(
  'autoInvoiceSaveOrDuplicateOrEdit/editAutoInvoice',
  async (request: { invoice: InvoiceModel, fileList: File[], errorSetters: InvoiceErrorSetters }): Promise<void> => {
    const autoInvoiceService = new AutoInvoiceService(request.errorSetters);

    return autoInvoiceService.edit(request.invoice, request.fileList, [])
      .catch(err => {
        err
          .json()
          .then((data: any) => {
            if (data.message === 'error.invalid-date') {
              request.errorSetters.setErrorDateMessage && request.errorSetters.setErrorDateMessage('Esiste già una fattura con data successiva ma numero inferiore.')
              request.errorSetters.setErrorDate && request.errorSetters.setErrorDate(true)
            }
          })
      })
  }
)

const autoInvoiceSaveOrDuplicateOrEditSlice = createSlice({
  name: 'autoInvoiceSaveOrDuplicateOrEdit',
  initialState,
  reducers: {
    setAutoInvoiceSaveOrDuplicateOrEditInvoiceId: (state, action: PayloadAction<string | undefined>) => {
      state.invoiceId = action.payload
    },
    setAutoInvoiceSaveOrDuplicateOrEditOperation: (state, action: PayloadAction<Operation>) => {
      state.operation = action.payload
    },
    setAutoInvoiceSaveOrDuplicateOrEditSuccessfullySaved: (state, action: PayloadAction<boolean>) => {
      state.successfullySaved = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editAutoInvoice.pending, (state) => {
        state.editStatus = 'loading';
      })
      .addCase(editAutoInvoice.fulfilled, (state) => {
        state.editStatus = 'successfully';
      })
      .addCase(editAutoInvoice.rejected, (state) => {
        state.editStatus = 'failed';
      })
    }
  });

export const {
  setAutoInvoiceSaveOrDuplicateOrEditInvoiceId,
  setAutoInvoiceSaveOrDuplicateOrEditOperation,
  setAutoInvoiceSaveOrDuplicateOrEditSuccessfullySaved
} = autoInvoiceSaveOrDuplicateOrEditSlice.actions;

export default autoInvoiceSaveOrDuplicateOrEditSlice.reducer;
