import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Operation } from "../../types/strings";

interface SupplierInvoiceSaveOrEditState {
    invoiceId?: string;
    operation: Operation;
    successfullySaved: boolean;
}

const initialState: SupplierInvoiceSaveOrEditState = {
    operation: 'save',
    successfullySaved: false
};

const supplierInvoiceSaveOrEditSlice = createSlice({
    name: 'supplierInvoiceSaveOrEdit',
    initialState,
    reducers: {
        setSupplierInvoiceSaveOrEditInvoiceId: (state, action: PayloadAction<string | undefined>) => {
            state.invoiceId = action.payload
        },
        setSupplierInvoiceSaveOrEditOperation: (state, action: PayloadAction<Operation>) => {
            state.operation = action.payload
        },
        setSupplierInvoiceSaveOrEditSuccessfullySaved: (state, action: PayloadAction<boolean>) => {
            state.successfullySaved = action.payload
        }
    }
});

export const {
    setSupplierInvoiceSaveOrEditInvoiceId,
    setSupplierInvoiceSaveOrEditOperation,
    setSupplierInvoiceSaveOrEditSuccessfullySaved
} = supplierInvoiceSaveOrEditSlice.actions

export default supplierInvoiceSaveOrEditSlice.reducer