import { CountryType } from "./countryModel";

export interface ContactModel {
  id?: string;
  companyName?: string;
  email?: string;
  phone?: string | null;
  vat?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: CountryType;
  pec?: string | null;
  ateco?: string | null;
  sdi?: string | null;
  webSite?: string | null;
  fax?: string | null;
  notes?: string | null;
  fiscalCode?: string;
  personType?: PersonType;
  fiscalRegime?: FiscalRegimes | null;
  referenceName?: string | null;
  ownerId?: string;
  type: ContactType;
  zipCode?: string;
  bankAccount: BankAccount;
  fund?: string | null;
  recourseValue?: number | null;
  foreign?: ForeignType;
  residence?: ClientResidence;
}

export interface BankAccount {
  bankIban: string;
  bankName: string;
}

export enum PersonType {
  GOV = 'GOV',
  VAT = 'VAT',
  FOREIGN = 'FOREIGN',
  PRIVATE_INDIVIDUAL = 'PRIVATE_INDIVIDUAL'
}

export enum ForeignType {
  AGENCY = 'AGENCY',
  PRIVATE = 'PRIVATE'
}

export enum ClientResidence {
  EXTRA_EU = 'EXTRA_EU',
  EU = 'EU'
}

export enum ContactType {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER'
}

export enum FiscalRegimes {
  RF01 = 'RF01',
  RF02 = 'RF02',
  RF04 = 'RF04',
  RF05 = 'RF05',
  RF06 = 'RF06',
  RF07 = 'RF07',
  RF08 = 'RF08',
  RF09 = 'RF09',
  RF10 = 'RF10',
  RF11 = 'RF11',
  RF12 = 'RF12',
  RF13 = 'RF13',
  RF14 = 'RF14',
  RF15 = 'RF15',
  RF16 = 'RF16',
  RF17 = 'RF17',
  RF18 = 'RF18',
  RF19 = 'RF19'
}
