import { useEffect, useState } from 'react';
import {
  AutoInvoiceSaveOrDuplicateOrEdit as AutoInvoiceSaveOrDuplicateOrEditComponent
} from '../../components/autoInvoiceSaveOrDuplicateOrEdit/autoInvoiceSaveOrDuplicateOrEdit';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { ReadOnlyNotice } from '../../components/readOnlyNotice/readOnlyNotice';
import { InvoiceStatusType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAutoInvoice, setAutoInvoiceFileList, setAutoInvoiceStatus } from '../../redux/slices/autoInvoiceSlice';
import { AutoInvoiceService } from '../../services/autoInvoiceService';
import { SetState } from '../../types/functions';
import { Operation, PromiseStatuses } from '../../types/strings';
import { useProSidebar } from 'react-pro-sidebar';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './autoInvoiceSaveOrDuplicateOrEdit.json';

export function AutoInvoiceSaveOrDuplicateOrEdit() {
  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const autoInvoiceState = useAppSelector(state => state.autoInvoice);
  const autoInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.autoInvoiceSaveOrDuplicateOrEdit)
  const userState = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  const autoInvoice = autoInvoiceState.invoice
  const invoiceId = autoInvoiceSaveOrDuplicateOrEditState.invoiceId
  const operation = autoInvoiceSaveOrDuplicateOrEditState.operation

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (operation === 'duplicate' || operation === 'edit') {
      findById(dispatch, setStatus, operation, invoiceId);
    }
  }, [invoiceId, operation]);

  useEffect(() => {
    if (invoiceId !== undefined && autoInvoiceSaveOrDuplicateOrEditState.editStatus === 'successfully' && operation !== 'save') {
      findById(dispatch, setStatus, operation, invoiceId);
    }
  }, [autoInvoiceSaveOrDuplicateOrEditState.editStatus]);

  if (status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        {lang.Error}
      </div>
    );
  }

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  const invoiceIsEditable = AutoInvoiceService.isEditable(autoInvoice);
  const invoiceAlreadyTransmitted = AutoInvoiceService.isSendable(autoInvoice);

  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='auto-invoice-edit-menu'
            menuItem={MenuItems.AUTO_INVOICE}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
              {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          {
            !invoiceIsEditable && !invoiceAlreadyTransmitted && operation === 'edit' && (
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <ReadOnlyNotice />
                </div>
              </div>
            )
          }
          <div className={'row'}>
            <div className={'col-12'}>
              <AutoInvoiceSaveOrDuplicateOrEditComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function findById(
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  operation: Operation,
  invoiceId?: string,
): void {
  if (!invoiceId) {
    return;
  }

  const service = new AutoInvoiceService();

  setStatus('loading');
  service
    .findById(invoiceId)
    .then(data => {
      dispatch(setAutoInvoice(data.invoice));
      dispatch(setAutoInvoiceFileList(data.files));
      if (operation === 'duplicate') {
        dispatch(setAutoInvoiceStatus(InvoiceStatusType.DRAFT));
      }

      setStatus('idle');
    })
    .catch(err => {
      console.error(err);
      setStatus('failed');
    });
}
