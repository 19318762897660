import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  findUserFinance,
  setFinanceUserRequestAddress,
  setFinanceUserRequestBirthDate,
  setFinanceUserRequestCity,
  setFinanceUserRequestPostalCode,
  setFinanceUserRequestProvince,
} from '../../redux/slices/financeSlice';
import { FinanceApproved } from '../financeApproved/financeApproved';
import { FinanceAuthorization } from '../financeAuthorization/financeAuthorization';
import { FinanceCheckFinanceability } from '../financeCheckFinanceability/financeCheckFinanceability';
import { FinanceCheckSubscription } from '../financeCheckSubscription/financeCheckSubscription';
import { FinanceInformations } from '../financeInformations/financeInformations';
import { FinanceRate } from '../financeRate/financeRate';
import { FinanceRefused } from '../financeRefused/financeRefused';
import { FinanceStatus } from '../financeStatus/financeStatus';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './finance.css';

export function FinanceComponent() {
  const userState = useAppSelector((state) => state.user);
  const financeState = useAppSelector((state) => state.finance);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userState.status === 'successfully') {
      dispatch(setFinanceUserRequestAddress(userState.user.address));
      dispatch(setFinanceUserRequestBirthDate(userState.user.dateOfBirth));
      dispatch(setFinanceUserRequestCity(userState.user.city));
      dispatch(setFinanceUserRequestPostalCode(userState.user.zipCode));
      dispatch(setFinanceUserRequestProvince(userState.user.province));
    }
  }, [userState.status]);

  useEffect(() => {
    if (financeState.sendFinanceUserStatus === 'successfully') {
      dispatch(findUserFinance());
    }
  }, [financeState.sendFinanceUserStatus]);

  if (
    userState.status === 'loading' ||
    financeState.findUserFinanceStatus === 'loading'
  ) {
    return (
      <div className={'finance-empty'} style={{ height: '100%' }}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed') {
    return (
      <div className="finance-empty">
        <div className={'p-blue'}>
          {'Errore durante il caricamento del finanziamento'}
        </div>
      </div>
    );
  }

  if (
    financeState.findUserFinanceStatus === 'failed' &&
    financeState.userFinance === undefined
  ) {
    if (financeState.financeStep === 0) return <FinanceCheckSubscription />;
    else if (financeState.financeStep === 1)
      return <FinanceCheckFinanceability />;
    else if (financeState.financeStep === 2) {
      if (financeState.financing?.financeability) {
        return <FinanceApproved />;
      } else {
        return <FinanceRefused />;
      }
    } else if (financeState.financeStep === 3) return <FinanceAuthorization />;
    else if (financeState.financeStep === 4) return <FinanceRate />;
    else if (financeState.financeStep === 5) return <FinanceInformations />;
  }

  return <FinanceStatus />;
}
