export function showBuldingExtraField(shortRentals: boolean,
    usage: string): boolean {
    if (
        shortRentals === false &&
        (usage === 'Locato in regime di libero mercato' ||
            usage === 'Locato in regime di equo canone' ||
            usage === 'Locato a canone concordato' ||
            usage ===
            'Sia abitaz. principale sia locato in regime di libero mercato' ||
            usage ===
            'Sia abitaz. principale sia locato in regime di equo canone' ||
            usage === 'Immobile situato in Abruzzo e locato a soggetti residenti')
    ) {
        return true;
    }
    return false;
}