import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmationError } from '../../components/confirmationError/confirmationError';
import { PasswordRecovery } from '../../components/passwordRecovery/passwordRecovery';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../../components/quickfiscoInput/quickfiscoInput';
import { QuickfiscoLink } from '../../components/quickfiscoLink/quickfiscoLink';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import {
  LogoDim,
  QuickfiscoLogo,
} from '../../components/quickfiscoLogo/quickfiscoLogo';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  doLogin,
  resendCode,
  setCode,
  setEmail,
  setPassword,
  setValidate,
  validateLogin,
} from '../../redux/slices/loginSlice';
import lang from './login.json';
import uri from '../../routers/quickfiscoUri.json';
import './login.css';
import { CodeModel } from '../../models/loginModel';

export function Login() {
  const validateIn = useAppSelector((state) => state.login.validateIn);
  const authCode = useAppSelector((state) => state.login.codeModel);

  const errorMaxAttempts = useAppSelector(
    (state) => state.login.errorMaxAttempts
  );
  const errorCode = useAppSelector((state) => state.login.errorCode);

  const navigate = useNavigate();
  const [passwordRecoveryOpen, setPasswordRecoveryOpen] = useState(false);

  const status = useAppSelector((state) => state.login.status);

  const [disabled, setDisabled] = useState(Boolean);

  const [errorLenght, setErrorLenght] = useState(false);

  const [counter, setCounter] = useState(10);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useAppDispatch();

  const [error, setError] = useState(Boolean);

  useEffect(() => {
    if (authCode.email === '') {
      dispatch(setValidate(false));
    }
  }, [status]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(intervalRef.current!);
          setError(false);
          setDisabled(false);
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalRef.current!);
  }, []);

  if (status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <>
      <ConfirmationError />
      <div className="row full-screen mr-0">
        <div className="d-none d-lg-flex col-6 login-left-side">
          <div>
            <h2>{lang.Welcome}</h2>
            <QuickfiscoIcon name={'handEmoji.svg'} className="w-25" />
          </div>
        </div>
        <div className="col-12 col-lg-6 p-5 login-right-side">
          {!validateIn ? (
            <div className="login-container">
              <QuickfiscoLogo id="login-logo" dim={LogoDim.MEDIUM} />
              <h2 className={'login-title mt-5 mb-4'}>{lang.Login}</h2>
              <QuickfiscoInput
                id="loginEmail"
                styleType={'default'}
                label={lang.EmailLabel}
                type="email"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  dispatch(setEmail(event.target.value))
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  login(authCode.email, authCode.password, dispatch, event.key)
                }
                error={false}
                placeholder={lang.EmailPlaceholder}
                required={true}
              />
              <div className="mt-4" />
              <QuickfiscoInput
                id="loginPassword"
                styleType={'default'}
                label={lang.PasswordLabel}
                type="password"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  dispatch(setPassword(event.target.value))
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  login(authCode.email, authCode.password, dispatch, event.key)
                }
                error={status === 'failed'}
                errorLabel={
                  status === 'failed' && !errorMaxAttempts
                    ? lang.ErrorLabel
                    : 'Hai raggiunto il numero massimo di tentativi. Riprova fra 10 minuti'
                }
                placeholder={lang.PasswordPlaceholder}
                required={true}
              />
              <div className="row mt-4 d-flex justify-content-start align-items-center">
                <div className="col-5">
                  <QuickfiscoButton
                    id="login-btn"
                    label={lang.LoginLabel}
                    type={'primary'}
                    onClick={() =>
                      login(authCode.email, authCode.password, dispatch)
                    }
                  />
                </div>
                <div className="col-7">
                  {status === 'failed' && (
                    <QuickfiscoLink
                      id={'login-recovery-password'}
                      label={lang.RecoveryPassword}
                      onClick={() => setPasswordRecoveryOpen(true)}
                    />
                  )}
                </div>
                <div className="login-register-container mx-auto mb-1 mb-lg-5">
                  <span className="me-1 login-not-signed">
                    {lang['NotSignedLabel']}
                  </span>
                  <QuickfiscoLink
                    id={'login-go-to-register'}
                    label={lang.RegisterLabel}
                    onClick={() => navigate(uri.Register)}
                  />
                  <QuickfiscoIcon name={'flash.png'} className={'ms-2'} />
                </div>
              </div>
              <PasswordRecovery
                open={passwordRecoveryOpen}
                setOpen={(open: boolean) => setPasswordRecoveryOpen(open)}
              />
            </div>
          ) : (
            <div className="login-container">
              <QuickfiscoLogo id="login-logo" dim={LogoDim.MEDIUM} />
              <h2 className={'login-title mt-5 mb-2'}>{'Conferma - Admin'}</h2>
              <h6 className={'login-information'}>
                {
                  'Inserisci il codice di verifica che hai ricevuto al numero di cellulare inserito sul tuo account Quickfisco'
                }
              </h6>
              <QuickfiscoInput
                id="loginCode"
                styleType={'default'}
                label={lang.LoginCode}
                type="text"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  dispatch(setCode(event.target.value))
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                  validation(authCode, dispatch, event.key)
                }
                error={status === 'failed' || errorLenght}
                errorLabel={
                  status === 'failed' && errorCode && !errorLenght
                    ? 'Hai inserito il codice errato riprova o reinvia nuovamente'
                    : errorLenght
                    ? 'La lunghezza del codice e di almeno 6 caratteri'
                    : ''
                }
                placeholder={'Inserisci il codice'}
              />
              <div className="mt-4" />
              <div className="row mt-4 d-flex justify-content-left align-items-center">
                <div className="col-4">
                  <QuickfiscoButton
                    id="login-btn"
                    label={lang.LoginLabel}
                    type={'primary'}
                    onClick={() =>
                      authCode.code.length === 6
                        ? validation(authCode, dispatch)
                        : setErrorLenght(true)
                    }
                  />
                </div>
                <div className="col-2">
                  <div>
                    <QuickfiscoIcon
                      name={'back-to-draft.svg'}
                      className="w-25"
                      onClick={() => {
                        !disabled &&
                          dispatch(
                            resendCode({
                              code: authCode.code,
                              email: authCode.email,
                              password: authCode.password,
                            })
                          );
                        setError(true);
                        setDisabled(true);
                        !disabled && setCounter(10);
                      }}
                      isButton={!disabled}
                    />
                  </div>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-center text-align-center">
                  {error && (
                    <h6 className={'login-information mt-2'}>
                      {'Il codice è stato inviato nuovamente! Riprova tra ' +
                        counter}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function login(
  email: string,
  password: string,
  dispatch: Function,
  eventKey?: string
): void {
  if ((eventKey === 'Enter' || !eventKey) && email && password) {
    dispatch(
      doLogin({
        email,
        password,
      })
    );
  }
}

function validation(
  code: CodeModel,
  dispatch: Function,
  eventKey?: string
): void {
  if ((eventKey === 'Enter' || !eventKey) && code) {
    dispatch(validateLogin(code));
  }
}
