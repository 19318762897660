import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTaxCalculation, setEditErrorMessage } from '../../redux/slices/taxCalculationSlice';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { TaxChart } from '../taxChart/taxChart';
import { TaxDeadlinesList } from '../taxDeadlinesList/taxDeadlinesList';
import { TaxProfileInfo } from '../taxProfileInfo/taxProfileInfo';

export function TaxForecast() {
  const dispatch = useAppDispatch();
  const taxCalculationState = useAppSelector(state => state.taxCalculation)

  useEffect(() => {
    dispatch(getTaxCalculation(taxCalculationState.year));
  }, [taxCalculationState.year]);

  return (
    <div>
      <QuickfiscoError
        message={taxCalculationState.editErrorMessage}
        active={taxCalculationState.editErrorMessage !== undefined}
        close={() => dispatch(setEditErrorMessage(undefined))}
      />
      <div className={'row'}>
        <div className={'col-12'}>
          <TaxDeadlinesList />
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-9'}>
          <div className={'tax-forecast-container'}>
            <TaxProfileInfo />
          </div>
        </div>
        <div className={'col-2'}>
          <div className={'tax-forecast-container'}>
            <TaxChart />
          </div>
        </div>
      </div>
    </div>
  );
}
