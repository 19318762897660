import { useEffect, useState } from 'react';
import { QuickfiscoBooleanCheckbox } from '../quickfiscoBooleanCheckbox/quickfiscoBooleanCheckbox';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  DocumentsTypeTaxReturnModel,
  ObjectFileTaxReturnModel,
  SectionTaxReturn7,
  ServiceInvoicesTaxReturnModel,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { QuickfiscoFileList } from '../quickfiscoFileList.css/quickfiscoFileList';
import { getExtension, toBase64, validateFile } from '../../utils/file';
import { sanitizeString } from '../../utils/string';
import { useAppSelector } from '../../redux/hooks';
import { CategoryType, UserModel } from '../../models/userModel';
import { PromiseStatuses } from '../../types/strings';
import { SetState } from '../../types/functions';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { NumericFormat } from 'react-number-format';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { ServiceInvoicesAnswer } from '../serviceInvoicesAnswer/serviceInvoicesAnswer';
import { isEmptyValue } from '../../utils/values';
import { counterFileRegex } from '../../regex/regex';
import lang from './taxReturnSection7.json';
import './taxReturnSection7.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn7) => void;
  onClickPrevStep: (data: SectionTaxReturn7) => void;
  downloadDoc: (docId: string) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
  scrollPosition?: number;
}

export function TaxReturnSection7(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { downloadDoc } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;
  const { scrollPosition } = props;

  const user = useAppSelector((state) => state.user.user);
  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [electronicInvoicingQuestion, setElectronicInvoicingQuestion] =
    useState<boolean | undefined>(undefined);
  const [serviceInvoicesQuestion, setServiceInvoicesQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [serviceInvoicesAnswer, setServiceInvoicesAnswer] = useState<
    ServiceInvoicesTaxReturnModel[]
  >([
    {
      consumptionType: undefined,
      totalAnnualAmount: undefined,
    },
  ]);
  const [documentsWithholdingTaxQuestion, setDocumentsWithholdingTaxQuestion] =
    useState<boolean | undefined>(undefined);
  const [documentsWithholdingTaxAnswer, setDocumentsWithholdingTaxAnswer] =
    useState<ObjectFileTaxReturnModel[] | undefined>(undefined);
  const [meansTransportQuestion, setMeansTransportQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [meansTransportAnswer, setMeansTransportAnswer] = useState<
    number | undefined
  >(undefined);
  const [purchaseGoodsQuestion, setPurchaseGoodsQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [purchaseGoodsAnswer, setPurchaseGoodsAnswer] = useState<
    number | undefined
  >(undefined);
  const [financialFeeQuestion, setFinancialFeeQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [financialFeeAnswer, setFinancialFeeAnswer] = useState<
    number | undefined
  >(undefined);
  const [rentalFeeQuestion, setRentalFeeQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [rentalFeeAnswer, setRentalFeeAnswer] = useState<number | undefined>(
    undefined
  );
  const [royaltyFeeQuestion, setRoyaltyFeeQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [royaltyFeeAnswer, setRoyaltyFeeAnswer] = useState<number | undefined>(
    undefined
  );
  const [fuelFeeQuestion, setFuelFeeQuestion] = useState<boolean | undefined>(
    undefined
  );
  const [fuelFeeAnswer, setFuelFeeAnswer] = useState<number | undefined>(
    undefined
  );
  const [electronicDevicesQuestion, setElectronicDevicesQuestion] = useState<
    boolean | undefined
  >(undefined);
  const [electronicDevicesAnswer, setElectronicDevicesAnswer] = useState<
    ObjectFileTaxReturnModel[] | undefined
  >(undefined);

  const [statusDocumentsWithholdingTax, setStatusDocumentsWithholdingTax] =
    useState<PromiseStatuses>('idle');
  const [statusElectronicDevicesQuestion, setStatusElectronicDevicesQuestion] =
    useState<PromiseStatuses>('idle');
  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (skypScroll?: boolean): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(7)
      .then((res) => {
        setLoadingStatus('successfully');
        scrollPosition && refreshApiCall(scrollPosition, skypScroll);
        const data = res as SectionTaxReturn7;
        setElectronicInvoicingQuestion(data.electronicInvoicingQuestion);
        setServiceInvoicesQuestion(data.serviceInvoicesQuestion);
        setServiceInvoicesAnswer(
          data.serviceInvoicesAnswer || [
            {
              consumptionType: undefined,
              totalAnnualAmount: undefined,
            },
          ]
        );
        setDocumentsWithholdingTaxQuestion(
          data.documentsWithholdingTaxQuestion
        );
        setDocumentsWithholdingTaxAnswer(
          data.documentsWithholdingTaxAnswer as ObjectFileTaxReturnModel[]
        );
        setMeansTransportQuestion(data.meansTransportQuestion);
        setMeansTransportAnswer(data.meansTransportAnswer);
        setPurchaseGoodsQuestion(data.purchaseGoodsQuestion);
        setPurchaseGoodsAnswer(data.purchaseGoodsAnswer);
        setFinancialFeeQuestion(data.financialFeeQuestion);
        setFinancialFeeAnswer(data.financialFeeAnswer);
        setRentalFeeQuestion(data.rentalFeeQuestion);
        setRentalFeeAnswer(data.rentalFeeAnswer);
        setRoyaltyFeeQuestion(data.royaltyFeeQuestion);
        setRoyaltyFeeAnswer(data.royaltyFeeAnswer);
        setFuelFeeQuestion(data.fuelFeeQuestion);
        setFuelFeeAnswer(data.fuelFeeAnswer);
        setElectronicDevicesQuestion(data.electronicDevicesQuestion);
        setElectronicDevicesAnswer(
          data.electronicDevicesAnswer as ObjectFileTaxReturnModel[]
        );
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep(true);
  }, []);

  useEffect(() => {
    if (electronicInvoicingQuestion === false) {
      setServiceInvoicesQuestion(undefined);
      setServiceInvoicesAnswer([
        {
          consumptionType: undefined,
          totalAnnualAmount: undefined,
        },
      ]);
    }
  }, [electronicInvoicingQuestion]);

  useEffect(() => {
    serviceInvoicesQuestion === false &&
      setServiceInvoicesAnswer([
        {
          consumptionType: undefined,
          totalAnnualAmount: undefined,
        },
      ]);
  }, [serviceInvoicesQuestion]);

  useEffect(() => {
    if (documentsWithholdingTaxQuestion === false) {
      setDocumentsWithholdingTaxAnswer(undefined);
    }
  }, [documentsWithholdingTaxQuestion]);

  useEffect(() => {
    if (meansTransportQuestion === false) {
      setMeansTransportAnswer(undefined);
    }
  }, [meansTransportQuestion]);

  useEffect(() => {
    if (purchaseGoodsQuestion === false) {
      setPurchaseGoodsAnswer(undefined);
    }
  }, [purchaseGoodsQuestion]);

  useEffect(() => {
    if (financialFeeQuestion === false) {
      setFinancialFeeAnswer(undefined);
    }
  }, [financialFeeQuestion]);

  useEffect(() => {
    if (rentalFeeQuestion === false) {
      setRentalFeeAnswer(undefined);
    }
  }, [rentalFeeQuestion]);

  useEffect(() => {
    if (royaltyFeeQuestion === false) {
      setRoyaltyFeeAnswer(undefined);
    }
  }, [royaltyFeeQuestion]);

  useEffect(() => {
    if (fuelFeeQuestion === false) {
      setFuelFeeAnswer(undefined);
    }
  }, [fuelFeeQuestion]);

  useEffect(() => {
    if (electronicDevicesQuestion === false) {
      setElectronicDevicesAnswer(undefined);
    }
  }, [electronicDevicesQuestion]);

  useEffect(() => {
    setCheckgoNext(
      taxReturnService.validateSection7(dataToSend, user.category)
    );
  }, [
    electronicInvoicingQuestion,
    serviceInvoicesQuestion,
    serviceInvoicesAnswer,
    documentsWithholdingTaxQuestion,
    documentsWithholdingTaxAnswer,
    meansTransportQuestion,
    meansTransportAnswer,
    purchaseGoodsQuestion,
    purchaseGoodsAnswer,
    financialFeeQuestion,
    financialFeeAnswer,
    rentalFeeQuestion,
    rentalFeeAnswer,
    royaltyFeeQuestion,
    royaltyFeeAnswer,
    fuelFeeQuestion,
    fuelFeeAnswer,
    electronicDevicesQuestion,
    electronicDevicesAnswer,
  ]);

  const dataToSend: SectionTaxReturn7 = {
    electronicInvoicingQuestion,
    serviceInvoicesQuestion,
    serviceInvoicesAnswer:
      serviceInvoicesQuestion === true &&
      !(
        serviceInvoicesAnswer &&
        serviceInvoicesAnswer
          .map((answer) => {
            return Object.values(answer).every(
              (el) => el !== '' && !isEmptyValue(el)
            );
          })
          .includes(false)
      )
        ? serviceInvoicesAnswer
        : undefined,
    documentsWithholdingTaxQuestion,
    documentsWithholdingTaxAnswer:
      documentsWithholdingTaxQuestion === true
        ? documentsWithholdingTaxAnswer
        : undefined,
    meansTransportQuestion,
    meansTransportAnswer:
      meansTransportQuestion === true ? meansTransportAnswer : undefined,
    purchaseGoodsQuestion,
    purchaseGoodsAnswer:
      purchaseGoodsQuestion === true ? purchaseGoodsAnswer : undefined,
    financialFeeQuestion,
    financialFeeAnswer:
      financialFeeQuestion === true ? financialFeeAnswer : undefined,
    rentalFeeQuestion,
    rentalFeeAnswer: rentalFeeQuestion === true ? rentalFeeAnswer : undefined,
    royaltyFeeQuestion,
    royaltyFeeAnswer:
      royaltyFeeQuestion === true ? royaltyFeeAnswer : undefined,
    fuelFeeQuestion,
    fuelFeeAnswer: fuelFeeQuestion === true ? fuelFeeAnswer : undefined,
    electronicDevicesQuestion,
    electronicDevicesAnswer:
      electronicDevicesQuestion === true ? electronicDevicesAnswer : undefined,
  };

  if (loadingStatus === 'loading') {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-7-title">{lang.title}</div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className={'row no-gutters mt-4'}>
              <div className="col-12">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-7-electronic-invoicing-question"
                  title={lang.electronicInvoicingQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={electronicInvoicingQuestion}
                  onChange={(checked) => {
                    setElectronicInvoicingQuestion(checked);
                  }}
                  required={true}
                />
              </div>
            </div>
            {electronicInvoicingQuestion === true &&
              (user.category === CategoryType.FUND ||
                user.category === CategoryType.NOT_FUND) && (
                <>
                  <div className={'row no-gutters mt-4'}>
                    <div className="col-12">
                      <QuickfiscoBooleanCheckbox
                        id="tax-return-section-7-services-invoices-question"
                        title={lang.serviceInvoicesQuestion}
                        yesLabel={lang.answerYes}
                        noLabel={lang.answerNo}
                        value={serviceInvoicesQuestion}
                        onChange={(checked) =>
                          setServiceInvoicesQuestion(checked)
                        }
                        required={true}
                      />
                    </div>
                  </div>
                  {serviceInvoicesQuestion === true && (
                    <ServiceInvoicesAnswer
                      id="tax-return-section-7-services-invoices-answers"
                      servicesAnswers={serviceInvoicesAnswer}
                      setServicesAnswers={(_servicesAnswer) => {
                        setServiceInvoicesAnswer(_servicesAnswer);
                      }}
                    />
                  )}
                </>
              )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12 mt-4">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-7-documents-withholding-tax-question"
                  title={lang.documentsWithholdingTaxQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={documentsWithholdingTaxQuestion}
                  onChange={(checked) => {
                    setDocumentsWithholdingTaxQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        dataToSend,
                        setStatusDocumentsWithholdingTax,
                        DocumentsTypeTaxReturnModel.WITHHOLDING_TAX,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusDocumentsWithholdingTax('idle');
                    }
                  }}
                  required={true}
                />
              </div>
            </div>
            {documentsWithholdingTaxQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusDocumentsWithholdingTax}
                      title={lang.documentsWithholdingTaxDocTitle}
                      fileList={documentsWithholdingTaxAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          dataToSend,
                          setStatusDocumentsWithholdingTax,
                          DocumentsTypeTaxReturnModel.WITHHOLDING_TAX,
                          documentsWithholdingTaxQuestion,
                          getSurveyStep,
                          doc,
                          documentsWithholdingTaxAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusDocumentsWithholdingTax,
                          docId,
                          index,
                          setDocumentsWithholdingTaxAnswer,
                          documentsWithholdingTaxAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusDocumentsWithholdingTax}
                    />
                  </div>
                </div>
              </>
            )}
            {(user.category === CategoryType.TRADER ||
              user.category === CategoryType.ARTISAN) && (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-7-means-transport-question"
                          title={lang.meansTransportQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={meansTransportQuestion}
                          onChange={(checked) =>
                            setMeansTransportQuestion(checked)
                          }
                          required={true}
                        />
                      </div>
                    </div>
                    {meansTransportQuestion === true && (
                      <div className={'row no-gutters mt-3'}>
                        <div className="col-6">
                          <NumericFormat
                            id="tax-return-section-7-means-transport-question-input"
                            key={
                              'tax-return-section-7-means-transport-question'
                            }
                            defaultValue={meansTransportAnswer}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              setMeansTransportAnswer(e.floatValue);
                            }}
                            styleType={'default'}
                            label={lang.meansTransportAnswerLabel}
                            placeholder={lang.meansTransportAnswerPlaceholder}
                            fixedDecimalScale
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-7-purchase-goods-question"
                          title={lang.purchaseGoodsQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={purchaseGoodsQuestion}
                          onChange={(checked) =>
                            setPurchaseGoodsQuestion(checked)
                          }
                          required={true}
                        />
                      </div>
                    </div>
                    {purchaseGoodsQuestion === true && (
                      <div className={'row no-gutters mt-3'}>
                        <div className="col-6">
                          <NumericFormat
                            id="tax-return-section-7-purchase-goods-question-input"
                            key={'tax-return-section-7-purchase-goods-question'}
                            suffix="€"
                            defaultValue={purchaseGoodsAnswer}
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              setPurchaseGoodsAnswer(e.floatValue);
                            }}
                            styleType={'default'}
                            label={lang.purchaseGoodsAnswerLabel}
                            placeholder={lang.purchaseGoodsAnswerPlaceholder}
                            fixedDecimalScale
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-7-financial-fee-question"
                          title={lang.financialFeeQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={financialFeeQuestion}
                          onChange={(checked) =>
                            setFinancialFeeQuestion(checked)
                          }
                          required={true}
                        />
                      </div>
                    </div>
                    {financialFeeQuestion === true && (
                      <div className={'row no-gutters mt-3'}>
                        <div className="col-6">
                          <NumericFormat
                            id="tax-return-section-7-financial-fee-question-input"
                            key={'tax-return-section-7-financial-fee-question'}
                            suffix="€"
                            defaultValue={financialFeeAnswer}
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              setFinancialFeeAnswer(e.floatValue);
                            }}
                            styleType={'default'}
                            label={lang.financialFeeAnswerLabel}
                            placeholder={lang.financialFeeAnswerPlaceholder}
                            fixedDecimalScale
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-7-rental-fee-question"
                          title={lang.rentalFeeQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={rentalFeeQuestion}
                          onChange={(checked) => setRentalFeeQuestion(checked)}
                          required={true}
                        />
                      </div>
                    </div>
                    {rentalFeeQuestion === true && (
                      <div className={'row no-gutters mt-3'}>
                        <div className="col-6">
                          <NumericFormat
                            id="tax-return-section-7-rental-fee-question-input"
                            key={'tax-return-section-7-rental-fee-question'}
                            suffix="€"
                            defaultValue={rentalFeeAnswer}
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              setRentalFeeAnswer(e.floatValue);
                            }}
                            styleType={'default'}
                            label={lang.rentalFeeAnswerLabel}
                            placeholder={lang.rentalFeeAnswerPlaceholder}
                            fixedDecimalScale
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-7-royalty-fee-question"
                          title={lang.royaltyFeeQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={royaltyFeeQuestion}
                          onChange={(checked) => setRoyaltyFeeQuestion(checked)}
                          required={true}
                        />
                      </div>
                    </div>
                    {royaltyFeeQuestion === true && (
                      <div className={'row no-gutters mt-3'}>
                        <div className="col-6">
                          <NumericFormat
                            id="tax-return-section-7-royalty-fee-question-input"
                            key={'tax-return-section-7-royalty-fee-question'}
                            suffix="€"
                            defaultValue={royaltyFeeAnswer}
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              setRoyaltyFeeAnswer(e.floatValue);
                            }}
                            styleType={'default'}
                            label={lang.royaltyFeeAnswerLabel}
                            placeholder={lang.royaltyFeeAnswerPlaceholder}
                            fixedDecimalScale
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className={'row no-gutters mt-4'}>
                      <div className="col-12">
                        <QuickfiscoBooleanCheckbox
                          id="tax-return-section-7-fuel-fee-question"
                          title={lang.fuelFeeQuestion}
                          yesLabel={lang.answerYes}
                          noLabel={lang.answerNo}
                          value={fuelFeeQuestion}
                          onChange={(checked) => setFuelFeeQuestion(checked)}
                          required={true}
                        />
                      </div>
                    </div>
                    {fuelFeeQuestion === true && (
                      <div className={'row no-gutters mt-3'}>
                        <div className="col-6">
                          <NumericFormat
                            id="tax-return-section-7-fuel-fee-question-input"
                            key={'tax-return-section-7-fuel-fee-question'}
                            suffix="€"
                            defaultValue={fuelFeeAnswer}
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            customInput={QuickfiscoInput}
                            onValueChange={(e) => {
                              setFuelFeeAnswer(e.floatValue);
                            }}
                            styleType={'default'}
                            label={lang.fuelFeeAnswerLabel}
                            placeholder={lang.fuelFeeAnswerPlaceholder}
                            fixedDecimalScale
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={'row no-gutters mt-4'}>
              <div className="col-12 mt-4">
                <QuickfiscoBooleanCheckbox
                  id="tax-return-section-7-electronic-devices-question"
                  title={lang.electronicDevicesQuestion}
                  yesLabel={lang.answerYes}
                  noLabel={lang.answerNo}
                  value={electronicDevicesQuestion}
                  onChange={(checked) => {
                    setElectronicDevicesQuestion(checked);
                    if (checked === false) {
                      saveQuestion(
                        dataToSend,
                        setStatusElectronicDevicesQuestion,
                        DocumentsTypeTaxReturnModel.ELECTRONIC_DEVICES,
                        false,
                        getSurveyStep
                      );
                    } else {
                      setStatusElectronicDevicesQuestion('idle');
                    }
                  }}
                  required={true}
                />
              </div>
            </div>
            {electronicDevicesQuestion === true && (
              <>
                <div className={'row no-gutters mt-3'}>
                  <div className="col-12">
                    <QuickfiscoFileList
                      status={statusElectronicDevicesQuestion}
                      title={lang.electronicDevicesDocTitle}
                      fileList={electronicDevicesAnswer}
                      addDoc={(doc) =>
                        saveQuestion(
                          dataToSend,
                          setStatusElectronicDevicesQuestion,
                          DocumentsTypeTaxReturnModel.ELECTRONIC_DEVICES,
                          electronicDevicesQuestion,
                          getSurveyStep,
                          doc,
                          electronicDevicesAnswer,
                          user
                        )
                      }
                      deleteDoc={(docId, index) =>
                        deleteDoc(
                          setStatusElectronicDevicesQuestion,
                          docId,
                          index,
                          setElectronicDevicesAnswer,
                          electronicDevicesAnswer
                        )
                      }
                      downloadDoc={(docId) => downloadDoc(docId)}
                      error={statusElectronicDevicesQuestion}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-7-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-7-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-7-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-7-go-next'}
                label={lang.goNext}
                type={checkgoNext ? 'secondary' : 'disabled'}
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function saveQuestion(
  dataToSend: SectionTaxReturn7,
  setStatus: SetState<PromiseStatuses>,
  docType: DocumentsTypeTaxReturnModel,
  question: boolean,
  getSurveyStep: Function,
  doc?: File | null,
  answer?: ObjectFileTaxReturnModel[],
  user?: UserModel
): void {
  setStatus('loading');

  const service = new TaxReturnService();

  const keyObjTypeMap = new Map<DocumentsTypeTaxReturnModel, string>([
    [DocumentsTypeTaxReturnModel.WITHHOLDING_TAX, 'documentsWithholdingTax'],
    [DocumentsTypeTaxReturnModel.ELECTRONIC_DEVICES, 'electronicDevices'],
  ]);
  const questionKey = keyObjTypeMap.get(docType) + 'Question';
  const answerKey = keyObjTypeMap.get(docType) + 'Answer';
  let objToSend = {};

  const counterRegex =
    answer && answer.length > 0
      ? answer[answer.length - 1].name.match(counterFileRegex)
      : undefined;
  const counter = counterRegex ? Number(counterRegex[0].split('.')[0]) + 1 : 1;

  if (question === true) {
    if (!doc || !user || !counter) {
      return;
    }

    if (!validateFile(doc)) {
      setStatus('failed');
      return;
    }

    const fileName = sanitizeString(
      `${docType}_${user.surname}_${user.name}_${counter}`
    ).toLowerCase();

    toBase64(doc)
      .then((res) => {
        const fileToSend = [
          {
            name: fileName + '.' + getExtension(doc),
            extension: getExtension(doc),
            file: res,
          },
        ];
        objToSend = {
          [questionKey]: question,
          [answerKey]: fileToSend,
        };

        service
          .setSurveyStep(7, { ...dataToSend, ...objToSend })
          .then(() => {
            setStatus('successfully');
            getSurveyStep();
          })
          .catch((err) => {
            setStatus('failedUploadApi');
            getSurveyStep();
            console.error(err);
          });
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        console.error(err);
      });
  } else {
    objToSend = {
      [questionKey]: question,
    };

    service
      .setSurveyStep(7, { ...dataToSend, ...objToSend })
      .then(() => {
        setStatus('successfully');
        getSurveyStep();
      })
      .catch((err) => {
        setStatus('failedUploadApi');
        getSurveyStep();
        console.error(err);
      });
  }
}

function deleteDoc(
  setStatus: SetState<PromiseStatuses>,
  docId: string,
  index: number,
  setAnswer: SetState<ObjectFileTaxReturnModel[] | undefined>,
  answer?: ObjectFileTaxReturnModel[]
): void {
  setStatus('loading');
  const service = new TaxReturnService();

  if (docId) {
    service
      .deleteTaxReturnDocuments(7, docId)
      .then(() => {
        if (answer) {
          const newAnswer = answer
            .slice(0, index)
            .concat(answer.slice(index + 1));
          setAnswer([...newAnswer]);
        }
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failedDeleteApi');
        console.error(err);
      });
  }
}
