import useComponentVisible from '../../hooks/componentVisible';
import { MenuAttributes } from '../quickfiscoMenu/quickfiscoMenu';
import { SubMenuCollapsedInfo } from '../subMenuCollapsedInfo/subMenuCollapsedInfo';
import './menuCollapsedInfo.css';

interface Props {
    label: string,
    subMenuItems?: MenuAttributes[]
}

export function MenuCollapsedInfo(props: Props) {
    const { label } = props;
    const { subMenuItems } = props;

    const componentVisible = useComponentVisible(false);

    return (
        <div className='menu-collapsed-info-container'>
            <div className={'position-relative h-100 w-100'}>
                <div
                    onMouseOver={() => componentVisible.setIsVisible(true)}
                    onMouseOut={() => componentVisible.setIsVisible(false)}
                    className={'h-100 w-100'}
                />
                <div
                    ref={componentVisible.ref}
                    className={'menu-collapsed-info-popup'}
                >
                    {
                        componentVisible.isVisible && (
                            <div
                                onMouseOver={() => componentVisible.setIsVisible(true)}
                                onMouseOut={() => componentVisible.setIsVisible(false)}
                            >
                                {subMenuItems ? (
                                    <SubMenuCollapsedInfo
                                        label={label}
                                        subMenuItems={subMenuItems}
                                    />
                                ) : (
                                    <div className={'menu-collapsed-info-popup-container'}>
                                        {label}
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
