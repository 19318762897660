import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import lang from './paymentResponseStatus.json';
import './paymentResponseStatus.css';

interface Props {
  paymentStatus: PromiseStatuses;
  onClick: Function;
  isRenewal?: boolean;
}

export function PaymentResponseStatus(props: Props) {
  const { paymentStatus } = props;
  const { onClick } = props;
  const { isRenewal } = props;

  return (
    <div className={'row text-center h-100'}>
      <div className="col-8 offset-2">
        <div className="payment-response-status-container">
          <div className="w-100">
            <QuickfiscoIcon
              name={
                paymentStatus === 'failed'
                  ? 'alert-error.svg'
                  : 'alert-success.svg'
              }
            />
            <p className="payment-response-status-title mt-4">
              {paymentStatus === 'failed'
                ? lang.titleFailed
                : lang.titleSuccess}
            </p>
            <p className="payment-response-status-text">
              {paymentStatus === 'failed' ? lang.textFailed : lang.textSuccess}
            </p>
            <div className="mt-5">
              {paymentStatus === 'failed' ? (
                <QuickfiscoButton
                  id={'invoice-auto-send-button'}
                  label={lang.buttonFailed}
                  type={'tertiary'}
                  onClick={() => onClick()}
                />
              ) : (
                <QuickfiscoButton
                  id={'invoice-auto-send-button'}
                  label={
                    isRenewal === true ? lang.buttonNext : lang.buttonSuccess
                  }
                  type={'senary'}
                  onClick={() => onClick()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
