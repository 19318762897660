import { useEffect, useState } from 'react';
import { ContactSaveOrEdit } from '../../components/contactSaveOrEdit/contactSaveOrEdit';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { ContactModel, ContactType } from '../../models/contactModel';
import { ContactService } from '../../services/contactService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import lang from './supplierEdit.json';
import { useProSidebar } from 'react-pro-sidebar';
import { useParams } from 'react-router-dom';

export function SupplierEdit() {

  const params = useParams();
  const id = params.id

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [supplier, setSupplier] = useState<ContactModel | undefined>(undefined);

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (id !== undefined) {
      findById(id, setStatus, setSupplier);
    }
  }, [id]);

  if (status === 'loading' || status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        {status === 'loading' && <QuickfiscoSpinner />}
        {status === 'failed' && <div>errore</div>}
      </div>
    );
  }

  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='supplier-edit-menu'
            menuItem={MenuItems.CONTACTS_SUPPLIER}
          />
        </div>
        <div className='col p-4 ps-5' style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
          <div className={'row'}>
            <div className={'col-12'}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <ContactSaveOrEdit
                contactType={ContactType.SUPPLIER}
                operationType="edit"
                contactToEdit={supplier}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function findById(
  id: string,
  setStatus: SetState<PromiseStatuses>,
  setSupplier: SetState<ContactModel | undefined>
) {
  const contactService = new ContactService();

  setStatus('loading');
  contactService.findById(id)
    .then(supplier => {
      setStatus('idle');
      setSupplier(supplier);
    })
    .catch(err => {
      console.error(err);
      setStatus('failed');
    });
}
