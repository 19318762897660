import { InvoiceErrorSetters } from '../models/invoiceErrorSetters';
import { InvoiceFilters } from '../models/invoiceFilters';
import { InvoiceModel } from '../models/invoiceModel';
import { PaginationModel } from '../models/paginationModel';
import { host } from '../utils/config';
import { getDateRangeByYearAndMonth } from '../utils/date';
import { CommonInvoiceService } from './commonInvoiceService';

export class OccasionalJobInvoiceService extends CommonInvoiceService {

  public constructor(errorSetters?: InvoiceErrorSetters) {
    super(host + '/api/v1/invoices/occasional-job', errorSetters);
}

  public findAll(filters: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> {
    const {
      dateFrom,
      dateTo
    } = getDateRangeByYearAndMonth(filters.year, filters.month);
    
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url +
      '?fromDate=' + dateFrom +
      '&toDate=' + dateTo
    })
      .then(res => res.json())
      .then((data: PaginationModel<InvoiceModel>) => data)
  }

  public findById(invoiceId?: string): Promise<InvoiceModel> {
    return this.http.sendAndReceive({
      url: this.url + '/' + invoiceId,
      method: 'get'
    })
      .then(res => res.json())
      .then((data: InvoiceModel) => data);
  }

  public changeStatus(invoiceId?: string): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/' + invoiceId + '/status',
      method: 'put',
      body: JSON.stringify({status: "REVERSED"}),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(() => undefined);
  }

  public send(invoiceId: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + '/' + invoiceId + '/send'
    })
      .then(() => undefined);
  }
}
