import React from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import {
  BuildingsTaxReturnModel,
  LandsTaxReturnModel,
} from '../../models/taxReturnModel';
import { NumericFormat } from 'react-number-format';
import { QuickfiscoYesNoSelect } from '../quickfiscoYesNoSelect/quickfiscoYesNoSelect';
import { TaxReturnUploadDoc } from '../taxReturnUploadDoc/taxReturnUploadDoc';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoBuildingUsageSelect } from '../quickfiscoBuildingUsageSelect/quickfiscoBuildingUsageSelect';
import { showBuldingExtraField } from '../../utils/taxReturn';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { TaxReturnService } from '../../services/taxReturnService';
import { resolveUndefinedDate, formatDateUS } from '../../utils/date';
import lang from './landsBuildingsAnswerInput.json';
import './landsBuildingsAnswerInput.css';

interface Props {
  id: string;
  type: 'lands' | 'buildings';
  answer: LandsTaxReturnModel[] | BuildingsTaxReturnModel[];
  setAnswer: (
    answer: LandsTaxReturnModel[] | BuildingsTaxReturnModel[]
  ) => void;
}

export function LandsBuildingsAnswerInput(props: Props) {
  const { id } = props;
  const { type } = props;
  const { answer } = props;
  const { setAnswer } = props;

  let lands: React.ReactNode[] = [];
  let buildings: React.ReactNode[] = [];
  if (type === 'lands') {
    lands = answer.map((_, index) => {
      return getLands(index, setAnswer, answer);
    });
  } else {
    buildings = answer.map((_, index) => {
      return getBuildings(index, setAnswer, answer, showBuldingExtraField);
    });
  }

  const emptyLandObj: LandsTaxReturnModel = {
    city: '',
    foglio: '',
    particella: '',
    sub: '',
    agriculturalIncome: undefined,
    dominicalIncome: undefined,
    possessionDaysFrom: undefined,
    possessionDaysTo: undefined,
    percentagePossession: undefined,
    imuSubject: undefined,
  };

  const emptyBuildingObj: BuildingsTaxReturnModel = {
    city: '',
    foglio: '',
    particella: '',
    sub: '',
    landRegistryCategory: '',
    landRegistryIncome: undefined,
    possessionDaysFrom: undefined,
    possessionDaysTo: undefined,
    percentagePossession: undefined,
    imuSubject: undefined,
    usage: undefined,
    shortRentals: undefined,
  };

  return (
    <div className={`${id} mb-4`}>
      {type === 'lands' && lands}
      {type === 'buildings' && buildings}
      <div className={'row mt-4'}>
        <div className={'col-5 offset-7'}>
          <QuickfiscoButton
            id={'lands-buildings-answers-add-button'}
            label={lang[type].AddButton}
            onClick={() =>
              setAnswer([
                ...answer,
                type === 'lands' ? emptyLandObj : emptyBuildingObj,
              ])
            }
            type={'primary'}
          />
        </div>
      </div>
    </div>
  );
}

function getLands(
  index: number,
  setAnswer: (answer: LandsTaxReturnModel[]) => void,
  answer: LandsTaxReturnModel[]
): React.ReactNode {
  const taxReturnService = new TaxReturnService();

  return (
    <div className={'row mt-5'} key={'item-' + answer[index] + index}>
      <div className="col-12">
        <div className="row d-flex align-items-center justify-content-between">
          <div className={'col-auto'}>
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <span className="lands-buildings-answers-sub-title">
                  {index + 1}
                  {lang.lands.subTitle}
                </span>
              </div>
              {answer[index].isConfirmed && (
                <div className={'col-auto'}>
                  <QuickfiscoIcon
                    name={'status-completed.svg'}
                    className="pe-2 pb-1"
                  />
                  <span className="lands-buildings-answers-sub-title-confirmed">
                    {lang.lands.confirmed}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={'col-auto'}>
            <div className="row d-flex align-items-center">
              {!answer[index].isConfirmed && (
                <div className="col-auto">
                  <QuickfiscoButton
                    id={'lands-answers-delete-button'}
                    label={lang.lands.deleteButton}
                    onClick={() => {
                      const newGoodService = answer
                        .slice(0, index)
                        .concat(answer.slice(index + 1));

                      setAnswer([...newGoodService]);
                    }}
                    type={'tertiary'}
                  />
                </div>
              )}
              <div className="col-auto">
                <QuickfiscoButton
                  id={'lands-answers-confirm-button'}
                  label={
                    answer[index].isConfirmed === true
                      ? lang.lands.unlockButton
                      : lang.lands.confirmButton
                  }
                  onClick={() => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      isConfirmed:
                        answer[index].isConfirmed === true ? false : true,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  type={
                    taxReturnService.validateSectionLand(answer[index]) ===
                    false
                      ? 'disabled'
                      : answer[index].isConfirmed
                      ? 'primary'
                      : 'senary'
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            answer[index].isConfirmed === true
              ? 'lands-buildings-answers-container-confirmed'
              : 'lands-buildings-answers-container'
          } mt-3 py-3 px-4`}
        >
          <div className="row mt-2">
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'lands-answers-city'}
                type={'text'}
                styleType={'default'}
                label={lang.city}
                placeholder={lang.city}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    city: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].city || ''}
                required={true}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'lands-answers-foglio'}
                type={'text'}
                styleType={'default'}
                label={lang.foglio}
                placeholder={lang.foglio}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    foglio: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].foglio}
                required={true}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'lands-answers-particella'}
                type={'text'}
                styleType={'default'}
                label={lang.particella}
                placeholder={lang.particella}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    particella: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].particella}
                required={true}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'lands-answers-sub'}
                type={'text'}
                styleType={'default'}
                label={lang.sub}
                placeholder={lang.sub}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    sub: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].sub}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className="row mt-2">
              <div className={'col-6'}>
                <NumericFormat
                  id={'lands-answers-agriculturalIncome-input'}
                  key={'lands-answers-agriculturalIncome'}
                  suffix="€"
                  value={answer[index].agriculturalIncome}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  customInput={QuickfiscoInput}
                  onValueChange={(e) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      agriculturalIncome: e.floatValue,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  styleType={'default'}
                  label={lang.lands.agriculturalIncome}
                  placeholder={lang.lands.agriculturalIncome}
                  fixedDecimalScale
                  required={true}
                  disabled={
                    answer[index].isExternalSource === true ||
                    answer[index].isConfirmed === true
                  }
                />
              </div>
              <div className={'col-6'}>
                <NumericFormat
                  id={'lands-answers-dominicalIncome-input'}
                  key={'lands-answers-dominicalIncome'}
                  suffix="€"
                  value={answer[index].dominicalIncome}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  customInput={QuickfiscoInput}
                  onValueChange={(e) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      dominicalIncome: e.floatValue,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  styleType={'default'}
                  label={lang.lands.dominicalIncome}
                  placeholder={lang.lands.dominicalIncome}
                  fixedDecimalScale
                  required={true}
                  disabled={
                    answer[index].isExternalSource === true ||
                    answer[index].isConfirmed === true
                  }
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className={'col-3'}>
                <QuickfiscoDatePicker
                  id={'lands-answers-possessionDaysFrom'}
                  label={lang.possessionDaysFrom}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      possessionDaysFrom:
                        value && resolveUndefinedDate(formatDateUS(value)),
                    };
                    setAnswer([...newAnswer]);
                  }}
                  value={
                    answer[index] && answer[index].possessionDaysFrom
                      ? new Date(answer[index].possessionDaysFrom as Date)
                      : undefined
                  }
                  required={true}
                  minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
                  maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                  styleType={'default'}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
              <div className={'col-3'}>
                <QuickfiscoDatePicker
                  id={'lands-answers-possessionDaysTo'}
                  label={lang.possessionDaysTo}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      possessionDaysTo:
                        value && resolveUndefinedDate(formatDateUS(value)),
                    };
                    setAnswer([...newAnswer]);
                  }}
                  value={
                    answer[index] && answer[index].possessionDaysTo
                      ? new Date(answer[index].possessionDaysTo as Date)
                      : undefined
                  }
                  required={true}
                  minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
                  maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                  styleType={'default'}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
              <div className={'col-6'}>
                <NumericFormat
                  id="lands-answers-possessionDaysTo-input"
                  key={'lands-answers-possessionDaysTo'}
                  suffix="%"
                  value={answer[index].percentagePossession}
                  maxLength={7}
                  decimalSeparator=","
                  decimalScale={2}
                  customInput={QuickfiscoInput}
                  onValueChange={(e) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      percentagePossession: e.floatValue,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  styleType={'default'}
                  label={lang.percentagePossession}
                  placeholder={lang.percentagePossession}
                  fixedDecimalScale
                  required={true}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className={'col-3'}>
                <QuickfiscoYesNoSelect
                  id={'lands-answers-imuSubject'}
                  label={lang.imuSubject}
                  value={answer[index].imuSubject}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      imuSubject: value,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  requiredField={true}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getBuildings(
  index: number,
  setAnswer: (answer: BuildingsTaxReturnModel[]) => void,
  answer: BuildingsTaxReturnModel[],
  showBuldingExtraField: Function
): React.ReactNode {
  const taxReturnService = new TaxReturnService();

  return (
    <div className={'row mt-5'} key={'item-' + answer[index] + index}>
      <div className="col-12">
        <div className="row d-flex align-items-center justify-content-between">
          <div className={'col-auto'}>
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <span className="lands-buildings-answers-sub-title">
                  {index + 1}
                  {lang.buildings.subTitle}
                </span>
              </div>
              {answer[index].isConfirmed && (
                <div className={'col-auto'}>
                  <QuickfiscoIcon
                    name={'status-completed.svg'}
                    className="pe-2 pb-1"
                  />
                  <span className="lands-buildings-answers-sub-title-confirmed">
                    {lang.buildings.confirmed}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={'col-auto'}>
            <div className="row d-flex align-items-center">
              {!answer[index].isConfirmed && (
                <div className="col-auto">
                  <QuickfiscoButton
                    id={'buildings-answers-delete-button'}
                    label={lang.buildings.deleteButton}
                    onClick={() => {
                      const newGoodService = answer
                        .slice(0, index)
                        .concat(answer.slice(index + 1));

                      setAnswer([...newGoodService]);
                    }}
                    type={'tertiary'}
                  />
                </div>
              )}
              <div className="col-auto">
                <QuickfiscoButton
                  id={'buildings-answers-confirm-button'}
                  label={
                    answer[index].isConfirmed === true
                      ? lang.buildings.unlockButton
                      : lang.buildings.confirmButton
                  }
                  onClick={() => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      isConfirmed:
                        answer[index].isConfirmed === true ? false : true,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  type={
                    taxReturnService.validateSectionBuilding(answer[index]) ===
                    false
                      ? 'disabled'
                      : answer[index].isConfirmed
                      ? 'primary'
                      : 'senary'
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            answer[index].isConfirmed === true
              ? 'lands-buildings-answers-container-confirmed'
              : 'lands-buildings-answers-container'
          } mt-3 py-3 px-4`}
        >
          <div className="row mt-2">
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'buildings-answers-city'}
                type={'text'}
                styleType={'default'}
                label={lang.city}
                placeholder={lang.city}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    city: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].city || ''}
                required={true}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'buildings-answers-foglio'}
                type={'text'}
                styleType={'default'}
                label={lang.foglio}
                placeholder={lang.foglio}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    foglio: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].foglio}
                required={true}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'buildings-answers-particella'}
                type={'text'}
                styleType={'default'}
                label={lang.particella}
                placeholder={lang.particella}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    particella: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].particella}
                required={true}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id={'buildings-answers-sub'}
                type={'text'}
                styleType={'default'}
                label={lang.sub}
                placeholder={lang.sub}
                onChange={(e) => {
                  const newAnswer = [...answer];
                  newAnswer[index] = {
                    ...answer[index],
                    sub: e.target.value,
                  };
                  setAnswer([...newAnswer]);
                }}
                value={answer[index].sub}
                disabled={
                  answer[index].isExternalSource === true ||
                  answer[index].isConfirmed === true
                }
              />
            </div>
            <div className="row mt-2">
              <div className={'col-6'}>
                <QuickfiscoInput
                  id={'buildings-answers-landRegistryCategory'}
                  type={'text'}
                  styleType={'default'}
                  label={lang.buildings.landRegistryCategory}
                  placeholder={lang.buildings.landRegistryCategory}
                  onChange={(e) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      landRegistryCategory: e.target.value,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  value={answer[index].landRegistryCategory}
                  required={true}
                  disabled={
                    answer[index].isExternalSource === true ||
                    answer[index].isConfirmed === true
                  }
                />
              </div>
              <div className={'col-6'}>
                <NumericFormat
                  id={'buildings-answers-landRegistryIncome-input'}
                  key={'buildings-answers-landRegistryIncome'}
                  suffix="€"
                  value={answer[index].landRegistryIncome}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  customInput={QuickfiscoInput}
                  onValueChange={(e) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      landRegistryIncome: e.floatValue,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  styleType={'default'}
                  label={lang.buildings.landRegistryIncome}
                  placeholder={lang.buildings.landRegistryIncome}
                  fixedDecimalScale
                  required={true}
                  disabled={
                    answer[index].isExternalSource === true ||
                    answer[index].isConfirmed === true
                  }
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className={'col-3'}>
                <QuickfiscoDatePicker
                  id={'buildings-answers-possessionDaysFrom'}
                  label={lang.possessionDaysFrom}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      possessionDaysFrom:
                        value && resolveUndefinedDate(formatDateUS(value)),
                    };
                    setAnswer([...newAnswer]);
                  }}
                  value={
                    answer[index] && answer[index].possessionDaysFrom
                      ? new Date(answer[index].possessionDaysFrom as Date)
                      : undefined
                  }
                  required={true}
                  minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
                  maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                  styleType={'default'}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
              <div className={'col-3'}>
                <QuickfiscoDatePicker
                  id={'buildings-answers-possessionDaysTo'}
                  label={lang.possessionDaysTo}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      possessionDaysTo:
                        value && resolveUndefinedDate(formatDateUS(value)),
                    };
                    setAnswer([...newAnswer]);
                  }}
                  value={
                    answer[index] && answer[index].possessionDaysTo
                      ? new Date(answer[index].possessionDaysTo as Date)
                      : undefined
                  }
                  required={true}
                  minDate={new Date(new Date().getFullYear() - 1, 0, 1)}
                  maxDate={new Date(new Date().getFullYear() - 1, 11, 31)}
                  styleType={'default'}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
              <div className={'col-6'}>
                <NumericFormat
                  id="buildings-answers-possessionDaysTo-input"
                  key={'buildings-answers-possessionDaysTo'}
                  suffix="%"
                  value={answer[index].percentagePossession}
                  maxLength={7}
                  decimalSeparator=","
                  decimalScale={2}
                  customInput={QuickfiscoInput}
                  onValueChange={(e) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      percentagePossession: e.floatValue,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  styleType={'default'}
                  label={lang.percentagePossession}
                  placeholder={lang.percentagePossession}
                  fixedDecimalScale
                  required={true}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className={'col-3'}>
                <QuickfiscoYesNoSelect
                  id={'buildings-answers-imuSubject'}
                  label={lang.imuSubject}
                  value={answer[index].imuSubject}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    newAnswer[index] = {
                      ...answer[index],
                      imuSubject: value,
                    };
                    setAnswer([...newAnswer]);
                  }}
                  requiredField={true}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
              <div className={'col-9'}>
                <QuickfiscoBuildingUsageSelect
                  id={'buildings-answers-usage'}
                  value={answer[index].usage}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    if (
                      !showBuldingExtraField(answer[index].shortRentals, value)
                    ) {
                      newAnswer[index] = {
                        ...answer[index],
                        usage: value,
                      };
                      delete newAnswer[index].amountAnnualRent;
                      delete newAnswer[index].cedolareSecca;
                      delete newAnswer[index].leaseContract;
                    } else {
                      newAnswer[index] = {
                        ...answer[index],
                        usage: value,
                      };
                    }
                    setAnswer([...newAnswer]);
                  }}
                  requiredField={true}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className={'col-3'}>
                <QuickfiscoYesNoSelect
                  id={'buildings-answers-shortRentals'}
                  label={lang.buildings.shortRentals}
                  value={answer[index].shortRentals}
                  onChange={(value) => {
                    const newAnswer = [...answer];
                    if (!showBuldingExtraField(value, answer[index].usage)) {
                      newAnswer[index] = {
                        ...answer[index],
                        shortRentals: value,
                      };
                      delete newAnswer[index].amountAnnualRent;
                      delete newAnswer[index].cedolareSecca;
                      delete newAnswer[index].leaseContract;
                    } else {
                      newAnswer[index] = {
                        ...answer[index],
                        shortRentals: value,
                      };
                    }
                    setAnswer([...newAnswer]);
                  }}
                  requiredField={true}
                  disabled={answer[index].isConfirmed === true}
                />
              </div>
              {showBuldingExtraField(
                answer[index].shortRentals,
                answer[index].usage
              ) && (
                <>
                  <div className="col-6">
                    <NumericFormat
                      id="buildings-answers-amount-annual-rent-input"
                      key={'buildings-answers-amount-annual-rent'}
                      suffix="€"
                      value={answer[index].amountAnnualRent}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      customInput={QuickfiscoInput}
                      onValueChange={(e) => {
                        const newAnswer = [...answer];
                        newAnswer[index] = {
                          ...answer[index],
                          amountAnnualRent: e.floatValue,
                        };
                        setAnswer([...newAnswer]);
                      }}
                      styleType={'default'}
                      label={lang.buildings.amountAnnualRent}
                      placeholder={lang.buildings.amountAnnualRent}
                      fixedDecimalScale
                      required={true}
                      disabled={answer[index].isConfirmed === true}
                    />
                  </div>
                  <div className={'col-3'}>
                    <QuickfiscoYesNoSelect
                      id={'buildings-answers-cedolareSecca'}
                      label={lang.buildings.cedolareSecca}
                      value={answer[index].cedolareSecca}
                      onChange={(value) => {
                        const newAnswer = [...answer];
                        newAnswer[index] = {
                          ...answer[index],
                          cedolareSecca: value,
                        };
                        setAnswer([...newAnswer]);
                      }}
                      requiredField={true}
                      disabled={answer[index].isConfirmed === true}
                    />
                  </div>
                </>
              )}
            </div>
            {showBuldingExtraField(
              answer[index].shortRentals,
              answer[index].usage
            ) && (
              <div className="row my-3">
                <div className={'col-auto'}>
                  <TaxReturnUploadDoc
                    index={index}
                    label={lang.buildings.uploadDocLabel}
                    fileName={lang.buildings.uploadDocFileName}
                    saveDoc={(doc) => {
                      const newAnswer = [...answer];
                      newAnswer[index] = {
                        ...answer[index],
                        leaseContract: doc,
                      };
                      setAnswer([...newAnswer]);
                    }}
                    deleteDoc={() => {
                      const newAnswer = [...answer];
                      newAnswer[index] = {
                        ...answer[index],
                        leaseContract: undefined,
                      };
                      setAnswer([...newAnswer]);
                    }}
                    sectionNumber={3}
                    doc={answer[index].leaseContract}
                    disabled={answer[index].isConfirmed === true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
