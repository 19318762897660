import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: number;
  onChange?: (paymentsNumber?: number) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoPaymentsNumberSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'Indica il numero di rate'}
      options={getOptions()}
      styleType={'default'}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      onChange={(e) =>
        e &&
        onChange &&
        onChange(e.value !== undefined ? Number(e.value) : undefined)
      }
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
    />
  );
}
function getOptions(value?: number): OptionModel[] {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined || value === null,
    },
    { label: '1', value: '1', selected: value === 1 },
    { label: '2', value: '2', selected: value === 2 },
    { label: '3', value: '3', selected: value === 3 },
    { label: '4', value: '4', selected: value === 4 },
    { label: '5', value: '5', selected: value === 5 },
    { label: '6', value: '6', selected: value === 6 },
  ];
}
