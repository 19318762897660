import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { OnboardingDocTypeModel } from '../../models/userModel';
import lang from './docIdTypeSelect.json';

interface Props {
  id: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?: (value: OnboardingDocTypeModel | undefined) => void;
}

export function DocIdTypeSelect(props: Props) {
  const { id } = props;
  const { onChange } = props;
  const { error } = props;
  const { value } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={lang.Label}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(OnboardingDocTypeModel[e.value?.toUpperCase()]);
        }
      }}
      error={error}
      errorLabel={lang.Error}
      options={getOptions(value)}
      styleType={'default'}
      requiredField={true}
      disabled={disabled}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Carta di identità',
      value: OnboardingDocTypeModel.IDENTITY_CARD,
      selected: value === OnboardingDocTypeModel.IDENTITY_CARD,
    },
    {
      label: 'Passaporto',
      value: OnboardingDocTypeModel.PASSPORT,
      selected: value === OnboardingDocTypeModel.PASSPORT,
    },
    {
      label: 'Permesso di soggiorno',
      value: OnboardingDocTypeModel.RESIDENCY_PERMIT,
      selected: value === OnboardingDocTypeModel.RESIDENCY_PERMIT,
    },
    {
      label: 'Altro',
      value: OnboardingDocTypeModel.OTHER,
      selected: value === OnboardingDocTypeModel.OTHER,
    },
  ];
}
