import { SingleValue } from 'react-select';
import { ProformaInvoiceStatusFilterType } from '../../models/invoiceFilters';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
    id: string;
    value?: ProformaInvoiceStatusFilterType;
    onChange?: ((status?: ProformaInvoiceStatusFilterType) => void);
}

export function InvoiceProformaStatusSelect(props: Props) {
    const { id } = props;
    const { value } = props;
    const { onChange } = props;

    return (
        <QuickfiscoSelect
            id={id}
            options={getProformaOptions(value)}
            styleType={'no-borders'}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {
                    // @ts-ignore
                    onChange && onChange(ProformaInvoiceStatusFilterType[e.value])
                }
            }}
            value={getProformaOptions(value).filter(option => option.selected === true)[0]}

        />
    );
}

function getProformaOptions(value?: ProformaInvoiceStatusFilterType): OptionModel[] {
    return [
        {
            label: 'Stato',
            value: undefined,
            selected: value === undefined
        },
        {
            label: "Inviata",
            value: ProformaInvoiceStatusFilterType.SENT,
            selected: value === ProformaInvoiceStatusFilterType.SENT
        },
    ];
}
