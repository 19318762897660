import { CodeModel, LoginModel } from '../models/loginModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class LoginService {

  private readonly url = host + '/api/v1/users';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public doLogin(request: LoginModel): Promise<string> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + "/token",
      body: JSON.stringify(request)
    })
      .then((res => res.text()));
  }

  public validateLogin(request: CodeModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + "/validation",
      body: JSON.stringify(request)
    })
      .then();
  }

  public resendCode(request: CodeModel): Promise<void> {
    return this.http.sendAndReceive({
      method: 'post',
      url: this.url + "/admin/send-code",
      body: JSON.stringify(request)
    })
      .then();
  }

}
