import { Spinner } from 'react-bootstrap';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { setItem } from '../../utils/localStorage';
import './incomeUpload.css';

export function IncomeUpload() {
  return (
    <div className="income-upload-container box-shadow p-4">
      <div className={'row d-flex align-items-center h-100'}>
        <div className={'col-12'}>
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="income-upload-spinner">
                <Spinner animation="grow" variant="warning" />
              </div>
              <span className={'income-upload-title'}>AZIONE NECESSARIA</span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <span className={'income-upload-subtitle'}>
                Verifica i tuoi incassi del 2023
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 mt-3">
              <QuickfiscoButton
                id={'income-upload-procedure'}
                label={'INIZIA PROCEDURA'}
                type={'quaternary'}
                bold={true}
                onClick={() => {
                  setItem('incomeUploadModalStatus', 'show');
                  const event = new Event('local-storage');
                  window.dispatchEvent(event);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
