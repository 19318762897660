import { useEffect, useState } from 'react';
import { v4 as uiidv4 } from 'uuid';
import { ContactType } from '../../models/contactModel';
import { InvoiceErrorSetters } from '../../models/invoiceErrorSetters';
import { InvoiceModel, InvoiceStatusType, InvoiceType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setGlobalStatus } from '../../redux/slices/operationStatusSlice';
import {
  editStsInvoice,
  setStsInvoiceSaveOrDuplicateOrEditInvoiceId,
  setStsInvoiceSaveOrDuplicateOrEditOperation,
  setStsInvoiceSaveOrDuplicateOrEditSuccessfullySaved
} from '../../redux/slices/stsInvoiceSaveOrDuplicateOrEditSlice';
import { setStsInvoiceAteco, setStsInvoiceBankAccount, setStsInvoiceCommunicationData, setStsInvoiceCustomer, setStsInvoiceDate, setStsInvoiceFileList, setStsInvoiceFund, setStsInvoiceGoodServices, setStsInvoiceNotes, setStsInvoicePaymentExpiration, setStsInvoicePaymentMode, setStsInvoiceSocialContribution, setStsInvoiceStamp, setStsInvoiceStatus, setStsInvoiceTrackedPayment, setStsInvoiceType, setStsStampId } from '../../redux/slices/stsInvoiceSlice';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { Operation, PromiseStatuses } from '../../types/strings';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { downloadPdf, validateFiles } from '../../utils/file';
import { formatNumberIT } from '../../utils/number';
import { AlreadyTransmittedCheckbox } from '../alreadyTransmittedCheckbox/alreadyTransmittedCheckbox';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import { CommunicateStsChange } from '../communicateStsChange/communicateStsChange';
import { ContactSelect } from '../contactSelect/contactSelect';
import { fundsMap } from '../fundSelect/fundSelect';
import { GoodServicesInput } from '../goodServicesInput/goodServicesInput';
import { InvoiceAmount } from '../invoiceAmount/invoiceAmount';
import { InvoicePaymentList } from '../invoicePaymentList/invoicePaymentList';
import { PaymentExpirationInput } from '../paymentExpirationInput/paymentExpirationInput';
import { PaymentModeSelect } from '../paymentModeSelect/paymentModeSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoSwitchInput } from '../quickfiscoSwitchInput/quickfiscoSwitchInput';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { SeePreviewPdfModal } from '../seePreviewPdfModal/seePreviewPdfModal';
import { SendStsInvoice } from '../sendStsInvoice/sendStsInvoice';
import { SwitchToCustomerInvoice } from '../switchToCustomerInvoice/switchToCustomerInvoice';
import { CategoryType, UserStatusType } from '../../models/userModel';
import { SendTrialInvoiceModal } from '../sendTrialInvoiceModal/sendTrialInvoiceModal';
import lang from './stsInvoiceSaveOrDuplicateOrEdit.json';
import './stsInvoiceSaveOrDuplicateOrEdit.css';
import { QuickfiscoBankSelect } from '../quickfiscoBankSelect/quickfiscoBankSelect';

export function StsInvoiceSaveOrDuplicateOrEdit() {
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [sendEmailStatus, setSendEmailStatus] = useState<PromiseStatuses>('idle');
  const [downloadPdfStatus, setDownloadPdfStatus] = useState<PromiseStatuses>('idle');
  const [sendSectionOpen, setSendSectionOpen] = useState(false);
  const [communicateChangeOpen, setCommunicateChangeOpen] = useState(false);
  const [seePreviewPdfOpen, setSeePreviewPdfOpen] = useState(false);
  const [sendTrialInvoiceOpen, setSendTrialInvoiceOpen] = useState(false);

  // TODO remove
  const [errorCustomer, setErrorCustomer] = useState(false);
  const [errorGoodServices, setErrorGoodServices] = useState(false);
  const [errorGoodServicesInput, setErrorGoodServicesInput] = useState(false);
  const [errorPaymentMode, setErrorPaymentMode] = useState(false);
  const [errorExpenseType, setErrorExpenseType] = useState(false);
  const [errorTrackedPayment, setErrorTrackedPayment] = useState(false);
  const [errorSendData, setErrorSendData] = useState(false);
  const [errorFiles, setErrorFiles] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorEnasarco, setErrorEnasarco] = useState(false);
  const [errorDateMessage, setErrorDateMessage] = useState(lang.DateError);
  const [errorPaymentExpiration, setErrorPaymentExpiration] = useState(false)
  const [errorStampId, setErrorStampId] = useState(false);

  const service = new StsInvoiceService(
    {
      setErrorCustomer,
      setErrorGoodServicesInput,
      setErrorGoodServices,
      setErrorPaymentMode,
      setErrorExpenseType,
      setErrorTrackedPayment,
      setErrorSendData,
      setErrorDate,
      setErrorDateMessage,
      setErrorEnasarco,
      setErrorPaymentExpiration,
      setErrorStampId,
    }
  );
  // TODO remove

  const userState = useAppSelector(state => state.user);
  const stsInvoiceState = useAppSelector(state => state.stsInvoice);
  const stsInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.stsInvoiceSaveOrDuplicateOrEdit);
  const globalStatus = useAppSelector(state => state.operationStatus.status);
  const globalErrorMessage = useAppSelector(state => state.operationStatus.errorMessage);

  const user = userState.user
  const invoice = stsInvoiceState.invoice
  const fileList = stsInvoiceState.fileList
  const operation = stsInvoiceSaveOrDuplicateOrEditState.operation
  const successfullySaved = stsInvoiceSaveOrDuplicateOrEditState.successfullySaved

  const [userIsNotEnabled, setUserIsNotEnabled] = useState(UserService.isNotEnabled(user))
  const [invoiceIsEditable, setInvoiceIsEditable] = useState(StsInvoiceService.isEditable(invoice))
  const [invoiceIsSendable, setInvoiceIsSendable] = useState(StsInvoiceService.isSendable(invoice))

  useEffect(() => {
    if (operation === 'edit' || operation === 'duplicate') {
      dispatch(setStsInvoiceType(InvoiceType.TD01))
    }

    if (
      operation === 'duplicate' &&
      invoice.status !== InvoiceStatusType.DRAFT &&
      invoice.status !== InvoiceStatusType.ALREADY_TRANSMITTED
    ) {
      dispatch(setStsInvoiceStatus(InvoiceStatusType.DRAFT))
    }

    if (user.fund !== undefined && operation === 'save') {
      dispatch(setStsInvoiceFund(user.fund))
    }

  }, [invoice, operation])

  useEffect(() => {
    setUserIsNotEnabled(UserService.isNotEnabled(user))
  }, [user.enabled, user.status])

  useEffect(() => {
    if ((operation === 'edit' || operation === 'duplicate') &&
      invoice.status !== InvoiceStatusType.ALREADY_TRANSMITTED) {
      setInvoiceIsEditable(StsInvoiceService.isEditable(invoice))
      setInvoiceIsSendable(StsInvoiceService.isSendable(invoice))
    }
  }, [invoice.status, operation])

  if (userState.status === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati.
      </div>
    )
  }

  if (userState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  let recourseValue: number | undefined = 0
  if (invoice.fund !== undefined) {
    recourseValue = fundsMap.get(invoice.fund)?.recourseValue
  }

  return (
    <div className={'row'}>
      <QuickfiscoSuccess
        message={lang.SaveSuccess}
        active={successfullySaved}
        close={() => dispatch(setStsInvoiceSaveOrDuplicateOrEditSuccessfullySaved(false))}
      />
      <QuickfiscoSuccess
        message={lang.EditSuccess}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={lang.SavingError}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        message={globalErrorMessage === undefined ? lang.SendError : globalErrorMessage}
        active={globalStatus === 'failed'}
        close={() => dispatch(setGlobalStatus({
          status: 'idle'
        }))}
      />
      <QuickfiscoError
        message={lang.DownloadPdfError}
        active={downloadPdfStatus === 'failed'}
        close={() => setDownloadPdfStatus('idle')}
      />
      <QuickfiscoSuccess
        message={lang.emailSuccess}
        active={sendEmailStatus === 'successfully'}
        close={() => setSendEmailStatus('idle')}
      />
      <QuickfiscoError
        message={lang.emailError}
        active={sendEmailStatus === 'failed'}
        close={() => setSendEmailStatus('idle')}
      />
      <div className={'col-12 col-xl-5 mt-4'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <SwitchToCustomerInvoice
              operation={operation}
              invoiceSign={'customer'}
              setTrackedPayment={(_trackedPayment?: string) => {
                dispatch(setStsInvoiceTrackedPayment(_trackedPayment === 'SI'));
                service.validateTrackedPayment(_trackedPayment === 'SI');
              }}
              trackedPayment={invoice.trackedPayment === true ? 'SI' : 'NO'}
              notSendData={invoice.customerDataCommunication}
              setNotSendData={(notSendData: boolean) => {
                dispatch(setStsInvoiceCommunicationData(notSendData));
              }}
              errorTrackedPayment={errorTrackedPayment}
              errorSendData={errorSendData}
              disabled={!invoiceIsEditable}
            />
          </div>
        </div>
        <div className={'row no-gutters mt-4'}>
          <div className={'col-12 no-gutters'}>
            <ContactSelect
              type={ContactType.CUSTOMER}
              selected={invoice.customer}
              setContact={contact => {
                dispatch(setStsInvoiceCustomer(contact));
                service.validateContact(contact);
              }}
              error={errorCustomer}
              requiredField={true}
              disabled={!invoiceIsEditable}
            />
          </div>
        </div>
        <div className={'row no-gutters mt-4'}>
          <div className={'col-12 invoice-sts-saveOrEdit-container p-4'}>
            <AlreadyTransmittedCheckbox
              id='sts-invoice-already-transmitted-input'
              value={invoice.status}
              disabled={!invoiceIsEditable}
              onChange={e => {
                dispatch(setStsInvoiceStatus(e.target.checked ? InvoiceStatusType.ALREADY_TRANSMITTED : InvoiceStatusType.DRAFT));
              }}
            />
            {user && user?.fund !== "TC07" && (user.category !== CategoryType.ARTISAN  && user.category !== CategoryType.TRADER)?
              <div className="invoice-customer-save-or-duplicate-enasarco my-4">
                <div className={"col d-flex align-items-center"}>
                  <QuickfiscoSwitchInput
                    id={'invoice-sts-save-or-duplicate-social-contribution'}
                    label={lang.SocialContribution}
                    onChange={checked => {
                      dispatch(setStsInvoiceSocialContribution(checked));
                    }}
                    checked={invoice.socialContribution === null ? undefined : invoice.socialContribution}
                    disabled={!invoiceIsEditable}
                  />
                </div>
              </div> : ""
            }
            {user && user.atecos && user.atecos.length > 1 && user.atecos.some(ateco => ateco === invoice.ateco) ?
              (
                <div className={'row mt-4'}>
                  <div className={'col-12'}>
                    <AtecoSelect
                      id={'sts-invoice-ateco-select'}
                      onChange={(_ateco) => dispatch(setStsInvoiceAteco(_ateco))}
                      value={invoice.ateco === null ? undefined : invoice.ateco}
                      type={'own'}
                    />
                  </div>
                </div>
              ) :
              <div className={'row mt-4 mb-0'}>
                <div className={'col-12'}>
                  <QuickfiscoInput
                    id={'invoice-sts-save-ateco'}
                    type={'text'}
                    label={'Codice ateco'}
                    value={invoice.ateco || "-"}
                    disabled={true}
                    styleType={'default'}
                  />
                </div>
              </div>
            }
            <div className={'row mt-4'}>
              <div className={'col'}>
                <QuickfiscoDatePicker
                  id={'invoice-sts-save-date'}
                  label={lang.line2}
                  onChange={e => {
                    e && dispatch(setStsInvoiceDate(resolveUndefinedDate(formatDateUS(e))));
                  }
                  }
                  styleType={'default'}
                  value={new Date(invoice.date)}
                  required={true}
                  disabled={!invoiceIsEditable}
                  error={errorDate}
                  errorLabel={errorDateMessage}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className={'row'}>
              <PaymentModeSelect
                id={'invoice-sts-save-payment-mode'}
                onChange={paymentMode => {
                  dispatch(setStsInvoicePaymentMode(paymentMode));
                  service.validatePaymentMode(paymentMode);
                }}
                value={invoice.paymentMode}
                error={errorPaymentMode}
                disabled={!invoiceIsEditable}
              />
            </div>
            <div className={'mt-2'}>
              <PaymentExpirationInput
                id={'invoice-sts-save-payment-expiration'}
                onChange={e => { e && dispatch(setStsInvoicePaymentExpiration(resolveUndefinedDate(formatDateUS(e)))) }}
                value={invoice.paymentExpiration}
                startDate={invoice.date}
                invoicePaymentTerms={invoice.paymentTerms}
                disabled={true}
                invoiceSign={'sts'}
                error={errorPaymentExpiration}
              />
            </div>
            {user.bankAccount && user.bankAccount.some(bank => bank.name === invoice.supplier?.bankAccount.bankName) ? (
              <div className={'row mt-2'}>
                <QuickfiscoBankSelect
                  id={'invoice-sts-save-bank'}
                  requiredField={true}
                  onChange={(bank) => { dispatch(setStsInvoiceBankAccount({ name: user.bankAccount.find(bankTemp => bankTemp.iban == bank)?.name ?? '', iban: bank })) }}
                  value={
                    operation === "edit" || invoice.supplier?.bankAccount != undefined ?
                      {
                        name: invoice.supplier?.bankAccount.bankName || "",
                        iban: invoice.supplier?.bankAccount.bankIban || ""
                      }
                      :
                      (user.bankAccount.find(bank => bank.default === true) || { name: '', iban: '' })
                  }
                />
              </div>
            ) :
              <div className={'row mt-2'}>
                <QuickfiscoInput
                  id={'invoice-proforma-save-bank'}
                  type={'text'}
                  label={'Banca'}
                  value={`${invoice.supplier?.bankAccount.bankName} - ${invoice.supplier?.bankAccount.bankIban}`}
                  disabled={true}
                  styleType={'default'}
                />
              </div>
            }
            <div className={'row mt-4'}>
              <QuickfiscoTextarea
                id={'invoice-sts-save-notes'}
                label={lang.line8}
                onChange={e => dispatch(setStsInvoiceNotes(e.target.value))}
                value={invoice.notes === null ? '' : invoice.notes}
                placeholder={lang.placeholderNote}
                secondaryLabel={lang.secondaryLabel}
                disabled={!invoiceIsEditable}
                maxLength={330}
              />
            </div>
          </div>
        </div>
        <div className={'row mt-4'}>
          <div className={'col-12'}>
            <InvoicePaymentList
              invoiceSign={'sts'}
              invoice={invoice}
            />
          </div>
        </div>
      </div>
      <div className={'col-12 col-xl-7 mt-4'}>
        <div className={'row no-gutters'}>
          <div className={'col-12 invoice-sts-saveOrEdit-container p-4'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <GoodServicesInput
                  goodServices={invoice.goodServices !== undefined && invoice.goodServices !== null && invoice.goodServices.length > 0 ? invoice.goodServices : [{
                    key: uiidv4(),
                    quantity: 1,
                    amount: 0
                  }]}
                  setGoodServices={goodServices => {
                    dispatch(setStsInvoiceGoodServices(goodServices));
                  }}
                  error={errorGoodServices || errorGoodServicesInput}
                  errorLabel={errorGoodServices ? lang.GoodServiceError : lang.GoodServiceInputError}
                  disabled={!invoiceIsEditable}
                  invoiceSign={'sts'}
                />
              </div>
            </div>
            <div className={'row mt-5'}>
              <div className={'col-12'}>
                <div className={'invoice-sts-saveOrEdit-line'} />
              </div>
            </div>
            <div className={'mt-4 d-flex align-items-center'}>
              <QuickfiscoSwitchInput
                id={'invoice-sts-save-or-duplicate-stamp'}
                disabled={!invoiceIsEditable}
                label={lang.Stamp}
                onChange={checked => dispatch(
                  setStsInvoiceStamp(checked &&
                    invoice.taxBase !== null &&
                    invoice.taxBase !== undefined &&
                    invoice.taxBase > StsInvoiceService.stampActivationThreshold)
                )}
                checked={invoice.stamp === true}
              />
              <div
                className={'invoice-sts-saveOrEdit-title ms-5'}>{formatNumberIT(StsInvoiceService.stampAmount)}€
              </div>
            </div>
            <div className={'row mt-3'}>
              <div className={'col-12'}>
                <div className={'invoice-sts-saveOrEdit-line'} />
              </div>
            </div>
            <div className={'mt-3'}>
              <InvoiceAmount
                invoice={invoice}
                invoiceSign={'sts'}
                stampId={invoice.stampId}
                setStampId={(id) => {
                  if (id.length === 0) {
                    dispatch(setStsStampId(undefined));
                  } else {
                    dispatch(setStsStampId(id));
                  }
                }}
                disabled={!invoiceIsEditable}
                error={errorStampId}
                errorLabel={lang.stampIdError}
              />
            </div>
            <div className={'row mt-5'}>
              <div className={'col-12'}>
                <QuickfiscoInputFile
                  preview={true}
                  multiple={true}
                  maxHeight={'300px'}
                  fileList={fileList}
                  disabled={!invoiceIsEditable}
                  setFileList={_fileList => {
                    dispatch(setStsInvoiceFileList([
                      ..._fileList
                    ]));
                  }}
                  error={errorFiles}
                  errorLabel={lang.filesError}
                />
              </div>
            </div>
            <div className={'row mt-4'}>
              <div className={'col-4'}>
                {
                  status === 'loading' ? (
                    <div className={'w-100 d-flex justify-content-center align-items-center'}>
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={'invoice-sts-save-button'}
                      label={lang.line14}
                      onClick={() => saveOrEdit(
                        invoice,
                        setStatus,
                        {
                          setErrorCustomer,
                          setErrorPaymentMode,
                          setErrorGoodServices,
                          setErrorGoodServicesInput,
                          setErrorExpenseType,
                          setErrorTrackedPayment,
                          setErrorSendData,
                          setErrorDateMessage,
                          setErrorDate,
                          setErrorPaymentExpiration,
                          setErrorEnasarco,
                          setErrorStampId,
                        },
                        operation,
                        dispatch,
                        fileList,
                        setErrorFiles
                      )}
                      type={userIsNotEnabled || !invoiceIsEditable || !user.stsEnabled ? 'disabled' : 'secondary'}
                    />
                  )
                }
              </div>
              <div className={'col-4'}>
                <QuickfiscoButton
                  id={'invoice-sts-pdf-button'}
                  label={lang.line13}
                  type={userIsNotEnabled || operation !== 'edit' || !user.stsEnabled ? 'disabled' : 'primary'}
                  onClick={() => setSeePreviewPdfOpen(true)}
                />
              </div>
              <div className={'col-4'}>
                <QuickfiscoButton
                  id={'invoice-sts-send-button'}
                  label={operation === 'edit' && (invoice.status === InvoiceStatusType.ACCEPTED || invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK || invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED) ? lang.labelVariation : lang.line15}
                  type={userIsNotEnabled || !invoiceIsSendable || operation !== 'edit' || !user.stsEnabled ? 'disabled' : 'primary'}
                  onClick={() => {
                    if (
                      invoice.status === InvoiceStatusType.ACCEPTED ||
                      invoice.status === InvoiceStatusType.COMMUNICATE_CHANGE_NACK ||
                      invoice.status === InvoiceStatusType.REJECTED_BUT_CREATED
                    ) {
                      setCommunicateChangeOpen(true);
                    } else {
                      if (user.status === UserStatusType.CONFIRMED) {
                        setSendTrialInvoiceOpen(true);
                      } else {
                        setSendSectionOpen(true);
                      }
                    }
                  }}
                />
              </div>
            </div>
            {
              operation === 'edit' && invoice.status === InvoiceStatusType.ACCEPTED && user.stsEnabled && (
                <div className={'row mt-4'}>
                  <div className={'col-12'}>
                    {
                      sendEmailStatus === 'loading' ? (
                        <div className={'d-flex align-items-center justify-content-center'}>
                          <QuickfiscoSpinner />
                        </div>
                      ) : (
                        <QuickfiscoButton
                          id={'invoice-sts-send-customer-email'}
                          label={lang.labelSendCustomer}
                          type={'secondary'}
                          onClick={() => sendEmail(setSendEmailStatus, invoice.id)}
                        />
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <SeePreviewPdfModal
        open={seePreviewPdfOpen}
        setOpen={(open: boolean) => setSeePreviewPdfOpen(open)}
        invoiceSign={'sts'}
        onClick={() => {
          getPdf(
            setDownloadPdfStatus,
            invoice.id,
            invoice.number,
            invoice.customer?.companyName
          )
        }}
        downloadPdfStatus={downloadPdfStatus}
      />
      <SendStsInvoice
        open={sendSectionOpen}
        setOpen={(open: boolean) => setSendSectionOpen(open)}
        invoiceId={invoice.id !== undefined ? invoice.id : ''}
      />
      <SendTrialInvoiceModal
        open={sendTrialInvoiceOpen}
        setOpen={(open: boolean) => setSendTrialInvoiceOpen(open)}
        type={'sts'}
      />
      <CommunicateStsChange
        open={communicateChangeOpen}
        setOpen={(_open) => setCommunicateChangeOpen(_open)}
        invoiceId={invoice.id !== undefined ? invoice.id : ''}
        edit={() => edit(
          {
            setErrorCustomer,
            setErrorPaymentMode,
            setErrorGoodServices,
            setErrorGoodServicesInput,
            setErrorExpenseType,
            setErrorTrackedPayment,
            setErrorSendData,
            setErrorDate,
            setErrorPaymentExpiration,
            setErrorDateMessage,
            setErrorStampId,
          },
          invoice,
          fileList
        )}
      />
    </div>
  );
}

function saveOrEdit(
  invoice: InvoiceModel,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters,
  operation: Operation,
  dispatch: Function,
  fileList: File[],
  setErrorFiles: SetState<boolean>
): void {
  setErrorFiles(false);

  const service = new StsInvoiceService(errorSetters);

  if (!service.validate(invoice, 'sts')) {
    return;
  }

  if (!validateFiles(fileList)) {
    setErrorFiles(true);
    return;
  }

  if (operation === 'duplicate' || operation === 'save') {
    setStatus('loading');
    service
      .save({ ...invoice }, [...fileList], [])
      .then(id => {
        dispatch(setStsInvoiceSaveOrDuplicateOrEditInvoiceId(id));
        dispatch(setStsInvoiceSaveOrDuplicateOrEditOperation('edit'));
        dispatch(setStsInvoiceSaveOrDuplicateOrEditSuccessfullySaved(true));
      })
      .catch(err => handleSaveOrEditError(err, setStatus, errorSetters));
  } else {
    dispatch(editStsInvoice({ invoice: { ...invoice }, fileList: [...fileList], errorSetters }))
    setStatus('successfully')
  }
}

function handleSaveOrEditError(
  err: any,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: InvoiceErrorSetters,
): void {
  err
    .json()
    .then((data: any) => {
      if (data.message === 'error.invalid-date') {
        errorSetters.setErrorDateMessage && errorSetters.setErrorDateMessage('Esiste già una fattura con data successiva ma numero inferiore.');
        errorSetters.setErrorDate && errorSetters.setErrorDate(true);
      }
    });

  setStatus('failed');

  console.error(err);
}

function edit(
  errorSetters: InvoiceErrorSetters,
  invoice: InvoiceModel,
  fileList: File[]
): Promise<void> {
  const service = new StsInvoiceService(errorSetters);

  return service.edit({ ...invoice }, [...fileList], []);
}

function getPdf(
  setStatus: SetState<PromiseStatuses>,
  invoiceId?: string,
  invoiceNumber?: string,
  invoiceCompanyName?: string
): void {
  if (!invoiceId) {
    return;
  }

  const service = new StsInvoiceService();

  setStatus('loading');
  service
    .getPdf(invoiceId)
    .then(data => {
      setStatus('successfully');
      downloadPdf(StsInvoiceService.getPdfName('sts', undefined, invoiceNumber, invoiceCompanyName), data, 'application/pdf');
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}

function sendEmail(
  setStatus: SetState<PromiseStatuses>,
  id?: string,
) {
  if (!id) {
    return;
  }

  const service = new StsInvoiceService();

  setStatus('loading');
  service
    .sendEmail(id)
    .then(() => setStatus('successfully'))
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}
