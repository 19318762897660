import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { UserCategory } from '../incomeUploadModal/incomeUploadModal';
import lang from './incomeUploadModalIntro.json';
import './incomeUploadModalIntro.css';

interface Props {
  onClickPrevStep: () => void;
  onClickNextStep: () => void;
  userCategory: UserCategory;
  disabledCloseModal: boolean;
}

export function IncomeUploadModalIntro(props: Props) {
  const { onClickPrevStep } = props;
  const { onClickNextStep } = props;
  const { userCategory } = props;
  const { disabledCloseModal } = props;

  const user = useAppSelector((state) => state.user.user);

  return (
    <>
      <div className={'row'}>
        <div className={'col-12'}>
          <div className={'income-upload-modal-intro-text'}>
            Ciao&nbsp;<b>{user.name}</b>,<br />
            iniziamo a lavorare sulla tua Dichiarazione dei Redditi 2024
            <br />
            sui redditi prodotti nel 2023.
            <br />
            <br />
            Il primo passo è acquisire il dato finale delle tue fatture
            incassate nel 2023.
            <br />
            Per fare correttamente questo passaggio <b>abbiamo bisogno</b>
            <br />
            <b>
              della tua collaborazione, <u>bastano pochi minuti!</u>
            </b>
            <br />
            <br />
            <span className={'income-upload-modal-intro-text-blue'}>
              Cosa devi fare? Nulla di complicato!
            </span>
            <br />
            Ti spieghiamo tutto in un video di pochi minuti per renderti tutto
            più chiaro.
            <br />
            <br />
            <iframe
              width="560"
              height="315"
              src={
                userCategory === UserCategory.TRADER479119
                  ? 'https://www.youtube.com/embed/GgZSkYYWzs4?si=HhQVtfTIFuAwkMqQ'
                  : userCategory === UserCategory.TRADER
                  ? 'https://www.youtube.com/embed/LdQfW59pds4?si=1p6v0D1x_bfnN8f2'
                  : 'https://www.youtube.com/embed/Jkotd00yi24?si=6puKCqd97GQGTHcA'
              }
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <br />
            <br />
            Ti chiediamo di completare questo passaggio{' '}
            <b>il prima possibile</b> per poter
            <br />
            <span className={'income-upload-modal-intro-text-blue'}>
              iniziare a lavorare alla tua Dichiarazione dei Redditi.
            </span>
            <br />
            <br />
            Ricordati che le tue tasse e i tuoi contributi verranno calcolati in
            base <br />
            <b>a ciò</b>
            <b> che hai incassato nel 2023</b> non sul tuo fatturato!
            <br />
            Se hai dei dubbi contattaci.
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className={'row my-3'}>
        <div className={'col-6 d-flex justify-content-center'}>
          {!disabledCloseModal && (
            <QuickfiscoButton
              id={'income-upload-modal-intro-button'}
              label={lang.step1ButtonPrev}
              type={'primary'}
              onClick={() => onClickPrevStep()}
            />
          )}
        </div>
        <div className={'col-4 offset-2 d-flex justify-content-center'}>
          <QuickfiscoButton
            id={'income-upload-modal-intro-button'}
            label={lang.step1buttonNext}
            type={'senary'}
            onClick={() => onClickNextStep()}
          />
        </div>
      </div>
    </>
  );
}
