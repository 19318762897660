import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { InvoiceList } from '../../components/invoiceList/invoiceList';
import { InvoiceReportList } from '../../components/invoiceReportList/invoiceReportList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getStsInvoiceList, setStsInvoiceListPage } from '../../redux/slices/stsInvoiceListSlice';
import { InvoiceFilters } from '../../components/invoiceFilters/invoiceFilters';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './stsHistory.json';

export function StsHistory() {
  const dispatch = useAppDispatch()

  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)
  const userState = useAppSelector((state) => state.user.user);

  const { collapsed } = useProSidebar();

  const stsInvoices = stsInvoiceListState.invoices

  useEffect(() => {
    dispatch(getStsInvoiceList({
      page: stsInvoiceListState.page,
      companyName: stsInvoiceListState.companyNameFilter,
      stsStatus: stsInvoiceListState.statusFilter,
      year: stsInvoiceListState.yearFilter,
      month: stsInvoiceListState.monthFilter
    }))
  }, [
    stsInvoiceListState.page,
    stsInvoiceListState.companyNameFilter,
    stsInvoiceListState.statusFilter,
    stsInvoiceListState.yearFilter,
    stsInvoiceListState.monthFilter
  ])

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='sts-history-menu'
            menuItem={MenuItems.STS_HISTORY}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'row'}>
                <div className={'col'}>
                  <QuickfiscoHeader title={lang.title} />
                  {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
                </div>
              </div>
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <InvoiceReportList invoiceSign={'sts'} />
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className={'col-12'}>
                  <InvoiceFilters invoiceSign={'sts'} />
                </div>
              </div>
              <div className={'row mt-4'}>
                <div className={'col-12'}>
                  <InvoiceList invoiceSign={'sts'} />
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className={'col-12'}>
                  <div className={'d-flex justify-content-center'}>
                    <QuickfiscoPagination
                      totalPages={stsInvoices.totalPages}
                      number={stsInvoices.number}
                      empty={stsInvoices.empty}
                      doPagination={(page: number) => dispatch(setStsInvoiceListPage(page))}
                      first={stsInvoices.first}
                      last={stsInvoices.last}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
