import { ChangePasswordModel } from '../../models/changePasswordModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  changePassword,
  setChangePasswordStatus,
  setConfirmPassword,
  setErrorConfirmPassword,
  setErrorNewPassword,
  setErrorOldPassword,
  setNewPassword,
  setOldPassword,
} from '../../redux/slices/userSlice';
import { UserService } from '../../services/userService';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import './profilePassword.css';
import lang from './profilePassword.json';

export function ProfilePassword() {
  const userState = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  if (!userState.user) {
    return <></>;
  }

  if (userState.changePasswordStatus === 'loading') {
    return (
      <div
        className={
          'profilePassword-box box-shadow d-flex justify-content-center align-items-center w-100'
        }
      >
        <QuickfiscoSpinner />
      </div>
    );
  }

  return (
    <div className="profilePassword-box box-shadow">
      <QuickfiscoSuccess
        message={lang.SuccessMessage}
        active={userState.changePasswordStatus === 'successfully'}
        close={() => dispatch(setChangePasswordStatus('idle'))}
      />
      <QuickfiscoError
        active={userState.changePasswordStatus === 'failed'}
        message={lang.ErrorMessage}
        close={() => dispatch(setChangePasswordStatus('idle'))}
      />
      <div className="row">
        <div className="col-12">
          <div className="profilePassword-box-title">{lang.Title}</div>
          <div className="row mt-3">
            <div className="col-12">
              <QuickfiscoInput
                id={'profile-old-password'}
                type={'password'}
                styleType={'default'}
                label={lang.OldPasswordLabel}
                placeholder={lang.OldPasswordPlaceholder}
                onChange={(e) => dispatch(setOldPassword(e.target.value))}
                required={true}
                defaultValue={userState.changePasswordRequest.oldPassword}
                error={userState.errorOldPassword}
                errorLabel={lang.ErrorOldPassword}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <QuickfiscoInput
                id={'profile-new-password'}
                type={'password'}
                styleType={'default'}
                label={lang.NewPasswordLabel}
                placeholder={lang.NewPasswordPlaceholder}
                error={userState.errorNewPassword}
                errorLabel={lang.ErrorNewPassword}
                onChange={(e) => dispatch(setNewPassword(e.target.value))}
                required={true}
                defaultValue={userState.changePasswordRequest.newPassword}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <QuickfiscoInput
                id={'profile-confirm-password'}
                type={'password'}
                styleType={'default'}
                label={lang.ConfirmPasswordLabel}
                placeholder={lang.ConfirmPasswordPlaceholder}
                error={userState.errorConfirmPassword}
                errorLabel={lang.ErrorConfirmPassword}
                onChange={(e) => dispatch(setConfirmPassword(e.target.value))}
                required={true}
                defaultValue={userState.changePasswordRequest.confirmPassword}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <p className="profilePassword-box-warn">{lang.txt}</p>
            </div>
          </div>
          <div className={'row mt-1'}>
            <div className={'col-12'}>
              <QuickfiscoButton
                id={'profile-password'}
                type={'primary'}
                label={lang.Label}
                onClick={() =>
                  change(
                    {
                      oldPassword: userState.changePasswordRequest.oldPassword,
                      newPassword: userState.changePasswordRequest.newPassword,
                      confirmPassword:
                        userState.changePasswordRequest.confirmPassword,
                    },
                    dispatch
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function change(request: ChangePasswordModel, dispatch: Function): void {
  dispatch(setErrorNewPassword(false));
  dispatch(setErrorOldPassword(false));
  dispatch(setErrorConfirmPassword(false));

  const userService = new UserService();

  if (!userService.validatePassword(request.newPassword)) {
    dispatch(setErrorNewPassword(true));
    return;
  }

  if (
    !userService.validateConfirmPassword(
      request.newPassword,
      request.confirmPassword
    )
  ) {
    dispatch(setErrorConfirmPassword(true));
    return;
  }

  dispatch(changePassword(request));
}
