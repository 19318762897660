import { useEffect } from 'react';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSpinner } from '../../components/quickfiscoSpinner/quickfiscoSpinner';
import { ReadOnlyNotice } from '../../components/readOnlyNotice/readOnlyNotice';
import { SupplierInvoiceSave as SupplierInvoiceSaveComponent } from '../../components/supplierInvoiceSave/supplierInvoiceSave';
import { InvoiceStatusType } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { findSupplierInvoiceById } from '../../redux/slices/supplierInvoiceSlice';
import { useProSidebar } from 'react-pro-sidebar';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './supplierInvoiceSave.json';

export function SupplierInvoiceSave() {
  const dispatch = useAppDispatch()

  const supplierInvoiceState = useAppSelector(state => state.supplierInvoice);
  const supplierInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.supplierInvoiceSaveOrEdit);
  const userState = useAppSelector((state) => state.user.user);

  const { collapsed } = useProSidebar();

  const supplierInvoice = supplierInvoiceState.invoice
  const invoiceId = supplierInvoiceSaveOrDuplicateOrEditState.invoiceId
  const operation = supplierInvoiceSaveOrDuplicateOrEditState.operation

  useEffect(() => {
    if (invoiceId !== undefined && operation === 'edit') {
      dispatch(findSupplierInvoiceById(invoiceId))
    }
  }, [invoiceId, operation]);

  if (supplierInvoiceState.findByIdStatus === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (supplierInvoiceState.findByIdStatus === 'failed') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        Errore durante il caricamento della fattura
      </div>
    );
  }

  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='supplier-invoice-save-menu'
            menuItem={MenuItems.SUPPLIER_INVOICE}
          />
        </div>
        <div className='col p-4 ps-5' style={{ marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out' }}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
              {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
              {
                (
                  supplierInvoice.status === InvoiceStatusType.PAID ||
                  !supplierInvoice.manual
                ) && (
                  <div className={'row mt-4'}>
                    <div className={'col-12'}>
                      <ReadOnlyNotice />
                    </div>
                  </div>
                )
              }
          <div className={'row'}>
            <div className={'col-12'}>
              <SupplierInvoiceSaveComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
