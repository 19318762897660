import { useEffect } from 'react';
import { InvoiceFilters } from '../../components/invoiceFilters/invoiceFilters';
import { InvoiceList } from '../../components/invoiceList/invoiceList';
import { InvoiceReportList } from '../../components/invoiceReportList/invoiceReportList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSupplierInvoiceList, setSupplierInvoiceListPage } from '../../redux/slices/supplierInvoiceListSlice';
import { useProSidebar } from 'react-pro-sidebar';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './supplierInvoiceList.json';

export function SupplierInvoiceList() {
  const dispatch = useAppDispatch()
  const newInvoice = useNewInvoice('supplier')

  const supplierInvoiceListState = useAppSelector(state => state.supplierInvoiceList)
  const userState = useAppSelector((state) => state.user.user);

  const { collapsed } = useProSidebar();

  const supplierInvoices = supplierInvoiceListState.invoices

  useEffect(() => {
    if (supplierInvoiceListState.page === undefined) {
      dispatch(setSupplierInvoiceListPage(0))
    }
  }, [])

  useEffect(() => {
    dispatch(getSupplierInvoiceList({
      page: supplierInvoiceListState.page,
      month: supplierInvoiceListState.monthFilter,
      year: supplierInvoiceListState.yearFilter,
      companyName: supplierInvoiceListState.companyNameFilter,
      status: supplierInvoiceListState.statusFilter
    }))
  }, [
    supplierInvoiceListState.page,
    supplierInvoiceListState.monthFilter,
    supplierInvoiceListState.yearFilter,
    supplierInvoiceListState.companyNameFilter,
    supplierInvoiceListState.statusFilter
  ])

  useEffect(() => {
    if (supplierInvoiceListState.page !== undefined && supplierInvoiceListState.page > 0 && supplierInvoiceListState.invoices.content.length === 0) {
      dispatch(setSupplierInvoiceListPage(supplierInvoiceListState.page - 1))
    }
  }, [supplierInvoiceListState.invoices.content])

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='supplier-invoice-list-menu'
            menuItem={MenuItems.SUPPLIER_INVOICE}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.title} />
              {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <InvoiceReportList invoiceSign={'supplier'} />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-11'}>
              <InvoiceFilters invoiceSign={'supplier'} />
            </div>
            <div className={'col-1 d-flex justify-content-end'}>
              <QuickfiscoIcon
                name={'new.svg'}
                className={'w-f-35'}
                isButton={true}
                onClick={() => newInvoice()}
              />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <InvoiceList invoiceSign={'supplier'} />
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <div className={'d-flex justify-content-center'}>
                <QuickfiscoPagination
                  totalPages={supplierInvoices.totalPages}
                  number={supplierInvoices.number}
                  empty={supplierInvoices.empty}
                  doPagination={(page: number) => dispatch(setSupplierInvoiceListPage(page))}
                  first={supplierInvoices.first}
                  last={supplierInvoices.last}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
