import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setProfileNotSavedModalIsOpen } from '../../redux/slices/userSlice';
import { UserService } from '../../services/userService';
import './createHeaderDropdownMenu.css';
import lang from './createHeaderDropdownMenu.json';

export function CreateHeaderDropdownMenu() {
  const [open, setOpen] = useState(false);

  const userState = useAppSelector((state) => state.user);
  const user = userState.user;
  const editUserRequest = userState.editUserRequest;

  const dispatch = useAppDispatch();

  const editingMe = UserService.editingMe(user, editUserRequest, user.status !== 4);

  const newInvoiceCustomerSts = useNewInvoice(
    editUserRequest.stsEnabled ? 'sts' : 'customer'
  );
  const newCreditSlip = useNewInvoice('creditSlip');
  const newInvoiceSupplier = useNewInvoice('supplier');
  const newInvoiceProforma = useNewInvoice('proforma');
  const newInvoiceAutoinvoice = useNewInvoice('auto');
  const newOccasionalJob = useNewInvoice('occasionalJob');

  return (
    <div className={'create-header-dropdown-menu-container d-none d-xxl-flex'}>
      <Dropdown drop={'down-centered'} onToggle={(isOpen) => setOpen(isOpen)}>
        <Dropdown.Toggle
          id="dropdown-create"
          className={
            open
              ? 'create-header-dropdown-menu-open'
              : 'create-header-dropdown-menu-close'
          }
        >
          <div className={'d-flex align-items-center'}>
            {lang.labelButton}{' '}
            <span className="create-header-dropdown-menu-plus">+</span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={'create-header-dropdown-menu'}>
          <ul className={'create-header-dropdown-menu-list'}>
            <li
              className={'create-header-dropdown-menu-item'}
              onClick={() => {
                if (editingMe) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  newInvoiceCustomerSts();
                }
              }}
            >
              {lang.line1}
            </li>
            <li
              className={'create-header-dropdown-menu-item'}
              onClick={() => {
                if (editingMe) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  newCreditSlip();
                }
              }}
            >
              {lang.line2}
            </li>
            <li
              className={'create-header-dropdown-menu-item'}
              onClick={() => {
                if (editingMe) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  newInvoiceSupplier();
                }
              }}
            >
              {lang.line3}
            </li>
            <li
              className={'create-header-dropdown-menu-item'}
              onClick={() => {
                if (editingMe) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  newInvoiceProforma();
                }
              }}
            >
              {lang.line4}
            </li>
            <li
              className={'create-header-dropdown-menu-item'}
              onClick={() => {
                if (editingMe) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  newInvoiceAutoinvoice();
                }
              }}
            >
              {lang.line5}
            </li>
            <li
              className={'create-header-dropdown-menu-item'}
              onClick={() => {
                if (editingMe) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  newOccasionalJob();
                }
              }}
            >
              {lang.line6}
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
