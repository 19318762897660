import { useState } from 'react';
import config from '../../../config.json';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { ProgressBar } from '../progressBar/progressBar';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { ValidationFormAnswers } from '../../models/validationForm';
import { useAppDispatch } from '../../redux/hooks';
import { getMe } from '../../redux/slices/userSlice';
import './validationFormModal.css';
import lang from './validationFormModal.json';

interface Props {
  open: boolean;
  setOpen: Function;
  validationFormStatus?: number;
}

export function ValidationFormModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { validationFormStatus } = props;

  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [stepIndex, setStepIndex] = useState(0);
  const [validationFormAnswers, setValidationFormAnswers] =
    useState<ValidationFormAnswers>({
      step1: undefined,
      step2: undefined,
      step3: undefined,
      step4: undefined,
    });

  let positionStep: string = 'step1';
  switch (stepIndex) {
    case 0:
      positionStep = 'step1';
      break;
    case 1:
      positionStep = 'step2';
      break;
    case 2:
      positionStep = 'step3';
      break;
    case 3:
      positionStep = 'step4';
      break;
  }

  const allFormAnswerTrue = Object.values(validationFormAnswers).every(
    (step) => step === true
  );

  const mailInfo = (
    <div className={'row d-flex justify-content-center pt-5 mb-3'}>
      <div className={'col-8'}>
        <span
          className={
            'validation-form-modal-last-step-info-mail d-flex justify-content-center'
          }
        >
          {lang.infoMail}
          <a
            target={'_blank'}
            href={`mailto:${config.mail}`}
            rel={'noreferrer'}
            className="ms-1"
          >
            {lang.mail}
          </a>
        </span>
      </div>
    </div>
  );

  const validationFormPassed: React.ReactNode = (
    <div className={'row'}>
      <div className={'col-12'}>
        <div className={'row mt-4'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <QuickfiscoIcon name={'rock.svg'} />
          </div>
        </div>
        <div className={'row d-flex justify-content-center mt-5'}>
          <div className={'col-9'}>
            <span
              className={
                'validation-form-modal-last-step-title d-flex justify-content-center'
              }
            >
              {lang.validationFormPassed.title}
            </span>
          </div>
        </div>
        <div className={'row d-flex justify-content-center mt-4'}>
          <div className={'col-6'}>
            <span
              className={
                'validation-form-modal-last-step-subtitle d-flex justify-content-center'
              }
            >
              {lang.validationFormPassed.subTitle}
            </span>
          </div>
        </div>
        <div className={'row d-flex justify-content-center my-3'}>
          <div className={'col-4 mt-3'}>
            <QuickfiscoButton
              id={'validation-form-modal-button'}
              label={lang.validationFormPassed.buttonStart}
              type={'secondary'}
              onClick={() => {
                dispatch(getMe());
                setOpen(false);
              }}
            />
          </div>
        </div>
        {mailInfo}
      </div>
    </div>
  );

  const validationFormFailed: React.ReactNode = (
    <div className={'row'}>
      <div className={'col-12'}>
        <div className={'row mt-4'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <QuickfiscoIcon name={'validation-form-failed.svg'} />
          </div>
        </div>
        <div className={'row d-flex justify-content-center mt-5'}>
          <div className={'col-9'}>
            <span
              className={
                'validation-form-modal-last-step-title d-flex justify-content-center'
              }
            >
              {lang.validationFormFailed.title}
            </span>
          </div>
        </div>
        <div className={'row d-flex justify-content-center mt-4'}>
          <div className={'col-8'}>
            <span
              className={
                'validation-form-modal-last-step-subtitle d-flex justify-content-center'
              }
            >
              {lang.validationFormFailed.subTitle}
            </span>
          </div>
        </div>
        <div className={'row d-flex justify-content-center my-4'}>
          <div className={'col-7'}>
            <span
              className={
                'validation-form-modal-last-step-blue-text d-flex justify-content-center'
              }
            >
              {lang.validationFormFailed.blueText}
            </span>
          </div>
        </div>
        {mailInfo}
      </div>
    </div>
  );

  return (
    <QuickfiscoModal
      hide={() => setOpen(false)}
      isOpen={open}
      closeButton={true}
      backdrop="static"
      keyboard={false}
    >
      <QuickfiscoError
        message={lang.errorStatus}
        active={status === 'failed'}
        close={() => setStatus('idle')}
      />
      <QuickfiscoSuccess
        message={lang.successStatus}
        active={status === 'successfully'}
        close={() => setStatus('idle')}
      />
      {validationFormStatus === -1 ? (
        <>{validationFormFailed}</>
      ) : (
        <div className={'row'}>
          <div className={'col-12'}>
            {stepIndex <= 3 ? (
              <>
                <div className={'row'}>
                  <div className={'col-12'}>
                    <span
                      className={
                        'validation-form-modal-title d-flex justify-content-center'
                      }
                    >
                      {lang.title} {new Date().getFullYear()}
                    </span>
                    <span
                      className={
                        'validation-form-modal-sub-title d-flex justify-content-center'
                      }
                    >
                      {lang.subTitle}
                    </span>
                  </div>
                </div>
                <div className={'row my-4'}>
                  <div className={'col-6 offset-3'}>
                    <ProgressBar
                      numberOfSteps={4}
                      currentStep={stepIndex + 1}
                      noText={true}
                    />
                  </div>
                </div>
                <div className={'row'}>
                  <div
                    className={'col-10 offset-1 d-flex justify-content-center'}
                  >
                    <span className="validation-form-modal-question">
                      {lang.questions[stepIndex].question}
                    </span>
                  </div>
                  <div
                    className={
                      'col-8 offset-2 mt-5 mb-4 d-flex justify-content-center validation-form-modal-checkbox'
                    }
                  >
                    <QuickfiscoCheckbox
                      id={`validation-form-modal-answer-yes-${stepIndex}`}
                      label={lang.questions[stepIndex].answerYes}
                      checked={
                        validationFormAnswers[
                          positionStep as keyof ValidationFormAnswers
                        ] === true
                      }
                      onChange={(e) =>
                        setValidationFormAnswers({
                          ...validationFormAnswers,
                          [positionStep as keyof ValidationFormAnswers]: true,
                        })
                      }
                    />
                  </div>
                  <div
                    className={
                      'col-8 offset-2 mb-4 d-flex justify-content-center validation-form-modal-checkbox'
                    }
                  >
                    <QuickfiscoCheckbox
                      id={`validation-form-modal-answer-yes-${stepIndex}`}
                      label={lang.questions[stepIndex].answerNo}
                      checked={
                        validationFormAnswers[
                          positionStep as keyof ValidationFormAnswers
                        ] === false
                      }
                      onChange={(e) =>
                        setValidationFormAnswers({
                          ...validationFormAnswers,
                          [positionStep as keyof ValidationFormAnswers]: false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={'row mb-3 mt-4'}>
                  <div
                    className={'col-4 offset-1 d-flex justify-content-center'}
                  >
                    {stepIndex > 0 && (
                      <QuickfiscoButton
                        id={'validation-form-modal-button'}
                        label={lang.buttonPrev}
                        type={'primary'}
                        onClick={() => setStepIndex(stepIndex - 1)}
                      />
                    )}
                  </div>
                  <div
                    className={'col-4 offset-2 d-flex justify-content-center'}
                  >
                    {status === 'loading' ? (
                      <div className={'d-flex justify-content-center'}>
                        <QuickfiscoSpinner />
                      </div>
                    ) : (
                      <QuickfiscoButton
                        id={'validation-form-modal-button'}
                        label={lang.buttonNext}
                        type={
                          validationFormAnswers[
                            positionStep as keyof ValidationFormAnswers
                          ] !== undefined
                            ? 'secondary'
                            : 'disabled'
                        }
                        onClick={() => {
                          if (stepIndex === 3) {
                            sendValidationFormAnswers(
                              validationFormAnswers,
                              setStatus,
                              stepIndex,
                              setStepIndex
                            );
                          } else {
                            setStepIndex(stepIndex + 1);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className={'row mt-3'}>
                <div className={'col-12 d-flex justify-content-center'}>
                  {allFormAnswerTrue ? (
                    <>{validationFormPassed}</>
                  ) : (
                    <>{validationFormFailed}</>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </QuickfiscoModal>
  );
}

function sendValidationFormAnswers(
  validationFormAnswers: ValidationFormAnswers,
  setStatus: SetState<PromiseStatuses>,
  stepIndex: number,
  setStepIndex: SetState<number>
) {
  setStatus('loading');

  const service = new UserService();

  service
    .sendValidationForm(validationFormAnswers)
    .then(() => {
      setStatus('successfully');
      setStepIndex(stepIndex + 1);
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
