import { ReactNode } from 'react';
import './quickfiscoLink.css';

interface Props {
  id: string,
  label?: string,
  onClick?: Function
  icon?: ReactNode
}

export function QuickfiscoLink(props: Props) {
  const { id } = props;
  const { label } = props;
  const { onClick } = props;
  const { icon } = props;

  return (
    <span
      id={id}
      className={`quickfisco-link ${icon && 'ps-3'}`}
      onClick={() => onClick && onClick()}>{icon ? icon : label}</span>
  );
}
