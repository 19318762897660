import { useState } from "react";
import config from "../../../config.json";
import { InvoiceImportModel } from "../../models/invoiceImportModel";
import { InvoiceModel, InvoiceType } from "../../models/invoiceModel";
import { InvoicePaymentModel } from "../../models/invoicePaymentModel";
import { useAppSelector } from "../../redux/hooks";
import { CustomerInvoiceService } from "../../services/customerInvoiceService";
import { ImportInvoiceService } from "../../services/importInvoiceService";
import { PromiseStatuses } from "../../types/strings";
import { formatDateIT } from "../../utils/date";
import { formatNumberIT } from "../../utils/number";
import { AtecoSelect } from "../atecoSelect/atecoSelect";
import { InvoicePaymentStatus } from "../invoicePaymentStatus/invoicePaymentStatus";
import { PaymentStatusInfo } from "../paymentStatusInfo/paymentStatusInfo";
import { QuickfiscoError } from "../quickfiscoError/quickfiscoError";
import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";
import { QuickfiscoSpinner } from "../quickfiscoSpinner/quickfiscoSpinner";
import { QuickfiscoSuccess } from "../quickfiscoSuccess/quickfiscoSuccess";
import { QuickfiscoYesNoSelect } from "../quickfiscoYesNoSelect/quickfiscoYesNoSelect";
import "./invoiceImportListRow.css";
import lang from "./invoiceImportListRow.json";
import { SetState } from "../../types/functions";

interface Props {
  invoice: InvoiceModel;
  index: number;
  updatePaginationInvoices: (
    index: number,
    updates: InvoiceImportModel
  ) => void;
  openInvoiceModal: () => void;
  type: "customer" | "sts";
  setStatusSaving: SetState<PromiseStatuses>;
}

export function InvoiceImportListRow(props: Props) {
  const { invoice } = props;
  const { index } = props;
  const { updatePaginationInvoices } = props;
  const { openInvoiceModal } = props;
  const { type } = props;
  const { setStatusSaving } = props;

  const [status, setStatus] = useState<PromiseStatuses>("idle");
  const [ateco, setAteco] = useState<string | undefined>(
    invoice.ateco === null ? undefined : invoice.ateco
  );
  const [socialContribution, setSocialContribution] = useState<
    boolean | undefined
  >(
    invoice.socialContribution === null ? undefined : invoice.socialContribution
  );

  const user = useAppSelector((state) => state.user.user);

  const totalAmount = CustomerInvoiceService.calculateTotalAmount(
    invoice,
    user,
    type
  );

  let partialAmount: number = 0;
  if (invoice.payments !== undefined && invoice.payments !== null) {
    invoice.payments.forEach((payment) => {
      partialAmount = partialAmount + payment.amount;
    });
  }

  const editInvoice = (
    ateco?: string,
    payments?: InvoicePaymentModel[],
    socialContribution?: boolean
  ) => {
    if (invoice.id) {
      const service = new ImportInvoiceService();

      if (type === "sts") {
        setStatus("loading");
        service
          .editStsInvoice(invoice.id, {
            ateco,
            socialContribution,
          })
          .then(() => {
            setStatus("successfully");
            setStatusSaving("successfully");
            updatePaginationInvoices(index, { ateco });
          })
          .catch((err) => {
            setStatus("failed");
            console.error(err);
          });
      } else {
        setStatus("loading");
        service
          .editCustomerInvoice(invoice.id, {
            ateco,
            payments,
          })
          .then(() => {
            setStatus("successfully");
            updatePaginationInvoices(index, { ateco });
          })
          .catch((err) => {
            setStatus("failed");
            console.error(err);
          });
      }
    }
  };

  return (
    <>
      <QuickfiscoError
        message={lang.savingError}
        active={status === "failed"}
        close={() => setStatus("idle")}
      />
      <QuickfiscoSuccess
        message={lang.editSuccess}
        active={status === "successfully"}
        close={() => setStatus("idle")}
      />
      <div className={"row no-gutters align-items-center"}>
        <div className={"col invoiceImportListRow-flex-05"}>
          <div className={"invoiceImportListRow-header"}>{lang.nr}</div>
          <div className={"invoiceImportListRow-text "}>{invoice.number}</div>
        </div>
        <div className={"col invoiceImportListRow-flex-2"}>
          <div className={"invoiceImportListRow-header"}>{lang.client}</div>
          <div className={"invoiceImportListRow-text "}>
            {type === "sts" ? "Cliente Anonimo" : invoice.customer?.companyName}
          </div>
        </div>
        <div className={"col invoiceImportListRow-flex-1"}>
          <div className={"invoiceImportListRow-header"}>{lang.amount}</div>
          <div className={"invoiceImportListRow-text "}>
            {formatNumberIT(totalAmount) + config.EUR}
          </div>
        </div>
        {type === "sts" && user.fund !== "TC22" && (
          <div className={"col invoiceImportListRow-flex-1 mb-2"}>
            <div className={"invoiceImportListRow-header-no-m"}>
              {status === "loading" ? (
                <div
                  className={
                    "d-flex justify-content-center align-items-center w-100"
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <div className="invoiceImportListRow-ateco">
                  <QuickfiscoYesNoSelect
                    id="invoice-import-yes-no-select"
                    label={lang.socialContribution}
                    onChange={(_socialContribution) => {
                      editInvoice(ateco, undefined, _socialContribution);
                      setSocialContribution(_socialContribution);
                    }}
                    value={socialContribution}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {type !== "sts" && (
          <div className={"col invoiceImportListRow-flex-1"}>
            <div className={"invoiceImportListRow-header"}>{lang.type}</div>
            <div className={"invoiceImportListRow-text "}>
              {invoice.type === InvoiceType.TD04
                ? lang.creditSlip
                : lang.invoice}
            </div>
          </div>
        )}
        <div
          className={`col invoiceImportListRow-flex-1 ${
            type === "sts" && user.fund !== "TC22"
              ? "invoiceImportListRow-margin-date"
              : ""
          }`}
        >
          {" "}
          <div className={"invoiceImportListRow-header"}>{lang.date}</div>
          <div className={"invoiceImportListRow-text "}>
            {invoice.date && formatDateIT(invoice.date)}
          </div>
        </div>
        <div className={"col invoiceImportListRow-flex-4 mb-2"}>
          <div className={"invoiceImportListRow-header-no-m"}>
            <div className={"invoiceImportListRow-text"}>
              {status === "loading" ? (
                <div
                  className={
                    "d-flex justify-content-center align-items-center w-100"
                  }
                >
                  <QuickfiscoSpinner />
                </div>
              ) : (
                <div className="invoiceImportListRow-ateco w-100">
                  <AtecoSelect
                    id={"invoiceImportListRow-ateco-select"}
                    label={lang.ateco}
                    onChange={(_ateco) => {
                      editInvoice(_ateco);
                      setAteco(_ateco);
                    }}
                    value={ateco}
                    type={"own"}
                    requiredField={true}
                    toolTip={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {type === "customer" && invoice.type !== InvoiceType.TD04 ? (
          <>
            <div className={"col invoiceImportListRow-flex-1"}>
              <div
                className={
                  "invoiceImportListRow-header d-flex align-items-center mb-2"
                }
              >
                {lang.paymentStatus}
                <div className="ms-2 mb-1">
                  <PaymentStatusInfo />
                </div>
              </div>
              <div className={"invoiceImportListRow-text "}>
                <InvoicePaymentStatus
                  status={invoice.status}
                  partialPaid={
                    partialAmount !== totalAmount && partialAmount !== 0
                  }
                />
              </div>
            </div>
            <div className={"col-auto invoiceImportListRow-open-modal"}>
              <div onClick={() => openInvoiceModal()}>
                <QuickfiscoIcon name={"edit-invoice.svg"} />
              </div>
            </div>
          </>
        ) : type === "sts" ? (
          <div className={"col-auto invoiceImportListRow-open-modal"}>
            <div onClick={() => openInvoiceModal()}>
              <QuickfiscoIcon name={"edit-invoice.svg"} />
            </div>
          </div>
        ) : (
          <>
            <div className={"col invoiceImportListRow-flex-1"}>
              <div
                className={
                  "invoiceImportListRow-header d-flex align-items-center"
                }
              >
                <div className="ms-2 mb-1"></div>
              </div>
            </div>
            <div className={"col-auto invoiceImportListRow-open-modal"}>
              <div onClick={() => openInvoiceModal()}>
                <QuickfiscoIcon name={"edit-invoice.svg"} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
