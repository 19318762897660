import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { MessagesTimetable } from '../messagesTimetable/messagesTimetable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMessagesList } from '../../redux/slices/messagesListSlice';
import { MessagesStatusFiltersType } from '../../models/messagesFilters';
import './dropdownLastMessages.css';

export function DropdownLastMessages() {
  const [open, setOpen] = useState(false);

  const unreadCounters = useAppSelector(
    (state) => state.messagesList.unreadCounters
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open === true) {
      dispatch(
        getMessagesList({ page: 1, type: MessagesStatusFiltersType.DASHBOARD })
      );
    }
  }, [open]);

  return (
    <Dropdown drop={'down-centered'} onToggle={(isOpen) => setOpen(isOpen)}>
      <Dropdown.Toggle id="dropdown-last-messages">
        <QuickfiscoIcon
          name={
            open
              ? unreadCounters.allUnreadMessages > 0
                ? 'message-open-header-user-menu.svg'
                : 'no-message-open-header-user-menu.svg'
              : unreadCounters.allUnreadMessages > 0
              ? 'message-header-user-menu.svg'
              : 'no-message-header-user-menu.svg'
          }
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className={'dropdown-last-messages-menu'}>
        <MessagesTimetable />
      </Dropdown.Menu>
    </Dropdown>
  );
}
