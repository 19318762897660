import { SingleValue } from 'react-select';
import { PersonType } from '../../models/contactModel';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: PersonType;
  error?: boolean;
  errorLabel?: string;
  onChange?: ((personType: PersonType | undefined) => void);
}

export function PersonTypeSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(PersonType[e.value])
        }
      }}
      value={getOptions(value).filter(option => option.selected === true)[0]}
      styleType={'no-borders'}
      options={getOptions(value)}
      error={error}
      errorLabel={errorLabel}
    />
  );
}

function getOptions(value?: PersonType): OptionModel[] {
  return [
    {
      label: 'Tipo di cliente *',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Privati / Associazioni senza Partita Iva',
      value: PersonType.PRIVATE_INDIVIDUAL,
      selected: value === PersonType.PRIVATE_INDIVIDUAL,
    },
    {
      label: 'Azienda / Associazione / Persone fisiche con partita iva',
      value: PersonType.VAT,
      selected: value === PersonType.VAT
    },
    {
      label: 'Pubblica amministrazione',
      value: PersonType.GOV,
      selected: value === PersonType.GOV
    },
    {
      label: 'Cliente estero',
      value: PersonType.FOREIGN,
      selected: value === PersonType.FOREIGN
    }
  ];
}
