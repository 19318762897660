import { useState } from 'react';
import useViewportResize from '../../hooks/viewportResize';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import config from '../../../config.json';
import lang from './viewportWarning.json';
import './viewportWarning.css';

export function ViewportWarning() {
  const [open, setOpen] = useState(true);

  const { width } = useViewportResize();

  return (
    <QuickfiscoModal
      hide={() => setOpen(false)}
      isOpen={open && width < config.minViewportWidth}
    >
      <div className={'p-4 pt-0'}>
        <div className={'row'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <QuickfiscoIcon name={'logo.svg'} className={'w-25'}/>
          </div>
        </div>
        <div className={'row mt-5'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <span className={'viewport-warning-title'}>
              {lang.title}
            </span>
          </div>
        </div>
        <div className={'row mt-5 d-flex justify-content-center'}>
          <div className={'col-8 d-flex justify-content-center'}>
            <span className={'viewport-warning-subtitle'}>
              {lang.subtitle}
            </span>
          </div>
        </div>
        <div className={'row d-flex justify-content-center my-5'}>
          <div className={'col-8 d-flex justify-content-center'}>
            <span className={'viewport-warning-message'}>
              {lang.message}
            </span>
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}
