import config from '../../config.json';

export function removeDots(value?: string): string {
  if (!value) {
    return '';
  }

  let fileNameArray = value.split('.');
  const extensionFile = fileNameArray[fileNameArray.length - 1];

  if (fileNameArray[fileNameArray.length - 1] === '' || config.supportedFileTypes.includes(extensionFile)) {
    fileNameArray.pop();
  }

  if (config.supportedFileTypes.includes(extensionFile)) {
    return fileNameArray.join('-') + '.' + extensionFile;
  } else {
    return fileNameArray.join('-')
  }

}

export function plainString(htmlString?: string): string {
  if (!htmlString) {
    return '';
  }

  return htmlString.replace(/<[^>]+>/g, ' ');
}

export function sanitizeString(string?: string): string {
  if (!string) {
    return '';
  }

  return string.replace(/([^\w]+|\s+)/g, '');
}