import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../../config.json';
import { InvoiceFilters, InvoiceStatusFiltersType } from '../../models/invoiceFilters';
import { InvoiceModel } from '../../models/invoiceModel';
import { PaginationModel } from '../../models/paginationModel';
import { AutoInvoiceService } from '../../services/autoInvoiceService';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { PromiseStatuses } from '../../types/strings';

interface AutoInvoiceListState {
  status: PromiseStatuses;
  invoices: PaginationModel<InvoiceModel>;
  page: number;
  monthFilter?: string;
  yearFilter: string;
  companyNameFilter?: string;
  statusFilter?: InvoiceStatusFiltersType;
}

const initialState: AutoInvoiceListState = {
  status: 'idle',
  invoices: { content: [] },
  page: 0,
  yearFilter: new Date().getFullYear().toString()
};

export const getAutoInvoiceList = createAsyncThunk(
  'autoInvoiceList/getAutoInvoiceList',
  async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {
    return new AutoInvoiceService().findAll(request);
  }
);

const autoInvoiceListSlice = createSlice({
  name: 'autoInvoiceList',
  initialState,
  reducers: {
    setAutoInvoiceListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setAutoInvoiceListMonthFilter: (state, action: PayloadAction<string | undefined>) => {
      state.monthFilter = action.payload;
    },
    setAutoInvoiceListYearFilter: (state, action: PayloadAction<string>) => {
      state.yearFilter = action.payload;
    },
    setAutoInvoiceListCompanyNameFilter: (state, action: PayloadAction<string | undefined>) => {
      state.companyNameFilter = action.payload;
    },
    setAutoInvoiceListStatusFilter: (state, action: PayloadAction<InvoiceStatusFiltersType | undefined>) => {
      state.statusFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutoInvoiceList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAutoInvoiceList.fulfilled, (state, action) => {
        let autoInvoices = CommonInvoiceService.filter(
          'auto',
          {
            page: state.page,
            month: state.monthFilter,
            year: state.yearFilter,
            companyName: state.companyNameFilter,
            status: state.statusFilter
          },
          action.payload
        );

        autoInvoices = CommonInvoiceService.sort(autoInvoices);

        autoInvoices = CommonInvoiceService.paginate(state.page, config.pageSize, autoInvoices);

        state.status = 'successfully';
        state.invoices = autoInvoices;
      })
      .addCase(getAutoInvoiceList.rejected, ((state) => {
        state.status = 'failed';
      }));
  },
});

export const {
  setAutoInvoiceListPage,
  setAutoInvoiceListMonthFilter,
  setAutoInvoiceListYearFilter,
  setAutoInvoiceListCompanyNameFilter,
  setAutoInvoiceListStatusFilter
} = autoInvoiceListSlice.actions;

export default autoInvoiceListSlice.reducer;
