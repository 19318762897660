import { InvoiceStatusFiltersType, ProformaInvoiceStatusFilterType } from '../../models/invoiceFilters';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAutoInvoiceListCompanyNameFilter, setAutoInvoiceListMonthFilter, setAutoInvoiceListStatusFilter, setAutoInvoiceListYearFilter } from '../../redux/slices/autoInvoiceListSlice';
import { setCustomerInvoiceListCompanyNameFilter, setCustomerInvoiceListMonthFilter, setCustomerInvoiceListStatusFilter, setCustomerInvoiceListYearFilter } from '../../redux/slices/customerInvoiceListSlice';
import { setOccasionalJobInvoiceListCompanyNameFilter, setOccasionalJobInvoiceListMonthFilter, setOccasionalJobInvoiceListYearFilter } from '../../redux/slices/occasionalJobInvoiceListSlice';
import { setProformaInvoiceListCompanyNameFilter, setProformaInvoiceListMonthFilter, setProformaInvoiceListStatusFilter, setProformaInvoiceListYearFilter } from '../../redux/slices/proformaInvoiceListSlice';
import { setStsInvoiceListCompanyNameFilter, setStsInvoiceListMonthFilter, setStsInvoiceListYearFilter } from '../../redux/slices/stsInvoiceListSlice';
import { setSupplierInvoiceListCompanyNameFilter, setSupplierInvoiceListMonthFilter, setSupplierInvoiceListStatusFilter, setSupplierInvoiceListYearFilter } from '../../redux/slices/supplierInvoiceListSlice';
import { InvoiceSign } from '../../types/strings';
import {
  InvoiceCustomerStatusSelect
} from '../invoiceCustomerStatusSelect/invoiceCustomerStatusSelect';
import { InvoiceProformaStatusSelect } from '../invoiceProformaStatusSelect/invoiceProformaStatusSelect';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoMonthSelect } from '../quickfiscoMonthSelect/quickfiscoMonthSelect';
import { QuickfiscoYearSelect } from '../quickfiscoYearSelect/quickfiscoYearSelect';
import lang from './invoiceFilters.json';

interface Props {
  invoiceSign: InvoiceSign;
  isCustomerSts?: boolean;
}

export function InvoiceFilters(props: Props) {
  const { invoiceSign } = props;
  const { isCustomerSts } = props;

  const dispatch = useAppDispatch()

  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const supplierInvoiceListState = useAppSelector(state => state.supplierInvoiceList)
  const proformaInvoiceListState = useAppSelector(state => state.proformaInvoiceList)
  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)
  const autoInvoiceListState = useAppSelector(state => state.autoInvoiceList)
  const occasionalJobListState = useAppSelector(state => state.occasionalJobInvoiceList)

  let filterCompanyName: (value?: string) => void
  let filterStatus: (value?: InvoiceStatusFiltersType) => void
  let filterProformaStatus: (value?: ProformaInvoiceStatusFilterType) => void
  let filterMonth: (value?: string) => void
  let filterYear: (value: string) => void
  let valueCompanyName: string | undefined
  let valueStatus: InvoiceStatusFiltersType | undefined
  let valueProformaStatus: ProformaInvoiceStatusFilterType | undefined
  let valueMonth: string | undefined
  let valueYear: string = new Date().getFullYear().toString()
  switch (invoiceSign) {
    case 'customer':
      filterCompanyName = (value?: string) => dispatch(setCustomerInvoiceListCompanyNameFilter(value))
      filterStatus = (value?: InvoiceStatusFiltersType) => dispatch(setCustomerInvoiceListStatusFilter(value))
      filterMonth = (value?: string) => dispatch(setCustomerInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setCustomerInvoiceListYearFilter(value))
      valueCompanyName = customerInvoiceListState.companyNameFilter
      valueStatus = customerInvoiceListState.statusFilter
      valueMonth = customerInvoiceListState.monthFilter
      valueYear = customerInvoiceListState.yearFilter
      break;
    case 'supplier':
      filterCompanyName = (value?: string) => dispatch(setSupplierInvoiceListCompanyNameFilter(value))
      filterStatus = (value?: InvoiceStatusFiltersType) => dispatch(setSupplierInvoiceListStatusFilter(value))
      filterMonth = (value?: string) => dispatch(setSupplierInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setSupplierInvoiceListYearFilter(value))
      valueCompanyName = supplierInvoiceListState.companyNameFilter
      valueStatus = supplierInvoiceListState.statusFilter
      valueMonth = supplierInvoiceListState.monthFilter
      valueYear = supplierInvoiceListState.yearFilter
      break;
    case 'sts':
      filterCompanyName = (value?: string) => dispatch(setStsInvoiceListCompanyNameFilter(value))
      filterMonth = (value?: string) => dispatch(setStsInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setStsInvoiceListYearFilter(value))
      valueCompanyName = stsInvoiceListState.companyNameFilter
      valueMonth = stsInvoiceListState.monthFilter
      valueYear = stsInvoiceListState.yearFilter
      break;
    case 'proforma':
      filterCompanyName = (value?: string) => dispatch(setProformaInvoiceListCompanyNameFilter(value))
      filterProformaStatus = (value?: ProformaInvoiceStatusFilterType) => dispatch(setProformaInvoiceListStatusFilter(value))
      filterMonth = (value?: string) => dispatch(setProformaInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setProformaInvoiceListYearFilter(value))
      valueCompanyName = proformaInvoiceListState.companyNameFilter
      valueProformaStatus = proformaInvoiceListState.statusFilter
      valueMonth = proformaInvoiceListState.monthFilter
      valueYear = proformaInvoiceListState.yearFilter
      break;
    case 'auto':
      filterCompanyName = (value?: string) => dispatch(setAutoInvoiceListCompanyNameFilter(value))
      filterStatus = (value?: InvoiceStatusFiltersType) => dispatch(setAutoInvoiceListStatusFilter(value))
      filterMonth = (value?: string) => dispatch(setAutoInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setAutoInvoiceListYearFilter(value))
      valueCompanyName = autoInvoiceListState.companyNameFilter
      valueStatus = autoInvoiceListState.statusFilter
      valueMonth = autoInvoiceListState.monthFilter
      valueYear = autoInvoiceListState.yearFilter
      break;
    case 'occasionalJob':
      filterCompanyName = (value?: string) => dispatch(setOccasionalJobInvoiceListCompanyNameFilter(value))
      filterMonth = (value?: string) => dispatch(setOccasionalJobInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setOccasionalJobInvoiceListYearFilter(value))
      valueCompanyName = occasionalJobListState.companyNameFilter
      valueMonth = occasionalJobListState.monthFilter
      valueYear = occasionalJobListState.yearFilter
      break;
    case 'creditSlip':
      filterCompanyName = (value?: string) => dispatch(setCustomerInvoiceListCompanyNameFilter(value))
      filterMonth = (value?: string) => dispatch(setCustomerInvoiceListMonthFilter(value))
      filterYear = (value: string) => dispatch(setCustomerInvoiceListYearFilter(value))
      valueCompanyName = customerInvoiceListState.companyNameFilter
      valueMonth = customerInvoiceListState.monthFilter
      valueYear = customerInvoiceListState.yearFilter
      break;
    default:
      return (
        <div>Tipo di fattura non valido.</div>
      )
  }

  return (
    <div className={'row'}>
      <div className={'col-6'}>
        <div>
          <QuickfiscoInput
            id={'invoice-filters-input'}
            type={'text'}
            styleType={'no-borders'}
            placeholder={(invoiceSign === 'supplier' || invoiceSign === 'auto') ? lang.placeholderSupplier : lang.placeholderCustomer}
            value={valueCompanyName || ''}
            onChange={e => filterCompanyName(e.target.value)}
          />
        </div>
      </div>
      {
        (invoiceSign !== 'auto' && invoiceSign !== 'proforma' && invoiceSign !== 'occasionalJob' && invoiceSign !== 'creditSlip' && invoiceSign !== 'sts' && isCustomerSts === false) && (
          <div className={'col-2'}>
            <div>
              <InvoiceCustomerStatusSelect
                id={'invoice-filters-status-select'}
                value={valueStatus}
                onChange={(value) => filterStatus(value)}
              />
            </div>
          </div>)
      }
      {
        invoiceSign === 'proforma' && (
          <div className={'col-2'}>
            <div>
              <InvoiceProformaStatusSelect
                id={'invoice-filters-status-select'}
                value={valueProformaStatus}
                onChange={(value) => filterProformaStatus(value)}
              />
            </div>
          </div>)
      }
      <div className={'col-2'}>
        <div>
          <QuickfiscoMonthSelect
            id={'invoice-filters-month-select'}
            value={valueMonth}
            onChange={(value) => filterMonth(value)}
          />
        </div>
      </div>
      <div className={'col-2'}>
        <div>
          <QuickfiscoYearSelect
            id={'invoice-filters-year-select'}
            value={valueYear}
            onChange={(value) => filterYear(value)}
          />
        </div>
      </div>
    </div>
  );
}
