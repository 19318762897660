import React from 'react';
import useComponentVisible from '../../hooks/componentVisible';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileConfigurationGuidePopup } from '../taxProfileConfigurationGuidePopup/taxProfileConfigurationGuidePopup';
import './taxProfileWizard.css';

interface Props {
  children?: React.ReactNode;
}

export function TaxProfileWizard(props: Props) {
  const { children } = props;

  const showPopup = useComponentVisible(true);

  const userState = useAppSelector(state => state.user)

  if (userState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;

  return (
    <div className={'tax-profile-wizard-background'}>
      <div className={'tax-profile-wizard-white'}>
        {children}
      </div>
      {
        user.status === 4 &&
        user.enabled &&
        user.category &&
        user.taxation !== 0 &&
        user.fund &&
        showPopup.isVisible && (
          <div className='row' style={{ marginTop: '30px' }}>
            <div className='col-8 offset-2'>
              <TaxProfileConfigurationGuidePopup
                showPopup={showPopup}
              />
            </div>
          </div>
        )
      }
    </div>
  );
}
