import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import useNewInvoice from '../../hooks/newInvoice';
import { InvoiceFilters } from '../../models/invoiceFilters';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getInvoicesToCashOut,
  getInvoicesToCashOutInfo,
} from '../../redux/slices/customerInvoiceListSlice';
import uri from '../../routers/quickfiscoUri.json';
import { formatNumberIT } from '../../utils/number';
import { InvoiceCustomerRowType, InvoiceRow } from '../invoiceRow/invoiceRow';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './invoicesToCashOut.css';
import lang from './invoicesToCashOut.json';

export function InvoicesToCashOut() {
  const userState = useAppSelector((state) => state.user);
  const user = useAppSelector(() => userState.user);
  const invoicesToCashOutState = useAppSelector(
    (state) => state.customerInvoiceList
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const customerInvoiceFilters: InvoiceFilters = {
    page: invoicesToCashOutState.page,
    month: invoicesToCashOutState.monthFilter,
    year: '',
    type: invoicesToCashOutState.typeFilter,
    companyName: invoicesToCashOutState.companyNameFilter,
    status: invoicesToCashOutState.statusFilter,
  };

  useEffect(() => {
    dispatch(getInvoicesToCashOut(customerInvoiceFilters));
    dispatch(getInvoicesToCashOutInfo(customerInvoiceFilters));
  }, [invoicesToCashOutState.invoicesToCashOutYearFilter]);

  const invoiceSign = user.stsEnabled ? 'sts' : 'customer';
  const invoicesToCashOut = invoicesToCashOutState.invoiceToCashOut;
  const invoicesToCashOutInfo = invoicesToCashOutState.invoiceToCashOutInfo;

  const newInvoice = useNewInvoice(invoiceSign);

  if (
    invoicesToCashOutState.statusInvoiceToCashOut === 'failed' ||
    invoicesToCashOutState.statusInvoiceToCashOutInfo === 'failed'
  ) {
    return (
      <div className={'invoices-to-pay-container box-shadow'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <span className={'invoices-to-pay-title'}>{lang.title}</span>
          </div>
        </div>
        <div className={'row mt-3 mb-3'}>
          <div className={'col-12 text-center'}>
            <span className={'invoices-to-pay-text'}>{lang.failedStatus}</span>
          </div>
        </div>
      </div>
    );
  }

  if (
    invoicesToCashOutState.statusInvoiceToCashOut === 'loading' ||
    invoicesToCashOutState.statusInvoiceToCashOutInfo === 'loading'
  ) {
    return (
      <div
        className={
          'invoices-to-pay-container box-shadow d-flex justify-content-center'
        }
      >
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (
    invoicesToCashOut !== undefined &&
    invoicesToCashOut.content.length <= 0
  ) {
    return (
      <div className={'invoices-to-pay-container box-shadow'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <span className={'invoices-to-pay-title'}>{lang.title}</span>
          </div>
        </div>
        <div className={'row mt-3'}>
          <div className={'col-12'}>
            <div className={'invoices-to-pay-blue-container'}>
              <span className={'invoices-to-pay-text'}>{lang.empty}</span>
            </div>
          </div>
        </div>
        <div className={'row mt-4'}>
          <div className={'col-12'}>
            <div className={'row d-flex justify-content-center'}>
              <div
                className={'col-auto d-flex justify-content-center mt-3 mb-4'}
              >
                <QuickfiscoIcon name={'new-invoice.svg'} />
              </div>
            </div>
            { (
              <div className={'row d-flex justify-content-center mt-2'}>
                <div className={'col-4'}>
                  <QuickfiscoButton
                    id={'invoices-to-pay-button'}
                    label={lang.emptyButton}
                    type={'secondary'}
                    onClick={() => newInvoice()}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  let rows =
    invoicesToCashOut !== undefined &&
    invoicesToCashOut.content.map((invoice) => {
      return (
        <div key={invoice.id + '-to-be-paid'}>
          <InvoiceRow
            invoiceSign={'customer'}
            invoice={invoice}
            invoiceCustomerRowType={InvoiceCustomerRowType.DASHBOARD}
          />
        </div>
      );
    });

  return (
    <div className={'invoices-to-pay-container box-shadow'}>
      <div className={'row no-gutters'}>
        <div className={'col-12'}>
          <span className={'invoices-to-pay-title'}>{lang.title}</span>
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-12'}>
          <div className={'invoices-to-pay-blue-container'}>
            <span className={'invoices-to-pay-text'}>
              {lang.invoices.line1}
            </span>
            <span className={'invoices-to-pay-text-blue'}>
              {invoicesToCashOutInfo.invoicesToCashOut + lang.invoices.line2}
            </span>
            <span className={'invoices-to-pay-text'}>
              {lang.invoices.line3}
            </span>
            <span className={'invoices-to-pay-text-blue'}>
              {config.EUR +
                formatNumberIT(Number(invoicesToCashOutInfo.amountToCashOut))}
            </span>
          </div>
        </div>
      </div>
      <div className={'row mt-2'}>
        <div className={'col-12'}>{rows}</div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-5 offset-7 col-xxl-6 offset-xxl-6'}>
          <QuickfiscoButton
            id={'invoices-to-pay-list'}
            label={lang.invoicesList}
            type={'primary'}
            onClick={() => navigate(uri.CustomerInvoiceList)}
          />
        </div>
      </div>
    </div>
  );
}
