import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel } from '../../models/contactModel';
import { CurrencyType, InvoiceModel, InvoiceStatusType, InvoiceType } from '../../models/invoiceModel';
import { InvoiceSupplierService } from '../../services/supplierInvoiceService';
import { PromiseStatuses } from '../../types/strings';

interface SupplierInvoiceState {
  findByIdStatus: PromiseStatuses,
  fileList: File[];
  invoice: InvoiceModel;
}

const initialState: SupplierInvoiceState = {
  findByIdStatus: 'idle',
  fileList: [],
  invoice: {
    date: new Date(),
    type: InvoiceType.TD01,
    status: InvoiceStatusType.RECEIVED,
    currency: CurrencyType.EUR,
    stamp: false,
    amount: 0,
    taxBase: 0,
    goodServices: [],
    socialContribution: false,
    socialContributionAmount: 0,
    reimbursementsOfExpenses: [],
    reimbursementsOfExpensesAmount: 0,
    manual: true,
    isPaymentDue: false,
    trackedPayment: false,
    customerDataCommunication: false,
    payments: [],
    documents: [],
    additionalDatasPa: []
  }
};

export const findSupplierInvoiceById = createAsyncThunk(
  'supplierInvoice/findSupplierInvoiceById',
  async (invoiceId: string): Promise<{ invoice: InvoiceModel, files: File[] }> => {
    const service = new InvoiceSupplierService();

    return service.findById(invoiceId)
  }
);

const supplierInvoiceSlice = createSlice({
  name: 'supplierInvoice',
  initialState,
  reducers: {
    setSupplierInvoice: (state, action: PayloadAction<InvoiceModel>) => {
      state.invoice = action.payload;
    },
    setSupplierInvoiceStatus: (state, action: PayloadAction<InvoiceStatusType>) => {
      state.invoice.status = action.payload
    },
    setSupplierInvoiceFileList: (state, action: PayloadAction<File[]>) => {
      state.fileList = action.payload;
    },
    setSupplierInvoiceSupplier: (state, action: PayloadAction<ContactModel | undefined>) => {
      state.invoice.supplier = action.payload
    },
    setSupplierInvoiceDate: (state, action: PayloadAction<Date>) => {
      state.invoice.date = action.payload
    },
    setSupplierInvoiceNumber: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.number = action.payload
    },
    setSupplierInvoiceAmount: (state, action: PayloadAction<number>) => {
      state.invoice.amount = action.payload
    },
    setSupplierInvoicePaymentExpiration: (state, action: PayloadAction<Date | undefined>) => {
      state.invoice.paymentExpiration = action.payload
    },
    setSupplierInvoiceNotes: (state, action: PayloadAction<string | undefined>) => {
      state.invoice.notes = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findSupplierInvoiceById.pending, (state) => {
        state.findByIdStatus = 'loading';
      })
      .addCase(findSupplierInvoiceById.fulfilled, (state, action) => {
        state.findByIdStatus = 'successfully';
        state.invoice = action.payload.invoice;
        state.fileList = action.payload.files;
      })
      .addCase(findSupplierInvoiceById.rejected, ((state) => {
        state.findByIdStatus = 'failed';
      }))
  }
});

export const {
  setSupplierInvoiceFileList,
  setSupplierInvoiceAmount,
  setSupplierInvoiceStatus,
  setSupplierInvoiceDate,
  setSupplierInvoiceNotes,
  setSupplierInvoiceNumber,
  setSupplierInvoicePaymentExpiration,
  setSupplierInvoiceSupplier,
  setSupplierInvoice
} = supplierInvoiceSlice.actions;

export default supplierInvoiceSlice.reducer;
