import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  onChange?: ((month?: string) => void);
}

export function QuickfiscoMonthSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      options={getOptions()}
      styleType={'no-borders'}
      onChange={(e) => e && onChange && onChange(e.value)}
      value={getOptions(value).filter(option => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Mese',
      value: undefined,
      selected: value === undefined
    },
    {
      label: 'Gennaio',
      value: '01',
      selected: value === '01',
    },
    {
      label: 'Febbraio',
      value: '02',
      selected: value === '02',
    },
    {
      label: 'Marzo',
      value: '03',
      selected: value === '03',
    },
    {
      label: 'Aprile',
      value: '04',
      selected: value === '04',
    },
    {
      label: 'Maggio',
      value: '05',
      selected: value === '05',
    },
    {
      label: 'Giugno',
      value: '06',
      selected: value === '06',
    },
    {
      label: 'Luglio',
      value: '07',
      selected: value === '07',
    },
    {
      label: 'Agosto',
      value: '08',
      selected: value === '08',
    },
    {
      label: 'Settembre',
      value: '09',
      selected: value === '09',
    },
    {
      label: 'Ottobre',
      value: '10',
      selected: value === '10',
    },
    {
      label: 'Novembre',
      value: '11',
      selected: value === '11',
    },
    {
      label: 'Dicembre',
      value: '12',
      selected: value === '12',
    },
  ];
}
