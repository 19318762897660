import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { TotalRevenuesModel } from '../../models/totalRevenuesModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setOtherCurrentYearRevenues } from '../../redux/slices/taxProfileSlice';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { FeesRegisterService } from '../../services/feesRegisterService';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileChangeStep } from '../taxProfileChangeStep/taxProfileChangeStep';
import {
  TaxProfileCurrentYearDataPopup
} from '../taxProfileCurrentYearDataPopup/taxProfileCurrentYearDataPopup';
import { TaxProfileStepCounter } from '../taxProfileStepCounter/taxProfileStepCounter';
import './taxProfileCurrentYearData.css';
import lang from './taxProfileCurrentYearData.json';

export function TaxProfileCurrentYearData() {
  const [statusRevenues, setStatusRevenues] = useState<PromiseStatuses>('idle');
  const [revenues, setRevenues] = useState<TotalRevenuesModel | undefined>(undefined);
  const [statusStsRevenues, setStatusStsRevenues] = useState<PromiseStatuses>('idle');
  const [stsRevenues, setStsRevenues] = useState<TotalRevenuesModel | undefined>(undefined);
  const [statusFeesRevenues, setStatusFeesRevenues] = useState<PromiseStatuses>('idle');
  const [feesRevenues, setFeesRevenues] = useState<TotalRevenuesModel | undefined>(undefined);

  const taxProfileState = useAppSelector(state => state.taxProfile)

  const dispatch = useAppDispatch();

  useEffect(() => {
    getTotalRevenues(setStatusRevenues, setRevenues, 'customer');
    getTotalRevenues(setStatusStsRevenues, setStsRevenues, 'sts');
    getTotalRevenues(setStatusFeesRevenues, setFeesRevenues, 'fees');
  }, []);

  if (taxProfileState.status === 'loading') {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <QuickfiscoSpinner />
      </div>
    )
  }

  const taxProfile = taxProfileState.profile;

  if (statusRevenues === 'loading' || statusStsRevenues === 'loading' || statusFeesRevenues === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (statusRevenues === 'failed' || statusStsRevenues === 'failed' || statusFeesRevenues === 'failed' || !revenues || !stsRevenues || !feesRevenues) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const mergedRevenues = CommonInvoiceService.mergeTotalRevenues(revenues, stsRevenues, feesRevenues);
  const totalInvoicesPaid: number = CommonInvoiceService.calculateTotalPaid(mergedRevenues).totalPaid + CommonInvoiceService.calculateTotalPaid(mergedRevenues).totalPaidLastYear;

  return (
    <div className={'tax-profile-current-year-data-container'}>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <TaxProfileStepCounter />
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-current-year-data-title'}>
            {lang.title}
          </span>
          <div className={'ms-3'}>
            <TaxProfileCurrentYearDataPopup />
          </div>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <QuickfiscoInput
            id='tax-profile-current-year-data-revenues-input'
            key={'input-tax-profile-revenues'}
            type={'text'}
            label={lang.revenues}
            styleType={'medium-center'}
            value={formatNumberIT(totalInvoicesPaid) + '€'}
            disabled={true}
          />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-current-year-data-text'}>
            {lang.totalRevenues}
          </span>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <NumericFormat
            key={'input-tax-profile-other-revenues'}
            id={'tax-profile-current-year-data-other-revenues'}
            suffix='€'
            defaultValue={taxProfile.otherCurrentYearRevenues}
            decimalSeparator=","
            decimalScale={2}
            customInput={QuickfiscoInput}
            onValueChange={e => { e.floatValue !== undefined && dispatch(setOtherCurrentYearRevenues(e.floatValue)) }}
            styleType={'medium-center'}
            label={lang.otherRevenues}
            placeholder={lang.placeholder}
            fixedDecimalScale
          />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-current-year-data-text'}>
            {lang.otherRevenuesLabel}
          </span>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <TaxProfileChangeStep />
        </div>
      </div>
    </div>
  );
}

function getTotalRevenues(
  setStatus: SetState<PromiseStatuses>,
  setReports: SetState<TotalRevenuesModel | undefined>,
  invoiceSign: 'customer' | 'sts' | 'fees'
): void {
  let invoiceService;
  if (invoiceSign === 'customer') {
    invoiceService = new CustomerInvoiceService()
  } else if (invoiceSign === 'sts') {
    invoiceService = new StsInvoiceService();
  } else {
    invoiceService = new FeesRegisterService();
  }

  setStatus('loading');
  invoiceService
    .getTotalRevenues(new Date().getFullYear())
    .then(res => {
      setStatus('idle');
      setReports(res);
    })
    .catch(err => {
      console.error(err);
      setStatus('failed');
    });
}
