import { useEffect } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './taxReturnIntro.json';
import './taxReturnIntro.css';

interface Props {
  onClickNextStep: () => void;
  onClickPrevStep: () => void;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnIntro(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { refreshApiCall } = props;

  useEffect(() => {
    refreshApiCall(0, true);
  }, []);

  return (
    <div className="row no-gutters p-5 mt-5">
      <div className="col-12 text-center">
        <QuickfiscoIcon name={'tax-return-intro.svg'} />
        <div className="row mt-5">
          <div className="col-8 offset-2 tax-return-section-intro-title">
            {lang.title}
          </div>
        </div>
        <div className="row">
          <div className="col-10 offset-1 tax-return-section-intro-description mt-4">
            {lang.description1}
          </div>
        </div>
        <div className="row">
          <div className="col-10 offset-1 tax-return-section-intro-description mt-4">
            {lang.description2}
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-4">
            <QuickfiscoButton
              id={'tax-return-intro-go-next'}
              label={lang.goNext}
              type={'secondary'}
              onClick={() => onClickNextStep()}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-4">
            <QuickfiscoButton
              id={'tax-return-intro-go-next'}
              label={lang.goprev}
              type={'primary'}
              onClick={() => onClickPrevStep()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
