import { useEffect } from 'react';
import './youSignIframe.css';

interface Props {
  signatureLink: string;
  onSuccessSignature: (youSignSignatureRequestId: string) => void;
}

export function YouSignIframe(props: Props) {
  const { signatureLink } = props;
  const { onSuccessSignature } = props;

  const scriptId = 'yousign-sdk-script';

  const loadScript = () => {
    return new Promise<void>((resolve, reject) => {
      let script = document.getElementById(scriptId) as HTMLScriptElement;
      if (!script) {
        script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://cdn.yousign.tech/iframe-sdk-1.5.0.min.js';
        script.integrity =
          'sha384-wrNTU81R+b2K+9A1aE1c3blhReNpdRK6Tn1fzXOXhWGZGUk+Btp0dyfnk45pVpOr';
        script.crossOrigin = 'anonymous';
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Failed to load script ${scriptId}`));
        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  };

  const loadIframe = () => {
    if (window.Yousign) {
      const yousign = new window.Yousign({
        signatureLink,
        iframeContainerId: 'youSign-iframe-container',
        isSandbox:
          window.location.hostname !== 'app.quickfisco.it' ? true : false,
      });

      yousign.onSuccess((data) => {
        onSuccessSignature(data.signature_request_id);
      });

      yousign.onError((data) => {
        console.error('Signer encountered an error when signing: ', data.error);
      });
    } else {
      console.error('YouSign SDK non caricato correttamente.');
    }
  };

  useEffect(() => {
    signatureLink &&
      loadScript()
        .then(loadIframe)
        .catch((error) => console.error(error));

    return () => {
      const script = document.getElementById(scriptId);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [signatureLink]);

  return (
    <div
      id="youSign-iframe-container"
      className="you-sign-iframe-container"
    ></div>
  );
}
