import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentsReportModel } from '../../models/documentsReportModel';
import { PaginationModel } from '../../models/paginationModel';
import { PromiseStatuses } from '../../types/strings';
import { DocumentsFilters, DocumentsStatusFiltersType } from '../../models/documentsFilters';
import { DocumentsModel } from '../../models/documentsModel';
import { DocumentsService } from '../../services/documentsService';

interface DocumentsListState {
  status: PromiseStatuses;
  documents: PaginationModel<DocumentsModel>;
  reports: DocumentsReportModel;
  reportsStatus: PromiseStatuses;
  page: number;
  statusFilter?: DocumentsStatusFiltersType;
}

const initialState: DocumentsListState = {
  status: 'idle',
  documents: { content: [] },
  reports: {
    allDocuments: 0,
    f24Models: 0,
    taxDeclaration: 0,
    VatandIdDocuments: 0,
    OtherDocuments: 0
  },
  reportsStatus: 'idle',
  page: 1,
  statusFilter: undefined
};

export const getDocumentsReports = createAsyncThunk(
  'documentsList/getDocumentsReports',
  async (): Promise<DocumentsReportModel> => {
    return await new DocumentsService().getReports();
  }
);

export const getDocumentsList = createAsyncThunk(
  'documentsList/getDocumentsList',
  async (request: DocumentsFilters): Promise<PaginationModel<DocumentsModel>> => {
    return await new DocumentsService().findAll(request);
  }
);

const documentsListSlice = createSlice({
  name: 'documentsList',
  initialState,
  reducers: {
    setDocumentsListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setDocumentsListStatusFilter: (state, action: PayloadAction<DocumentsStatusFiltersType | undefined>) => {
      state.statusFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsReports.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(getDocumentsReports.fulfilled, (state, action) => {
        state.reportsStatus = 'idle';
        state.reports = action.payload;
      })
      .addCase(getDocumentsReports.rejected, ((state) => {
        state.reportsStatus = 'failed';
      }))
      .addCase(getDocumentsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDocumentsList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.documents = action.payload;
      })
      .addCase(getDocumentsList.rejected, ((state) => {
        state.status = 'failed';
      }))
  },
});

export const {
  setDocumentsListPage,
  setDocumentsListStatusFilter
} = documentsListSlice.actions;

export default documentsListSlice.reducer;
