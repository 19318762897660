import { useEffect } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { InvoiceFilters } from '../../components/invoiceFilters/invoiceFilters';
import { InvoiceList } from '../../components/invoiceList/invoiceList';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { QuickfiscoIcon } from '../../components/quickfiscoIcon/quickfiscoIcon';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import useNewInvoice from '../../hooks/newInvoice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getOccasionalJobInvoiceList,
  setOccasionalJobInvoiceListPage,
} from '../../redux/slices/occasionalJobInvoiceListSlice';
import { QuickfiscoPagination } from '../../components/quickfiscoPagination/quickfiscoPagination';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';

export function OccasionalJobInvoiceList() {
  const newInvoice = useNewInvoice('occasionalJob');
  const dispatch = useAppDispatch();

  const occasionalJobInvoiceListState = useAppSelector(
    (state) => state.occasionalJobInvoiceList
  );
  const userState = useAppSelector((state) => state.user.user);

  const occasionalJobInvoices = occasionalJobInvoiceListState.invoices;

  const { collapsed } = useProSidebar();

  useEffect(() => {
    if (occasionalJobInvoiceListState.page === undefined) {
      dispatch(setOccasionalJobInvoiceListPage(0));
    }
  }, []);

  useEffect(() => {
    dispatch(
      getOccasionalJobInvoiceList({
        page: occasionalJobInvoiceListState.page,
        companyName: occasionalJobInvoiceListState.companyNameFilter,
        month: occasionalJobInvoiceListState.monthFilter,
        year: occasionalJobInvoiceListState.yearFilter,
      })
    );
  }, [
    occasionalJobInvoiceListState.page,
    occasionalJobInvoiceListState.companyNameFilter,
    occasionalJobInvoiceListState.monthFilter,
    occasionalJobInvoiceListState.yearFilter,
  ]);

  useEffect(() => {
    if (
      occasionalJobInvoiceListState.page !== undefined &&
      occasionalJobInvoiceListState.page > 0 &&
      occasionalJobInvoiceListState.invoices.content.length === 0
    ) {
      dispatch(
        setOccasionalJobInvoiceListPage(occasionalJobInvoiceListState.page - 1)
      );
    }
  }, [occasionalJobInvoiceListState.invoices.content]);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="occasional-job-invoice-list-menu"
            menuItem={MenuItems.OCCASIONAL_JOB_INVOICE}
          />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col">
                  <QuickfiscoHeader
                    title="Prestazioni Occasionali"
                  />
                  {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-11">
                  <InvoiceFilters invoiceSign={'occasionalJob'} />
                </div>
                <div className="col-1 d-flex justify-content-end">
                  <QuickfiscoIcon
                    name={'new.svg'}
                    className={'w-f-35'}
                    isButton={true}
                    onClick={() => newInvoice()}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <InvoiceList invoiceSign={'occasionalJob'} />
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className={'col-12'}>
                  <div className={'d-flex justify-content-center'}>
                    <QuickfiscoPagination
                      totalPages={occasionalJobInvoices.totalPages}
                      number={occasionalJobInvoices.number}
                      empty={occasionalJobInvoices.empty}
                      doPagination={(page: number) =>
                        dispatch(setOccasionalJobInvoiceListPage(page))
                      }
                      first={occasionalJobInvoices.first}
                      last={occasionalJobInvoices.last}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
