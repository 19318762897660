import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  label?: string;
  onChange?: (province?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoProvinceSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { label } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={label || 'Provincia'}
      options={getOptions()}
      styleType={'default'}
      value={
        getOptions(value?.toUpperCase()).filter(
          (option) => option.selected === true
        )[0]
      }
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
    />
  );
}
function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona provincia',
      value: undefined,
      selected: value === undefined,
    },
    { label: 'AG', value: 'AG', selected: value === 'AG' },
    { label: 'AL', value: 'AL', selected: value === 'AL' },
    { label: 'AN', value: 'AN', selected: value === 'AN' },
    { label: 'AO', value: 'AO', selected: value === 'AO' },
    { label: 'AR', value: 'AR', selected: value === 'AR' },
    { label: 'AP', value: 'AP', selected: value === 'AP' },
    { label: 'AT', value: 'AT', selected: value === 'AT' },
    { label: 'AV', value: 'AV', selected: value === 'AV' },
    { label: 'BA', value: 'BA', selected: value === 'BA' },
    { label: 'BL', value: 'BL', selected: value === 'BL' },
    { label: 'BN', value: 'BN', selected: value === 'BN' },
    { label: 'BG', value: 'BG', selected: value === 'BG' },
    { label: 'BI', value: 'BI', selected: value === 'BI' },
    { label: 'BO', value: 'BO', selected: value === 'BO' },
    { label: 'BT', value: 'BT', selected: value === 'BT' },
    { label: 'BZ', value: 'BZ', selected: value === 'BZ' },
    { label: 'BS', value: 'BS', selected: value === 'BS' },
    { label: 'BR', value: 'BR', selected: value === 'BR' },
    { label: 'CA', value: 'CA', selected: value === 'CA' },
    { label: 'CI', value: 'CI', selected: value === 'CI' },
    { label: 'CL', value: 'CL', selected: value === 'CL' },
    { label: 'CB', value: 'CB', selected: value === 'CB' },
    { label: 'CE', value: 'CE', selected: value === 'CE' },
    { label: 'CT', value: 'CT', selected: value === 'CT' },
    { label: 'CZ', value: 'CZ', selected: value === 'CZ' },
    { label: 'CH', value: 'CH', selected: value === 'CH' },
    { label: 'CO', value: 'CO', selected: value === 'CO' },
    { label: 'CS', value: 'CS', selected: value === 'CS' },
    { label: 'CR', value: 'CR', selected: value === 'CR' },
    { label: 'KR', value: 'KR', selected: value === 'KR' },
    { label: 'CN', value: 'CN', selected: value === 'CN' },
    { label: 'EE', value: 'EE', selected: value === 'EE' },
    { label: 'EN', value: 'EN', selected: value === 'EN' },
    { label: 'FM', value: 'FM', selected: value === 'FM' },
    { label: 'FE', value: 'FE', selected: value === 'FE' },
    { label: 'FI', value: 'FI', selected: value === 'FI' },
    { label: 'FG', value: 'FG', selected: value === 'FG' },
    { label: 'FO', value: 'FO', selected: value === 'FO' },
    { label: 'FC', value: 'FC', selected: value === 'FC' },
    { label: 'FR', value: 'FR', selected: value === 'FR' },
    { label: 'GE', value: 'GE', selected: value === 'GE' },
    { label: 'GO', value: 'GO', selected: value === 'GO' },
    { label: 'GR', value: 'GR', selected: value === 'GR' },
    { label: 'IM', value: 'IM', selected: value === 'IM' },
    { label: 'IS', value: 'IS', selected: value === 'IS' },
    { label: 'SP', value: 'SP', selected: value === 'SP' },
    { label: 'AQ', value: 'AQ', selected: value === 'AQ' },
    { label: 'LT', value: 'LT', selected: value === 'LT' },
    { label: 'LE', value: 'LE', selected: value === 'LE' },
    { label: 'LC', value: 'LC', selected: value === 'LC' },
    { label: 'LI', value: 'LI', selected: value === 'LI' },
    { label: 'LO', value: 'LO', selected: value === 'LO' },
    { label: 'LU', value: 'LU', selected: value === 'LU' },
    { label: 'MC', value: 'MC', selected: value === 'MC' },
    { label: 'MN', value: 'MN', selected: value === 'MN' },
    { label: 'MS', value: 'MS', selected: value === 'MS' },
    { label: 'MT', value: 'MT', selected: value === 'MT' },
    { label: 'ME', value: 'ME', selected: value === 'ME' },
    { label: 'MI', value: 'MI', selected: value === 'MI' },
    { label: 'MO', value: 'MO', selected: value === 'MO' },
    { label: 'MB', value: 'MB', selected: value === 'MB' },
    { label: 'NA', value: 'NA', selected: value === 'NA' },
    { label: 'NO', value: 'NO', selected: value === 'NO' },
    { label: 'NU', value: 'NU', selected: value === 'NU' },
    { label: 'OG', value: 'OG', selected: value === 'OG' },
    { label: 'OR', value: 'OR', selected: value === 'OR' },
    { label: 'OT', value: 'OT', selected: value === 'OT' },
    { label: 'PD', value: 'PD', selected: value === 'PD' },
    { label: 'PA', value: 'PA', selected: value === 'PA' },
    { label: 'PR', value: 'PR', selected: value === 'PR' },
    { label: 'PV', value: 'PV', selected: value === 'PV' },
    { label: 'PG', value: 'PG', selected: value === 'PG' },
    { label: 'PS', value: 'PS', selected: value === 'PS' },
    { label: 'PU', value: 'PU', selected: value === 'PU' },
    { label: 'PE', value: 'PE', selected: value === 'PE' },
    { label: 'PC', value: 'PC', selected: value === 'PC' },
    { label: 'PI', value: 'PI', selected: value === 'PI' },
    { label: 'PT', value: 'PT', selected: value === 'PT' },
    { label: 'PN', value: 'PN', selected: value === 'PN' },
    { label: 'PZ', value: 'PZ', selected: value === 'PZ' },
    { label: 'PO', value: 'PO', selected: value === 'PO' },
    { label: 'RG', value: 'RG', selected: value === 'RG' },
    { label: 'RA', value: 'RA', selected: value === 'RA' },
    { label: 'RC', value: 'RC', selected: value === 'RC' },
    { label: 'RE', value: 'RE', selected: value === 'RE' },
    { label: 'RI', value: 'RI', selected: value === 'RI' },
    { label: 'RN', value: 'RN', selected: value === 'RN' },
    { label: 'RM', value: 'RM', selected: value === 'RM' },
    { label: 'RO', value: 'RO', selected: value === 'RO' },
    { label: 'SA', value: 'SA', selected: value === 'SA' },
    { label: 'SS', value: 'SS', selected: value === 'SS' },
    { label: 'SU', value: 'SU', selected: value === 'SU' },
    { label: 'SV', value: 'SV', selected: value === 'SV' },
    { label: 'SI', value: 'SI', selected: value === 'SI' },
    { label: 'SR', value: 'SR', selected: value === 'SR' },
    { label: 'SO', value: 'SO', selected: value === 'SO' },
    { label: 'TA', value: 'TA', selected: value === 'TA' },
    { label: 'TE', value: 'TE', selected: value === 'TE' },
    { label: 'TR', value: 'TR', selected: value === 'TR' },
    { label: 'TO', value: 'TO', selected: value === 'TO' },
    { label: 'TP', value: 'TP', selected: value === 'TP' },
    { label: 'TN', value: 'TN', selected: value === 'TN' },
    { label: 'TV', value: 'TV', selected: value === 'TV' },
    { label: 'TS', value: 'TS', selected: value === 'TS' },
    { label: 'UD', value: 'UD', selected: value === 'UD' },
    { label: 'VA', value: 'VA', selected: value === 'VA' },
    { label: 'VE', value: 'VE', selected: value === 'VE' },
    { label: 'VB', value: 'VB', selected: value === 'VB' },
    { label: 'VC', value: 'VC', selected: value === 'VC' },
    { label: 'VCO', value: 'VCO', selected: value === 'VCO' },
    { label: 'VR', value: 'VR', selected: value === 'VR' },
    { label: 'VS', value: 'VS', selected: value === 'VS' },
    { label: 'VV', value: 'VV', selected: value === 'VV' },
    { label: 'VI', value: 'VI', selected: value === 'VI' },
    { label: 'VT', value: 'VT', selected: value === 'VT' },
  ];
}
