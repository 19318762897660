import { InvoiceType } from './invoiceModel';

export interface InvoiceFilters {
  page: number;
  year: string;
  month?: string;
  companyName?: string;
  type?: InvoiceType;
  status?: InvoiceStatusFiltersType;
  stsStatus?: StsInvoiceStatusFilterType;
  proformaStatus?: ProformaInvoiceStatusFilterType;
  isSts?: boolean;
}

export enum InvoiceStatusFiltersType {
  TO_PAY = 'TO_PAY',
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  CAN_BE_PAY = 'CAN_BE_PAY'
}

export enum StsInvoiceStatusFilterType {
  SEND = 'SEND',
  QUEUED = 'QUEUED',
  TO_SEND = 'TO_SEND',
  REJECTED = 'REJECTED'
}

export enum ProformaInvoiceStatusFilterType {
  SENT = 'SENT',
}
