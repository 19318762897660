import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeesRegisterModel } from '../../models/feesRegisterModel';
import { FeesRegisterService } from '../../services/feesRegisterService';
import { PromiseStatuses } from '../../types/strings';

export interface FeesRegisterStatus {
    monthFilter: string
    yearFilter: string,
    findAllStatus: PromiseStatuses,
    feesRegisterList: FeesRegisterModel[],
    findByIdStatus: PromiseStatuses,
    feesRegisterToEdit: FeesRegisterModel,
    editStatus: PromiseStatuses
    saveStatus: PromiseStatuses
}

export const initialState: FeesRegisterStatus = {
    monthFilter: (new Date().getMonth() + 1).toString(),
    yearFilter: new Date().getFullYear().toString(),
    findAllStatus: 'idle',
    findByIdStatus: 'idle',
    editStatus: 'idle',
    saveStatus: 'idle',
    feesRegisterList: [],
    feesRegisterToEdit: {
        date: new Date(),
        description: '',
        amount: 0,
        amount_EU: 0,
        amount_IT: 0,
        amount_EXTRA_EU: 0
    }
};

export const feesRegisterFindAll = createAsyncThunk(
    'feesRegister/findAll',
    async (request: { month: string, year: string }): Promise<FeesRegisterModel[]> => {
        return new FeesRegisterService().findAll(request.year, request.month);
    }
);

export const feesRegisterFindById = createAsyncThunk(
    'feesRegister/findById',
    async (id: string): Promise<FeesRegisterModel> => {
        return new FeesRegisterService().findById(id);
    }
);

export const feesRegisterEdit = createAsyncThunk(
    'feesRegister/edit',
    async (request: { id?: string, feesRegister: FeesRegisterModel, }): Promise<void> => {
        return new FeesRegisterService().edit(request.feesRegister, request.id);
    }
);

export const feesRegisterDelete = createAsyncThunk(
    'feesRegister/delete',
    async (request: { id?: string }): Promise<void> => {
        return new FeesRegisterService().delete(request.id);
    }
);

export const feesRegisterSave = createAsyncThunk(
    'feesRegister/save',
    async (request: { feesRegister: FeesRegisterModel }): Promise<void> => {
        return new FeesRegisterService().save(request.feesRegister);
    }
);

const feesRegisterSlice = createSlice({
    name: 'feesRegister',
    initialState,
    reducers: {
        setFeesRegisterMonthFilter: (state, action: PayloadAction<string>) => {
            state.monthFilter = action.payload;
        },
        setFeesRegisterYearFilter: (state, action: PayloadAction<string>) => {
            state.yearFilter = action.payload
        },
        setFeesRegisterDate: (state, action: PayloadAction<Date>) => {
            state.feesRegisterToEdit.date = action.payload
        },
        setFeesRegisterDescription: (state, action: PayloadAction<string | undefined>) => {
            state.feesRegisterToEdit.description = action.payload
        },
        setFeesRegisterAmountIt: (state, action: PayloadAction<number>) => {
            state.feesRegisterToEdit.amount_IT = action.payload
        },
        setFeesRegisterAmountEu: (state, action: PayloadAction<number>) => {
            state.feesRegisterToEdit.amount_EU = action.payload
        },
        setFeesRegisterAmountExtraEu: (state, action: PayloadAction<number>) => {
            state.feesRegisterToEdit.amount_EXTRA_EU = action.payload
        },
        setFeesRegisterFindByIdStatus: (state, action: PayloadAction<PromiseStatuses>) => {
            state.findByIdStatus = action.payload
        },
        setResetFeesRegister: (state, action: PayloadAction<FeesRegisterModel>) => {
            state.feesRegisterToEdit = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(feesRegisterFindAll.pending, (state) => {
                state.findAllStatus = 'loading';
            })
            .addCase(feesRegisterFindAll.fulfilled, (state, action: PayloadAction<FeesRegisterModel[]>) => {
                state.findAllStatus = 'successfully';
                state.feesRegisterList = action.payload;
            })
            .addCase(feesRegisterFindAll.rejected, (state) => {
                state.findAllStatus = 'failed';
            })
            .addCase(feesRegisterFindById.pending, (state) => {
                state.findByIdStatus = 'loading';
            })
            .addCase(feesRegisterFindById.fulfilled, (state, action: PayloadAction<FeesRegisterModel>) => {
                state.findByIdStatus = 'successfully';
                state.feesRegisterToEdit = action.payload;
            })
            .addCase(feesRegisterFindById.rejected, (state) => {
                state.findByIdStatus = 'failed';
            })
            .addCase(feesRegisterEdit.pending, (state) => {
                state.editStatus = 'loading';
            })
            .addCase(feesRegisterEdit.fulfilled, (state) => {
                state.editStatus = 'successfully';
            })
            .addCase(feesRegisterEdit.rejected, (state) => {
                state.editStatus = 'failed';
            })
            .addCase(feesRegisterSave.pending, (state) => {
                state.saveStatus = 'loading';
            })
            .addCase(feesRegisterSave.fulfilled, (state) => {
                state.saveStatus = 'successfully';
            })
            .addCase(feesRegisterSave.rejected, (state) => {
                state.saveStatus = 'failed';
            })
    },
});

export const {
    setFeesRegisterMonthFilter,
    setFeesRegisterYearFilter,
    setFeesRegisterAmountEu,
    setFeesRegisterAmountExtraEu,
    setFeesRegisterAmountIt,
    setFeesRegisterDescription,
    setFeesRegisterDate,
    setFeesRegisterFindByIdStatus,
    setResetFeesRegister
} = feesRegisterSlice.actions;

export default feesRegisterSlice.reducer
