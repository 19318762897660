import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { DropdownUserMenu } from '../dropdownUserMenu/dropdownUserMenu';
import { DropdownLastMessages } from '../dropdownLastMessages/dropdownLastMessages';
import { DropdownFiscalEvent } from '../dropdownFiscalEvent/dropdownFiscalEvent';
import { QuickfiscoYearSelect } from '../quickfiscoYearSelect/quickfiscoYearSelect';
import { setFeesRegisterYearFilter } from '../../redux/slices/feesRegisterSlice';
import { setOccasionalJobInvoiceListYearFilter } from '../../redux/slices/occasionalJobInvoiceListSlice';
import { setProformaInvoiceListYearFilter } from '../../redux/slices/proformaInvoiceListSlice';
import { setAutoInvoiceListYearFilter } from '../../redux/slices/autoInvoiceListSlice';
import { setSupplierInvoiceListYearFilter } from '../../redux/slices/supplierInvoiceListSlice';
import { setCustomerInvoiceListYearFilter } from '../../redux/slices/customerInvoiceListSlice';
import { setStsInvoiceListYearFilter } from '../../redux/slices/stsInvoiceListSlice';
import { setYear } from '../../redux/slices/taxCalculationSlice';
import './rightHeaderMenu.css';

export function RightHeaderMenu() {
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.user.user);
  const feesRegisterState = useAppSelector((state) => state.feesRegister);
  const currentYear = feesRegisterState.yearFilter;

  const setGlobalYear = (year: string) => {
    dispatch(setFeesRegisterYearFilter(year));
    dispatch(setStsInvoiceListYearFilter(year));
    dispatch(setYear(year));
    dispatch(setCustomerInvoiceListYearFilter(year));
    dispatch(setSupplierInvoiceListYearFilter(year));
    dispatch(setProformaInvoiceListYearFilter(year));
    dispatch(setAutoInvoiceListYearFilter(year));
    dispatch(setOccasionalJobInvoiceListYearFilter(year));
  };

  return (
    <div
      className={
        'right-header-menu-container d-flex justify-content-center align-items-center'
      }
    >
      <div className="row no-gutters d-flex justify-content-center align-items-center">
        <div className="col px-1 pb-1">
          <DropdownLastMessages />
        </div>
        <div className="col px-1 pb-1">
          <DropdownFiscalEvent />
        </div>
        {userState.status !== 6 && (
          <div className="col px-1">
            <QuickfiscoYearSelect
              id="right-header-menu-year-select"
              value={currentYear}
              onChange={(year) => setGlobalYear(year)}
              isHeader={true}
            />
          </div>
        )}
        <div className="col text-center">
          <DropdownUserMenu />
        </div>
      </div>
    </div>
  );
}
