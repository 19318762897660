import lang from './taxReturnSection10Informations.json';
import './taxReturnSection10Informations.css';

export function TaxReturnSection10Informations() {
  return (
    <div className={'row no-gutters my-4'}>
      <div className="col-12">
        <div
          className={'tax-return-section-10-informations-container-text p-5'}
        >
          <div className="row">
            <div className="col-12 tax-return-section-10-informations-title">
              {lang.title}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 tax-return-section-10-informations-description">
              {lang.p1}
              <br />
              <br />
              {lang.p2}
              <br />
              <br />
              {lang.listP}
              <ul>
                <li>{lang.list1}</li>
                <li>{lang.list2}</li>
                <li>{lang.list3}</li>
                <li>{lang.list4}</li>
              </ul>
              {lang.p3}
              <br />
              <br />
              <br />
              <b>{lang.p4}</b>
            </div>
          </div>
          <div className={'row'}>
            <div className="col-12">
              <hr className="tax-return-section-10-informations-hr my-4" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 tax-return-section-10-informations-description text-center">
              <b>{lang.p5}</b>
              <br />
              <br />
              Grazie alla partnership tra Quickfisco e{' '}
              <span className="tax-return-section-10-informations-description-blue">
                CryptoBooks.tax
              </span>{' '}
              potrai accedere a uno sconto esclusivo
              <br />
              pari al 25% inserendo il{' '}
              <b>
                promocode{' '}
                <span className="tax-return-section-10-informations-description-blue">
                  <b>QUICKFISCO</b>
                </span>
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
