import { CategoryType } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import { fundsMap } from '../fundSelect/fundSelect';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileChangeStep } from '../taxProfileChangeStep/taxProfileChangeStep';
import { TaxProfileStepCounter } from '../taxProfileStepCounter/taxProfileStepCounter';
import './taxProfileConfirmData.css';
import lang from './taxProfileConfirmData.json';

export function TaxProfileConfirmData() {
  const userState = useAppSelector(state => state.user);

  if (userState.status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;

  return (
    <div className={'tax-profile-confirm-data-container'}>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <TaxProfileStepCounter />
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-confirm-data-title'}>
            {lang.title}
          </span>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <QuickfiscoInput
            id='tax-profile-confirm-data-ateco-input'
            key={'input-tax-profile-ateco'}
            type={'text'}
            label={lang.ateco}
            styleType={'default-center'}
            defaultValue={user.atecos?.[0]}
            disabled={true}
          />
        </div>
      </div>
      <div className={'row mt-4'}>
        <div className={'col-12'}>
          <QuickfiscoInput
            id='tax-profile-confirm-data-taxation-input'
            key={'input-tax-profile-taxation'}
            type={'text'}
            label={lang.taxation}
            styleType={'default-center'}
            defaultValue={String(user.taxation) + '%'}
            disabled={true}
          />
        </div>
      </div>
      <div className={'row mt-4'}>
        <div className={'col-12'}>
          <QuickfiscoInput
            id='tax-profile-confirm-data-fund-input'
            key={'input-tax-profile-fund'}
            type={'text'}
            label={lang.cassa}
            styleType={'default-center'}
            defaultValue={user.fund && fundsMap.get(user.fund)?.desc}
            disabled={true}
          />
        </div>
      </div>
      {
        (user.category === CategoryType.ARTISAN ||
          user.category === CategoryType.TRADER) && 
        (
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <QuickfiscoInput
                id='tax-profile-confirm-data-contribution-reduction-input'
                key={'input-tax-profile-contribution-reduction'}
                type={'text'}
                label={lang.contributionReduction}
                styleType={'default-center'}
                defaultValue={user.contributionReduction ? 'SI' : "NO"}
                disabled={true}
              />
            </div>
          </div>
        )
      }
      <div className={'row mt-5'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-confirm-data-blue-text'}>
            {lang.updateData}
          </span>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <TaxProfileChangeStep />
        </div>
      </div>
    </div>
  );
}
