import './quickfiscoPagination.css';

interface Props {
  totalPages?: number;
  number?: number;
  empty?: boolean;
  doPagination?: Function;
  first?: boolean;
  last?: boolean;
}

export function QuickfiscoPagination(props: Props) {
  const { totalPages } = props;
  const { number } = props;
  const { empty } = props;
  const { doPagination } = props;
  const { first } = props;
  const { last } = props;

  if (totalPages === undefined || number === undefined || doPagination === undefined || empty) {
    return null;
  }

  const pages = [];
  for (let i = 0; i < totalPages; i++) {
    pages.push(
      <li
        className={'page-item ' + (number === i ? 'active' : '')}
        onClick={() => doPagination(i)}
        key={'page-item-' + i}
      >
        <div className="page-link quickfisco-pagination-text quickfisco-pagination-container">
          {i + 1}
        </div>
      </li>
    );
  }

  return (
    <nav aria-label="Page navigation example" className={'quickfisco-pagination-nav'}>
      <ul className="pagination">
        <li className="page-item">
          <div
            className="page-link"
            aria-label="Previous"
            onClick={() => !first && doPagination(number - 1)}
          >
            <span className={'quickfisco-pagination-text'} aria-hidden="true">&laquo;</span>
          </div>
        </li>
        {pages}
        <li className="page-item">
          <div
            className="page-link"
            aria-label="Next"
            onClick={() => !last && doPagination(number + 1)}
          >
            <span className={'quickfisco-pagination-text'} aria-hidden="true">&raquo;</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}
