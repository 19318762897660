import React from 'react';
import { InvoiceModel } from '../../models/invoiceModel';
import { AutoInvoiceService } from '../../services/autoInvoiceService';
import { formatNumberIT } from '../../utils/number';

interface Props {
  invoice: InvoiceModel
}

export function AutoInvoiceSummary(props: Props) {
  const { invoice } = props;

  const summary = AutoInvoiceService.getSummary(invoice);

  const items: React.ReactNode[] = summary.map(value => {
    return (
      <div key={value.key}>
        <div className={'d-flex justify-content-between mt-2'}>
          <div className={'invoiceAmount-label'}>iva{' '}{value.vatRate}%</div>
          <div className={'invoiceAmount-label'}>
            {formatNumberIT(Number(value.tax))}€
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={'row'}>
      <div className={'col-12'}>
        {items}
        <div className={'invoice-customer-saveOrEdit-line mt-3 mb-3'} />
      </div>
    </div>
  );
}
