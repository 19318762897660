import { AddressModel } from '../models/addressModel';
import { Http } from '../utils/http';

export class AddressService {
  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public searchCity(city: string): Promise<AddressModel[]> {
    return this.http.sendAndReceive({
      method: 'get',
      url: `https://external-data.quickfisco.it/comuni/search-comune?comune=${city}`
    })
      .then(res => res.json())
      .then((data: AddressModel[]) => data);
  }

  // public searchZipCode(zipCode: string): Promise<AddressModel[]> {
  //   return this.http.sendAndReceive({
  //     method: 'get',
  //     url: `https://external-data.quickfisco.it/comuni/search-cap?cap=${zipCode}`
  //   })
  //     .then(res => res.json())
  //     .then((data: AddressModel[]) => data);
  // }
}
