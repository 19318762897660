import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  onChange?: (party?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
}

export function QuickfiscoPoliticalPartiesSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'Selezione il codice del partito politico'}
      options={getOptions()}
      styleType={'default'}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
      isMenuPlacementTop={true}
    />
  );
}
function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined || value === null,
    },
    {
      label: 'Alternativa Popolare',
      value: 'Alternativa Popolare',
      selected: value === 'Alternativa Popolare',
    },
    {
      label: 'Articolo 1 - Movimento Democratico e Progressista',
      value: 'Articolo 1 - Movimento Democratico e Progressista',
      selected: value === 'Articolo 1 - Movimento Democratico e Progressista',
    },
    { label: 'Azione', value: 'Azione', selected: value === 'Azione' },
    { label: 'Cambiamo!', value: 'Cambiamo!', selected: value === 'Cambiamo!' },
    {
      label: 'Centro Democratico',
      value: 'Centro Democratico',
      selected: value === 'Centro Democratico',
    },
    {
      label: 'Democrazia Solidale - Demo. S',
      value: 'Democrazia Solidale - Demo. S',
      selected: value === 'Democrazia Solidale - Demo. S',
    },
    {
      label: 'Federazione dei Verdi',
      value: 'Federazione dei Verdi',
      selected: value === 'Federazione dei Verdi',
    },
    {
      label: 'Fratelli d’Italia - Alleanza Nazionale',
      value: 'Fratelli d’Italia - Alleanza Nazionale',
      selected: value === 'Fratelli d’Italia - Alleanza Nazionale',
    },
    {
      label: 'IDEA - Identità e Azione - popolo e libertà',
      value: 'IDEA - Identità e Azione - popolo e libertà',
      selected: value === 'IDEA - Identità e Azione - popolo e libertà',
    },
    {
      label: 'Italia dei Valori',
      value: 'Italia dei Valori',
      selected: value === 'Italia dei Valori',
    },
    {
      label: 'Italia Viva',
      value: 'Italia Viva',
      selected: value === 'Italia Viva',
    },
    {
      label: 'Lega Nord per l’Indipendenza della Padania',
      value: 'Lega Nord per l’Indipendenza della Padania',
      selected: value === 'Lega Nord per l’Indipendenza della Padania',
    },
    {
      label: 'Lega per Salvini Premier',
      value: 'Lega per Salvini Premier',
      selected: value === 'Lega per Salvini Premier',
    },
    {
      label: 'Movimento Associativo Italiani all’Estero - MAIE',
      value: 'Movimento Associativo Italiani all’Estero - MAIE',
      selected: value === 'Movimento Associativo Italiani all’Estero - MAIE',
    },
    {
      label: 'Movimento politico Forza Italia',
      value: 'Movimento politico Forza Italia',
      selected: value === 'Movimento politico Forza Italia',
    },
    {
      label: 'Movimento - La Puglia in Più',
      value: 'Movimento - La Puglia in Più',
      selected: value === 'Movimento - La Puglia in Più',
    },
    {
      label: 'Noi con l’Italia',
      value: 'Noi con l’Italia',
      selected: value === 'Noi con l’Italia',
    },
    {
      label: 'Partito della Rifondazione Comunista - Sinistra Europea',
      value: 'Partito della Rifondazione Comunista - Sinistra Europea',
      selected:
        value === 'Partito della Rifondazione Comunista - Sinistra Europea',
    },
    {
      label: 'Partito Democratico',
      value: 'Partito Democratico',
      selected: value === 'Partito Democratico',
    },
    {
      label: 'Partito Socialista Italiano',
      value: 'Partito Socialista Italiano',
      selected: value === 'Partito Socialista Italiano',
    },
    { label: '+ Europa', value: '+ Europa', selected: value === '+ Europa' },
    { label: 'Possibile', value: 'Possibile', selected: value === 'Possibile' },
    {
      label: 'Radicali italiani',
      value: 'Radicali italiani',
      selected: value === 'Radicali italiani',
    },
    {
      label: 'Sinistra Italiana',
      value: 'Sinistra Italiana',
      selected: value === 'Sinistra Italiana',
    },
    {
      label: 'Stella Alpina',
      value: 'Stella Alpina',
      selected: value === 'Stella Alpina',
    },
    {
      label: 'Südtiroler Volkspartei',
      value: 'Südtiroler Volkspartei',
      selected: value === 'Südtiroler Volkspartei',
    },
    {
      label: 'Union Valdôtaine',
      value: 'Union Valdôtaine',
      selected: value === 'Union Valdôtaine',
    },
    {
      label: 'UDC - Unione di centro',
      value: 'UDC - Unione di centro',
      selected: value === 'UDC - Unione di centro',
    },
    {
      label: 'Unione per il Trentino',
      value: 'Unione per il Trentino',
      selected: value === 'Unione per il Trentino',
    },
    {
      label: 'Unione Sudamericana Emigrati Italiani',
      value: 'Unione Sudamericana Emigrati Italiani',
      selected: value === 'Unione Sudamericana Emigrati Italiani',
    },
  ];
}
