import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../../config.json';
import { InvoiceFilters, InvoiceStatusFiltersType } from '../../models/invoiceFilters';
import { InvoiceModel } from '../../models/invoiceModel';
import { InvoiceReportModel } from '../../models/invoiceReportModel';
import { PaginationModel } from '../../models/paginationModel';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { InvoiceSupplierService } from '../../services/supplierInvoiceService';
import { PromiseStatuses } from '../../types/strings';

interface SupplierInvoiceListState {
  status: PromiseStatuses;
  invoices: PaginationModel<InvoiceModel>;
  reports: InvoiceReportModel;
  reportsStatus: PromiseStatuses;
  page: number;
  monthFilter?: string;
  yearFilter: string;
  companyNameFilter?: string;
  statusFilter?: InvoiceStatusFiltersType;
}

const initialState: SupplierInvoiceListState = {
  status: 'idle',
  invoices: { content: [] },
  reports: {
    toPayInvoice: 0,
    totalInvoice: 0,
    paidInvoice: 0,
    expiredInvoice: 0,
  },
  reportsStatus: 'idle',
  page: 0,
  yearFilter: new Date().getFullYear().toString()
};

export const getSupplierInvoiceList = createAsyncThunk(
  'supplierInvoiceList/getSupplierInvoiceList',
  async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {
    return new InvoiceSupplierService().findAll(request);
  }
);

export const getSupplierInvoiceReports = createAsyncThunk(
  'supplierInvoiceList/getSupplierInvoiceReports',
  async (request: string): Promise<InvoiceReportModel> => {
    return new InvoiceSupplierService().getReports(request);
  }
);

const supplierInvoiceListSlice = createSlice({
  name: 'supplierInvoiceList',
  initialState,
  reducers: {
    setSupplierInvoiceListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSupplierInvoiceListMonthFilter: (state, action: PayloadAction<string | undefined>) => {
      state.monthFilter = action.payload;
    },
    setSupplierInvoiceListYearFilter: (state, action: PayloadAction<string>) => {
      state.yearFilter = action.payload;
    },
    setSupplierInvoiceListCompanyNameFilter: (state, action: PayloadAction<string | undefined>) => {
      state.companyNameFilter = action.payload;
    },
    setSupplierInvoiceListStatusFilter: (state, action: PayloadAction<InvoiceStatusFiltersType | undefined>) => {
      state.statusFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierInvoiceList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSupplierInvoiceList.fulfilled, (state, action) => {
        let supplierInvoices = CommonInvoiceService.filter(
          'supplier',
          {
            page: state.page,
            month: state.monthFilter,
            year: state.yearFilter,
            companyName: state.companyNameFilter,
            status: state.statusFilter
          },
          action.payload
        );

        supplierInvoices = CommonInvoiceService.sort(supplierInvoices);

        supplierInvoices = CommonInvoiceService.paginate(state.page, config.pageSize, supplierInvoices);

        state.status = 'successfully';
        state.invoices = supplierInvoices;
      })
      .addCase(getSupplierInvoiceList.rejected, ((state) => {
        state.status = 'failed';
      }))
      .addCase(getSupplierInvoiceReports.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(getSupplierInvoiceReports.fulfilled, (state, action) => {
        state.reportsStatus = 'idle';
        state.reports = action.payload;
      })
      .addCase(getSupplierInvoiceReports.rejected, ((state) => {
        state.reportsStatus = 'failed';
      }))
  },
});

export const {
  setSupplierInvoiceListPage,
  setSupplierInvoiceListMonthFilter,
  setSupplierInvoiceListYearFilter,
  setSupplierInvoiceListCompanyNameFilter,
  setSupplierInvoiceListStatusFilter
} = supplierInvoiceListSlice.actions;

export default supplierInvoiceListSlice.reducer;
