import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(window.location.hostname === 'app.quickfisco.it' ? 
  'pk_live_51HXSNFKHPU7pZHpVCOIZQjcNEcntSx1PW74JkW65Rj9Ff1WRkBeF2bw0uwDqjISKcMWnni6M5dEY4JvVM4vr5nzY00WyXbGqHN' :
  'pk_test_51HXSNFKHPU7pZHpV2Zw14QmhatOByzxdWPr1s4A875yjQhFGwAjQHZtTJ19NmTEstVCXYsAaxMPkAt5HTZhdGkWg00o72HvLJB'
);

const getStripe = () => {
  return stripePromise;
};

export default getStripe;