import { useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { formatPaymentNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { UploadBankDoc } from '../uploadBankDoc/uploadBankDoc';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './bankTransfer.css';

interface Props {
  onPayClick: Function;
}

export function BankTransfer(props: Props) {
  const { onPayClick } = props;

  const [copyConfirm, setCopyConfirm] = useState<boolean>(false);
  const [messagge, setMessage] = useState<string>('');

  const paymentState = useAppSelector((state) => state.payment);
  const invoiceState = useAppSelector((state) => state.payment.invoice);
  const userState = useAppSelector((state) => state.user.user);

  return (
    <div className="row">
      <QuickfiscoSuccess
        active={copyConfirm}
        message={messagge}
        close={() => setCopyConfirm(false)}
      />
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <p className="bank-transfer-text-blue">
              Effettua un bonifico di{' '}
              <b>{formatPaymentNumber(Number(invoiceState.finalAmount))}€</b>
            </p>
            <p className="bank-transfer-text">
              <b>Intestato a:</b> Quickfisco S.r.l.
              <QuickfiscoIcon
                name={'copy-button.svg'}
                className={'bring-a-friend-modal-copy-button'}
                onClick={() => {
                  setMessage('INTESTATARIO copiato correttamente');
                  navigator.clipboard.writeText('Quick Fisco S.r.l.');
                  setCopyConfirm(true);
                }}
              />
            </p>
            <p className="bank-transfer-text">
              <b>IBAN:</b> IT74U0511601601000000003400
              <QuickfiscoIcon
                name={'copy-button.svg'}
                className={'bring-a-friend-modal-copy-button'}
                onClick={() => {
                  setMessage('IBAN copiato correttamente');
                  navigator.clipboard.writeText('IT74U0511601601000000003400');
                  setCopyConfirm(true);
                }}
              />
            </p>
            <p className="bank-transfer-text">
              <b>Causale:</b> Abbonamento annuale Quickfisco {userState.name}{' '}
              {userState.surname} {invoiceState.receiptCode}
              <QuickfiscoIcon
                name={'copy-button.svg'}
                className={'bring-a-friend-modal-copy-button'}
                onClick={() => {
                  setMessage('CAUSALE copiata correttamente');
                  navigator.clipboard.writeText(
                    `Abbonamento annuale Quickfisco ${userState.name} ${userState.surname} ${invoiceState.receiptCode}`
                  );
                  setCopyConfirm(true);
                }}
              />
            </p>
          </div>
        </div>
        <div className="row my-5 d-flex justify-content-center">
          <div className="col-7">
            <UploadBankDoc
              label={'Carica contabile bonifico'}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-6 offset-3">
            {paymentState.statusCompletePayment === 'loading' ? (
              <div
                className={
                  'w-100 d-flex justify-content-center align-items-center'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'exceeding-thresholds-modal-request-sent'}
                label={'PAGA'}
                type={!invoiceState.receiptFileId ? 'disabled' : 'senary'}
                onClick={() => onPayClick()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
