import { CategoryType } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFundYearRegistration, setStep } from '../../redux/slices/taxProfileSlice';
import { TaxProfileSteps } from '../../types/strings';
import { resolveUndefinedDate } from '../../utils/date';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileFourthStep } from '../taxProfileFourthStep/taxProfileFourthStep';
import lang from './taxProfileChangeStep.json';

interface Props {
  completeProfile?: Function
  error?: boolean
}

export function TaxProfileChangeStep(props: Props) {
  const { completeProfile } = props;
  const { error } = props;

  const userState = useAppSelector(state => state.user);
  const taxProfileState = useAppSelector(state => state.taxProfile);

  const dispatch = useAppDispatch();

  if (userState.status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;

  let nextStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
    ['confirmData', 'currentYearData'],
    ['currentYearData', 'lastYearData'],
    ['lastYearData', 'completed'],
  ]);

  let previousStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
    ['confirmData', 'vatOpeningDate'],
    ['currentYearData', 'confirmData'],
    ['lastYearData', 'currentYearData'],
    ['completed', 'lastYearData'],
  ]);

  if (
    resolveUndefinedDate(user.vatOpeningDate)
      .getFullYear() === new Date().getFullYear()
  ) {
    nextStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
      ['confirmData', 'currentYearData'],
      ['currentYearData', 'completed'],
    ]);

    previousStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
      ['confirmData', 'vatOpeningDate'],
      ['currentYearData', 'confirmData'],
      ['completed', 'currentYearData'],
    ]);
  }

  if (user.category===CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01")) {
    nextStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
      ['confirmData', 'currentYearData'],
      ['currentYearData', 'lastYearData'],
      ['lastYearData', 'fourthStep'],
      ['fourthStep', 'completed'],
    ]);

    previousStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
      ['confirmData', 'vatOpeningDate'],
      ['currentYearData', 'confirmData'],
      ['lastYearData', 'currentYearData'],
      ['fourthStep', 'lastYearData'],
      ['completed', 'fourthStep'],
    ]);
    if (
      resolveUndefinedDate(user.vatOpeningDate)
        .getFullYear() === new Date().getFullYear()
    ) {
      nextStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
        ['confirmData', 'currentYearData'],
        ['currentYearData', 'fourthStep'],
        ['fourthStep', 'completed'],
      ]);

      previousStepMap = new Map<TaxProfileSteps, TaxProfileSteps>([
        ['confirmData', 'vatOpeningDate'],
        ['currentYearData', 'confirmData'],
        ['fourthStep', 'currentYearData'],
        ['completed', 'fourthStep'],
      ]);
    }
  }

  const nextStep = nextStepMap.get(taxProfileState.step);
  const previousStep = previousStepMap.get(taxProfileState.step);

  return (
    <div className={'row d-flex align-items-end justify-content-between'}>
      <div className={'col-4'}>
        <QuickfiscoButton
          id={'tax-profile-confirm-data-back'}
          label={lang.backLabel}
          onClick={() => {
            if (taxProfileState.step) {
              dispatch(setFundYearRegistration(0))
            }
            dispatch(setStep(previousStep ? previousStep : 'welcome'))
          }}
          type={'primary'}
        />
      </div>
      <div className={'col-4'}>
        <QuickfiscoButton
          id={'tax-profile-confirm-data-continue'}
          label={lang.continueLabel}
          type={'secondary'}
          onClick={() =>
            taxProfileState.step === 'completed' ? completeProfile && completeProfile() : !error ? dispatch(setStep(nextStep ? nextStep : 'welcome')) : validateFundYearRegistration(error)}
        />
      </div>
    </div>
  );
}

function validateFundYearRegistration(error: boolean) {
  return (
    <TaxProfileFourthStep error={error}
    />
  )
}