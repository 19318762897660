import { OnBlur, OnChange } from '../../types/functions';
import './quickfiscoCheckbox.css';

interface Props {
  id: string;
  label: string;
  required?: boolean
  checked?: boolean;
  disabled?: boolean
  error?: boolean;
  link?: string,
  linkLabel?: string
  fontNormal?: boolean
  onBlur?: OnBlur
  onChange?: OnChange;
}

export function QuickfiscoCheckbox(props: Props) {
  const { id } = props;
  const { label } = props;
  const { error } = props;
  const { checked } = props;
  const { required } = props;
  const { disabled } = props;
  const { link } = props;
  const { linkLabel } = props;
  const { fontNormal } = props;
  const { onBlur } = props;
  const { onChange } = props;

  return (
    <div className="w-100 d-flex align-items-center">
      <input
        id={id}
        type={'checkbox'}
        checked={checked}
        disabled={disabled}
        className={'quickfisco-checkbox-input'}
        onChange={(event) => onChange && onChange(event)}
        onBlur={(event) => onBlur && onBlur(event)}
      />
      <div>
        <label
          className={
            'ms-2' +
            (error ? ' quickfisco-checkbox-error' : '') +
            (fontNormal ? ' quickfisco-checkbox-label-normal' : ' quickfisco-checkbox-label')
          }
        >
          <span className={disabled ? 'quickfisco-checkbox-disabled' : ''}>{checked}</span>
          <span>{label}</span>
          {
            link && linkLabel && (
              <a
                href={link}
                target={'_blank'}
                rel={'noreferrer'}
                className={fontNormal ? ' quickfisco-checkbox-label-normal' : ' quickfisco-checkbox-label'}
              >
                {linkLabel}
              </a>
            )
          }
          {required && ' *'}
        </label>
      </div>
    </div>
  );
}
