export interface StsProfileModel {
  id?: string,
  ownerId?: string,
  status?: ProfileStatus,
  identificationCode?: string,
  password?: string,
  pinCode?: string,
  expenseType?: ExpenseType,
  expenseTypeFlag?: ExpenseTypeFlag,
  trackedPayment: boolean
}

export enum ProfileStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_VALID = 'NOT_VALID'
}

export enum ExpenseType {
  TK = 'TK',
  FC = 'FC',
  FV = 'FV',
  SV = 'SV',
  SP = 'SP',
  AD = 'AD',
  AS = 'AS',
  SR = 'SR',
  CT = 'CT',
  PI = 'PI',
  IC = 'IC',
  AA = 'AA'
}

export enum ExpenseTypeFlag {
  TPS = 'TPS',
  VI = 'VI'
}
