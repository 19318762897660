import { useState } from 'react';
import { UserModel } from '../../models/userModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStep } from '../../redux/slices/taxProfileSlice';
import { getMe } from '../../redux/slices/userSlice';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { formatDateUS } from '../../utils/date';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './taxProfileVatOpeningDate.css';
import lang from './taxProfileVatOpeningDate.json';

export function TaxProfileVatOpeningDate() {
  const [editStatus, setEditStatus] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector(state => state.user);

  const [vatOpeningDate, setVatOpeningDate] = useState<Date | undefined>(userState.user && userState.user.vatOpeningDate ? new Date(userState.user.vatOpeningDate) : undefined);

  const dispatch = useAppDispatch();

  if (userState.status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;

  return (
    <div className={'tax-profile-vat-opening-date-container'}>
      <div className={'row'}>
        <div className={'col-12 d-flex justify-content-center'}>
          <span className={'tax-profile-vat-opening-date-text'}>
            {lang.inputDate}
          </span>
        </div>
      </div>
      <div className={'row mt-5'}>
        <div className={'col-12'}>
          <QuickfiscoDatePicker
            id='tax-profile-vat-opening-date-input'
            maxDate={new Date()}
            styleType={'large-center'}
            value={vatOpeningDate ? new Date(vatOpeningDate) : undefined}
            onChange={e => {
              if (!e) {
                setVatOpeningDate(undefined)
              } else {
                setVatOpeningDate(new Date(e));
              }
            }}
          />
        </div>
      </div>
      <div className={'row mt-5 d-flex justify-content-center'}>
        <div className={'col-5'}>
          {
            editStatus === 'loading' ? (
              <div className='text-center'>
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-profile-configurate-vat-opening-date'}
                label={lang.labelDate}
                type={!vatOpeningDate ? 'disabled' : 'secondary'}
                onClick={
                  () => {
                    if (vatOpeningDate) {
                      const newUser: UserModel = {
                        ...user,
                        vatOpeningDate: formatDateUS(vatOpeningDate)
                      };
                      updateMe(newUser, dispatch, setEditStatus);
                    }
                  }
                }
              />
            )
          }
        </div>
      </div>
    </div>
  );

}

function updateMe(
  user: UserModel,
  dispatch: Function,
  setEditStatus: SetState<PromiseStatuses>
) {
  const userService = new UserService();

  setEditStatus('loading');
  userService
    .updateMe(user)
    .then(() => {
      dispatch(getMe());
      dispatch(setStep('confirmData'));
    })
    .catch(err => {
      console.error(err);
      setEditStatus('failed');
    });
}
