import { host } from '../utils/config';
import { Http } from '../utils/http';
import { ObjectService } from './objectService';
import { OnboardingDocumentsErrorSetters, OnboardingFiscalInfoErrorSetters, OnboardingPersonalInfoErrorSetters, UserOnboardingModel, UserRegistrationModel } from '../models/onboardingModel';
import { setErrorAddress, setErrorAteco, setErrorBirthPlace, setErrorBirthProvince, setErrorCity, setErrorDateOfBirth, setErrorDocIdExpirationDate, setErrorDocIdNumber, setErrorDocIdReleasedBy, setErrorDocIdReleasedDate, setErrorDocIdType, setErrorIdDocFileId, setErrorProvince, setErrorTaxCode, setErrorTaxDocFileId, setErrorVat, setErrorZipCode } from '../redux/slices/onboardingSlice';
import { fiscalCodeRegex, vatRegex, zipCodeRegex } from '../regex/regex';

export class CrmIntegrationService {
  private readonly url = host + '/api/v1/crm-integration';
  private readonly http: Http;
  private readonly dispatch?: Function;
  private readonly onboardingPersonalInfoErrorSetters?: OnboardingPersonalInfoErrorSetters;
  private readonly onboardingFiscalInfoErrorSetters?: OnboardingFiscalInfoErrorSetters;
  private readonly onboardingDocumentsErrorSetters?: OnboardingDocumentsErrorSetters;
  private readonly objectService: ObjectService;

  public constructor(
    dispatch?: Function,
    onboardingPersonalInfoErrorSetters?: OnboardingPersonalInfoErrorSetters,
    onboardingFiscalInfoErrorSetters?: OnboardingFiscalInfoErrorSetters,
    onboardingDocumentsErrorSetters?: OnboardingDocumentsErrorSetters,
  ) {
    this.http = Http.getInstance();
    this.dispatch = dispatch;
    this.onboardingPersonalInfoErrorSetters = onboardingPersonalInfoErrorSetters;
    this.onboardingFiscalInfoErrorSetters = onboardingFiscalInfoErrorSetters;
    this.onboardingDocumentsErrorSetters = onboardingDocumentsErrorSetters;

    this.objectService = new ObjectService();
  }

  public getCrmUserData(userId: string): Promise<UserOnboardingModel> {
    return this.http.sendAndReceive({
      url: this.url + '/user/' + userId,
      method: 'get'
    })
      .then(res => res.json())
      .then((data: UserOnboardingModel) => data);
  }

  public validateAddress(address?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorAddress(false);
    this.dispatch && this.dispatch(setErrorAddress(false))

    if (!address || address === '' || address.length > 60) {
      this.onboardingPersonalInfoErrorSetters?.setErrorAddress(true);
      this.dispatch && this.dispatch(setErrorAddress(true))
      return false;
    }

    return true;
  }

  public validateCity(city?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorCity(false);
    this.dispatch && this.dispatch(setErrorCity(false))

    if (!city || city === '' || city.length > 60) {
      this.onboardingPersonalInfoErrorSetters?.setErrorCity(true);
      this.dispatch && this.dispatch(setErrorCity(true))
      return false;
    }

    return true;
  }

  public validateZipCode(zipCode?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorZipCode(false);
    this.dispatch && this.dispatch(setErrorZipCode(false))

    if (!zipCode || !zipCodeRegex.test(zipCode)) {
      this.onboardingPersonalInfoErrorSetters?.setErrorZipCode(true);
      this.dispatch && this.dispatch(setErrorZipCode(true))
      return false;
    }

    return true;
  }

  public validateProvince(province?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorProvince(false);
    this.dispatch && this.dispatch(setErrorProvince(false))

    if (!province || province === '') {
      this.onboardingPersonalInfoErrorSetters?.setErrorProvince(true);
      this.dispatch && this.dispatch(setErrorProvince(true))
      return false;
    }

    return true;
  }

  public validateTaxCode(taxCode?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorTaxCode(false);
    this.dispatch && this.dispatch(setErrorTaxCode(false))

    if (!taxCode || !fiscalCodeRegex.test(taxCode)) {
      this.onboardingPersonalInfoErrorSetters?.setErrorTaxCode(true);
      this.dispatch && this.dispatch(setErrorTaxCode(true))
      return false;
    }

    return true;
  }

  public validateDateOfBirth(dateOfBirth?: Date): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorDateOfBirth(false);
    this.dispatch && this.dispatch(setErrorDateOfBirth(false))

    if (!dateOfBirth) {
      this.onboardingPersonalInfoErrorSetters?.setErrorDateOfBirth(true);
      this.dispatch && this.dispatch(setErrorDateOfBirth(true))
      return false;
    }

    return true;
  }

  public validateBirthPlace(birthPlace?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorBirthPlace(false);
    this.dispatch && this.dispatch(setErrorBirthPlace(false))

    if (!birthPlace || birthPlace === '') {
      this.onboardingPersonalInfoErrorSetters?.setErrorBirthPlace(true);
      this.dispatch && this.dispatch(setErrorBirthPlace(true))
      return false;
    }

    return true;
  }

  public validateBirthProvince(birthProvince?: string): boolean {
    this.onboardingPersonalInfoErrorSetters?.setErrorBirthProvince(false);
    this.dispatch && this.dispatch(setErrorBirthProvince(false))

    if (!birthProvince || birthProvince === '') {
      this.onboardingPersonalInfoErrorSetters?.setErrorBirthProvince(true);
      this.dispatch && this.dispatch(setErrorBirthProvince(true))
      return false;
    }

    return true;
  }

  public validatePersonalInfo(user: UserRegistrationModel): boolean {
    let isValid = true;

    if (!this.validateAddress(user.residence?.address)) {
      isValid = false;
    }
    if (!this.validateCity(user.residence?.city)) {
      isValid = false;
    }
    if (!this.validateZipCode(user.residence?.cap)) {
      isValid = false;
    }
    if (!this.validateProvince(user.residence?.province)) {
      isValid = false;
    }
    if (!this.validateTaxCode(user.taxCode)) {
      isValid = false;
    }
    if (!this.validateDateOfBirth(user.birthDate)) {
      isValid = false;
    }
    if (!this.validateBirthPlace(user.birthPlace)) {
      isValid = false;
    }
    if (!this.validateBirthProvince(user.birthProvince)) {
      isValid = false;
    }

    return isValid;
  }


  public validateAtecos(atecos?: string[]): boolean {
    this.onboardingFiscalInfoErrorSetters?.setErrorAtecos(false);
    this.dispatch && this.dispatch(setErrorAteco(false))

    if (atecos && atecos.length > 1) {
      for (const ateco of atecos) {
        if (!ateco || ateco === 'Seleziona il codice ateco') {
          this.onboardingFiscalInfoErrorSetters?.setErrorAtecos(true);
          this.dispatch && this.dispatch(setErrorAteco(true))
          return false;
        }
      }
    }

    return true;
  }

  public validateVat(vat?: string): boolean {
    this.onboardingFiscalInfoErrorSetters?.setErrorVat(false);
    this.dispatch && this.dispatch(setErrorVat(false))

    if (!vat || vat === '') {
      this.onboardingFiscalInfoErrorSetters?.setErrorVat(true);
      this.dispatch && this.dispatch(setErrorVat(true))
      return false;
    }

    if (vat && !vatRegex.test(vat)) {
      this.onboardingFiscalInfoErrorSetters?.setErrorVat(true);
      this.dispatch && this.dispatch(setErrorVat(true))
      return false;
    }

    return true;

  }

  public validateFiscallInfo(user: UserRegistrationModel, skipVat?: boolean): boolean {
    let isValid = true;

    if (!this.validateAtecos(user.atecos)) {
      isValid = false;
    }
    if (!this.validateVat(user.vat) && skipVat) {
      isValid = false;
    }

    return isValid;
  }

  public validateDocIdType(docIdType?: string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorDocIdType(false);
    this.dispatch && this.dispatch(setErrorDocIdType(false))

    if (!docIdType || docIdType === '') {
      this.onboardingDocumentsErrorSetters?.setErrorDocIdType(true);
      this.dispatch && this.dispatch(setErrorDocIdType(true))
      return false;
    }

    return true;
  }

  public validateDocIdNumber(docIdNumber?: string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorDocIdNumber(false);
    this.dispatch && this.dispatch(setErrorDocIdNumber(false))

    if (!docIdNumber || docIdNumber === '') {
      this.onboardingDocumentsErrorSetters?.setErrorDocIdNumber(true);
      this.dispatch && this.dispatch(setErrorDocIdNumber(true))
      return false;
    }

    return true;
  }

  public validateDocIdReleasedBy(docIdReleasedBy?: string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorDocIdReleasedBy(false);
    this.dispatch && this.dispatch(setErrorDocIdReleasedBy(false))

    if (!docIdReleasedBy || docIdReleasedBy === '') {
      this.onboardingDocumentsErrorSetters?.setErrorDocIdReleasedBy(true);
      this.dispatch && this.dispatch(setErrorDocIdReleasedBy(true))
      return false;
    }

    return true;
  }

  public validateDocIdReleasedDate(docIdReleasedDate?: Date | string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorDocIdReleasedDate(false);
    this.dispatch && this.dispatch(setErrorDocIdReleasedDate(false))

    if (!docIdReleasedDate) {
      this.onboardingDocumentsErrorSetters?.setErrorDocIdReleasedDate(true);
      this.dispatch && this.dispatch(setErrorDocIdReleasedDate(true))
      return false;
    }

    return true;
  }

  public validateDocIdExpirationDate(docIdExpirationDate?: Date | string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorDocIdExpirationDate(false);
    this.dispatch && this.dispatch(setErrorDocIdExpirationDate(false))

    if (!docIdExpirationDate) {
      this.onboardingDocumentsErrorSetters?.setErrorDocIdExpirationDate(true);
      this.dispatch && this.dispatch(setErrorDocIdExpirationDate(true))
      return false;
    }

    return true;
  }

  public validateIdDocFileId(fileId?: string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorIdDocFileId(false);
    this.dispatch && this.dispatch(setErrorIdDocFileId(false))

    if (!fileId || fileId === '') {
      this.onboardingDocumentsErrorSetters?.setErrorIdDocFileId(true);
      this.dispatch && this.dispatch(setErrorIdDocFileId(true))
      return false;
    }

    return true;
  }

  public validateTaxDocFileId(fileId?: string): boolean {
    this.onboardingDocumentsErrorSetters?.setErrorTaxDocFileId(false);
    this.dispatch && this.dispatch(setErrorTaxDocFileId(false))

    if (!fileId || fileId === '') {
      this.onboardingDocumentsErrorSetters?.setErrorTaxDocFileId(true);
      this.dispatch && this.dispatch(setErrorTaxDocFileId(true))
      return false;
    }

    return true;
  }


  public validateDocuments(user: UserRegistrationModel): boolean {
    let isValid = true;

    if (!this.validateDocIdType(user.idDoc?.type)) {
      isValid = false;
    }

    if (!this.validateDocIdNumber(user.idDoc?.number)) {
      isValid = false;
    }

    if (!this.validateDocIdReleasedBy(user.idDoc?.releasedBy)) {
      isValid = false;
    }

    if (!this.validateDocIdReleasedDate(user.idDoc?.releaseAt)) {
      isValid = false;
    }

    if (!this.validateDocIdExpirationDate(user.idDoc?.expiredAt)) {
      isValid = false;
    }

    if (!this.validateIdDocFileId(user.idDoc?.objectId)) {
      isValid = false;
    }

    if (!this.validateTaxDocFileId(user.taxDoc?.objectId)) {
      isValid = false;
    }

    return isValid;
  }
}
