import Select, { CSSObjectWithLabel, GroupBase, SingleValue, StylesConfig } from 'react-select';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { OptionModel } from '../../models/optionModel';
import './quickfiscoSelect.css';

interface Props {
  id: string;
  label?: string;
  styleType: 'default' | 'no-borders' | 'header';
  placeholder?: string;
  options: OptionModel[];
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
  value?: OptionModel
  colorLabel?: string;
  onChange?: ((option: SingleValue<OptionModel>) => void);
  isMenuPlacementTop?: boolean;
}

export function QuickfiscoSelect(props: Props) {
  const { id } = props;
  const { label } = props;
  const { styleType } = props;
  const { placeholder } = props;
  const { options } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;
  const { value } = props;
  const { onChange } = props;
  const { colorLabel } = props;
  const { isMenuPlacementTop } = props;

  const styleDefault = {
    control: (base: CSSObjectWithLabel, optionProps: { isDisabled: boolean }) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      font: 'normal normal normal 12px/22px Poppins',
      width: '100%',
      minHeight: '20px !important',
      height: '27px',
      borderBottom: '1px solid #EBEAEA !important',
      background: optionProps.isDisabled ? 'transparent' :'',
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: 0,
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none'
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      paddding: '0 !important',
      margin: 0
    }),
    option: (base: CSSObjectWithLabel, optionProps: { isFocused: boolean, isSelected: boolean }) => {
      return {
        ...base,
        backgroundColor: optionProps.isSelected
          ? '#DCE2FF'
          : optionProps.isFocused
            ? '#FAFAFF'
            : undefined,
        font: 'normal normal normal 13px/16px Poppins',
        letterSpacing: '0px',
        color: '#000000'
      };
    },
  }

  const styleNoBorder = {
    control: (base: CSSObjectWithLabel, optionProps: { isDisabled: boolean }) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      font: 'normal normal normal 12px/22px Poppins',
      width: '100%',
      minHeight: '20px !important',
      height: '52px',
      padding: '15px',
      borderRadius: '10px',
      alignItems: 'start',
      background: optionProps.isDisabled ? 'transparent' :'',
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: 0,
      height: 'auto'
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none'
    }),
    container: (base: CSSObjectWithLabel) => ({
      ...base,
      boxShadow: '0 4px 10px #00000005'
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      paddding: '0 !important',
      margin: 0
    }),
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: '0px !important',
    }),
    option: (base: CSSObjectWithLabel, optionProps: { isFocused: boolean, isSelected: boolean }) => {
      return {
        ...base,
        backgroundColor: optionProps.isSelected
          ? '#DCE2FF'
          : optionProps.isFocused
            ? '#FAFAFF'
            : undefined,
        font: 'normal normal normal 13px/16px Poppins',
        letterSpacing: '0px',
        color: '#000000'
      };
    },
  }

  const styleHeader = {
    control: (base: CSSObjectWithLabel, optionProps: { isDisabled: boolean }) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      font: 'normal normal normal 15px/22px Poppins',
      width: '80px',
      minHeight: '20px !important',
      height: '37px',
      padding: '7px 8px 8px 8px',
      borderRadius: '4px',
      alignItems: 'start',
      background: optionProps.isDisabled ? 'blue' :'',
      "&:hover": {
        backgroundColor: "#F2F2FC"
      },
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: 0,
      height: 'auto'
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none'
    }),
    container: (base: CSSObjectWithLabel) => ({
      ...base,
      width: '70px !important',
      zIndex: 2
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      paddding: '0 !important',
      margin: 0
    }),
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: '0px !important',
    }),
    option: (base: CSSObjectWithLabel, optionProps: { isFocused: boolean, isSelected: boolean }) => {
      return {
        ...base,
        backgroundColor: optionProps.isSelected
          ? '#FAFAFF'
          : optionProps.isFocused
            ? '#FAFAFF'
            : undefined,
        font: 'normal normal normal 15px/22px Poppins',
        letterSpacing: '0px',
        color: optionProps.isSelected ? '#4643D3' : '#9E9EAC',
        fontWeight: optionProps.isSelected ? '600' : 'normal'
      };
    },
  }

  const styleTypeMap = new Map<string, StylesConfig<OptionModel, false, GroupBase<OptionModel>>>([
    ['default', styleDefault],
    ['no-borders', styleNoBorder],
    ['header', styleHeader],
  ])

  const DropdownIndicator: React.FC = () => {
    if(styleType !== 'header') {
      return <QuickfiscoIcon name={'arrow-down.svg'} />;
    } else {
      return <QuickfiscoIcon name={'arrow-header-user-menu.svg'} />;
    }
  };

  return (
    <div>
      {
        label && <label className={colorLabel ? colorLabel : "quickfisco-input-label"}>{label}{requiredField ? ' *' : null}{disabled && <QuickfiscoIcon name={'block.svg'} className={'ps-2'} />}</label>
      }
      <div className='d-flex align-items-end'>
        <div className={'d-flex align-items-end w-100'}>
          <Select
            id={id}
            className={'w-100'}
            styles={styleTypeMap.get(styleType)}
            placeholder={placeholder}
            options={options}
            onChange={(option) => onChange && onChange(option)}
            value={value}
            defaultValue={value}
            isDisabled={disabled}
            components={{ DropdownIndicator }}
            isSearchable={styleType !== 'header'}
            menuPlacement={isMenuPlacementTop ? 'top' : 'auto'}
          />
        </div>
      </div>
      {error && <div className="quickfisco-select-error mt-2">{errorLabel}</div>}
    </div>
  );
}
