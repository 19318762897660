import './quickfiscoLogo.css';
import { host } from '../../utils/config';
import lang from './quickfiscoLogo.json';

interface Props {
  id: string;
  dim: LogoDim;
}

export enum LogoDim {
  MEDIUM = 'quickfisco-logo-medium',
  SMALL = 'quickfisco-logo-small'
}

export function QuickfiscoLogo(props: Props) {
  const { id } = props;
  const { dim } = props;

  return (
    <div id={id}>
      <img className={dim} src={`${host}/images/logo.svg`} alt={lang.AltImage}/>
    </div>
  );
}
