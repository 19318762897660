import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../../config.json';
import { InvoiceFilters, InvoiceStatusFiltersType } from '../../models/invoiceFilters';
import { InvoiceModel, InvoiceType } from '../../models/invoiceModel';
import { InvoiceReportModel } from '../../models/invoiceReportModel';
import { InvoicesToCashOutModel } from '../../models/invoicesToCashOutModel';
import { PaginationModel } from '../../models/paginationModel';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { StsInvoiceService } from '../../services/stsInvoiceService';
import { PromiseStatuses } from '../../types/strings';

interface CustomerInvoiceListState {
  status: PromiseStatuses;
  invoices: PaginationModel<InvoiceModel>;
  reports: InvoiceReportModel;
  reportsStatus: PromiseStatuses;
  page: number;
  monthFilter?: string;
  yearFilter: string;
  invoicesToCashOutYearFilter: string
  typeFilter?: InvoiceType;
  companyNameFilter?: string;
  statusFilter?: InvoiceStatusFiltersType;
  invoiceToCashOut: PaginationModel<InvoiceModel>
  statusInvoiceToCashOut: PromiseStatuses
  statusInvoiceToCashOutInfo: PromiseStatuses
  invoiceToCashOutInfo: InvoicesToCashOutModel;
  isStsFilter?: boolean;
}

const initialState: CustomerInvoiceListState = {
  status: 'idle',
  invoices: { content: [] },
  reports: {
    toPayInvoice: 0,
    totalInvoice: 0,
    paidInvoice: 0,
    expiredInvoice: 0
  },
  reportsStatus: 'idle',
  page: 0,
  yearFilter: new Date().getFullYear().toString(),
  invoicesToCashOutYearFilter: new Date().getFullYear().toString(),
  statusInvoiceToCashOut: 'idle',
  statusInvoiceToCashOutInfo: 'idle',
  invoiceToCashOutInfo: {
    invoicesToCashOut: 0,
    amountToCashOut: 0
  },
  invoiceToCashOut: { content: [] }
};

export const getCustomerInvoiceList = createAsyncThunk(
  'customerInvoiceList/getCustomerInvoiceList',
  async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {
    if (request.isSts === true) {
      return await new StsInvoiceService().findAll(request);
    } else {
      return await new CustomerInvoiceService().findAll(request);
    }
  }
);

export const getCustomerInvoiceReports = createAsyncThunk(
  'customerInvoiceList/getCustomerInvoiceReports',
  async (request: string): Promise<InvoiceReportModel> => {
    return await new CustomerInvoiceService().getReports(request);
  }
);

export const getInvoicesToCashOut = createAsyncThunk(
  'getInvoices/getInvoicesToCashOut',
  async (request: InvoiceFilters): Promise<PaginationModel<InvoiceModel>> => {

    return new CustomerInvoiceService().getInvoicesToCashOut();
  }
);

export const getInvoicesToCashOutInfo = createAsyncThunk(
  'getInvoices/getInvoicesToCashOutInfo',
  async (request: InvoiceFilters): Promise<InvoicesToCashOutModel> => {

    return new CustomerInvoiceService().getInvoicesToCashOutInfo();
  }
);


const customerInvoiceListSlice = createSlice({
  name: 'customerInvoiceList',
  initialState,
  reducers: {
    setCustomerInvoiceListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCustomerInvoiceListMonthFilter: (state, action: PayloadAction<string | undefined>) => {
      state.monthFilter = action.payload;
    },
    setCustomerInvoiceListYearFilter: (state, action: PayloadAction<string>) => {
      state.yearFilter = action.payload;
    },
    setInvoicesToCashOutYearFilter: (state, action: PayloadAction<string>) => {
      state.invoicesToCashOutYearFilter = action.payload;
    },
    setCustomerInvoiceListTypeFilter: (state, action: PayloadAction<InvoiceType | undefined>) => {
      state.typeFilter = action.payload;
    },
    setCustomerInvoiceListCompanyNameFilter: (state, action: PayloadAction<string | undefined>) => {
      state.companyNameFilter = action.payload;
    },
    setCustomerInvoiceListStatusFilter: (state, action: PayloadAction<InvoiceStatusFiltersType | undefined>) => {
      state.statusFilter = action.payload;
    },
    setCustomerInvoiceListIsStsFilter: (state, action: PayloadAction<boolean | undefined>) => {
      state.isStsFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerInvoiceList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerInvoiceList.fulfilled, (state, action) => {
        let customerAndStsInvoices = CommonInvoiceService.filter(
          'customer',
          {
            page: state.page,
            month: state.monthFilter,
            year: state.yearFilter,
            type: state.typeFilter,
            companyName: state.companyNameFilter,
            status: state.statusFilter
          },
          action.payload
        );

        customerAndStsInvoices = CommonInvoiceService.sort(customerAndStsInvoices);

        customerAndStsInvoices = CommonInvoiceService.paginate(state.page, config.pageSize, customerAndStsInvoices);

        state.status = 'idle';
        state.invoices = customerAndStsInvoices;
      })
      .addCase(getCustomerInvoiceList.rejected, ((state) => {
        state.status = 'failed';
      }))
      .addCase(getCustomerInvoiceReports.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(getCustomerInvoiceReports.fulfilled, (state, action) => {
        state.reportsStatus = 'idle';
        state.reports = action.payload;
      })
      .addCase(getCustomerInvoiceReports.rejected, ((state) => {
        state.reportsStatus = 'failed';
      }))
      .addCase(getInvoicesToCashOut.pending, (state) => {
        state.statusInvoiceToCashOut = 'loading';
      })
      .addCase(getInvoicesToCashOut.fulfilled, (state, action) => {
        let customerInvoice = CommonInvoiceService.filter(
          'customer',
          {
            page: state.page,
            month: state.monthFilter,
            year: state.invoicesToCashOutYearFilter,
            type: state.typeFilter,
            companyName: state.companyNameFilter,
            status: state.statusFilter
          },
          action.payload
        );

        state.statusInvoiceToCashOut = 'idle';
        state.invoiceToCashOut = customerInvoice;
      })
      .addCase(getInvoicesToCashOut.rejected, ((state) => {
        state.statusInvoiceToCashOut = 'failed';
      }))
      .addCase(getInvoicesToCashOutInfo.pending, (state) => {
        state.statusInvoiceToCashOutInfo = 'loading';
      })
      .addCase(getInvoicesToCashOutInfo.fulfilled, (state, action) => {
        state.statusInvoiceToCashOutInfo = 'idle';
        state.invoiceToCashOutInfo = action.payload;
      })
      .addCase(getInvoicesToCashOutInfo.rejected, ((state) => {
        state.statusInvoiceToCashOutInfo = 'failed';
      }))
  },
});

export const {
  setCustomerInvoiceListPage,
  setCustomerInvoiceListMonthFilter,
  setCustomerInvoiceListYearFilter,
  setCustomerInvoiceListTypeFilter,
  setCustomerInvoiceListCompanyNameFilter,
  setCustomerInvoiceListStatusFilter,
  setCustomerInvoiceListIsStsFilter,
  setInvoicesToCashOutYearFilter
} = customerInvoiceListSlice.actions;

export default customerInvoiceListSlice.reducer;
