import './quickfiscoButton.css';

interface Props {
  id: string;
  label: string;
  onClick?: Function;
  bold?: boolean;
  type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'quinary'
    | 'senary'
    | 'disabled'
    | 'septarian'
    | 'eighth'
    | 'ninth';
}

export function QuickfiscoButton(props: Props) {
  const { id } = props;
  const { type } = props;
  const { label } = props;
  const { onClick } = props;
  const { bold } = props;

  const cssTypeMap = new Map<string, string>([
    ['primary', 'quickfisco-button-primary'],
    ['secondary', 'quickfisco-button-secondary'],
    ['tertiary', 'quickfisco-button-tertiary'],
    ['quaternary', 'quickfisco-button-quaternary'],
    ['quinary', 'quickfisco-button-quinary'],
    ['senary', 'quickfisco-button-senary'],
    ['septarian', 'quickfisco-button-septarian'],
    ['eighth', 'quickfisco-button-eighth'],
    ['ninth', 'quickfisco-button-ninth'],
    ['disabled', 'quickfisco-button-disabled'],
  ]);

  const cssBoldMap = new Map<boolean | undefined, string>([
    [true, 'fw-bold'],
    [false, 'fw-normal'],
  ]);

  return (
    <button
      id={id}
      className={
        'quickfisco-button ' + cssTypeMap.get(type) + ' ' + cssBoldMap.get(bold)
      }
      onClick={(event) => onClick && type !== 'disabled' && onClick(event)}
    >
      {label}
    </button>
  );
}
