import { SingleValue } from "react-select";
import { AdditionalDataPAType } from "../../models/invoiceModel";
import { OptionModel } from "../../models/optionModel";
import { QuickfiscoSelect } from "../quickfiscoSelect/quickfiscoSelect";

interface Props {
    id: string;
    value?: string;
    disabled?: boolean;
    onChange?: ((value?: AdditionalDataPAType) => void);
}

export function AdditionalDataPATypeSelect(props: Props) {
    const { id } = props;
    const { value } = props;
    const { disabled } = props;
    const { onChange } = props;

    return (
        <QuickfiscoSelect
            id={id}
            label={'Emesso in seguito a'}
            styleType={'default'}
            disabled={disabled}
            options={getOptions(value)}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {
                    // @ts-ignore
                    onChange && onChange(AdditionalDataPAType[e.value])
                }
            }}
            value={getOptions(value).filter(option => option.selected === true)[0]}
        />
    );
}

function getOptions(value?: string): OptionModel[] {
    return [
        {
            value: undefined,
            label: 'Seleziona (opzionale)',
            selected: value === undefined
        },
        {
            value: AdditionalDataPAType.PURCHASE_ORDER,
            label: "Ordine d'acquisto",
            selected: value === AdditionalDataPAType.PURCHASE_ORDER
        },
        {
            value: AdditionalDataPAType.CONVENTION,
            label: "Convenzione",
            selected: value === AdditionalDataPAType.CONVENTION
        },
        {
            value: AdditionalDataPAType.CONTRACT,
            label: "Contratto",
            selected: value === AdditionalDataPAType.CONTRACT
        }
    ];
}
