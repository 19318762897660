import lang from './exceedingThresholds100k.json';
import './exceedingThresholds100k.css';

export function ExceedingThresholds100k() {
  return (
    <div className={'exceeding-thresholds-100k-container mt-4'}>
      <span className={'exceeding-thresholds-100k-red-bold'}>
        {lang.boldRedText}
      </span>
      {lang.text1}
      <span className={'exceeding-thresholds-100k-black-bold'}>
        {lang.boldBlackText}
      </span>
    </div>
  );
}
