import { useEffect, useState } from 'react';
import config from '../../../config.json';
import { TaxDeadlineModel, TaxDeadlineStatus } from '../../models/taxDeadlineModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { editTaxCalculation, getTaxCalculation } from '../../redux/slices/taxCalculationSlice';
import { formatDateIT } from '../../utils/date';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './taxEditDeadlinePsychologists.json';

interface Props {
    index: number
    deadline: TaxDeadlineModel;
}

interface DeadlineAttributes {
    minimalContribution?: string,
    variablesContributionBalance?: string,
    variablesContributionDeposit?: string,
    taxBalance?: string,
    taxDeposit?: string,
    contributionBalance?: string,
    contributionDeposit?: string,
}

export function TaxEditDeadlinePsychologists(props: Props) {
    const { index } = props;
    const { deadline } = props;

    const taxCalculationState = useAppSelector(state => state.taxCalculation);

    const dispatch = useAppDispatch();

    const [totalAmount, setTotalAmount] = useState<number>(parseNumber(formatNumberIT(deadline.totalAmount)));
    const [taxAmount, setTaxAmount] = useState<number>(parseNumber(formatNumberIT(deadline.taxAmount)));
    const [taxBalance, setTaxBalance] = useState<number>(parseNumber(formatNumberIT(deadline.taxBalance)));
    const [taxDeposit, setTaxDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.taxDeposit)));
    const [contributionAmount, setContributionAmount] = useState<number>(parseNumber(formatNumberIT(deadline.contributionAmount)));
    const [contributionDeposit, setContributionDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.contributionDeposit)));

    useEffect(() => {
        setTaxAmount(taxBalance + taxDeposit);
        setContributionAmount(contributionDeposit);
        setTotalAmount(contributionDeposit + taxBalance + taxDeposit);
    }, [contributionDeposit, taxDeposit, taxBalance]);

    const deadlineYear = new Date(deadline.expirationDate).getFullYear()

    const dataVisualizeMap: Map<number, DeadlineAttributes> = new Map([
        [
            0,
            {
                variablesContributionBalance: 'Acconto'
            }
        ],
        [
            1,
            {
                taxBalance: 'Saldo ' + deadlineYear,
                taxDeposit: 'Acconto Rata 1 '
            }
        ],
        [
            2,
            {
                variablesContributionBalance: 'Saldo'
            }
        ],
        [
            3,
            {
                taxDeposit: 'Acconto Rata 2 ' + deadlineYear
            }
        ],
    ])

    return (
        <div key={'tax-forecast-edit-deadline' + deadline.id}>
            <div className={'row mt-3'}>
                <div className={'col-12'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <span className={'tax-forecast-deadline-text fw-normal'}>
                                {lang.expiration}
                            </span>
                        </div>
                    </div>
                    <div className={'row d-flex justify-content-between align-items-end'}>
                        <div className={'col-auto'}>
                            <span className={'tax-forecast-deadline-date'}>
                                {deadline.expirationDate && formatDateIT(deadline.expirationDate)}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <span className={'tax-forecast-deadline-amount'}>
                                {formatNumberIT(totalAmount)}{config.EUR}
                            </span>
                        </div>
                    </div>
                    {
                        dataVisualizeMap.get(index)?.variablesContributionBalance && (
                            <div>

                                <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {lang.contributions}
                                        </span>
                                    </div>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {formatNumberIT(contributionDeposit)}{config.EUR}
                                        </span>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'tax-forecast-edit-deadline-line'} />
                                    </div>
                                </div>
                                <div className={'row mt-3'}>
                                    <div className={'col-12'}>
                                        <QuickfiscoInput
                                            id='tax-edit-deadline-artisan-and-traders-minimal-contribution-input'
                                            key={'input-minimal-contribution-' + deadline.id}
                                            styleType={'default'}
                                            type={'text'}
                                            label={dataVisualizeMap.get(index)?.variablesContributionBalance}
                                            placeholder={lang.placeholder}
                                            defaultValue={formatNumberIT(contributionDeposit)}
                                            onChange={e => { setContributionDeposit(parseNumber(e.target.value)) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (
                            dataVisualizeMap.get(index)?.taxDeposit
                        ) && (
                            <div>
                                <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {lang.tax}
                                        </span>
                                    </div>
                                    <div className={'col-auto'}>
                                        <span className={'tax-forecast-deadline-text'}>
                                            {formatNumberIT(taxAmount)}{config.EUR}
                                        </span>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <div className={'tax-forecast-edit-deadline-line'} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.taxBalance && (
                            <div className={'row mt-3'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-variables-contribution-balance-input'
                                        key={'input-variables-contribution-balance' + deadline.id}
                                        type={'text'}
                                        label={dataVisualizeMap.get(index)?.taxBalance}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(taxBalance)}
                                        styleType={'default'}
                                        onChange={e => setTaxBalance(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        dataVisualizeMap.get(index)?.taxDeposit && (
                            <div className={'row mt-2'}>
                                <div className={'col-12'}>
                                    <QuickfiscoInput
                                        id='tax-edit-deadline-artisan-and-traders-variables-contribution-deposit-input'
                                        key={'input-variables-contribution-deposit' + deadline.id}
                                        type={'text'}
                                        styleType={'default'}
                                        label={dataVisualizeMap.get(index)?.taxDeposit}
                                        placeholder={lang.placeholder}
                                        defaultValue={formatNumberIT(taxDeposit)}
                                        onChange={e => setTaxDeposit(parseNumber(e.target.value))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className={'row mt-3 d-flex justify-content-between align-items-center'}>
                        <div className={'col-auto'}>
                            {
                                deadline.status === TaxDeadlineStatus.VALID ? (
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <span className={'tax-forecast-deadline-text-valid'}>
                                                {lang.valid}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <span className={'tax-forecast-deadline-text-not-valid'}>
                                                {lang.notValid}
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={'col-3'}>
                            {
                                taxCalculationState.editStatus === 'loading' ? (
                                    <QuickfiscoSpinner />
                                ) : (
                                    <QuickfiscoButton
                                        id={'save-tax-calculation-deadline'}
                                        label={lang.buttonLabel}
                                        type={'primary'}
                                        key={'edit-deadline' + deadline.id}
                                        onClick={() => dispatch(editTaxCalculation({
                                            contributionDeposit,
                                            contributionBalance: 0,
                                            taxDeposit,
                                            taxBalance,
                                            id: deadline.id,
                                            expirationDate: deadline.expirationDate,
                                            totalAmount,
                                            status: deadline.status,
                                            taxAmount,
                                            contributionAmount,
                                            minimalContribution: 0,
                                            variablesContributionAmount: 0,
                                            variablesContributionBalance: 0,
                                            variablesContributionDeposit: 0
                                        }))
                                        }
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
