import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TotalRevenuesModel } from "../../models/totalRevenuesModel";
import { CustomerInvoiceService } from "../../services/customerInvoiceService";
import { FeesRegisterService } from "../../services/feesRegisterService";
import { StsInvoiceService } from "../../services/stsInvoiceService";
import { PromiseStatuses } from "../../types/strings";

export interface TotalRevenuesState {
    revenues?: TotalRevenuesModel
    stsRevenues?: TotalRevenuesModel
    feesRevenues?: TotalRevenuesModel
    status: PromiseStatuses
    statusStsRevenues: PromiseStatuses
    statusFeesRevenues: PromiseStatuses
    yearFilter: number
}

const initialState: TotalRevenuesState = {
    status: 'idle',
    statusStsRevenues: 'idle',
    statusFeesRevenues: 'idle',
    yearFilter: new Date().getFullYear()
}

export const getCustomerTotalRevenues = createAsyncThunk(
    'totalRevenues/getCustomerTotalRevenues',
    async (year: number): Promise<TotalRevenuesModel> => {
        return new CustomerInvoiceService().getTotalRevenues(year)
    }
)

export const getStsTotalRevenues = createAsyncThunk(
    'totalRevenues/getStsTotalRevenues',
    async (year: number): Promise<TotalRevenuesModel> => {
        return new StsInvoiceService().getTotalRevenues(year)
    }
)

export const getFeesTotalRevenues = createAsyncThunk(
    'totalRevenues/getfeesTotalRevenues',
    async (year: number): Promise<TotalRevenuesModel> => {
        return new FeesRegisterService().getTotalRevenues(year)
    }
)


const totalRevenuesSlice = createSlice({
    name: 'totalRevenues',
    initialState,
    reducers: {
        setTotalRevenuesYearFilter: (state, action: PayloadAction<number>) => {
            state.yearFilter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerTotalRevenues.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getCustomerTotalRevenues.fulfilled, (state, action) => {
                state.status = 'successfully'
                state.revenues = action.payload
            })
            .addCase(getCustomerTotalRevenues.rejected, (state) => {
                state.status = 'failed'
            })
            .addCase(getStsTotalRevenues.pending, (state) => {
                state.statusStsRevenues = 'loading'
            })
            .addCase(getStsTotalRevenues.fulfilled, (state, action) => {
                state.statusStsRevenues = 'successfully'
                state.stsRevenues = action.payload
            })
            .addCase(getStsTotalRevenues.rejected, (state) => {
                state.statusStsRevenues = 'failed'
            })
            .addCase(getFeesTotalRevenues.pending, (state) => {
                state.statusFeesRevenues = 'loading'
            })
            .addCase(getFeesTotalRevenues.fulfilled, (state, action) => {
                state.statusFeesRevenues = 'successfully'
                state.feesRevenues = action.payload
            })
            .addCase(getFeesTotalRevenues.rejected, (state) => {
                state.statusFeesRevenues = 'failed'
            })
    },
})

export const {
    setTotalRevenuesYearFilter
} = totalRevenuesSlice.actions;

export default totalRevenuesSlice.reducer

