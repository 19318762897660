import './invoiceReport.css';
import { InvoiceStatusFiltersType, StsInvoiceStatusFilterType } from '../../models/invoiceFilters';
import { InvoiceType } from '../../models/invoiceModel';

interface Props {
  title: string,
  amount?: number
  selected?: boolean,
  color: InvoiceLRColor,
  typeFilter?: InvoiceType,
  statusFilter?: InvoiceStatusFiltersType,
  stsStatusFilter?: StsInvoiceStatusFilterType,
  setTypeFilter?: (type?: InvoiceType) => void,
  setStatusFilter?: (status?: InvoiceStatusFiltersType) => void,
  setStsStatusFilter?: (status?: StsInvoiceStatusFilterType) => void,
}

export enum InvoiceLRColor {
  BLACK = 'invoice-list-resume-black',
  BLUE = 'invoice-list-resume-blue',
  YELLOW = 'invoice-list-resume-yellow',
  RED = 'invoice-list-resume-red',
  PURPLE = 'invoice-list-resume-purple'
}

export function InvoiceReport(props: Props) {
  const { title } = props;
  const { color } = props;
  const { amount } = props;
  const { selected } = props;
  const { typeFilter } = props;
  const { statusFilter } = props;
  const { stsStatusFilter } = props;
  const { setTypeFilter } = props;
  const { setStatusFilter } = props;
  const { setStsStatusFilter } = props;

  return (
    <div
      className={
        selected === true ? (
          'row d-flex align-items-center invoice-list-resume-border'
        ) : (
          'row d-flex align-items-center invoice-list-resume-container'
        )
      }
      onClick={() => {
        setTypeFilter && (!selected ? setTypeFilter(typeFilter) : setTypeFilter(undefined));
        setStatusFilter && (!selected ? setStatusFilter(statusFilter) : setStatusFilter(undefined));
        setStsStatusFilter && (!selected ? setStsStatusFilter(stsStatusFilter) : setStsStatusFilter(undefined));
      }}
    >
      <div className={'col-8 pe-0'}>
        <div className={'invoice-list-resume-title'}>
          {title}
        </div>
      </div>
      <div className={'col-4'}>
        <div className={color}>{amount}</div>
      </div>
    </div>
  );
}
