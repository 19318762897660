import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseStatuses } from '../../types/strings';
import { CrmIntegrationService } from '../../services/crmIntegrationService';
import { UserOnboardingModel } from '../../models/onboardingModel';

interface OnboardingState {
  statusCrmUserData: PromiseStatuses;
  user: UserOnboardingModel;
  errorAddress: boolean;
  errorCity: boolean;
  errorZipCode: boolean;
  errorProvince: boolean;
  errorTaxCode: boolean;
  errorDateOfBirth: boolean;
  errorBirthPlace: boolean;
  errorBirthProvince: boolean;
  errorAteco: boolean;
  errorDocIdType: boolean;
  errorDocIdNumber: boolean;
  errorDocIdReleasedBy: boolean;
  errorDocIdReleasedDate: boolean;
  errorDocIdExpirationDate: boolean;
  errorIdDocFileId: boolean;
  errorTaxDocFileId: boolean;
  errorVat: boolean;
}

const initialState: OnboardingState = {
  statusCrmUserData: 'idle',
  user: {
    name: '',
    email: '',
  },
  errorAddress: false,
  errorCity: false,
  errorZipCode: false,
  errorProvince: false,
  errorTaxCode: false,
  errorDateOfBirth: false,
  errorBirthPlace: false,
  errorBirthProvince: false,
  errorAteco: false,
  errorDocIdType: false,
  errorDocIdNumber: false,
  errorDocIdReleasedBy: false,
  errorDocIdReleasedDate: false,
  errorDocIdExpirationDate: false,
  errorIdDocFileId: false,
  errorTaxDocFileId: false,
  errorVat: false,
};

export const getCrmUserData = createAsyncThunk(
  'onboarding/getCrmUserData',
  async (userId: string): Promise<UserOnboardingModel> => {
    return await new CrmIntegrationService().getCrmUserData(userId);
  }
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setErrorAddress: (state, action: PayloadAction<boolean>) => {
      state.errorAddress = action.payload
    },
    setErrorCity: (state, action: PayloadAction<boolean>) => {
      state.errorCity = action.payload
    },
    setErrorZipCode: (state, action: PayloadAction<boolean>) => {
      state.errorZipCode = action.payload
    },
    setErrorProvince: (state, action: PayloadAction<boolean>) => {
      state.errorProvince = action.payload
    },
    setErrorTaxCode: (state, action: PayloadAction<boolean>) => {
      state.errorTaxCode = action.payload
    },
    setErrorDateOfBirth: (state, action: PayloadAction<boolean>) => {
      state.errorDateOfBirth = action.payload
    },
    setErrorBirthPlace: (state, action: PayloadAction<boolean>) => {
      state.errorBirthPlace = action.payload
    },
    setErrorBirthProvince: (state, action: PayloadAction<boolean>) => {
      state.errorBirthProvince = action.payload
    },
    setErrorAteco: (state, action: PayloadAction<boolean>) => {
      state.errorAteco = action.payload
    },
    setErrorDocIdType: (state, action: PayloadAction<boolean>) => {
      state.errorDocIdType = action.payload
    },
    setErrorDocIdNumber: (state, action: PayloadAction<boolean>) => {
      state.errorDocIdNumber = action.payload
    },
    setErrorDocIdReleasedBy: (state, action: PayloadAction<boolean>) => {
      state.errorDocIdReleasedBy = action.payload
    },
    setErrorDocIdReleasedDate: (state, action: PayloadAction<boolean>) => {
      state.errorDocIdReleasedDate = action.payload
    },
    setErrorDocIdExpirationDate: (state, action: PayloadAction<boolean>) => {
      state.errorDocIdExpirationDate = action.payload
    },
    setErrorIdDocFileId: (state, action: PayloadAction<boolean>) => {
      state.errorIdDocFileId = action.payload
    },
    setErrorTaxDocFileId: (state, action: PayloadAction<boolean>) => {
      state.errorTaxDocFileId = action.payload
    },
    setErrorVat: (state, action: PayloadAction<boolean>) => {
      state.errorVat = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrmUserData.pending, (state) => {
        state.statusCrmUserData = 'loading';
      })
      .addCase(getCrmUserData.fulfilled, (state, action) => {
        state.statusCrmUserData = 'idle';
        state.user = action.payload;
      })
      .addCase(getCrmUserData.rejected, ((state) => {
        state.statusCrmUserData = 'failed';
      }))
  },
});

export const {
  setErrorAddress,
  setErrorCity,
  setErrorZipCode,
  setErrorProvince,
  setErrorTaxCode,
  setErrorDateOfBirth,
  setErrorBirthPlace,
  setErrorBirthProvince,
  setErrorAteco,
  setErrorDocIdType,
  setErrorDocIdNumber,
  setErrorDocIdReleasedBy,
  setErrorDocIdReleasedDate,
  setErrorDocIdExpirationDate,
  setErrorIdDocFileId,
  setErrorTaxDocFileId,
  setErrorVat,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
