import { MessagesFilters } from '../models/messagesFilters';
import { MessagesModel } from '../models/messagesModel';
import { MessagesReportModel } from '../models/messagesReportModel';
import { MessagesDocumentsUnreadcount } from '../models/messagesDocumentsUnreadcount';
import { PaginationModel } from '../models/paginationModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';
import { ObjectService } from './objectService';
import { toFile } from '../utils/file';

export class MessagesService {
  private readonly url = host + '/api/v1/messages-documents/messages';
  private readonly http: Http;
  private readonly objectService: ObjectService;

  public constructor() {
    this.http = Http.getInstance();
    this.objectService = new ObjectService();
  }

  public getReports(): Promise<MessagesReportModel> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/report'
    })
      .then(res => res.json())
      .then((data: MessagesReportModel) => data);
  }

  public getUnreadCounters(): Promise<MessagesDocumentsUnreadcount> {
    return this.http.sendAndReceive({
      method: 'get',
      url: host + '/api/v1/messages-documents/unread-count'
    })
      .then(res => res.json())
      .then((data: MessagesDocumentsUnreadcount) => data);
  }

  public findAll(filters: MessagesFilters): Promise<PaginationModel<MessagesModel>> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url +
        (filters.type !== undefined ? '?filterType=' + filters.type : '') +
        (filters.page !== undefined ? `${filters.type !== undefined ? '&' : '?'}page=` + filters.page : '')
    })
      .then(res => res.json())
      .then((data: PaginationModel<MessagesModel>) => data)
  }

  public findById(userId?: string): Promise<MessagesModel> {
    return this.http.sendAndReceive({
      url: this.url + '/' + userId,
      method: 'get'
    })
      .then(res => res.json())
      .then((data: MessagesModel) => data);
  }

  public markAsRead(userId?: string): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/mark_as_read/' + userId,
      method: 'put'
    })
      .then(() => undefined);
  }

  public markAsUnread(userId?: string): Promise<void> {
    return this.http.sendAndReceive({
      url: this.url + '/mark_as_unread/' + userId,
      method: 'put'
    })
      .then(() => undefined);
  }

  public del(id: string): Promise<void> {
    return this.http.sendAndReceive({
      method: 'delete',
      url: this.url + '/' + id
    })
      .then(() => undefined);
  }

  public async getFiles(documents: string[]): Promise<File[]> {
    const fileList: File[] = [];

    for (let document of documents) {
      const object = await this.objectService.findById(document);
      const arrayBuffer = await this.objectService.findFile(document);

      fileList.push(toFile(arrayBuffer, object));
    }

    return fileList;
  }
}
