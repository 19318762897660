import { useEffect, useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import {
  BuildingsTaxReturnModel,
  LandsTaxReturnModel,
  SectionTaxReturn3,
} from '../../models/taxReturnModel';
import { TaxReturnService } from '../../services/taxReturnService';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { LandsBuildingsAccordion } from '../landsBuildingsAccordion/landsBuildingsAccordion';
import { LandsBuildingsAddButton } from '../landsBuildingsAddButton/landsBuildingsAddButton';
import { LandsBuildingsAnswerInput } from '../landsAnswerInput/landsBuildingsAnswerInput';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './taxReturnSection3.json';
import './taxReturnSection3.css';

interface Props {
  onClickNextStep: (data: SectionTaxReturn3) => void;
  onClickPrevStep: (data: SectionTaxReturn3) => void;
  nextButtonStatus: PromiseStatuses;
  refreshApiCall: (scrollPosition: number, skyScroll?: boolean) => void;
}

export function TaxReturnSection3(props: Props) {
  const { onClickNextStep } = props;
  const { onClickPrevStep } = props;
  const { nextButtonStatus } = props;
  const { refreshApiCall } = props;

  const taxReturnService = new TaxReturnService();

  const [loadingStatus, setLoadingStatus] = useState<PromiseStatuses>('idle');
  const [showAddLand, setShowAddLand] = useState<boolean>(false);
  const [showAddBuildings, setShowAddBuildings] = useState<boolean>(false);
  const [landsAnswer, setLandsAnswer] = useState<LandsTaxReturnModel[]>([]);
  const [buildingsAnswer, setBuildingsAnswer] = useState<
    BuildingsTaxReturnModel[]
  >([]);

  const [checkgoNext, setCheckgoNext] = useState<boolean>(false);

  const getSurveyStep = (): void => {
    const service = new TaxReturnService();

    setLoadingStatus('loading');
    service
      .getSurveyStep(3)
      .then((res) => {
        const data = res as SectionTaxReturn3;
        if (Object.keys(data).length === 0) {
          setLoadingStatus('loading');
          service
            .getLandsBuildings()
            .then((res) => {
              res.landsAnswer && setLandsAnswer(res.landsAnswer);
              res.buildingsAnswer && setBuildingsAnswer(res.buildingsAnswer);
              setLoadingStatus('successfully');
            })
            .catch((err) => {
              console.error(err);
              setLoadingStatus('failed');
            });
        } else {
          data.landsAnswer && setLandsAnswer(data.landsAnswer);
          data.buildingsAnswer && setBuildingsAnswer(data.buildingsAnswer);
          setLoadingStatus('successfully');
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    getSurveyStep();
    refreshApiCall(0, true);
  }, []);

  useEffect(() => {
    setCheckgoNext(taxReturnService.validateSection3(dataToSend));
  }, [landsAnswer, buildingsAnswer]);

  const dataToSend: SectionTaxReturn3 = {
    landsAnswer: landsAnswer[0] ? landsAnswer : undefined,
    buildingsAnswer: buildingsAnswer[0] ? buildingsAnswer : undefined,
  };

  return (
    <div className="row no-gutters p-5">
      <div className="col-12">
        <div className="row mb-4 text-center">
          <div className="col-12 tax-return-section-3-title">{lang.title}</div>
        </div>
        {loadingStatus === 'loading' && (
          <div className="row d-flex justify-content-center mb-4">
            <div className="col-auto py-3 px-4 d-flex mt-3 align-items-center tax-return-section-3-confirm-container">
              <QuickfiscoIcon
                name={'alert-yellow-small.svg'}
                className="quickfisco-input-cursor-pointer me-3"
              />
              <span>
                <strong>{lang.loadingMessageBold}</strong>&nbsp;
                {lang.loadingMessageNormal}
              </span>
            </div>
          </div>
        )}
        <div className="row mb-4">
          <div className="col-12 tax-return-section-3-question">
            {lang.landsQuestion}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <LandsBuildingsAccordion type="lands" />
          </div>
        </div>
        {loadingStatus === 'loading' ? (
          <div className="d-flex align-items-center justify-content-center my-5 py-5">
            <QuickfiscoSpinner />
          </div>
        ) : (
          <>
            {(showAddLand === true && landsAnswer[0]) || landsAnswer[0] ? (
              <div>
                <LandsBuildingsAnswerInput
                  id="tax-return-section-3-lands-answers"
                  type="lands"
                  answer={landsAnswer}
                  setAnswer={(landsAnswer) => setLandsAnswer(landsAnswer)}
                />
              </div>
            ) : (
              <LandsBuildingsAddButton
                type="lands"
                onClick={() => {
                  setShowAddLand(true);
                  !landsAnswer[0] &&
                    setLandsAnswer([
                      {
                        city: '',
                        foglio: '',
                        particella: '',
                        sub: '',
                        agriculturalIncome: undefined,
                        dominicalIncome: undefined,
                        possessionDaysFrom: undefined,
                        possessionDaysTo: undefined,
                        percentagePossession: undefined,
                        imuSubject: undefined,
                        isConfirmed: undefined,
                      },
                    ]);
                }}
              />
            )}
          </>
        )}
        <div className="row mt-5 pt-5 mb-4">
          <div className="col-12 tax-return-section-3-question">
            {lang.buildingsQuestion}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <LandsBuildingsAccordion type="buildings" />
          </div>
        </div>
        {loadingStatus === 'loading' ? (
          <div className="d-flex align-items-center justify-content-center my-5 py-5">
            <QuickfiscoSpinner />
          </div>
        ) : (
          <>
            {(showAddBuildings === true && buildingsAnswer[0]) ||
            buildingsAnswer[0] ? (
              <div>
                <LandsBuildingsAnswerInput
                  id="tax-return-section-3-buildings-answers"
                  type="buildings"
                  answer={buildingsAnswer}
                  setAnswer={(buildingsAnswer) =>
                    setBuildingsAnswer(buildingsAnswer)
                  }
                />
              </div>
            ) : (
              <LandsBuildingsAddButton
                type="buildings"
                onClick={() => {
                  setShowAddBuildings(true);
                  !buildingsAnswer[0] &&
                    setBuildingsAnswer([
                      {
                        city: '',
                        foglio: '',
                        particella: '',
                        sub: '',
                        landRegistryCategory: '',
                        landRegistryIncome: undefined,
                        possessionDaysFrom: undefined,
                        possessionDaysTo: undefined,
                        percentagePossession: undefined,
                        imuSubject: undefined,
                        usage: undefined,
                        shortRentals: undefined,
                        isConfirmed: undefined,
                      },
                    ]);
                }}
              />
            )}
          </>
        )}
        {(landsAnswer.length > 0 || buildingsAnswer.length > 0) && (
          <div className="row d-flex justify-content-center">
            <div className="col-auto py-3 px-4 d-flex mt-3 align-items-center tax-return-section-3-confirm-container">
              <QuickfiscoIcon
                name={'alert-yellow-small.svg'}
                className="quickfisco-input-cursor-pointer me-3"
              />
              {lang.confirmation}
            </div>
          </div>
        )}
        <div className="row d-flex mt-5 align-items-center">
          <div className="col-3">
            <QuickfiscoButton
              id={'tax-return-section-3-go-prev'}
              label={lang.goPrev}
              type={'primary'}
              onClick={() => onClickPrevStep(dataToSend)}
            />
          </div>
          <div className="col-6 text-center">
            <div className="tax-return-section-3-go-title">
              {lang.goAlertTitle}
            </div>
            <div className="tax-return-section-3-go-description">
              {lang.goAlertdescription}
            </div>
          </div>
          <div className="col-3">
            {nextButtonStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'tax-return-section-3-go-next'}
                label={lang.goNext}
                type={
                  checkgoNext && loadingStatus !== 'loading'
                    ? 'secondary'
                    : 'disabled'
                }
                onClick={() => onClickNextStep(dataToSend)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
