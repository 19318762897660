import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { doLogin } from '../../redux/slices/loginSlice';
import { UserService } from '../../services/userService';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { UserErrorSetters } from '../../models/userErrorSetters';
import { SetState } from '../../types/functions';
import { RegisterOnboardingModel } from '../../models/onboardingModel';
import TagManager from 'react-gtm-module';
import config from '../../../config.json';
import lang from './registerOnboardingComponent.json';
import './registerOnboardingComponent.css';

interface Props {
  crmUserId?: string | null;
}

export function RegisterOnboardingComponent(props: Props) {
  const { crmUserId } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [errorName, setErrorName] = useState(false);
  const [errorSurname, setErrorSurname] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorPrivacy, setErrorPrivacy] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [statusRegistration, setStatusRegistration] =
    useState<PromiseStatuses>('idle');

  const user = useAppSelector((state) => state.onboarding.user);

  const dispatch = useAppDispatch();

  const registerService = new UserService(undefined, {
    setErrorName,
    setErrorSurname,
    setErrorEmail,
    setErrorPhone,
    setErrorPassword,
    setErrorConfirmPassword,
    setErrorPrivacy,
    setErrorTerms,
  });

  return (
    <>
      <QuickfiscoError
        message={lang.registerError}
        active={statusRegistration === 'failed'}
        close={() => setStatusRegistration('idle')}
      />
      <div className="row">
        <div className="col-12">
          <div className={'row no-gutters'}>
            <div className="col-12 text-center">
              <h2 className="register-onboarding-component-sub-title">
                {lang.hi} <span>{user?.name},</span>
              </h2>
              <p className="register-onboarding-component-text">{lang.text}</p>
              <p className="register-onboarding-component-info-text mb-0">
                {lang.infoText_1}
                <br />
                {lang.infoText_2}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className="col-12">
              <hr className="my-4" />
            </div>
          </div>
          <div className="col-12">
            <div className={'row d-flex justify-content-center no-gutters'}>
              <div className="col-12">
                <QuickfiscoInput
                  id="register-onboarding-email"
                  styleType={'default'}
                  label={lang.EmailLabel}
                  value={user.email}
                  type="email"
                  error={false}
                  placeholder={lang.EmailPlaceholder}
                  required={true}
                  disabled={true}
                />
              </div>
              <div className="col-12">
                <QuickfiscoInput
                  id="register-onboarding-password"
                  label={lang.PasswordLabel}
                  type="password"
                  styleType={'default'}
                  placeholder={lang.PasswordPlaceholder}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  error={errorPassword}
                  errorLabel={lang.ErrorPassword}
                  required={true}
                  onBlur={(e) =>
                    registerService.validatePasswordInput(
                      { confirmPassword: confirmPassword },
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="col-12">
                <QuickfiscoInput
                  id="register-onboarding-confirmPassword"
                  label={lang.ConfirmPasswordLabel}
                  type="password"
                  placeholder={lang.ConfirmPasswordPlaceholder}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  error={errorConfirmPassword}
                  styleType={'default'}
                  errorLabel={lang.ErrorConfirmPassword}
                  required={true}
                  onBlur={(e) =>
                    registerService.validateConfirmPasswordInput(
                      { confirmPassword: e.target.value },
                      password
                    )
                  }
                />
              </div>
              <div className="col-12 register-onboarding-privacy-container">
                <QuickfiscoCheckbox
                  id="register-onboarding-marketing"
                  label={lang.MarketingLabel}
                  onChange={(e) => setMarketing(e.target.checked)}
                  checked={marketing}
                  linkLabel={lang.MarketingLabelLink}
                  link={config.marketing}
                />
              </div>
              <div className="col-12 register-onboarding-privacy-container">
                <QuickfiscoCheckbox
                  id="register-onboarding-privacy"
                  label={lang.PrivacyLabel}
                  onChange={(e) => setPrivacy(e.target.checked)}
                  checked={privacy}
                  error={errorPrivacy}
                  linkLabel={lang.PrivacyLabelLink}
                  link={config.privacy}
                  required={true}
                  onBlur={(e) =>
                    registerService.validatePrivacy(e.target.checked)
                  }
                />
              </div>
              <div className="col-12 register-onboarding-privacy-container">
                <QuickfiscoCheckbox
                  id="register-onboarding-terms"
                  label={lang.TermsLabel}
                  onChange={(e) => setTerms(e.target.checked)}
                  checked={terms}
                  error={errorTerms}
                  linkLabel={lang.TermsLabelLink}
                  link={config.terms}
                  required={true}
                  onBlur={(e) =>
                    registerService.validateTerms(e.target.checked)
                  }
                />
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  {statusRegistration === 'loading' ? (
                    <div className="text-center">
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id="register-onboarding-register-btn"
                      label={lang.LoginLabel}
                      type={'senary'}
                      onClick={() => {
                        doRegistration(
                          {
                            email: user.email,
                            password,
                            privacy,
                            terms,
                            marketing,
                          },
                          confirmPassword,
                          {
                            setErrorName,
                            setErrorSurname,
                            setErrorEmail,
                            setErrorPhone,
                            setErrorPassword,
                            setErrorConfirmPassword,
                            setErrorPrivacy,
                            setErrorTerms,
                          },
                          dispatch,
                          setStatusRegistration,
                          crmUserId
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

async function doRegistration(
  register: RegisterOnboardingModel,
  confirmPassword: string,
  errorSetters: UserErrorSetters,
  dispatch: Function,
  setStatus: SetState<PromiseStatuses>,
  crmUserId?: string | null
) {
  setStatus('loading');

  const service = new UserService(undefined, errorSetters);

  if (
    !(await service.validateOnboardingUser(register, {
      confirmPassword: confirmPassword,
    }))
  ) {
    setStatus('failed');
    return;
  }

  if (!crmUserId) {
    return;
  }

  const registerService = new UserService();

  try {
    registerService
      .doOnboardingRegistration({ ...register, crmId: crmUserId })
      .then(() => {
        dispatch(
          doLogin({
            email: register.email,
            password: register.password,
          })
        ).then((res: { error?: Object }) => {
          if (res.error) {
            throw res.error;
          }
          TagManager.dataLayer({
            dataLayer: {
              event: 'free_trial',
              email: register.email,
            },
          });
        });
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  } catch (err) {
    console.error(err);
    setStatus('failed');
  }
}
