import { AtecoModel } from '../models/atecoModel';
import { host } from '../utils/config';
import { Http } from '../utils/http';

export class AtecoService {

  private readonly url = host + '/api/v1/ateco';

  private readonly http: Http;

  public constructor() {
    this.http = Http.getInstance();
  }

  public findAll(): Promise<AtecoModel[]> {
    return this.http.sendAndReceive({
      method: 'get',
      url: this.url + '/'
    })
      .then(res => res.json())
      .then((data: AtecoModel[]) => data);
  }

}
