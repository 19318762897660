import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoCheckbox } from '../quickfiscoCheckbox/quickfiscoCheckbox';
import lang from './incomeUploadModalStep1.json';
import './incomeUploadModalStep1.css';

interface Props {
  qfUsedFlag: boolean | undefined;
  setQfUsedFlag: (response: boolean) => void;
  onClickPrevStep: () => void;
  onClickNextStep: () => void;
}

export function IncomeUploadModalStep1(props: Props) {
  const { qfUsedFlag } = props;
  const { setQfUsedFlag } = props;
  const { onClickPrevStep } = props;
  const { onClickNextStep } = props;

  return (
    <>
      <div className={'row px-5'}>
        <div className={'col-12 income-upload-modal-step1-subtitle'}>
          {lang.subtitle}
        </div>
      </div>
      <div className={'row my-3 p-4 income-upload-modal-step1-checkbox'}>
        <div
          className={'col-12 d-flex align-items-start justify-content-center'}
        >
          <QuickfiscoCheckbox
            id={'income-upload-modal-step1-response-true'}
            label={lang.checkbox1}
            checked={qfUsedFlag === true}
            onChange={(e) => setQfUsedFlag(true)}
          />
        </div>
        <div
          className={
            'col-12 mt-4 d-flex align-items-start justify-content-center'
          }
        >
          <QuickfiscoCheckbox
            id={'income-upload-modal-step1-response-false'}
            label={lang.checkbox2}
            checked={qfUsedFlag === false}
            onChange={(e) => setQfUsedFlag(false)}
          />
        </div>
      </div>
      <div className={'row no-gutters my-4'}>
        <div className={'col-3 d-flex justify-content-center'}>
          <QuickfiscoButton
            id={'income-upload-modal-step1-button'}
            label={lang.step1ButtonPrev}
            type={'primary'}
            onClick={() => onClickPrevStep()}
          />
        </div>
        <div className={'col-3 offset-6 d-flex justify-content-center'}>
          <QuickfiscoButton
            id={'income-upload-modal-step1-button'}
            label={lang.step1buttonNext}
            type={qfUsedFlag !== undefined ? 'secondary' : 'disabled'}
            onClick={() => onClickNextStep()}
          />
        </div>
      </div>
    </>
  );
}
