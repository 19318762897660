import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import lang from './bringAFriendBox.json';
import './bringAFriendBox.css';

export function BringAFriendBox() {
  const userState = useAppSelector((state) => state.user);

  return (
    <div className="bring-a-frind-box-container box-shadow">
      <div className="bring-a-frind-box-title pb-2">{lang.title}</div>
      <div className={'mt-4 mb-4'}>
        <div
          className={
            'bring-a-friend-box-promo-code d-flex align-items-center justify-content-between'
          }
        >
          {userState.user.assignedPromoCode}
          <QuickfiscoIcon
            name={'copy-button.svg'}
            className={'bring-a-friend-modal-copy-button'}
            onClick={() => {
              navigator.clipboard.writeText(
                userState.user.assignedPromoCode || ''
              );
            }}
          />
        </div>
      </div>
      <div className="bring-a-frind-box-title-text pt-3">
        {lang.text}
        <b>{lang.textBold}</b>
      </div>
    </div>
  );
}
