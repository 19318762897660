import { useEffect } from 'react';
import { InvoiceModel } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAutoInvoiceTaxBase } from '../../redux/slices/autoInvoiceSlice';
import { setCustomerInvoiceReimbursementOfExpensesAmount, setCustomerInvoiceSocialContributionAmount, setCustomerInvoiceStamp, setCustomerInvoiceTaxBase, setCustomerInvoiceWithholdingAmount } from '../../redux/slices/customerInvoiceSlice';
import { setProformaInvoiceReimbursementOfExpensesAmount, setProformaInvoiceSocialContributionAmount, setProformaInvoiceStamp, setProformaInvoiceTaxBase } from '../../redux/slices/proformaInvoiceSlice';
import { setStsInvoiceSocialContributionAmount, setStsInvoiceStamp, setStsInvoiceTaxBase } from '../../redux/slices/stsInvoiceSlice';
import { CommonInvoiceService } from '../../services/commonInvoiceService';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { InvoiceSign } from '../../types/strings';
import { formatNumberIT } from '../../utils/number';
import { AutoInvoiceSummary } from '../autoInvoiceSummary/autoInvoiceSummary';
import config from '../../../config.json';
import './invoiceAmount.css';
import lang from './invoiceAmount.json';

interface Props {
  invoice: InvoiceModel;
  invoiceSign: InvoiceSign;
  stsIsActive?: boolean;
  stampId?: string;
  setStampId?: (id: string) => void;
  disabled?: boolean;
  error?: boolean;
  errorLabel?: string;
}

export function InvoiceAmount(props: Props) {
  const { invoice } = props
  const { invoiceSign } = props;
  const { stsIsActive } = props;
  const { stampId } = props;
  const { setStampId } = props;
  const { disabled } = props;
  const { error } = props;
  const { errorLabel } = props;

  const dispatch = useAppDispatch();

  const userState = useAppSelector(state => state.user);

  const user = userState.user

  const taxBase = CommonInvoiceService.calculateTaxBase(invoice);
  const socialContributionAmount = CommonInvoiceService.calculateSocialContribution(invoice, user, false);
  const reimbursementsOfExpensesAmount = CommonInvoiceService.calculateReimbursementsOfExpenses(invoice)
  const totalAmount = CommonInvoiceService.calculateTotalAmount(invoice, user, invoiceSign);
  const withholding = CommonInvoiceService.calculateWithholding(invoice, false)

  useEffect(() => {
    switch (invoiceSign) {
      case 'customer':
        if (taxBase < CommonInvoiceService.stampActivationThreshold) {
          dispatch(setCustomerInvoiceStamp(false))
        }

        dispatch(setCustomerInvoiceTaxBase(taxBase))
        dispatch(setCustomerInvoiceSocialContributionAmount(socialContributionAmount))
        dispatch(setCustomerInvoiceReimbursementOfExpensesAmount(reimbursementsOfExpensesAmount))
        dispatch(setCustomerInvoiceWithholdingAmount(withholding))
        break
      case 'proforma':
        if (taxBase < CommonInvoiceService.stampActivationThreshold) {
          dispatch(setProformaInvoiceStamp(false))
        }

        dispatch(setProformaInvoiceTaxBase(taxBase))
        dispatch(setProformaInvoiceSocialContributionAmount(socialContributionAmount))
        dispatch(setProformaInvoiceReimbursementOfExpensesAmount(reimbursementsOfExpensesAmount))
        dispatch(setCustomerInvoiceWithholdingAmount(withholding))
        break
      case 'sts':
        if (taxBase < CommonInvoiceService.stampActivationThreshold) {
          dispatch(setStsInvoiceStamp(false))
        }

        dispatch(setStsInvoiceTaxBase(taxBase))
        dispatch(setStsInvoiceSocialContributionAmount(socialContributionAmount))
        break
      case 'auto':
        dispatch(setAutoInvoiceTaxBase(taxBase))
        break
      default:
        break
    }
  }, [taxBase, socialContributionAmount, reimbursementsOfExpensesAmount, withholding])

  return (
    <div>
      {
        invoiceSign === 'auto' && (
          <AutoInvoiceSummary invoice={invoice} />
        )
      }
      <div className={'d-flex justify-content-between'}>
        <div className={'invoiceAmount-label'}>{lang.GoodServiceAmount}</div>
        <div className={'invoiceAmount-label'}>{invoice.taxBase !== null && invoice.taxBase !== undefined && formatNumberIT(invoice.taxBase)}€</div>
      </div>
      {
        invoiceSign !== 'auto' && (
          <div>
            {user?.fund === 'TC07' ?
              <><div className={'invoice-customer-saveOrEdit-line mt-3 mb-3'}></div><div className={'d-flex justify-content-between'}>
                <div className={'invoiceAmount-label'}>{"Importo Ritenuta"}</div>
                <div className={'invoiceAmount-label'}>{formatNumberIT(withholding*-1)}€</div>
              </div></> :
              <><div className={'invoice-customer-saveOrEdit-line mt-3 mb-3'}></div><div className={'d-flex justify-content-between'}>
                <div className={'invoiceAmount-label'}>{lang.SocialContribution}</div>
                <div className={'invoiceAmount-label'}>{invoice.socialContributionAmount !== null && invoice.socialContributionAmount !== undefined && formatNumberIT(invoice.socialContributionAmount)}€</div>
              </div></>
            }
            {invoice.withholding?.active && user.fund !== "TC07" ?
              <><div className={'invoice-customer-saveOrEdit-line mt-3 mb-3'}></div><div className={'d-flex justify-content-between'}>
                <div className={'invoiceAmount-label'}>{"Importo Ritenuta"}</div>
                <div className={'invoiceAmount-label'}>{formatNumberIT(withholding*-1)}€</div>
              </div></> : ""}
            {
              (invoiceSign === 'customer' || (!invoice.stsIsActive && invoiceSign === 'proforma')) && (
                <div>
                  <div className={'invoice-customer-saveOrEdit-line mt-3 mb-3'} />
                  <div className={'d-flex justify-content-between'}>
                    <div className={'invoiceAmount-label'}>{lang.ReimbursementOfExpenses}</div>
                    <div className={'invoiceAmount-label'}>
                      {invoice.reimbursementsOfExpensesAmount !== null && invoice.reimbursementsOfExpensesAmount !== undefined && formatNumberIT(invoice.reimbursementsOfExpensesAmount)}€
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      <div className={'invoice-customer-saveOrEdit-line mt-3 mb-3'} />
      <div className={'d-flex justify-content-between'}>
        <div className={'invoiceAmount-main-label'}>{lang.Label}</div>
        <div className={'invoiceAmount-main-label'}>{formatNumberIT(totalAmount)}€</div>
      </div>
      {((invoiceSign === 'sts' && totalAmount > config.stampIdTheshold) || (invoiceSign === 'proforma' && stsIsActive && totalAmount > config.stampIdTheshold) || error) && (
        <div className={'row mt-5'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <QuickfiscoTextarea
                id={'invoiceAmount-amount-stamp-id'}
                label={lang.Stamp}
                onChange={(e) => setStampId && setStampId(e.target.value)}
                value={stampId ? stampId : ''}
                placeholder={lang.StampPlaceholder}
                maxLength={30}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={'row mt-1'}>
            <div className={'col-12'}>
              {error && errorLabel && (
                <div className={'invoiceAmount-amount-services-error'}>
                  {errorLabel}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
