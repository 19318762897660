import config from '../../../config.json';
import { useAppSelector } from "../../redux/hooks";
import { FeesRegisterService } from '../../services/feesRegisterService';
import { formatNumberIT } from '../../utils/number';
import './feesRegisterTotalAmount.css';

export function FeesRegisterTotalAmount() {
    const feesRegisterState = useAppSelector(state => state.feesRegister);

    const feesRegisterList = feesRegisterState.feesRegisterList

    const { totalAmount, amountIT, amountEU, amountEXTRAEU } = FeesRegisterService.calculateFeesRegisterTotalAmount(feesRegisterList)

    return (
        <div className="row fees-register-total-amount-container">
            <div className="col-12">
                <div className="row">
                    <div className="col-2 fees-register-table-cells">
                        <span className="fees-register-total-amount-title">
                            TOTALE
                        </span>
                    </div>
                    <div className="col-2 fees-register-table-cells">
                        <span className="fees-register-total-amount-text">
                            {config.EUR + formatNumberIT(totalAmount)}
                        </span>
                    </div>
                    <div className="col-3 fees-register-table-cells" />
                    <div className="col">
                        <div className="row">
                            <div className="col-4 fees-register-table-cells">
                                <span className="fees-register-total-amount-text fw-normal">
                                    {config.EUR + formatNumberIT(amountIT)}
                                </span>
                            </div>
                            <div className="col-4 fees-register-table-cells">
                                <span className="fees-register-total-amount-text fw-normal">
                                    {config.EUR + formatNumberIT(amountEU)}
                                </span>
                            </div>
                            <div className="col-4 fees-register-table-cells">
                                <span className="fees-register-total-amount-text fw-normal">
                                    {config.EUR + formatNumberIT(amountEXTRAEU)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto fees-register-table-cells">
                        <div style={{ width: '52px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
