import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './taxCalculationGuidePopup.css';
import lang from './taxCalculationGuidePopup.json';

export function TaxCalculationGuidePopup() {
  return (
    <div className="tax-calculation-guide-popup-container">
      <div className="row align-items-center">
        <div className="col-auto d-flex align-items-center pe-0">
          <QuickfiscoIcon name="tax-calculation-guide.svg" />
        </div>
        <div className="col-auto">
          <p className="tax-calculation-guide-popup-text">
            {lang.text1}
            <a
              className="tax-calculation-guide-popup-text-blue"
              href={'https://quickfisco.it/guide-previsionale-tasse/'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {lang.text2}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
