import { useState } from 'react';
import config from '../../../config.json';
import { CategoryType } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import { formatDateIT } from '../../utils/date';
import { formatNumberIT, roundToTwoDigitDecimal } from '../../utils/number';
import { fundsMap } from '../fundSelect/fundSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxEditProfile } from '../taxEditProfile/taxEditProfile';
import './taxProfileInfo.css';
import lang from './taxProfileInfo.json';

export function TaxProfileInfo() {
  const [open, setOpen] = useState(false);

  const userState = useAppSelector(state => state.user);
  const taxProfileState = useAppSelector(state => state.taxProfile);
  const taxCalculationState = useAppSelector(state => state.taxCalculation);

  if (userState.status === 'loading' || taxProfileState.status === 'loading') {
    return (
      <div className="full-screen d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;
  const taxProfile = taxProfileState.profile;

  return (
    <div className={'tax-profile-resume-container'} style={(user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01")) ? { height: "850px" } : { height: "500px" }}>
      <div className={'row d-flex justify-content-between align-items-center'}>
        <div className={'col-auto'}>
          <span className={'tax-profile-resume-title'}>
            {lang.title}
          </span>
        </div>
        <div className={'col-auto'}>
          <QuickfiscoButton
            type={'primary'}
            id={'button-tax-profile-edit'}
            label={lang.buttonLabel}
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      <div className={'row mt-4'}>
        <div className={'col-5'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.vat}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-resume-value'}>
                {user.vatOpeningDate && formatDateIT(user.vatOpeningDate)}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.ateco}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12 list-crop'}>
              <span className={'tax-profile-resume-value'}>
                {user.atecos && user.atecos[0]}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.taxation}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-resume-value'}>
                {user.taxation}%
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.fund}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12 list-crop'}>
              <span className={'tax-profile-resume-value'}>
                {user.fund && fundsMap.get(user.fund)?.desc}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.otherYearRevenues}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-resume-value'}>
                {formatNumberIT(taxProfile.lastYearRevenues) + config.EUR}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.currentYearRevenues}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-resume-value'}>
                {formatNumberIT(taxProfile.otherCurrentYearRevenues) + config.EUR}
              </span>
            </div>
          </div>
        </div>
        <div className={'col-5 offset-2'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {
                  (user.category !== CategoryType.FUND && (user.fund !== "TC04" && user.fund !== "TC21" && user.fund !== "TC01")) ?
                    lang.contributionBalance : lang.contributionBalanceWithFund
                }
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-resume-value'}>
                {formatNumberIT(taxProfile.lastYearContributionBalance) + config.EUR}
              </span>
            </div>
          </div>
          {
            (user.category !== CategoryType.FUND && (user.fund !== "TC04" && user.fund !== "TC21" && user.fund !== "TC01")) &&
            (
              <div>
                <div className={'row mt-3'}>
                  <div className={'col-12'}>
                    <p className={'tax-profile-resume-label'}>
                      {lang.contributionDeposit}
                    </p>
                  </div>
                </div><div className={'row'}>
                  <div className={'col-12'}>
                    <span className={'tax-profile-resume-value'}>
                      {formatNumberIT(taxProfile.lastYearContributionDeposit) + config.EUR}
                    </span>
                  </div>
                </div>
              </div>
            )
          }
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <p className={'tax-profile-resume-label'}>
                {lang.taxationDeposit}
              </p>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-profile-resume-value'}>
                {formatNumberIT(taxProfile.lastYearTaxDeposit) + config.EUR}
              </span>
            </div>
          </div>
          {
            (user.category === CategoryType.ARTISAN ||
              user.category === CategoryType.TRADER) &&
            (
              <div>
                <div className={'row mt-3'}>
                  <div className={'col-12'}>
                    <p className={'tax-profile-resume-label'}>
                      {lang.contributionReduction}
                    </p>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-12'}>
                    <span className={'tax-profile-resume-value'}>
                      {user.contributionReduction ? 'SI' : 'NO'}
                    </span>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          (user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01")) && (
            <div>

              <div className={'row d-flex justify-content-between align-items-center'}>
                <div className={'col-auto mt-4'}>
                  <span className={'tax-profile-resume-title-secondary'}>
                    Riepilogo Cassa di Previdenza
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className={'col-5 mt-4'}>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <p className={'tax-profile-resume-label'}>
                        Anno d'iscrizione alla cassa
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-profile-resume-value'}>
                        {String(taxProfile.fundYearRegistration)}
                      </span>
                    </div>
                  </div>
                  <div className={'row mt-3'}>
                    <div className={'col-12'}>
                      <p className={'tax-profile-resume-label'}>
                        Aliquota contributo soggettivo
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-12 list-crop'}>
                      <span className={'tax-profile-resume-value'}>
                        {taxCalculationState.year === (new Date().getFullYear() + 1).toString() ? roundToTwoDigitDecimal(taxProfile.lastTaxContributionRate * 100) : roundToTwoDigitDecimal(taxProfile.taxContributionRate * 100)}%
                      </span>
                    </div>
                  </div>
                  <div className={'row mt-3'}>
                    <div className={'col-12'}>
                      <p className={'tax-profile-resume-label'}>
                        Ammontare contributo soggettivo minimo
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-profile-resume-value'}>
                        {formatNumberIT(taxProfile.minTaxContribution) + config.EUR}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={'col-5 mt-4 offset-2'}>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <p className={'tax-profile-resume-label'}>
                        Ammontare contributo maternità
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-profile-resume-value'}>
                        {formatNumberIT(taxProfile.maternityContribution) + config.EUR}
                      </span>
                    </div>
                  </div>
                  <div className={'row mt-3'}>
                    <div className={'col-12'}>
                      <p className={'tax-profile-resume-label'}>
                        Aliquota contributo integrativo
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-profile-resume-value'}>
                        {(user.recourseValue)}%
                      </span>
                    </div>
                  </div>
                  <div className={'row mt-3'}>
                    <div className={'col-12'}>
                      <p className={'tax-profile-resume-label'}>
                        Ammontare contributo integrativo minimo
                      </p>
                    </div>
                  </div>
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-profile-resume-value'}>
                        {formatNumberIT(taxProfile.minSupplementaryContribution) + config.EUR}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <TaxEditProfile
        open={open}
        hide={() => setOpen(false)}
      />
    </div>
  );
}