import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessagesReportModel } from '../../models/messagesReportModel';
import { PaginationModel } from '../../models/paginationModel';
import { PromiseStatuses } from '../../types/strings';
import { MessagesService } from '../../services/messagesService';
import { MessagesModel } from '../../models/messagesModel';
import { MessagesFilters, MessagesStatusFiltersType } from '../../models/messagesFilters';
import { MessagesDocumentsUnreadcount } from '../../models/messagesDocumentsUnreadcount';

interface MessagesListState {
  status: PromiseStatuses;
  messages: PaginationModel<MessagesModel>;
  reports: MessagesReportModel;
  unreadCounters: MessagesDocumentsUnreadcount;
  reportsStatus: PromiseStatuses;
  page: number;
  statusFilter?: MessagesStatusFiltersType;
}

const initialState: MessagesListState = {
  status: 'idle',
  messages: { content: [] },
  reports: {
    allMessages: 0,
    importantMessages: 0,
    allUnreadMessages: 0,
  },
  unreadCounters: {
    allUnreadDocuments: 0,
    allUnreadMessages: 0
  },
  reportsStatus: 'idle',
  page: 1
};

export const getMessagesReports = createAsyncThunk(
  'messagesList/getMessagesReports',
  async (): Promise<MessagesReportModel> => {
    return await new MessagesService().getReports();
  }
);

export const getUnreadCounters = createAsyncThunk(
  'messagesList/getUnreadCounters',
  async (): Promise<MessagesDocumentsUnreadcount> => {
    return await new MessagesService().getUnreadCounters();
  }
);

export const getMessagesList = createAsyncThunk(
  'messagesList/getMessagesList',
  async (request: MessagesFilters): Promise<PaginationModel<MessagesModel>> => {
    return await new MessagesService().findAll(request);
  }
);

const messagesListSlice = createSlice({
  name: 'messagesList',
  initialState,
  reducers: {
    setMessagesListPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setMessagesListStatusFilter: (state, action: PayloadAction<MessagesStatusFiltersType | undefined>) => {
      state.statusFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessagesReports.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(getMessagesReports.fulfilled, (state, action) => {
        state.reportsStatus = 'idle';
        state.reports = action.payload;
      })
      .addCase(getMessagesReports.rejected, ((state) => {
        state.reportsStatus = 'failed';
      }))
      .addCase(getUnreadCounters.pending, (state) => {
        state.reportsStatus = 'loading';
      })
      .addCase(getUnreadCounters.fulfilled, (state, action) => {
        state.reportsStatus = 'idle';
        state.unreadCounters = action.payload;
      })
      .addCase(getUnreadCounters.rejected, ((state) => {
        state.reportsStatus = 'failed';
      }))
      .addCase(getMessagesList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMessagesList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.messages = action.payload;
      })
      .addCase(getMessagesList.rejected, ((state) => {
        state.status = 'failed';
      }))
  },
});

export const {
  setMessagesListPage,
  setMessagesListStatusFilter
} = messagesListSlice.actions;

export default messagesListSlice.reducer;
