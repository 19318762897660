import { useEffect } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import lang from './taxReturnSection9Informations.json';
import './taxReturnSection9Informations.css';

interface Props {
  setEnabledSurvey: () => void;
  refreshApiCall: () => void;
}

export function TaxReturnSection9Informations(props: Props) {
  const { setEnabledSurvey } = props;
  const { refreshApiCall } = props;

  useEffect(() => {
    refreshApiCall();
  }, []);

  return (
    <div className="row d-flex justify-content-center mt-4">
      <div className="col-9">
        <div className={'row no-gutters mt-4'}>
          <div className="col-12">
            <div
              className={'tax-return-section-9-informations-container-text p-4'}
            >
              <div className="row no-gutters">
                <div className="col-12 tax-return-section-9-informations-title">
                  {lang.title}
                </div>
                <div className="col-12 tax-return-section-9-informations-description mt-3">
                  Il quadro RW del modello Redditi Persone Fisiche è dedicato al
                  monitoraggio annuale delle attività finanziarie e degli
                  investimenti patrimoniali detenuti all’estero da parte di
                  persone fisiche, nonché per la determinazione delle due
                  relative imposte patrimoniali: IVIE – Imposta sugli immobili
                  detenuti all’estero; IVAFE – Imposta sulle attività
                  finanziarie detenute all’estero. Le persone fisiche residenti
                  fiscalmente in Italia che detengono investimenti patrimoniali
                  all’estero, o attività finanziarie suscettibili di produrre
                  reddito in Italia devono compilare il quadro RW del modello
                  Redditi. L’articolo 4 del D.L. n. 167/90 prevede l’obbligo di
                  indicazione nella dichiarazione dei redditi:
                  <ul>
                    <li>Degli investimenti all’estero, ovvero</li>
                    <li>Delle attività estere di natura finanziaria.</li>
                  </ul>
                  <b>
                    <u>Le attività estere di natura finanziaria</u>
                  </b>{' '}
                  che sono oggetto di segnalazione all'interno del quadro RW
                  sono:
                  <ul>
                    <li>
                      Depositi e conti correnti bancari costituiti all’estero;
                    </li>
                    <li>Conti correnti valutari;</li>
                    <li>Criptovalute, Bitcoin, etc.</li>
                    <li>I metalli preziosi detenuti all’estero;</li>
                    <li>
                      Le attività e gli investimenti detenuti all’estero per il
                      tramite di soggetti localizzati in Paesi diversi da quelli
                      collaborativi. nonché in entità giuridiche italiane o
                      estere, diverse dalle società, qualora il contribuente
                      risulti essere “titolare effettivo“;
                    </li>
                    <li>
                      Le partecipazioni al capitale o al patrimonio di soggetti
                      non residenti;
                    </li>
                    <li>
                      Le obbligazioni estere o titoli similari, i titoli
                      pubblici e i titoli equiparati emessi all’estero, i titoli
                      non rappresentativi di merce e i certificati di massa
                      emessi da non residenti;
                    </li>
                    <li>
                      I diritti all’acquisto o alla sottoscrizione di azioni
                      estere o strumenti finanziari assimilati;
                    </li>
                    <li>
                      I contratti di natura finanziaria stipulati con
                      controparti non residenti (finanziamenti, riporti, pronti
                      contro termine, prestito titoli);
                    </li>
                    <li>
                      I contratti derivati e altri rapporti finanziari stipulati
                      al di fuori del territorio dello Stato;
                    </li>
                    <li>
                      Le forme di previdenza complementare organizzate o gestite
                      da soggetti esteri, escluse quelle obbligatorie per legge;
                    </li>
                    <li>
                      Le polizze di assicurazione sulla vita e di
                      capitalizzazione;
                    </li>
                    <li>
                      Le attività finanziarie italiane detenute all’estero,
                      anche se in cassetta di sicurezza;
                    </li>
                    <li>
                      Le attività finanziarie estere detenute in Italia al di
                      fuori del circuito degli intermediari residenti;
                    </li>
                    <li>
                      I titoli o diritti offerti ai lavoratori dipendenti ed
                      assimilati (cosiddette “stock option“) nei casi in cui, al
                      termine del periodo di imposta, il prezzo di esercizio sia
                      inferiore al valore corrente del sottostante.
                    </li>
                  </ul>
                  <u>
                    Sono tenuti agli obblighi di monitoraggio non solo i
                    titolari delle attività detenute all'estero, ma anche coloro
                    che ne hanno la disponibilità o la possibilità di
                    movimentazione.
                  </u>
                  <br />
                  <br />
                  <b>
                    <u>IVAFE</u>
                  </b>
                  <br />
                  L’imposta, calcolata sul valore dei prodotti finanziari e
                  dovuta proporzionalmente alla quota di possesso e al periodo
                  di detenzione, è pari al 2 per mille (4 per mille se detenuti
                  in Stati o territori a regime fiscale privilegiato). Per i
                  conti correnti e i libretti di risparmio detenuti all’estero
                  l’imposta è stabilita nella misura fissa di 34,20 euro per
                  ciascun conto corrente o libretto di risparmio detenuti
                  all’estero. L’imposta non è dovuta quando il valore medio di
                  giacenza annuo risultante dagli estratti conto e dai libretti
                  non è superiore a 5.000 euro. A tal fine occorre tener conto
                  di tutti i conti o libretti detenuti all’estero dal
                  contribuente presso lo stesso intermediario, a nulla rilevando
                  il periodo di detenzione del rapporto durante l’anno. Se il
                  contribuente possiede rapporti cointestati, al fine della
                  determinazione del limite di 5.000 euro si tiene conto degli
                  importi a lui riferibili pro quota. Il valore dei prodotti
                  finanziari è costituito dal valore di mercato, rilevato al
                  termine di ciascun anno solare nel luogo in cui le stesse sono
                  detenute, anche utilizzando la documentazione
                  dell’intermediario estero di riferimento per le singole
                  attività o dell’impresa di assicurazione estera. Se al 31
                  dicembre le attività non sono più possedute, si fa riferimento
                  al valore di mercato rilevato al termine del periodo di
                  possesso. Per le attività finanziarie che hanno una quotazione
                  nei mercati regolamentati deve essere utilizzato questo
                  valore. Per le azioni, obbligazioni e altri titoli o strumenti
                  finanziari non negoziati in mercati regolamentati e, comunque,
                  nei casi in cui le attività finanziarie quotate siano state
                  escluse dalla negoziazione, occorre far riferimento al valore
                  nominale o, in mancanza, al valore di rimborso, anche se
                  rideterminato ufficialmente. Se il titolo ha sia il valore
                  nominale sia quello di rimborso, la base imponibile è
                  costituita dal valore nominale. Quando, invece, manca sia il
                  valore nominale sia il valore di rimborso, la base imponibile
                  è costituita dal valore di acquisto dei titoli. Dall’imposta
                  dovuta si detrae, fino a concorrenza del suo ammontare, un
                  credito d’imposta pari all’ammontare dell’eventuale imposta
                  patrimoniale versata nello Stato in cui i prodotti finanziari,
                  i conti correnti e i libretti di risparmio sono detenuti. Il
                  credito non può in ogni caso superare l’imposta dovuta in
                  Italia. Non spetta alcun credito d’imposta se con il Paese nel
                  quale è detenuta l’attività finanziaria è in vigore una
                  convenzione per evitare le doppie imposizioni (riguardante
                  anche le imposte di natura patrimoniale) che prevede, per
                  l’attività, l’imposizione esclusiva nel Paese di residenza del
                  possessore.
                  <br />
                  <br />
                  <b>
                    <u>Attività patrimoniali situate all'estero</u>
                  </b>
                  <br />
                  Secondo l’Agenzia delle Entrate, sono considerate attività
                  estere di natura patrimoniale da indicare nel quadro RW:
                  <br />
                  <ul>
                    <li>gli immobili (anche se tenuti a disposizione);</li>
                    <li>
                      i preziosi e le opere d'arte che si trovano (anche in
                      custodia) fuori dal territorio dello Stato;
                    </li>
                    <li>
                      gli yacht e le imbarcazioni o le navi da diporto "esteri";
                    </li>
                    <li>
                      altri beni mobili detenuti e/o iscritti nei pubblici
                      registri esteri, nonché quelli che pur non essendo
                      iscritti nei predetti registri avrebbero i requisiti per
                      essere iscritti in Italia;
                    </li>
                    <li>i beni immateriali (marchi, brevetti, ecc.);</li>
                    <li>i mobili e gli oggetti di antiquariato.</li>
                  </ul>
                  <b>
                    <u>IVIE</u>
                  </b>
                  <br />
                  Per quanto riguarda il valore degli immobili da prendere come
                  riferimento, secondo quanto previsto dalla Circolare n. 28/E
                  del 2 luglio 2012 dell’Agenzia delle Entrate, occorre chiarire
                  che questo valore cambia, a seconda dello Stato in cui è
                  situato l’immobile:
                  <ul>
                    <li>
                      se l’immobile è situato nell’Unione Europea o in Paesi
                      aderenti allo Spazio economico europeo (Norvegia e
                      Islanda) che garantiscono un adeguato scambio di
                      informazioni, il valore da utilizzare è prioritariamente
                      quello catastale. In mancanza del valore catastale, si fa
                      riferimento al costo che risulta dall’atto di acquisto e,
                      in assenza, al valore di mercato rilevabile nel luogo in
                      cui è situato l’immobile.
                    </li>
                    <li>
                      per gli altri Stati, il valore dell’immobile è costituito
                      dal costo risultante dall'atto di acquisto o dai contratti
                      e, in mancanza, dal valore di mercato rilevabile nel luogo
                      in cui è situato l'immobile.
                    </li>
                  </ul>
                  L’aliquota è pari, ordinariamente, allo 0,76% del valore degli
                  immobili, ed è calcolata in proporzione alla quota di possesso
                  e ai mesi dell’anno nei quali il possesso c’è stato. Il
                  versamento non è dovuto se l’importo complessivo non supera i
                  200 euro. L’aliquota scende allo 0,4% per gli immobili adibiti
                  ad abitazione principale che in Italia risultano classificati
                  nelle categorie catastali A/1, A/8 e A/9, per i quali è
                  possibile, inoltre, detrarre dall’imposta 200 euro, rapportati
                  al periodo dell’anno durante il quale l’immobile è destinato
                  ad abitazione principale. Nel caso di immobile adibito ad
                  abitazione principale da più soggetti passivi, la detrazione
                  spetta a ciascun soggetto in proporzione alla quota per la
                  quale la destinazione medesima si verifica. Dall’IVIE è
                  possibile dedurre l’eventuale imposta patrimoniale versata
                  nello Stato in cui è situato l’immobile.
                </div>
              </div>
              <div className="row d-flex mt-4 justify-content-center align-items-center">
                <div className="col-4">
                  <QuickfiscoButton
                    id={'tax-return-section-9-informations'}
                    label={lang.acceptedButton}
                    type={'primary'}
                    onClick={() => {
                      refreshApiCall();
                      setEnabledSurvey();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
