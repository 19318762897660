import { InvoiceStatusType } from '../../models/invoiceModel';
import './invoicePaymentStatus.css';
import lang from './invoicePaymentStatus.json';

interface Props {
  status?: InvoiceStatusType;
  partialPaid?: boolean;
}

export function InvoicePaymentStatus(props: Props) {
  const { status } = props;
  const { partialPaid } = props;

  if (status === InvoiceStatusType.PAID) {
    return (
      <div className={'invoice-payment-status-info invoice-payment-status-paid'}>
        {lang.paid}
      </div>
    );
  }

  if (status === InvoiceStatusType.REVERSED) {
    return (
      <div className={'invoice-payment-status-info invoice-payment-status-expired'}>
        {lang.reversed}
      </div>
    );
  }

  if (partialPaid){
    return (
      <div className={'invoice-payment-status-info invoice-payment-status-to-pay'}>
        {lang.partial_paid}
      </div>
    );
  }

  return (
    <div className={'invoice-payment-status-info invoice-payment-status-to-pay'}>
      {lang.toBePaid}
    </div>
  );
}
