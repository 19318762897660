import { ReactNode } from 'react';
import { v4 as uiidv4 } from 'uuid';
import './progressStep.css';

interface Props {
  currentStep: number;
  numberOfSteps: number;
  labels: (string | undefined)[];
  infoText: (string | undefined)[];
}

export function ProgressStep(props: Props) {
  const { currentStep } = props;
  const { numberOfSteps } = props;
  const { labels } = props;
  const { infoText } = props;

  if (currentStep > numberOfSteps) {
    return null;
  }

  const dots = (index: number, currentStep: number) => {
    return (
      <div
        style={{
          height: '18px',
          width: '18px',
          borderRadius: '9px',
          backgroundColor: index > currentStep ? '#F2F2FC' : '#4542D3',
          zIndex: 2,
        }}
        key={uiidv4()}
      />
    );
  };

  const line = (index: number, currentStep: number, numberOfSteps: number) => {
    return (
      <div
        style={{
          height: '5px',
          backgroundColor: index >= currentStep ? '#F2F2FC' : '#4542D3',
          width: 'calc(100% / ' + (numberOfSteps - 1) + ' -  22px)',
        }}
        key={uiidv4()}
      />
    );
  };

  const label = (
    index: number,
    currentStep: number,
    last: boolean,
    label?: string,
    infoText?: string
  ) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis:
            index === 0
              ? 'calc(100% / ' + (numberOfSteps - 1) * 2 + ')'
              : last
              ? 'calc(100% / ' + (numberOfSteps - 1) * 2 + ')'
              : 'calc(100% / ' + (numberOfSteps - 1) + ')',
          alignItems: index === 0 ? 'flex-start' : last ? 'flex-end' : 'center',
        }}
        key={uiidv4()}
      >
        {label && (
          <span
            style={{
              textAlign: index === 0 ? 'left' : last ? 'right' : 'center',
            }}
            className={
              index > currentStep
                ? 'progress-step--label'
                : 'progress-step--label-active'
            }
          >
            {label}
          </span>
        )}
        {infoText && (
          <span
            style={{
              textAlign: index === 0 ? 'left' : last ? 'right' : 'center',
            }}
            className="progress-step--info-text mt-2"
          >
            {infoText}
          </span>
        )}
      </div>
    );
  };

  let steps: ReactNode[] = [];
  let text: ReactNode[] = [];
  for (let i = 0; i < numberOfSteps; i++) {
    steps.push(dots(i, currentStep));
    if (i !== numberOfSteps - 1) {
      steps.push(line(i, currentStep, numberOfSteps));
    }
    text.push(
      label(i, currentStep, i === numberOfSteps - 1, labels[i], infoText[i])
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {steps}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        {text}
      </div>
    </div>
  );
}
