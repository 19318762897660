export function roundToTwoDigitDecimal(amount: number): number {
  return Number((amount).toFixed(3));
}

export function parseNumber(value: string): number {
  value = value.replaceAll('.', '');
  value = value.replace(',', '.');

  return Number.parseFloat(value);
}

export function parseNumberComma(value: string): number {
  value = value.replaceAll(',', '.');

  return Number.parseFloat(value);
}

export function formatNumberIT(value: number): string {
  if (!value) {
    return '0,00';
  }

  return new Intl.NumberFormat(
    'it-IT',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
    .format(value);
}

export function formatPaymentNumber(value: number): string {
  if (!value) {
    return '0,00';
  }

  return (value / 100).toFixed(2).toString().replaceAll('.', ',').toString();
}