import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { Link } from 'react-router-dom';
import uri from '../../routers/quickfiscoUri.json';
import lang from './noProfileCompleted.json';
import './noProfileCompleted.css';

export function NoProfileCompleted() {
  return (
    <div className={'no-profile-completed-container my-4'}>
      <QuickfiscoIcon name={'alert-triangle-red.svg'} className={'pe-2'} />
      <span className={'no-profile-completed-red-bold'}>
        {lang.boldRedText}
      </span>
      {lang.text1}
      <Link to={uri.Profile}>
        <span className={'no-profile-completed-black-bold'}>
          {lang.boldBlackText}
        </span>
      </Link>
    </div>
  );
}
