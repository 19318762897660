import { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { ConfirmationError } from "../../components/confirmationError/confirmationError";
import { FeesCalculator } from "../../components/feesCalculator/feesCalculator";
import { FinanceBox } from "../../components/financeBox/financeBox";
import { IncomeUpload } from "../../components/incomeUpload/incomeUpload";
import { InvoicesToCashOut } from "../../components/invoicesToCashOut/invoicesToCashOut";
import { NoProfileCompleted } from "../../components/noProfileCompleted/noProfileCompleted";
import { NoVatAdvise } from "../../components/noVatAdvise/noVatAdvise";
import { PlatformGuide } from "../../components/platformGuide/platformGuide";
import { QuickfiscoError } from "../../components/quickfiscoError/quickfiscoError";
import { QuickfiscoHeader } from "../../components/quickfiscoHeader/quickfiscoHeader";
import {
  MenuItems,
  QuickfiscoMenu,
} from "../../components/quickfiscoMenu/quickfiscoMenu";
import { QuickfiscoModal } from "../../components/quickfiscoModal/quickfiscoModal";
import { QuickfiscoSpinner } from "../../components/quickfiscoSpinner/quickfiscoSpinner";
import { QuickfiscoSuccess } from "../../components/quickfiscoSuccess/quickfiscoSuccess";
import { RegistrationCompleted } from "../../components/registrationCompleted/registrationCompleted";
import { SubscriptionExpired } from "../../components/subscriptionExpired/subscriptionExpired";
import { ExceedingThresholds100k } from "../../components/exceedingThresholds100k/exceedingThresholds100k";
import { TaxDeclaration } from "../../components/taxDeclaration/taxDeclaration";
import { TotalRevenues } from "../../components/totalRevenues/totalRevenues";
import { TrendGraphs } from "../../components/trendGraphs/trendGraphs";
import { TrialSubscriptionExpiration } from "../../components/trialSubscriptionExpiration/trialSubscriptionExpiration";
import { TrialSubscriptionActivationModal } from "../../components/trialSubscriptionActivationModal/trialSubscriptionActivationModal";
import {
  getItem,
  getItemWithExipiration,
  setItem,
} from "../../utils/localStorage";
import { addDays, differenceInCalendarDays, differenceInDays } from "date-fns";
import { ViewportWarning } from "../../components/viewportWarning/viewportWarning";
import { OnboardingStatusBox } from "../../components/onboardingStatusBox/onboardingStatusBox";
import {
  RegimeLimitStatusType,
  UserModel,
  UserStatusType,
} from "../../models/userModel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getCustomerTotalRevenues,
  getFeesTotalRevenues,
  getStsTotalRevenues,
} from "../../redux/slices/totalRevenuesSlice";
import { getMe } from "../../redux/slices/userSlice";
import { UserService } from "../../services/userService";
import { SetState } from "../../types/functions";
import { PromiseStatuses } from "../../types/strings";
import { parseNumber } from "../../utils/number";
import { routing } from "../../utils/onboardingSteps";
import conf from "../../../config.json";
import { useNavigate } from "react-router-dom";
import lang from "./home.json";
import { InvoiceImportStsBoxHome } from "../../components/invoiceImportStsBoxHome/invoiceImportStsBoxHome";
import { InvoiceImportBoxHome } from "../../components/invoiceImportBoxHome/invoiceImportBoxHome";

export function Home() {
  const { collapsed } = useProSidebar();

  const [registrationModalOpen, setRegistrationModalOpen] = useState(true);
  const [registrationModalOpenCounter, setRegistrationModalOpenCounter] =
    useState<number>(Number(getItem("registrationModalOpen")) || 0);
  const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(false);
  const [subscriptionExpiredModalOpen, setSubscriptionExpiredModalOpen] =
    useState(true);
  const [subscriptionAlreadySentOpen, setSubscriptionAlreadySentOpen] =
    useState(false);
  const [trialSubscriptionActivationOpen, setTrialSubscriptionActivationOpen] =
    useState<boolean>(true);
  const [differenceRegistrationDate, setDifferenceRegistrationDate] = useState<
    boolean | undefined
  >(undefined);

  const [statusSubscription, setStatusSubscription] =
    useState<PromiseStatuses>("idle");

  const dispatch = useAppDispatch();
  const [yearFilter, setYearFilter] = useState<number>(
    new Date().getFullYear()
  );
  const userState = useAppSelector((state) => state.user);
  const revenuesState = useAppSelector((state) => state.totalRevenues);
  const feesRegisterState = useAppSelector((state) => state.feesRegister);

  const incomeFormCompleted = useAppSelector(
    (state) => state.user.user.incomeFormCompleted
  );
  const ddrArray = useAppSelector((state) => state.user.user.ddrYears);

  const user = userState.user;
  const editUserRequest = userState.editUserRequest;
  const userStatus = userState.status;
  const currentYear = feesRegisterState.yearFilter;
  let daysLeft;
  let paymentExpiration;

  if (user.lastPayment) {
    paymentExpiration = addDays(
      new Date(user.lastPayment),
      user.status !== UserStatusType.ACTIVE &&
        user.status !== UserStatusType.PAYMENT_DUE
        ? conf.trialSubscriptionPeriod
        : conf.SubscrptionPeriod
    );
    daysLeft = differenceInCalendarDays(paymentExpiration, new Date());
  }

  if (user.status === UserStatusType.PAYMENT_DUE) {
    daysLeft = 0;
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (userState.user.registrationSteps) {
      navigate(routing(userState.user.registrationSteps));
    }

    if (userState.user.registrationDate) {
      differenceInDays(new Date(), new Date(userState.user.registrationDate)) >
      15
        ? setDifferenceRegistrationDate(true)
        : setDifferenceRegistrationDate(false);
    }
  }, [userState.user]);

  useEffect(() => {
    checkProfileComplete(editUserRequest, setIsProfileCompleted);
  }, [editUserRequest]);
  useEffect(() => {
    setYearFilter(parseNumber(currentYear));
  }, [currentYear]);
  useEffect(() => {
    dispatch(getMe());
    dispatch(getCustomerTotalRevenues(yearFilter));
    dispatch(getStsTotalRevenues(yearFilter));
    dispatch(getFeesTotalRevenues(yearFilter));
  }, [yearFilter]);

  if (userStatus === "loading") {
    return (
      <div className="full-screen bg-blue d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userStatus === "failed") {
    return <div>{lang.loadDataError}</div>;
  }

  return (
    <div className="full-screen bg-blue">
      <QuickfiscoSuccess
        active={statusSubscription === "successfully"}
        message={lang.emailSuccess}
        close={() => setStatusSubscription("idle")}
      />
      <QuickfiscoError
        active={statusSubscription === "failed"}
        message={lang.emailError}
        close={() => setStatusSubscription("idle")}
      />
      <ViewportWarning />
      <ConfirmationError />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu id="home-menu" menuItem={MenuItems.HOME} />
        </div>
        <div
          className="col no-gutters"
          style={{
            marginLeft: collapsed ? "120px" : "280px",
            transition: "all .2s ease-in-out",
          }}
        >
          <div className="row no-gutters ps-4">
            <div className="col-12 p-4">
              <div className={"row"}>
                <div className={"col-12"}>
                  <QuickfiscoHeader
                    title={lang.dashboard}
                    menuItem={MenuItems.HOME}
                  />
                </div>
              </div>
              {user.status === 6 ? (
                <div className="row">
                  <div className="col-12 mt-4">
                    <OnboardingStatusBox />
                  </div>
                </div>
              ) : (
                <>
                  {(user.status === 2 || user.status === 1) &&
                    user.subscriptionPeriod === 30 && (
                      <div className={"row mt-4"}>
                        <div className={"col-12 mb-3"}>
                          <NoVatAdvise />
                        </div>
                        <div className={"col-12 mb-3"}>
                          <TrialSubscriptionExpiration
                            lastPaymentDate={user.lastPayment}
                          />
                        </div>
                      </div>
                    )}
                  {!isProfileCompleted && user.status === 4 && (
                    <NoProfileCompleted />
                  )}
                  {userState.user.importInvoices?.needToImportInvoices && (
                    <InvoiceImportBoxHome isHome={true} />
                  )}
                  {userState.user.importInvoicesSts?.activationSts &&
                    user.stsEnabled && (
                      <InvoiceImportStsBoxHome isHome={true} />
                    )}
                  {user.regimeLimit?.status ===
                    RegimeLimitStatusType.OVER100k && (
                    <ExceedingThresholds100k />
                  )}
                  <div className="row">
                    <div className="col-xxl-6 col-12 mt-4">
                      <TotalRevenues
                        status={revenuesState.status}
                        revenues={revenuesState.revenues}
                        stsStatus={revenuesState.statusStsRevenues}
                        stsRevenues={revenuesState.stsRevenues}
                        feesStatus={revenuesState.statusFeesRevenues}
                        feesRevenues={revenuesState.feesRevenues}
                        year={yearFilter}
                      />
                    </div>
                    <div className="col-xxl-6 col-12 mt-4">
                      <InvoicesToCashOut />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`${
                        !incomeFormCompleted &&
                        ddrArray?.includes(new Date().getFullYear() - 1) // sostituire true con "incomeFormCompleted"
                          ? "col-xxl-3 col-6 mt-4 order-xxl-1 order-1"
                          : "col-xxl-6 col-12 mt-4 order-xxl-1 order-1"
                      }`}
                    >
                      <PlatformGuide />
                    </div>
                    <div className="col-xxl-3 col-6 mt-4 order-xxl-2 order-2">
                      <FinanceBox />
                    </div>
                    {!incomeFormCompleted &&
                      ddrArray?.includes(new Date().getFullYear() - 1) && ( // sostituire true con "incomeFormCompleted"
                        <div className="col-xxl-3 col-6 mt-4 order-xxl-3 order-3">
                          <IncomeUpload />
                        </div>
                      )}
                    <div
                      className={`${
                        !incomeFormCompleted &&
                        ddrArray?.includes(new Date().getFullYear() - 1) // sostituire true con "incomeFormCompleted"
                          ? "col-xxl-3 col-6 mt-4 order-xxl-4 order-4"
                          : "col-xxl-3 col-6 mt-4 order-xxl-3 order-3"
                      }`}
                    >
                      <TaxDeclaration />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-12 offset-xxl-0 order-xxl-1 order-2 mt-4">
                      <TrendGraphs
                        status={revenuesState.status}
                        revenues={revenuesState.revenues}
                        stsStatus={revenuesState.statusStsRevenues}
                        stsRevenues={revenuesState.stsRevenues}
                        feesStatus={revenuesState.statusFeesRevenues}
                        feesRevenues={revenuesState.feesRevenues}
                        year={yearFilter}
                      />
                    </div>
                    <div className="col-xxl-6 col-12 order-xxl-2 order-1 mt-4">
                      <div className="row">
                        <div className={"col-12"}>
                          <FeesCalculator
                            isProfileCompleted={isProfileCompleted}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <SubscriptionExpired
          open={
            (subscriptionExpiredModalOpen &&
              (user.status === 3 ||
                (user.status === 4 &&
                  (daysLeft ?? NaN) >= -7 &&
                  (daysLeft ?? NaN) < 0))) ||
            subscriptionAlreadySentOpen === true
          }
          setOpen={setSubscriptionExpiredModalOpen}
        />
        <TrialSubscriptionActivationModal
          open={
            differenceRegistrationDate === true &&
            trialSubscriptionActivationOpen &&
            user.status !== 3 &&
            user.status !== 4 &&
            user.status !== 6 &&
            getItemWithExipiration("stopTrialSubscriptionActivationModal") !==
              "true"
          }
          setOpen={setTrialSubscriptionActivationOpen}
        />
        <QuickfiscoModal
          modalClassName={"registration-modal-open"}
          isOpen={
            (registrationModalOpen &&
              user.status === UserStatusType.REGISTERED) ||
            (registrationModalOpen &&
              user.status === 6 &&
              registrationModalOpenCounter < 2)
          }
          hide={() => {
            setRegistrationModalOpen(false);
            if (user.status === 6) {
              setItem(
                "registrationModalOpen",
                (registrationModalOpenCounter + 1).toString()
              );
              setRegistrationModalOpenCounter(registrationModalOpenCounter + 1);
            }
          }}
        >
          <RegistrationCompleted isOnboarding={user.status === 6} />
        </QuickfiscoModal>
      </div>
    </div>
  );
}
function checkProfileComplete(
  editUserRequest: UserModel,
  setIsProfileCompleted: SetState<boolean>
): void {
  const userService = new UserService();

  userService
    .validate(editUserRequest, {
      skipConfirmPassword: true,
      skipPassword: true,
      skipEmailAvailable: true,
    })
    .then((res) => setIsProfileCompleted(res));
}
