import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: boolean;
  label?: string;
  error?: boolean;
  errorLabel?: string;
  disabled?: boolean;
  requiredField?: boolean;
  onChange?: (anser?: boolean) => void;
}

export function QuickfiscoYesNoSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { label } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { disabled } = props;
  const { requiredField } = props;
  const { onChange } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={label}
      onChange={(e) => {
        if (e?.value === 'true') onChange && onChange(true);
        else if (e?.value === 'false') onChange && onChange(false);
        else onChange && onChange(undefined);
      }}
      error={error}
      errorLabel={errorLabel}
      options={getOptions(value)}
      styleType={'default'}
      requiredField={requiredField}
      disabled={disabled}
      value={getOptions(value).filter((option) => option.selected === true)[0]}
    />
  );
}

function getOptions(value?: boolean) {
  return [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: 'Si',
      value: 'true',
      selected: value === true,
    },
    {
      label: 'No',
      value: 'false',
      selected: value === false,
    },
  ];
}
