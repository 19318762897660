import { Accordion } from 'react-bootstrap';
import lang from './taxReturnSplitPaymentsAccordion.json';
import './taxReturnSplitPaymentsAccordion.css';

interface Props {
  splitPaymentsNumber: number;
}

export function TaxReturnSplitPaymentsAccordion(props: Props) {
  const { splitPaymentsNumber } = props;

  return (
    <Accordion className="tax-return-split-payments-accordion">
      <Accordion.Item eventKey={'first-item-split-payments-accordion'}>
        <Accordion.Header>{lang.accordionTitle}</Accordion.Header>
        <Accordion.Body>
          <div className="row ">
            <div className="col-12 tax-return-split-payments-accordion-title">
              {lang.splitPayments1}
              <b>{lang.splitPayments2}</b>
              {lang.splitPayments3}
            </div>
          </div>
          <div className="row ">
            <div className="col-4 offset-4 my-4">
              <div className="tax-return-split-payments-accordion-number-container py-4">
                <span className="tax-return-split-payments-accordion-number">
                  {splitPaymentsNumber}
                </span>
                <br />
                <span className="tax-return-split-payments-accordion-number-info">
                  {splitPaymentsNumber > 1 ? lang.splits : lang.split}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 tax-return-split-payments-accordion-info">
              {lang.info1}
              <br />
              {lang.info2}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
