import { useProSidebar } from 'react-pro-sidebar';
import { ContactSaveOrEdit } from '../../components/contactSaveOrEdit/contactSaveOrEdit';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import { MenuItems, QuickfiscoMenu } from '../../components/quickfiscoMenu/quickfiscoMenu';
import { ContactType } from '../../models/contactModel';
import lang from './supplierSave.json';

export function SupplierSave() {
  const { collapsed } = useProSidebar();
  
  return (
    <div className="full-screen bg-blue">
      <div className="row no-gutters">
        <div className='col-auto no-gutters bg-blue'>
          <QuickfiscoMenu
            id='supplier-save-menu'
            menuItem={MenuItems.CONTACTS_SUPPLIER}
          />
        </div>
        <div className='col p-4 ps-5' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <ContactSaveOrEdit
                contactType={ContactType.SUPPLIER}
                operationType="save"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
