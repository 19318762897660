import { useProSidebar } from "react-pro-sidebar";
import { MenuItems, QuickfiscoMenu } from "../../components/quickfiscoMenu/quickfiscoMenu";
import { TaxDeclarationFrame } from "../../components/taxDeclarationFrame/taxDeclarationFrame";
import { useAppSelector } from "../../redux/hooks";
import "./taxDeclaration.css"

export function TaxDeclaration() {
    const user = useAppSelector(state => state.user)

    const { collapsed } = useProSidebar();

    return (
        <div className="full-screen bg-blue">
            <div className="row no-gutters">
                <div className='col-auto no-gutters'>
                    <QuickfiscoMenu
                        id='tax-declaration-menu'
                        menuItem={MenuItems.TAX_DECLARATION}
                    />
                </div>
                <div className='col no-gutters' style={{marginLeft: collapsed ? '120px' : '280px', transition: 'all .2s ease-in-out'}}>
                    <div className='row no-gutters'>
                        <div className="col-12 no-gutters">
                            {!user.user.ddrDisabled ? <TaxDeclarationFrame /> : <span className="tax-declaration-blue-text">Ops! Nel tuo abbonamento non è inclusa la dichiarazione dei redditi 2023 relativa ai redditi del 2022</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}