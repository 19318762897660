import { SingleValue } from 'react-select';
import { ForeignType } from '../../models/contactModel';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
    id: string;
    value?: ForeignType;
    error?: boolean;
    errorLabel?: string;
    onChange?: ((foreign: ForeignType | undefined) => void);
    required?: boolean;
}

export function ForeignCustomerSelect(props: Props) {
    const { onChange } = props;
    const { value } = props;
    const { id } = props;
    const { error } = props;
    const { errorLabel } = props;
    const { required } = props;

    return (
        <QuickfiscoSelect
            id={id}
            label={'Natura del cliente'}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {
                    // @ts-ignore
                    onChange && onChange(ForeignType[e.value])
                }
            }}
            options={getOptions(value)}
            styleType={'default'}
            error={error}
            requiredField={required}
            errorLabel={errorLabel}
            value={getOptions(value).filter(option => option.selected === true)[0]}
        />
    );
}

function getOptions(value?: ForeignType): OptionModel[] {
    return [
        {
            label: 'Seleziona natura cliente',
            value: undefined,
            selected: value === undefined,
        },
        {
            label: 'Azienda',
            value: ForeignType.AGENCY,
            selected: value === ForeignType.AGENCY,
        },
        {
            label: 'Privato',
            value: ForeignType.PRIVATE,
            selected: value === ForeignType.PRIVATE
        },
    ];
}
