import { formatNumberIT } from '../../utils/number';
import config from '../../../config.json';
import './occasionalJobAmount.css';
import lang from './occasionalJobAmount.json';
import { QuickfiscoSwitchInput } from '../quickfiscoSwitchInput/quickfiscoSwitchInput';
import { WithholdingTaxInfo } from '../withholdingTaxInfo/withholdingTaxInfo';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';

interface Props {
  totalAmount: number;
  applyWithholdingTaxFlag?: boolean;
  setApplyWithholdingTax: (checked: boolean) => void;
  stampId?: string;
  setStampId: (id: string) => void;
  disabled: boolean;
  error?: boolean;
  errorLabel?: string;
}

export function OccasionalJobAmount(props: Props) {
  const { totalAmount } = props;
  const { setApplyWithholdingTax } = props;
  const { applyWithholdingTaxFlag } = props;
  const { stampId } = props;
  const { setStampId } = props;
  const { disabled } = props;
  const { error } = props;
  const { errorLabel } = props;

  const withholdingTaxFee = applyWithholdingTaxFlag
    ? totalAmount * config.withholdingTax
    : 0;

  return (
    <div>
      <div className={'d-flex justify-content-between'}>
        <div className={'occasional-job-amount-label'}>
          {lang.GoodServiceAmount}
        </div>
        <div className={'occasional-job-amount-label'}>
          {formatNumberIT(totalAmount)}€
        </div>
      </div>
      <div className={'occasional-job-amount-line mt-3 mb-3'} />
      <div className={'d-flex justify-content-between align-items-center'}>
        <div className={'d-flex justify-content-start'}>
          <div className={'occasional-job-amount-label'}>
            {lang.WithholdingTax}
          </div>
          <WithholdingTaxInfo />
        </div>
        <div className={'d-flex'}>
          <div>
            <QuickfiscoSwitchInput
              id={'occasional-job-applyWithholdingTaxFlag'}
              onChange={(checked) => setApplyWithholdingTax(checked)}
              checked={applyWithholdingTaxFlag === true ? true : false}
              disabled={disabled}
            />
          </div>
          <div
            className={
              'occasional-job-amount-label occasional-job-amount-withholding-tax-fee'
            }
          >
            {formatNumberIT(withholdingTaxFee)}€
          </div>
        </div>
      </div>
      <div className={'occasional-job-amount-line mt-3 mb-3'} />
      <div className={'d-flex justify-content-between'}>
        <div className={'occasional-job-amount-label'}>{lang.GrossFee}</div>
        <div className={'occasional-job-amount-label'}>
          {formatNumberIT(totalAmount)}€
        </div>
      </div>
      <div className={'occasional-job-amount-line mt-3 mb-3'} />
      <div className={'d-flex justify-content-between'}>
        <div className={'occasional-job-amount-label-net-fee'}>
          {lang.NetFee}
        </div>
        <div className={'occasional-job-amount-label'}>
          {formatNumberIT(totalAmount - withholdingTaxFee)}€
        </div>
      </div>
      {(totalAmount > config.stampIdTheshold || error) && (
        <div className={'row mt-5'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <QuickfiscoTextarea
                id={'occasional-job-amount-stamp-id'}
                label={lang.Stamp}
                onChange={(e) => setStampId(e.target.value)}
                value={stampId ? stampId : ''}
                placeholder={lang.StampPlaceholder}
                maxLength={30}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={'row mt-1'}>
            <div className={'col-12'}>
              {error && errorLabel && (
                <div className={'occasional-job-amount-services-error'}>
                  {errorLabel}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
