import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { OccasionalJobInvoiceSaveOrDuplicateOrEdit } from '../../components/occasionalJobInvoiceSaveOrDuplicateOrEdit/occasionalJobInvoiceSaveOrDuplicateOrEdit';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import { InvoiceModel } from '../../models/invoiceModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setOccasionalJobInvoiceIdToDuplicate } from '../../redux/slices/occasionalJobInvoiceListSlice';
import { Operation } from '../../types/strings';
import { RegimeLimitStatusType } from '../../models/userModel';
import { ExceedingThresholds100k } from '../../components/exceedingThresholds100k/exceedingThresholds100k';
import lang from './occasionalJobInvoiceSaveOrDuplicate.json';

export function OccasionalJobInvoiceSaveOrDuplicate() {
  const { collapsed } = useProSidebar();

  const [occasionalJobInvoiceToEdit, setOccasionalJobInvoiceToEdit] = useState<
    InvoiceModel | undefined
  >();
  const [operation, setOperation] = useState<Operation>('save')

  const dispatch = useAppDispatch();

  const occasionalJobInvoiceListState = useAppSelector(
    (state) => state.occasionalJobInvoiceList
  );
  const userState = useAppSelector((state) => state.user.user);

  const occasionalJobInvoiceListArray =
    occasionalJobInvoiceListState.invoices.content;

  useEffect(() => {
    if (occasionalJobInvoiceListState.occasionalJobInvoiceIdToDuplicate) {
      setOperation('duplicate')
      setOccasionalJobInvoiceToEdit(
        occasionalJobInvoiceListArray?.find(
          (invoice) =>
            invoice.id ===
            occasionalJobInvoiceListState.occasionalJobInvoiceIdToDuplicate
        )
      );
    }
    return () => {
      dispatch(setOccasionalJobInvoiceIdToDuplicate(undefined));
    };
  }, [occasionalJobInvoiceListState.occasionalJobInvoiceIdToDuplicate]);

  return (
    <div className="full-screen bg-blue">
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="occasional-job-invoice-edit-menu"
            menuItem={MenuItems.OCCASIONAL_JOB_INVOICE}
          />
        </div>
        <div
          className="col p-4 ps-5"
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className="row">
            <div className="col">
              <QuickfiscoHeader
                title={lang.Title}
              />
            {userState.regimeLimit?.status === RegimeLimitStatusType.OVER100k && <ExceedingThresholds100k />}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <OccasionalJobInvoiceSaveOrDuplicateOrEdit 
                operation={operation}
                occasionalJobInvoiceToEdit={occasionalJobInvoiceToEdit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
