import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from '../../../config.json';
import { ContactModel, ContactType } from '../../models/contactModel';
import { PaginationModel } from '../../models/paginationModel';
import { ContactService } from '../../services/contactService';
import { PromiseStatuses } from '../../types/strings';

interface CustomerListState {
  status: PromiseStatuses;
  customers: PaginationModel<ContactModel>;
  page?: number
  filterCompanyName?: string
}

const initialState: CustomerListState = {
  status: 'idle',
  customers: { content: [] }
};

export const getCustomerList = createAsyncThunk(
  'customerList/getCustomerList',
  async (): Promise<PaginationModel<ContactModel>> => {
    return new ContactService().findAll(ContactType.CUSTOMER);
  }
);

const customerListSlice = createSlice({
  name: 'customerList',
  initialState,
  reducers: {
    setCustomerListPage: (state, action: PayloadAction<number | undefined>) => {
      state.page = action.payload;
    },
    setCustomerListFilterCompanyName: (state, action: PayloadAction<string | undefined>) => {
      state.filterCompanyName = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        let customers = ContactService.filter(action.payload, state.filterCompanyName);

        if (state.page !== undefined) {
          customers = ContactService.paginate(customers, state.page, config.pageSize);
        }

        state.status = 'successfully';
        state.customers = customers;
      })
      .addCase(getCustomerList.rejected, ((state) => {
        state.status = 'failed';
      }));
  },
});

export const {
  setCustomerListPage,
  setCustomerListFilterCompanyName
} = customerListSlice.actions;

export default customerListSlice.reducer;
