import { useState } from 'react';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { TaxProfileEmailConfirmation } from '../taxProfileEmailConfirmation/taxProfileEmailConfirmation';
import {
  getItemWithExipiration,
  setItemWithExipiration,
} from '../../utils/localStorage';
import { useAppSelector } from '../../redux/hooks';
import './taxTrialUser.css';
import lang from './taxTrialUser.json';

export function TaxTrialUser() {
  const [emailStatus, setEmailStatus] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector((state) => state.user.user);

  if (emailStatus === 'successfully') {
    return <TaxProfileEmailConfirmation />;
  }

  return (
    <div>
      <div className={'tax-calculation-trial-user-container'}>
        <div className={'row'}>
          <div className={'col-12 d-flex justify-content-center mb-4'}>
            <QuickfiscoIcon name={'alert-yellow.svg'} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 d-flex justify-content-center'}>
            <span className={'tax-calculation-trial-user-text mt-2 mb-4'}>
              {lang.title1}
              <br />
              {lang.title2}
            </span>
          </div>
        </div>
        {userState.status === 4 && (
          <div className={'row d-flex justify-content-center'}>
            {getItemWithExipiration('stopSubscriptionEmail') === 'true' ? (
              <div className={'col-5 text-center'}>
                <div className="trial-expired-stop-subscription">
                  {lang.stopSubscription}
                </div>
              </div>
            ) : (
              <div className={'col-10'}>
                {emailStatus === 'loading' ? (
                  <div
                    className={
                      'w-100 d-flex justify-content-center align-items-center'
                    }
                  >
                    <QuickfiscoSpinner />
                  </div>
                ) : (
                  <QuickfiscoButton
                    id={'tax-calculation-trial-user'}
                    label={lang.label}
                    type={'secondary'}
                    onClick={() => activateSubscription(setEmailStatus)}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function activateSubscription(setStatus: SetState<PromiseStatuses>) {
  const service = new UserService();

  setStatus('loading');
  service
    .sendActivateSubscriptionEmail()
    .then(() => {
      setStatus('successfully');
      setItemWithExipiration('stopSubscriptionEmail', {
        value: 'true',
        expireTime: Date.now() + 1000 * 60 * 60 * 24 * 10,
      });
    })
    .catch((err) => {
      console.error(err);
      setStatus('failed');
    });
}
