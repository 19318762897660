import React from 'react';
import { CategoryType } from '../../models/userModel';
import { useAppSelector } from '../../redux/hooks';
import { resolveUndefinedDate } from '../../utils/date';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './taxProfileStepCounter.css';

export function TaxProfileStepCounter() {
  const userState = useAppSelector(state => state.user);
  const taxProfileState = useAppSelector(state => state.taxProfile);

  if (userState.status === 'loading') {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (userState.status === 'failed' || !userState.user) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        Errore durante il caricamento dei dati
      </div>
    );
  }

  const user = userState.user;
  let steps = resolveUndefinedDate(user.vatOpeningDate)
    .getFullYear() === new Date().getFullYear() ? ['confirmData', 'currentYearData'] : ['confirmData', 'currentYearData', 'lastYearData'];

  if (user.category === CategoryType.FUND && (user.fund === "TC04" || user.fund === "TC21" || user.fund === "TC01")) {
    steps = resolveUndefinedDate(user.vatOpeningDate)
      .getFullYear() === new Date().getFullYear() ? ['confirmData', 'currentYearData', 'fourthStep'] : ['confirmData', 'currentYearData', 'lastYearData', 'fourthStep'];
  }

  let i = 1;
  let items: React.ReactNode[] = [];
  for (let step of steps) {
    items.push(
      <div key={step + i}>
        <div
          className={'tax-profile-step-counter-circle' + (step === taxProfileState.step ? ' tax-profile-step-counter-selected' : '')}
        >
          {i}
        </div>
      </div>
    );
    if (i !== steps.length) {
      items.push(
        <div key={'step-line'}>
          <div className={'tax-profile-step-counter-line'} />
        </div>
      );
    }
    i++;
  }

  return (
    <div className={'d-flex align-items-center'}>
      {items}
    </div>
  );
}
