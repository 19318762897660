import { getMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import config from '../../../config.json';
import { TaxDeadlineModel, TaxDeadlineStatus } from '../../models/taxDeadlineModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { editTaxCalculation, getTaxCalculation } from '../../redux/slices/taxCalculationSlice';
import { formatDateIT, formatDateUS } from '../../utils/date';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './taxEditDeadlineINPS.css';
import lang from './taxEditDeadlineINPS.json';

interface Props {
  deadline: TaxDeadlineModel;
}

export function TaxEditDeadlineINPS(props: Props) {
  const { deadline } = props;

  const expirationDate = new Date(deadline.expirationDate);
  const expirationDateMonth = expirationDate.getMonth();

  const [totalAmount, setTotalAmount] = useState<number>(parseNumber(formatNumberIT(deadline.totalAmount)));
  const [taxAmount, setTaxAmount] = useState<number>(parseNumber(formatNumberIT(deadline.taxAmount)));
  const [taxBalance, setTaxBalance] = useState<number>(parseNumber(formatNumberIT(deadline.taxBalance)));
  const [taxDeposit, setTaxDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.taxDeposit)));
  const [contributionAmount, setContributionAmount] = useState<number>(parseNumber(formatNumberIT(deadline.contributionAmount)));
  const [contributionBalance, setContributionBalance] = useState<number>(parseNumber(formatNumberIT(deadline.contributionBalance)));
  const [contributionDeposit, setContributionDeposit] = useState<number>(parseNumber(formatNumberIT(deadline.contributionDeposit)));

  const taxCalculationState = useAppSelector(state => state.taxCalculation);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setTaxAmount(taxBalance + taxDeposit);
    setContributionAmount(contributionBalance + contributionDeposit);
    setTotalAmount(contributionBalance + contributionDeposit + taxBalance + taxDeposit);
  }, [contributionDeposit, contributionBalance, taxDeposit, taxBalance]);

  return (
    <div key={'tax-forecast-edit-deadline' + deadline.id}>
      <div className={'row mt-3'}>
        <div className={'col-12'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'tax-forecast-deadline-text fw-normal'}>
                {lang.expiration}
              </span>
            </div>
          </div>
          <div className={'row d-flex justify-content-between align-items-end'}>
            <div className={'col-auto'}>
              <span className={'tax-forecast-deadline-date'}>
                {deadline.expirationDate && formatDateIT(deadline.expirationDate)}
              </span>
            </div>
            <div className={'col-auto'}>
              <span className={'tax-forecast-deadline-amount'}>
                {formatNumberIT(totalAmount)}{config.EUR}
              </span>
            </div>
          </div>
          <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
            <div className={'col-auto'}>
              <span className={'tax-forecast-deadline-text'}>
                {lang.contributions}
              </span>
            </div>
            <div className={'col-auto'}>
              <span className={'tax-forecast-deadline-text'}>
                {formatNumberIT(contributionAmount)}{config.EUR}
              </span>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'tax-forecast-edit-deadline-line'} />
            </div>
          </div>
          {
            expirationDateMonth != 10 && (
              <div className={'row mt-3'}>
                <div className={'col-12'}>
                  <QuickfiscoInput
                    id='tax-edit-deadline-inps-contribution-balance-input'
                    key={'input-contribution-balance' + deadline.id}
                    type={'text'}
                    styleType={'default'}
                    label={lang.balance}
                    placeholder={lang.balancePlaceholder}
                    defaultValue={formatNumberIT(contributionBalance)}
                    onChange={e => setContributionBalance(parseNumber(e.target.value))}
                  />
                </div>
              </div>
            )
          }
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <QuickfiscoInput
                id='tax-edit-deadline-inps-contribution-deposit-input'
                type={'text'}
                styleType={'default'}
                key={'input-contribution-deposit' + deadline.id}
                label={lang.deposit + (getMonth(new Date(formatDateUS(deadline.expirationDate))) === 5 ? ' 1' : ' 2')}
                placeholder={lang.depositPlaceholder}
                defaultValue={formatNumberIT(contributionDeposit)}
                onChange={e => setContributionDeposit(parseNumber(e.target.value))}
              />
            </div>
          </div>
          <div className={'row mt-3 d-flex justify-content-between align-items-end'}>
            <div className={'col-auto'}>
              <span className={'tax-forecast-deadline-text'}>
                {lang.tax}
              </span>
            </div>
            <div className={'col-auto'}>
              <span className={'tax-forecast-deadline-text'}>
                {formatNumberIT(taxAmount)}{config.EUR}
              </span>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'tax-forecast-edit-deadline-line'} />
            </div>
          </div>
          {
             expirationDateMonth != 10 && (
              <div className={'row mt-3'}>
                <div className={'col-12'}>
                  <QuickfiscoInput
                    id='tax-edit-deadline-inps-tax-balance-input'
                    type={'text'}
                    styleType={'default'}
                    key={'input-tax-balance' + deadline.id}
                    label={lang.balance}
                    placeholder={lang.balancePlaceholder}
                    defaultValue={formatNumberIT(taxBalance)}
                    onChange={e => setTaxBalance(parseNumber(e.target.value))}
                  />
                </div>
              </div>
            )
          }
          <div className={'row mt-3'}>
            <div className={'col-12'}>
              <QuickfiscoInput
                id='tax-edit-deadline-inps-tax-deposit-input'
                type={'text'}
                styleType={'default'}
                key={'input-tax-deposit' + deadline.id}
                label={lang.deposit + (getMonth(new Date(formatDateUS(deadline.expirationDate))) === 5 ? ' 1' : ' 2')}
                placeholder={lang.depositPlaceholder}
                defaultValue={formatNumberIT(taxDeposit)}
                onChange={e => setTaxDeposit(parseNumber(e.target.value))}
              />
            </div>
          </div>
          <div className={'row mt-3 d-flex justify-content-between align-items-center'}>
            <div className={'col-auto'}>
              {
                deadline.status === TaxDeadlineStatus.VALID ? (
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-forecast-deadline-text-valid'}>
                        {lang.valid}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={'row'}>
                    <div className={'col-12'}>
                      <span className={'tax-forecast-deadline-text-not-valid'}>
                        {lang.notValid}
                      </span>
                    </div>
                  </div>
                )
              }
            </div>
            <div className={'col-5'}>
              {
                taxCalculationState.editStatus === 'loading' ? (
                  <QuickfiscoSpinner />
                ) : (
                  <QuickfiscoButton
                    id={'save-tax-calculation-deadline'}
                    label={lang.buttonLabel}
                    type={'primary'}
                    key={'edit-deadline' + deadline.id}
                    onClick={() => dispatch(editTaxCalculation({
                      contributionDeposit,
                      contributionBalance,
                      taxDeposit,
                      taxBalance,
                      id: deadline.id,
                      expirationDate: deadline.expirationDate,
                      totalAmount,
                      status: deadline.status,
                      taxAmount,
                      contributionAmount,
                      minimalContribution: deadline.minimalContribution,
                      variablesContributionAmount: deadline.variablesContributionAmount,
                      variablesContributionBalance: deadline.variablesContributionBalance,
                      variablesContributionDeposit: deadline.variablesContributionDeposit
                    }))}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
