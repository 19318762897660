import { Accordion } from 'react-bootstrap';
import { ProgressStep } from '../progressStep/progressStep';
import { format } from 'date-fns';
import lang from './taxReturnProgressBarStatusAccordion.json';
import './taxReturnProgressBarStatusAccordion.css';

interface Props {
  currentStep: number;
  createdAt?: Date;
}

export function TaxReturnProgressBarStatusAccordion(props: Props) {
  const { currentStep } = props;
  const { createdAt } = props;

  return (
    <Accordion className="tax-return-progress-bar-status-accordion">
      <Accordion.Item eventKey={'first-item-progress-bar-status-accordion'}>
        <Accordion.Header>{lang.accordionTitle}</Accordion.Header>
        <Accordion.Body>
          <div className="row ">
            <div className="col-12">{lang.description}</div>
          </div>
          <div className="row ">
            <div className="col-10 offset-1 my-5">
              <ProgressStep
                currentStep={currentStep}
                numberOfSteps={4}
                labels={[
                  'Da predisporre',
                  'In lavorazione',
                  'Completa e in visione',
                  'Inviata',
                ]}
                infoText={
                  createdAt
                    ? [
                        currentStep === 0
                          ? 'il ' +
                            format(new Date(createdAt), 'dd/LL/yy') +
                            ' alle ore ' +
                            format(new Date(createdAt), 'HH.mm')
                          : '',
                        currentStep === 1
                          ? 'il ' +
                            format(new Date(createdAt), 'dd/LL/yy') +
                            ' alle ore ' +
                            format(new Date(createdAt), 'HH.mm')
                          : '',
                        currentStep === 2
                          ? 'il ' +
                            format(new Date(createdAt), 'dd/LL/yy') +
                            ' alle ore ' +
                            format(new Date(createdAt), 'HH.mm')
                          : '',
                        currentStep === 3
                          ? 'il ' +
                            format(new Date(createdAt), 'dd/LL/yy') +
                            ' alle ore ' +
                            format(new Date(createdAt), 'HH.mm')
                          : '',
                      ]
                    : ['-']
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 tax-return-progress-bar-status-accordion-info">
              <span>{lang.nb}</span>
              {lang.info1}
              <br />
              {lang.info2}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
