import { SingleValue } from "react-select";
import { InvoiceType, MapWithholdingCausal, WithholdingCausal } from "../../models/invoiceModel";
import { OptionModel } from "../../models/optionModel";
import { InvoiceSign } from "../../types/strings";
import { QuickfiscoSelect } from "../quickfiscoSelect/quickfiscoSelect";
import lang from './withholdingCasualPayment.json';

interface Props {
    id: string
    value?: string;
    error?: boolean;
    disabled?: boolean;
    invoiceSign?: InvoiceSign
    onChange?: ((type?: WithholdingCausal) => void);
}

export function WithholdingCasualPayment(props: Props) {
    const { id } = props;
    const { onChange } = props;
    const { error } = props;
    const { value } = props;
    const { disabled } = props;

    return (
        <QuickfiscoSelect
            id={id}
            label={lang.Label}
            onChange={(e: SingleValue<OptionModel>) => {
                if (e !== null) {
                    // @ts-ignore
                    onChange && onChange(WithholdingCausal[e.value])
                }
            }}
            error={error}
            errorLabel={lang.Error}
            options={getOptions(value)}
            requiredField={true}
            disabled={disabled}
            styleType={'default'}
            value={getOptions(value).filter(option => option.selected === true)[0]}
        />
    );
}

function getOptions(value?: string): OptionModel[] {
    let optionList: OptionModel[] = [];
    MapWithholdingCausal.forEach((_value, key) => {
        const label = MapWithholdingCausal.get(key) ? MapWithholdingCausal.get(key) : ''
        optionList.push(
            {
                label: label ? key + " - " + label : '',
                value: key,
                selected: value === key
            },
        )
    })
    return optionList
}