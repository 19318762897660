import './quickfiscoInputRange.css';

interface Props {
    value?: number;
    min: number
    max: number
    step: number
    disabled?: boolean
    onChange?: (value: number) => void;
    onDrag?: (value: number) => void;
}

export function QuickfiscoInputRange(props: Props) {
    const { value } = props;
    const { min } = props;
    const { max } = props;
    const { step } = props;
    const { disabled } = props;
    const { onChange } = props;
    const { onDrag } = props;

    const valuePercentage = ((100 / (max - min)) * ((value || 0) - min))

    return (
        <input
            style={{
                background: `linear-gradient(to right, #717CFF ${valuePercentage}%, #D3D3E2 ${valuePercentage}%)`
            }}
            type='range'
            id={'quickfisco-input-range'}
            max={max}
            disabled={disabled}
            min={min}
            step={step}
            value={value}
            onMouseUp={e => onDrag && onDrag(e.currentTarget.valueAsNumber > max ? max : e.currentTarget.valueAsNumber)}
            className='quickfisco-input-range'
            onChange={e => onChange && onChange(e.currentTarget.valueAsNumber > max ? max : e.currentTarget.valueAsNumber)}
        />
    );
}
