import { useEffect, useState } from 'react';
import config from '../../../config.json';
import { InvoiceFilters } from '../../models/invoiceFilters';
import { InvoiceModel, InvoiceStatusType } from '../../models/invoiceModel';
import { PaymentErrorSetters } from '../../models/paymentErrorSetters';
import { InvoicePaymentModel } from '../../models/invoicePaymentModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAutoInvoiceList } from '../../redux/slices/autoInvoiceListSlice';
import { getCustomerInvoiceList, getInvoicesToCashOut, getInvoicesToCashOutInfo } from '../../redux/slices/customerInvoiceListSlice';
import { findCustomerInvoiceById } from '../../redux/slices/customerInvoiceSlice';
import { getStsInvoiceList } from '../../redux/slices/stsInvoiceListSlice';
import { getSupplierInvoiceList } from '../../redux/slices/supplierInvoiceListSlice';
import { findSupplierInvoiceById } from '../../redux/slices/supplierInvoiceSlice';
import { getCustomerTotalRevenues, getFeesTotalRevenues, getStsTotalRevenues } from '../../redux/slices/totalRevenuesSlice';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { InvoicePaymentService } from '../../services/invoicePaymentService';
import { SetState } from '../../types/functions';
import { InvoiceSign, PaymentType, PromiseStatuses } from '../../types/strings';
import { formatDateUS, resolveUndefinedDate } from '../../utils/date';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputRadio } from '../quickfiscoInputRadio/quickfiscoInputRadio';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './invoicePaymentSave.css';
import lang from './invoicePaymentSave.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  invoiceSign: InvoiceSign;
  invoice: InvoiceModel;
}

export function InvoicePaymentSave(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { invoiceSign } = props;
  const { invoice } = props;

  const dispatch = useAppDispatch()

  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [leftAmount, setLeftAmount] = useState(0);
  const [paymentType, setPaymentType] = useState<PaymentType>('total');

  const [errorDate, setErrorDate] = useState(false);
  const [errorDateMessage, setErrorDateMessage] = useState(lang.errors.date);
  const [errorAmount, setErrorAmount] = useState(false);

  const [statusPayment, setStatusPayment] = useState<PromiseStatuses>('idle');
  const [statusTotalPaid, setStatusTotalPaid] = useState<PromiseStatuses>('idle');

  const userState = useAppSelector(state => state.user)
  const customerInvoiceListState = useAppSelector(state => state.customerInvoiceList)
  const supplierInvoiceListState = useAppSelector(state => state.supplierInvoiceList)
  const stsInvoiceListState = useAppSelector(state => state.stsInvoiceList)
  const autoInvoiceListState = useAppSelector(state => state.autoInvoiceList)
  const totalRevenuesState = useAppSelector(state => state.totalRevenues)

  const user = userState.user
  const invoicesToCashOutFilters: InvoiceFilters = {
    page: customerInvoiceListState.page,
    month: customerInvoiceListState.monthFilter,
    year: customerInvoiceListState.invoicesToCashOutYearFilter,
    type: customerInvoiceListState.typeFilter,
    companyName: customerInvoiceListState.companyNameFilter,
    status: customerInvoiceListState.statusFilter
  }

  const customerInvoiceFilters: InvoiceFilters = {
    page: customerInvoiceListState.page,
    month: customerInvoiceListState.monthFilter,
    year: customerInvoiceListState.yearFilter,
    type: customerInvoiceListState.typeFilter,
    companyName: customerInvoiceListState.companyNameFilter,
    status: customerInvoiceListState.statusFilter
  }
  const supplierInvoiceFilters: InvoiceFilters = {
    page: supplierInvoiceListState.page,
    month: supplierInvoiceListState.monthFilter,
    year: supplierInvoiceListState.yearFilter,
    companyName: supplierInvoiceListState.companyNameFilter,
    status: supplierInvoiceListState.statusFilter
  }
  const stsInvoiceFilters: InvoiceFilters = {
    page: stsInvoiceListState.page,
    year: new Date().getFullYear().toString(),
    companyName: stsInvoiceListState.companyNameFilter,
    stsStatus: stsInvoiceListState.statusFilter
  }
  const autoInvoiceFilters: InvoiceFilters = {
    page: autoInvoiceListState.page,
    month: autoInvoiceListState.monthFilter,
    year: autoInvoiceListState.yearFilter,
    companyName: autoInvoiceListState.companyNameFilter,
    status: autoInvoiceListState.statusFilter
  }

  const totalAmount = CustomerInvoiceService.calculateTotalAmount(invoice, user, invoiceSign)

  const invoicePaymentService = new InvoicePaymentService(
    {
      setErrorDate,
      setErrorDateMessage,
      setErrorAmount
    }
  );

  useEffect(() => {
    if (invoice.id !== undefined && open) {
      getTotalPaid(invoice.id, invoiceSign, totalAmount, setAmount, setLeftAmount, setStatusTotalPaid);
    }
    setDate(new Date());
  }, [invoice, invoiceSign, open]);

  const invoiceId = invoice.id

  if (invoiceId === undefined) {
    return (
      <div>Id fattura non valido</div>
    )
  }

  return (
    <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
      <QuickfiscoError
        message={lang.errors.payment}
        active={statusPayment === 'failed'}
        close={() => setStatusPayment('idle')}
      />
      <QuickfiscoError
        message={lang.errors.leftAmount}
        active={statusTotalPaid === 'failed'}
        close={() => setStatusTotalPaid('idle')}
      />
      {
        statusTotalPaid === 'loading' ? (
          <div className={'d-flex justify-content-center'}>
            <QuickfiscoSpinner />
          </div>
        ) : (
          <div className={'p-4 pt-0'}>
            <div className={'row'}>
              <div className={'col-12'}>
                <div className={'payment-status-title'}>{lang.title} {invoice.number}</div>
              </div>
            </div>
            <div className={'row mt-3'}>
              <div className={'col-12'}>
                <QuickfiscoDatePicker
                  id='invoice-payment-save-date-input'
                  label={lang.date}
                  styleType={'default'}
                  value={new Date(date)}
                  onChange={e => e && setDate((resolveUndefinedDate(formatDateUS(e))))}
                  error={errorDate}
                  errorLabel={errorDateMessage}
                  onBlur={(e) => invoicePaymentService.validateDate(new Date(e.target.value))}
                />
              </div>
            </div>
            <div className={'row mt-3'}>
              <div className={'col-6'}>
                <QuickfiscoInputRadio
                  name={'paymentstatus'}
                  label={lang.total}
                  value={'total'}
                  key={'total'}
                  id={'payment-status-total'}
                  checked={paymentType === 'total'}
                  onChange={() => {
                    setPaymentType('total');
                    setAmount(leftAmount);
                  }}
                />
              </div>
              <div className={'col-6'}>
                <QuickfiscoInputRadio
                  name={'paymentstatus'}
                  label={lang.partial}
                  value={'partial'}
                  key={'partial'}
                  id={'payment-status-partial'}
                  checked={paymentType === 'partial'}
                  onChange={() => {
                    setPaymentType('partial');
                    invoicePaymentService.validateAmount(paymentType, leftAmount, amount);
                  }}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-6 mt-3'}>
                {
                  invoiceSign !== 'supplier' &&
                  <QuickfiscoInputRadio
                    name={'paymentstatus'}
                    label={lang.reversed}
                    value={'reverse'}
                    key={'reverse'}
                    id={'payment-status-total'}
                    checked={paymentType === 'reverse'}
                    onChange={() => setPaymentType('reverse')}
                  />
                }
              </div>
              <div className={'col-6'}>
                {
                  paymentType === 'partial' && (
                    <QuickfiscoInput
                      styleType={'default'}
                      id='invoice-payment-save-amount-input'
                      type={'text'}
                      label={'Importo'}
                      defaultValue={formatNumberIT(amount)}
                      onChange={e => setAmount(parseNumber(e.target.value))}
                      error={errorAmount}
                      errorLabel={lang.errors.amount}
                      placeholder={lang.amountPlaceholder + config.EUR}
                      onBlur={(e) => invoicePaymentService.validateAmount(paymentType, leftAmount, parseNumber(e.target.value))}
                    />
                  )
                }
              </div>
            </div>
            <div className={'row mt-5'}>
              <div className={'col-4 offset-4'}>
                {
                  statusPayment === 'loading' ? (
                    <div className={'d-flex justify-content-center'}>
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={'payment-status'}
                      label={'AGGIORNA'}
                      type={'secondary'}
                      onClick={() => {
                        if (paymentType === 'reverse') {
                          setReversedStatus(
                            invoiceId,
                            invoiceSign,
                            customerInvoiceFilters,
                            dispatch,
                            setOpen,
                            setStatusPayment
                          );
                        } else {
                          save(
                            invoiceId,
                            invoiceSign,
                            {
                              amount: Number(amount),
                              receiptDate: date,
                            },
                            paymentType,
                            totalRevenuesState.yearFilter,
                            Number(leftAmount),
                            {
                              setErrorDate,
                              setErrorDateMessage,
                              setErrorAmount
                            },
                            customerInvoiceFilters,
                            supplierInvoiceFilters,
                            stsInvoiceFilters,
                            autoInvoiceFilters,
                            invoicesToCashOutFilters,
                            dispatch,
                            setOpen,
                            setStatusPayment
                          );
                        }
                      }}
                    />
                  )
                }
              </div>
            </div>
          </div>
        )
      }
    </QuickfiscoModal>
  );
}

function getTotalPaid(
  invoiceId: string,
  invoiceSign: InvoiceSign,
  totalAmount: number,
  setAmount: SetState<number>,
  setLeftAmount: SetState<number>,
  setStatus: SetState<PromiseStatuses>,
): void {
  const service = new InvoicePaymentService();

  setStatus('loading');
  service.getTotalPaid(invoiceSign, invoiceId)
    .then(data => {
      setAmount(totalAmount - data.totalPaid);
      setLeftAmount(totalAmount - data.totalPaid);
      setStatus('idle');
    })
    .catch(err => {
      setStatus('failed');
      console.error(err);
    });
}

function setReversedStatus(
  invoiceId: string,
  invoiceSign: InvoiceSign,
  customerInvoiceListFilters: InvoiceFilters,
  dispatch: Function,
  setOpen: (open: boolean) => void,
  setStatusPayment: SetState<PromiseStatuses>
): void {
  if (invoiceSign !== 'customer') {
    return
  }

  const service = new CustomerInvoiceService();

  setStatusPayment('loading');
  service
    .changeStatus(invoiceId, { status: InvoiceStatusType.REVERSED })
    .then(() => {
      setStatusPayment('successfully');
      dispatch(getCustomerInvoiceList(customerInvoiceListFilters))
      dispatch(findCustomerInvoiceById(invoiceId))
      setOpen(false)
    })
    .catch(err => {
      setStatusPayment('failed');
      console.error(err);
    });
}

function save(
  invoiceId: string,
  invoiceSign: InvoiceSign,
  payment: InvoicePaymentModel,
  paymentType: PaymentType,
  year: number,
  leftAmount: number,
  errorSetters: PaymentErrorSetters,
  customerInvoiceListFilters: InvoiceFilters,
  supplierInvoiceListFilters: InvoiceFilters,
  stsInvoiceListFilters: InvoiceFilters,
  autoInvoiceListFilters: InvoiceFilters,
  invoicesToCashOutFilters: InvoiceFilters,
  dispatch: Function,
  setOpen: (open: boolean) => void,
  setStatusPayment: SetState<PromiseStatuses>
): void {
  if (paymentType === 'reverse') {
    return
  }

  const service = new InvoicePaymentService(errorSetters);

  if (!service.validate(payment, paymentType, leftAmount)) {
    return;
  }

  setStatusPayment('loading');
  service
    .save(invoiceSign, invoiceId, payment)
    .then(() => {
      setOpen(false)
      setStatusPayment('successfully');
      dispatch(getInvoicesToCashOut(invoicesToCashOutFilters))
      dispatch(getInvoicesToCashOutInfo(invoicesToCashOutFilters))

      dispatch(getCustomerTotalRevenues(year))
      dispatch(getStsTotalRevenues(year))
      dispatch(getFeesTotalRevenues(year))


      switch (invoiceSign) {
        case 'customer':
          dispatch(findCustomerInvoiceById(invoiceId))
          dispatch(getCustomerInvoiceList(customerInvoiceListFilters))
          break;
        case 'supplier':
          dispatch(findSupplierInvoiceById(invoiceId))
          dispatch(getSupplierInvoiceList(supplierInvoiceListFilters))
          break;
        case 'sts':
          dispatch(getStsInvoiceList(stsInvoiceListFilters))
          break;
        case 'auto':
          dispatch(getAutoInvoiceList(autoInvoiceListFilters))
          break;
      }
    }).catch(err => handleSaveOrEditError(err, setStatusPayment, errorSetters));
}

function handleSaveOrEditError(
  err: any,
  setStatus: SetState<PromiseStatuses>,
  errorSetters: PaymentErrorSetters,
): void {
  err
    .json()
    .then((data: any) => {
      if (data.message === 'error.invalid-invoice-date') {
        errorSetters.setErrorDateMessage && errorSetters.setErrorDateMessage('Non puoi pagare una fattura con data precedente a quella di emissione.');
        errorSetters.setErrorDate && errorSetters.setErrorDate(true);
      }
    });

  setStatus('failed');

  console.error(err);
}
