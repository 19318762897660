import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  label?: string;
  onChange?: (province?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
}

export function QuickfiscoRegionSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { label } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={label || 'Regione'}
      options={getOptions()}
      styleType={'default'}
      value={
        getOptions(value).find(
          (option) => option.selected === true
        )
      }
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
    />
  );
}
function getOptions(value?: string): OptionModel[] {
  return [
    {
      label: 'Seleziona regione',
      value: undefined,
      selected: value === undefined,
    },
    { label: 'Abruzzo', value: 'Abruzzo', selected: value === 'Abruzzo' },
    { label: 'Basilicata', value: 'Basilicata', selected: value === 'Basilicata' },
    { label: 'Calabria', value: 'Calabria', selected: value === 'Calabria' },
    { label: 'Campania', value: 'Campania', selected: value === 'Campania' },
    { label: 'Emilia-Romagna', value: 'Emilia-Romagna', selected: value === 'Emilia-Romagna' },
    { label: 'Friuli Venezia Giulia', value: 'Friuli Venezia Giulia', selected: value === 'Friuli Venezia Giulia' },
    { label: 'Lazio', value: 'Lazio', selected: value === 'Lazio' },
    { label: 'Liguria', value: 'Liguria', selected: value === 'Liguria' },
    { label: 'Lombardia', value: 'Lombardia', selected: value === 'Lombardia' },
    { label: 'Marche', value: 'Marche', selected: value === 'Marche' },
    { label: 'Molise', value: 'Molise', selected: value === 'Molise' },
    { label: 'Piemonte', value: 'Piemonte', selected: value === 'Piemonte' },
    { label: 'Puglia', value: 'Puglia', selected: value === 'Puglia' },
    { label: 'Sardegna', value: 'Sardegna', selected: value === 'Sardegna' },
    { label: 'Sicilia', value: 'Sicilia', selected: value === 'Sicilia' },
    { label: 'Trentino-Alto Adige', value: 'Trentino-Alto Adige', selected: value === 'Trentino-Alto Adige' },
    { label: 'Umbria', value: 'Umbria', selected: value === 'Umbria' },
    { label: "Valle d'Aosta", value: "Valle d'Aosta", selected: value === "Valle d'Aosta" },
    { label: "Veneto", value: 'Veneto', selected: value === 'Veneto' },
  ];
}
