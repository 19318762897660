import { Accordion } from 'react-bootstrap';
import './landsBuildingsAccordion.css';
import lang from './landsBuildingsAccordion.json';

interface Props {
  type: 'lands' | 'buildings';
}

export function LandsBuildingsAccordion(props: Props) {
  const { type } = props;

  return (
    <Accordion className="lands-buildings-accordion">
      <Accordion.Item
        eventKey={`first-item-${type === 'lands' ? 'lands' : 'buildings'}`}
      >
        <Accordion.Header>{lang.accordionTitle}</Accordion.Header>
        <Accordion.Body>
          <ul className="lands-buildings-accordion-list">
            <li>
              {lang[type].text1_1}
              <span className="text-green">{lang[type].text1_2_green}</span>
              {lang[type].text1_3}
            </li>
            <li className="mt-3">
              {lang[type].text2_1}
              <b>{lang[type].text2_2_bold}</b>
              {lang[type].text2_3}{' '}
              <span className="text-blue">{lang[type].text2_4_blue}</span>
            </li>
            <li className="mt-3">
              {lang[type].text3_1}
              <b>{lang[type].text3_2_bold}</b>
              {lang[type].text3_3}{' '}
              <span className="text-blue">{lang[type].text3_4_blue}</span>
            </li>
            <li className="mt-3">
              {lang[type].text4_1}
              <b>{lang[type].text4_2_bold}</b>
              {lang[type].text4_3}{' '}
              <b>{lang[type].text4_4_bold}</b><br/>
              <b>{lang[type].text4_5_bold}</b>

            </li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
