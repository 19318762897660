import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  id: string;
  value?: string;
  onChange?: (deduction?: string) => void;
  error?: boolean;
  errorLabel?: string;
  requiredField?: boolean;
  disabled?: boolean;
  consortTaxDependent?: boolean;
}

export function QuickfiscoDeductionSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { onChange } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { requiredField } = props;
  const { disabled } = props;
  const { consortTaxDependent } = props;

  return (
    <QuickfiscoSelect
      id={id}
      label={'% di detrazione'}
      options={getOptions(consortTaxDependent)}
      styleType={'default'}
      value={
        getOptions(consortTaxDependent, value).filter(
          (option) => option.selected === true
        )[0]
      }
      onChange={(e) => e && onChange && onChange(e.value)}
      requiredField={requiredField}
      error={error}
      errorLabel={errorLabel}
      disabled={disabled}
    />
  );
}
function getOptions(
  consortTaxDependent?: boolean,
  value?: string
): OptionModel[] {
  let options: OptionModel[] = [
    {
      label: 'Seleziona',
      value: undefined,
      selected: value === undefined,
    },
    {
      label: '100%',
      value: '100%',
      selected: value === '100%',
    },
  ];

  if (consortTaxDependent !== true) {
    options.splice(1, 0, {
      label: '50%',
      value: '50%',
      selected: value === '50%',
    });
  }
  return options;
}
